/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _isEqual from 'lodash/isEqual';
import _random from 'lodash/random';

import AppWrapper from '../../components/AppWrapper';
import SnackBarSave from '../../components/SnackBarSave';
import ModalView from '../../components/ModalView';

import CSVUploadForm from './csv_upload_form';
import ResultTable from './table';
import ContactForm from './contact_form';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { getMomentNow } from '../../helpers/date';

import { InfoButton, InverseButton } from '../../styles/button';

import { doContactsImportCSV, createNewImportAccount } from '../../actions/contacts/import_from_csv';

import { getUsersOptions } from '../../actions/users';
import { getSelectables } from '../../actions/m_selectables';
import { getLeadSource } from '../../actions/m_lead_source';
import { getMailingLists } from '../../actions/m_mailing_lists';
import { toggleLoader } from '../../actions/global';
import { resetRedux, appChangesReset } from '../../actions/misc';

import { contactSchema } from '../../schemas/contact';

// import { DEV_MODE } from '../../constants';

class ContactsImportFromCSV extends React.Component {

    state = {
        import_contacts: [],
        csv_contacts: [],
        openEditModal: false,
        selectedContact: false,
        selectedIndex: 0,
        contacts: false,
        accounts: false,
        needToEditContacts: false,
        randNum: false, 
        dataLoaded: false
    };

    componentDidMount() {
        const { usersOptions, selectablesList, leadSourceList, mailingList } = this.props;

        // get all users
        if ( !usersOptions )
            this.props.dispatch(getUsersOptions());

        // get selectables
        if ( !selectablesList )
            this.props.dispatch(getSelectables());

        // get lead source
        if ( !leadSourceList )
            this.props.dispatch(getLeadSource());

        // get mailing lists
        if ( !mailingList )
            this.props.dispatch(getMailingLists());

        if ( usersOptions && selectablesList && leadSourceList && mailingList ) {
            this.setState({ dataLoaded: true });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { usersOptions, selectablesList, leadSourceList, mailingList } = this.props;
        // after maintenance are loaded
        if ( !this.state.dataLoaded && usersOptions && selectablesList && leadSourceList && mailingList ) {
            this.setState({ dataLoaded: true });
        }
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('contacts_import_csv'));
    }

    doReset = () => {
        this.props.dispatch(resetRedux('contacts_import_csv'));
        this.props.dispatch(appChangesReset());
        setTimeout(() => {
            this.setState({
                import_contacts: [],
                csv_contacts: [],
                openEditModal: false,
                selectedContact: false,
                selectedIndex: 0,
                contacts: false,
                accounts: false,
                needToEditContacts: false,
                randNum: false
            });
        },200);
    }

    handleReset = () => {
        const answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to reset everything?");
        if ( answer ) {
            this.doReset();
        }
    }

    handleCSVUploadComplete = ({ import_contacts, csv_contacts, contacts,accounts }) => {
        const { selectedIndex } = this.state;
        let newState = { 
            import_contacts: ( import_contacts && isArrayExists( import_contacts ) ? this.getNeedToUpdateContacts( import_contacts, csv_contacts ) : [] ), 
            csv_contacts, 
            contacts, 
            accounts, 
            randNum: _random(1,9999),
        };

        // check if need to trigger review modal
        let needToEditContacts = this.getNeedToEditContacts(import_contacts,csv_contacts),
            selectedContact = ( needToEditContacts && isArrayExists( needToEditContacts ) && needToEditContacts[selectedIndex] && !_isEmpty( needToEditContacts[selectedIndex] ) ? cloneCollections( needToEditContacts[selectedIndex] ) : false );
        if ( selectedContact ) {
            newState['openEditModal'] = true;
            newState['needToEditContacts'] = needToEditContacts;
            newState['selectedContact'] = selectedContact;
        }

        this.setState(newState);
    }

    handleImportDataToCRM = (event) => {
        const { authData } = this.props;
        const { import_contacts, csv_contacts } = this.state;
        event.preventDefault();
        let list = [],
            needToCreateAccount = false,
            accountFormData = {};

        if ( import_contacts && isArrayExists( import_contacts ) ) {
            _forEach( import_contacts, contact => {
                let val = cloneCollections(contact);
                // check if need to create new account
                if ( !( contact.account_id && !_isEmpty( contact.account_id ) && contact.account_label && !_isEmpty( contact.account_label ) ) ) {
                    needToCreateAccount = true;
                }
                // remove unique_id
                if ( val.unique_id ) {
                    delete val.unique_id;
                }
                list.push( val );
            });
        } // end - import_contacts

        if ( needToCreateAccount ) {
            accountFormData = {
                name: 'Imported '+getMomentNow('DDMMMYYYY-HH:mm:ss'),
                assigned_to: ( authData.email && !_isEmpty( authData.email ) ? [{ 
                    id: authData.email,
                    name: ( authData.name || '' )
                }] : [] )
            };
        } // end - needToCreateAccount

        // toggle Loader
        this.props.dispatch(toggleLoader(true,"The import process is currently in progress. Please do not close this window or click the Back button on your browser. On average, 90 seconds are needed to process every 100 contacts."));

        // create a new import account first (if needed)
        createNewImportAccount(accountFormData)
        .then( account => {
            
            // add account_id / account_label / assigned_to (if available)
            if ( account && account.id && !_isEmpty( account.id ) ) {
                const tempList = cloneCollections(list);
                _forEach( tempList, (item,index) => {
                    if ( !( item.account_id && !_isEmpty( item.account_id ) && item.account_label && !_isEmpty( item.account_label ) ) ) {
                        list[index].account_id = account.id;
                        list[index].account_label = account.name || '';
                        list[index].assigned_to = ( account.assigned_to && isArrayExists( account.assigned_to ) ? cloneCollections( account.assigned_to ) : [] );
                        list[index].mailing_lists = [];
                    } // end - account_id
                });
            } // end -  account.id

            doContactsImportCSV(list)
            .then( results => {
                this.props.dispatch(toggleLoader(false));
                this.doReset();
                triggerSuccessAlert("Contacts Import Success");
            })
            .catch(error => {
                let errorMessage = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to import contacts. Please try again.' ) );
                this.props.dispatch(toggleLoader(false));
                this.doReset();
                triggerErrorAlert(errorMessage,6000);
            });

        })
        .catch(error => {
            let errorMessage = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to import contacts. Please try again.' ) );
            this.props.dispatch(toggleLoader(false));
            this.doReset();
            triggerErrorAlert(errorMessage,6000);
        });

    }

    handleContactPrevious = (event) => {
        event.preventDefault();
        const { needToEditContacts, selectedIndex } = this.state;
        const newIndex = ( selectedIndex > 0 ? selectedIndex-1 : 0 );
        const newContact = ( needToEditContacts && needToEditContacts[newIndex] && isObjectExists( needToEditContacts[newIndex] ) ? cloneCollections( needToEditContacts[newIndex] ) : false );
        this.setState({ selectedIndex: newIndex, selectedContact: newContact });
    }


    handleContactNext = (event) => {
        event.preventDefault();
        const { needToEditContacts, selectedIndex } = this.state;
        const total = _size( needToEditContacts ),
            newIndex = ( selectedIndex < (total-1) ? selectedIndex+1 : total );

        if ( newIndex === total ) {
            this.setState({ selectedIndex: 0, selectedContact: false, openEditModal: false });
        } else {
            const newContact = ( needToEditContacts && needToEditContacts[newIndex] && isObjectExists( needToEditContacts[newIndex] ) ? cloneCollections( needToEditContacts[newIndex] ) : false );
            this.setState({ selectedIndex: newIndex, selectedContact: newContact });
        }
    }

    handleContactUpdate = (event) => {
        event.preventDefault();
        const { import_contacts, needToEditContacts, selectedContact, selectedIndex } = this.state;

        // update import_contacts 
        const newContacts = ( import_contacts && isArrayExists( import_contacts ) ? cloneCollections( import_contacts ) : [] );
        let index = ( import_contacts && isArrayExists( import_contacts ) && selectedContact && selectedContact.unique_id && !_isEmpty( selectedContact.unique_id ) ? _findIndex( import_contacts, { unique_id: selectedContact.unique_id }) : -1 );
        if ( index >= 0 ) {
            newContacts[index] = cloneCollections(selectedContact);
        } // end - index

        // update needToEditContacts
        const newNeedToEditContacts = ( needToEditContacts && isArrayExists( needToEditContacts ) ? cloneCollections( needToEditContacts ) : [] );
        let reviewIndex = ( needToEditContacts && isArrayExists( needToEditContacts ) && selectedContact && selectedContact.unique_id && !_isEmpty( selectedContact.unique_id ) ? _findIndex( needToEditContacts, { unique_id: selectedContact.unique_id }) : -1 );
        if ( reviewIndex >= 0 ) {
            newNeedToEditContacts[reviewIndex] = cloneCollections(selectedContact);
        } // end - reviewIndex

        this.setState({ import_contacts: newContacts, needToEditContacts: newNeedToEditContacts });
    }

    checkIfContactInfoNotMatch = (contact,csvContact) => {
        let notMatch = false;
        if ( contact && csvContact ) {
            contactSchema.forEach(schema => {
                if ( schema.import_csv && schema.id !== 'account_id' && schema.id !== 'account_label' ) {
                    if ( !_isEqual( contact[schema.id], csvContact[schema.id] ) ) {
                        notMatch = true;
                    }  // end - contact
                } // end - import_csv
            });
        } // end - contact
        return notMatch;
    }

    doesContactNeedToBeReview = (contact, csvContacts) => {
        let toBeReviewed = false;
        // make sure to do this for existing contacts only
        if ( contact && contact.id && !_isEmpty( contact.id ) ) {
            let csvContact = _find( csvContacts, { email: contact.email } );
            toBeReviewed = this.checkIfContactInfoNotMatch(contact,csvContact);
        } // end - c
        return toBeReviewed; 
    }

    doesContactNeedToBeUpdate = (contact, csvContacts ) => {
        let toBeUpdated = false;
        if ( contact ) {
            if ( contact.id && !_isEmpty( contact.id ) ) {
                let csvContact = _find( csvContacts, { email: contact.email } );
                toBeUpdated = this.checkIfContactInfoNotMatch(contact,csvContact);
            } else {
                toBeUpdated = true;
            } // end - toBeUpdated
        } // end - contact
        return toBeUpdated; 
    }

    getNeedToEditContacts = (contacts,csvContacts) => {
        return ( contacts && isArrayExists( contacts ) ? _filter( contacts, (c) => this.doesContactNeedToBeReview( c, csvContacts ) ) : [] );
    }

    getNeedToUpdateContacts = (contacts,csvContacts) => {
        return ( contacts && isArrayExists( contacts ) ? _filter( cloneCollections( contacts ), (c) => this.doesContactNeedToBeUpdate( c, csvContacts ) ) : [] );
    }

    renderEditForm = () => {
        const { selectablesList, leadSourceList } = this.props;
        const { accounts, csv_contacts, selectedContact, openEditModal } = this.state;
        return (
        <div>
            <ContactForm
                contact={( selectedContact || false )}
                csvContact={( selectedContact && selectedContact.email && !_isEmpty( selectedContact.email ) ? _find( csv_contacts, { email: selectedContact.email } ) : false )}
                accounts={( accounts || false )}
                selectables={( selectablesList || [] )}
                lead_source={( leadSourceList || [] )}
                onUpdate={(newValue) => this.setState({ selectedContact: newValue })} />
        </div>
        );
    }

    renderEditActions = () => {
        const { selectedIndex, needToEditContacts } = this.state;
        const total = ( needToEditContacts && isArrayExists( needToEditContacts ) ? _size( needToEditContacts ) : 0 );
        return (
        <DialogActions align="center">
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    Total Records: {selectedIndex+1} of {total}
                </Grid>
                <Grid item xs={8}>
                    <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group" style={{ boxShadow: 'none' }}>
                        <Button onClick={this.handleContactPrevious} disabled={( selectedIndex === 0 ? true : false )} >Previous</Button>
                        <Button onClick={this.handleContactUpdate} style={{ marginLeft: '12px' }}>Save</Button>
                        <Button onClick={this.handleContactNext} style={{ marginLeft: '12px' }}>{( selectedIndex === (total-1) ? 'Done' : 'Next' )}</Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </DialogActions>
        )
    }

    renderUploadForm = () => {
        const { selectablesList, leadSourceList } = this.props;
        return <CSVUploadForm 
                selectables={( selectablesList || false )}
                lead_source={( leadSourceList || false )}
                onUpdate={this.handleCSVUploadComplete} />
    }

    renderTable = () => {
        const { selectablesList, leadSourceList } = this.props;
        const { import_contacts, csv_contacts, contacts, accounts } = this.state;
        return (
        <>

            <div style={{ marginBottom: "20px" }}>
                <InverseButton style={{ padding: '8px 15px' }} onClick={this.handleReset}>Reset</InverseButton>
            </div>

            <ResultTable
                import_contacts={import_contacts}
                csv_contacts={csv_contacts}
                contacts={contacts}
                accounts={accounts}
                selectables={( selectablesList || false )}
                lead_source={( leadSourceList || false )}
                onUpdate={(newValue) => this.setState({ import_contacts: newValue })}
             />

            { import_contacts && isArrayExists( import_contacts ) ? (
            <div style={{ marginTop: "45px", textAlign: "center" }}>
                <InfoButton style={{ padding: "15px 45px" }} onClick={this.handleImportDataToCRM}>Perform Contacts Import</InfoButton>
            </div>
            ) : null }

        </>
        )
    }

    renderContents() {
        const { import_contacts, randNum, openEditModal } = this.state;
        return (
        <>

            { import_contacts && randNum ? this.renderTable() : this.renderUploadForm() }

            <ModalView 
                open={openEditModal}
                title="Please Review these Contacts' Data"
                maxWidth="lg"
                disableBackdrop={true}
                onClose={() => this.setState({ openEditModal: false, selectedContact: false, needToEditContacts: false, selectedIndex: 0 })}
                customActions={this.renderEditActions()}
                contents={this.renderEditForm()} />

            <SnackBarSave noSaveButton={true} />

        </>
        );
    }

    render() {
        const { dataLoaded } = this.state;
        return <AppWrapper 
                title="Import Contacts via CSV"
                subtitle="Contacts"
                back="/contacts"
                breadcrumbs={[
                    { url: '/contacts', label: 'Contacts' },
                    { label: 'Import via CSV' }
                ]}
                onLoad={( !( dataLoaded ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        importContactsList: state.contacts && state.contacts.import_csv_contacts ? state.contacts.import_csv_contacts : null,
        importAccountsList: state.contacts && state.contacts.import_csv_accounts ? state.contacts.import_csv_accounts : null,
        usersOptions: state.users && state.users.users_options || null,
        selectablesList: state.maintenance && state.maintenance.selectables || null,
        leadSourceList: state.maintenance && state.maintenance.lead_source || null,
        mailingList: state.maintenance && state.maintenance.mailing_lists || null,
        randNum: state.contacts && state.contacts.rand_import_csv ? state.contacts.rand_import_csv : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ContactsImportFromCSV);