/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_LATEST_UPDATES,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

// import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
// import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
// import { appChangesReset } from '../misc';

import { fb_getLatestUpdates } from './fb';


export const getLatestUpdates = (limits,authData) => {
	return dispatch => {

		fb_getLatestUpdates((list) => {
			dispatch({
				type: GET_LATEST_UPDATES,
				payload: { list }
			});
		},limits,authData)

	}
}