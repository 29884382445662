/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';

import ModalView from '../ModalView';
import FormMultiSelect from '../../components/FormMultiSelect';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { getSelectOptions, getSelectValues } from '../../helpers/data';
import { reverseUsersValues } from '../../helpers/users';

import { doMassAction } from '../../actions/mass_action';
import { resetRedux } from '../../actions/misc';

class MassTransferOwnership extends React.Component {

    state = {
        list: [],
        randNum: false
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { actionDone, randNum } = this.props;
        if ( actionDone && randNum && actionDone === randNum && randNum !== this.state.randNum ) {
            this.setState({ randNum });
            this.handleComplete();
        } // end - actionDone
    }

    handleComplete = () => {
        const { onCompleted } = this.props;
        if ( onCompleted ) {
            this.setState({ list: [] }); // reset 
            onCompleted();
            this.props.dispatch(resetRedux('mass_action'));
        } // end - onCompleted
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose ) {
            this.setState({ list: [] }); // reset 
            onClose();
        } // end - onClose
    }

    handleAddNew = () => {
        const { list } = this.state;
        const { refer_type, selected } = this.props;

        if ( list && isArrayExists( list ) ) {
            // trigger add comment dispatch
            this.props.dispatch(doMassAction( refer_type, 'transfer_ownership', {
                selected,
                users: list
            }, 'Ownership Transferred'));
        } else {
            triggerErrorAlert("Please select at least one user.");
        } // end - comment
    }

    renderAddNewForm = () => {
        const { users } = this.props;
        const { list } = this.state;
        return (
        <div>
            <FormMultiSelect 
                label="Select User(s)" 
                value={( list  && isArrayExists( list ) ? getSelectValues( list, 'id' ) : [] )} 
                options={getSelectOptions({ list: ( users ? _filter( users, (u) => u.status !== 'disabled' ) : [] ), keys: { value: 'email', label: 'name' }, sortBy: 'label' })} 
                onChange={(newValue) => this.setState({ list: reverseUsersValues( newValue, users ) })} />
        </div>
        )
    }

    render() {
        const { open } = this.props;
        return (
        <div>

            <ModalView 
                open={open}
                title="Transfer Ownership"
                onClose={this.handleClose}
                actionLabel="transfer Ownership"
                doAction={this.handleAddNew}
                contents={this.renderAddNewForm()} />

        </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        actionDone: state.massaction && state.massaction.action_done || null,
        randNum: state.massaction && state.massaction.rand || null,
    }
}

export default compose(
    connect(mapStateToProps)
)(MassTransferOwnership);