/* eslint-disable */
import * as firebase from 'firebase/app';
import moment from 'moment';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _size from 'lodash/size';
import _split from 'lodash/split';
import _merge from 'lodash/merge';
import _toLower from 'lodash/toLower';
import _snakeCase from 'lodash/snakeCase';
import _uniq from 'lodash/uniq';
import _remove from 'lodash/remove';
import _replace from 'lodash/replace';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _trim from 'lodash/trim';

import { isArrayExists, isObjectExists } from './validation';
import { cloneCollections, compileEmailKeywordsArray } from './data';
import { getSelectedSchemas, getSelectedSchema, getDocDataBySchema } from './schemas';

import { FIRESTORE_DEFAULT_PERPAGE } from '../constants';

const defaultProps = { 
    searchterms: false,
    filterBy: false,
    startAfter: false,
    perPage: FIRESTORE_DEFAULT_PERPAGE, 
    page: 1, 
    sortBy: false 
};

// get defaultProps for query
const getProps = (props) => {
    return ( props && isObjectExists( props ) ? _merge( cloneCollections( defaultProps ), cloneCollections( props ) ) : cloneCollections( defaultProps ) );
}

// check if selected key is available for date filter
export const isDateFilter = (type,key) => {
    var selected = getSelectedSchema(type+'|'+key);
    return ( selected && selected.search_date ? true : false );
}

export const getTimestampFilter = (keywords,type) => {
    let splitData = ( keywords && !_isEmpty( keywords ) ? _split( keywords, ', ' ) : false ),
        startDate = ( splitData && splitData[0] && !_isEmpty( splitData[0] ) ? splitData[0] : false ),
        endDate = ( splitData && splitData[1] && !_isEmpty( splitData[1] ) ? splitData[1] : false );
    if ( type && type === 'end_date' ) {
        return ( endDate ? moment(endDate).utcOffset(8).endOf('date').valueOf() : ( startDate ? moment(startDate).utcOffset(8).endOf('date').valueOf() : moment().utcOffset(8).endOf('date').valueOf() ) );
    } else {
        return ( startDate ? moment(startDate).utcOffset(8).startOf('date').valueOf() : moment().utcOffset(8).startOf('date').valueOf() );
    } // end - type
}

// get search key from searchterms
export const getSearchKey = (searchterms) => {
    var splitData = ( searchterms && !_isEmpty( searchterms ) ? _split( searchterms, '_|_' ) : false );
    return ( splitData && isArrayExists( splitData ) && splitData[0] && !_isEmpty( splitData[0] ) ? _snakeCase( _toLower( splitData[0] ) ) : false );
}

// get search key (schema ID) from searchterms
export const getSearchKeyID = (searchterms) => {
    var ID = false,
        searchKey = getSearchKey(searchterms);
    if ( searchKey && !_isEmpty( searchKey ) ) {
       searchKey = _replace( searchKey, '_srch', '' );
       switch( searchKey ) {
            case 'assigned_to':
            case 'accounts_linked':
            case 'end_user_company_linked':
            case 'contacts_linked':
            case 'potentials_linked':
            case 'products_linked':
                ID = searchKey+'.name';
                break;
           default: ID = searchKey; break;
       }
    } // end - searchKey
    return ID;
}

// get search keywords from searchterms
export const getRawSearchKeywords = (searchterms) => {
    var splitData = ( searchterms && !_isEmpty( searchterms ) ? _split( searchterms, '_|_' ) : false );
    return ( splitData && isArrayExists( splitData ) && splitData[1] && !_isEmpty( splitData[1] ) ? _trim( splitData[1] ) : false );
}

export const doSearchSort = (items, sortBy) => {
    var splitSort = _split( sortBy, '-' ),
        sortKey = ( splitSort && isArrayExists( splitSort ) && splitSort[0] && !_isEmpty(splitSort[0]) ? splitSort[0] : false ),
        sortDirection = ( splitSort && isArrayExists( splitSort ) && splitSort[1] && !_isEmpty(splitSort[1]) ? splitSort[1] : false );

    if ( sortKey && !_isEmpty( sortKey ) ) {
        switch( sortKey ) {
            default:
                items = _sortBy( items, [sortKey] );
                break;
        }
    } // end - sortKey

    if ( sortDirection && sortDirection === 'desc' ) {
        items = _reverse( items );
    } // end - sortDirection

    return items;
}

const getSearchKeywords = (searchterms) => {
    var keywords = [],
        splitData = ( searchterms && !_isEmpty( searchterms ) ? _split( searchterms, '_|_' ) : false ),
        searchKey = ( searchterms && !_isEmpty( searchterms ) ? getSearchKey( searchterms ) : false ),
        snake = ( splitData && isArrayExists( splitData ) && splitData[1] && !_isEmpty( splitData[1] ) ? _snakeCase( _toLower( splitData[1] ) ) : false );

    // for email search
    if ( splitData && isArrayExists( splitData ) && splitData[1] && !_isEmpty( splitData[1] ) && searchKey && !_isEmpty( searchKey ) && (
        searchKey === 'email_srch'
    ) ) {
        keywords = compileEmailKeywordsArray( splitData[1] );
    } else {

        if ( snake )
            keywords = _split( snake, '_' );

        if ( keywords && isArrayExists( keywords ) )
            keywords = _uniq( keywords );
    } // end - searchKey

    // check for total size to make sure didn't exceed 10
    if ( isArrayExists( keywords ) && _size( keywords ) > 10 ) {
        var keywordList = cloneCollections( keywords ),
            keywordCount = 1;
        keywords = []; // reset it back to empty array
        _forEach( keywordList, keyword => {
            // only take the first 10 keywords & no 1 alphabet keywords
            if ( keywordCount <= 10 && _size( keyword ) > 1 ) {
                keywords.push( keyword );
                keywordCount++;
            }
        });
    } // end - keywords

    return keywords;
}

export const buildFirstoreQuery = ( type, props ) => {
    // console.log(props);
    const { perPage, page, sortBy, searchterms, filterBy, startAfter, endBefore } = getProps(props);

    var ref = firebase.firestore().collection(type);

    if ( searchterms && !_isEmpty( searchterms ) ) {
        // if is search keywords
        if ( searchterms.indexOf('_srch') >= 0 ) {
            // if is search keywords
            var searchKey = getSearchKey(searchterms),
                keywords = getSearchKeywords( searchterms );
            if ( searchKey && !_isEmpty(searchKey) && keywords && isArrayExists( keywords ) ) {
                ref = ref.where(searchKey, "array-contains-any", keywords);
            } else if ( searchKey && !_isEmpty(searchKey) ) {
                // add fake keywords if empty
                ref = ref.where(searchKey, "array-contains-any", ['jdmef38dn45']);
            } else {
                // for just in case - fail safe
                ref = ref.where('id', "==", 'jdmef38dn45');
            } // end - keywords
        } else {
            // else do in
            var searchKey = getSearchKey(searchterms),
                keywords = getRawSearchKeywords( searchterms );
            // do date filter 
            if ( isDateFilter( type, searchKey ) ) {
                ref = ref.where(searchKey, ">=", getTimestampFilter(keywords,'start_date')).where(searchKey, "<=", getTimestampFilter(keywords,'end_date')).orderBy(searchKey,'asc');
            } else {

                // otherwise - do match search
                if ( searchKey && !_isEmpty(searchKey) && keywords && !_isEmpty( keywords ) ) {
                    // for email turn it to lowercase
                    if ( searchKey.indexOf('email') >= 0 ) {
                        keywords = _toLower( keywords );
                    } // end - searchKey
                    ref = ref.where(searchKey, "==", keywords);
                } else if ( searchKey && !_isEmpty(searchKey) ) {
                    // add fake keywords if empty
                    ref = ref.where(searchKey, "==", 'jdmef38dn45');
                } else {
                    // for just in case - fail safe
                    ref = ref.where('id', "==", 'jdmef38dn45');
                } // end - keywords// end - keywords

            } // end - isDateFilter

        } // end - searchterms

        // do filterBy
        // if ( filterBy && !_isEmpty( filterBy ) && filterBy !== 'all' ) {
        //     var filterKey = getSearchKey(filterBy),
        //         filterVal = getRawSearchKeywords( filterBy );
        //     if ( filterKey && !_isEmpty( filterKey ) && filterVal && !_isEmpty( filterVal ) && filterVal !== 'all' ) {
        //        ref = ref.where(filterKey, "==", filterVal);
        //     }
        // } // end - filterBy

    } else {

        // do filterBy
        if ( filterBy && !_isEmpty( filterBy ) && filterBy !== 'all' ) {
            var filterKey = getSearchKey(filterBy),
                filterVal = getRawSearchKeywords( filterBy );
            if ( filterKey && !_isEmpty( filterKey ) && filterVal && !_isEmpty( filterVal ) && filterVal !== 'all' ) {
               ref = ref.where(filterKey, "==", filterVal);
            }
        } // end - filterBy

        // only do pagination & sortBy if not searching
        if ( sortBy && !_isEmpty( sortBy ) ) {
            let sortSplit = _split( sortBy, '-' ),
                sortKey = ( sortSplit && sortSplit[0] && !_isEmpty( sortSplit[0] ) ? sortSplit[0] : '' ),
                direction = ( sortSplit && sortSplit[1] && sortSplit[1] === 'asc' ? 'asc' : 'desc' );
            if ( !_isEmpty( sortKey ) ) {
                ref = ref.orderBy(sortKey,direction);
            } else {
                ref = ref.orderBy("modified_on","desc");
            } // end - sortKey
        } else {
            ref = ref.orderBy("modified_on","desc");
        } // end - sortBy

        // set startAfter
        if ( startAfter )
            ref = ref.startAfter(startAfter);

        // set endBefore
        if ( endBefore )
            ref = ref.endBefore(endBefore);

        // set per page
        if ( perPage )
            ref = ref.limit(parseInt(perPage,10));

    } // end - searchterms

    return ref;
}

export const getItemsFromFirestore = ( type, props ) => {
    return new Promise((resolve,reject) => {

        const query = buildFirstoreQuery(type, props);
        
        query.get()
        .then((snapshot) => {
            var list = [];
            if ( snapshot && !snapshot.empty ) {
                snapshot.forEach(doc => {
                    list.push(getDocDataBySchema(getSelectedSchemas(type),doc));
                });
            } // end - snapshot
            resolve({ 
                list, 
                firstDoc: ( snapshot && !snapshot.empty ? snapshot.docs[0] : false ),
                lastDoc: ( snapshot && !snapshot.empty ? snapshot.docs[snapshot.docs.length-1] : false ) 
            });
        })
        .catch(error => {
            console.log(error);
            reject(error);
        })

    })
}

export const getTotalCountBySchema = ( type ) => {
    return new Promise((resolve,reject) => {

        firebase.database().ref("totals/"+type).once("value")
        .then( snapshot => {
            resolve(( snapshot && snapshot.exists() ? snapshot.val() : 0 ));
        })
        .catch(error => {
            reject(error);
        })

    })
}

export const sampleFunc = ( props ) => {
    return new Promise((resolve,reject) => {

        

    })
}