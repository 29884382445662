/* eslint-disable */
import _random from 'lodash/random';

import {
    M_GET_MAILING_LISTS,
    M_MAILING_LISTS_UPDATED
} from '../types';

import { 
	toggleModalDeleting, toggleModalProcessing, 
	toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
// import { appChangesReset } from '../misc';

import { fb_getMailingLists, fb_addMailingList, fb_updateMailingList, fb_deleteMailingList } from './fb';

export const getMailingLists = () => {
	return dispatch => {

		fb_getMailingLists(list => {
			dispatch({ 
                type: M_GET_MAILING_LISTS, 
                payload: { list } 
            });
		});

	}
}

export const addNewMailingList = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addMailingList(formData)
		.then( results => {
			dispatch({ type: M_MAILING_LISTS_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Mailing List Added");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new mailing list' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const updateMailingList = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_updateMailingList(formData)
		.then( results => {
			dispatch({ type: M_MAILING_LISTS_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Mailing List Updated");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update Mailing List' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteMailingList = (id) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteMailingList(id)
		.then( results => {
			dispatch({ type: M_MAILING_LISTS_UPDATED });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Mailing List Deleted");
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete Mailing List' ) );
			triggerErrorAlert(errMsg);
		});

	}
}