import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import ReportTemplates from './templates';

import { getReportCategories, getReportTemplates } from '../../actions/reports';
import { resetRedux } from '../../actions/misc';

// import { DEV_MODE } from '../../constants';

class ReportsPage extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {
        // get report templates
        this.props.dispatch(getReportTemplates());

        // get report categories
        this.props.dispatch(getReportCategories());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { reportTemplatesList, reportCategoryList, randNum } = this.props;

        // once all the data is loaded
        if ( reportTemplatesList && reportCategoryList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('reports'));
    }

    handleRefresh = () => {
        this.setState({ randNum: false });
        this.props.dispatch(resetRedux('reports'));
        this.props.dispatch(getReportTemplates());
    }

    renderContents() {
        const { authData, reportTemplatesList, reportCategoryList } = this.props;
        return <ReportTemplates 
                authData={authData} 
                templates={reportTemplatesList}
                categories={reportCategoryList}
                onRefresh={this.handleRefresh} />;
    }

    render() {
        const { randNum } = this.state;
        const { reportTemplatesList } = this.props;
        return <AppWrapper 
                title="Reports"
                onLoad={( !( reportTemplatesList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        reportTemplatesList: state.reports && state.reports.templates || null,
        reportCategoryList: state.reports && state.reports.categories || null,
        randNum: state.reports && state.reports.rand || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ReportsPage);