export const emailTemplateSchema = [
    { 
        id: 'id', 
        label: 'ID',
        required: ['update','delete'], 
        default: '', 
        type: 'string', 
        validation: 'string_id', 
        skip: ['add','update','transfer_ownership'],
        sync: false 
    },
    {   
        id: 'name',
        label: 'Name',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'text',
        skip: ['transfer_ownership'],
        sync: true
    },
    {   
        id: 'desc',
        label: 'Description',
        required: false,
        default: '',
        type: 'string',
        field: 'textarea',
        skip: false,
        sync: true
    },
    {   
        id: 'subject',
        label: 'Subject Line',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        skip: false,
        sync: true
    },

    {   
        id: 'created_on',
        label: 'Created On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: ['add','update','transfer_ownership'],
        sync: true
    },
    {   
        id: 'modified_on',
        label: 'Modified On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: ['add','update','transfer_ownership'],
        sync: true
    }
];