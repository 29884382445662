/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _remove from 'lodash/remove';
import _trim from 'lodash/trim';
import _toLower from 'lodash/toLower';

import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';

import { InfoButton } from '../../styles/button';
import { FormBox } from '../../styles/form';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';

import { contactSchema } from '../../schemas/contact';

import { COUNTRIES_LIST } from '../../data/const_countries';
import { STATE_OPTIONS } from '../../data/const_states';

const useStyles = theme => ({
    paper: {
        padding: '20px',
        backgroundColor: '#fff'
    }
});


class ContactForm extends React.Component {

    handleFormPaste = (schemaID) => (event) => {
        event.preventDefault();
        const { contact, csvContact, onUpdate, selectables, lead_source } = this.props;
        const newContact = ( contact && isObjectExists( contact ) ? cloneCollections( contact ) : {} );

        switch( schemaID ) {
            case 'designation':
                newContact[ schemaID ] = ( csvContact && csvContact[schemaID] ? csvContact[schemaID] : '' );
                newContact[ schemaID + '_id' ] = ( csvContact && csvContact[schemaID + '_id'] ? csvContact[schemaID + '_id'] : '' );
                break;
            case 'lead_source':
                newContact[ schemaID ] = ( csvContact && csvContact[schemaID] ? csvContact[schemaID] : '' );
                newContact[ schemaID + '_id' ] = ( csvContact && csvContact[schemaID + '_id'] ? csvContact[schemaID + '_id'] : '' );
                break;
            case 'billing_country':
                let selected_country = ( csvContact && csvContact[schemaID] && !_isEmpty( csvContact[schemaID] ) ? _find( COUNTRIES_LIST, { value: csvContact[schemaID] }) : false );
                newContact[ schemaID ] = ( selected_country && selected_country.value ? selected_country.value : '' );
                newContact['billing_state'] = ''; // reset state
                break;
            default:
                newContact[ schemaID ] = ( csvContact && csvContact[schemaID] ? csvContact[schemaID] : '' );
                break;
        }

        if ( onUpdate )
            onUpdate(newContact);
    }

    handleFormUpdate = (newValue,key) => {
        const { contact, onUpdate, selectables, lead_source } = this.props;
        const newContact = ( contact && isObjectExists( contact ) ? cloneCollections( contact ) : {} );

        switch( key ) {
            case 'designation_id':
                let designation_o = ( selectables && isArrayExists( selectables ) ? _find( selectables, { id: 'designation' } ) : false ),
                    selected_d = ( designation_o && designation_o.options && isArrayExists( designation_o.options ) ? _find( designation_o.options, { id: newValue }) : false );
                newContact[key] = newValue;
                newContact['designation'] = ( selected_d && selected_d.label || '' );
                break;
            case 'lead_source_id':
                let selected_lead = ( lead_source && isArrayExists( lead_source ) ? _find( lead_source, { id: newValue} ) : false );
                newContact[key] = newValue;
                newContact['lead_source'] = ( selected_lead && selected_lead.label || '' );
                break;
            default:
                newContact[key] = newValue;
                break;
        }

        if ( onUpdate )
            onUpdate(newContact);
    }

    getFieldValue = (obj,schema) => {
        switch ( schema.id ) {
            case 'billing_country':
                let selected_country = ( obj && obj[schema.id] && !_isEmpty( obj[schema.id] ) ? _find( COUNTRIES_LIST, { value: obj[schema.id] }) : false );
                return ( selected_country && selected_country.label ? selected_country.label : '' );
            default:
                return ( obj && obj[schema.id] ? obj[schema.id] : '' );
        }
    }

    renderSelect = (schema) => {
        const { contact, selectables, lead_source } = this.props;
        switch( schema.id ) {
            case 'lead_source':
                return <FormSelect label={( schema.label || '' )} value={( contact && contact[schema.id + '_id'] ? contact[schema.id + '_id'] : '' )} options={getSelectOptions({ 
                    list: ( lead_source || false ), 
                    options: [{ value: '', label: 'Select an Option'}],
                    keys: { value: 'id', label: 'label' }, 
                    sortBy: 'label'
                })} name={schema.id + '_id'} onChange={this.handleFormUpdate} />;
            case 'designation':
                let designation_o = ( selectables && isArrayExists( selectables ) ? _find( selectables, { id: 'designation' } ) : false );
                return <FormSelect label={( schema.label || '' )} value={( contact && contact[schema.id + '_id'] ? contact[schema.id + '_id'] : '' )} options={getSelectOptions({ 
                    list: ( designation_o && designation_o.options ? designation_o.options : [] ), 
                    options: [{ value: '', label: 'Select an Option'}],
                    keys: { value: 'id', label: 'label' }, 
                    sortBy: 'label'
                })} name={schema.id + '_id'} onChange={this.handleFormUpdate} />;
            case 'billing_state':
                let selected_c = ( contact && contact.billing_country && !_isEmpty( contact.billing_country ) ? _find( COUNTRIES_LIST, { value: contact.billing_country }) : false ),
                    country = ( selected_c && selected_c.label && !_isEmpty( selected_c.label ) ? _find( STATE_OPTIONS, { country: selected_c.label }) : false ),
                    options = [{ value: '', label: 'Select an Option' }];
                if ( country && country.states && isArrayExists( country.states ) ) {
                    country.states.forEach(state => {
                        options.push({ value: state, label: state });
                    });
                } // end - country.states
                return <FormSelect label={( schema.label || '' )} value={( contact && contact[schema.id] ? contact[schema.id] : '' )} options={options} name={schema.id} onChange={this.handleFormUpdate} />;
            case 'billing_country':
                return <FormSelect label={( schema.label || '' )} value={( contact && contact[schema.id] ? contact[schema.id] : '' )} options={getSelectOptions({ list: COUNTRIES_LIST, options: [{ value: '', label: 'Select an Option' }], keys: { value: 'value', label: 'label' } })} name={schema.id} onChange={this.handleFormUpdate} />;
        }
    }

    renderTextField = (schema) => {
        const { contact } = this.props;
        return <FormInput label={( schema.label || '' )} value={( contact && contact[schema.id] || '' )} rows={( 'notes' === schema.id ? 3 : 0 )} multiline={( 'notes' === schema.id ? true : false )} name={schema.id} onChange={this.handleFormUpdate} />;
    }

    renderField = (schema) => {
        const { contact } = this.props;
        return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            { 'lead_source' === schema.id || 'designation' === schema.id || 'billing_state' === schema.id || 'billing_country' === schema.id ? this.renderSelect(schema) : this.renderTextField(schema) }
            <InfoButton style={{ padding: "5px 12px", marginLeft: "8px" }} onClick={this.handleFormPaste(schema.id)}>Paste</InfoButton>
        </div>
        )
    }

    renderForm = (schema) => {
        const { csvContact } = this.props;
        return (
        <Grid key={schema.id} container spacing={2} style={{ marginTop: '10px' }}>
            <Grid item xs={4}>
                <div style={{ paddingRight: '20px' }}>
                    <FormInput label={( schema && schema.label ? "From CSV: " + schema.label : '' )} value={this.getFieldValue( csvContact, schema )} disabled={true} />
                </div>
            </Grid>
            <Grid item xs={8}>{this.renderField(schema)}</Grid>
        </Grid>
        )
    }

    render() {
        const { contact, csvContact, classes } = this.props;
        return (
        <FormBox>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormInput label="Contact ID" value={( contact && contact.id && !_isEmpty( contact.id ) ? contact.id : '[new]' )} disabled={true} />
                </Grid>

                <Grid item xs={12}>
                    <FormInput label="Account" value={( contact && contact.account_label && !_isEmpty( contact.account_label ) ? contact.account_label : '' ) + ( contact && contact.account_id && !_isEmpty( contact.account_id ) ? ' ('+contact.account_id+')' : '[new]' )} disabled={true} />
                </Grid>
                <Grid item xs={12}>
                    <FormInput label="Email" type="email" value={( contact && contact.email || '' )} disabled={true} />
                </Grid>
                {contactSchema.map(schema => {
                    if ( schema.id === 'email' || 
                        schema.id === 'account_id' || 
                        schema.id === 'account_label' ||
                        !schema.import_csv ) {
                        // skip it
                        return null;
                    } else {
                        return this.renderForm(schema);
                    }
                })}
            </Grid>
        </FormBox>
        )
    }

}

export default compose(
    withStyles(useStyles)
)(ContactForm);