/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _endsWith from 'lodash/endsWith';
import _startsWith from 'lodash/startsWith';
import _size from 'lodash/size';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _round from 'lodash/round';

import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';

const NoSelectWrapper = styled.div`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none; 
    -ms-user-select: none;
    user-select: none;
`;

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '14px',
        verticalAlign: 'top'
    },
    bodycellnoselect: {
        fontSize: '14px',
        verticalAlign: 'top'
    }
});

class TableComp extends React.Component {

    handleAllCheck = event => {
        const { items } = this.props;
        let newChecked = [];

        // compile new checks
        if ( event.target.checked ) {
            if ( items && isArrayExists( items ) ) {
                _forEach( items, media => {
                    newChecked.push({ id: media.id });
                });
            } // end - items
        } // end - event.target.checked

        // return values back to parent
        this.props.onChecked( newChecked );
        this.props.onAllChecked( event.target.checked );
    }

    handleCheckboxClick = item => event => {
        const { checked } = this.props;

        var newChecked = ( checked && isArrayExists( checked ) ? cloneCollections( checked ) : [] );
        // compile new checks
        if ( event.target.checked ) {
            if ( !_find( newChecked, { id: item.id }) ) {
                newChecked.push({ id: item.id });
            }
        } else {
            if ( _find( newChecked, { id: item.id }) ) {
                var pulled = _remove( newChecked, { id: item.id });
            }
        }

        // return values back to parent
        this.props.onChecked( newChecked );
    }

    isChecked = (item) => {
        const { checked } = this.props;
        return ( checked && isArrayExists( checked ) && item && item.id && _find( checked, { id: item.id } ) ? true : false );
    }

    renderHeaderCheckboxCell = () => {
        const { classes, allCheck, hideAllCheck } = this.props;
        return (
        <TableCell padding="checkbox" className={classes.headcell}>
            { hideAllCheck ? null : <Checkbox
                color="primary"
                checked={allCheck}
                onClick={this.handleAllCheck} /> }
        </TableCell>
        )
    }

    renderBodyCheckboxCell = (item) => {
        const { classes } = this.props;
        return (
        <TableCell padding="checkbox" style={{ verticalAlign: 'top', paddingTop: "8px" }}>
            <Checkbox
                color="primary"
                disabled={( item.disabled_check ? true : false )}
                checked={this.isChecked(item)}
                onClick={this.handleCheckboxClick(item)} />
        </TableCell>
        )
    }
    
    renderHeaderActionCell = () => {
        const { classes, actionStyles } = this.props;
        return (
        <TableCell className={classes.headcell} style={( actionStyles || null )}>Actions</TableCell>
        )
    }

    renderBodyActionCell = (item) => {
        const { classes, actions } = this.props;
        return (
        <TableCell className={classes.bodycell}>{actions(item)}</TableCell>
        )
    }

    renderRow = (item) => {
        const { classes, cells, actions, showCheckbox, noSelect, uniqueKey } = this.props;
        return (
        <TableRow key={( uniqueKey && !_isEmpty( uniqueKey ) ? item[uniqueKey] : item.id )} id={( uniqueKey && !_isEmpty( uniqueKey ) ? item[uniqueKey] : item.id )} hover>

            { showCheckbox ? this.renderBodyCheckboxCell(item) : null }

            { cells && isArrayExists( cells ) ? cells.map( cell => (
                <TableCell key={cell.id} className={classes.bodycell} style={( cell.style || null )}>{ noSelect ? <NoSelectWrapper>{( cell.render ? cell.render(item) : '' )}</NoSelectWrapper> : ( cell.render ? cell.render(item) : '' )}</TableCell>
            )) : null }
            
            { actions ? this.renderBodyActionCell(item) : null }

        </TableRow>
        )
    }

    renderBody = () => {
        const { classes, items, cells, emptyCell } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(this.renderRow) : (
                <TableRow>
                    <TableCell className={classes.bodycell} colSpan={_size( cells )}>{ emptyCell ? emptyCell : 'No item(s) found.' }</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes, cells, items, actions, showCheckbox } = this.props;
        return (
        <TableHead>
            <TableRow>
                { items && isArrayExists( items ) && showCheckbox ? this.renderHeaderCheckboxCell() : null }

                { cells && isArrayExists( cells ) ? cells.map( cell => (
                    <TableCell key={cell.id} className={classes.headcell}>{( cell.renderHead ? cell.renderHead(cell) : ( cell.label || '' ) )}</TableCell>
                )) : null }

                { items && isArrayExists( items ) && actions ?  this.renderHeaderActionCell() : null }

            </TableRow>
        </TableHead>
        )
    }

    render() {
        return (
        <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
            <Table>
                {this.renderHeader()}
                {this.renderBody()}
            </Table>
        </Paper>
        )
    }

}

export default compose(
    withStyles(useStyles),
    withRouter
)(TableComp);