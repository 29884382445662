/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';

import Table from '../../components/Table';
import PaginationFireStore from '../../components/PaginationFireStore';
import TableBarFirestore from '../../components/TableBarFirestore';
import ModelDelete from '../../components/ModalDelete';
import ButtonActions from '../../components/ButtonActions';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, combineStrings } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { isAdmin } from '../../helpers/auth';
import { getSearchKeyID, getRawSearchKeywords, doSearchSort } from '../../helpers/firestore';


class List extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        openViewModal: false
    }

    handlePageRefresh = (event) => {
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh();
    }

    handleEdit = (id,event) => {
        event.preventDefault();
        this.props.history.push("/invoices/"+id);
    }

    handleQueryChange = (newQuery) => {
        const { onQueryChange } = this.props;
        // trigger change
        if ( onQueryChange )
            onQueryChange(newQuery);
    }

    reorganizeData() {
        const { invoices, total, authData, searchterms, sortBy } = this.props;
        let items = ( invoices ? cloneCollections( invoices ) : [] ),
            totalCount = ( total ? total : 0 );

        // add in searchable label
        if ( invoices && isArrayExists( invoices ) ) {
            _forEach( invoices, (current,index) => {

                // set label as empty
                items[index].accounts_label = '';
                items[index].assigned_to_label = '';

                // run through all the IDs to get labels
                if ( current && current.accounts_linked && isArrayExists( current.accounts_linked ) ) {
                    _forEach( current.accounts_linked, u => {
                        items[index].accounts_label += ( !_isEmpty( items[index].accounts_label ) ? ' ' : '' ) + ( u && u.name ? u.name : u.id );  
                    });
                } // end - current.accounts_linked

                // run through all the IDs to get labels
                if ( current && current.assigned_to && isArrayExists( current.assigned_to ) ) {
                    _forEach( current.assigned_to, u => {
                        items[index].assigned_to_label += ( !_isEmpty( items[index].assigned_to_label ) ? ' ' : '' ) + ( u && u.name ? u.name : u.id );  
                    });
                } // end - current.assigned_to
                
            });
        } // end - invoices

        // for searchterms
        if ( searchterms && !_isEmpty( searchterms ) ) {
            // further filter out unnecessary keywords
            var search_key = getSearchKeyID(searchterms),
                search_keywords = getRawSearchKeywords(searchterms);

            items = doArraySearch( items, search_keywords, [ search_key ]);
            totalCount = _size( items );

            // do sorting on search results
            if ( sortBy && !_isEmpty( sortBy ) ) {
                items = doSearchSort( items, sortBy );
            }
        } // end - searchterms

        return { items, total: totalCount };
    }

    renderCell = type => item => {
        switch( type ) {
            case 'accounts_linked':
            case 'potentials_linked':
            case 'assigned_to':
                return ( item[type] && isArrayExists( item[type] ) ? item[type].map( (u) => (<div key={u.id}>{u.name}</div>) ) : '--' );
            case 'email':
                return <WrapWord>{( item[type] || '' )}</WrapWord>
        }
    }

    renderTableActions = () => {
        const { authData, sortBy, perPage, searchterms } = this.props;
        const { checked } = this.state;
        return <TableBarFirestore
                mod="invoices"
                authData={authData}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                searchOptions={[
                    { id: 'status', options: [
                        { value: 'created', label: 'Created' },
                        { value: 'sent', label: 'Sent' },
                        { value: 'paid', label: 'Paid' },
                        { value: 'approved', label: 'Approved' },
                        { value: 'cancel', label: 'Cancel' },
                        { value: 'auto-created', label: 'Auto Created' }
                    ] }
                ]}
                sortByOptions={[
                    { value: 'modified_on-desc', label: 'Recent Modified first' },
                    { value: 'modified_on-asc', label: 'Oldest Modified first' },
                    { value: 'name_sort-asc', label: 'Subject ( A - Z)' },
                    { value: 'name_sort-desc', label: 'Subject ( Z - A )' },
                    { value: 'accounts_linked_sort-asc', label: 'Account Name ( A - Z)' },
                    { value: 'accounts_linked_sort-desc', label: 'Account Name ( Z - A )' }
                ]}
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                    </div>
                    ] : null }
                rightButtons={[
                    <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton>
                ]}
                onFilterByChange={(newFilterBy) => this.handleQueryChange({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.handleQueryChange({ perPage: parseInt(newPerPage,10), page: 1 })}
                onSearchChange={(terms) => this.handleQueryChange({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.handleQueryChange({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page, searchterms, currentTotal } = this.props;
        return <PaginationFireStore 
                    total={totalCount}
                    currentTotal={currentTotal}
                    searchterms={searchterms}
                    perPage={perPage} 
                    page={page}
                    doneLoaded={true}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.handleQueryChange({ page: newPage }) } />
    }

    render() {
        const { authData, users } = this.props;
        const { openDeleteModal, deleteModal, openViewModal, allCheck, checked } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            {this.renderTableActions()}
            <Table 
                items={items}
                showCheckbox={true}
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={[
                    { id: 'id', label: 'Invoice Number', render: (item) => ( item.id || '' ) },
                    { id: 'name', label: 'Subject', render: (item) => ( item.name || '' ) },
                    { id: 'status', label: 'Status', render: (item) => ( item.status || '' ) },
                    { id: 'account_label', label: 'Account Name', render: (item) => ( item.accounts_label && !_isEmpty( item.accounts_label ) ? item.accounts_label : '--' ) },
                    { id: 'grand_total', label: 'Total', render: (item) => ( item.grand_total && !_isEmpty( item.grand_total ) ? ( item.currency && !_isEmpty( item.currency ) ? item.currency : '' )+item.grand_total : '---' ) },
                    { id: 'assigned_to', label: 'Assigned To', render: this.renderCell('assigned_to') },
                ]}
                actionStyles={{ width: "10%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <AInfoLink key="edit" href={"/invoices/"+item.id} size="small" onClick={this.handleEdit.bind(this,item.id)}><i className="fa fa-edit"></i>Edit</AInfoLink>
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(List);