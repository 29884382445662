import _random from 'lodash/random';


import {
	GLOBAL_MODAL_PROCESSING,
	GLOBAL_MODAL_DELETING,
	GLOBAL_ERROR_TRIGGER,
	GLOBAL_LOADER_START,
	GLOBAL_LOADER_END,
	GLOBAL_SNACKBAR_TRIGGER
} from './types';


export const toggleLoader = (status,message) => {
	return dispatch => {
		if ( status ) {
			dispatch({ 
				type: GLOBAL_LOADER_START,
				payload: ( message || null )
			});
		} else {
			dispatch({ type: GLOBAL_LOADER_END });
		} // end - status
	}
}

export const toggleModalDeleting = (status) => {
	return dispatch => {
		dispatch({ 
			type: GLOBAL_MODAL_DELETING,
			payload: ( status || null )
		});
	}
}

export const toggleModalProcessing = (status,rand) => {
	return dispatch => {
		dispatch({ 
			type: GLOBAL_MODAL_PROCESSING,
			payload: { status, rand }
		});
	}
}

export const toggleSnackBar = (type,message) => {
	return dispatch => {
		dispatch({ 
			type: GLOBAL_SNACKBAR_TRIGGER,
			payload: { type, message }
		});
	}
}