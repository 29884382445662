import React from 'react';
import styled from "styled-components";
import Select, { components } from 'react-select';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';
import _replace from 'lodash/replace';

/* eslint-disable */

import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';

import theme from '../../theme';

const Select2Wrapper = styled.div`
    padding: 0 5px;

    .react-select__control {
        border-top: none;
        border-left: none;
        border-right: none;
    }

    .react-select-container > div:not(.react-select__control),
    .react-select__menu {
        z-index: 9999;

        .react-select__option { font-size: 13px; }
    }
`;


function arrayMove(array, from, to) {
    var newArry = cloneCollections( array );
    newArry = newArry.slice();
    newArry.splice(to < 0 ? newArry.length + to : to, 0, newArry.splice(from, 1)[0]);
    return newArry;
}
  
const SortableMultiValue = SortableElement(props => {
    const onMouseDown = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const innerProps = { onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableSelect = SortableContainer(Select);

class ColumnSelector extends React.Component {

    state = {
        open: false
    };

    handleColumnSort = ({ oldIndex, newIndex }) => {
        const { name, value } = this.props;
        const selectedOptions = arrayMove(value, oldIndex, newIndex);
        if ( this.props.onChange ) {
            var newValue = [];
            if ( selectedOptions && isArrayExists( selectedOptions ) ) {
                _forEach( selectedOptions, option => {
                    if ( option && option.value && !_isEmpty( option.value ) )
                        newValue.push( option.value );
                });
            } // end - selectedOptions
            this.props.onChange(newValue,name);
        }
    }

    handleChange = (selectedOptions) => {
        const { value, name } = this.props;
        if ( this.props.onChange ) {
            var newValue = [];
            if ( selectedOptions && isArrayExists( selectedOptions ) ) {
                _forEach( selectedOptions, option => {
                    if ( option && option.value && !_isEmpty( option.value ) )
                        newValue.push( option.value );
                });
            } // end - selectedOptions
            this.props.onChange(newValue,name);
        }
    }

    handleMenuItemClick = ( selected, checked ) => (event) => {
        const { name, value } = this.props;
        var newValue = ( value && isArrayExists( value ) ? cloneCollections( value ) : [] );

        // if checked
        if ( checked ) {
            // only add if wasn't already in the options
            if ( !_find( newValue, (o) => o === selected ) )
                newValue.push(selected);
        } else {
            // only remove if it's in the options
            if ( _find( newValue, (o) => o === selected ) )
                var pulled = _remove( newValue, (o) => o === selected );
        } // end - checked

        if ( this.props.onChange ) {
            this.props.onChange(newValue,name);
        }
    }

    renderValue = selected => {
        const { options } = this.props;
        var string = '';
        if ( selected && isArrayExists( selected ) ) {
            selected.forEach(value => {
                var item = _find( options, { value } );
                string += ( !_isEmpty( string ) ? ', ' : '' ) + ( item && item.label || '' );
            });
        }
        return string;
    }

    renderMenuItem = (option) => {
        const { value, columns } = this.props;
        const checked = ( value && !_isEmpty( value ) && value.indexOf(option.value) > -1 ? true : false );
        return (
        <Grid item key={option.value} xs={( columns ? columns : 12 )}>
            <MenuItem value={option.value} disabled={( option.disabled && option.disabled == 'yes' ? true : false )} onClick={this.handleMenuItemClick(option.value,!checked)}>
                <Checkbox color="primary" checked={checked} />
                <ListItemText primary={option.label} />
            </MenuItem>
        </Grid>
        );
    }

    renderMenuHeadling = (option) => {
        return (
        <Grid item key={option.value} xs={12}>
            <ListSubheader style={{ fontWeight: '700', fontSize: '16px' }}>{option.label}</ListSubheader>
        </Grid>
        );
    }

    renderOption = (option) => {
        const { columns } = this.props;
        return option && option.heading ? this.renderMenuHeadling(option) : this.renderMenuItem(option) ;
    }

    renderSelectors = () => {
        const { options } = this.props;
        return <Grid container spacing={1}>{ options && isArrayExists( options ) ? options.map(this.renderOption) : null }</Grid>;
    }

    renderLabel = () => {
        const { label } = this.props;
        var newLabel = false;
        if ( label && !_isEmpty( label ) && ( 
            ( _toLower( label ).indexOf("(required)") >= 0 ) || 
            ( _toLower( label ).indexOf("*") >= 0 )
         ) ) {
            newLabel = _replace( label, '(Required)', '' );
            newLabel = _replace( newLabel, '(required)', '' );
            newLabel = _replace( newLabel, '*', '' );
        } // end - label
        return ( newLabel ? <span>{ newLabel || '' }<span className="required">*</span></span> : ( label || '' ) );
    }

    render() {
        const { id, value, options, disabled } = this.props;
        const { open } = this.state;
        return (
        <Select2Wrapper>
            <InputLabel style={{ fontSize: "12px", fontWeight: "700", textTransform: "uppercase", background: '#fff', padding: "0px 5px 5px 5px" }}>{this.renderLabel()}</InputLabel>
            <SortableSelect
                axis="xy"
                onSortEnd={this.handleColumnSort}
                distance={4}
                getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                isMulti
                isClearable={false}
                isDisabled={( disabled ? true : false )}
                isOptionDisabled={(option) => ( option.disabled && option.disabled === 'yes' ? true : false )}
                options={( options || null )}
                value={( value || null )}
                onChange={this.handleChange}
                components={{
                    MultiValue: SortableMultiValue,
                }}
                closeMenuOnSelect={false}
                />
        </Select2Wrapper>
        )
    }

    // renderOldRender = () => {
    //     return (
    //     <div style={{ paddingLeft: "5px", paddingRight: "5px" }}>
    //         <FormControl fullWidth={true}>
    //             <InputLabel shrink={true} htmlFor={( id || newID )} style={{ fontSize: "16px", fontWeight: "700", textTransform: "uppercase" }}>{label || '' }</InputLabel>
    //             <Select
    //                 multiple
    //                 //open={( open || false )}
    //                 value={( value && isArrayExists( value ) ? value : [] )}
    //                 //onChange={this.handleChange}
    //                 input={ disabled ? <FilledInput id={( id || newID )} fullWidth={true} /> : <Input id={( id || newID )} fullWidth={true} />}
    //                 renderValue={this.renderValue}
    //                 disabled={( disabled || false )}
    //                 MenuProps={{
    //                     PaperProps: {
    //                         style: { maxHeight: '75vh', width: 250, background: "#fff" }
    //                     }
    //                 }}
    //                 >
    //             </Select>
    //         </FormControl>
    //     </div>
    //     )
    // }

}

export default ColumnSelector