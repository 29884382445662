/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import shortid from 'shortid';
import styled from "styled-components";
import Dropzone from 'react-dropzone';
import Papa from 'papaparse';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import blue from '@material-ui/core/colors/blue';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _remove from 'lodash/remove';
import _trim from 'lodash/trim';
import _toLower from 'lodash/toLower';

import FormDatePicker from '../../components/FormDatePicker';

import { InfoButton, AInverseLink } from '../../styles/button';
import { FormBox } from '../../styles/form';

import { isArrayExists, isObjectExists, validateEmail } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { getMomentNow } from '../../helpers/date';
import { getContactsByEmail, getAccountsData } from '../../actions/contacts/import_from_csv';

import { contactSchema } from '../../schemas/contact';

import { toggleLoader } from '../../actions/global';

import { COUNTRIES_LIST } from '../../data/const_countries';
import { STATE_OPTIONS } from '../../data/const_states';

const useStyles = theme => ({
    paper: {
        padding: '20px',
        backgroundColor: '#fff'
    },
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background
    },
    btnwrapper: {
        marginTop: "15px",
        textAlign: "center",
        '& button': {
            padding: "12px 25px"
        }
    }
});

const FormWrapper = styled.div`
    display: block;
    margin: 30px auto;
    max-width: 800px;
`;

class CSVUploadForm extends React.Component {

    handleFileUpload = (files) => {
        const { onUpdate } = this.props;
        if ( files && isArrayExists( files ) && files[0] && onUpdate ) {
            Papa.parse(files[0], {
                header: true,
                complete: (results) => {
                    this.props.dispatch(toggleLoader(true));
                    const promises = [], 
                        csv_contacts = this.processDataFromCSV( results ),
                        csv_accounts = this.extractAccountsFromContacts( csv_contacts );

                    promises.push( getContactsByEmail( csv_contacts ) );
                    promises.push( getAccountsData( csv_accounts ) );

                    Promise.all( promises )
                    .then(results => {
                        this.props.dispatch(toggleLoader(false));
                        const contacts = ( results && results[0] && isArrayExists( results[0] ) ? cloneCollections( results[0] ) : [] ),
                            accounts = ( results && results[1] && isArrayExists( results[1] ) ? cloneCollections( results[1] ) : [] );
                        onUpdate({
                            import_contacts: this.getImportContacts(csv_contacts,contacts,accounts),
                            csv_contacts,
                            contacts,
                            accounts
                        });
                    })
                    .catch(error => {
                        this.props.dispatch(toggleLoader(false));
                        let errorMessage = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to import contacts. Please try again.' ) );
                        triggerErrorAlert(errorMessage,5000);
                    });
                }
            }); 
        } // end - files
    }

    getImportContacts = (csv_contacts,contacts,accounts) => {
        let list = [];

        // add existing contacts first
        if ( contacts && isArrayExists( contacts ) ) {
            _forEach( contacts, contact => {
                let item = cloneCollections( contact );
                item['unique_id'] = shortid.generate(); // add unique_id for reference
                list.push( item );
            });
        } // end - contacts

        // then, add csv contacts if wasn't found in contacts (based on email)
        if ( csv_contacts && isArrayExists( csv_contacts ) ) {
            _forEach( csv_contacts, contact => {
                let item = cloneCollections( contact );
                // make sure it wasn't already in the list
                if ( !_find( list, { email: item.email } ) ) {
                    // add account id & label if found
                    let selected_account = ( accounts && isArrayExists( accounts ) ? _find( accounts, (a) => ( 
                        ( a.id && !_isEmpty( a.id ) && contact.account_id && !_isEmpty( contact.account_id ) && _trim( _toLower( a.id ) ) === _trim( _toLower( contact.account_id ) ) ) || 
                        ( a.name && !_isEmpty( a.name ) && contact.account_label && !_isEmpty( contact.account_label ) && _trim( _toLower( a.name ) ) === _trim( _toLower( contact.account_label ) ) )
                        ? true : false ) ) : false );
                    // if found, add it
                    if ( selected_account ) {
                        item.account_id = ( selected_account.id || '' );
                        item.account_label = ( selected_account.name || '' );
                        // add mailing list
                        item.mailing_lists = ( selected_account.mailing_lists && isArrayExists( selected_account.mailing_lists ) ? cloneCollections( selected_account.mailing_lists ) : [] );
                        // add assigned_to
                        item.assigned_to = ( selected_account.assigned_to && isArrayExists( selected_account.assigned_to ) ? cloneCollections( selected_account.assigned_to ) : [] );
                    } else {
                        // if wasn't in the list, reset it to empty string
                        item.account_id = '';
                        item.account_label = '';
                        // add company to notes
                        item.notes = 'Imported from CSV file on '+getMomentNow('DDMMMYYYY-HH:mm:ss')+'\n'+'Company Name: ' + ( contact && contact.account_label && !_isEmpty( contact.account_label ) ? contact.account_label : '---' );
                    } // end - selected_account
                    list.push( item );
                } // end - list
            });
        } // end - csv_contacts

        return list;
    }

    processDataFromCSV = (results) => {
        const { selectables, lead_source } = this.props;
        let contacts = [];
        if ( results && results.data && isArrayExists( results.data ) ) {
            _forEach( results.data, item => {
                let contact = { 
                    id: '',
                    unique_id: shortid.generate() 
                };

                // run through schema to get value
                _forEach(contactSchema,schema => {
                    // only add if is for import_csv
                    if ( schema.import_csv ) {
                        switch ( schema.id ) {
                            case 'designation':
                                let designation = ( item[schema.id] ? _trim( _toLower( item[schema.id] ) ) : '' ),
                                    designation_o = ( selectables && isArrayExists( selectables ) ? _find( selectables, { id: 'designation' } ) : false ),
                                    selected_d = ( designation_o && designation_o.options && isArrayExists( designation_o.options ) ? _find( designation_o.options, o => !_isEmpty( designation ) && _trim( _toLower( o.label ) ) === designation ) : false );
                                    contact[schema.id] = ( selected_d && selected_d.label || '' );
                                    contact['designation_id'] = ( selected_d && selected_d.id || '' ); // add to designation_id for reference purpose
                                break;
                            case 'account_label':
                                contact[schema.id] = ( item['account_name'] ? item['account_name'] : ( schema.default || '' ) );
                                break;
                            case 'lead_source':
                                let selected_lead = ( lead_source && isArrayExists( lead_source ) ? _find( lead_source, l => item[schema.id] && !_isEmpty( item[schema.id] ) && _trim(_toLower( l.label )) === _trim(_toLower( item[schema.id] )) ) : false );
                                contact[schema.id] = ( selected_lead && selected_lead.label || '' ); // add to label
                                contact['lead_source_id'] = ( selected_lead && selected_lead.id || '' ); // add to label
                                break;
                            case 'email':
                                contact[schema.id] = ( item[schema.id] ? _trim( _toLower( item[schema.id] ) ) : ( schema.default || '' ) );
                                break;
                            case 'billing_country':
                                let csv_country = ( item[schema.id] && !_isEmpty( item[schema.id] ) ? _trim( _toLower( item[schema.id] ) ) : false ),
                                    selected_country = ( csv_country ? _find( COUNTRIES_LIST, c => _trim( _toLower( c.label ) ) === csv_country ) : false );
                                contact[schema.id] = ( selected_country && selected_country.value && !_isEmpty( selected_country.value ) ? selected_country.value : '' );
                                break;
                            default:
                                contact[schema.id] = ( item[schema.id] ? item[schema.id] : ( schema.default || '' ) );
                                break;
                        }
                    } // end import_csv
                });

                // make sure contacts is unique (based on email)
                if ( contact.email && !_isEmpty( contact.email ) && validateEmail( contact.email ) && !_find( contacts, { email: contact.email } ) )
                    contacts.push( contact );
            });
        } // end - results.data
        return contacts;
    }

    extractAccountsFromContacts = (contacts) => {
        let accounts = [];
        _forEach(contacts, (contact) => {
            if ( contact && contact.account_id && !_isEmpty( contact.account_id ) ) {
                if ( !_find( accounts, { id: contact.account_id, type: 'id' } ) ) {
                    accounts.push({ id: contact.account_id, type: 'id' });
                }
            } else if ( contact && contact.account_label && !_isEmpty( contact.account_label ) ) {
                if ( !_find( accounts, { id: contact.account_label, type: 'name' } ) ) {
                    accounts.push({ id: contact.account_label, type: 'name' });
                }
            } // end - contact
        });
        return accounts;
    }

    render() {
        const { classes } = this.props;
        return (
        <FormWrapper>
            <Paper elevation={2} className={classes.paper}>
                
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="h4" className={classes.boxheading}>Select CSV File</Typography>
                    </Grid>
                    <Grid item xs={6} align="right">
                        <AInverseLink href="https://firebasestorage.googleapis.com/v0/b/dreamcatcher-lms.appspot.com/o/website%2Fcrm_contacts_import_sample.csv?alt=media&token=82d0712c-c0e8-400b-800a-4830d9afe2ea" size="small" target="_blank" style={{ padding: "5px 8px" }}>Download Sample CSV</AInverseLink>
                    </Grid>
                    <Grid item xs={12}>
                        <Dropzone maxFiles={1} accept=".csv" onDrop={this.handleFileUpload}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ style: {
                                display: "block",
                                background: blue['50'],
                                border: "2px dashed " + blue['700'],
                                padding: "30px",
                                textAlign: "center",
                                height: "200px"
                            } })}>
                                <input {...getInputProps()} />
                                <span style={{ fontWeight: "700" }}>Drag 'n' drop a csv file here, or click to select a csv</span>
                            </div>
                        )}
                        </Dropzone>
                    </Grid>
                </Grid>
            </Paper>
        </FormWrapper>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles)
)(CSVUploadForm);