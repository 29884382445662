/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";

import ModalView from "../../components/ModalView";

import { callFunctionsAPI } from "../../helpers/action";
import { triggerErrorAlert } from "../../helpers/alert";
import { isObjectExists, isArrayExists } from "../../helpers/validation";
import { compareStrings } from "../../helpers/data";

import { InfoButton, GreyButton, InverseButton, ErrorButton, AIndigoLink } from "../../styles/button";
import { AmberTag, GreyTag, SuccessTag, IndigoTag } from "../../styles/tag";
import { WrapWord } from "../../styles/misc";

const useStyles = (theme) => ({
    tbody: {
        "& tr": {
            "& td": {
                padding: "8px 0",
                borderBottom: "1px solid #ccc",
            },
            "&:last-child": {
                "& td": {
                    borderBottom: "none",
                },
            },
        },
    },
    emailcell: {
        fontSize: "13px",
        width: "50%",
        verticalAlign: "middle",
    },
    emptycell: {
        fontSize: "13px",
        verticalAlign: "middle",
        color: "#999",
    },
    actioncell: {
        verticalAlign: "middle",
        width: "50%",
        textAlign: "right",
    },
});

const Wrapper = styled.div`
    padding: 10px 20px;
    border: 1px solid #ccc;
`;

const Label = styled.h5`
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.54);
`;

const ActionsWrapper = styled.div``;

const AlternativeEmails = ({ authData, contact = {}, classes, onPersonalEmailUpdate = () => {}, onAlternativeEmailsUpdate = () => {} }) => {
    const dclSyncUID = useMemo(() => {
        return contact && contact.dcl_sync_uid ? contact.dcl_sync_uid : null;
    }, [contact]);

    const emails = useMemo(() => {
        let list = [];
        if (contact && contact.alternative_emails && isArrayExists(contact.alternative_emails)) {
            contact.alternative_emails.forEach((email) => {
                list.push({ email: email, type: "alternative" });
            });
        }
        if (contact && contact.email_personal && !_isEmpty(contact.email_personal)) {
            // make sure the personal email is not already in the list
            if (!_find(list, { email: contact.email_personal })) {
                list.push({ email: contact.email_personal, type: "personal" });
            }
        }
        return list;
    }, [contact]);

    return (
        <Wrapper>
            <Label>Alternative Emails</Label>
            <Table>
                <TableBody className={classes.tbody}>
                    {isArrayExists(emails) ? (
                        emails.map((item) => (
                            <TableRow key={item.email}>
                                <TableCell className={classes.emailcell} align="left">
                                    <WrapWord>{item.email}</WrapWord>
                                    {item.type === "personal" && (
                                        <Tooltip
                                            title={
                                                <span style={{ fontSize: "14px", lineHeight: 1.5 }}>
                                                    This email is originally from {item.type} email.
                                                </span>
                                            }
                                        >
                                            <i
                                                className="fa fa-exclamation-circle"
                                                style={{ color: "black", marginLeft: "10px", fontSize: "13px", verticalAlign: "middle" }}
                                            ></i>
                                        </Tooltip>
                                    )}
                                </TableCell>
                                <TableCell className={classes.actioncell} align="right">
                                    <ActionsWrapper>
                                        {dclSyncUID && item.type === "alternative" && (
                                            <>
                                                <Tooltip
                                                    title={
                                                        <span style={{ fontSize: "14px" }}>
                                                            This email is synced from Digitalbadge Platform. You can only edit it in the platform.
                                                        </span>
                                                    }
                                                >
                                                    <i
                                                        className="fa fa-exclamation-circle"
                                                        style={{ color: "black", marginRight: "10px", fontSize: "16px", verticalAlign: "middle" }}
                                                    ></i>
                                                </Tooltip>
                                                <AIndigoLink
                                                    size="small"
                                                    href={"https://digitalbadge.dreamcatcher.asia/admin/users/" + dclSyncUID}
                                                    target="_blank"
                                                >
                                                    <i className="fa fa-link"></i>
                                                    Edit in Digitalbadge Platform
                                                </AIndigoLink>
                                            </>
                                        )}
                                        {item.type === "personal" && (
                                            <ErrorButton
                                                size="small"
                                                minWidth="0px"
                                                noIconMargin="yes"
                                                onClick={() => {
                                                    const confirm = window.confirm("Are you sure you want to delete this email?");
                                                    if (confirm) {
                                                        onPersonalEmailUpdate("");
                                                    }
                                                }}
                                            >
                                                <i className="fa fa-trash"></i>
                                            </ErrorButton>
                                        )}
                                    </ActionsWrapper>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={2} className={classes.emptycell} align="left">
                                No alternative emails found.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Wrapper>
    );
};

const mapStateToProps = (state) => {
    return {
        authData: (state.auth && state.auth.user) || null,
        changesMade: (state.misc && state.misc.changes_made) || null,
    };
};

export default compose(connect(mapStateToProps), withStyles(useStyles), withRouter)(AlternativeEmails);
