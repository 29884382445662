/* eslint-disable */
import _random from 'lodash/random';

import {
    M_GET_REGIONS,
    M_REGIONS_UPDATED,
	GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getRegions, fb_syncRegions, fb_addRegion, fb_updateRegion, fb_deleteRegion } from './fb';

export const getRegions = () => {
	return dispatch => {

		fb_getRegions(list => {
			dispatch({ 
                type: M_GET_REGIONS, 
                payload: { list } 
            });
		});

	}
}

export const addRegion = (formData) => {
	return dispatch => {

		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addRegion(formData)
		.then( results => {
			dispatch({ type: M_REGIONS_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Company Added");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new Company' ) );
			triggerErrorAlert(errMsg);
		});
        
  }
}

export const editRegion = (formData) => {
	return dispatch => {

		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_updateRegion(formData)
		.then( results => {
			dispatch({ type: M_REGIONS_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Company Updated");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update Company' ) );
			triggerErrorAlert(errMsg);
		});
        
  }
}

export const deleteRegion = (id) => {
	return dispatch => {

		dispatch(toggleModalDeleting(true));

		fb_deleteRegion(id)
		.then( results => {
			dispatch({ type: M_REGIONS_UPDATED });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Company Deleted!");
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete Company' ) );
			triggerErrorAlert(errMsg);
		});

  	}
}

export const syncDataWithLMS = (id,type) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_syncRegions()
		.then(() => {
			dispatch({ type: M_REGIONS_UPDATED });
			dispatch(toggleLoader(false));
			triggerSuccessAlert("Companies Synced!");
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to sync data with LMS' ) );
			dispatch(toggleLoader(false));
			triggerErrorAlert(errMsg);
		});

  	}
}