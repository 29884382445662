export const contactSchema = [
    {
        id: "id",
        label: "ID",
        required: ["update", "delete"],
        default: "",
        type: "string",
        validation: "string_id",
        skip: ["add", "transfer_ownership", "duplicate"],
        report: true,
        sort: true,
        search_match: true,
        sync: false,
    },
    {
        id: "scrm_id",
        label: "Second CRM ID",
        required: false,
        default: "",
        type: "system_data",
        skip: ["add", "update", "transfer_ownership"],
        report: false,
        sync: true,
        disabled: ["update"],
    },
    {
        id: "lms_linked",
        label: "LMS Trainee IDs",
        required: false,
        default: [],
        type: "system_data",
        skip: ["add", "update", "transfer_ownership"],
        report: false,
        sync: false,
    },
    {
        id: "mailer_linked",
        label: "Mailer ID",
        required: false,
        default: "",
        type: "system_data",
        skip: ["update", "transfer_ownership"],
        report: false,
        sync: true,
        disabled: ["update"],
    },
    {
        id: "status",
        label: "Status",
        required: ["update"],
        default: "active",
        type: "string",
        field: "radio",
        skip: ["transfer_ownership"],
        report: true,
        merge: true,
        sync: true,
    },
    {
        id: "designation",
        label: "Designation",
        required: false,
        default: "",
        type: "string",
        field: "select",
        skip: ["transfer_ownership", "duplicate"],
        import_csv: true,
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "designation_id",
        label: "Designation (ID)",
        required: false,
        default: "",
        type: "system_data",
        skip: ["transfer_ownership", "duplicate"],
        report: false,
        merge: true,
        sync: true,
    },
    {
        id: "first_name",
        label: "First Name",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership"],
        import_csv: true,
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "last_name",
        label: "Last Name",
        required: ["add", "update"],
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership"],
        import_csv: true,
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "full_name",
        label: "Name",
        required: false,
        default: "",
        type: "system_data",
        skip: ["add", "update", "delete", "duplicate", "transfer_ownership"],
        report: false,
        sort: true,
        search_keywords: true,
        sync: false,
    },
    {
        id: "account_id",
        label: "Account",
        required: ["add", "update"],
        default: "",
        type: "string",
        field: "select2",
        skip: ["transfer_ownership"],
        import_csv: true,
        report: false,
        merge: true,
        sync: true,
        disabled: ["update"],
    },
    {
        id: "account_label",
        label: "Account Name",
        required: ["add", "update"],
        default: "",
        type: "system_data",
        skip: ["transfer_ownership"],
        import_csv: true,
        report: true,
        sort: true,
        search_keywords: true,
        merge: true,
        sync: true,
    },

    {
        id: "email",
        label: "Email",
        required: ["add", "update"],
        default: "",
        type: "email",
        field: "email",
        skip: ["transfer_ownership", "duplicate"],
        import_csv: true,
        report: true,
        search_match: true,
        search_keywords: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "email_personal",
        label: "Personal Email",
        required: false,
        default: "",
        type: "email",
        field: "email",
        skip: ["transfer_ownership", "duplicate"],
        import_csv: true,
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "alternative_emails",
        label: "Alternative Emails",
        required: false,
        default: [],
        type: "array",
        field: "custom",
        skip: ["transfer_ownership", "duplicate"],
        import_csv: true,
        report: true,
        search_keywords: true,
        merge: true,
        email_shortcode: false,
        sync: true,
    },
    {
        id: "mobile_phone",
        label: "Mobile Phone",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership", "duplicate"],
        import_csv: true,
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "office_phone",
        label: "Office Phone",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership", "duplicate"],
        import_csv: true,
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "other_phone",
        label: "Other Phone",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership", "duplicate"],
        import_csv: true,
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "fax",
        label: "Fax",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership", "duplicate"],
        import_csv: true,
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "position",
        label: "Position",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership", "duplicate"],
        import_csv: true,
        report: true,
        search_keywords: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "department",
        label: "Department",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership", "duplicate"],
        import_csv: true,
        report: true,
        search_keywords: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "lead_source_id",
        label: "Lead Source",
        required: false,
        default: "",
        type: "system_data",
        field: "select",
        skip: ["transfer_ownership", "duplicate"],
        report: false,
        merge: true,
        sync: true,
    },
    {
        id: "lead_source",
        label: "Lead Source",
        required: false,
        default: "",
        type: "string",
        skip: ["transfer_ownership", "duplicate"],
        import_csv: true,
        report: true,
        merge: true,
        sync: true,
    },
    {
        id: "last_called",
        label: "Last Called",
        required: false,
        default: 0,
        type: "timestamp",
        field: "datepicker",
        skip: ["transfer_ownership", "duplicate"],
        report: true,
        merge: true,
        sync: true,
    },
    {
        id: "do_not_call",
        label: "Do Not Call",
        required: false,
        default: "no",
        type: "string",
        field: "select",
        skip: ["transfer_ownership", "duplicate"],
        report: true,
        merge: true,
        sync: true,
    },
    {
        id: "email_optout",
        label: "Email Opt Out",
        required: false,
        default: "no",
        type: "string",
        field: "select",
        skip: ["transfer_ownership", "duplicate"],
        report: true,
        merge: true,
        sync: true,
    },
    {
        id: "email_bounced",
        label: "Email Bounced",
        required: false,
        default: "no",
        type: "string",
        field: "select",
        skip: ["transfer_ownership", "duplicate"],
        report: true,
        merge: true,
        sync: true,
        disabled: ["update"],
    },
    {
        id: "mailing_lists",
        label: "Mailing Lists",
        required: false,
        default: [],
        type: "array_ids",
        field: "checkboxes",
        skip: ["transfer_ownership", "duplicate"],
        report: true,
        merge: true,
        sync: true,
    },

    {
        id: "billing_address",
        label: "Billing (address)",
        required: false,
        default: "",
        type: "string",
        field: "textarea",
        skip: ["transfer_ownership", "duplicate"],
        import_csv: true,
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "billing_city",
        label: "Billing (city)",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership", "duplicate"],
        import_csv: true,
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "billing_post_code",
        label: "Billing (Post Code)",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership", "duplicate"],
        import_csv: true,
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "billing_state",
        label: "Billing (state)",
        required: false,
        default: "",
        type: "string",
        field: "select",
        skip: ["transfer_ownership", "duplicate"],
        import_csv: true,
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "billing_country",
        label: "Billing (Country)",
        required: false,
        default: "",
        type: "string",
        field: "select",
        skip: ["transfer_ownership", "duplicate"],
        import_csv: true,
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "notes",
        label: "Notes",
        required: false,
        default: "",
        type: "string",
        field: "textarea",
        skip: ["transfer_ownership"],
        import_csv: true,
        report: true,
        merge: true,
        sync: true,
    },
    {
        id: "age_group",
        label: "Age Group",
        required: false,
        default: "",
        type: "string",
        field: "select",
        skip: ["transfer_ownership", "duplicate"],
        import_csv: true,
        report: true,
        merge: true,
        sync: true,
    },
    {
        id: "area_of_interest_string",
        label: "Area of Interest",
        required: false,
        default: "",
        type: "string",
        field: "textarea",
        skip: ["transfer_ownership", "duplicate"],
        search_keywords: true,
        import_csv: true,
        report: true,
        merge: true,
        sync: true,
    },
    {
        id: "dcl_sync_id",
        label: "DCL Sync Connection ID",
        required: false,
        default: "",
        type: "system_data",
        skip: ["transfer_ownership"],
        report: false,
        sync: true,
    },
    {
        id: "dcl_sync_uid",
        label: "DCL Sync User ID",
        required: false,
        default: "",
        type: "system_data",
        skip: ["transfer_ownership"],
        report: false,
        sync: true,
    },
    {
        id: "dcl_sync_date",
        label: "Last DCL Sync Date",
        required: false,
        default: 0,
        type: "timestamp",
        skip: ["transfer_ownership"],
        report: false,
        sync: true,
    },

    {
        id: "assigned_to",
        label: "Assigned To",
        required: ["add", "update"],
        default: [],
        type: "array_ids",
        field: "multiselect",
        report: true,
        sort: true,
        search_keywords: true,
        merge: true,
        sync: true,
        disabled: ["update"],
    },
    {
        id: "created_on",
        label: "Created On",
        required: false,
        default: 0,
        type: "system_date",
        skip: ["update", "transfer_ownership"],
        report: true,
        sync: true,
    },
    {
        id: "modified_on",
        label: "Modified On",
        required: false,
        default: 0,
        type: "system_date",
        report: false,
        sync: true,
    },
];
