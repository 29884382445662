export const quoteSchema = [
    { 
        id: 'id', 
        label: 'Quote Number',
        required: ['update','delete'], 
        default: '', 
        type: 'string', 
        field: 'text',
        disabled: ['update'],
        validation: 'string_id', 
        skip: ['add','transfer_ownership'],
        report: true,
        sort: true,
        search_match: true,
        email_shortcode: true,
        sync: false 
    },
    { 
        id: 'pdf_id', 
        label: 'PDF ID',
        required: false, 
        default: '', 
        type: 'system_data', 
        skip: ['add','transfer_ownership'],
        report: false,
        sync: true 
    },
    {   
        id: 'name',
        label: 'Subject',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'text',
        skip: ['transfer_ownership'],
        report: true,
        sort: true,
        search_keywords: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'stage',
        label: 'Quote Stage',
        required: ['add','update'],
        default: 'created',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        search_filter: true,
        sync: true
    },
    {   
        id: 'potentials_linked',
        label: 'Potential',
        required: false,
        default: [],
        type: 'array_ids',
        field: 'select2',
        skip: ['transfer_ownership'],
        report: true,
        sort: true,
        search_keywords: true,
        sync: true
    },
    {   
        id: 'accounts_linked',
        label: 'Account',
        required: ['add','update'],
        default: [],
        type: 'array_ids',
        field: 'select2',
        skip: ['transfer_ownership'],
        report: true,
        sort: true,
        search_keywords: true,
        sync: true
    },
    {   
        id: 'contacts_linked',
        label: 'Contact',
        required: false,
        default: [],
        type: 'array_ids',
        field: 'select2',
        skip: ['transfer_ownership'],
        report: true,
        sort: true,
        search_keywords: true,
        sync: true
    },
    {   
        id: 'email',
        label: 'Email',
        required: false,
        default: '',
        type: 'email',
        field: 'email',
        skip: ['transfer_ownership'],
        report: true,
        search_match: true,
        search_keywords: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'sales_person',
        label: 'Sales Person',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'select',
        report: true,
        search_filter: true,
        sync: true
    },

    {   
        id: 'reference',
        label: 'Reference',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        skip: ['transfer_ownership'],
        report: true,
        search_keywords: true,
        sync: true
    },
    {   
        id: 'issue_date',
        label: 'issue Date',
        required: ['add','update'],
        default: 0,
        type: 'timestamp',
        field: 'datepicker',
        skip: ['transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'valid_until',
        label: 'Valid Until',
        required: false,
        default: 0,
        type: 'timestamp',
        field: 'datepicker',
        skip: ['transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'payment_terms',
        label: 'Payment Terms',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'company_details',
        label: 'Company',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'billing_address',
        label: 'Billing (address)',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'textarea',
        skip: ['transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'billing_city',
        label: 'Billing (city)',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        skip: ['transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'billing_post_code',
        label: 'Billing (Post Code)',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        skip: ['transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'billing_state',
        label: 'Billing (state)',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'billing_country',
        label: 'Billing (Country)',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: true
    },

    // items
    {   
        id: 'currency',
        label: 'Currency',
        required: ['add','update'],
        default: 'RM',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'currency_conversion_rate',
        label: 'Currency Conversion Rate',
        required: false,
        default: 1,
        type: 'currency',
        field: 'text_number',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'tax_mode',
        label: 'Tax Mode',
        required: ['add','update'],
        default: 'group',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'products_linked',
        label: 'Item(s)',
        required: ['add','update'],
        default: [],
        type: 'array_ids',
        field: 'special',
        skip: ['transfer_ownership'],
        report: false,
        report_hidden: true,
        sort: true,
        search_keywords: true,
        sync: true
    },
    // products for report only
    {   
        id: 'prod_name',
        label: 'Product Name',
        required: false,
        default: '',
        type: 'string',
        skip: ['add','update','transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: false
    },
    {   
        id: 'prod_unit_price',
        label: 'Product Unit Price',
        required: false,
        default: 0,
        type: 'currency',
        skip: ['add','update','transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: false
    },
    {   
        id: 'prod_qty',
        label: 'Product Quantity',
        required: false,
        default: 0,
        type: 'number',
        skip: ['add','update','transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: false
    },
    {   
        id: 'prod_discount_amt',
        label: 'Product Discount Amount',
        required: false,
        default: 0,
        type: 'currency',
        skip: ['add','update','transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: false
    },
    {   
        id: 'prod_net_price',
        label: 'Product Net Price',
        required: false,
        default: 0,
        type: 'currency',
        skip: ['add','update','transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: false
    },
    {   
        id: 'prod_desc',
        label: 'Product Description',
        required: false,
        default: '',
        type: 'string',
        skip: ['add','update','transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: false
    },
    
    {   
        id: 'group_discount_type',
        label: 'Group Discount Type',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'group_discount',
        label: 'Group Discount',
        required: false,
        default: 0,
        type: 'currency',
        field: 'text_number',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'custom_tax_rate',
        label: 'Tax Rate',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        skip: ['transfer_ownership'],
        report: true,
        email_shortcode: false,
        sync: true
    },
    {   
        id: 'calc_sub_total',
        label: 'Sub Total',
        required: false,
        default: 0,
        type: 'currency',
        skip: ['add','update','transfer_ownership'],
        report: true,
        sync: false
    },
    {   
        id: 'calc_total_discount_amount',
        label: 'Total Discount Amount',
        required: false,
        default: 0,
        type: 'currency',
        skip: ['add','update','transfer_ownership'],
        report: true,
        sync: false
    },
    {   
        id: 'calc_pretax_total',
        label: 'Pre Tax Total',
        required: false,
        default: 0,
        type: 'currency',
        skip: ['add','update','transfer_ownership'],
        report: true,
        sync: false
    },
    {   
        id: 'calc_total_tax_amount',
        label: 'Total Tax Amount',
        required: false,
        default: 0,
        type: 'currency',
        skip: ['add','update','transfer_ownership'],
        report: true,
        sync: false
    },
    {   
        id: 'total_adjustment_type',
        label: 'Total Adjustment Type',
        required: false,
        default: 'add',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: false,
        sync: true
    },
    {   
        id: 'total_adjustment',
        label: 'Total Adjustment',
        required: false,
        default: 0,
        type: 'currency',
        field: 'text_number',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'grand_total',
        label: 'Grand Total',
        required: false,
        default: 0,
        type: 'currency',
        field: 'text_number',
        skip: ['transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: true
    },

    {   
        id: 'notes',
        label: 'Notes',
        required: false,
        default: '',
        type: 'string',
        field: 'textarea',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },

    {   
        id: 'assigned_to',
        label: 'Assigned To',
        required: ['add','update'],
        default: [],
        type: 'array_ids',
        field: 'multiselect',
        report: true,
        sort: true,
        sync: true,
        disabled: ['update']
    },
    {   
        id: 'created_on',
        label: 'Created On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: ['update','transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'modified_on',
        label: 'Modified On',
        required: false,
        default: 0,
        type: 'system_date',
        report: false,
        sync: true
    }
];