/* eslint-disable */
import * as firebase from 'firebase/app';
import axios from 'axios';
import shortid from 'shortid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _snakeCase from 'lodash/snakeCase';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';

import { doesUserHaveAccess } from '../../helpers/auth';
import { callFunctionsAPI, doPromise } from '../../helpers/action';
import { isArrayExists, validateEmail, isObjectExists } from '../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema } from '../../helpers/schemas';
import { getItemsFromFirestore, getTotalCountBySchema } from '../../helpers/firestore';
import { cloneCollections } from '../../helpers/data';

import { contactSchema } from '../../schemas/contact';

import { doSingleContactUpdate } from './fb';

import { LMS_CLOUD_API_URL } from '../../constants';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

const getTraineesFromLMS = ({ start_date, end_date }) => {
    return new Promise((resolve,reject) => {

        // get LMS token
        callFunctionsAPI({ url: 'auth', action: 'get_read_token' })
        .then(({ token }) => {
            // call for read API in LMS
            return ( token && !_isEmpty( token ) ? doPromise({
                idToken: token,
                method: 'POST',
                api_url: LMS_CLOUD_API_URL,
                url: 'read',
                params: [{ key: 'action', value: 'get_trainees' }],
                formData: { start_date, end_date }
            }) : false );
        })
        .then(response => {
            // only get trainees with valid email address
            const trainees = ( response && response.data && isArrayExists( response.data ) ? _filter( response.data, (t) => ( t.trainee_email && !_isEmpty( t.trainee_email ) && validateEmail( t.trainee_email ) ? true : false )) : [] );
            resolve(trainees);
        })
        .catch(error => {
            console.log(error);
            reject(error);
        });

    })
}

const getAccountsList = (trainees) => {
    return new Promise((resolve,reject) => {

        const promises = [],
            companies = [],
            accounts = [];

        _forEach( trainees, trainee => {
            if ( trainee && trainee.company_id && !_isEmpty( trainee.company_id ) && !_find( companies, { id: trainee.company_id } ) ) {
                promises.push( firebase.firestore().collection("accounts").where("lms_linked",'==',trainee.company_id).limit(1).get() );
                companies.push({ id: trainee.company_id });
            } // end - trainee.company_id
        });

        if ( isArrayExists( promises ) ) {
            Promise.all( promises )
            .then(results => {
                if ( results && isArrayExists( results ) ) {
                    _forEach( results, result => {
                        if ( result && !result.empty ) {
                            result.forEach(doc => {
                                accounts.push(doc.data());
                            });
                        } // end - result
                    }); // end - results
                } // end - results
                resolve( accounts );
            })
            .catch(error => {
                reject( error );
            });
        } else {
            resolve( accounts );
        } // end - promises

    });
}

const getContactsList = (trainees,accounts) => {
    return new Promise((resolve,reject) => {

        const promises = [];
        let contacts = [];

        _forEach(trainees,trainee => {
            let account = ( trainee && trainee.company_id && !_isEmpty( trainee.company_id ) ? _find( accounts, { lms_linked: trainee.company_id } ) : false );
            if ( account && account.id && !_isEmpty( account.id ) ) {
                promises.push( firebase.firestore().collection("contacts").where("email","==",trainee.trainee_email).where("account_id","==",account.id).limit(30).get() );
            } // end - account
        });

        if ( isArrayExists( promises ) ) {
            Promise.all( promises )
            .then( results => {

                // get all existing contacts
                if ( results && isArrayExists( results ) ) {
                    _forEach( results, result => {
                        if ( result && !result.empty ) {
                            result.forEach(doc => {
                                if ( !_find( contacts, { id: doc.id } ) ) {
                                    let contactData = doc.data();
                                    contactData.unique_id = shortid.generate();
                                    contacts.push(contactData);
                                } // end - doc.id
                            });
                        } // end - result
                    });
                } // end - results

                // create placeholder data for new contacts
                _forEach(trainees,trainee => {
                    let account = ( trainee && trainee.company_id && !_isEmpty( trainee.company_id ) ? _find( accounts, { lms_linked: trainee.company_id } ) : false );
                    if ( account && account.id && !_isEmpty( account.id ) && !_find( contacts, { email: trainee.trainee_email, account_id: account.id } ) ) {
                        contacts.push({ 
                            id: '', 
                            unique_id: shortid.generate(),
                            first_name: '',
                            last_name: ( trainee && trainee.trainee_name && !_isEmpty( trainee.trainee_name ) ? trainee.trainee_name : '' ), 
                            email: trainee.trainee_email,
                            mobile_phone: ( trainee && trainee.trainee_contact && !_isEmpty( trainee.trainee_contact ) ? trainee.trainee_contact : '' ),
                            office_phone: '',
                            other_phone: '',
                            position: ( trainee && trainee.trainee_job_title_dept && !_isEmpty( trainee.trainee_job_title_dept ) ? trainee.trainee_job_title_dept : '' ),
                            account_id: account.id,
                            account_label: ( account && account.name && !_isEmpty( account.name ) ? account.name : '' ),
                            mailing_lists: ( account && account.mailing_lists && isArrayExists( account.mailing_lists ) ? account.mailing_lists : [] ),
                            assigned_to: ( account && account.assigned_to && isArrayExists( account.assigned_to ) ? account.assigned_to : [] )
                        });
                    } // end - contacts
                });

                resolve( contacts );
            })
            .catch(error => {
                reject( error );
            });
        } else {
            resolve( contacts );
        } // end - promises

    })
}

const fakePromise = (contact) => {
    return new Promise((resolve,reject) => {
        
        setTimeout(() => {
            console.log(contact.email);
            // resolve(true);
            // reject({ code:'error', message: 'Forced Error: ' + contact.email });
            if ( contact.email === 'abc-muhamadsobirin.misran@dyson.com' ) {
                console.log('trigger error');
                reject({ code:'error', message: 'Forced Error: ' + contact.email });
            } else {
                resolve(true);
            }
        },3500);

    })
}

const doPartPromises = (contacts) => {
    return new Promise((resolve,reject) => {

        const promises = [];

        // compile promises
        if ( contacts && isArrayExists( contacts ) ) {
           _forEach( contacts, contact => {
                if ( contact ) {
                    if ( contact.id && !_isEmpty( contact.id ) ) {
                        promises.push( callFunctionsAPI({ url: 'contact', action: 'update', formData: contact }) );
                    } else {
                        promises.push( callFunctionsAPI({ url: 'contact', action: 'add', formData: contact }) );
                    } // end - contact.id
                } // end - contact

            //    promises.push( fakePromise(contact) );
           });
        } // end - contacts

        Promise.all( promises )
        .then(() => {
            resolve(true);
        })
        .catch(error => {
            reject(error);
        });

    })
}

/* helpers end */

export const fb_importContactsFromLMS = ({ start_date, end_date }) => {
    return new Promise((resolve,reject) => {

        let trainees = [],
            accounts = [],
            contacts = [];

        getTraineesFromLMS({ start_date, end_date })
        .then((results) => {
            trainees = ( results && isArrayExists( results ) ? cloneCollections( results ) : [] );
            return ( trainees && isArrayExists( trainees ) ? getAccountsList(trainees) : [] );
        })
        .then((results) => {
            accounts = ( results && isArrayExists( results ) ? cloneCollections( results ) : [] );
            return ( trainees && isArrayExists( trainees ) && accounts && isArrayExists( accounts ) ? getContactsList(trainees,accounts) : [] );
        })
        .then(results => {
            contacts = ( results && isArrayExists( results ) ? cloneCollections( results ) : [] );
            resolve({ 
                contacts: _sortBy(contacts,['email']), 
                trainees 
            });
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const doContactsImport = async (contacts) => {

    const list = [];
    const limits = 6;
    let count = 1,
        batch = [];

    if ( contacts && isArrayExists( contacts ) ) {
        _forEach( contacts, contact => {
            batch.push(contact);
            if ( count === limits ) {
                list.push(batch); // add to list
                // then reset
                count = 1; 
                batch = [];
            } else {
                count++;
            } // end - count
        });

        // if batch is not empty - add to list
        if ( batch && isArrayExists( batch ) )
            list.push( batch );

    } // end - contacts

    if ( list && isArrayExists( list ) ) {
        let cIndex = 0;
        while ( cIndex < list.length ) {
            await doPartPromises(list[cIndex])
                .then(() => {
                    cIndex++;
                })
                .catch(error => {
                    let errorMessage = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to import contacts. Please try again.' ) );
                    throw new Error( errorMessage );
                });
        }
    } // end - contacts

    return 'done';
}

export const doContactsImportOriginal = (contacts) => {
    return new Promise((resolve,reject) => {

        // const promises = [];

        // if ( contacts && isArrayExists( contacts ) ) {
        //     _forEach( contacts, contact => {
        //         if ( contact ) {
        //             if ( contact.id && !_isEmpty( contact.id ) ) {
        //                 promises.push( callFunctionsAPI({ url: 'contact', action: 'update', formData: contact }) );
        //             } else {
        //                 promises.push( callFunctionsAPI({ url: 'contact', action: 'add', formData: contact }) );
        //             } // end - contact.id
        //         } // end - contact
        //     });
        // } // end - contacts

        // if ( isArrayExists( promises ) ) {
        //     Promise.all( promises )
        //     .then(results => {
        //         resolve(true);
        //     })
        //     .catch(error => {
        //         reject(error);
        //     })
        // } else {
        //     resolve(false);
        // } // end - promises

    })
}