import React from 'react';
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _split from 'lodash/split';
import _remove from 'lodash/remove';
import _startsWith from 'lodash/startsWith';
import _concat from 'lodash/concat';
import _toString from 'lodash/toString';

/* eslint-disable */

import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormSelect2 from '../../components/FormSelect2';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormDatePicker from '../../components/FormDatePicker';
import FormMultiOptions from '../../components/FormMultiOptions';
// import FormCheckbox from '../../components/FormCheckbox';
import FormRadio from '../../components/FormRadio';

// import { InfoButton, GreyButton } from '../../styles/button';
// import { WrapWord } from '../../styles/misc';
import { FormBox } from '../../styles/form';

import { isAdmin } from '../../helpers/auth';
import { reverseUsersValues } from '../../helpers/users';
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, getSelectValues, calculatePosition } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';

import { emailTemplateSchema } from '../../schemas/email_template';


const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

class Details extends React.Component {

    state = {
        randNum: false
    };

    handleFormUpdate = ( newValue, key ) => {
        const { onFormUpdate, template, categories, users } = this.props;
        let newData = ( template && !_isEmpty( template ) ? cloneCollections( template ) : {} );
        
        switch( key ) {
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }


    getFieldOptions = (field) => {
        const { template, selectables, categories, users } = this.props;
        switch( field.name ) {
            // case 'report_owners':
            //     return getSelectOptions({ list: ( users ? _filter( users, (u) => u.status !== 'disabled' ) : [] ), keys: { value: 'email', label: 'name' }, sortBy: 'label' });
            default:
                return [];
        }
    }

    getFieldValue = (field) => {
        const { template } = this.props;
        switch( field.name ) {
            // case 'report_owners':
            //     return ( template && template[field.name] && isArrayExists( template[field.name] ) ? getSelectValues( template[field.name], 'id' ) : [] );
            default:
                return ( template && template[field.name] || ( field.default || '' ) );
        }
    }

    isFieldDisabled = (schema) => {
        const { authData, template } = this.props;
        let disabled = false;

        if ( schema && schema.disabled && isArrayExists( schema.disabled ) ) {
            schema.disabled.forEach( condition => {
                switch( condition ) {
                    case 'admin':
                        if ( !isAdmin( authData ) )
                            disabled = true;
                        break;
                    case 'update':
                        disabled = true;
                        break;
                }
            });
        } // end - schema

        return disabled
    }

    getField = (id) => {
        let schema = _find( emailTemplateSchema, { id } );
        return ( schema ? {
            name: ( schema.id || '' ),
            label: ( schema.label || '' ) + ( isSchemaRequired(schema,'update') ? ' (Required)' : '' ),
            field_type: ( schema.field || '' ),
            default: ( schema.default || null ),
            disabled: this.isFieldDisabled( schema )
        } : null );
    }

    renderField = (id) => {
        const { template, authData } = this.props;
        let field = this.getField(id);
        if ( field && field.field_type && !_isEmpty( field.field_type ) ) {
            switch( field.field_type ) {
                case 'text':
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'textarea':
                    return <FormInput {...field} multiline={true} rows={3} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'text_number':
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'email':
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'select2':
                    return <FormSelect2 placeholder="Select an Option" {...field} value={this.getFieldValue(field)} isClearable={true} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} />;
                case 'select':
                    return <FormSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} />;
                case 'multiselect':
                    return <div style={{ paddingTop: "7px" }}><FormMultiSelect {...field} columns={12} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} /></div>;
                case 'datepicker':
                    return <FormDatePicker {...field} noDefaultVal={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'radio':
                    return <div style={{ paddingTop: "7px" }}><FormRadio inline={true} {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} /></div>;
            }
        } // end - field.field_type
    }


    renderDetailsBox = () => {
        const { authData, classes, template } = this.props;
        return (
        <FormBox style={{ paddingBottom: "30px" }}>
            <Typography variant="h4" className={classes.boxheading}>Template Settings</Typography>
            <Grid container spacing={3}>

                <Grid item xs={6}>{this.renderField('name')}</Grid>
                <Grid item xs={12}>{this.renderField('desc')}</Grid>
                <Grid item xs={12}>{this.renderField('subject')}</Grid>

            </Grid>
        </FormBox>
        );
    }
   
    render() {
        const { template } = this.props;
        return (
        <div>
            {this.renderDetailsBox()}
            <div style={{ paddingTop: "10px", textAlign: 'right', color: '#999', fontSize: '1.25rem' }}>
                <div>{ template.created_on ? 'Created on ' + getMomentTime( (template.created_on) , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
                <div>{ template.modified_on ? 'Last Modified on ' + getMomentTime( (template.modified_on) , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
            </div>
        </div>
        )
    }

}

export default compose(
    withStyles(useStyles),
    withRouter
)(Details);