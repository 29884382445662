/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_EMAIL_TEMPLATES,
	GET_EMAIL_TEMPLATE,
	ADD_EMAIL_TEMPLATE,
	EDIT_EMAIL_TEMPLATE,
	DELETE_EMAIL_TEMPLATE,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getEmailTemplatesLive, fb_getEmailTemplates, fb_getEmailTemplate, fb_addEmailTemplate, fb_updateEmailTemplate, fb_deleteEmailTemplate } from './fb';

export const getEmailTemplates = (props) => {
	return dispatch => {

		fb_getEmailTemplatesLive(props,list => {
			dispatch({
				type: GET_EMAIL_TEMPLATES,
				payload: { list }
			});
		});

		// fb_getEmailTemplates(props)  
		// .then(list => {
		// 	dispatch({
		// 		type: GET_EMAIL_TEMPLATES,
		// 		payload: { list }
		// 	});
		// })
		// .catch(error => {
		// 	var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
		// 	triggerErrorAlert(errMsg);
		// 	dispatch({
		// 		type: GET_EMAIL_TEMPLATES,
		// 		payload: { list: [] }
		// 	});
		// });

	}
}

export const getEmailTemplate = (email_id) => {
	return dispatch => {

		fb_getEmailTemplate(email_id)  
		.then(template => {
			dispatch({
				type: GET_EMAIL_TEMPLATE,
				payload: { template }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve template' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_EMAIL_TEMPLATE,
				payload: { template: {} }
			});
		});

	}
}

export const addNewEmailTemplate = (formData,history) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addEmailTemplate(formData)
		.then( results => {
			return ( results && results.new_template_id && !_isEmpty( results.new_template_id ) ? fb_getEmailTemplate( results.new_template_id ) : false );
		})
		.then( template => {
			if ( template && template.id && !_isEmpty( template.id ) ) {
				// updated
				dispatch({ type: GET_EMAIL_TEMPLATE, payload: { template } });
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("Email Template Added");

				// redirect to email template page
				history.push("/email_templates/" + template.id);

			} else {
				dispatch(toggleModalProcessing(false,apiNum));
				triggerErrorAlert('Missing new template ID');
			} // end - template
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new email template' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const editEmailTemplate = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateEmailTemplate(formData)
		.then( results => {
			return fb_getEmailTemplate(formData.id);
		})
		.then(template => {
			// push to list
			dispatch({ type: GET_EMAIL_TEMPLATE, payload: { template } });

			// updated
			dispatch({ type: EDIT_EMAIL_TEMPLATE });
			dispatch(toggleLoader(false));
			dispatch(appChangesReset());
			triggerSuccessAlert("Email Template Updated");

		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update email template' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteEmailTemplate = (id,props) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteEmailTemplate(id)
		.then( results => {
			return fb_getEmailTemplates(props)
		})
		.then( list => {
			// send updates
			dispatch({
				type: GET_EMAIL_TEMPLATES,
				payload: { list }
			});

			// trigger updated
			dispatch({ type: DELETE_EMAIL_TEMPLATE });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Email Template Deleted");

		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete email template' ) );
			triggerErrorAlert(errMsg);
		});

	}
}