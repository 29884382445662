import React from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _round from "lodash/round";
import _floor from "lodash/floor";

/* eslint-disable */

import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";

import { isArrayExists, isNumeric } from "../../helpers/validation";
import { triggerErrorAlert } from "../../helpers/alert";
import {
    cloneCollections,
    doPagination,
    doArraySearch,
} from "../../helpers/data";
import { getMomentTime, getMomentTimestamp } from "../../helpers/date";
import { formatMoney, convertToTwoDecimalPoints } from "../../helpers/number";

import { InverseButton } from "../../styles/button";

import { getAccountsOptions } from "../../actions/accounts";

const useStyles = (theme) => ({
    gridwrapper: {
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            backgroundColor: "#fff",
            paddingLeft: "5px",
        },
    },
});

class Calculator extends React.Component {
    state = {
        quantity: 1,
        unit_price: 0,
        discount: 0,
        tax_amount: 0,
        net_amount: 0,
        completed: false,
    };

    handleCalUpdate = () => {
        const { onUpdate, item, type } = this.props;
        const { quantity, unit_price, discount, tax_amount, net_amount } =
            this.state;

        if (quantity && net_amount) {
            let newProducts =
                    item.products_linked && isArrayExists(item.products_linked)
                        ? cloneCollections(item.products_linked)
                        : [],
                index = isArrayExists(newProducts)
                    ? type && type === "grand_total"
                        ? 0
                        : _findIndex(newProducts, { id: type })
                    : -1;
            if (index >= 0) {
                newProducts[index].quantity = quantity;
                newProducts[index].unit_price = unit_price;
                if (discount) {
                    newProducts[index].discount_type = "fixed";
                    newProducts[index].discount = discount;
                } else {
                    newProducts[index].discount_type = "none";
                    newProducts[index].discount = 0;
                }
            }

            // reset everything
            this.setState({
                quantity: 1,
                unit_price: 0,
                discount: 0,
                tax_amount: 0,
                net_amount: 0,
                completed: false,
            });

            // push for updates
            if (onUpdate) {
                onUpdate(newProducts, "products_linked");
            }
        } else {
            this.setState({ completed: false });
            triggerErrorAlert("Please fill out all the required fields");
        }
    };

    handleCalculate = () => {
        const { quantity, unit_price, discount, tax_amount, net_amount } =
            this.state;

        let taxPercentage = 0,
            newDiscount = 0,
            newTaxAmount = 0,
            newUnitPrice = 0,
            newNetAmount = 0;

        if (unit_price && quantity && net_amount) {
            taxPercentage = _round(
                net_amount / (1 + this.getTaxAmount() / 100),
                2
            );
            newTaxAmount = _round(net_amount - taxPercentage, 2);
            newDiscount = _round(
                unit_price * quantity + newTaxAmount - net_amount,
                2
            );
            if (unit_price * quantity + newTaxAmount >= net_amount) {
                this.setState({
                    discount: _round(newDiscount, 2),
                    tax_amount: newTaxAmount,
                    completed: true,
                });
            } else {
                this.setState({ completed: false });
                triggerErrorAlert(
                    "( Quantity * Unit Price + Tax ) must be greater or equal to Net Price"
                );
            }
        } else if (quantity && net_amount) {
            taxPercentage = _round(
                net_amount / (1 + this.getTaxAmount() / 100),
                2
            );
            newTaxAmount = _round(net_amount - taxPercentage, 2);
            newUnitPrice = convertToTwoDecimalPoints(
                (net_amount - newTaxAmount) / quantity,
                2
            );
            // re-calculate tax amount (if quantity is more than 1)
            // if (quantity > 1) {
            //     newTaxAmount =
            //         quantity *
            //         convertToTwoDecimalPoints(
            //             newUnitPrice * (this.getTaxAmount() / 100),
            //             2
            //         );
            //     // console.log("recalTaxAmount", newTaxAmount);
            // }
            newNetAmount = _round(newUnitPrice * quantity + newTaxAmount, 2);
            this.setState({
                net_amount: newNetAmount,
                unit_price: newUnitPrice,
                tax_amount: newTaxAmount,
                discount: 0,
                completed: true,
            });
        } else {
            this.setState({ completed: false });
            triggerErrorAlert("Please fill out all the required fields");
        }
    };

    handleFormUpdate = (newValue, key) => {
        const { quantity, unit_price, discount, tax_amount, net_amount } =
            this.state;
        let newState = {};
        switch (key) {
            case "quantity":
                newState[key] = newValue;
                break;
            case "unit_price":
                newState[key] = newValue;
                break;
            case "net_amount":
                newState[key] = newValue;
                break;
        } // end - key

        if (!_isEmpty(newState)) this.setState(newState);
    };

    handleClose = () => {
        const { onClose } = this.props;

        // reset everything
        this.setState({
            quantity: 1,
            unit_price: 0,
            discount: 0,
            tax_amount: 0,
            net_amount: 0,
            completed: false,
        });

        if (onClose) onClose();
    };

    getSelectedRegion = (type) => {
        const { item, regions, authData } = this.props;
        var selected =
            regions && isArrayExists(regions)
                ? _find(regions, {
                      id:
                          item.company_details &&
                          !_isEmpty(item.company_details)
                              ? item.company_details
                              : (authData && authData.region) || "",
                  })
                : false;
        if (type && !_isEmpty(type)) {
            return (selected && selected[type]) || false;
        } else {
            return selected || false;
        } // end - type
    };

    getTaxAmount = () => {
        const { item, type } = this.props;
        let amt = this.getSelectedRegion("tax_amount");
        if (
            item &&
            item.custom_tax_rate &&
            !_isEmpty(item.custom_tax_rate) &&
            isNumeric(item.custom_tax_rate)
        ) {
            amt = item.custom_tax_rate;
        }
        if (item.tax_mode === "group" && type === "grand_total") {
            return amt ? parseInt(amt, 10) : 0;
        } else if (item.tax_mode === "individual" && type !== "grand_total") {
            let selected_product =
                item.products_linked && isArrayExists(item.products_linked)
                    ? _find(item.products_linked, { id: type })
                    : false;
            if (selected_product && selected_product.no_tax === "no")
                return amt ? parseInt(amt, 10) : 0;
            else return 0;
        } else {
            return 0;
        }
    };

    renderTaxLabel = () => {
        return this.getSelectedRegion("tax_label");
    };

    renderModal = () => {
        const { classes } = this.props;
        const { quantity, unit_price, discount, tax_amount, net_amount } =
            this.state;
        return (
            <div className={classes.gridwrapper}>
                <Grid container spacing={1} align="center">
                    <Grid item xs={2}>
                        <Grid container spacing={1} align="center">
                            <Grid item xs={10}>
                                <FormInput
                                    type="number"
                                    label="Quantity *"
                                    name="quantity"
                                    value={quantity || 0}
                                    onChange={this.handleFormUpdate}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{ lineHeight: "84px" }}>X</div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container spacing={1} align="center">
                            <Grid item xs={10}>
                                <FormInput
                                    type="number"
                                    label="Unit Price"
                                    name="unit_price"
                                    value={unit_price || 0}
                                    onChange={this.handleFormUpdate}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{ lineHeight: "84px" }}>-</div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Grid container spacing={1} align="center">
                            <Grid item xs={10}>
                                <FormInput
                                    label="Discount"
                                    disabled={true}
                                    name="discount"
                                    value={
                                        discount
                                            ? formatMoney(discount, 2, 3)
                                            : "0.00"
                                    }
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{ lineHeight: "84px" }}>+</div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Grid container spacing={1} align="center">
                            <Grid item xs={10}>
                                <FormInput
                                    label={this.renderTaxLabel()}
                                    disabled={true}
                                    name="tax_amount"
                                    value={
                                        tax_amount
                                            ? formatMoney(tax_amount, 2, 3)
                                            : "0.00"
                                    }
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <div style={{ lineHeight: "84px" }}>=</div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container spacing={1} align="center">
                            <Grid item xs={10}>
                                <FormInput
                                    type="number"
                                    label="Net Price *"
                                    name="net_amount"
                                    value={net_amount || 0}
                                    onChange={this.handleFormUpdate}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <InverseButton
                                    style={{ marginTop: "25px" }}
                                    size="small"
                                    minWidth="none"
                                    noIconMargin="yes"
                                    onClick={this.handleCalculate}
                                >
                                    <i className="fa fa-calculator"></i>
                                </InverseButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    };

    render() {
        const { open } = this.props;
        const { completed } = this.state;
        return (
            <ModalView
                open={open}
                title="Calculator"
                noAction={completed ? false : true}
                actionLabel="Apply"
                maxWidth="md"
                cancelLabel="Close"
                doAction={this.handleCalUpdate}
                onClose={this.handleClose}
                contents={open ? this.renderModal() : null}
            />
        );
    }
}

export default compose(connect(), withStyles(useStyles))(Calculator);
