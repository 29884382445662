/* eslint-disable */
import * as firebase from 'firebase/app';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

/* helper end */

export const fb_getInvoiceType = (callback) => {

    firebase.database()
    .ref("maintenance/invoice_type")
    .on("value", snapshot => {

        var list = [];

        if ( snapshot.exists() && snapshot.hasChildren() ) {
            snapshot.forEach( childSnapshot => {
                var val = childSnapshot.val(),
                    item = {
                        id: childSnapshot.key,
                        label: val.label || '',
                        status: val.status || 'disabled',
                        regions: []
                    };

                if ( childSnapshot.child("regions") && childSnapshot.child("regions").hasChildren() ) {
                    childSnapshot.child("regions").forEach( regionSnapshot => {
                        item.regions.push({
                            id: regionSnapshot.key,
                        });
                    });
                }

                list.push(item);
            });
        } // end - snapshpt\
        
        callback(list);

    });
    
}

export const fb_addInvoiceType = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'mInvoiceType', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateInvoiceType = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'mInvoiceType', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteInvoiceType = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'mInvoiceType', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}