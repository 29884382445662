import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Alert from "react-s-alert";
import { Editor } from '@tinymce/tinymce-react';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _last from 'lodash/last';
import _random from 'lodash/random';

/* eslint-disable */

import './editor_mce.css';

import DotsLoader from '../../components/DotsLoader';

import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerSuccessAlert, triggerErrorAlert } from '../../helpers/alert';

// import { getEmailTemplate } from '../../actions/emailTemplates';
import { fb_uploadNewMedia } from '../../actions/media_library/fb';
import { appChangesMade } from '../../actions/misc';

// import { FIREBASE_CLOUD_API } from '../../constants';

const filePickerCallBack = (authData) => ( callback, value, meta ) => {
    var input = document.getElementById('editor-mce-file-upload-field');

    input.click();
    input.onchange = function () {
        const file = input.files[0];
        Alert.success('<span class="app-alert text-center content-block"><i class="fa fa-circle-o-notch fa-spin"></i>Uploading image - Please do not click on anything, browser back button, refresh or close this page!</span>', { position: 'top', effect: 'flip', beep: false, timeout: 'none', offset: 70 });

        fb_uploadNewMedia(file, authData)
        .then(function(link) {
            Alert.closeAll();
            triggerSuccessAlert('Upload Done!');
            callback(link);
        })
        .catch(function(error) {
            Alert.closeAll();
            triggerErrorAlert(error.data.error);
        });

    };
}

class MCEEditor extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    componentWillUnmount() {

    }

    setComponents = () => {
           
    }

    handleEditorChange = (content, editor) => {
        const { onEditorUpdate } = this.props;
        if ( onEditorUpdate )
            onEditorUpdate(( content && !_isEmpty( content ) ? content : '' ),'contents_html');
    }

    render() {
        const { template, isPreview, randNum, authData } = this.props;
        return (
        <>
            <Editor
                id={"mce-editor-" + ( randNum || _random(1,9999) )}
                disabled={( isPreview ? true : false )}
                value={( template && template.contents_html && !_isEmpty( template.contents_html ) ? template.contents_html : '' )}
                init={{
                    height: 800,
                    plugins: [
                            'advlist autolink lists link image charmap preview hr anchor',
                            'searchreplace visualblocks visualchars code',
                            'insertdatetime media nonbreaking save table directionality',
                            'paste textpattern imagetools toc'
                        ],
                    toolbar1: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
                    toolbar2: 'preview media | forecolor backcolor',
                    file_picker_types: 'image',
                    file_picker_callback: filePickerCallBack(authData)
                }}
                // tinymceScriptSrc='/assets/js/tinymce/tinymce.min.js'
                onEditorChange={this.handleEditorChange} />
            <input id={'editor-mce-file-upload-field'} type="file" name={'editor-mce-file_upload_field'} style={{ display: "none" }} />
        </>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(MCEEditor);