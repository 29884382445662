import _random from 'lodash/random';

import { 
    GET_INVOICES,
    GET_INVOICES_BY,
	GET_INVOICE,
	ADD_INVOICE,
	EDIT_INVOICE,
    DELETE_INVOICE,
    RESET_INVOICE,
    RESET_INVOICES,
    RESET_GET_INVOICES_BY
} from '../actions/types';

const initialState = {
    invoices: null,
    total: null,
    firstDoc: null,
    lastDoc: null,
    invoices_by: null,
    invoice: null,
    new_invoice_id: null,
    updated: null,
    by_rand: false,
    rand: false
};

export const invoices = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_INVOICES:
            return {
                ...state,
                invoices: ( payload.list || [] ),
                total: ( payload.total || null ),
                firstDoc: ( payload.firstDoc || null ),
                lastDoc: ( payload.lastDoc || null ),
                rand: _random(1,9999)
            };
        case GET_INVOICES_BY:
            return {
                ...state,
                invoices_by: ( payload.list || [] ),
                by_rand: _random(1,9999)
            };
        case GET_INVOICE:
            return {
                ...state,
                invoice: ( payload.invoice || {} ),
                rand: _random(1,9999)
            };
        case ADD_INVOICE:
            return {
                ...state,
                new_invoice_id: ( payload.id || false ),
                rand: _random(1,9999)
            };
        case EDIT_INVOICE:
        case DELETE_INVOICE:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_INVOICE:
            return {
                ...state,
                invoice: null,
                updated: null,
                rand: false
            };
        case RESET_INVOICES:
            return {
                ...state,
                invoices: null,
                total: null,
                firstDoc: null,
                lastDoc: null,
                rand: false
            };
        case RESET_GET_INVOICES_BY:
            return {
                ...state,
                invoices_by: null,
                by_rand: false
            };    
        default:
            return state;
    }
}