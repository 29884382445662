/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _random from 'lodash/random';
import _isEqual from 'lodash/isEqual';

import ModalView from '../ModalView';
import Table from '../Table';
import Pagination from '../Pagination';
import TableBar from '../TableBar';
import DotsLoader from '../DotsLoader';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { formatMoney } from '../../helpers/number';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';

import { getContactsOptions, updateContactsOptionsRand } from '../../actions/contacts';

class ModalSelectContacts extends React.Component {

    state = {
        searchterms: '',
        filterBy: 'all',
        sortBy: 'name-asc',
        perPage: 20,
        page: 1,
        randNum: false
    };

    componentDidMount() {
        this.dispatchOptions({ selected_accounts: false, predefined_account: false });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( !_isEqual( prevProps.selected_accounts, this.props.selected_accounts ) || 
            !_isEqual( prevProps.predefined_account, this.props.predefined_account ) || 
            !_isEqual( prevProps.contactsOptions, this.props.contactsOptions ) || 
            !_isEqual( prevProps.authData, this.props.authData ) || 
            !_isEqual( prevProps.randNum, this.props.randNum ) ) {
            this.dispatchOptions(prevProps);
        }
    }

    dispatchOptions = (prevProps) => {
        const { contactsOptions, authData, randNum } = this.props;

        // only perform load if account changed
        if ( authData && this.detectAccountChanged(prevProps) ) {
            this.props.dispatch(updateContactsOptionsRand(null));
            this.props.dispatch(getContactsOptions({ account_id: this.getSelectedAccountID(this.props), authData }));
        } else if ( 
                ( contactsOptions && randNum && randNum != this.state.randNum ) || 
                ( randNum && randNum != this.state.randNum ) 
            ) { 
            this.setState({ randNum });
        } else if ( !randNum && !this.state.randNum ) {
            this.props.dispatch(updateContactsOptionsRand(_random(10000,99999)));
        } // end - contactsOptions

    }

    detectAccountChanged = (prevProps) => {
        let changed = false;
        if ( prevProps ) {
            let prevAccountID = this.getSelectedAccountID(prevProps),
                currentAccountID = this.getSelectedAccountID(this.props);
            if ( prevAccountID != currentAccountID )
                changed = true;
        } // end - prevProps
        return changed
    }

    getSelectedAccountID = (props) => {
        const { selected_accounts, predefined_account } = props;
        var account_id = false;
        // if predefined_account exists - use predefined_account.id
        if ( predefined_account && predefined_account.id && !_isEmpty( predefined_account.id ) ) {
            account_id = predefined_account.id;
        } else {
            // else run through all the selected_accounts
            if ( selected_accounts && isArrayExists( selected_accounts ) ) {
                _forEach( selected_accounts, o => {
                    if ( o.id && !_isEmpty( o.id ) )
                     account_id = o.id;
                });
            } // end - selected_accounts
        } // end - predefined_account

        return account_id;
    }

    handleSelect = (option) => {
        const { onSelect } = this.props;
        // trigger option select
        if ( onSelect )
            onSelect(option);
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose )
            onClose();
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { contactsOptions, selected_accounts, predefined_account } = this.props;
        let items = ( contactsOptions ? cloneCollections( contactsOptions ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','account_label','assigned_to.name'] );
			total = _size( items );
        } // end - searchterms

        // do custom filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy != 'all' ) {
            // items = _filter( items, { org: filterBy });
            // total = _size( items );
        }

        // if predefined_account exists - then filter it to just predefined_account.id
        if ( predefined_account && predefined_account.id && !_isEmpty( predefined_account.id ) ) {
            items = _filter( items, { account_id: predefined_account.id });
            total = _size( items );
        } else {
            // else run through all the selected_accounts
            if ( selected_accounts && isArrayExists( selected_accounts ) ) {
                items = _filter( items, (o) => ( o.account_id && !_isEmpty( o.account_id ) && _find( selected_accounts, { id: o.account_id }) ? true : false ));
                total = _size( items );
            } // end - selected_accounts
        } // end - predefined_account

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'name-desc':
                    items = _sortBy( items, [(i) => i.name.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, [(i) => i.name.toLowerCase()] );
                    break;
                case 'account_label-desc':
                    items = _sortBy( items, [(i) => i.account_label.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'account_label-asc':
                    items = _sortBy( items, [(i) => i.account_label.toLowerCase()] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                total={totalCount}
                perPage={perPage} 
                page={page}
                doneLoaded={true}
                style={{ marginTop: "20px" }}
                onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderTableActions = () => {
        const { sortBy, perPage, searchterms } = this.state;
        return <TableBar
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' },
                    { value: 'account_label-asc', label: 'Account Name ( A - Z)' },
                    { value: 'account_label-desc', label: 'Account Name ( Z - A )' }
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderAssignedTo = (item) => {
        return <div>{( item.assigned_to && !_isEmpty( item.assigned_to ) ? _map( item.assigned_to, (u) => {
            return <div key={u.id}>{( u.name || '' )}</div>
        }) : '---' )}</div>;
    }

    renderTable = (items) => {
        return <Table 
            items={items}
            showCheckbox={false}
            cells={[
                { id: 'name', label: 'Name', render: (item) => ( item.name || '' ) },
                { id: 'account_label', label: 'Account', render: (item) => ( item.account_label || '' ) },
                { id: 'assigned_to', label: 'Assigned To', render: this.renderAssignedTo }
            ]}
            actionStyles={{ width: "10%" }}
            actions={(item) => (
                <ButtonGroup>
                    <InfoButton key="select" size="small" onClick={this.handleSelect.bind(this,item)}><i className="fa fa-edit"></i>Select</InfoButton>
                </ButtonGroup>
            )} />
    }

    renderModal = () => {
        const { randNum } = this.state;
        const { items, total } = this.reorganizeData();
        return !randNum ? <DotsLoader /> : (
        <div>

            {this.renderTableActions()}
            {this.renderTable(items)}
            {this.renderPagination(total)}
            
        </div>   
        )
    }

    render() {
        const { open } = this.props;
        return <ModalView 
                open={open}
                title="Select Contact"
                noAction={true}
                actionLabel="Select"
                maxWidth="lg"
                cancelLabel="Close"
                onClose={this.handleClose}
                contents={ open ? this.renderModal() : null } />
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        contactsOptions: state.contacts && state.contacts.contacts_options || null,
        randNum: state.contacts && state.contacts.options_rand || null
    }
}

export default compose(
    connect(mapStateToProps)
)(ModalSelectContacts);