/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import moment from 'moment';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _split from 'lodash/split';

import FormSelect from '../FormSelect';
import FormDatePicker from '../../components/FormDatePicker';
import PopperBox from '../Popper';

import { InfoButton, GreyButton, ButtonGroup, InverseButton } from '../../styles/button';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { getSelectedSchemas } from '../../helpers/schemas';
import { getMomentTimestamp } from '../../helpers/date';
import { getSearchKey, getRawSearchKeywords } from '../../helpers/firestore';

import theme from '../../theme';

const useStyles = theme => ({
    selector: {
        
    }
});

class SearchFilterFireStore extends React.Component {

    state = {
        open: false,
        anchor: {},
        keywords: '',
        search_key: ''
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    handleSearchChange = () => {
        const { keywords, search_key } = this.state;
        if ( keywords && !_isEmpty( keywords ) && search_key && !_isEmpty( search_key ) ) {
            if ( this.props.onSearchChange ) {
                this.props.onSearchChange( search_key+'_|_'+keywords );
                this.setState({ keywords: '', search_key: '', open: false, anchor: {} });
            }
        } else {
            triggerErrorAlert("Please fill out all the fields.");
        }
    }

    handleSearchReset = (event) => {
        event.preventDefault();
        this.setState({ keywords: '', search_key: '', open: false, anchor: {} });
        if ( this.props.onSearchChange )
            this.props.onSearchChange('');
    }

    handleDateChange = (newValue,key) => {
        const { keywords, anchor } = this.state;
        let splitDate = ( keywords && !_isEmpty( keywords ) ? _split( keywords, ', ' ) : false ),
            newDate = '',
            newValueInLabel = moment(newValue).utcOffset(8).startOf('date').format('YYYY-MM-DD');

        if ( key === 'end_date' ) {
            // if no start date, use end date as start date
            var startDateTimestamp = ( splitDate && splitDate[0] && !_isEmpty( splitDate[0] ) ? moment(splitDate[0]).utcOffset(8).startOf('date').valueOf() : this.getDefaultDateTimestamp('start_date') );
            newDate = ( startDateTimestamp && startDateTimestamp < newValue ? moment(startDateTimestamp).utcOffset(8).startOf('date').format('YYYY-MM-DD') : newValueInLabel ) + ', ' + newValueInLabel;
        } else {
            // if no end date, use start date as end date
            var endDateTimestamp = ( splitDate && splitDate[1] && !_isEmpty( splitDate[1] ) ? moment(splitDate[1]).utcOffset(8).endOf('date').valueOf() : this.getDefaultDateTimestamp('end_date') );
            newDate = newValueInLabel + ', ' + ( endDateTimestamp && endDateTimestamp > newValue ? moment(endDateTimestamp).utcOffset(8).endOf('date').format('YYYY-MM-DD') : newValueInLabel );
        } // end - key
        this.setState({ keywords: newDate });
    }

    isSearchFilter = (id) => {
        const { mod } = this.props;
        var schemas = getSelectedSchemas(mod),
            schema = _find(schemas, { id });
        return ( schema && schema.search_filter ? true : false );
    }

    isSearchMatch = (id) => {
        const { mod } = this.props;
        var schemas = getSelectedSchemas(mod),
            schema = _find(schemas, { id });
        return ( schema && schema.search_match ? true : false );
    }

    isSearchDate = (id) => {
        const { mod } = this.props;
        var schemas = getSelectedSchemas(mod),
            schema = _find(schemas, { id });
        return ( schema && schema.search_date ? true : false );
    }

    getDefaultKeywords = (search_key) => {
        let keywords = '';
        if ( this.isSearchDate( search_key ) ) {
            keywords = this.getDefaultDate(search_key,'start_date') + ', ' + this.getDefaultDate(search_key,'end_date');
        }
        return keywords;
    }

    getDefaultDate = (search_key,type) => {
        const { dateOptions } = this.props;
        var selected = ( dateOptions && isArrayExists( dateOptions ) && search_key && !_isEmpty( search_key ) ? _find( dateOptions , { id: search_key } ) : false );
        if ( type && type === 'end_date' ) {
            return ( selected && selected[type] ? moment(selected[type]).utcOffset(8).endOf('date').format('YYYY-MM-DD') : moment(selected[type]).utcOffset(8).endOf('date').format('YYYY-MM-DD') );
        } else {
            return ( selected && selected[type] ? moment(selected[type]).utcOffset(8).startOf('date').format('YYYY-MM-DD') : moment(selected[type]).utcOffset(8).startOf('date').format('YYYY-MM-DD') );
        }
    }

    getDefaultDateTimestamp = (key) => {
        const { dateOptions } = this.props;
        const { search_key } = this.state;
        var selected = ( dateOptions && isArrayExists( dateOptions ) && search_key && !_isEmpty( search_key ) ? _find( dateOptions , { id: search_key } ) : false );
        return ( selected && selected[key] ? selected[key] : 0 );
    }

    getSearchOptions = () => {
        const { searchOptions } = this.props;
        const { search_key } = this.state;
        var selected = ( searchOptions && isArrayExists( searchOptions ) && search_key && !_isEmpty( search_key ) ? _find( searchOptions , { id: search_key } ) : false );
        return ( selected && selected.options && isArrayExists( selected.options ) ? selected.options : [] );
    }

    getSearchDateValue = (key) => {
        const { keywords } = this.state;
        let splitDate = ( keywords && !_isEmpty( keywords ) ? _split( keywords, ', ' ) : false ),
            startTimestamp = ( splitDate[0] && !_isEmpty( splitDate[0] ) ? getMomentTimestamp( splitDate[0] ) : this.getDefaultDateTimestamp('start_date') ),
            endTimestamp = ( splitDate[1] && !_isEmpty( splitDate[1] ) ? getMomentTimestamp( splitDate[1] ) : this.getDefaultDateTimestamp('end_date') );
        return ( key && key === 'end_date' ? endTimestamp : startTimestamp );
    }

    renderKeywordTags = () => {
        const { searchterms, searchOptions } = this.props;
        var search_key = getSearchKey(searchterms),
            keywords = getRawSearchKeywords(searchterms),
            search = ( searchOptions && isArrayExists( searchOptions ) && search_key && !_isEmpty( search_key ) ? _find( searchOptions , { id: search_key } ) : false ),
            option = ( this.isSearchFilter(search_key) && search && search.options && isArrayExists( search.options ) ? _find( search.options, { value: keywords } ) : false );
        return ( option && option.label && !_isEmpty( option.label ) ? option.label : keywords );
    }

    renderSearchResults = () => {
        const { searchterms } = this.props;
        return (
        <div style={{ padding: '10px', border: '2px solid #999' }}>
            <div style={{ marginBottom: '5px' }}>
                <GreyButton style={{ padding: "5px 15px", borderRadius: "15px" , textTransform: 'none' }} size="small" onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "7px" }}></i>{this.renderKeywordTags()}</GreyButton>
            </div>
            <ButtonGroup>
                <InfoButton style={{ padding: "5px 10px" }} size="small" onClick={(event) => this.setState({ open: true, anchor: event.currentTarget, keywords: getRawSearchKeywords(searchterms), search_key: getSearchKey(searchterms) })}><i className="fa fa-edit"></i>Edit</InfoButton>
                <InverseButton style={{ padding: "5px 10px" }} size="small" onClick={this.handleSearchReset}><i className="fa fa-refresh"></i>Reset</InverseButton>
            </ButtonGroup>
        </div>
        )
    }

    renderSearchButton = () => {
        const { open } = this.state;
        return <InfoButton style={{ padding: "15px", backgroundColor: ( open ? '#212121' : null ) }} noIconMargin="yes" onClick={(event) => this.setState({ open: !open, anchor: event.currentTarget })}><i className={ open ? "fa fa-times" : "fa fa-search"}></i></InfoButton>;
    }

    renderSearchOptions = (mod) => {
        var options = [],
            schemas = getSelectedSchemas(mod);
        return ( schemas && isArrayExists( schemas ) ? _map( schemas, schema => {
            if ( schema.search_match )
                return <option key={schema.id} value={schema.id}>{( schema.label || '' )}</option>
            else if ( schema.search_keywords )
                return <option key={schema.id} value={schema.id+'_srch'}>{( schema.label || '' )}</option>
            else if ( schema.search_filter || schema.search_date )
                return <option key={schema.id} value={schema.id}>{( schema.label || '' )}</option>
        }) : null );
    }

    renderColumnSelector = () => {
        const { mod } = this.props;
        const { search_key } = this.state;
        return (
        <FormControl variant="outlined" fullWidth={true} style={{ background: "#fff" }}>
            <InputLabel shrink={true} style={{ background: "#fff", padding: "5px", color: theme.palette.background }}>Search By</InputLabel>
            <Select native value={search_key} 
                onChange={(event) => this.setState({ search_key: event.target.value, keywords: this.getDefaultKeywords(event.target.value) })}
                input={
                    <OutlinedInput name="perpage" />
                } >
                <option value=''>Select an Option</option>
                {this.renderSearchOptions(mod)}
            </Select>
        </FormControl>
        )
    }

    renderSearchSelector = () => {
        const { keywords } = this.state;
        return (
        <FormControl variant="outlined" fullWidth={true} style={{ background: "#fff", margin: "5px 0px" }}>
            <Select native value={keywords} 
                onChange={(event) => this.setState({ keywords: event.target.value })}
                input={
                    <OutlinedInput name="perpage" />
                } >
                <option value=''>Select an Option</option>
                {_map( this.getSearchOptions(), option => {
                    return <option key={option.value} value={option.value}>{( option.label || '' )}</option>
                })}
            </Select>
        </FormControl>
        )
    }

    renderDateSelector = () => {
        const { keywords } = this.state;
        return (
        <Grid container spacing={0}>
            <Grid item xs={12} sm={12}>
                <FormDatePicker label="Start Date" name="start_date" value={this.getSearchDateValue('start_date')} onChange={this.handleDateChange} />
            </Grid>
            <Grid item xs={12} sm={12}>
                <FormDatePicker label="End Date" name="end_date" value={this.getSearchDateValue('end_date')} onChange={this.handleDateChange} />
            </Grid>
        </Grid>
        )
    }

    renderSearchField = () => {
        const { keywords, search_key } = this.state;
        return (
        <div>
            <TextField
                label={ this.isSearchMatch(search_key) ? "Enter value" : "Enter keyword(s)" }
                value={( keywords || '' )}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                    shrink: true
                }}
                onChange={(event) => this.setState({ keywords: event.target.value })}
                style={{ background: "#fff", width: '100%' }} />
            { this.isSearchMatch(search_key) ? <div style={{ fontSize: "1.15rem", fontStyle: 'italic' }}>*Value entered must be an exact match</div> : null }
        </div>
        )
    }

    renderSearchInput = () => {
        const { search_key } = this.state;
        if ( this.isSearchFilter( search_key ) )
            return this.renderSearchSelector();
        else if ( this.isSearchDate( search_key ) )
            return this.renderDateSelector();
        else 
            return this.renderSearchField();
    }

    renderSearchBox = () => {
        const { searchterms } = this.props;
        const { search_key } = this.state;
        return (
        <div style={{ padding: '20px' }}>
            {this.renderColumnSelector()}
            {this.renderSearchInput()}
            <div style={{ textAlign: 'right', marginTop: '0px' }}>
                <InfoButton style={{ padding: "5px 15px" }} onClick={this.handleSearchChange}>Search</InfoButton>
            </div>
        </div>
        )
    }

    renderSearch = () => {
        const { searchterms } = this.props;
        return ( searchterms && !_isEmpty( searchterms) ? this.renderSearchResults() : this.renderSearchButton() );
    }

    render() {
        const { open, anchor } = this.state;
        return (
        <div>

            {this.renderSearch()}

            <PopperBox 
                zIndex="999"
                open={open}
                placement="bottom-start"
                anchorEl={anchor}
                // disableClickAway={true}
                //onClose={() => this.setState({ open: false, anchor: {} })}
                wrapperStyle={{ paddingTop: "10px" }}
                containerStyle={{ width: "350px" }}
                contents={this.renderSearchBox()} />
            
        </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles)
)(SearchFilterFireStore);