/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { withStyles } from "@material-ui/core/styles";
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _remove from 'lodash/remove';

import MediaTable from './table';
import DotsLoader from '../DotsLoader';

import { InfoButton } from '../../styles/button';
import { GreyTag, InfoTag } from '../../styles/tag';

import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
// import { isAdmin } from '../../helpers/auth';
import { getMomentTime } from '../../helpers/date';

import { getAllMedia, uploadNewMedia, deleteMedia } from '../../actions/media_library';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class MediaLibrary extends React.Component {

    state = {
        open: false,
        randNum: false
    };

    componentDidMount() {
        const { mediaList, authData } = this.props;

        if ( !mediaList ) {
            this.props.dispatch(getAllMedia(authData));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { mediaList, randNum } = this.props;
        if ( mediaList && randNum && randNum !== this.state.randNum ) {
            this.setState({ randNum });
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    onFileAttached = (medias) => {
        const { onAttachmentUpdate, attachments } = this.props;
        const newAttachments = ( attachments && isArrayExists( attachments ) ? cloneCollections( attachments ) : [] );
        if ( medias && isArrayExists( medias ) ) {
            _forEach( medias, file => {
                if ( !_find( newAttachments, { id: file.id } ) ) {
                    newAttachments.push(file);
                } // end - newAttachments
            });
        } // end - medias
        if ( onAttachmentUpdate ) {
            onAttachmentUpdate(newAttachments);
            this.setState({ open: false });
        }
    }

    onFileDettached = (file) => event => {
        const { onAttachmentUpdate, attachments } = this.props;
        const newAttachments = ( attachments && isArrayExists( attachments ) ? cloneCollections( attachments ) : [] );
        const pulled = _remove( newAttachments, { id: file.id });
        if ( onAttachmentUpdate ) {
            onAttachmentUpdate(newAttachments);
            this.setState({ open: false });
        }
    }

    onFileUpload = (files) => {
        const { authData } = this.props;
        this.props.dispatch(uploadNewMedia(files,authData));
    }

    onFileDelete = (medias) => {
        const { mediaList } = this.props;
        var list = ( mediaList && isArrayExists( mediaList ) ? _filter( mediaList, (i) => ( _find( medias, (m) => m === i.id ) ) ) : false );
        if ( list )
            this.props.dispatch(deleteMedia(list));
    }

    renderAttachments = () => {
        const { attachments, readOnly } = this.props;
        return (
        <Grid item xs={6}>
            <Typography variant="h6" style={{ paddingBottom: "8px" }}>Attachments:</Typography>
            { attachments && isArrayExists( attachments ) ? _map( attachments, file => {
                return (
                <InfoTag key={file.id} id={file.id} style={{ margin: '2px 4px', textTransform: 'none' }}>
                    {( file.name || '' )}
                    { readOnly ? null : <i className="fa fa-remove" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={this.onFileDettached(file)}></i> }
                </InfoTag>
                )
            }) : <GreyTag>None</GreyTag> }
        </Grid>
        )
    }

    renderAppBar = () => {
        return (
        <AppBar>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                    <i className="fa fa-remove"></i>
                </IconButton>
                <Typography variant="h6" style={{ paddingLeft: "15px" }}>Media Library</Typography>
            </Toolbar>
        </AppBar>
        )
    }

    render() {
        const { mediaList, attachable, readOnly, extraButtons } = this.props;
        const { open, randNum } = this.state;
        return (
        <div>

            <Grid container spacing={( attachable ? 1 : 0 )} alignItems="center">
                { attachable ? this.renderAttachments() : null }
                <Grid item xs={( attachable ? 6 : 12 )}>
                    <div style={{ textAlign: "right" }}>
                        <InfoButton style={{ padding: "10px 45px", borderRadius: "45px" }} disabled={( readOnly ? 'yes' : false )} onClick={() => this.setState({ open: true })}><i className="fa fa-folder-open"></i>Open Media Library</InfoButton>
                        { extraButtons ? extraButtons : null }
                    </div>
                </Grid>
            </Grid>

            <Dialog scroll="body" fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
                {this.renderAppBar()}
                <div style={{ width: "100%", minHeight: "100vh", padding: "96px 30px 30px 30px", background: "#fff" }}>
                    { randNum ? (
                        <MediaTable 
                        list={( mediaList || [] )}
                        attachable={( attachable || false )}
                        onFileAttached={this.onFileAttached}
                        onFileUpload={this.onFileUpload}
                        onFileDelete={this.onFileDelete} />
                    ) : (
                        <DotsLoader />
                    ) }
                </div>
            </Dialog>

        </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        mediaList: state.media_library && state.media_library.list || null,
        randNum: state.media_library && state.media_library.rand || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles)
)(MediaLibrary);