export const potentialSchema = [
    { 
        id: 'id', 
        label: 'ID',
        required: ['update','delete'], 
        default: '', 
        type: 'string', 
        validation: 'string_id', 
        skip: ['add','transfer_ownership'],
        report: true,
        sort: true,
        search_match: true,
        sync: false 
    },
    {   
        id: 'name',
        label: 'Potential Deal',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'text',
        skip: ['transfer_ownership'],
        report: true,
        sort: true,
        search_keywords: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'product_id',
        label: 'Product',
        required: false,
        default: '',
        type: 'string',
        field: 'select2',
        skip: ['transfer_ownership'],
        report: false,
        sync: true,
        //disabled: ['update']
    },
    {   
        id: 'product_label',
        label: 'Product Name',
        required: false,
        default: '',
        type: 'system_data',
        skip: ['transfer_ownership'],
        report: true,
        sort: true,
        search_keywords: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'accounts_linked',
        label: 'Account',
        report_label: 'Account Name',
        required: ['add','update'],
        default: [],
        type: 'array_ids',
        field: 'select2',
        skip: ['transfer_ownership'],
        report: true,
        sort: true,
        search_keywords: true,
        sync: true
    },
    {   
        id: 'contacts_linked',
        label: 'Contact',
        report_label: 'Contact Name',
        required: false,
        default: [],
        type: 'array_ids',
        field: 'select2',
        skip: ['transfer_ownership'],
        report: true,
        sort: true,
        search_keywords: true,
        sync: true
    },
    {   
        id: 'email',
        label: 'Potential Email',
        required: false,
        default: '',
        type: 'email',
        field: 'email',
        skip: ['transfer_ownership'],
        report: true,
        search_match: true,
        search_keywords: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'expected_close_date',
        label: 'Expected Close Date',
        required: false,
        default: 0,
        type: 'timestamp',
        field: 'datepicker',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'sales_stage',
        label: 'Sales Stage',
        required: ['update'],
        default: '',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        search_filter: true,
        sync: true
    },
    {   
        id: 'num_of_pax',
        label: 'Number of Pax',
        required: false,
        default: 0,
        type: 'number',
        field: 'text_number',
        skip: ['transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'probability',
        label: 'Probability',
        required: false,
        default: 0,
        type: 'number',
        field: 'text_number',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'amount',
        label: 'Amount',
        required: false,
        default: 0,
        type: 'currency',
        field: 'text_number',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'type',
        label: 'Type',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'class_type',
        label: 'Class Type',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'sales_person',
        label: 'Sales Person',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        report: true,
        sync: true
    },

    {   
        id: 'notes',
        label: 'Notes',
        required: false,
        default: '',
        type: 'string',
        field: 'textarea',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },

    {   
        id: 'assigned_to',
        label: 'Assigned To',
        required: ['add','update'],
        default: [],
        type: 'array_ids',
        field: 'multiselect',
        report: true,
        sort: true,
        search_keywords: true,
        sync: true,
        disabled: ['update']
    },
    {   
        id: 'created_on',
        label: 'Created On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: ['update','transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'modified_on',
        label: 'Modified On',
        required: false,
        default: 0,
        type: 'system_date',
        report: false,
        sync: true
    }
];