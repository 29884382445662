import _random from 'lodash/random';

import { 
    GET_CONTACTS,
    GET_CONTACTS_BY,
    GET_CONTACTS_OPTIONS,
    UPDATE_CONTACTS_OPTIONS_RAND,
	GET_CONTACT,
	ADD_CONTACT,
	EDIT_CONTACT,
    DELETE_CONTACT,
    RESET_CONTACT,
    RESET_CONTACTS,
    RESET_GET_CONTACTS_BY,
    IMPORT_CONTACTS_FROM_LMS,
    RESET_IMPORT_CONTACTS_FROM_LMS,
    IMPORT_CONTACTS_FROM_CSV,
    RESET_IMPORT_CONTACTS_FROM_CSV
} from '../actions/types';

const initialState = {
    contacts: null,
    total: null,
    firstDoc: null,
    lastDoc: null,
    contacts_loaded: false,
    contacts_by: null,
    contact: null,
    new_contact_id: null,
    updated: null,
    by_rand: false,
    rand: false
};

export const contacts = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_CONTACTS:
            return {
                ...state,
                contacts: ( payload.list || [] ),
                total: ( payload.total || null ),
                firstDoc: ( payload.firstDoc || null ),
                lastDoc: ( payload.lastDoc || null ),
                contacts_loaded: true, // fix this to true - change it if needed
                rand: _random(1,9999)
            };
        case GET_CONTACTS_BY:
            return {
                ...state,
                contacts_by: ( payload.list || [] ),
                by_rand: _random(1,9999)
            }; 
        case GET_CONTACTS_OPTIONS:
            return {
                ...state,
                contacts_options: ( payload.list || [] ),
                options_rand: _random(1,9999)
            }; 
        case UPDATE_CONTACTS_OPTIONS_RAND:
            return {
                ...state,
                options_rand: payload.rand
            }; 
        case GET_CONTACT:
            return {
                ...state,
                contact: ( payload.contact || {} ),
                rand: _random(1,9999)
            };
        case ADD_CONTACT:
            return {
                ...state,
                new_contact_id: ( payload.id || false ),
                rand: _random(1,9999)
            };
        case EDIT_CONTACT:
        case DELETE_CONTACT:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_CONTACT:
            return {
                ...state,
                contact: null,
                updated: null,
                rand: false
            };
        case RESET_CONTACTS:
            return {
                ...state,
                contacts: null,
                total: null,
                firstDoc: null,
                lastDoc: null,
                contacts_loaded: false,
                rand: false
            };
        case RESET_GET_CONTACTS_BY:
            return {
                ...state,
                contacts_by: null,
                by_rand: false
            }; 
        case IMPORT_CONTACTS_FROM_LMS:
            return {
                ...state,
                import_contacts: ( payload.contacts || false ),
                lms_trainees: ( payload.trainees || false ),
                rand_import: _random(1,9999)
            }
        case RESET_IMPORT_CONTACTS_FROM_LMS:
            return {
                ...state,
                import_contacts: null,
                lms_trainees: null,
                rand_import: false
            }
        case IMPORT_CONTACTS_FROM_CSV:
            return {
                ...state,
                import_csv_contacts: ( payload.contacts || false ),
                import_csv_accounts: ( payload.accounts || false ),
                rand_import_csv: _random(1,9999)
            }
        case RESET_IMPORT_CONTACTS_FROM_CSV:
            return {
                ...state,
                import_csv_contacts: null,
                import_csv_accounts: null,
                rand_import_csv: false
            }
        default:
            return state;
    }
}