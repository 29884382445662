import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _split from 'lodash/split';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _replace from 'lodash/replace';
import _remove from 'lodash/remove';
import _map from 'lodash/map';

/* eslint-disable */

import Pagination from '../../components/Pagination';
import DotsLoader from '../../components/DotsLoader';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormSelect from '../../components/FormSelect';
import FormInput from '../../components/FormInput';

import { InfoButton, ErrorButton, ButtonGroup, AInfoLink, SuccessButton } from '../../styles/button';
import { WrapWord } from '../../styles/misc';

import { isArrayExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isAdmin, hasAccessRights } from '../../helpers/auth';

import { fb_getListsFromMailer } from '../../actions/m_mailing_lists/fb';
import { addNewMailingList, updateMailingList, deleteMailingList } from '../../actions/m_mailing_lists';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});

const ActiveTag = styled.div`
    display: inline-block;
    padding: 5px 8px;
    margin: 0px 5px 5px 0px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 6px;
    color: #fff;
    background: ${props => (props.active && props.active == 'yes' ? green['500'] : grey['500'])};
`;

class List extends React.Component {
    
    state = {
        list_options: [],
        sortBy: 'name-asc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalType: false,
        modalData: false,
        modalLoading: false,
        openDeleteModal: false,
        deleteModal: false
    }

    handleListSelect = (newValue,key) => {
        const { modalData, list_options } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} ),
            selected = ( list_options && isArrayExists( list_options ) ? _find( list_options, { id: newValue } ) : false );
        if ( selected && selected.id && !_isEmpty( selected.id ) ) {
            newData.id = selected.id;
            newData.name = ( selected.name || '' );
            newData.domain_name = ( selected.domain_name || '' );
            newData.org = ( selected.org || '' );
            newData.org_label = ( selected.org_label || '' );
        } // end - selected
        this.setState({ modalData: newData });
    }

    handleFormUpdate = (newValue,key) => {
        const { modalData, list_options } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        newData[key] = newValue;
        this.setState({ modalData: newData });
    }

    handleAddNew = () => {
        const { modalData } = this.state;
        var error = false;

        if ( !( modalData && modalData.id && !_isEmpty( modalData.id ) && modalData.name && !_isEmpty( modalData.name ) ) ) {
            error = 'Please select a valid list';
        } // end - modalData.id

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addNewMailingList(modalData));
        } // end - error
    }

    handleEdit = () => {
        const { modalData } = this.state;
        var error = false;

        if ( !( modalData && modalData.id && !_isEmpty( modalData.id ) && modalData.name && !_isEmpty( modalData.name ) ) ) {
            error = 'Please select a valid list';
        } // end - modalData.id

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateMailingList(modalData));
        } // end - error
    }

    handleAction = () => {
        const { modalType } = this.state;
        if ( modalType && modalType == 'new' ) {
            this.handleAddNew();
        } else if ( modalType && modalType == 'edit' ) {
            this.handleEdit();
        } // end - modalType
    }

    handleDelete = () => {
        const { deleteModal } = this.state;
        //perform delete
        this.props.dispatch(deleteMailingList(deleteModal.id));
    }

    handleOpenModal = () => {
        this.setState({ openViewModal: true, modalType: 'new', modalData: {}, modalLoading: true });
        fb_getListsFromMailer()
        .then( list => {
            this.setState({ modalLoading: false, list_options: cloneCollections( list ) });
        })
        .catch(error => {
            triggerErrorAlert(error.message);
            this.setState({ openViewModal: false, modalType: false, modalData: false, modalLoading: false, list_options: [] });
        });
    }

    getFieldValue = (item,key) => {
        const { portfolio } = this.props;
        switch (key) {
            case 'account_tier_1_id':
                var selected = ( portfolio && isArrayExists( portfolio ) && item[key] && !_isEmpty( item[key] ) ? _find( portfolio, { id: item[key] }) : false );
                return ( selected.value || '' );
                break;
            default:
                return ( item[key] || '' );
        }
    }

    reorganizeData() {
        const { sortBy, perPage, page } = this.state;
        const { list } = this.props;
        var items = ( list ? cloneCollections( list ) : [] ),
            total = _size( items );

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                // case 'position-desc':
                //     items = _sortBy( items, [(i) => ( i.position ? parseInt( i.position, 10 ) : 99 )] );
                //     items = _reverse( items );
                //     break;
                // case 'position-asc':
                //     items = _sortBy( items, [(i) => ( i.position ? parseInt( i.position, 10 ) : 99 )] );
                //     break;
                case 'org-desc':
                    items = _sortBy( items, ['org_label'] );
                    items = _reverse( items );
                    break;
                case 'org-asc':
                    items = _sortBy( items, ['org_label'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderViewForm = () => {
        const { authData, portfolio } = this.props;
        const { modalData, modalType, list_options } = this.state;
        return (
        <div>
            <FormSelect label="Select a List" name="id" required={true} options={getSelectOptions({ list: ( list_options && isArrayExists( list_options ) ? _sortBy( list_options, ['name'] ) : [] ), options: [{ value: '', label: 'Select an Option'}], keys: { value: 'id', label: 'name' } })} value={( modalData.id || '' )} onChange={this.handleListSelect} />
            <div style={{ paddingTop: "20px" }}>
                <FormSelect label="Select a Portfolio" name="account_tier_1_id" options={getSelectOptions({ list: ( portfolio && isArrayExists( portfolio ) ? _sortBy( portfolio, ['name'] ) : [] ), options: [{ value: '', label: 'Select an Option'}], keys: { value: 'id', label: 'label' } })} value={( modalData.account_tier_1_id || '' )} onChange={this.handleFormUpdate} />
            </div>
        </div>
        );
    }

    renderTableActions = () => {
        const { sortBy, perPage, searchterms } = this.state;
        return <TableBar
                show={['sort','entries']}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'name-asc', label: 'List Name ( A - Z)' },
                    { value: 'name-desc', label: 'List Name ( Z - A )' },
                    { value: 'org-asc', label: 'Belongs To ( A - Z)' },
                    { value: 'org-desc', label: 'Belongs To ( Z - A )' },
                    // { value: 'position-asc', label: 'Position (smallest number first)' },
                    // { value: 'position-desc', label: 'Position (biggest number first)' }
                ]}
                rightButtons={[
                    <InfoButton minWidth="128px" key="addnew" style={{ marginRight: "5px" }} onClick={this.handleOpenModal}><i className="fa fa-plus-circle"></i>Add New</InfoButton>
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    doneLoaded={true}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderBody = (items) => {
        const { classes, authData } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(item => {
                return (
                <TableRow key={item.id}>
                    <TableCell className={classes.bodycell}>{this.getFieldValue(item,'name')}</TableCell>
                    <TableCell className={classes.bodycell} style={{ width: '30%' }}><WrapWord>{item.id && item.domain_name ? item.id+'@'+item.domain_name : ''}</WrapWord></TableCell>
                    <TableCell className={classes.bodycell}>{this.getFieldValue(item,'org_label')}</TableCell>
                    <TableCell className={classes.bodycell}>{this.getFieldValue(item,'account_tier_1_id')}</TableCell>
                    <TableCell className={classes.bodycell}>
                        <ButtonGroup>
                            {/* <InfoButton size="small" onClick={() => this.setState({ openViewModal: true, modalData: item, modalType: 'edit' })}><i className="fa fa-edit"></i>Edit</InfoButton> */}
                            { authData && hasAccessRights( authData, ['mtd'] ) ? <ErrorButton size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Remove</ErrorButton> : null }
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
                )
            }) : (
                <TableRow>
                    <TableCell className={classes.bodycell}>No item(s) found.</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes } = this.props;
        return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headcell}>List Name</TableCell>
                <TableCell className={classes.headcell}>List ID</TableCell>
                <TableCell className={classes.headcell}>Belongs To</TableCell>
                <TableCell className={classes.headcell}>Account Tier 1</TableCell>
                <TableCell className={classes.headcell}>Actions</TableCell>
            </TableRow>
        </TableHead>
        )
    }

    render() {
        const { openDeleteModal, deleteModal, openViewModal, modalType, modalLoading } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openViewModal}
                title={ modalType && modalType == 'edit' ? "Edit Mailing List" : "Add New Mailing List" }
                actionLabel={ modalType && modalType == 'edit' ? "Update" : "Add New" }
                onClose={() => this.setState({ openViewModal: false, modalType: false, modalData: false, modalLoading: false, list_options: [] })}
                noAction={( modalLoading ? true : false )}
                doAction={this.handleAction}
                contents={ modalLoading ? <DotsLoader /> : this.renderViewForm()} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.label ? `Are you sure you want to delete this lead source ( ${deleteModal.label} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            {this.renderTableActions()}
            <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                <Table>
                    {this.renderHeader()}
                    {this.renderBody(items)}
                </Table>
            </Paper>
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(List);