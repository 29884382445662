/* eslint-disable */
import * as firebase from 'firebase/app';

import { getIDToken } from '../auth/fb';
import { doPromise, callFunctionsAPI } from '../../helpers/action';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

/* helper end */

export const fb_getCoursePortfolios = (callback) => {

    firebase.database()
    .ref("maintenance/course_portfolio")
    .on("value", snapshot => {

        var list = [];

        if ( snapshot.exists() && snapshot.hasChildren() ) {
            snapshot.forEach( childSnapshot => {
                var val = childSnapshot.val(),
                    item = {
                        id: childSnapshot.key,
                        label: val.label || '',
                        status: val.status || '',
                        value: val.value || '',
                        type: val.type || '',
                        position: val.position || 99,
                        course_group: [],
                        regions: []
                    };

                // add course group
                if ( childSnapshot.child("course_group").exists() && childSnapshot.child("course_group").hasChildren() ) {
                    childSnapshot.child("course_group").forEach(groupSnapshot => {
                        item.course_group.push({ id: groupSnapshot.key });
                    });
                } // end - childSnapshot.child("course_group")

                if ( childSnapshot.child("regions") && childSnapshot.child("regions").hasChildren() ) {
                    childSnapshot.child("regions").forEach( regionSnapshot => {
                        item.regions.push({
                            id: regionSnapshot.key,
                        });
                    });
                }

                list.push(item);
            });
        } // end - snapshpt\
        
        callback(list);

    });
    
}

export const fb_addCoursePortfolio = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'mCoursePortfolio', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateCoursePortfolio = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'mCoursePortfolio', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteCoursePortfolio = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'mCoursePortfolio', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_syncCoursePortfolios = () => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'sync',
                params: [{ key: 'action', value: 'sync_course_portfolio' }]
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}