/* eslint-disable */
import React from 'react';
// import * as firebase from 'firebase/app';
// import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _isEqual from 'lodash/isEqual';
import _toLower from 'lodash/toLower';
import _replace from 'lodash/replace';

import FormSelect2 from '../FormSelect2';
import ModalSelectContacts from '../ModalSelectContacts';

import { cloneCollections, getSelectOptions,
    // getSelectValues, doArraySearch 
} from '../../helpers/data';
import { isArrayExists, isObjectExists } from '../../helpers/validation';

//import { getContactsOptions } from '../../actions/contacts';

const useStyles = theme => ({
    loaderwrapper: {
        position: 'relative'
    },
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        margin: '-18px 0 0 -18px',
        zIndex: '999'
    },
    selectinput: {
        "& .MuiInputBase-root.Mui-disabled": {
            color: theme.palette.background
        }
    }
});

class SelectContacts extends React.Component {

    state = {
        openSelect: false,
        selectItem: false,
        randNum: false
    };

    componentDidMount() {
        const { predefined_account } = this.props;
        this.dispatchOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selected_accounts } = this.props;
        if ( !_isEqual( prevProps.contactsOptions, this.props.contactsOptions ) || 
            !_isEqual( prevProps.randNum, this.props.randNum ) || 
            !_isEqual( prevProps.predefined_account, this.props.predefined_account ) || 
            !_isEqual( prevProps.selected_accounts, this.props.selected_accounts ) ) {
            this.dispatchOptions();
            if ( !_isEqual( selected_accounts, prevProps.selected_accounts ) ) {
                this.onAccountsUpdated();
            } // end - selected_accounts
        }
    }

    dispatchOptions = () => {
        const { contactsOptions, randNum, predefined_account } = this.props;
        if ( contactsOptions && randNum && randNum != this.state.randNum ) {
            this.setState({ randNum });

            // if predefined_account exists - then do accounts updated
            if ( predefined_account && predefined_account.id && !_isEmpty( predefined_account.id ) ) {
                this.onAccountsUpdated();
            }
        } else if ( randNum && randNum != this.state.randNum ) {
            this.setState({ randNum });
        } // end - contactsOptions
    }

    onAccountsUpdated = () => {
        const { onChange, name } = this.props;
        const list = this.getOptions();
        if ( list && isArrayExists( list ) && _size( list ) === 1 ) {
            // if only one contact under the selected account - preselect the contact
            if ( onChange ) {
                onChange(list,name);
            } // end - onChange
        }
    }

    getValue = () => {
        const { value, valueObj } = this.props;
        if ( valueObj && isObjectExists( valueObj ) ) {
            return ( valueObj && valueObj.name || '' )
        } else {
            let selected = ( value && !_isEmpty( value ) ? _find( this.getOptions(), { id: value } ) : false );
            return ( selected && selected.name || '' );
        } // end - valueObj
    }

    getOptions = () => {
        const { contactsOptions, selected_accounts, predefined_account } = this.props;
        var options = ( contactsOptions ? cloneCollections( contactsOptions ) : [] );
        // if predefined_account exists - then filter it to just predefined_account.id
        if ( predefined_account && predefined_account.id && !_isEmpty( predefined_account.id ) ) {
            options = _filter( options, { account_id: predefined_account.id });
        } else {
            // else run through all the selected_accounts
            if ( selected_accounts && isArrayExists( selected_accounts ) ) {
                options = _filter( options, (o) => ( o.account_id && !_isEmpty( o.account_id ) && _find( selected_accounts, { id: o.account_id }) ? true : false ));
            } // end - selected_accounts
        } // end - predefined_account
        return options;
    }

    handleFormUpdate = (newValue,key) => {
        const { onChange } = this.props;
        let newOptions = [],
            selected = ( newValue && !_isEmpty( newValue ) ? _find( this.getOptions(), { id: newValue } ) : false );

        if ( onChange ) {
            if ( selected )
                newOptions.push( selected );

            // do onChange
            onChange(newOptions,key);
        } // end - onChange     
    }

    handleSelect = (selected) => {
        const { name, onChange } = this.props;
        let newOptions = [];
        // update data
        if ( onChange ) {
            if ( selected )
                newOptions.push( selected );

            // do onChange
            onChange(newOptions,name);
        } // end - onChange

        // reset modal
        this.setState({ openSelect: false, selectItem: false });
    }

    handleReset = () => {
        const { name, onChange } = this.props;
        if ( onChange ) {
            // do onChange
            onChange([],name);
        } // end - onChange
    }

    // renderSelectOld = () => {
    //     const { label, name, disabled, value, isClearable } = this.props;
    //     const { randNum } = this.state;
    //     const list = this.getOptions();
    //     return <FormSelect2 
    //         label={( label || '' )}
    //         name={( name || '' )}
    //         disabled={( 
    //             disabled || 
    //             !randNum || 
    //             _size( list ) < 2 // if only one (or no) contact under the selected account - disable the field
    //             ? true : false )} 
    //         value={( value || '' )} 
    //         options={getSelectOptions({ list, keys: { value: 'id', label: 'name' }, sortBy: 'label' })}  
    //         placeholder="Select an Option"
    //         menuPosition="fixed"
    //         isClearable={( isClearable || false )}
    //         onChange={this.handleFormUpdate} />
    // }

    renderLabel = () => {
        const { label } = this.props;
        var newLabel = false;
        if ( label && !_isEmpty( label ) && ( 
            ( _toLower( label ).indexOf("(required)") >= 0 ) || 
            ( _toLower( label ).indexOf("*") >= 0 )
         ) ) {
            newLabel = _replace( label, '(Required)', '' );
            newLabel = _replace( newLabel, '(required)', '' );
            newLabel = _replace( newLabel, '*', '' );
        } // end - label
        return ( newLabel ? <span>{ newLabel || '' }<span className="required">*</span></span> : ( label || '' ) );
    }

    renderSelect = () => {
        const { disabled, labelProps, isClearable, value, classes } = this.props;
        const { randNum } = this.state;
        const list = this.getOptions();
        return <TextField 
            label={this.renderLabel()} 
            value={this.getValue()} 
            fullWidth={true}
            variant="outlined" 
            className={classes.selectinput}
            disabled={true}
            InputLabelProps={( labelProps ? labelProps : {
                shrink: true,
                style: { fontSize: "16px", fontWeight: "700", textTransform: "uppercase", color: '#757575' }
            })}
            InputProps={{
                startAdornment: ( value && !_isEmpty( value ) && isClearable && !( disabled || !randNum || _size( list ) < 2 ) ? (
                <InputAdornment position="start">
                    <Tooltip title="Remove" arrow="true">
                        <IconButton 
                            style={{ padding: '6px' }}
                            aria-label="Remove"
                            onClick={this.handleReset}><ClearIcon /></IconButton>
                    </Tooltip>
                </InputAdornment>
                ) : null ),
                endAdornment: ( 
                    //disabled || !randNum || _size( list ) < 2 ? null : (
                    disabled || !randNum ? ( disabled ? null : (  // if is disabled - still return null - otherwise return loading icon
                <InputAdornment position="end">
                    <Tooltip title={"Loading"} arrow="true">
                        <CircularProgress size={24} />
                    </Tooltip>
                </InputAdornment>
                ) ) : (
                <InputAdornment position="end">
                    <Tooltip title={"Select Contact"} arrow="true">
                        <IconButton 
                            aria-label="Select Contact"
                            onClick={() => this.setState({ openSelect: true })}><ControlPointIcon /></IconButton>
                    </Tooltip>
                </InputAdornment>
                ) )
            }} />
    }

    render() {
        const { randNum, openSelect} = this.state;
        const { classes, selected_accounts, predefined_account, valueObj } = this.props;
        return (
        <div>

            {( !randNum && !( valueObj && isObjectExists( valueObj ) ) ? (
            <div className={classes.loaderwrapper}>
                <CircularProgress size={36} className={classes.loader} />
                {this.renderSelect()}
            </div>
            ) : this.renderSelect() )}

            <ModalSelectContacts
                open={openSelect}
                selected_accounts={selected_accounts}
                predefined_account={predefined_account}
                onClose={() => this.setState({ openSelect: false, selectItem: false })}
                // forceRand={newRand => this.setState({ randNum: newRand })}
                onSelect={this.handleSelect} />

        </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        contactsOptions: state.contacts && state.contacts.contacts_options || null,
        randNum: state.contacts && state.contacts.options_rand || null
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles)
)(SelectContacts);