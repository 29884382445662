/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import AppWrapper from '../../components/AppWrapper';
import WidgetLatestUpdates from '../../components/WidgetLatestUpdates';


class DashboardPage extends React.Component {

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    renderContents() {
        return (
        <div>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <WidgetLatestUpdates />
                </Grid>
            </Grid>

        </div>
        );
    }

    render() {
        return <AppWrapper 
                title="Dashboard"
                maxWidth="1110px"
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        sample: state.sample || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(DashboardPage);