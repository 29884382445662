/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';

import Table from '../Table';
import Pagination from '../Pagination';
import TableBar from '../TableBar';
import AddNewEmail from '../AddNewEmail';
import ModalEditEmail from '../ModalEditEmail';
import ModelDelete from '../../components/ModalDelete';

import { InfoButton, GreyButton, ButtonGroup, SuccessButton, InverseButton, ErrorButton } from '../../styles/button';
import { SuccessTag, AmberTag, GreyTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { hasLimitedAccess } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
// import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from '../../helpers/data';
// import { getMomentTime, getMomentTimestamp } from '../../helpers/date';

import { deleteEmail } from '../../actions/emails';
import { appChangesReset } from '../../actions/misc';

// import { DEV_MODE } from '../../constants';

class EmailsList extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        filterBy: 'all',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openAddNewModal: false,
        openViewModal: false,
        modalData: false,
        modalType: false,
        openDeleteModal: false,
        deleteModal: false
    }

    handlePageRefresh = (event) => {
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh('emails');
    }

    handleDelete = () => {
        const { type, typeID } = this.props;
        const { deleteModal } = this.state;
        // perform delete
        this.props.dispatch(deleteEmail(deleteModal.id,type,typeID));
    }

    handleEdit = (item,type) => event => {
        const { changesMade } = this.props;
        event.preventDefault();
        if ( changesMade ) {
            alert("Please save changes first before proceed");
        } else {
            this.setState({ openViewModal: true, modalData: item, modalType: type });
        } // end - changesMade
    }

    handleAddNew = (event) => {
        const { changesMade } = this.props;
        event.preventDefault();
        if ( changesMade ) {
            alert("Please save changes first before proceed");
        } else {
            this.setState({ openAddNewModal: true });
        } // end - changesMade
    }

    handleView = (id,event) => {
        const { changesMade, history, dispatch } = this.props;
        event.preventDefault();
        let url = "/emails/"+id;
        if ( changesMade ) {
            var answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
            // reset status if answer is yes
            if ( answer ) {
                dispatch(appChangesReset());
                history.push(url);
            }
        } else {
            history.push(url);
        } // end - changesMade
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { emails } = this.props;
        let items = ( emails ? cloneCollections( emails ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['subject','status'] );
			total = _size( items );
        } // end - searchterms

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['modified_on'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['modified_on'] );
                    break;
                case 'subject-desc':
                    items = _sortBy( items, [(i) => i.subject.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'subject-asc':
                    items = _sortBy( items, [(i) => i.subject.toLowerCase()] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderCell = type => item => {
        switch( type ) {
            // case 'assigned_to':
            //     return ( item[type] && isArrayExists( item[type] ) ? item[type].map((u) => (<div key={u.id}>{u.name}</div>) ) : '--' );
            case 'status':
                if ( item[type] && item[type] === 'publish' ) {
                    return <SuccessTag>Publish</SuccessTag>
                } else if ( item[type] && item[type] === 'pending' ) {
                    return <GreyTag>Pending</GreyTag>
                } else {
                    return <AmberTag>Draft</AmberTag>
                }
            case 'emails':
                let emails = (  item[type] && isArrayExists( item[type] ) ? cloneCollections( item[type] ) : false );
                emails = _sortBy( emails, ['type','email']);
                emails = _reverse( emails );
                return ( emails ? emails.map((e) => (
                    <div key={e.id}>{e.type}: {e.email}</div>
                ) ) : '--' );
            default:
                return <WrapWord>{( item[type] || '' )}</WrapWord>
        }
    }

    renderRightActionButtons = () => {
        const { noAddNew } = this.props;
        var rightButtons = [];

        // add add new email button
        if ( !noAddNew ) {
            rightButtons.push( <InfoButton minWidth="128px" key="addnew" style={{ marginRight: "5px" }} onClick={this.handleAddNew}><i className="fa fa-plus-circle"></i>Add New</InfoButton> );
        } // end - noAddNew

        rightButtons.push( <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton> );

        return rightButtons;
    }

    renderTableActions = () => {
        const { authData } = this.props;
        const { sortBy, perPage, searchterms } = this.state;
        return <TableBar
                limitedPerPage={( hasLimitedAccess(authData) ? true : false )}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Modified first' },
                    { value: 'date-asc', label: 'Oldest Modified first' },
                    { value: 'subject-asc', label: 'Subject ( A - Z)' },
                    { value: 'subject-desc', label: 'Subject ( Z - A )' },
                ]}
                rightButtons={this.renderRightActionButtons()}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    doneLoaded={true}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { noAddNew, authData, users, account, contact, potential, type, typeID } = this.props;
        const { openAddNewModal, openViewModal, modalType, modalData, openDeleteModal, deleteModal } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            {this.renderTableActions()}
            <Table 
                noSelect={( hasLimitedAccess(authData) ? true : false )}
                items={items}
                showCheckbox={false}
                cells={[
                    { id: 'subject', label: 'Subject', render: (item) => ( item.subject || '' ) },
                    { id: 'status', label: 'Status', render: this.renderCell('status') },
                    { id: 'emails', label: 'Emails', render: this.renderCell('emails') },
                ]}
                actionStyles={{ width: "20%" }}
                actions={(item) => (
                    <ButtonGroup>
                        { item && item.status && item.status === 'draft' ? <SuccessButton key="edit" size="small" onClick={this.handleEdit(item,'edit')}><i className="fa fa-edit"></i>Edit</SuccessButton> : <InfoButton key="view" size="small" onClick={this.handleEdit(item,'view')}><i className="fa fa-search"></i>View</InfoButton> }
                        { item && item.status && item.status === 'draft' ? <ErrorButton key="delete" size="small"  onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton> : null }
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.subject ? `Are you sure you want to delete this email ( ${deleteModal.subject} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            { noAddNew ? null : <AddNewEmail
                authData={authData}
                type={( type || false )}
                typeID={( typeID || false )}
                open={openAddNewModal}
                users={( users || [] )}
                predefined_accounts={( account && !_isEmpty( account ) ? [ account ] : null )}
                predefined_contacts={( contact && !_isEmpty( contact ) ? [ contact ] : null )}
                predefined_potentials={( potential && !_isEmpty( potential ) ? [ potential ] : null )}
                onClose={() => this.setState({ openAddNewModal: false })} /> }

            <ModalEditEmail
                authData={authData}
                type={( type || false )}
                typeID={( typeID || false )}
                open={openViewModal}
                email_id={( modalData && modalData.id && !_isEmpty( modalData.id ) ? modalData.id : false )}
                readOnly={( modalType && modalType === 'view' ? true : false )}
                users={( users || [] )}
                onClose={() => this.setState({ openViewModal: false, modalType: false, modalData: false })} />

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(EmailsList);