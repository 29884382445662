/* eslint-disable */
import * as firebase from 'firebase/app';

import { getIDToken } from '../auth/fb';
import { doPromise } from '../../helpers/action';

export const fb_getRolesFromDB = (callback) => {

    firebase.database()
    .ref("maintenance/roles")
    .on("value", snapshot => {

        var list = [];

        if ( snapshot.exists() && snapshot.hasChildren() ) {
            snapshot.forEach( childSnapshot => {
                var val = childSnapshot.val(),
                    item = {
                        id: childSnapshot.key,
                        label: val.label || '',
                        accesses: []
                    };

                if ( childSnapshot.child("values").exists() && childSnapshot.child("values").hasChildren() ) {
                    childSnapshot.child("values").forEach(accessSnapshot => {
                        if ( accessSnapshot.val() == 'yes' )
                            item.accesses.push(accessSnapshot.key)
                    });
                } // end - childSnapshot

                list.push(item);
            });
        } // end - snapshpt\
        
        callback(list);

    });
    
}

export const fb_addRole = (formData) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'mRoles',
                params: [{ key: 'action', value: 'add' }],
                formData
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });


    })
}

export const fb_editRole = (formData) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'mRoles',
                params: [{ key: 'action', value: 'update' }],
                formData
            })
            .then(res => {
                resolve('done');
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteRole = (id) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'mRoles',
                params: [{ key: 'action', value: 'delete' }],
                formData: { id }
            })
            .then(res => {
                resolve('done');
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_syncRoles = () => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'sync',
                params: [{ key: 'action', value: 'sync_roles' }]
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}