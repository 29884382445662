/* eslint-disable */
import * as firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _find from 'lodash/find';

import { callFunctionsAPI, checkIfUserAccessLimited } from '../../helpers/action';
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema } from '../../helpers/schemas';

import { reportTemplateSchema } from '../../schemas/report_template';


/* helpers start */
// get report JSON file
const getReportJSONData = (report_id) => {
    return new Promise((resolve,reject) => {

        firebase.storage().ref('reports/'+report_id+'/report.json').getDownloadURL().then(function(url) {

            // This can be downloaded directly:
            var xhr = new XMLHttpRequest();
            xhr.overrideMimeType("application/json");
            xhr.open('GET', url);
            xhr.onreadystatechange = () => {
                if (xhr.readyState == 4 && xhr.status == "200") {
                    resolve(JSON.parse(xhr.responseText));
                }
            }
            xhr.send(null);

        }).catch(function(error) {
            // do nothing
            resolve(false);  
        });
          

    })
}

const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

/* helper end */

export const fb_getReportTemplates = (props) => {
    return new Promise((resolve,reject) => {

        const promises = [];
        promises.push( firebase.database().ref("report_templates").once("value") );
        promises.push( checkIfUserAccessLimited() );
        
        Promise.all( promises )
        .then(results => {

            let list = [];

            if ( results && results[0] && results[0].exists() && results[0].hasChildren() ) {
                results[0].forEach( childSnapshot => {
                    let item = getSnapshotDataBySchema( reportTemplateSchema, childSnapshot ),
                        isLimitedUser = ( results && results[1] && !_isEmpty( results[1] ) ? true : false );

                    // if is limited user - check if is restricted
                    if ( isLimitedUser ) {
                        if ( item && item.report_restricted && item.report_restricted === 'no' )
                            list.push(item);
                    } else {
                        list.push(item);
                    } // end - isLimitedUser
                });
            } // end - results[0]

            resolve(list);

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getReportTemplate = (report_id) => {
    return new Promise((resolve,reject) => {

        let promises = [];
        promises.push( firebase.firestore().collection("report_templates").doc(report_id).get() );
        promises.push( getReportJSONData(report_id) );
        promises.push( checkIfUserAccessLimited() );
        
        Promise.all( promises )
        .then(results => {
            let item = {};

            if ( results && results[0] ) {
                item = getDocDataBySchema( reportTemplateSchema, results[0] );
            } // end - results[0]

            if ( results && results[1] && !_isEmpty( results[1] ) ) {
                item.report = results[1];
            } // end - results[0]

            // if is limited user
            if ( results && results[2] && !_isEmpty( results[2] ) ) {
                // if it wasn't restricted
                if ( item && item.report_restricted && item.report_restricted === 'no' ) {
                    // do check if they have access to the previous report
                    if ( item.generated_by && !_isEmpty( item.generated_by ) && results[2].email && !_isEmpty( results[2].email ) && item.generated_by === results[2].email ) {
                        // filter out assigned_to ( make sure primary module wasn't products )
                        if ( item.primary_module && item.primary_module !== 'product' && item.primary_module !== 'products' ) {
                            item.report = _filter( item.report, (r) => r.primary_assigned_to && ( isArrayExists( r.primary_assigned_to ) || isObjectExists( r.primary_assigned_to ) ) && _find( r.primary_assigned_to, { id: results[2].email } ) ? true : false );
                        } // end - item.primary_module
                    } else {
                        item.report = [];
                    } // end - item.generated_by
                    resolve(item);
                } else {
                    resolve({});
                }
            } else {    
                resolve(item);
            } // end - results

        })
        .catch(error => {
            reject(error);
        });


    })
}

export const fb_addReportTemplate = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'report', action: 'add_template', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateReportTemplate = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'report', action: 'update_template', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_generateReport = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'generateReport', action: 'generate', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteReportTemplate = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'report', action: 'delete_template', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
    })
}

export const fb_getReportCategories = (callback) => {
    return new Promise((resolve,reject) => {

        firebase.database()
        .ref("maintenance/report_categories")
        .on("value", snapshot => {
    
            var list = [];
    
            if ( snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach( childSnapshot => {
                    var val = childSnapshot.val(),
                        item = {
                            id: childSnapshot.key,
                            name: val.name || '',
                            desc: val.desc || ''
                        };
    
                    list.push(item);
                });
            } // end - snapshpt\
            
            list = _sortBy( list, [(i) => i.name.toLowerCase()]);
            callback(list);
    
        });

    })
}

export const fb_addReportCategory = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'report', action: 'add_category', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateReportCategory = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'report', action: 'update_category', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteReportCategory = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'report', action: 'delete_category', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
    })
}