import _random from 'lodash/random';

import { 
    M_GET_REGIONS,
    M_REGIONS_UPDATED,
    M_GET_ROLES,
    M_ROLES_UPDATED,
    M_GET_COURSE_PORTFOLIOS,
    M_COURSE_PORTFOLIOS_UPDATED,
    M_GET_CONSULTANTS,
    M_CONSULTANTS_UPDATED,
    M_GET_COURSE_GROUP,
    M_COURSE_GROUP_UPDATED,
    M_GET_SELECTABLES,
    M_SELECTABLES_UPDATED,
    M_GET_COURSE_PROFILE,
    M_COURSE_PROFILE_UPDATED,
    M_GET_PAYMENT_TERMS,
    M_PAYMENT_TERMS_UPDATED,
    M_GET_INVOICE_TYPE,
    M_INVOICE_TYPE_UPDATED,
    M_GET_BENEFICIARY_DATA,
    M_BENEFICIARY_DATA_UPDATED,
    M_GET_LEAD_SOURCE,
    M_LEAD_SOURCE_UPDATED,
    M_GET_ACCOUNTS_TIER_2,
    M_ACCOUNTS_TIER_2_UPDATED,
    M_GET_MAILING_LISTS,
    M_MAILING_LISTS_UPDATED
} from '../actions/types';

const initialState = {
    regions: null,
    roles: null,
    course_portfolio: null,
    course_group: null,
    selectables: null,
    rand: false
};

export const maintenance = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case M_GET_REGIONS: return { ...state, regions: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_ROLES: return { ...state, roles: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_COURSE_PORTFOLIOS: return { ...state, course_portfolio: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_CONSULTANTS: return { ...state, consultants: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_COURSE_GROUP: return { ...state, course_group: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_COURSE_PROFILE: return { ...state, courses_profile: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_SELECTABLES: return { ...state, selectables: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_PAYMENT_TERMS: return { ...state, payment_terms: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_INVOICE_TYPE: return { ...state, invoice_type: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_BENEFICIARY_DATA: return { ...state, beneficiary_data: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_LEAD_SOURCE: return { ...state, lead_source: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_ACCOUNTS_TIER_2: return { ...state, accounts_tier_2: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_MAILING_LISTS: return { ...state, mailing_lists: ( payload.list || [] ), rand: _random(1,9999) };

        case M_REGIONS_UPDATED:
        case M_ROLES_UPDATED:
        case M_COURSE_PORTFOLIOS_UPDATED:
        case M_CONSULTANTS_UPDATED:
        case M_COURSE_GROUP_UPDATED:
        case M_COURSE_PROFILE_UPDATED:
        case M_SELECTABLES_UPDATED:   
        case M_PAYMENT_TERMS_UPDATED:
        case M_INVOICE_TYPE_UPDATED:
        case M_BENEFICIARY_DATA_UPDATED:
        case M_LEAD_SOURCE_UPDATED:
        case M_ACCOUNTS_TIER_2_UPDATED:
        case M_MAILING_LISTS_UPDATED:
            return { ...state, rand: _random(1,9999) };
        default:
            return state;
    }
}