import React from 'react';
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _remove from 'lodash/remove';

/* eslint-disable */

import FormInput from '../../components/FormInput';

import { InfoButton } from '../../styles/button';
import { FormBox } from '../../styles/form';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';

const useStyles = theme => ({
    paper: {
        padding: '20px',
        backgroundColor: '#fff'
    }
});


class ContactForm extends React.Component {

    handleFormPaste = (contactKey,traineeKey) => (event) => {
        event.preventDefault();
        const { contact, trainee, onUpdate } = this.props;
        const newContact = ( contact && isObjectExists( contact ) ? cloneCollections( contact ) : {} );

        switch( traineeKey ) {
            case 'trainee_name':
                newContact[ contactKey ] = ( trainee && trainee[traineeKey] ? trainee[traineeKey] : '' );
                break;
            case 'trainee_contact':
                newContact[ contactKey ] = ( trainee && trainee[traineeKey] ? trainee[traineeKey] : '' );
                break;
            case 'trainee_job_title_dept':
                newContact[ contactKey ] = ( trainee && trainee[traineeKey] ? trainee[traineeKey] : '' );
                break;
        }

        if ( onUpdate )
            onUpdate(newContact);
    }

    handleFormUpdate = (newValue,key) => {
        const { contact, trainee, onUpdate } = this.props;
        const newContact = ( contact && isObjectExists( contact ) ? cloneCollections( contact ) : {} );

        switch( key ) {
            default:
                newContact[key] = newValue;
                break;
        }

        if ( onUpdate )
            onUpdate(newContact);
    }

    renderTextField = (label,contactKey,traineeKey) => {
        const { contact } = this.props;
        return (
        <>
            <FormInput label={( label || '' )} value={( contact && contact[contactKey] || '' )} name={contactKey} onChange={this.handleFormUpdate} />
            { traineeKey && !_isEmpty( traineeKey ) ? <InfoButton style={{ padding: "5px 12px", marginTop: "8px" }} onClick={this.handleFormPaste(contactKey,traineeKey)}>Paste</InfoButton> : null }
        </>
        )
    }

    render() {
        const { contact, trainee, classes } = this.props;
        return (
        <FormBox>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormInput label="Contact ID" value={( contact && contact.id && !_isEmpty( contact.id ) ? contact.id : '[new]' )} disabled={true} />
                </Grid>
                <Grid item xs={12}>
                    <FormInput label="Account" value={( contact && contact.account_label && !_isEmpty( contact.account_label ) ? contact.account_label : '' ) + ( contact && contact.account_id && !_isEmpty( contact.account_id ) ? ' ('+contact.account_id+')' : '' )} disabled={true} />
                </Grid>
                <Grid item xs={12}>
                    <FormInput label="Email" type="email" value={( contact && contact.email || '' )} disabled={true} />
                </Grid>
                <Grid item xs={4}>
                    <div style={{ paddingRight: '20px' }}>
                        <FormInput label="LMS Full Name" value={( trainee && trainee.trainee_name || '' )} disabled={true} />
                    </div>
                </Grid>
                <Grid item xs={8}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>{this.renderTextField('First Name','first_name')}</Grid>
                        <Grid item xs={6}>{this.renderTextField('Last Name','last_name','trainee_name')}</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <div style={{ paddingRight: '20px' }}>
                        <FormInput label="LMS Contact" value={( trainee && trainee.trainee_contact || '' )} disabled={true} />
                    </div>
                </Grid>
                <Grid item xs={8}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>{this.renderTextField('Office Phone','office_phone','trainee_contact')}</Grid>
                        <Grid item xs={4}>{this.renderTextField('Mobile Phone','mobile_phone','trainee_contact')}</Grid>
                        <Grid item xs={4}>{this.renderTextField('Other Phone','other_phone','trainee_contact')}</Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <div style={{ paddingRight: '20px' }}>
                        <FormInput label="LMS Job Title, Department" value={( trainee && trainee.trainee_job_title_dept || '' )} disabled={true} />
                    </div>
                </Grid>
                <Grid item xs={8}>
                    {this.renderTextField('Position','position','trainee_job_title_dept')}
                </Grid>
            </Grid>
        </FormBox>
        )
    }

}

export default compose(
    withStyles(useStyles)
)(ContactForm);