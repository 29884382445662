/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import ReactTagInput from "@pathofdev/react-tag-input";
// import {WithContext as ReactTags} from 'react-tag-input'
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _split from 'lodash/split';
import _remove from 'lodash/remove';
import _startsWith from 'lodash/startsWith';
import _concat from 'lodash/concat';
import _toString from 'lodash/toString';
import _replace from 'lodash/replace';
import _snakeCase from 'lodash/snakeCase';

import './form.css';

import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormSelect2 from '../../components/FormSelect2';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormDatePicker from '../../components/FormDatePicker';
import FormMultiOptions from '../../components/FormMultiOptions';
// import FormCheckbox from '../../components/FormCheckbox';
import FormRadio from '../../components/FormRadio';
import ModalSelectEmailTemplate from '../../components/ModalSelectEmailTemplate';

import { isAdmin } from '../../helpers/auth';
import { reverseUsersValues } from '../../helpers/users';
import { isArrayExists, isObjectExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { isSchemaRequired } from '../../helpers/schemas';

import { FormBox } from '../../styles/form';
import { InverseButton, SuccessButton, InfoButton, WarningButton, ButtonGroup, InfoLink } from '../../styles/button';

// import { addNewEmailTemplate } from '../../actions/emailTemplates';

import { emailSchema } from '../../schemas/email';

// const ReactTags = require('react-tag-input').WithOutContext;

const useStyles = theme => ({
    emailInput: {
        position: 'relative',
        '& label': {
            position: 'absolute',
            top: '-5px',
            left: '-12px',
            zIndex: '2',
            fontSize: "16px", 
            fontWeight: "700", 
            textTransform: "uppercase", 
            background: '#fff', 
            paddingLeft: '10px', 
            paddingRight: '10px',
            color: 'rgba(0, 0, 0, 0.54)',
            transform: 'translate(14px, -6px) scale(0.75)'
        },
        '& .react-tag-input': {
            padding: '10px 14px',
            border: '1px solid rgba(0, 0, 0, 0.23)'
        },
        '& .react-tag-input__input': {
            fontSize: '1.25em'
        },
        '& .react-tag-input__tag__content': {
            fontSize: '1.5em'
        }
    },
    tooltipTitle: {
        padding: '10px',
        fontSize: '16px',
        lineHeight: '1.25'
    },
    toolTipWrapper: {
        background: '#f44336',
        maxWidth: '600px',
        '& .MuiTooltip-tooltip': {
            background: '#f44336'
        }
    }
});

class ComposeForm extends React.Component {

    state = {
        show_cc: false,
        show_bcc: false,
        onEmailFocus: []
    }

    handleFormUpdate = ( newValue, key ) => {
        const { onFormUpdate, email } = this.props;
        let newData = ( email && !_isEmpty( email ) ? cloneCollections( email ) : {} );
        
        switch( key ) {
            case 'from':
                // update form
                newData[key] = newValue;
                // update form label
                let formOptions = this.availableFromOptions(),
                    selected = _find( formOptions, { id: newValue });
                newData['from_label'] = ( selected && selected.label ? selected.label : '' );
                break;
            case 'emails_to':
            case 'emails_cc':
            case 'emails_bcc':
                let emails = ( newData && newData.emails && isArrayExists( newData.emails ) ? cloneCollections( newData.emails ) : [] ),
                    type = _replace( key, 'emails_', '' );
                // remove all selected emails
                let pulled = _remove( emails, { type } );
                // re-add the new value into emails
                if ( newValue && isArrayExists( newValue ) ) {
                    _forEach( newValue, email => {
                        emails.push({
                            id: _snakeCase( email ),
                            email,
                            type
                        });
                    });
                } // end - newValue
                newData['emails'] = emails;
                break;
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    handleTemplateTrigger = () => {
        const { onTemplateTriggered } = this.props;
        if ( onTemplateTriggered )
            onTemplateTriggered();
    }

    handleEmailFieldChange = (open,type) => event => {
        const { onFormUpdate, email } = this.props;
        event.preventDefault();
        let newData = ( email && !_isEmpty( email ) ? cloneCollections( email ) : {} );
        switch( type ) {
            case 'cc':
                this.setState({ show_cc: !open });
                if ( newData && newData.emails && isArrayExists( newData.emails ) ) {
                    let pulled = _remove( newData.emails, { type } );
                }
                break;
            case 'bcc':
                this.setState({ show_bcc: !open });
                if ( newData && newData.emails && isArrayExists( newData.emails ) ) {
                    let pulled = _remove( newData.emails, { type } );
                }
                break;
        }

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    handleAddNewEmail = (status) => event => {
        event.preventDefault();
        const { onAddNew } = this.props;
        // trigger addnew
        if ( onAddNew )
            onAddNew(status);
    }

    handleTagOnBlur = (type) => (event) => {
        const { onEmailFocus } = this.state;
        let newFocus = ( onEmailFocus && isArrayExists( onEmailFocus ) ? cloneCollections( onEmailFocus ) : [] ),
            refNode = ReactDOM.findDOMNode(this.refs["app-email-" + type + "-wrapper"]),
            inputValue = ( refNode && refNode.children && refNode.children[0] && refNode.children[0].lastChild && refNode.children[0].lastChild.value && !_isEmpty( refNode.children[0].lastChild.value ) ? refNode.children[0].lastChild.value : false );
        if ( inputValue ) {
            if ( !_find( newFocus, { type } ) )
                newFocus.push({ type });
        } else {
            if ( _find( newFocus, { type } ) ) {
                let pulled = _remove( newFocus, { type });
            }
        } // end - inputValue
        this.setState({ onEmailFocus: ( newFocus && isArrayExists( newFocus ) ? newFocus : [] ) });
    }

    handleTagOnFocus = (type) => (event) => {
    }

    availableFromOptions = () => {
        const { authData } = this.props;
        const options = [];
        if ( authData && authData.email && !_isEmpty( authData.email ) ) {
            options.push({ id: authData.email, email: authData.email, label: authData.name || '' });
        }
        // add in default options
        options.push({ id: 'enquiry@dreamcatcher.asia', email: 'enquiry@dreamcatcher.asia', label: 'DreamCatcher Consulting' });
        return options;
    }

    getFieldOptions = (field) => {
        const { email, authData, users } = this.props;
        switch( field.name ) {
            // case 'report_owners':
            //     return getSelectOptions({ list: ( users ? _filter( users, (u) => u.status !== 'disabled' ) : [] ), keys: { value: 'email', label: 'name' }, sortBy: 'label' });
            case 'from': 
                return getSelectOptions({ list: this.availableFromOptions(), keys: { value: 'id', label: 'email' } });
            default:
                return [];
        }
    }

    getFieldValue = (field) => {
        const { email } = this.props;
        switch( field.name ) {
            case 'emails_to':
            case 'emails_cc':
            case 'emails_bcc':
                let type = _replace( field.name, 'emails_', '' ),
                    selected = ( email && email['emails'] && isArrayExists( email['emails'] ) ? _filter( email['emails'], { type } ) : [] ),
                    list = [];
                if ( isArrayExists( selected ) ) {
                    _forEach( selected, e => {
                        list.push(e.email);
                        // list.push({ id: e.email, text: e.email });
                    });
                } // end - selected
                return list;
            default:
                return ( email && email[field.name] || ( field.default || '' ) );
        }
    }

    isFieldDisabled = (schema) => {
        const { authData, email, readOnly } = this.props;
        let disabled = false;

        if ( schema && schema.disabled && isArrayExists( schema.disabled ) ) {
            schema.disabled.forEach( condition => {
                switch( condition ) {
                    case 'admin':
                        if ( !isAdmin( authData ) )
                            disabled = true;
                        break;
                    case 'update':
                        disabled = true;
                        break;
                }
            });
        } // end - schema

        if ( readOnly )
            disabled = true;

        return disabled
    }

    getField = (id) => {
        let schema = _find( emailSchema, { id } );
        return ( schema ? {
            name: ( schema.id || '' ),
            label: ( schema.label || '' ) + ( isSchemaRequired(schema,'update') ? ' (Required)' : '' ),
            field_type: ( schema.field || '' ),
            default: ( schema.default || null ),
            disabled: this.isFieldDisabled( schema )
        } : null );
    }

    getEmailValue = (tags) => {
        const list = [];
        if ( tags && isArrayExists( tags ) ) {
            _forEach( tags, tag => {
                list.push(tag.id);
            });
        } // end - tags
        return list;
    }

    renderField = (id) => {
        // const { authData, email } = this.props;
        let field = this.getField(id);
        if ( field && field.field_type && !_isEmpty( field.field_type ) ) {
            switch( field.field_type ) {
                case 'text':
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'textarea':
                    return <FormInput {...field} multiline={true} rows={3} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'select':
                    return <FormSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} />;
                case 'datepicker':
                    return <FormDatePicker {...field} noDefaultVal={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'radio':
                    return <div style={{ paddingTop: "7px" }}><FormRadio inline={true} {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} /></div>;
            }
        } // end - field.field_type
    }

    renderEmailField = ({ label = 'To', required = false, type = 'to' }) => {
        const { classes, readOnly } = this.props;
        const { onEmailFocus } = this.state;
        return (
        <Tooltip classes={{ tooltip: classes.toolTipWrapper }} open={( onEmailFocus && isArrayExists( onEmailFocus ) && _find( onEmailFocus, { type } ) ? true : false )} title={<div className={classes.tooltipTitle}>Incomplete entry - Please select the email & press Enter to complete the entry</div>} placement="top-start">
            <div className={classes.emailInput}>
                <label>{label}{ required ? <span className="required">*</span> : null }</label>
                <div 
                    ref={ "app-email-" + type + "-wrapper" }
                    className={"app-email-tag" + ( onEmailFocus && isArrayExists( onEmailFocus ) && _find( onEmailFocus, { type } ) ? " onfocus" : "" )}
                    onBlur={this.handleTagOnBlur(type)}
                    onFocus={this.handleTagOnFocus(type)}>
                    <ReactTagInput 
                        tags={this.getFieldValue({ name: 'emails_' + type })} 
                        editable={true}
                        readOnly={( readOnly || false )}
                        validator={(newValue) => {
                            if ( newValue && !_isEmpty( newValue ) && validateEmail( newValue ) ) {
                                return true;
                            } else {
                                triggerErrorAlert("Please insert a valid email address.")
                                return false;
                            } // end - newValue
                        }}
                        onChange={newEmails => {
                            // remove focus
                            let newFocus = ( onEmailFocus && isArrayExists( onEmailFocus ) ? cloneCollections( onEmailFocus ) : [] );
                            if ( _find( newFocus, { type } ) ) {
                                let pulled = _remove( newFocus, { type } );
                            }  
                            
                            this.setState({ onEmailFocus: newFocus });

                            this.handleFormUpdate( newEmails, 'emails_'+type );
                        }} />
                </div>
            </div>
        </Tooltip>
        )
    }

    render() {
        const { open, readOnly } = this.props;
        const { show_cc, show_bcc } = this.state;
        let ccEmails = this.getFieldValue({ name: 'emails_cc' }),
            bccEmails = this.getFieldValue({ name: 'emails_bcc' })
        return (
        <FormBox>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <div style={{ marginBottom: "10px" }}>{this.renderEmailField({ required: true })}</div>
                    { show_cc || ( ccEmails && isArrayExists( ccEmails ) ) ? <div style={{ marginBottom: "10px" }}>{this.renderEmailField({ label: 'CC', type: 'cc' })}</div> : null }
                    { show_bcc || ( bccEmails && isArrayExists( bccEmails ) ) ? <div>{this.renderEmailField({ label: 'BCC', type: 'bcc' })}</div> : null }
                    <ButtonGroup style={{ marginTop: '10px' }}>
                        <InfoLink key="addcc" size="small" disabled={( readOnly ? 'yes' : false )} style={{ padding: '5px 10px', marginRight: "5px" }} onClick={this.handleEmailFieldChange(show_cc,'cc')}>{( !show_cc ? 'Add' : 'Remove' )} CC</InfoLink>
                        <InfoLink key="addbcc" size="small" disabled={( readOnly ? 'yes' : false )} style={{ padding: '5px 10px' }} onClick={this.handleEmailFieldChange(show_bcc,'bcc')}>{( !show_bcc ? 'Add' : 'Remove' )} BCC</InfoLink>
                    </ButtonGroup>
                </Grid>

                <Grid item xs={12}>{this.renderField('subject')}</Grid>
                <Grid item xs={12}>{this.renderField('from')}</Grid>

                <Grid item xs={9}>
                    <ButtonGroup>
                        <WarningButton key="draft" disabled={( readOnly ? 'yes' : false )} style={{ padding: '5px 10px', marginRight: "5px"}} onClick={this.handleAddNewEmail('draft')}><i className="fa fa-save"></i>Save as Draft</WarningButton>
                        <InfoButton key="template" disabled={( readOnly ? 'yes' : false )} style={{ padding: '5px 10px' }} onClick={this.handleTemplateTrigger}><i className="fa fa-copy"></i>Select Email Template</InfoButton>
                    </ButtonGroup>
                </Grid>
                <Grid item xs={3} style={{ textAlign: 'right' }}>
                    <SuccessButton key="send" disabled={( readOnly ? 'yes' : false )} style={{ padding: '5px 10px', marginLeft: "15px"}} onClick={this.handleAddNewEmail('publish')}><i className="fa fa-envelope"></i>Send</SuccessButton>
                </Grid>

            </Grid>
        </FormBox>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(ComposeForm);