export const emailSchema = [
    { 
        id: 'id', 
        label: 'ID',
        required: ['update','delete'], 
        default: '', 
        type: 'string', 
        validation: 'string_id', 
        skip: ['add','update','transfer_ownership'],
        sync: false 
    },
    {   
        id: 'status',
        label: 'Status',
        required: ['add','update'],
        default: 'draft',
        type: 'string',
        field: 'text',
        skip: false,
        sync: true
    },

    {   
        id: 'accounts_linked',
        label: 'Account',
        required: false,
        default: [],
        type: 'array_ids',
        skip: false,
        sync: true
    },
    {   
        id: 'contacts_linked',
        label: 'Contact',
        required: false,
        default: [],
        type: 'array_ids',
        skip: false,
        sync: true
    },
    {   
        id: 'potentials_linked',
        label: 'Potential',
        required: false,
        default: [],
        type: 'array_ids',
        skip: false,
        sync: true
    },
    {   
        id: 'quotes_linked',
        label: 'Quote',
        required: false,
        default: [],
        type: 'array_ids',
        skip: false,
        sync: true
    },
    {   
        id: 'invoices_linked',
        label: 'Invoice',
        required: false,
        default: [],
        type: 'array_ids',
        skip: false,
        sync: true
    },
    {   
        id: 'products_linked',
        label: 'Product',
        required: false,
        default: [],
        type: 'array_ids',
        skip: false,
        sync: true
    },

    {   
        id: 'emails',
        label: 'Email(s)',
        required: ['add','update'],
        default: [],
        type: 'array_obj',
        field: 'select2',
        skip: false,
        sync: true
    },
    {   
        id: 'from',
        label: 'From',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'from_label',
        label: 'From (label)',
        required: false,
        default: '',
        type: 'string',
        skip: false,
        sync: true
    },

    {   
        id: 'subject',
        label: 'Subject Line',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'text',
        skip: false,
        sync: true
    },

    {   
        id: 'attachments',
        label: 'Attachment',
        required: false,
        default: [],
        type: 'array_obj',
        field: 'select2',
        skip: false,
        sync: true
    },

    {   
        id: 'created_by',
        label: 'Created By',
        required: false,
        default: '',
        type: 'system_data',
        skip: false,
        sync: true
    },
    {   
        id: 'created_on',
        label: 'Created On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: false,
        sync: true
    },
    {   
        id: 'modified_on',
        label: 'Modified On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: false,
        sync: true
    }
];