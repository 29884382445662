/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';
import _last from 'lodash/last';
import _merge from 'lodash/merge';

import {
	GET_CONTACTS,
	GET_CONTACTS_BY,
	GET_CONTACTS_OPTIONS,
	UPDATE_CONTACTS_OPTIONS_RAND,
	GET_CONTACT,
	ADD_CONTACT,
	EDIT_CONTACT,
	DELETE_CONTACT,
	IMPORT_CONTACTS_FROM_LMS,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections } from '../../helpers/data';
import { isArrayExists } from '../../helpers/validation';
import { appChangesReset, appChangesMade } from '../misc';

import { fb_getContacts, fb_getContactsFirstBatch, fb_getContactsForTheRest, fb_getContactsBy, fb_getContactsOptions, fb_getContact, fb_addContact, fb_duplicateContact, fb_updateContact, fb_massEditContact, fb_deleteContact, fb_mergeContacts } from './fb';

import { fb_importContactsFromLMS } from './import_from_lms';

/* helper start */
// const getContactsByBatch = (dispatch,props,callback) => {
// 	const { perPage } = props;
// 	var mainList = [];

// 	fb_getContactsFirstBatch(props)
// 	.then(({ list, accessLimited }) => {

// 		var endAt = false;
// 		mainList = cloneCollections( list );

// 		// calculate endAt
// 		if ( isArrayExists( mainList ) ) {
// 			let lastItem = _last( mainList );
// 			if ( lastItem && lastItem.modified_on ) {
// 				endAt = lastItem.modified_on;
// 			} // end - lastItem
// 		} // end - mainList

// 		// dispatch first batch
// 		dispatch({
// 			type: GET_CONTACTS,
// 			payload: { list, loaded: ( !accessLimited && isArrayExists( mainList ) && endAt ? false : true ) }
// 		});

// 		// trigger callback
// 		if ( callback ) {
// 			callback( dispatch );
// 		} // end - callback

// 		return ( !accessLimited && isArrayExists( mainList ) && endAt ? fb_getContactsForTheRest( endAt ) : false );
// 	})
// 	.then(list => {

// 		if ( list && isArrayExists( list ) ) {
// 			// dispatch other items
// 			dispatch({
// 				type: GET_CONTACTS,
// 				payload: { list: _merge( mainList, list ), loaded: true }
// 			});
// 		} // end - list
	
// 		// else - do nothing
// 	})
// 	.catch(error => {
// 		var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
// 		triggerErrorAlert(errMsg);
// 		dispatch({
// 			type: GET_CONTACTS,
// 			payload: { list: [] }
// 		});
// 	});

// }
/* helper end */

export const getContacts = (props) => {
	return dispatch => {

		// getContactsByBatch( dispatch, ( props || {} ) );

		fb_getContacts(props)  
		.then(({ list, total, firstDoc, lastDoc }) => {
			dispatch({
				type: GET_CONTACTS,
				payload: { list, total, firstDoc, lastDoc }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_CONTACTS,
				payload: { list: [] }
			});
		});

	}
}

export const getContactsBy = (type,id) => {
	return dispatch => {

		fb_getContactsBy(type,id)  
		.then(list => {
			dispatch({
				type: GET_CONTACTS_BY,
				payload: { list }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_CONTACTS_BY,
				payload: { list: {} }
			});
		});

	}
}

export const getContact = (contact_id) => {
	return dispatch => {

		fb_getContact(contact_id)  
		.then(contact => {
			dispatch({
				type: GET_CONTACT,
				payload: { contact }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve info' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_CONTACT,
				payload: { contact: {} }
			});
		});

	}
}

export const getContactsOptions = ({ account_id, authData }) => {
	return dispatch => {

		fb_getContactsOptions(list => {
			dispatch({
				type: GET_CONTACTS_OPTIONS,
				payload: { list }
			});	
		},{ account_id, authData });

	}
}

export const updateContactsOptionsRand = (rand) => {
	return dispatch => {
		setTimeout(() => {
			dispatch({
				type: UPDATE_CONTACTS_OPTIONS_RAND,
				payload: { rand }
			});	
		},500);
	}
}

export const addNewContact = (formData,history) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addContact(formData)
		.then( results => {
			if ( results && results.new_contact_id && !_isEmpty( results.new_contact_id ) ) {

				fb_getContact( results.new_contact_id )
				.then( contact => {

					// updated
					dispatch({ type: GET_CONTACT, payload: { contact } });
					dispatch(toggleModalProcessing(false));
					triggerSuccessAlert("Contact Added");

					// redirect to contact page
					history.push("/contacts/" + results.new_contact_id);

				});

			} else {
				dispatch(toggleModalProcessing(false,apiNum));
				triggerErrorAlert('Missing new contact ID');
			}
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new contact' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const duplicateContact = (formData,history) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_duplicateContact(formData)
		.then( results => {
			if ( results && results.new_contact_id && !_isEmpty( results.new_contact_id ) ) {

				fb_getContact( results.new_contact_id )
				.then( contact => {

					// updated
					dispatch({ type: GET_CONTACT, payload: { contact } });
					dispatch(toggleModalProcessing(false));
					triggerSuccessAlert("Contact Added");

					// redirect to contact page
					history.push("/contacts/" + results.new_contact_id);

				});

			} else {
				dispatch(toggleModalProcessing(false,apiNum));
				triggerErrorAlert('Missing new contact ID');
			}
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new contact' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const editContact = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateContact(formData)
		.then( results => {

			fb_getContact(formData.id)
			.then(contact => {
				// push to list
				dispatch({ type: GET_CONTACT, payload: { contact } });

				// updated
				dispatch({ type: EDIT_CONTACT });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Contact Updated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update contact' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const massEditContact = (selected,formData,props) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));
		dispatch(toggleLoader(true));

		fb_massEditContact(selected,formData)
		.then( results => {

			// setTimeout(() => {
			// 	// retrieve new list
			// 	getContactsByBatch( dispatch, ( props || {} ), (dispatch) => {
			// 		// trigger updated
			// 		dispatch({ type: EDIT_CONTACT });
			// 		dispatch(toggleModalProcessing(false));
			// 		dispatch(toggleLoader(false));
			// 		triggerSuccessAlert("Selected Contact(s) Updated");

			// 	});
			// }, 5000);

			// retrieve new list
			fb_getContacts(props)  
			.then(({ list, total, firstDoc, lastDoc }) => {
				dispatch({
					type: GET_CONTACTS,
					payload: { list, total, firstDoc, lastDoc }
				});

				// trigger updated
				dispatch({ type: EDIT_CONTACT });
				dispatch(toggleModalProcessing(false));
				dispatch(toggleLoader(false));
				triggerSuccessAlert("Selected Contact(s) Updated");

			});
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update contact' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const changeContactAccount = (contact_id,formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));
		dispatch(toggleLoader(true));

		setTimeout(() => {
			// trigger updated
			dispatch({ type: EDIT_CONTACT });
			dispatch(toggleModalProcessing(false));
			dispatch(toggleLoader(false));
			triggerSuccessAlert("Contact's Account Updated");
		},1500)

		// fb_massEditContact([{ id: contact_id }],formData)
		// .then( results => {
		// 	// trigger updated
		// 	dispatch({ type: EDIT_CONTACT });
		// 	dispatch(toggleModalProcessing(false));
		// 	dispatch(toggleLoader(false));
		// 	triggerSuccessAlert("Contact's Account Updated");
		// })
		// .catch( error => {
		// 	dispatch(toggleModalProcessing(false,apiNum));
		// 	dispatch(toggleLoader(false));
		// 	var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update contact' ) );
		// 	triggerErrorAlert(errMsg);
		// });

	}
}

export const changeContactAccountViaPromise = (contact_id,formData,dispatch) => {
	return new Promise((resolve,reject) => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));
		dispatch(toggleLoader(true));

		// setTimeout(() => {
		// 	// trigger updated
		// 	dispatch({ type: EDIT_CONTACT });
		// 	dispatch(toggleModalProcessing(false));
		// 	dispatch(toggleLoader(false));
		// 	triggerSuccessAlert("Contact's Account Updated");
		// 	resolve('done');
		// },1500)

		fb_massEditContact([{ id: contact_id }],formData)
		.then( results => {
			// trigger updated
			dispatch({ type: EDIT_CONTACT });
			dispatch(toggleModalProcessing(false));
			dispatch(toggleLoader(false));
			triggerSuccessAlert("Contact's Account Updated");
			resolve('done');
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update contact' ) );
			triggerErrorAlert(errMsg);
			resolve(false);
		});
	})
}

export const deleteContact = (id,props) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteContact(id)
		.then( results => {

			// setTimeout(() => {
			// 	// retrieve new list
			// 	getContactsByBatch( dispatch, ( props || {} ), (dispatch) => {
			// 		// trigger updated
			// 		dispatch({ type: DELETE_CONTACT });
			// 		dispatch(toggleModalDeleting(false));
			// 		triggerSuccessAlert("Contact Deleted");
			// 	});
			// }, 5000);

			// retrieve new list
			fb_getContacts(props)  
			.then(({ list, total, firstDoc, lastDoc }) => {
				dispatch({
					type: GET_CONTACTS,
					payload: { list, total, firstDoc, lastDoc }
				});

				// trigger updated
				dispatch({ type: DELETE_CONTACT });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("Contact Deleted");

			});

		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete list' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

/* merge functions */
export const mergeContacts = (selected,formData,props) => {
	return dispatch => {
		var apiNum = _random(1,9999);
			dispatch(toggleModalProcessing(true,apiNum));
			dispatch(toggleLoader(true));

			fb_mergeContacts(selected,formData)
			.then( results => {

				// retrieve new list
				fb_getContacts(props)  
				.then(({ list, total, firstDoc, lastDoc }) => {
					dispatch({
						type: GET_CONTACTS,
						payload: { list, total, firstDoc, lastDoc }
					});

					// trigger updated
					dispatch({ type: EDIT_CONTACT });
					dispatch(toggleModalProcessing(false));
					dispatch(toggleLoader(false));
					triggerSuccessAlert("Selected contacts marged");

				});
			})
			.catch( error => {
				dispatch(toggleModalProcessing(false,apiNum));
				dispatch(toggleLoader(false));
				var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to merge contacts' ) );
				triggerErrorAlert(errMsg);
			});

	}
}

/* import contacts from lms functions */
export const importContactsFromLMS = (props) => {
	const { start_date, end_date } = props;
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_importContactsFromLMS({ start_date, end_date })
		.then(({ contacts, trainees })=> {
			dispatch({
				type: IMPORT_CONTACTS_FROM_LMS,
				payload: { contacts, trainees }
			});
			dispatch(toggleLoader(false));
			dispatch(appChangesMade());
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to import contacts from LMS' ) );
			triggerErrorAlert(errMsg);
		});

	}
}