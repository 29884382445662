import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _remove from "lodash/remove";
import _isEqual from "lodash/isEqual";

/* eslint-disable */

import AppWrapper from "../../components/AppWrapper";
import DotsLoader from "../../components/DotsLoader";
import SnackBarSave from "../../components/SnackBarSave";

import Details from "./details";
import ContactsList from "../../components/ListContacts";
import PotentialsList from "../../components/ListPotentials";
import QuotesList from "../../components/ListQuotes";
import InvoicesList from "../../components/ListInvoices";
import CreditNotesList from "../../components/ListCreditNotes";
import EmailsList from "../../components/ListEmails";
import ItemNotFound from "../../components/ItemNotFound";

import { InfoButton, GreyButton } from "../../styles/button";

import { hasAccessRights } from "../../helpers/auth";
import { isArrayExists, isObjectExists, validateEmail } from "../../helpers/validation";
import { cloneCollections } from "../../helpers/data";
import { triggerErrorAlert } from "../../helpers/alert";
import { doSchemaErrorCheck } from "../../helpers/schemas";

import { getAccount, editAccount } from "../../actions/accounts";
import { getContactsBy } from "../../actions/contacts";
import { getPotentialsBy } from "../../actions/potentials";
import { getQuotesBy } from "../../actions/quotes";
import { getInvoicesBy } from "../../actions/invoices";
import { getCreditNotesBy } from "../../actions/credit_notes";
import { getEmailsBy } from "../../actions/emails";
import { getUsersOptions } from "../../actions/users";
import { getCoursePortfolios } from "../../actions/m_course_portfolio";
import { getAccountTier2 } from "../../actions/m_accounts_tier_2";
import { getMailingLists } from "../../actions/m_mailing_lists";
import { appChangesMade, resetRedux } from "../../actions/misc";

import { accountSchema } from "../../schemas/account";

class AccountPage extends React.Component {
    state = {
        currentTab: "details",
        account: false,
        mailingListMessages: [],
        contacts: false,
        contactsRand: false,
        potentials: false,
        potentialsRand: false,
        quotes: false,
        quotesRand: false,
        invoices: false,
        invoicesRand: false,
        creditNotes: false,
        creditNotesRand: false,
        emails: false,
        emailsRand: false,
        randNum: false,
    };

    componentDidMount() {
        const { account_id } = this.props.match.params;
        const { coursePortfolioList, accountsTier2List } = this.props;

        // retrieve account details
        this.props.dispatch(getAccount(account_id));

        // get contacts by
        this.props.dispatch(getContactsBy("accounts", account_id));

        // get potentials by
        this.props.dispatch(getPotentialsBy("accounts", account_id));

        // get quotes by
        this.props.dispatch(getQuotesBy("accounts", account_id));

        // get invoices by
        this.props.dispatch(getInvoicesBy("accounts", account_id));

        // get credit notes by
        this.props.dispatch(getCreditNotesBy("accounts", account_id));

        // get emails by
        this.props.dispatch(getEmailsBy("accounts", account_id));

        // get all users
        if (!this.props.usersOptions) this.props.dispatch(getUsersOptions());

        // get course portfolio
        if (!coursePortfolioList) this.props.dispatch(getCoursePortfolios());

        // get account tier 2 options
        if (!accountsTier2List) this.props.dispatch(getAccountTier2());

        // get mailing lists
        if (!this.props.mailingList) this.props.dispatch(getMailingLists());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            currentAccount,
            randNum,
            usersOptions,
            coursePortfolioList,
            accountsTier2List,
            mailingList,
            contactsByList,
            contactsByListRand,
            potentialsByList,
            potentialsByListRand,
            quotesByList,
            quotesByListRand,
            invoicesByList,
            invoicesByListRand,
            creditNotesByList,
            creditNotesByListRand,
            emailsByList,
            emailsByListRand,
        } = this.props;

        // for account
        if (currentAccount && usersOptions && coursePortfolioList && accountsTier2List && mailingList && randNum && randNum !== this.state.randNum) {
            this.setState({ account: cloneCollections(currentAccount), randNum, mailingListMessages: [] });
        } // end - currentAccount

        // for contacts
        if (contactsByList && contactsByListRand && contactsByListRand !== this.state.contactsRand) {
            this.setState({ contacts: cloneCollections(contactsByList), contactsRand: contactsByListRand });
        }

        // for potentials
        if (potentialsByList && potentialsByListRand && potentialsByListRand !== this.state.potentialsRand) {
            this.setState({ potentials: cloneCollections(potentialsByList), potentialsRand: potentialsByListRand });
        }

        // for quotes
        if (quotesByList && quotesByListRand && quotesByListRand !== this.state.quotesRand) {
            this.setState({ quotes: cloneCollections(quotesByList), quotesRand: quotesByListRand });
        }

        // for invoices
        if (invoicesByList && invoicesByListRand && invoicesByListRand !== this.state.invoicesRand) {
            this.setState({ invoices: cloneCollections(invoicesByList), invoicesRand: invoicesByListRand });
        }

        // for credit notes
        if (creditNotesByList && creditNotesByListRand && creditNotesByListRand !== this.state.creditNotesRand) {
            this.setState({ creditNotes: cloneCollections(creditNotesByList), creditNotesRand: creditNotesByListRand });
        }

        // for emails
        if (emailsByList && emailsByListRand && emailsByListRand !== this.state.emailsRand) {
            this.setState({ emails: cloneCollections(emailsByList), emailsRand: emailsByListRand });
        }
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux("account"));
    }

    handleRefresh = (type) => {
        const { account_id } = this.props.match.params;
        switch (type) {
            case "contacts":
                this.setState({ contacts: false });
                this.props.dispatch(getContactsBy("accounts", account_id));
                break;
            case "potentials":
                this.setState({ potentials: false });
                this.props.dispatch(getPotentialsBy("accounts", account_id));
                break;
            case "quotes":
                this.setState({ quotes: false });
                this.props.dispatch(getQuotesBy("accounts", account_id));
                break;
            case "invoices":
                this.setState({ invoices: false });
                this.props.dispatch(getInvoicesBy("accounts", account_id));
                break;
            case "credit_notes":
                this.setState({ creditNotes: false });
                this.props.dispatch(getCreditNotesBy("accounts", account_id));
                break;
            case "emails":
                this.setState({ emails: false });
                this.props.dispatch(getEmailsBy("accounts", account_id));
                break;
        }
    };

    handleSaveChanges = (event) => {
        const { account, mailingListMessages } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do error check
        accountSchema.forEach((schema) => {
            formData[schema.id] = account && account[schema.id] ? cloneCollections(account[schema.id]) : schema.default;
            if (!doSchemaErrorCheck(formData[schema.id], schema, "update")) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        // do einvoice related error check
        if (formData.tin_no && !_isEmpty(formData.tin_no)) {
            // (validation: upto 13 alphanumeric characters)
            if (!(formData.tin_no.length <= 13 && /^[a-zA-Z0-9]*$/.test(formData.tin_no))) {
                error = "Tax Identification Number (TIN) should be up to 13 alphanumeric characters";
            }
        }

        if (formData.sst_no && !_isEmpty(formData.sst_no)) {
            // validation: Xnn-nnnn-nnnnnnnn (X=alphabet, n=numeric)
            if (!/^[A-Z]\d{2}-\d{4}-\d{8}$/.test(formData.sst_no)) {
                error = "SST Number should be in the format of Xnn-nnnn-nnnnnnnn (X=alphabet, n=numeric)";
            }
        }

        if (formData.einv_notification_email && !_isEmpty(formData.einv_notification_email)) {
            // validation: email format
            if (!validateEmail(formData.einv_notification_email)) {
                error = "E-invoicing notification email should be a valid email address";
            }
        }

        if (formData.msic_code && !_isEmpty(formData.msic_code)) {
            // validation: it needs to start with 5-digit number
            if (!/^\d{5}/.test(formData.msic_code)) {
                error = "MSIC Code should start with a 5-digit number";
            }
        }

        // add in mailing_lists_trigger if needed
        if (mailingListMessages && isArrayExists(mailingListMessages)) {
            // set as new array
            formData.mailing_lists_trigger = [];
            _forEach(mailingListMessages, (list) => {
                if (list && list.id && !_isEmpty(list.id)) {
                    formData.mailing_lists_trigger.push({
                        id: list.id,
                        name: list.name || "",
                        type: list.type || "",
                    });
                } // end - list
            });
        } // end - mailingListMessages

        if (error) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editAccount(formData));
        } // end - error
    };

    handleFormUpdate = (newValue) => {
        const { currentAccount } = this.props;
        const { mailingListMessages, account } = this.state;
        let newMessages = mailingListMessages && isArrayExists(mailingListMessages) ? cloneCollections(mailingListMessages) : [];

        // only trigger if changes in mailing_lists
        if (!_isEqual(account.mailing_lists, newValue.mailing_lists)) {
            // reset messages to empty array
            newMessages = [];

            const existingMailingLists =
                currentAccount && currentAccount.mailing_lists && isArrayExists(currentAccount.mailing_lists)
                    ? cloneCollections(currentAccount.mailing_lists)
                    : [];

            // run through new list to look for new list added
            if (newValue && newValue.mailing_lists && isArrayExists(newValue.mailing_lists)) {
                _forEach(newValue.mailing_lists, (list) => {
                    let exists = _find(existingMailingLists, { id: list.id });
                    if (exists) {
                        // if exists remove from the compare list
                        let pulled = _remove(existingMailingLists, { id: list.id });
                    } else {
                        // add warning message
                        newMessages.push({
                            id: list.id,
                            name: list.name || "",
                            message: "", // no message so that it won't display
                            type: "add",
                        });
                    } // end - exists
                });
            } // end - newValue.mailing_lists

            // if still not empty, add remove message
            if (existingMailingLists && isArrayExists(existingMailingLists)) {
                _forEach(existingMailingLists, (list) => {
                    // add warning message
                    newMessages.push({
                        id: list.id,
                        name: list.name || "",
                        message: "WARNING: All the contacts under this account will be removed from this mailing list.",
                        type: "remove",
                    });
                });
            } // end - existingMailingLists
        } // end - mailing_lists

        this.setState({ account: newValue, mailingListMessages: newMessages });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    };

    handleTabChange = (newTab) => {
        this.setState({ currentTab: newTab });
    };

    handleMailingListMessagesUpdate = (id) => {
        const { mailingListMessages } = this.state;
        let newMessages = mailingListMessages && isArrayExists(mailingListMessages) ? cloneCollections(mailingListMessages) : [];
        if (_find(newMessages, { id })) {
            let pulled = _remove(newMessages, { id });
        }
        this.setState({ mailingListMessages: newMessages });
    };

    getLinks = () => {
        const { authData } = this.props;
        let links = [{ id: "details", label: "Account Info", icon: "fa fa-cog" }];

        if (authData && hasAccessRights(authData, ["ctr", "ctw"])) links.push({ id: "contacts", label: "Contacts", icon: "fa fa-user" });

        if (authData && hasAccessRights(authData, ["ptr", "ptw"])) links.push({ id: "potentials", label: "Potentials", icon: "fa fa-bookmark" });

        if (authData && hasAccessRights(authData, ["qtr", "qtw"])) links.push({ id: "quotes", label: "Quotes", icon: "fa fa-edit" });

        if (authData && hasAccessRights(authData, ["ivr", "ivw"])) links.push({ id: "invoices", label: "Invoices", icon: "fa fa-file-pdf-o" });

        if (authData && hasAccessRights(authData, ["cnr", "cnw"]))
            links.push({ id: "credit_notes", label: "Credit Notes", icon: "fa fa-file-text-o" });

        if (authData && hasAccessRights(authData, ["acs"])) links.push({ id: "emails", label: "Emails", icon: "fa fa-envelope" });

        return links;
    };

    renderTabContents = () => {
        const { account_id } = this.props.match.params;
        const { authData, currentAccount, usersOptions, coursePortfolioList, accountsTier2List, mailingList, changesMade } = this.props;
        const { currentTab, account, contacts, potentials, quotes, invoices, creditNotes, emails, mailingListMessages } = this.state;
        const tabProps = {
            authData: authData || false,
            account: account || false,
            changesMade: changesMade || false,
            onFormUpdate: this.handleFormUpdate,
        };
        switch (currentTab) {
            case "details":
                return (
                    <Details
                        isAccountPage={true}
                        currentAccount={currentAccount || false}
                        users={usersOptions || []}
                        mailingListMessages={mailingListMessages || []}
                        course_portfolio={coursePortfolioList || []}
                        accounts_tier_2={accountsTier2List || []}
                        mailing_lists={mailingList || []}
                        onMailingListMessagesUpdate={this.handleMailingListMessagesUpdate}
                        updateActions={[getAccount(account_id)]}
                        {...tabProps}
                    />
                );
            case "contacts":
                return contacts ? (
                    <ContactsList
                        users={usersOptions || []}
                        contacts={contacts}
                        changesMade={changesMade}
                        onRefresh={this.handleRefresh}
                        {...tabProps}
                    />
                ) : (
                    <DotsLoader />
                );
            case "potentials":
                return potentials ? (
                    <PotentialsList
                        users={usersOptions || []}
                        potentials={potentials}
                        changesMade={changesMade}
                        onRefresh={this.handleRefresh}
                        {...tabProps}
                    />
                ) : (
                    <DotsLoader />
                );
            case "quotes":
                return quotes ? (
                    <QuotesList users={usersOptions || []} quotes={quotes} changesMade={changesMade} onRefresh={this.handleRefresh} {...tabProps} />
                ) : (
                    <DotsLoader />
                );
            case "invoices":
                return invoices ? (
                    <InvoicesList
                        users={usersOptions || []}
                        invoices={invoices}
                        changesMade={changesMade}
                        onRefresh={this.handleRefresh}
                        {...tabProps}
                    />
                ) : (
                    <DotsLoader />
                );
            case "credit_notes":
                return creditNotes ? (
                    <CreditNotesList
                        users={usersOptions || []}
                        credit_notes={creditNotes}
                        changesMade={changesMade}
                        onRefresh={this.handleRefresh}
                        {...tabProps}
                    />
                ) : (
                    <DotsLoader />
                );
            case "emails":
                return emails ? (
                    <EmailsList
                        type="accounts"
                        typeID={account_id || false}
                        emails={emails}
                        changesMade={changesMade}
                        onRefresh={this.handleRefresh}
                        {...tabProps}
                    />
                ) : (
                    <DotsLoader />
                );
        }
    };

    renderTabs = () => {
        const { currentTab } = this.state;
        const btnStyle = {
            textAlign: "center",
            minWidth: "175px",
            padding: "10px 5px",
            marginRight: "5px",
        };
        return (
            <Box display="flex" justifyContent="center" alignItems="center">
                {this.getLinks().map((button, index) => {
                    if (button.id === currentTab) {
                        return (
                            <InfoButton key={index} style={btnStyle} onClick={(event) => event.preventDefault()}>
                                {button.icon ? <i className={button.icon}></i> : ""}
                                {button.label}
                            </InfoButton>
                        );
                    } else {
                        return (
                            <GreyButton key={index} style={btnStyle} onClick={this.handleTabChange.bind(this, button.id)}>
                                {button.icon ? <i className={button.icon}></i> : ""}
                                {button.label}
                            </GreyButton>
                        );
                    }
                })}
            </Box>
        );
    };

    renderContents() {
        return (
            <div>
                {this.renderTabs()}

                <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                    {this.renderTabContents()}
                </Paper>

                <div style={{ textAlign: "center", marginTop: "45px" }}>
                    <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}>
                        <i className="fa fa-save"></i>Save Changes
                    </InfoButton>
                </div>

                <SnackBarSave onSave={this.handleSaveChanges} />
            </div>
        );
    }

    render() {
        const { randNum } = this.state;
        const { currentAccount } = this.props;
        return (
            <AppWrapper
                title="Edit Account"
                subtitle="Accounts"
                back="/accounts"
                breadcrumbs={[{ url: "/accounts", label: "Accounts" }, { label: "Edit" }]}
                maxWidth="1110px"
                onLoad={!randNum ? true : false}
                contents={
                    currentAccount && currentAccount.id && !_isEmpty(currentAccount.id) ? this.renderContents() : <ItemNotFound item="Account" />
                }
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: (state.auth && state.auth.user) || null,
        currentAccount: (state.accounts && state.accounts.account) || null,
        randNum: (state.accounts && state.accounts.rand) || null,
        contactsByList: (state.contacts && state.contacts.contacts_by) || null,
        contactsByListRand: (state.contacts && state.contacts.by_rand) || null,
        potentialsByList: (state.potentials && state.potentials.potentials_by) || null,
        potentialsByListRand: (state.potentials && state.potentials.by_rand) || null,
        quotesByList: (state.quotes && state.quotes.quotes_by) || null,
        quotesByListRand: (state.quotes && state.quotes.by_rand) || null,
        invoicesByList: (state.invoices && state.invoices.invoices_by) || null,
        invoicesByListRand: (state.invoices && state.invoices.by_rand) || null,
        creditNotesByList: (state.credit_notes && state.credit_notes.credit_notes_by) || null,
        creditNotesByListRand: (state.credit_notes && state.credit_notes.by_rand) || null,
        emailsByList: (state.emails && state.emails.emails_by) || null,
        emailsByListRand: (state.emails && state.emails.by_rand) || null,
        usersOptions: (state.users && state.users.users_options) || null,
        coursePortfolioList: (state.maintenance && state.maintenance.course_portfolio) || null,
        accountsTier2List: (state.maintenance && state.maintenance.accounts_tier_2) || null,
        mailingList: (state.maintenance && state.maintenance.mailing_lists) || null,
        changesMade: (state.misc && state.misc.changes_made) || null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(AccountPage);
