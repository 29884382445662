import _random from 'lodash/random';

import { 
    GET_EMAILS_BY,
	GET_EMAIL,
	ADD_EMAIL,
	EDIT_EMAIL,
    DELETE_EMAIL,
    RESET_EMAIL,
    RESET_GET_EMAILS_BY
} from '../actions/types';

const initialState = {
    emails_by: null,
    email: null,
    new_email_id: null,
    updated: null,
    by_rand: false,
    rand: false
};

export const emails = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_EMAILS_BY:
            return {
                ...state,
                emails_by: ( payload.list || [] ),
                by_rand: _random(1,9999)
            };
        case GET_EMAIL:
            return {
                ...state,
                email: ( payload.email || {} ),
                rand: _random(1,9999)
            };
        case ADD_EMAIL:
            return {
                ...state,
                new_email_id: ( payload.id || false ),
                rand: _random(1,9999)
            };
        case EDIT_EMAIL:
        case DELETE_EMAIL:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_EMAIL:
            return {
                ...state,
                email: null,
                updated: null,
                rand: false
            };
        case RESET_GET_EMAILS_BY:
            return {
                ...state,
                emails_by: null,
                by_rand: false
            };    
        default:
            return state;
    }
}