/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import _isEmpty from 'lodash/isEmpty';
import _split from 'lodash/split';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _filter from 'lodash/filter';

import { isArrayExists } from '../../helpers/validation';
import { compareTimeFromMoment } from '../../helpers/date';

import { appChangesReset } from '../../actions/misc';

class ListUpdates extends React.Component {

    handleLinkClick = item => event => {
        const { history, changesMade } = this.props;
        event.preventDefault();
        let url = '/'+item.type+'s/'+item.type_id;

        // check if changes already made before redirect
        if ( changesMade ) {
            let answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
            // if answer is yes - proceed
            if ( answer ) {
                this.props.dispatch(appChangesReset());
                history.push(url);
            }
        } else {
            this.props.dispatch(appChangesReset());
            history.push(url);
        } // end - changesMade
        
    }

    renderText = (item) => {
        var textArray = ( item.text && !_isEmpty( item.text ) ? _split( item.text, '\n' ) : false );
    return ( textArray && isArrayExists( textArray ) ? <div style={{ fontSize: "1.15rem", fontStyle: "italic" }}>{textArray.map((text,index) => (<div key={index}>{text}</div>))}</div> : null );
    }

    renderActiontargetType = (item) => {
        return ( item.type && !_isEmpty( item.type ) ? ' in ' + item.type : '' );
    }

    renderActiontarget = (item) => {
        return ( item.type_id && !_isEmpty( item.type_id ) && item.type_label && !_isEmpty( item.type_label ) && item.type && !_isEmpty( item.type ) ? <a href={'/'+item.type+'s/'+item.type_id} onClick={this.handleLinkClick(item)} style={{ margin: "0px 3px", fontWeight: "700" }}>{item.type_label}</a> : '' );
    }

    renderAction = (item) => {
        return ( item.action_type && !_isEmpty( item.action_type ) ? item.action_type : '' );
    }

    renderWho = (item) => {
        return ( item.user_name && !_isEmpty( item.user_name ) ? <strong style={{ marginRight: "3px", fontWeight: "700" }}>{item.user_name}</strong> : ( item.user && !_isEmpty( item.user ) ? <strong style={{ marginRight: "3px", fontWeight: "700" }}>{item.user}</strong> : null ) );
    }

    renderUpdateText = (item) => {
        const { showActionTarget } = this.props;
        return (
        <div style={{ fontSize: "1.15rem" }}>
            {this.renderWho(item)}
            {this.renderAction(item)}
            { showActionTarget ? this.renderActiontarget(item) : null }
            { showActionTarget ? this.renderActiontargetType(item) : null }
            {this.renderText(item)}
        </div>
        )
    }

    renderItem = (item) => {
        return (
        <Box id={item.id} key={item.id} display="flex" alignItems="center" justifyContent="space-between" style={{ marginBottom: "10px" }}>
            {this.renderUpdateText(item)}
            { item.logged_on ? <div style={{ width: "20%", minWidth: "90px", fontSize: "1rem", textAlign: 'right' }}>{compareTimeFromMoment(item.logged_on) + ' ago'}</div> : null }
        </Box>
        );
    }

    renderList = () => {
        const { list } = this.props;
        // filter out beta test log
        let items = _filter( list, i => i.logged_on && i.logged_on > 1590940800000 );
        // sort by date
        items = _sortBy( items, ['logged_on'] );
        items = _reverse( items );
        return items.map(this.renderItem);
    }

    render() {
        const { list } = this.props;
        return (
        <div style={{ padding: "15px 15px 5px 15px", background: "#f1f1f1", marginTop: "15px" }}>
            { list && isArrayExists( list ) ? this.renderList() : 'No update(s) yet' }
        </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ListUpdates);