/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';

import ModalView from '../ModalView';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';
import FormSelect2 from '../FormSelect2';
import FormMultiSelect from '../FormMultiSelect';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';

import { addNewEmailTemplate } from '../../actions/emailTemplates';

const initialState = {
    name: '',
    desc: ''
};

class AddNewEmailTemplate extends React.Component {

    state = {
        data: initialState,
        randNum: false
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { open } = this.props;
        if ( open && !prevProps.open ) {
            this.setState({ data: this.getInitialValues() }); // reset 
        }
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose ) {
            this.setState({ data: this.getInitialValues() }); // reset 
            onClose();
        } // end - onClose
    }

    handleFormUpdate = (newValue,key) => {
        const { categories } = this.props;
        const { data } = this.state;
        var newData = ( data ? cloneCollections( data ) : {} );
        newData[key] = newValue;

        this.setState({ data: newData });
    }

    handleAddNew = () => {
        const { history } = this.props;
        const { data } = this.state;
        var error = false,
            formData = cloneCollections( data );

        if ( !( formData && formData.name && !_isEmpty( formData.name ) ) ) {
            error = 'Please enter a valid template name';
        } // end - formData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addNewEmailTemplate(formData,history));
        } // end - error
    }

    getInitialValues = () => {
        const { authData } = this.props;
        var data = cloneCollections( initialState );
        return data;
    }

    renderAddNewForm = () => {
        const { data } = this.state;
        return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormInput label="Name *" name="name" value={( data.name || '' )} onChange={this.handleFormUpdate} />
                    <FormInput label="Description" name="desc" value={( data.desc || '' )} rows={3} multiline={true} onChange={this.handleFormUpdate} />
                </Grid>
            </Grid>
        </div>
        );
    }

    render() {
        const { open } = this.props;
        return (
        <div>

            <ModalView 
                open={open}
                title="Add New Email Template"
                onClose={this.handleClose}
                doAction={this.handleAddNew}
                contents={this.renderAddNewForm()} />

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(AddNewEmailTemplate);