import React from 'react';
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { ThemeProvider } from '@zendeskgarden/react-theming';
import {
  Table,
  Caption,
  Head,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell
} from '@zendeskgarden/react-tables';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _sortBy from 'lodash/sortBy';
import _round from 'lodash/round';
import _random from 'lodash/random';

/* eslint-disable */

import ModalSelectDiscount from '../../components/ModalSelectDiscount';

import { FormBox } from '../../styles/form';
import { InverseButton, GreyButton } from '../../styles/button';

import { isAdmin } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { reverseUsersValues } from '../../helpers/users';
import { getMomentTime } from '../../helpers/date';
import { formatMoney } from '../../helpers/number';
import { getTotal, getTotalDiscountAmount, getPreTaxTotal, getTaxAmount, getGrandTotal, getTaxRate } from '../../helpers/quotes';

import { quoteSchema } from '../../schemas/quote';

const useStyles = theme => ({
    tablewrapper: {
        margin: '30px 0',
        '& .MuiTextField-root': {
            margin: theme.spacing(0),
            width: '100%',
        },
        '& input.MuiOutlinedInput-input, & div.MuiOutlinedInput-multiline': {
            padding: theme.spacing(1)
        }
    },
    cellhead: {
        fontWeight: '700',
        justifyContent: 'flex-end'
    },
    cellbody: {
        justifyContent: 'flex-end'
    },
    adjustmentfield: {
        maxWidth: '100px',
        '& .MuiOutlinedInput-input': {
            textAlign: 'right'
        }
    }
});

class QuoteTotals extends React.Component {

    state = {
        openSelectDiscount: false,
        selectItem: false
    };

    handleFormUpdate = ( newValue, key ) => {
        const { onFormUpdate, quote } = this.props;
        let newData = ( quote && !_isEmpty( quote ) ? cloneCollections( quote ) : {} );

        switch( key ) {
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    handleDiscountSelect = ({ discount,type }) => {
        const { onFormUpdate, quote } = this.props;
        let newData = ( quote && !_isEmpty( quote ) ? cloneCollections( quote ) : {} );

        newData.group_discount = ( discount || 0 );
        newData.group_discount_type = ( type || '' );

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );

        // reset modal
        this.setState({ openSelectDiscount: false, selectItem: false });
    }

    handleFieldUpdate = key => event => {
        this.handleFormUpdate(event.target.value,key);
    }

    getSelectedRegion = (type) => {
        const { quote, regions, isAddNew, authData } = this.props;
        let selected_company = ( quote && quote.company_details && !_isEmpty( quote.company_details ) ? quote.company_details : ( isAddNew && authData && authData.region && !_isEmpty( authData.region ) ? authData.region : '' ) ),
            region = ( selected_company && !_isEmpty( selected_company ) && regions && isArrayExists( regions ) ? _find( regions, { id: selected_company } ) : false );
        if ( type && !_isEmpty( type ) ) {
            return ( region[type] || '' );
        } else {
            return region
        } // end - type
    }

    isIndividualTax = () => {
        const { quote } = this.props;
        let region = this.getSelectedRegion();
        return ( quote.tax_mode && quote.tax_mode === 'individual' && region && region.tax_amount && region.tax_label && !_isEmpty( region.tax_label ) ? true : false );
    }

    isGroupTax = () => {
        const { quote } = this.props;
        let region = this.getSelectedRegion();
        return ( quote.tax_mode && quote.tax_mode === 'group' && region && region.tax_amount && region.tax_label && !_isEmpty( region.tax_label ) ? true : false );
    }

    renderCurrencyFormat = (number) => {
        return formatMoney( _round(number,2), 2, 3 );
    }

    renderAdjustmentBtn = (type,label) => {
        const { quote } = this.props;
        const button = {
            size: 'small',
            noIconMargin: 'yes',
            minWidth: '24px',
            style: {
                marginRight: '5px'
            },
            onClick: () => this.handleFormUpdate(type,'total_adjustment_type')
        };
        if ( quote && quote.total_adjustment_type && quote.total_adjustment_type === type ) {
            return <Tooltip title={label} arrow><InverseButton {...button}><i className={"fa fa-"+( type == 'add' ? 'plus' : 'minus' )}></i></InverseButton></Tooltip>
        } else {
            return <Tooltip title={label} arrow><GreyButton {...button}><i className={"fa fa-"+( type == 'add' ? 'plus' : 'minus' )}></i></GreyButton></Tooltip>
        } // end - quote
    }

    render() {
        const { classes, quote } = this.props;
        const { openSelectDiscount, selectItem } = this.state;
        return (
        <div className={classes.tablewrapper}>

            <ThemeProvider>
                <Table>
                    <Body>
                        <Row>
                            <Cell width="50%" className={classes.cellhead}>Sub Total</Cell>
                            <Cell width="50%" className={classes.cellbody}>{this.renderCurrencyFormat( getTotal(quote,this.isIndividualTax(),this.getSelectedRegion('tax_amount')), 2 )}</Cell>
                        </Row>
                        <Row>
                            <Cell width="50%" className={classes.cellhead}>(-) <span style={{ fontWeight: '700', cursor: 'pointer', color: '#1976d2', textDecoration: 'underline', marginLeft: '5px' }} onClick={() => this.setState({ openSelectDiscount: _random(1,9999), selectItem: { discount_type: ( quote && quote.group_discount_type || 'fixed' ), discount: ( quote && quote.group_discount || 0 ) } })}>Discount</span></Cell>
                            <Cell width="50%" className={classes.cellbody}>{this.renderCurrencyFormat( getTotalDiscountAmount(quote,this.isIndividualTax(),this.getSelectedRegion('tax_amount')), 2 )}</Cell>
                        </Row>

                        { this.isGroupTax() ? (
                        <Row>
                            <Cell width="50%" className={classes.cellhead}>Pre Tax Total</Cell>
                            <Cell width="50%" className={classes.cellbody}>{this.renderCurrencyFormat( getPreTaxTotal(quote,this.isIndividualTax(),this.getSelectedRegion('tax_amount')), 2 )}</Cell>
                        </Row>
                        ) : null }

                        { this.isGroupTax() ? (
                        <Row>
                            <Cell width="50%" className={classes.cellhead}>
                                {this.getSelectedRegion('tax_label')}
                                <span style={{ paddingLeft: '3px' }}>({getTaxRate(quote,this.getSelectedRegion('tax_amount'))}%)</span>
                            </Cell>
                            <Cell width="50%" className={classes.cellbody}>{this.renderCurrencyFormat( getTaxAmount(quote,this.isIndividualTax(),this.getSelectedRegion('tax_amount')), 2 )}</Cell>
                        </Row>
                        ) : null }

                        <Row>
                            <Cell width="50%" className={classes.cellhead}>Adjustment</Cell>
                            <Cell width="50%" className={classes.cellbody}>
                                {this.renderAdjustmentBtn('add','Add')}
                                {this.renderAdjustmentBtn('deduct','Deduct')}
                                <TextField label="" value={( quote.total_adjustment || 0 )} size="small" type="number" variant="outlined" className={classes.adjustmentfield} onChange={this.handleFieldUpdate('total_adjustment')} />
                            </Cell>
                        </Row>
                        <Row>
                            <Cell width="50%" className={classes.cellhead}>Grand Total</Cell>
                            <Cell width="50%" className={classes.cellbody}>{this.renderCurrencyFormat( getGrandTotal(quote,this.isIndividualTax(),this.getSelectedRegion('tax_amount')) )}</Cell>
                        </Row>
                    </Body>
                </Table>
            </ThemeProvider>

            <ModalSelectDiscount
                open={openSelectDiscount}
                item={( selectItem || false )}
                onClose={() => this.setState({ openSelectDiscount: false, selectItem: false })}
                onDiscountSelect={this.handleDiscountSelect} />

        </div>
        )
    }

}


export default compose(
    withStyles(useStyles),
    withRouter
)(QuoteTotals);