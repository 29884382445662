/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_CREDIT_NOTES,
	GET_CREDIT_NOTES_BY,
	GET_CREDIT_NOTE,
	ADD_CREDIT_NOTE,
	EDIT_CREDIT_NOTE,
	DELETE_CREDIT_NOTE,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getCreditNotes, fb_getCreditNotesBy, fb_getCreditNote, fb_getPreloadCreditNoteData, fb_addCreditNote, fb_updateCreditNote, fb_generateCreditNotePDF, fb_duplicateCreditNote, fb_deleteCreditNote } from './fb';


export const getCreditNotes = (props) => {
	return dispatch => {

		fb_getCreditNotes(props)  
		.then(({ list, total, firstDoc, lastDoc }) => {
			dispatch({
				type: GET_CREDIT_NOTES,
				payload: { list, total, firstDoc, lastDoc }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_CREDIT_NOTES,
				payload: { list: [] }
			});
		});

	}
}

export const getCreditNotesBy = (type,id) => {
	return dispatch => {

		fb_getCreditNotesBy(type,id)  
		.then(list => {
			dispatch({
				type: GET_CREDIT_NOTES_BY,
				payload: { list }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_CREDIT_NOTES_BY,
				payload: { list: {} }
			});
		});

	}
}

export const getCreditNote = (credit_note_id) => {
	return dispatch => {

		fb_getCreditNote(credit_note_id)  
		.then(credit_note => {
			dispatch({
				type: GET_CREDIT_NOTE,
				payload: { credit_note }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve data' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_CREDIT_NOTE,
				payload: { credit_note: {} }
			});
		});

	}
}

export const getPreloadCreditNoteData = (props) => {
	return dispatch => {

		fb_getPreloadCreditNoteData(props)  
		.then(credit_note => {
			dispatch({
				type: GET_CREDIT_NOTE,
				payload: { credit_note }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve data' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_CREDIT_NOTE,
				payload: { credit_note: {} }
			});
		});

	}
}

export const addNewCreditNote = (formData,history) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_addCreditNote(formData)
		.then( results => {
			if ( results && results.new_credit_note_id && !_isEmpty( results.new_credit_note_id ) ) {

				fb_getCreditNote( results.new_credit_note_id )
				.then( credit_note => {

					// updated
					dispatch({ type: GET_CREDIT_NOTE, payload: { credit_note } });
					dispatch(toggleLoader(false));
					dispatch(appChangesReset());
					triggerSuccessAlert("Credit Note Added");

					// redirect to credit_note page
					history.push("/credit_notes/" + results.new_credit_note_id);

				});

			} else {
				dispatch(toggleLoader(false));
				triggerErrorAlert('Missing new credit note ID');
			}
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new credit note' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const editCreditNote = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateCreditNote(formData)
		.then( results => {

			fb_getCreditNote(formData.id)
			.then(credit_note => {
				// push to list
				dispatch({ type: GET_CREDIT_NOTE, payload: { credit_note } });

				// updated
				dispatch({ type: EDIT_CREDIT_NOTE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Credit Note Updated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update credit note' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const generateCreditNotePDF = (id) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_generateCreditNotePDF(id)
		.then( results => {

			fb_getCreditNote(id)
			.then(credit_note => {
				// push to list
				dispatch({ type: GET_CREDIT_NOTE, payload: { credit_note } });

				// updated
				dispatch({ type: EDIT_CREDIT_NOTE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("PDF Generated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to generate credit note PDF' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const duplicateCreditNote = (id,history) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_duplicateCreditNote(id)
		.then( results => {
			if ( results && results.new_credit_note_id && !_isEmpty( results.new_credit_note_id ) ) {

				fb_getCreditNote( results.new_credit_note_id )
				.then( credit_note => {

					// updated
					dispatch({ type: GET_CREDIT_NOTE, payload: { credit_note } });
					dispatch(toggleLoader(false));
					triggerSuccessAlert("Credit Note Duplicated");

					// redirect to credit_note page
					history.push("/credit_notes/" + results.new_credit_note_id);

				});

			} else {
				dispatch(toggleLoader(false));
				triggerErrorAlert('Missing new credit note ID');
			} // end - results
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to duplicate credit note' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteCreditNote = (id,props) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteCreditNote(id)
		.then( results => {

			fb_getCreditNotes(props)  
			.then(({ list, total, firstDoc, lastDoc }) => {
				// update list
				dispatch({
					type: GET_CREDIT_NOTES,
					payload: { list, total, firstDoc, lastDoc }
				});

				// trigger updated
				dispatch({ type: DELETE_CREDIT_NOTE });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("Credit Note Deleted");
			});

		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete list' ) );
			triggerErrorAlert(errMsg);
		});

	}
}