/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_QUOTES,
	GET_QUOTES_BY,
	GET_QUOTE,
	ADD_QUOTE,
	EDIT_QUOTE,
	DELETE_QUOTE,
	GET_INVOICE
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getQuotes, fb_getQuotesBy, fb_getQuote, fb_getPreloadQuoteData, fb_addQuote, fb_updateQuote, fb_generateQuotePDF, fb_deleteQuote, fb_convertQuoteToInvoice, fb_duplicateQuote } from './fb';
import { fb_getInvoice } from '../invoices/fb';

export const getQuotes = (props) => {
	return dispatch => {

		fb_getQuotes(props)  
		.then(({ list, total, firstDoc, lastDoc }) => {
			dispatch({
				type: GET_QUOTES,
				payload: { list, total, firstDoc, lastDoc }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_QUOTES,
				payload: { list: [] }
			});
		});

	}
}

export const getQuotesBy = (type,id) => {
	return dispatch => {

		fb_getQuotesBy(type,id)  
		.then(list => {
			dispatch({
				type: GET_QUOTES_BY,
				payload: { list }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_QUOTES_BY,
				payload: { list: {} }
			});
		});

	}
}

export const getQuote = (quote_id) => {
	return dispatch => {

		fb_getQuote(quote_id)  
		.then(quote => {
			dispatch({
				type: GET_QUOTE,
				payload: { quote }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_QUOTE,
				payload: { quote: {} }
			});
		});

	}
}

export const getPreloadQuoteData = (props) => {
	return dispatch => {

		fb_getPreloadQuoteData(props)  
		.then(quote => {
			dispatch({
				type: GET_QUOTE,
				payload: { quote }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_QUOTE,
				payload: { quote: {} }
			});
		});

	}
}

export const addNewQuote = (formData,history) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_addQuote(formData)
		.then( results => {
			if ( results && results.new_quote_id && !_isEmpty( results.new_quote_id ) ) {

				fb_getQuote( results.new_quote_id )
				.then( quote => {

					// updated
					dispatch({ type: GET_QUOTE, payload: { quote } });
					dispatch(toggleLoader(false));
					dispatch(appChangesReset());
					triggerSuccessAlert("Quote Added");

					// redirect to quote page
					history.push("/quotes/" + results.new_quote_id);

				});

			} else {
				dispatch(toggleLoader(false));
				triggerErrorAlert('Missing new quote ID');
			}
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new quote' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const editQuote = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateQuote(formData)
		.then( results => {

			fb_getQuote(formData.id)
			.then(quote => {
				// push to list
				dispatch({ type: GET_QUOTE, payload: { quote } });

				// updated
				dispatch({ type: EDIT_QUOTE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Quote Updated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update quote' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const generateQuotePDF = (id) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_generateQuotePDF(id)
		.then( results => {

			fb_getQuote(id)
			.then(quote => {
				// push to list
				dispatch({ type: GET_QUOTE, payload: { quote } });

				// updated
				dispatch({ type: EDIT_QUOTE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("PDF Generated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to generate quote PDF' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const convertQuoteToInvoice = (id,history) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_convertQuoteToInvoice(id)
		.then( results => {
			if ( results && results.new_invoice_id && !_isEmpty( results.new_invoice_id ) ) {

				fb_getInvoice( results.new_invoice_id )
				.then( invoice => {

					// updated
					dispatch({ type: GET_INVOICE, payload: { invoice } });
					dispatch(toggleLoader(false));
					triggerSuccessAlert("Converted to Invoice");

					// redirect to invoice page
					history.push("/invoices/" + results.new_invoice_id);

				});

			} else {
				dispatch(toggleLoader(false));
				triggerErrorAlert('Missing new invoice ID');
			}
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to convert quote to invoice' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const duplicateQuote = (id,history) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_duplicateQuote(id)
		.then( results => {
			if ( results && results.new_quote_id && !_isEmpty( results.new_quote_id ) ) {

				fb_getQuote( results.new_quote_id )
				.then( quote => {

					// updated
					dispatch({ type: GET_QUOTE, payload: { quote } });
					dispatch(toggleLoader(false));
					triggerSuccessAlert("Quote Duplicated");

					// redirect to quote page
					history.push("/quotes/" + results.new_quote_id);

				});

			} else {
				dispatch(toggleLoader(false));
				triggerErrorAlert('Missing new quote ID');
			}
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to duplicate quote' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteQuote = (id,props) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteQuote(id)
		.then( results => {

			fb_getQuotes(props)  
			.then(({ list, total, firstDoc, lastDoc }) => {
				// update list
				dispatch({
					type: GET_QUOTES,
					payload: { list, total, firstDoc, lastDoc }
				});

				// trigger updated
				dispatch({ type: DELETE_QUOTE });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("Quote Deleted");
			});

		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete list' ) );
			triggerErrorAlert(errMsg);
		});

	}
}