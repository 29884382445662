import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { withStyles } from "@material-ui/core/styles";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _remove from 'lodash/remove';
import _startCase from 'lodash/startCase';
import _toString from 'lodash/toString';

/* eslint-disable */

// import DotsLoader from '../../components/DotsLoader';

import { InfoButton } from '../../styles/button';
import { GreyTag, InfoTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
// import { isAdmin } from '../../helpers/auth';
import { getMomentTime } from '../../helpers/date';
import { getSelectedSchemas } from '../../helpers/schemas';
import { triggerSuccessAlert } from '../../helpers/alert';

// import { getAllMedia, uploadNewMedia, deleteMedia } from '../../actions/media_library';

const drawerWidth = 240;

const useStyles = theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        top: '65px',
        width: drawerWidth,
        background: '#fff'
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1)
    },
    accordion_root: {
        display: 'block', 
        width: '100%'
    },
    accordion_heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    accordion_panel_root: {
        '&.Mui-expanded': {
            marginTop: '0px'
        }
    },
    accordion_panel_details: {
        padding: '8px',
        borderTop: '1px solid #ddd'
    }
});

class ShortcodeMenu extends React.Component {

    state = {
        expanded: false,
        randNum: false
    };

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose ) {
            onClose();
        }
    }

    getAvailableShortcodes = () => {
        const shortcodes = [];
        const list = ['account','contact','potential','quote','invoice','product'];

        _forEach( list, mod => {
            const schemas = getSelectedSchemas(mod);
            const shortcode = {
                id: mod,
                name: _startCase( mod ),
                items: []
            };
            _forEach( schemas, schema => {
                if ( schema.email_shortcode ) {
                    shortcode.items.push({
                        id: mod + '_' + schema.id,
                        label: ( schema.label || '' )
                    });
                } // end - schema.shortcode
            });
            shortcodes.push( shortcode )
        });

        // add user shortcode
        shortcodes.push({
             id: 'user',
             name: 'User',
             items:[
                { id: 'user_name', label: 'Name' },
                { id: 'user_email', label: 'Email' },
                { id: 'user_signature', label: 'Signature (from LMS)' }
             ]
        });

        // add custom shortcode
        shortcodes.push({
             id: 'custom',
             name: 'Custom',
             items:[
                { id: 'custom_date_now', label: 'Current Date' },
                { id: 'custom_time_now', label: 'Current Time' },
             ]
        });

        return shortcodes;
    }

    renderOption = (mod) => (item) => {
        const shortcode = "#"+( item.id || '' )+"#"
        return (
        <ListItem key={item.id}>
            <ListItemText
            primary={( item.label || '' )}
            secondary={<WrapWord style={{ fontSize: '16px'}}>{shortcode}</WrapWord>}
            />
            {/* <ListItemSecondaryAction>
                <CopyToClipboard text={_toString(shortcode)}
                    onCopy={() => {
                        triggerSuccessAlert("Copied to clipboard!")
                    }}>
                    <IconButton edge="end" aria-label="copy">
                        <FileCopyIcon />
                    </IconButton>
                </CopyToClipboard>
            </ListItemSecondaryAction> */}
        </ListItem>
        )
    }

    renderAccordion = () => {
        const { classes } = this.props
        const { expanded } = this.state;
        const list = this.getAvailableShortcodes();
        return (
        <div className={classes.accordion_root}>
            {_map( list, mod => {
                return (
                <ExpansionPanel key={mod.id} className={classes.accordion_panel_root} expanded={expanded=== mod.id} onChange={() => this.setState({ expanded: mod.id })}>
                    <ExpansionPanelSummary
                        onClick={() => this.setState({ expanded: ( expanded === mod.id ? false : mod.id ) })}
                        expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.accordion_heading}>{( mod.name || '' )}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.accordion_panel_details}>
                        <List dense={true}>
                            { mod.items && isArrayExists( mod.items ) ? _map( mod.items, this.renderOption(mod) ) : null }
                        </List>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                )
            })}
        </div>
        )
    }

    render() {
        const { open, classes } = this.props;
        return (
        <div>

            <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={open}
            classes={{
                paper: classes.drawerPaper,
                }} >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={this.handleClose}>
                        <ChevronRightIcon />
                    </IconButton>
                    <Typography variant="h6">Available Shortcodes:</Typography>
                </div>
                <Divider />
                {this.renderAccordion()}
        </Drawer>

        </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles)
)(ShortcodeMenu);