import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';
import ButtonActions from '../../components/ButtonActions';
import ModalView from '../../components/ModalView';

import Details from './details.js';
import DCLSync from './dcl_sync.js';
import ContactsList from '../../components/ListContacts';
import PotentialsList from '../../components/ListPotentials';
import QuotesList from '../../components/ListQuotes';
import InvoicesList from '../../components/ListInvoices';
import CreditNotesList from '../../components/ListCreditNotes';
import EmailsList from '../../components/ListEmails';
import DuplicateContact from '../../components/DuplicateContact';
import ItemNotFound from '../../components/ItemNotFound';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights } from '../../helpers/auth'; 
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, combineStrings } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { getContact, editContact } from '../../actions/contacts';
import { getPotentialsBy } from '../../actions/potentials';
import { getQuotesBy } from '../../actions/quotes';
import { getInvoicesBy } from '../../actions/invoices';
import { getCreditNotesBy } from '../../actions/credit_notes';
import { getEmailsBy } from '../../actions/emails';
import { getUsersOptions } from '../../actions/users';
import { getSelectables } from '../../actions/m_selectables';
import { getLeadSource } from '../../actions/m_lead_source';
import { getMailingLists } from '../../actions/m_mailing_lists';
import { appChangesMade, resetRedux } from '../../actions/misc';

import { contactSchema } from '../../schemas/contact';


class ContactPage extends React.Component {

    state = {
        contact: false,
        currentTab: 'details',
        openViewModal: false,
        modalType: false,
        selectModal: false,
        contact: false,
        contacts: false,
        contactsRand: false,
        potentials: false,
        potentialsRand: false,
        quotes: false,
        quotesRand: false,
        invoices: false,
        invoicesRand: false,
        creditNotes: false,
        creditNotesRand: false,
        emails: false,
        emailsRand: false,
        randNum: false
    };

    componentDidMount() {
        const { contact_id } = this.props.match.params;
        
        // retrieve contact details
        this.props.dispatch(getContact(contact_id));

        // get potentials by
        this.props.dispatch(getPotentialsBy('contacts',contact_id));

        // get quotes by
        this.props.dispatch(getQuotesBy('contacts',contact_id));

        // get invoices by
        this.props.dispatch(getInvoicesBy('contacts',contact_id));

        // get credit notes by
        this.props.dispatch(getCreditNotesBy('contacts',contact_id));

        // get emails by
        this.props.dispatch(getEmailsBy('contacts',contact_id));

        // get all users
        if ( !this.props.usersOptions )
            this.props.dispatch(getUsersOptions());

        // get selectables
        if ( !this.props.selectablesList )
            this.props.dispatch(getSelectables());

        // get lead source
        if ( !this.props.leadSourceList )
            this.props.dispatch(getLeadSource());

        // get mailing lists
        if ( !this.props.mailingList )
            this.props.dispatch(getMailingLists());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentContact, usersOptions, selectablesList, leadSourceList, mailingList, randNum, 
            potentialsByList, potentialsByListRand, 
            quotesByList, quotesByListRand, 
            invoicesByList, invoicesByListRand, 
            creditNotesByList, creditNotesByListRand, 
            emailsByList, emailsByListRand } = this.props;

        // for contact
        if ( currentContact && usersOptions && selectablesList && leadSourceList && mailingList && randNum && randNum !== this.state.randNum ) {
            this.setState({ contact: cloneCollections( currentContact ), randNum });
        } // end - currentContact

        // for potentials
        if ( potentialsByList && potentialsByListRand && potentialsByListRand !== this.state.potentialsRand ) {
            this.setState({ potentials: cloneCollections( potentialsByList ), potentialsRand: potentialsByListRand });
        }

        // for quotes
        if ( quotesByList && quotesByListRand && quotesByListRand !== this.state.quotesRand ) {
            this.setState({ quotes: cloneCollections( quotesByList ), quotesRand: quotesByListRand });
        }

        // for invoices
        if ( invoicesByList && invoicesByListRand && invoicesByListRand !== this.state.invoicesRand ) {
            this.setState({ invoices: cloneCollections( invoicesByList ), invoicesRand: invoicesByListRand });
        }

        // for credit notes
        if ( creditNotesByList && creditNotesByListRand && creditNotesByListRand !== this.state.creditNotesRand ) {
            this.setState({ creditNotes: cloneCollections( creditNotesByList ), creditNotesRand: creditNotesByListRand });
        }

        // for emails
        if ( emailsByList && emailsByListRand && emailsByListRand !== this.state.emailsRand ) {
            this.setState({ emails: cloneCollections( emailsByList ), emailsRand: emailsByListRand });
        }
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('contact'));
    }

    handleRefresh = (type) => {
        const { contact_id } = this.props.match.params;
        switch( type ) {
            case 'potentials':
                this.setState({ potentials: false });
                this.props.dispatch(getPotentialsBy('contacts',contact_id));
                break;
            case 'quotes':
                this.setState({ quotes: false });
                this.props.dispatch(getQuotesBy('contacts',contact_id));
                break;
            case 'invoices':
                this.setState({ invoices: false });
                this.props.dispatch(getInvoicesBy('contacts',contact_id));
                break;
            case 'credit_notes':
                this.setState({ creditNotes: false });
                this.props.dispatch(getCreditNotesBy('contacts',contact_id));
                break;
            case 'emails':
                this.setState({ emails: false });
                this.props.dispatch(getEmailsBy('contacts',contact_id));
                break;
        }
    }

    handleSaveChanges = (event) => {
        const { contact } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do error check
        contactSchema.forEach(schema => {
            formData[schema.id] = ( contact && contact[schema.id] ? cloneCollections( contact[schema.id] ) : schema.default );
            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editContact(formData));
        } // end - error

    }

    handleFormUpdate = (newValue) => {
        this.setState({ contact: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    handleTabChange = (newTab) => {
        this.setState({ currentTab: newTab });
    }

    handleButtonActions = (type) => {
        const { contact } = this.state;
        const { changesMade } = this.props;
        var error = false,
            formData = {};
        // if changes made - trigger error
        if ( changesMade ) {
            alert("Please save changes first before proceed");
        } else {    
            switch( type ) {
                case 'duplicate-contact':
                    this.setState({ openViewModal: true, modalType: type });
                    break;
            } // end - type
        } // end - changesMade
    }

    getActions = () => {
        const { authData } = this.props;
        const { contact } = this.state;
        let actions = [];

        actions.push( { 
            id: 'duplicate-contact', 
            label: 'Duplicate Contact', 
            icon: 'fa-clone', 
            disabled: false, 
            onClick: this.handleButtonActions.bind(this,'duplicate-contact') } 
        );

        return actions;
    }

    getLinks = () => {
        const { authData } = this.props;
        let links = [{ id: 'details', label: 'Contact Info', icon: 'fa fa-cog' }];

        if ( authData && hasAccessRights( authData, ['ptr','ptw']) )
            links.push({ id: 'potentials', label: 'Potentials', icon: 'fa fa-bookmark' });

        if ( authData && hasAccessRights( authData, ['qtr','qtw']) )
            links.push({ id: 'quotes', label: 'Quotes', icon: 'fa fa-edit' });

        if ( authData && hasAccessRights( authData, ['ivr','ivw']) )
            links.push({ id: 'invoices', label: 'Invoices', icon: 'fa fa-file-pdf-o' });

        if ( authData && hasAccessRights( authData, ['cnr','cnw']) )
            links.push({ id: 'credit_notes', label: 'Credit Notes', icon: 'fa fa-file-text-o' }); 

        if ( authData && hasAccessRights( authData, ['cts']) )
            links.push({ id: 'emails', label: 'Emails', icon: 'fa fa-envelope' });

        return links;
    }

    renderTabContents = () => {
        const { contact_id } = this.props.match.params;
        const { authData, currentContact, usersOptions, selectablesList, leadSourceList, mailingList, changesMade } = this.props;
        const { currentTab, contact, potentials, quotes, invoices, creditNotes, emails } = this.state;
        const tabProps = {
            authData: ( authData || false ),
            contact: ( contact || false ),
            changesMade: ( changesMade || false ),
            onFormUpdate: this.handleFormUpdate
        };
        switch( currentTab ) {
            case 'details':
                return <Details 
                    isContactPage={true}
                    currentContact={( currentContact || false )}
                    users={( usersOptions || [] )} 
                    selectables={( selectablesList || [] )}
                    lead_source={( leadSourceList || [] )}
                    mailing_lists={( mailingList || [] )}
                    updateActions={[getContact(contact_id)]} 
                    {...tabProps} />; 
            case 'potentials':
                return ( potentials ? <PotentialsList
                    users={( usersOptions || [] )}
                    contact={( contact || null )}
                    account= { contact && contact.account_id && !_isEmpty( contact.account_id ) ? { id: contact.account_id, name: ( contact.account_label || '' ) } : null }
                    potentials={potentials}
                    changesMade={changesMade}
                    onRefresh={this.handleRefresh}
                    {...tabProps} /> : <DotsLoader /> ); 
            case 'quotes':
                return ( quotes ? <QuotesList
                    users={( usersOptions || [] )}
                    contact={( contact || null )}
                    account= { contact && contact.account_id && !_isEmpty( contact.account_id ) ? { id: contact.account_id, name: ( contact.account_label || '' ) } : null }
                    quotes={quotes}
                    changesMade={changesMade}
                    onRefresh={this.handleRefresh}
                    {...tabProps} /> : <DotsLoader /> ); 
            case 'invoices': 
                return ( invoices ? <InvoicesList
                    users={( usersOptions || [] )}
                    contact={( contact || null )}
                    account= { contact && contact.account_id && !_isEmpty( contact.account_id ) ? { id: contact.account_id, name: ( contact.account_label || '' ) } : null }
                    invoices={invoices}
                    changesMade={changesMade}
                    onRefresh={this.handleRefresh}
                    {...tabProps} /> : <DotsLoader /> );
            case 'credit_notes': 
                return ( creditNotes ? <CreditNotesList
                    users={( usersOptions || [] )}
                    contact={( contact || null )}
                    account= { contact && contact.account_id && !_isEmpty( contact.account_id ) ? { id: contact.account_id, name: ( contact.account_label || '' ) } : null }
                    credit_notes={creditNotes}
                    changesMade={changesMade}
                    onRefresh={this.handleRefresh}
                    {...tabProps} /> : <DotsLoader /> );  
            case 'emails': 
                return ( emails ? <EmailsList
                    type="contacts"
                    typeID={( contact_id || false )}
                    emails={emails}
                    account= { contact && contact.account_id && !_isEmpty( contact.account_id ) ? { id: contact.account_id, name: ( contact.account_label || '' ), assigned_to: ( contact.assigned_to && isArrayExists( contact.assigned_to ) ? contact.assigned_to : [] ) } : null }
                    contact={( contact || null )}
                    changesMade={changesMade}
                    onRefresh={this.handleRefresh}
                    {...tabProps} /> : <DotsLoader /> ); 
        }
    }

    renderTabs = () => {
        const { currentTab } = this.state;
        const btnStyle = {
            textAlign: 'center',
            minWidth: '175px',
            padding: "10px 5px",
            marginRight: "5px"
        };
        return (
        <Box display="flex" justifyContent="center" alignItems="center">
            {this.getLinks().map((button,index) => {
                if ( button.id === currentTab) {
                    return <InfoButton key={index} style={btnStyle} onClick={event => event.preventDefault()}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</InfoButton>
                } else {
                    return <GreyButton key={index} style={btnStyle} onClick={this.handleTabChange.bind(this,button.id)}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</GreyButton>
                }
            })}
        </Box>
        )
    }

    renderActionModal = () => {

    }

    renderActionsButton = () => {
        return (
        <div style={{ textAlign: 'right', paddingTop: '15px' }}>
            <DCLSync
                key="sync"
                contact={this.state.contact}
                onUpdate={this.handleFormUpdate}
                />
            <ButtonActions 
                key="actions" 
                label="Actions"
                color="inverse"
                menuContainerStyle={{ width: "300px" }}
                containerStyle={{ display: 'inline-block' }}
                style={{ padding: '15px 45px' }}
                actions={this.getActions()} />
        </div>
        );
    }

    renderContents() {
        const { authData, usersOptions } = this.props;
        const { contact, openViewModal, modalType } = this.state;
        return (
        <div>

            {this.renderTabs()}

            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                {this.renderTabContents()}
            </Paper>

            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
            </div>

            <DuplicateContact
                authData={authData}
                contact={( contact || null )}
                open={( openViewModal && modalType && modalType === 'duplicate-contact' ? true : false )}
                users={( usersOptions || [] )}
                onClose={() => this.setState({ openViewModal: false, modalType: false, selectModal: false })} />

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { randNum } = this.state;
        const { currentContact } = this.props;
        return <AppWrapper 
                title="Edit Contact"
                subtitle="Contacts"
                back="/contacts"
                breadcrumbs={[
                    { url: '/contacts', label: 'Contacts' },
                    { label: 'Edit' }
                ]}
                customColumn={( randNum && currentContact && currentContact.id && !_isEmpty( currentContact.id ) ? this.renderActionsButton() : null )}
                maxWidth="1110px"
                onLoad={( !( randNum ) ? true : false )}
                contents={ currentContact && currentContact.id && !_isEmpty( currentContact.id ) ? this.renderContents() : <ItemNotFound item="Contact" />} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentContact: state.contacts && state.contacts.contact || null,
        randNum: state.contacts && state.contacts.rand || null,
        potentialsByList: state.potentials && state.potentials.potentials_by || null,
        potentialsByListRand: state.potentials && state.potentials.by_rand || null,
        quotesByList: state.quotes && state.quotes.quotes_by || null,
        quotesByListRand: state.quotes && state.quotes.by_rand || null,
        invoicesByList: state.invoices && state.invoices.invoices_by || null,
        invoicesByListRand: state.invoices && state.invoices.by_rand || null,
        creditNotesByList: state.credit_notes && state.credit_notes.credit_notes_by || null,
        creditNotesByListRand: state.credit_notes && state.credit_notes.by_rand || null,
        emailsByList: state.emails && state.emails.emails_by || null,
        emailsByListRand: state.emails && state.emails.by_rand || null,
        usersOptions: state.users && state.users.users_options || null,
        selectablesList: state.maintenance && state.maintenance.selectables || null,
        leadSourceList: state.maintenance && state.maintenance.lead_source || null,
        mailingList: state.maintenance && state.maintenance.mailing_lists || null,
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ContactPage);