import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';

import Details from './details';
import AccountsList from '../../components/ListAccounts';
import ContactsList from '../../components/ListContacts';
import PotentialsList from '../../components/ListPotentials';
import QuotesList from '../../components/ListQuotes';
import InvoicesList from '../../components/ListInvoices';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights, isAdmin } from '../../helpers/auth'; 
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { getProduct, editProduct, getProductUsage } from '../../actions/products';
import { getPotentialsBy } from '../../actions/potentials';
import { getQuotesBy } from '../../actions/quotes';
import { getInvoicesBy } from '../../actions/invoices';
import { getAccountsBy } from '../../actions/accounts';
import { getContactsBy } from '../../actions/contacts';
import { getSelectables } from '../../actions/m_selectables';
import { getCoursePortfolios } from '../../actions/m_course_portfolio';
import { getCourseGroup } from '../../actions/m_course_group';
import { getRegions } from '../../actions/m_regions';
import { getConsultants } from '../../actions/m_consultants';
import { appChangesMade, resetRedux } from '../../actions/misc';

import { productSchema } from '../../schemas/product';

class ProductPage extends React.Component {

    state = {
        currentTab: 'details',
        product: false,
        accounts: false,
        accountsRand: false,
        contacts: false,
        contactsRand: false,
        potentials: false,
        potentialsRand: false,
        quotes: false,
        quotesRand: false,
        invoices: false,
        invoicesRand: false,
        randNum: false
    };

    componentDidMount() {
        const { product_id } = this.props.match.params;
        const { selectablesList, courseGroupList, coursePortfolioList, consultantsList, regionsList, authData } = this.props;
        
        // retrieve account details
        this.props.dispatch(getProduct(product_id));

        // get products usage by
        this.props.dispatch(getProductUsage(product_id,authData));

        // get course portfolio
        if ( !coursePortfolioList )
            this.props.dispatch(getCoursePortfolios());

        // get consultants option
        if ( !consultantsList )
            this.props.dispatch(getConsultants());

        // get course group
        // if ( !courseGroupList )
        //     this.props.dispatch(getCourseGroup());

        // get selectables
        if ( !selectablesList )
            this.props.dispatch(getSelectables());

        // get regions
        if ( !regionsList )
            this.props.dispatch(getRegions());

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentProduct, randNum, 
            accountsByList, accountsByListRand,
            contactsByList, contactsByListRand, 
            potentialsByList, potentialsByListRand, 
            quotesByList, quotesByListRand, 
            invoicesByList, invoicesByListRand,
            selectablesList, courseGroupList, coursePortfolioList, consultantsList, regionsList } = this.props;

        // for product
        if ( currentProduct && selectablesList && coursePortfolioList && consultantsList && regionsList && randNum && randNum !== this.state.randNum ) {
            this.setState({ product: cloneCollections( currentProduct ), randNum });
        } // end - currentProduct

        // for accounts
        if ( accountsByList && accountsByListRand && accountsByListRand !== this.state.accountsRand ) {
            this.setState({ accounts: cloneCollections( accountsByList ), accountsRand: accountsByListRand });
        }

        // for contacts
        if ( contactsByList && contactsByListRand && contactsByListRand !== this.state.contactsRand ) {
            this.setState({ contacts: cloneCollections( contactsByList ), contactsRand: contactsByListRand });
        }

        // for potentials
        if ( potentialsByList && potentialsByListRand && potentialsByListRand !== this.state.potentialsRand ) {
            this.setState({ potentials: cloneCollections( potentialsByList ), potentialsRand: potentialsByListRand });
        }

        // for quotes
        if ( quotesByList && quotesByListRand && quotesByListRand !== this.state.quotesRand ) {
            this.setState({ quotes: cloneCollections( quotesByList ), quotesRand: quotesByListRand });
        }

        // for invoices
        if ( invoicesByList && invoicesByListRand && invoicesByListRand !== this.state.invoicesRand ) {
            this.setState({ invoices: cloneCollections( invoicesByList ), invoicesRand: invoicesByListRand });
        }
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('product'));
    }

    handleRefresh = (type) => {
        const { product_id } = this.props.match.params;
        this.setState({ accounts: false, contacts: false, potentials: false, quotes: false, invoices: false });
        this.props.dispatch(getProductUsage(product_id));
    }

    handleSaveChanges = (event) => {
        const { product } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do error check
        productSchema.forEach(schema => {
            formData[schema.id] = ( product && product[schema.id] ? cloneCollections( product[schema.id] ) : schema.default );
            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editProduct(formData));
        } // end - error

    }

    handleFormUpdate = (newValue) => {
        this.setState({ product: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    handleTabChange = (newTab) => {
        this.setState({ currentTab: newTab });
    }

    isLMSLinked = () => {
        const { product } = this.state;
        return ( product && product.lms_linked && product.lms_linked === 'yes' ? true : false );
    }

    getLinks = () => {
        const { authData } = this.props;
        let links = [{ id: 'details', label: 'Product Info', icon: 'fa fa-cog' }];

        links.push({ id: 'potentials', label: 'Potentials', icon: 'fa fa-bookmark' });
        links.push({ id: 'quotes', label: 'Quotes', icon: 'fa fa-edit' });
        links.push({ id: 'invoices', label: 'Invoices', icon: 'fa fa-file-pdf-o' });
        links.push({ id: 'accounts', label: 'Accounts', icon: 'fa fa-briefcase' });
        links.push({ id: 'contacts', label: 'Contacts', icon: 'fa fa-user' });

        return links;
    }

    renderTabContents = () => {
        const { product_id } = this.props.match.params;
        const { authData,  changesMade, selectablesList, courseGroupList, regionsList, coursePortfolioList, consultantsList } = this.props;
        const { currentTab, product, accounts, contacts, potentials, quotes, invoices } = this.state;
        const tabProps = {
            authData: ( authData || false ),
            product: ( product || false ),
            changesMade: ( changesMade || false ),
            onFormUpdate: this.handleFormUpdate
        };
        switch( currentTab ) {
            case 'details':
                return <Details 
                    selectables={( selectablesList || [] )}
                    //course_group={( courseGroupList || [] )}
                    course_portfolio={( coursePortfolioList || [] )}
                    consultants={( consultantsList || [] )}
                    regions={( regionsList || [] )}
                    updateActions={[getProduct(product_id)]} 
                    {...tabProps} />;
            case 'accounts':
                    return ( accounts ? <AccountsList 
                        accounts={accounts} 
                        changesMade={changesMade}
                        onRefresh={this.handleRefresh}
                        {...tabProps} /> : <DotsLoader /> );   
            case 'contacts':
                return ( contacts ? <ContactsList 
                    contacts={contacts} 
                    changesMade={changesMade}
                    noAddNew={true}
                    onRefresh={this.handleRefresh}
                    {...tabProps} /> : <DotsLoader /> );   
            case 'potentials':
                return ( potentials ? <PotentialsList
                        noAddNew={true}
                        potentials={potentials}
                        changesMade={changesMade}
                        onRefresh={this.handleRefresh}
                        {...tabProps} /> : <DotsLoader /> ); 
            case 'quotes':
                return ( quotes ? <QuotesList
                    noAddNew={true}
                    quotes={quotes}
                    changesMade={changesMade}
                    onRefresh={this.handleRefresh}
                    {...tabProps} /> : <DotsLoader /> ); 
            case 'invoices': 
                return ( invoices ? <InvoicesList
                    noAddNew={true}
                    invoices={invoices}
                    changesMade={changesMade}
                    onRefresh={this.handleRefresh}
                    {...tabProps} /> : <DotsLoader /> ); 
        }
    }

    renderTabs = () => {
        const { currentTab } = this.state;
        const btnStyle = {
            textAlign: 'center',
            minWidth: '175px',
            padding: "10px 5px",
            marginRight: "5px"
        };
        return (
        <Box display="flex" justifyContent="center" alignItems="center">
            {this.getLinks().map((button,index) => {
                if ( button.id === currentTab) {
                    return <InfoButton key={index} style={btnStyle} onClick={event => event.preventDefault()}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</InfoButton>
                } else {
                    return <GreyButton key={index} style={btnStyle} onClick={this.handleTabChange.bind(this,button.id)}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</GreyButton>
                }
            })}
        </Box>
        )
    }

    renderContents() {
        return (
        <div>

            {this.renderTabs()}

            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                {this.renderTabContents()}
            </Paper>

            { this.isLMSLinked() ? null : (<div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
            </div>) }

            { this.isLMSLinked() ? null : <SnackBarSave onSave={this.handleSaveChanges} /> }

        </div>
        );
    }

    render() {
        const { randNum } = this.state;
        return <AppWrapper 
                title="Edit Product"
                subtitle="Products"
                back="/products"
                breadcrumbs={[
                    { url: '/products', label: 'Products' },
                    { label: 'Edit' }
                ]}
                onLoad={( !( randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentProduct: state.products && state.products.product || null,
        randNum: state.products && state.products.rand || null,
        accountsByList: state.accounts && state.accounts.accounts_by || null,
        accountsByListRand: state.accounts && state.accounts.by_rand || null,
        contactsByList: state.contacts && state.contacts.contacts_by || null,
        contactsByListRand: state.contacts && state.contacts.by_rand || null,
        potentialsByList: state.potentials && state.potentials.potentials_by || null,
        potentialsByListRand: state.potentials && state.potentials.by_rand || null,
        quotesByList: state.quotes && state.quotes.quotes_by || null,
        quotesByListRand: state.quotes && state.quotes.by_rand || null,
        invoicesByList: state.invoices && state.invoices.invoices_by || null,
        invoicesByListRand: state.invoices && state.invoices.by_rand || null,
        selectablesList: state.maintenance && state.maintenance.selectables || null,
        courseGroupList: state.maintenance && state.maintenance.course_group || null,
        coursePortfolioList: state.maintenance && state.maintenance.course_portfolio || null,
        consultantsList: state.maintenance && state.maintenance.consultants || null,
        regionsList: state.maintenance && state.maintenance.regions || null,
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ProductPage);