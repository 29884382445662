import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';

/* eslint-disable */

import ModalView from '../../components/ModalView';
import ModalDelete from '../../components/ModalDelete';
import FormInput from '../../components/FormInput';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import DotsLoader from '../../components/DotsLoader';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { formatMoney } from '../../helpers/number';
import { isAdmin, hasAccessRights } from '../../helpers/auth';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';

import { addNewReportCategory, updateReportCategory, deleteReportCategory } from '../../actions/reports';

class ReportCategories extends React.Component {

    state = {
        searchterms: '',
        filterBy: 'all',
        sortBy: 'name-asc',
        perPage: 20,
        page: 1,
        randNum: false,
        openViewModal: false,
        modalType: false,
        selectItem: false,
        openDeleteModal: false,
        deleteModal: false,
    };

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose )
            onClose();
    }

    handleFormUpdate = (newValue,key) => {
        const { selectItem } = this.state;
        let newData = ( selectItem ? cloneCollections( selectItem ) : {} );
        newData[key] = newValue;
        this.setState({ selectItem: newData });
    }

    handleCategoryDelete = () => {
        const { deleteModal } = this.state;
        this.props.dispatch( deleteReportCategory( deleteModal.id ) );
    }

    handleCategoryAction = () => {
        const { modalType, selectItem } = this.state;
        var error = false;

        if ( !( selectItem && selectItem.name && !_isEmpty( selectItem.name ) ) ) {
            error = 'Please insert a category name'
        } // end - selectItem.name


        if ( error ) {
            triggerErrorAlert(error);
        } else {
            switch( modalType ) {
                case 'add':
                    this.props.dispatch( addNewReportCategory( selectItem ) );
                    break;
                case 'update':
                    this.props.dispatch( updateReportCategory( selectItem ) );
                    break;
            }
        } // end - error

    }

    reorganizeData() {
        const { searchterms, sortBy, perPage, page } = this.state;
        const { categories } = this.props;
        let items = ( categories ? cloneCollections( categories ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','desc'] );
			total = _size( items );
        } // end - searchterms

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'name-desc':
                    items = _sortBy( items, [(i) => i.name.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, [(i) => i.name.toLowerCase()] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                total={totalCount}
                perPage={perPage} 
                page={page}
                doneLoaded={true}
                style={{ marginTop: "20px" }}
                onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderTableActions = () => {
        const { sortBy, perPage, searchterms } = this.state;
        return <TableBar
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' }
                ]}
                rightButtons={[
                    <InfoButton minWidth="128px" key="addnew" style={{ marginRight: "5px" }} onClick={() => this.setState({ openViewModal: true, selectItem: {
                        name: '',
                        desc: ''
                    }, modalType: 'add' })}><i className="fa fa-plus-square-o"></i>Add New</InfoButton>
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderTotalReports = (item) => {
        const { templates } = this.props;
        let list = ( templates && isArrayExists( templates ) ? _filter( templates, { category_id: item.id }) : [] );
        return _size( list );
    }

    renderTable = (items) => {
        const { authData } = this.props;
        return <Table 
            items={items}
            showCheckbox={false}
            cells={[
                { id: 'name', label: 'Name', render: (item) => ( item.name || '' ) },
                { id: 'desc', label: 'Description', render: (item) => ( item.desc || '' ) },
                { id: 'total', label: 'Total Report(s)', render: this.renderTotalReports }
            ]}
            actionStyles={{ width: "10%" }}
            actions={(item) => (
                <ButtonGroup>
                    <InfoButton key="select" size="small" onClick={() => this.setState({ openViewModal: true, selectItem: item, modalType: 'update' })}><i className="fa fa-edit"></i>Edit</InfoButton>
                    { authData && hasAccessRights( authData, ['rpd'] ) ? <ErrorButton key="delete" size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton> : null }
                </ButtonGroup>
            )} />
    }

    renderCategoriesModal = () => {
        const { items, total } = this.reorganizeData();
        return (
        <div>

            {this.renderTableActions()}
            {this.renderTable(items)}
            {this.renderPagination(total)}
            
        </div>   
        )
    }

    renderCategoryModal = () => {
        const { modalType, selectItem } = this.state;
        return (
        <div>
            <FormInput label="Name (Required)" name="name" value={( selectItem.name || '' )} onChange={this.handleFormUpdate} />
            <FormInput label="Description" name="desc" value={( selectItem.desc || '' )} multiline={true} rows={3} onChange={this.handleFormUpdate} />
        </div>
        )
    }

    render() {
        const { open } = this.props;
        const { openViewModal, modalType, openDeleteModal, deleteModal } = this.state;
        return (
        <div>

            <ModalView 
                open={open}
                title="Report Categories"
                noAction={true}
                actionLabel="Select"
                maxWidth="lg"
                fullScreen={true}
                cancelLabel="Close"
                onClose={this.handleClose}
                noForceClose={true}
                contents={this.renderCategoriesModal()} />

            <ModalView 
                open={openViewModal}
                title={( modalType && modalType === 'add' ? "Add New Category" : "Edit Category" )}
                onClose={() => this.setState({ openViewModal: false, modalType: false, selectItem: false })}
                actionLabel={( modalType && modalType === 'add' ? "Add New" : "Update" )}
                doAction={this.handleCategoryAction}
                contents={this.renderCategoryModal()} />

            <ModalDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to delete this category ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleCategoryDelete} />

        </div>
        )
    }

}

export default compose(
    connect()
)(ReportCategories);