/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GENERATE_REPORT,
	GET_REPORT_TEMPLATES,
	GET_REPORT_TEMPLATE,
	ADD_REPORT_TEMPLATE,
	UPDATE_REPORT_TEMPLATE,
	DELETE_REPORT_TEMPLATE,
	GET_REPORT_CATEGORIES,
	ADD_REPORT_CATEGORY,
	UPDATE_REPORT_CATEGORY,
	DELETE_REPORT_CATEGORY
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { 
	fb_getReportTemplates,
	fb_getReportTemplate,
	fb_addReportTemplate,
	fb_updateReportTemplate,
	fb_deleteReportTemplate,
	fb_generateReport,
	fb_getReportCategories,
	fb_addReportCategory,
	fb_updateReportCategory,
	fb_deleteReportCategory
} from './fb';


export const getReportTemplates = (props) => {
	return dispatch => {

		fb_getReportTemplates(props)  
		.then(list => {
			dispatch({
				type: GET_REPORT_TEMPLATES,
				payload: { list }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_REPORT_TEMPLATES,
				payload: { list: [] }
			});
		});

	}
}

export const getReportTemplate = (report_id) => {
	return dispatch => {

		fb_getReportTemplate(report_id)  
		.then(template => {
			dispatch({
				type: GET_REPORT_TEMPLATE,
				payload: { template }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve report template' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_REPORT_TEMPLATE,
				payload: { template: {} }
			});
		});

	}
}

export const addNewReportTemplate = (formData,history) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addReportTemplate(formData)
		.then( results => {
			if ( results && results.new_report_id && !_isEmpty( results.new_report_id ) ) {

				fb_getReportTemplate( results.new_report_id )
				.then( template => {

					// updated
					dispatch({ type: GET_REPORT_TEMPLATE, payload: { template } });
					dispatch(toggleModalProcessing(false));
					triggerSuccessAlert("Report Template Added");

					// redirect to template page
					history.push("/reports/" + results.new_report_id);

				});

			} else {
				dispatch(toggleModalProcessing(false,apiNum));
				triggerErrorAlert('Missing new report template ID');
			}
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new report template' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const editReportTemplate = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateReportTemplate(formData)
		.then( results => {

			fb_getReportTemplate(formData.id)
			.then(template => {
				// push to list
				dispatch({ type: GET_REPORT_TEMPLATE, payload: { template } });

				// updated
				dispatch({ type: UPDATE_REPORT_TEMPLATE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Report Template Updated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update Report Template' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const generateReport = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_generateReport(formData)
		.then( results => {

			fb_getReportTemplate(formData.id)
			.then(template => {
				// push to list
				dispatch({ type: GET_REPORT_TEMPLATE, payload: { template } });

				// updated
				dispatch({ type: UPDATE_REPORT_TEMPLATE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Report Generated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to generate Report' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const updateGenerateReport = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateReportTemplate(formData)
		.then(results => {
			return fb_generateReport(formData);
		})
		.then( results => {
			return fb_getReportTemplate(formData.id)
		})
		.then(template => {
			// push to list
			dispatch({ type: GET_REPORT_TEMPLATE, payload: { template } });

			// updated
			dispatch({ type: UPDATE_REPORT_TEMPLATE });
			dispatch(toggleLoader(false));
			dispatch(appChangesReset());
			triggerSuccessAlert("Report Updated & Generated");

		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update & generate Report' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteReportTemplate = (id,props) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteReportTemplate(id)
		.then( results => {

			fb_getReportTemplates(props)  
			.then(list => {
				// send updates
				dispatch({
					type: GET_REPORT_TEMPLATES,
					payload: { list }
				});

				// trigger updated
				dispatch({ type: DELETE_REPORT_TEMPLATE });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("Report Template Deleted");
			});

		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete Report Template' ) );
			triggerErrorAlert(errMsg);
		});

	}
}



export const getReportCategories = () => {
	return dispatch => {

		fb_getReportCategories(list => {
			dispatch({ 
                type: GET_REPORT_CATEGORIES, 
                payload: { list } 
            });
		});

	}
}

export const addNewReportCategory = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addReportCategory(formData)
		.then( results => {
			dispatch({ type: ADD_REPORT_CATEGORY });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Report category added");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add report category' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const updateReportCategory = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_updateReportCategory(formData)
		.then( results => {
			dispatch({ type: UPDATE_REPORT_TEMPLATE });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Report category updated");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update report category' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteReportCategory = (id) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteReportCategory(id)
		.then( results => {
			dispatch({ type: DELETE_REPORT_CATEGORY });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Report category deleted");
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete report category' ) );
			triggerErrorAlert(errMsg);
		});

	}
}