import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import ModalView from '../../components/ModalView';
import ButtonActions from '../../components/ButtonActions';
import SnackBarSave from '../../components/SnackBarSave';
import ItemNotFound from '../../components/ItemNotFound';

import Details from './details';
import ReportTable from './table';

import { InfoButton, GreyButton, ButtonGroup, SuccessButton, IndigoButton } from '../../styles/button';

import { isAdmin } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { getReportTemplate, generateReport, updateGenerateReport, editReportTemplate, getReportCategories } from '../../actions/reports';
import { getUsersOptions } from '../../actions/users';
import { appChangesMade, resetRedux } from '../../actions/misc';

import { reportTemplateSchema } from '../../schemas/report_template';

class ReportTemplatePage extends React.Component {

    state = {
        template: false,
        randNum: false
    };

    componentDidMount() {
        const { report_id } = this.props.match.params;
        
        // retrieve report details
        this.props.dispatch(getReportTemplate(report_id));

        // get report categories
        this.props.dispatch(getReportCategories());

        // get all users
        if ( !this.props.usersOptions )
            this.props.dispatch(getUsersOptions());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentReportTemplate, reportCategoryList, usersOptions, randNum } = this.props;

        // for report template
        if ( currentReportTemplate && reportCategoryList && usersOptions && randNum && randNum !== this.state.randNum ) {
            this.setState({ template: currentReportTemplate, randNum });
        } // end - currentReportTemplate
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('report'));
    }

    handleUpdateGenerate = (event) => {
        const { template } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do error check
        reportTemplateSchema.forEach(schema => {
            formData[schema.id] = ( template && template[schema.id] ? cloneCollections( template[schema.id] ) : schema.default );
            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateGenerateReport(formData));
        } // end - error
    }

    handleReportGenerate = (event) => {
        const { changesMade } = this.props;
        const { template } = this.state;
        event.preventDefault();

        // if changes made - trigger error
        if ( changesMade ) {
            alert("Please save changes first before proceed");
        } else {  
            var error = false,
                formData = {};

            // do error check
            reportTemplateSchema.forEach(schema => {
                formData[schema.id] = ( template && template[schema.id] ? cloneCollections( template[schema.id] ) : schema.default );
                if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                    error = 'Please fill out "' + schema.label + '" field';
                }
            });

            if ( error ) {
                triggerErrorAlert(error);
            } else {
                // generate report
                this.props.dispatch( generateReport({ id: ( template && template.id || '' ) }) );
            } // end - error
        } // end - changesMade
        
    }

    handleSaveChanges = (event) => {
        const { template } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do error check
        reportTemplateSchema.forEach(schema => {
            formData[schema.id] = ( template && template[schema.id] ? cloneCollections( template[schema.id] ) : schema.default );
            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editReportTemplate(formData));
        } // end - error

    }

    handleFormUpdate = (newValue) => {
        this.setState({ template: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    canUpdateSettings = (filters) => {
        const { authData } = this.props;
        const { template } = this.state;
        let can = false;
        // if is admin 
        if ( authData && isAdmin( authData ) ) {
            can = true;
        } else if ( template && template.report_access && !_isEmpty( template.report_access ) ) {
            switch ( template.report_access ) {
                case 'lock':
                    // if is filter
                    if ( filters ) {
                        can = true;
                    } else {
                        if ( template.report_owners && isArrayExists( template.report_owners ) && authData && authData.email && !_isEmpty( authData.email ) && _find( template.report_owners, { id: authData.email } ) ) {
                            can = true;
                        } // end - template.report_owners
                    } // end - filters
                    break;
                case 'generate-only':
                    if ( template.report_owners && isArrayExists( template.report_owners ) && authData && authData.email && !_isEmpty( authData.email ) && _find( template.report_owners, { id: authData.email } ) ) {
                        can = true;
                    } // end - template.report_owners
                    break;
                case 'default':
                    can = true;
                    break;
            }
        } // end - authData
        return can;
    }

    renderReport = () => {
        const { report_id } = this.props.match.params;
        const { authData, usersOptions } = this.props;
        const { template } = this.state;
        return <ReportTable
            report_id={report_id}
            authData={( authData || false )}
            template={( template || false )}
            users={( usersOptions || [] )}
            report={( template && template.report || false )} />
    }

    renderdetails = () => {
        const { report_id } = this.props.match.params;
        const { authData, changesMade, reportCategoryList, usersOptions } = this.props;
        const { template } = this.state;
        return <Details
            authData={( authData || false )}
            template={( template || false )}
            categories={( reportCategoryList || [] )}
            users={( usersOptions || [] )}
            changesMade={( changesMade || false )}
            onFormUpdate={this.handleFormUpdate}
            updateActions={[getReportTemplate(report_id)]} />;
    }

    renderContents() {
        return (
        <div>
            
            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                {this.renderdetails()}
            </Paper>

            <Grid container style={{ marginTop: "45px" }}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <ButtonGroup>
                        { this.canUpdateSettings(true) ? <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Report Settings</InfoButton> : null }
                        <SuccessButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleReportGenerate}><i className="fa fa-cogs"></i>Generate Now</SuccessButton>
                        { this.canUpdateSettings(true) ? <IndigoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleUpdateGenerate}><i className="fa fa-random"></i>Save & Generate Report</IndigoButton> : null }
                    </ButtonGroup>
                </Grid>
            </Grid>

            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "45px" }}>
                {this.renderReport()}
            </Paper>

            { this.canUpdateSettings(true) ? <SnackBarSave saveNowLabel="Save Report Settings" onSave={this.handleSaveChanges} /> : null }

        </div>
        );
    }

    render() {
        const { currentReportTemplate } = this.props;
        const { randNum } = this.state;
        return <AppWrapper 
                title="Edit Report Template"
                subtitle="Reports"
                back="/reports"
                breadcrumbs={[
                    { url: '/reports', label: 'Reports' },
                    { label: 'Edit' }
                ]}
                onLoad={( !( randNum ) ? true : false )}
                contents={ currentReportTemplate && currentReportTemplate.id && !_isEmpty( currentReportTemplate.id ) ? this.renderContents() : <ItemNotFound item="Report" />} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentReportTemplate: state.reports && state.reports.template || null,
        reportCategoryList: state.reports && state.reports.categories || null,
        randNum: state.reports && state.reports.rand || null,
        usersOptions: state.users && state.users.users_options || null,
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ReportTemplatePage);