import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';

/* eslint-disable */

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBarLimitedAccess';
import ModelDelete from '../../components/ModalDelete';
// import ModalView from '../../components/ModalView';
// import FormSelect from '../../components/FormSelect';
// import FormInput from '../../components/FormInput';
import ButtonActions from '../../components/ButtonActions';
import MassComment from '../../components/MassComment';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
// import { SuccessTag, AmberTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, combineStrings } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { isAdmin, hasAccessRights } from '../../helpers/auth';

import { deleteQuote } from '../../actions/quotes';


class Quotes extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        filterBy: 'all',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        openDeleteModal: false,
        deleteModal: false,
        openComment: false
    }

    handlePageRefresh = (event) => {
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh();
    }

    handleEdit = (id,event) => {
        event.preventDefault();
        this.props.history.push("/quotes/"+id);
    }

    handleDelete = () => {
        const { deleteModal } = this.state;
        // perform delete
        this.props.dispatch(deleteQuote(deleteModal.id));
    }

    handleAction = type => {
        const { checked } = this.state;
        // make sure there is an item selected
        if ( isArrayExists( checked ) ) {
            if ( type && type === 'comment' ) {
                this.setState({ openComment: true });
            }
        } else {
            triggerErrorAlert("Please select at least one item");
        } // end - checked
    }

    getActions = () => {
        const { authData } = this.props;
        let actions = [
            { id: 'addnew', label: 'Add New Quote', icon: 'fa-plus-circle', onClick: () => {
                this.props.history.push("/quotes/new");
            }}
        ];

        if ( authData && isAdmin( authData ) ) {
            actions.push({ id: 'export', label: 'Export', icon: 'fa-cloud-download', onClick: false, disabled: true });
        } // end - authData

        return actions;
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { quotes, authData, users } = this.props;
        let items = ( quotes ? cloneCollections( quotes ) : [] ),
            total = _size( items );

        // add in searchable label
        if ( quotes && isArrayExists( quotes ) ) {
            _forEach( quotes, (current,index) => {

                // set label as empty
                items[index].accounts_label = '';
                items[index].contacts_label = '';
                items[index].potentials_label = '';
                items[index].products_label = '';
                items[index].assigned_to_label = '';
                items[index].sales_person_label = '';

                // run through all the IDs to get labels
                if ( current && current.accounts_linked && isArrayExists( current.accounts_linked ) ) {
                    _forEach( current.accounts_linked, u => {
                        items[index].accounts_label += ( !_isEmpty( items[index].accounts_label ) ? ' ' : '' ) + ( u && u.name ? u.name : u.id );  
                    });
                } // end - current.accounts_linked

                // run through all the IDs to get labels
                if ( current && current.contacts_linked && isArrayExists( current.contacts_linked ) ) {
                    _forEach( current.contacts_linked, u => {
                        items[index].contacts_label += ( !_isEmpty( items[index].contacts_label ) ? ' ' : '' ) + ( u && u.name ? u.name : u.id );  
                    });
                } // end - current.accounts_linked

                // run through all the IDs to get labels
                if ( current && current.potentials_linked && isArrayExists( current.potentials_linked ) ) {
                    _forEach( current.potentials_linked, u => {
                        items[index].potentials_label += ( !_isEmpty( items[index].potentials_label ) ? ' ' : '' ) + ( u && u.name ? u.name : u.id );  
                    });
                } // end - current.potentials_linked

                // run through all the IDs to get labels
                if ( current && current.products_linked && isArrayExists( current.products_linked ) ) {
                    _forEach( current.products_linked, u => {
                        items[index].products_label += ( !_isEmpty( items[index].products_label ) ? ' ' : '' ) + ( u && u.name ? u.name : '' );  
                    });
                } // end - current.potentials_linked

                // run through all the IDs to get labels
                // if ( current && current.assigned_to && isArrayExists( current.assigned_to ) ) {
                //     _forEach( current.assigned_to, u => {
                //         items[index].assigned_to_label += ( !_isEmpty( items[index].assigned_to_label ) ? ' ' : '' ) + ( u && u.name ? u.name : u.id );  
                //     });
                // } // end - current.assigned_to

                 // run through all the IDs to get labels
                if ( current && current.sales_person && !_isEmpty( current.sales_person ) ) {
                    const user = ( current.sales_person && !_isEmpty( current.sales_person ) ? _find( users, { email: current.sales_person } ) : false );
                    items[index].sales_person_label = ( user && user.name && !_isEmpty( user.name ) ? user.name : ( current.sales_person || '---' ) );
                } // end - current.sales_person
                
            });
        } // end - quotes

        // do search
        // if ( searchterms && !_isEmpty( searchterms ) ) {
        //     items = doArraySearch( items, searchterms, ['id','name','stage','accounts_label','contacts_label','potentials_label','products_label','assigned_to_label','sales_person_label'] );
		// 	total = _size( items );
        // } // end - searchterms

        // do search using search filter component
        if ( searchterms && !_isEmpty( searchterms ) ) {
            const splitTerms = searchterms.split('_|_');
            if ( splitTerms[0] && !_isEmpty(splitTerms[0]) && splitTerms[1] && !_isEmpty(splitTerms[1]) ) {
                // trim _srch from the end
                const search_col = splitTerms[0].replace(/_srch$/g,'');
                switch( search_col ) {
                    case 'accounts_linked':
                        items = doArraySearch( items, splitTerms[1], [ 'accounts_label' ] );
                        total = _size( items );
                        break;
                    case 'contacts_linked':
                        items = doArraySearch( items, splitTerms[1], [ 'contacts_label' ] );
                        total = _size( items );
                        break;
                    case 'potentials_linked':
                        items = doArraySearch( items, splitTerms[1], [ 'potentials_label' ] );
                        total = _size( items );
                        break;
                    case 'products_linked':
                        items = doArraySearch( items, splitTerms[1], [ 'products_label' ] );
                        total = _size( items );
                        break;
                    case 'assigned_to':
                        items = doArraySearch( items, splitTerms[1], [ 'assigned_to_label' ] );
                        total = _size( items );
                        break;
                    default:
                        items = doArraySearch( items, splitTerms[1], [ search_col ] );
                        total = _size( items );
                        break;
                }
            }
        }

        // do custom filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy != 'all' ) {
            // items = _filter( items, { org: filterBy });
            // total = _size( items );
        }

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['modified_on'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['modified_on'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, [(i) => i.name.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, [(i) => i.name.toLowerCase()] );
                    break;
                case 'account_label-desc':
                    items = _sortBy( items, [(i) => i.accounts_label.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'account_label-asc':
                    items = _sortBy( items, [(i) => i.accounts_label.toLowerCase()] );
                    break;
                case 'potential_label-desc':
                    items = _sortBy( items, [(i) => i.potentials_label.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'potential_label-asc':
                    items = _sortBy( items, [(i) => i.potentials_label.toLowerCase()] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderCell = type => item => {
        const { users } = this.props;
        switch( type ) {
            case 'sales_person':
                const user = ( item[type] && !_isEmpty( item[type] ) ? _find( users, { email: item[type] } ) : false );
                return ( user && user.name && !_isEmpty( user.name ) ? user.name : ( item[type] || '---' ) );
            case 'accounts_linked':
            case 'potentials_linked':
            case 'assigned_to':
                return ( item[type] && isArrayExists( item[type] ) ? item[type].map( (u) => (<div key={u.id}>{u.name}</div>) ) : '--' );
            case 'email':
                return <WrapWord>{( item[type] || '' )}</WrapWord>
        }
    }

    renderTableActions = () => {
        const { authData, users } = this.props;
        const { sortBy, perPage, searchterms, checked } = this.state;
        return <TableBar
                mod="quotes"
                sortBy={sortBy}
                perPage={perPage}
                limitedPerPage={true}
                searchterms={searchterms}
                searchOptions={[
                    { id: 'stage', options: [
                        { value: 'created', label: 'Created' },
                        { value: 'delivered', label: 'Delivered' },
                        { value: 'reviewed', label: 'Reviewed' },
                        { value: 'accepted', label: 'Accepted' },
                        { value: 'rejected', label: 'Rejected' },
                        { value: 'invoiced', label: 'Invoiced' },
                        { value: 'expired', label: 'Expired' }
                    ] },
                    { id: 'sales_person', options: getSelectOptions({ list: ( users || [] ), keys: { value: 'email', label: 'name' }, sortBy: 'label' }) },
                ]}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Modified first' },
                    { value: 'date-asc', label: 'Oldest Modified first' },
                    { value: 'name-asc', label: 'Subject ( A - Z)' },
                    { value: 'name-desc', label: 'Subject ( Z - A )' },
                    { value: 'account_label-asc', label: 'Account Name ( A - Z)' },
                    { value: 'account_label-desc', label: 'Account Name ( Z - A )' },
                    { value: 'potential_label-asc', label: 'Potential Name ( A - Z)' },
                    { value: 'potential_label-desc', label: 'Potential Name ( Z - A )' }
                ]}
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                    </div>
                    ] : null }
                rightButtons={[
                    <ButtonActions 
                        key="actions" 
                        label="Actions"
                        menuContainerStyle={{ width: "200px" }}
                        style={{ marginRight: "5px" }}
                        actions={this.getActions()} />
                    ,
                    <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton>
                ]}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    doneLoaded={true}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { authData, users } = this.props;
        const { openDeleteModal, deleteModal, openViewModal, allCheck, checked, openComment } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to delete this quote ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            {this.renderTableActions()}
            <Table 
                noSelect={true}
                items={items}
                showCheckbox={true}
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={[
                    { id: 'id', label: 'Quote Number', render: (item) => ( item.id || '' ) },
                    { id: 'name', label: 'Subject', render: (item) => ( item.name || '' ) },
                    { id: 'stage', label: 'Quote Stage', render: (item) => ( item.stage || '' ) },
                    { id: 'potential_label', label: 'Potential Name', render: (item) => ( item.potentials_label && !_isEmpty( item.potentials_label ) ? item.potentials_label : '--' ) },
                    { id: 'account_label', label: 'Account Name', render: (item) => ( item.accounts_label && !_isEmpty( item.accounts_label ) ? item.accounts_label : '--' ) },
                    { id: 'grand_total', label: 'Total', render: (item) => ( item.grand_total && !_isEmpty( item.grand_total ) ? ( item.currency && !_isEmpty( item.currency ) ? item.currency : '' )+item.grand_total : '---' ) },
                    { id: 'sales_person', label: 'Sales Person', render: this.renderCell('sales_person') },
                ]}
                actionStyles={{ width: "10%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <AInfoLink key="edit" href={"/quotes/"+item.id} size="small" onClick={this.handleEdit.bind(this,item.id)}><i className="fa fa-edit"></i>Edit</AInfoLink>
                        { authData && hasAccessRights( authData, ['qtd'] ) ? <ErrorButton key="delete" size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton> : null }
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}

            <MassComment 
                refer_type="quote"
                open={openComment}
                selected={( checked || [] )}
                onClose={() => this.setState({ openComment: false })}
                onCompleted={() => {
                    // reset modal, and selector
                    this.setState({ openComment: false, checked: [], allCheck: false })
                    // trigger refresh
                    if ( this.props.onRefresh )
                        this.props.onRefresh();
                }} />

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(Quotes);