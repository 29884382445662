/* eslint-disable */
import * as firebase from 'firebase/app';
import shortid from 'shortid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import { hasLimitedAccess } from '../../helpers/auth';
import { callFunctionsAPI, doPromise } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';

/* helpers start */

const deleteFileFromStorage = (path) => {
    return new Promise((resolve,reject) => {
        if ( path && !_isEmpty(path) ) {
            const storageRef = firebase.storage().ref();
            storageRef.child(path).delete()
            .then(function() {
                resolve('deleted'); 
            }).catch(function(error) {
                resolve('deleted');
            });
        } else {
            resolve('deleted');
        } // end - path
    })
}

const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

/* helper end */

export const fb_getAllMedia = (callback,authData) => {

    let Query = firebase.database().ref("file_storage");

    if ( authData && hasLimitedAccess( authData ) && authData.email && !_isEmpty( authData.email ) ) {
        Query = Query.orderByChild('uploaded_by').equalTo(authData.email); 
    } // end - authData

    Query.on("value", snapshot => {
        var list = [];

        if ( snapshot.exists() && snapshot.hasChildren() ) {
            snapshot.forEach( childSnapshot => {
                var val = childSnapshot.val(),
                    file = {
                        id: childSnapshot.key,
                        name: val.name || '',
                        url: val.url || '',
                        path: val.path || '',
                        size: val.size || '',
                        type: val.type || '',
                        uploaded_by: val.uploaded_by || '',
                        created_on: val.created_on || false,
                        region: val.region || '',
                    };

                list.push(file);
            });
        } // end - snapshot

        callback( list );
    });
    
}

export const fb_uploadNewMedia = (file,user) => {
    return new Promise((resolve,reject) => {

        const storageRef = firebase.storage().ref();

        var file_id = shortid.generate(),
            filePath = 'file_storage/' + file_id + '_' + file.name,
            metaData = {
                uid: user.uid,
                contentType: ( file.type || '' ),
                region: ( user.region || '' )
            };

        const uploadTask = storageRef.child(filePath).put(file, metaData);

        uploadTask.on('state_changed', function(snapshot){
            // Observe state change events such as progress, pause, and resume
        }, function(error) {
            reject(error);
        }, function() {
            // Handle successful uploads on complete
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {

                callFunctionsAPI({ 
                    url: 'mediaLibrary', 
                    action: 'add', 
                    formData: {
                        id: file_id,
                        name: file.name,
                        url: downloadURL,
                        path: filePath,
                        size: file.size || 0,
                        type: ( file.type || '' ),
                        region: ( user.region || '' )
                    } 
                })
                .then(data => {
                    resolve(downloadURL);
                })
                .catch(error => {
                    reject(error);
                });

            });
        });

    })
}

export const fb_deleteMedia = (file) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ 
            url: 'mediaLibrary', 
            action: 'delete', 
            formData: { id: file.id } 
        })
        .then(data => {
            return ( file && file.path && !_isEmpty( file.path ) ? deleteFileFromStorage( file.path ) : false );
        })
        .then(data => {
            resolve('done');
        })
        .catch(error => {
            reject(error);
        });

    })
}