/* eslint-disable */
import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import styled from "styled-components";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import _startCase from 'lodash/startCase';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';

import PopperBox from '../Popper';

import { H3 } from '../../styles/heading';
import { FlexColumn } from '../../styles/division';
import { ErrorLink, GreyLink, InverseLink } from '../../styles/button';

import { isArrayExists } from '../../helpers/validation';
import { hasAccessRights, isAdmin } from '../../helpers/auth';

import { logout } from '../../actions/auth';
import { appChangesReset } from '../../actions/misc';

import theme from '../../theme';

// import { DEV_MODE } from '../../constants';

const HeaderWrapper = styled.header`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 0px 0px 0px 30px;
    border-bottom: 1px solid #ddd;
    z-index: 999;
`;

const Nav = styled.nav`
    display: block;

    ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        list-style: none;

        li {
            display: block;
            padding: 0px 1.25vw 0px 0px;
            margin: 0px;
            height: 64px;

            &:last-child {
                padding-right: 0px;
            }
        }

    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0px 5px;
        min-width: 64px;
        text-align: center;
        color: ${theme.palette.background};
        font-weight: 700;
        box-shadow:inset 0 0 0 0 #fff;
        text-transform: uppercase;

        
        &:hover,
        &:active,
        &.active {
            color: ${theme.palette.primary.main};
            box-shadow:inset 0 -5px 0 0 ${theme.palette.primary.main};
        }
    }
`;

const ToggleButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.35ms ease-in-out;
    cursor: pointer;
    padding: 0px 45px;
    height: 100%;
    vertical-align: middle;
    border-radius: 45px 0px 0px 45px;

    i { 
        font-size: 30px;
        margin-left: 30px;
        
        &:first-child {
            margin-left: 0px;
        }
    }

    &.opened {
        background: ${theme.palette.primary.main};
        color: #fff;
    }
`;

const UserDetails = styled.div`
    padding: 20px;
    border-bottom: 1px solid #ddd;
`;

const UserSubMenuWrapper = styled.div`
    padding: 20px;
`;

class Header extends React.Component {

    state = {
        openSubMenu: false,
        subMenuAnchor: {},
        subMenuID: false,
        userAnchorEl: {},
        openUserMenu: false
    };

    handleLogOut = (event) => {
        const { changesNotSaved } = this.props;
        event.preventDefault();

        // if changes not saved - trigger warning        
        if ( changesNotSaved ) {
            var answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
            // reset status if answer is yes
            if ( answer ) {
                this.props.dispatch(appChangesReset());
                this.props.dispatch(logout());
            }
        } else {
            this.props.dispatch(logout());
        }
    }

    handleLinkClick(url,event) {
        const { history, changesNotSaved } = this.props;
        event.preventDefault();

        // if changes not saved - trigger warning        
        if ( changesNotSaved ) {
            var answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
            // reset status if answer is yes
            if ( answer ) {
                this.props.dispatch(appChangesReset());
                history.push(url);
            }
        } else {
            history.push(url);
        }
    }

    handleSubMenu = (link_id,event) => {
        const { openSubMenu } = this.state;
        event.preventDefault();
        this.setState({ openSubMenu: !openSubMenu, subMenuAnchor: event.currentTarget, subMenuID: link_id });
    }

    handleUserMenu = (event) => {
        const { openUserMenu } = this.state;
        this.setState({ openUserMenu: !openUserMenu, userAnchorEl: event.currentTarget });
    }

    getLinks = () => {
        const { authData } = this.props;
        let links = [{ id: 'dashboard', label: 'Dashboard', url: '/dashboard' }];

        if ( hasAccessRights( authData, ['acr'] ) )
            links.push({ id: 'accounts', label: 'Accounts', url: '/accounts' });

        if ( hasAccessRights( authData, ['ctr'] ) )
            links.push({ id: 'contacts', label: 'Contacts', url: '/contacts' });

        if ( hasAccessRights( authData, ['ptr'] ) )
            links.push({ id: 'potentials', label: 'Potentials', url: '/potentials' });

        if ( hasAccessRights( authData, ['qtr'] ) )
            links.push({ id: 'quotes', label: 'Quotes', url: '/quotes' });

        if ( hasAccessRights( authData, ['ivr'] ) )
            links.push({ id: 'invoices', label: 'Invoices', url: '/invoices' });

        if ( hasAccessRights( authData, ['cnr'] ) )
            links.push({ id: 'credit_notes', label: 'Credit Notes', url: '/credit_notes' });

        if ( hasAccessRights( authData, ['pdr'] ) )
            links.push({ id: 'products', label: 'Products', url: '/products' });

        if ( hasAccessRights( authData, ['etr'] ) )
            links.push({ id: 'email_templates', label: 'Email Templates', url: '/email_templates' });

        if ( hasAccessRights( authData, ['rpr'] ) )
            links.push({ id: 'reports', label: 'Reports', url: '/reports' });

        return links;
    }

    isAdmin = () => {
        const { authData } = this.props;
        return ( authData && authData.type && authData.type == 'admin' ? true : false );
    }

    renderSubMenu() {
        const { openSubMenu, subMenuAnchor, subMenuID } = this.state;
        const links = this.getLinks();
        var parent = _find( links, { id: subMenuID });
        return <PopperBox 
                open={openSubMenu}
                placement="bottom"
                anchorEl={subMenuAnchor}
                onClose={() => this.setState({ openSubMenu: false, subMenuAnchor: {}, subMenuID: false })}
                wrapperStyle={{ paddingTop: "10px" }}
                containerStyle={{ width: "250px" }}
                contents={(
                    <div style={{ padding: "10px" }}>
                    { parent && parent.submenu && isArrayExists( parent.submenu ) ? parent.submenu.map(link => {
                       return <InverseLink key={link.id} style={{ display: 'block', padding: "15px" }} onClick={this.handleLinkClick.bind(this,link.url)}>{ link.icon ? <i className={link.icon}></i> : '' }{link.label}</InverseLink>
                    }) : '' }
                    </div>
                )} />
    }

    renderUserSubMenu() {
        const { authData } = this.props;
        const linkStyle = { marginBottom: "10px" };
        const hasMTR = hasAccessRights( authData, ['mtr'] );
        return (
        <UserSubMenuWrapper>
            <Grid container spacing={3}>
                { hasMTR ? <Grid item xs={12} sm={6}>
                    <div style={{ padding: "0 10px" }}>
                        <Typography variant="h5" style={{ marginBottom: "15px", padding: "0px 5px" }}>Maintenance</Typography>
                        { this.isAdmin() ? <GreyLink onClick={this.handleLinkClick.bind(this,'/users')} style={linkStyle}><i className="fa fa-users"></i>Users</GreyLink> : null }
                        { this.isAdmin() ? <GreyLink onClick={this.handleLinkClick.bind(this,'/roles')} style={linkStyle}><i className="fa fa-address-card-o"></i>Roles & Privileges</GreyLink> : null }
                        <GreyLink onClick={this.handleLinkClick.bind(this,'/maintenance/companies')} style={linkStyle}><i className="fa fa-map-marker"></i>Companies</GreyLink>
                        <GreyLink onClick={this.handleLinkClick.bind(this,'/maintenance/accounts_tier_2')} style={linkStyle}><i className="fa fa-database"></i>Accounts Tier 2</GreyLink>
                        <GreyLink onClick={this.handleLinkClick.bind(this,'/maintenance/business')} style={linkStyle}><i className="fa fa-briefcase"></i>Business</GreyLink>
                        <GreyLink onClick={this.handleLinkClick.bind(this,'/maintenance/invoice_data')} style={linkStyle}><i className="fa fa-university"></i>Invoice Data</GreyLink>
                        <GreyLink onClick={this.handleLinkClick.bind(this,'/maintenance/payment_terms')} style={linkStyle}><i className="fa fa-tags"></i>Payment Terms</GreyLink>
                        <GreyLink onClick={this.handleLinkClick.bind(this,'/maintenance/product_line')} style={linkStyle}><i className="fa fa-bookmark"></i>Product Line</GreyLink>
                        <GreyLink onClick={this.handleLinkClick.bind(this,'/maintenance/lead_source')} style={linkStyle}><i className="fa fa-database"></i>Lead Source</GreyLink>
                        
                    </div>
                </Grid> : null }
                <Grid item xs={12} sm={6}>
                    <div style={ hasMTR ? { padding: "0 10px", borderLeft: "1px solid #ddd" } : { padding: "0 10px" }}>
                        {/* <Typography variant="h5" style={{ marginBottom: "15px", padding: "0px 5px" }}>Account Settings</Typography>
                        <GreyLink onClick={this.handleLinkClick.bind(this,'/auth/profile')} style={linkStyle}><i className="fa fa-user"></i>Edit Profile</GreyLink> */}
                        { hasMTR ? (
                        <div>
                            <Typography variant="h5" style={{ marginBottom: "15px", padding: "0px 5px" }}>LMS Related Data</Typography>
                            <GreyLink onClick={this.handleLinkClick.bind(this,'/maintenance/consultants')} style={linkStyle}><i className="fa fa-users"></i>Consultants</GreyLink>
                            <GreyLink onClick={this.handleLinkClick.bind(this,'/maintenance/course_group')} style={linkStyle}><i className="fa fa-folder-o"></i>Course Group</GreyLink>
                            <GreyLink onClick={this.handleLinkClick.bind(this,'/maintenance/course_profile')} style={linkStyle}><i className="fa fa-book"></i>Course Profile</GreyLink>
                            <GreyLink onClick={this.handleLinkClick.bind(this,'/maintenance/selectables')} style={linkStyle}><i className="fa fa-tasks"></i>Selectables</GreyLink>
                            
                            <Typography variant="h5" style={{ margin: "15px 0", padding: "0px 5px" }}>Mailer Related Data</Typography>
                            <GreyLink onClick={this.handleLinkClick.bind(this,'/maintenance/mailing_lists')} style={linkStyle}><i className="fa fa-list-alt"></i>mailing Lists</GreyLink>
                        </div>
                        ) : null }
                    </div>
                </Grid>
            </Grid>
        </UserSubMenuWrapper>
        )
    }

    renderUserDetails() {
        const { authData } = this.props;
        return (
        <UserDetails>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <FlexColumn width="70%">
                    <Box display="flex">
                        { authData.photoURL && !_isEmpty( authData.photoURL ) ? <img src={authData.photoURL} style={{ width: "45px", height: "45px", borderRadius: "100%" }} /> : <i className="fa fa-user-circle" style={{ fontSize: '45px' }}></i> }
                        <div style={{ paddingLeft: "20px" }}>
                            <Typography variant="h5" style={{ fontWeight: "700", marginBottom: "4px" }}>{authData.name || ''}</Typography>
                            <Typography variant="h6" color="textSecondary" style={{ marginBottom: "4px" }}>{authData.email || ''}</Typography>
                            <Typography variant="h6" color="primary">{( authData.role ? _startCase( authData.role ) : '' )}</Typography>
                        </div>
                    </Box>
                </FlexColumn>
                <FlexColumn width="30%">
                    <ErrorLink onClick={this.handleLogOut}><i className="fa fa-sign-out"></i>Logout</ErrorLink>
                </FlexColumn>
            </Box>
        </UserDetails>
        )
    }

    renderUserMenu = () => {
        const { authData } = this.props;
        const { openUserMenu, userAnchorEl } = this.state;
        const hasMTR = hasAccessRights( authData, ['mtr'] );
        return <PopperBox 
                open={openUserMenu}
                anchorEl={userAnchorEl}
                wrapperStyle={{ padding: "15px" }}
                onClose={() => this.setState({ openUserMenu: false, userAnchorEl: {} })}
                contents={(
                    <div>
                        {this.renderUserDetails()}
                        { hasMTR ? this.renderUserSubMenu() : null }
                    </div>
                )} />
    }

    render() {
        const { openUserMenu, subMenuID } = this.state;
        const links = this.getLinks();
        return (
        <HeaderWrapper>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <FlexColumn width="20%" style={{ paddingRight: "15px" }}>
                    <img src="/logo.png" />
                </FlexColumn>
                <FlexColumn width="80%">
                    <Nav>
                        <ul>
                        {links.map( link => {
                            if ( link.submenu && isArrayExists( link.submenu ) )
                                return (
                                <li key={link.id}>
                                    <a href="#" className={( subMenuID && subMenuID === link.id ? 'active' : null )} onClick={this.handleSubMenu.bind(this,link.id)}><span>{link.label}</span><i className="fa fa-angle-down" style={{ marginLeft: "8px" }}></i></a>
                                </li>
                                );
                            else
                                return <li key={link.id}><a href={link.url} onClick={this.handleLinkClick.bind(this,link.url)}><span>{link.label}</span></a></li>;
                        })}
                            <li>
                                <ToggleButton onClick={this.handleUserMenu} className={( openUserMenu ? 'opened' : 'closed' )}>
                                    <i className="fa fa-user-circle"></i>
                                    { openUserMenu ? <i className="fa fa-remove"></i> : <i className="fa fa-ellipsis-v"></i> }
                                </ToggleButton>
                            </li>
                        </ul>
                    </Nav>
                    {this.renderSubMenu()}
                    {this.renderUserMenu()}
                </FlexColumn>
            </Box>
        </HeaderWrapper>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth.user || null,
        changesNotSaved: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(Header);