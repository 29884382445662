/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from "@material-ui/core/styles";
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _random from 'lodash/random';
import _isEqual from 'lodash/isEqual';

import EditForm from '../../pages/ContactPage/form';

import ModalView from '../ModalView';
import DotsLoader from '../DotsLoader';
import Table from '../Table';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { formatMoney } from '../../helpers/number';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { FormBox } from '../../styles/form';

import { contactSchema } from '../../schemas/contact';

import { mergeContacts } from '../../actions/contacts';

const useStyles = theme => ({
    checkbox: {
        padding: '0px 9px 9px 9px'
    },
    closeBtn: {
        position: 'absolute',
        top: '0',
        right: '0',
        padding: '5px 8px',
        borderRadius: '0px'
    }
});

class ModalMergeContacts extends React.Component {

    state = {
        contact: {},
        main: false
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { open } = this.props;

        if ( !prevProps.open && open ) {
            this.setState({ contact: this.getInitialValues(), main: this.getInitialMain() });
        } // end - open
            
    }

    handleMerge = () => {
        const { selected, query } = this.props;
        const { contact, main } = this.state;
        const formData = this.compileFormData();
        const list = this.compileSelectedList();
        let error = false;

        if ( _isEmpty( formData ) )
            error = 'Please make at least one changes';

        if ( !( list && isArrayExists( list ) && _size( list ) > 1 ) )
            error = 'Please select at least 2 contacts.';

        // check if selected contacts are from the same contact
        if ( !this.makeSureContactIsSameAccount() ) {
            error = 'Please select contacts from the same account only.';
        }

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(mergeContacts(list,formData,query));
        } // end - error
    }

    handleCheckboxUpdate = ({ id, field }) => event => {
        const { contact } = this.state;
        let newContact = ( contact ? cloneCollections( contact) : {} );
        if ( event.target.checked && id && field ) {
            newContact[field] = id;
        }
        this.setState({ contact: newContact });
    }

    handleHeadCheckboxUpdate = (id) => event => {
        const { contact, main } = this.state;
        if ( id ) {
            let newContact = ( contact ? cloneCollections( contact) : {} );
            _forEach( contactSchema, schema => {
                if ( schema.merge && !this.checkIfIsIgnoredSystemData(schema) ) {
                    newContact[schema.id] = id;
                } // end - schema.merge
            });
            this.setState({ main: id, contact: newContact });
        }
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose ) {
            this.setState({ contact: {} });
            onClose();
        }
    }

    makeSureContactIsSameAccount = () => {
        // const { selected } = this.props;
        // let accountID = false,
        //     valid = true;
        // if ( selected && isArrayExists( selected ) ) {
        //     _forEach( selected, item => {
        //         if ( item.account_id && !_isEmpty( item.account_id ) ) {
        //             // add to accountID if empty
        //             if ( !( accountID && !_isEmpty( accountID ) ) ) {
        //                 accountID = item.account_id;
        //             } else {
        //                 // do check if it's the same ID
        //                 if ( !( item.account_id && !_isEmpty( item.account_id ) && accountID === item.account_id ) ) {
        //                     valid = false;
        //                 }
        //             } // end - accountID
        //         } else {
        //             // contact without account_id - make it false
        //             valid = false;
        //         } // end - item.account_id
        //     });
        // } // end - selected
        // return valid;
        return true; // make this constants
    }

    compileSelectedList = () => {
        const { selected } = this.props;
        const { main } = this.state;
        const list = [];
        if ( selected &&  isArrayExists( selected ) ) {
            _forEach( selected, item => {
                list.push({ id: item.id , type: ( item.id && main && main === item.id ? 'main' : 'sub' ) });
            });
        } // end - selected
        return list;
    }

    compileFormData = () => {
        const { selected } = this.props;
        const { contact } = this.state;
        let formData = {};
        _forEach( contactSchema, schema => {
            if ( schema.merge ) {
                let selectedContact = false;
                switch( schema.id ) {
                    case 'designation_id':
                        selectedContact = ( contact && contact['designation'] && !_isEmpty( contact['designation'] ) ? _find( selected, { id: contact['designation'] }) : false );
                        formData[schema.id] = ( selectedContact && selectedContact[schema.id] ? selectedContact[schema.id] : ( schema.default || '' ) );
                        break;
                    case 'account_id':
                        selectedContact = ( contact && contact['account_label'] && !_isEmpty( contact['account_label'] ) ? _find( selected, { id: contact['account_label'] }) : false );
                        formData[schema.id] = ( selectedContact && selectedContact[schema.id] ? selectedContact[schema.id] : ( schema.default || '' ) );
                        break;
                    case 'lead_source_id':
                        selectedContact = ( contact && contact['lead_source'] && !_isEmpty( contact['lead_source'] ) ? _find( selected, { id: contact['lead_source'] }) : false );
                        formData[schema.id] = ( selectedContact && selectedContact[schema.id] ? selectedContact[schema.id] : ( schema.default || '' ) );
                        break;
                    default:
                        selectedContact = ( contact && contact[schema.id] && !_isEmpty( contact[schema.id] ) ? _find( selected, { id: contact[schema.id] }) : false );
                        formData[schema.id] = ( selectedContact && selectedContact[schema.id] ? selectedContact[schema.id] : ( schema.default || '' ) );
                        break;
                } // end - schema
            } // end - schema.merge
        });
        return formData;
    }

    checkIfIsIgnoredSystemData = (schema) => {
        let ignore = false;
        switch ( schema.id ) {
            case 'designation_id':
            case 'account_id':
            case 'lead_source_id':
                ignore = true;
                break;
        }
        return ignore;
    } 

    getInitialValues = () => {
        const { selected } = this.props;
        let contact = {},
            firstID = ( selected && selected[0] && selected[0].id && !_isEmpty( selected[0].id ) ? selected[0].id : false );
        if ( firstID ) {
            _forEach( contactSchema, schema => {
                if ( schema.merge && !this.checkIfIsIgnoredSystemData(schema) ) {
                    contact[schema.id] = firstID;
                } // end - schema.merge
            });
        } // end - firstID
        return contact;
    }

    getInitialMain = () => {
        const { selected } = this.props;
        return ( selected && selected[0] && selected[0].id && !_isEmpty( selected[0].id ) ? selected[0].id : false )
    }

    getCells = () => {
        const { selected } = this.props;
        const cells = [{ id: 'field', label: 'Fields', style: { width: '20%' }, render: (item) => ( item.field || '' ) }];
        if ( selected && isArrayExists( selected ) ) {
            const totalRecords = _size(selected);
            const widthSize = (80/totalRecords);
            for (let index = 1; index <= totalRecords; index++) { 
                cells.push({ id: 'record_'+index, label: 'Record #'+index + ' ('+( selected && selected[index-1] && selected[index-1].id ? selected[index-1].id : '' )+')', style: { width: widthSize+'%' }, render: this.renderCell( index ), renderHead: this.renderHeadCell(index) });
            }
        } // end - selected
        return cells;
    }

    getValue = (schema,index,val) => {
        switch (schema.id) {
            case 'assigned_to':
            case 'mailing_lists':
                let value = '';
                if ( val && val[schema.id] && isArrayExists( val[schema.id] ) ) {
                    _forEach( val[schema.id], item => {
                        if ( item.name && !_isEmpty( item.name ) ) {
                            value += ( !_isEmpty( value ) ? ', ' : '' ) + item.name;
                        }
                    });
                } // end - val[schema.id]
                return value;
            default:
                return ( val && val[schema.id] || '' );
        }
    }

    getItems = () => {
        const { selected } = this.props;
        const { contact } = this.state;
        const items = [];
        const totalRecords = _size(selected);
        _forEach( contactSchema, schema => {
            if ( schema.merge && !this.checkIfIsIgnoredSystemData(schema) ) {
                let item = { id: schema.id, field: ( schema.label || '' ) };
                for (let index = 1; index <= totalRecords; index++) { 
                    let val = ( selected && selected[index-1] || false );
                    item['record_'+index] = this.getValue(schema,index,val);
                }
                items.push(item)
            } // end - schema.merge
        });
        return items;
    }

    isAllChecked = (id) => {
        const { contact } = this.state;
        let checked = true;
        _forEach( contactSchema, schema => {
            if ( schema.merge && !this.checkIfIsIgnoredSystemData(schema) ) {
                if ( contact[schema.id] !== id )
                    checked = false;
            } // end - schema.merge
        });
        return checked;
    }

    renderHeadCell = (index) => (cell) => {
        const { selected, classes } = this.props;
        const { contact, main } = this.state;
        const selectedContactID = ( selected && selected[index-1] && selected[index-1].id && !_isEmpty( selected[index-1].id ) ? selected[index-1].id : false );
        return (
        <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs={1}>
                <Checkbox
                    className={classes.checkbox}
                    checked={( selectedContactID && main && main === selectedContactID ? true : false )}
                    onChange={this.handleHeadCheckboxUpdate(selectedContactID)}
                    color="primary" />
            </Grid>
            <Grid item xs={11}>
                {( cell.label || '' )}
            </Grid>
        </Grid>
        );
    }

    renderCell = (index) => (item) => {
        const { selected, classes } = this.props;
        const { contact, main } = this.state;
        const selectedContactID = ( selected && selected[index-1] && selected[index-1].id && !_isEmpty( selected[index-1].id ) ? selected[index-1].id : false );
        return (
        <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs={1}>
                <Checkbox
                    className={classes.checkbox}
                    checked={( contact && contact[item.id] && contact[item.id] === selectedContactID ? true : false )}
                    disabled={( item.id && ( item.id === 'account_label' || item.id === 'assigned_to' ) ? true : false )} // account will follow the main one
                    onChange={this.handleCheckboxUpdate({ id: selectedContactID, field: item.id })}
                    color="primary" />
            </Grid>
            <Grid item xs={11}>
                {( item && item['record_'+index] && !_isEmpty( item['record_'+index] ) ? item['record_'+index] : '' )}
            </Grid>
        </Grid>
        );
    }

    renderTable = () => {
        const { authData, selected } = this.props;
        const { contact } = this.state;
        return <Table 
                items={this.getItems()}
                showCheckbox={false}
                cells={this.getCells()}
                 />;
    }


    renderModal = () => {
        const { selected, classes } = this.props;
        return (
        <div>
            {this.renderTable()} 
            <InverseButton className={classes.closeBtn} noIconMargin="yes" size="small" minWidth="0px" onClick={this.handleClose}><i className="fa fa-times"></i></InverseButton>           
        </div>
        )
    }

    render() {
        const { open } = this.props;
        return <ModalView 
            open={open}
            title="Merge Contacts"
            actionLabel="Merge"
            maxWidth="lg"
            cancelLabel="Close"
            disableBackdrop={true}
            onClose={this.handleClose}
            doAction={this.handleMerge}
            contents={ open ? this.renderModal() : null } />
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles)
)(ModalMergeContacts);