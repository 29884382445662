/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import _isNumber from 'lodash/isNumber';
import _ceil from 'lodash/ceil';

import { FlexColumn } from '../../styles/division';

import theme from '../../theme';

const Links = styled.ul`
    display: inline-block;
    margin: 0px;
    padding: 0px;
    list-style: none;

    & > li {
        display: inline-block;

        &:last-child() {
            a { border-right: none; }
        }
    }

    a {
        display: block;
        padding: 8px 15px;
        text-align: center;
        color: ${theme.palette.background};
        background: none;
        border-right: 1px solid #ddd;

        &.current {
            background: ${theme.palette.primary.main};
            color: #fff;
            border-right: none;
            cursor: default;
            pointer-events: none;
        }

        &:hover {
            background: ${theme.palette.primary.light};
            color: ${theme.palette.background};
        }

        &.disabled {
            cursor: default;
            pointer-events: none;
        }
    }
`;

class Pagination extends React.Component {

    handlePageChange = (newPage,event) => {
        event.preventDefault();
		this.props.onPageChange(newPage);
    }

	isCurrent = (thisPage) => {
        const { page } = this.props;
		return ( page == thisPage ? ' current' : '' );
	}

    getTotalPages = () => {
        const { total, perPage, page } = this.props;
        return ( total && _isNumber( total ) ? _ceil( total / perPage ) : 0 );
    }

    renderLinks = () => {
        const { total, perPage, page } = this.props;
        var totalPage = this.getTotalPages();
        return (
        <Paper elevation={2} style={{ backgroundColor: "#fff", display: "inline-block" }}>
            <Links>
                {( page > 1 ? this.renderPrevLink('Prev') : '' )}
                {( page > 1 ? this.renderFirstPageLink() : '' )}
                {( ( page - 2 ) > 2 ? this.renderBlank() : '' )}
                {this.renderPaginationLinks()}
                {( ( page + 2 ) < ( totalPage - 1 ) ? this.renderBlank() : '' )}
                {( totalPage != 1 && totalPage != page ? this.renderLastPageLink() : '' )}
                {( page >= totalPage ? '' : this.renderNextLink('Next') )}
            </Links>
        </Paper>
        );
    }

    renderPaginationLinks() {
        const { page } = this.props;
        var links = [],
            totalPage = this.getTotalPages();

		// prev 2 pages
		var i = page - 2;
		if ( i > 1 && i < ( page ) ) {
			links.push(<li key={i}><a href="#" onClick={this.handlePageChange.bind(this, i)}>{i}</a></li>);
		}

		var j = page - 1;
		if ( j > 1 && j < ( page ) ) {
			links.push(<li key={j}><a href="#" onClick={this.handlePageChange.bind(this, j)}>{j}</a></li>);
		}

		// current page
		links.push(<li key={page}><a href="#" className={"current"}>{page}</a></li>);

		// next 2 pages
		for (var k = page + 1; k < totalPage && k <= ( page + 2 ); k++) {
			links.push(<li key={k}><a href="#" className={this.isCurrent(k)} onClick={this.handlePageChange.bind(this, k)}>{k}</a></li>);
		};

		return links;
    }

	renderFirstPageLink() {
		var thisPage = 1;
		return <li key={1}><a href="#" className={this.isCurrent(thisPage)} onClick={this.handlePageChange.bind(this, thisPage)}>{thisPage}</a></li>;
	}

	renderLastPageLink() {
		var thisPage = this.getTotalPages();
		return <li key={thisPage}><a href="#" className={this.isCurrent(thisPage)} onClick={this.handlePageChange.bind(this, thisPage)}>{thisPage}</a></li>;
	}

	renderPrevLink(label) {
        const { page } = this.props;
		return <li><a href="#prev" title="Previous" onClick={this.handlePageChange.bind(this, page-1)}><i className="fa fa-angle-left"></i>{ label ? <span style={{ display: "inline-block", marginLeft: "8px" }}>{label}</span> : '' }</a></li>;
	}

	renderNextLink(label) {
        const { page } = this.props;
		return <li><a href="#next" title="Next" onClick={this.handlePageChange.bind(this, page+1)}>{ label ? <span style={{ display: "inline-block", marginRight: "8px" }}>{label}</span> : '' }<i className="fa fa-angle-right"></i></a></li>;
	}

	renderBlank() {
		return <li><a href="#" className="disabled">...</a></li>
	}

    renderTotalEntries = () => {
        const { total, perPage, page, doneLoaded } = this.props;
		var minNum = (perPage*(page-1))+1,
			maxNum = perPage*page;
        return (
        <Typography display="block" variant="h6">
            Showing { total > 0 ? minNum + ' - ' : '' }{( maxNum > total ? total : maxNum )} of { doneLoaded ? total : <div style={{ display: 'inline-block', padding: '0 10px' }}><CircularProgress size={12} /></div> } entries
        </Typography>
        )
    }

    render() {
        const { style, total } = this.props;
        return (
        <Box display="flex" justifyContent="space-between" alignItems="center" style={( style || null )}>
            <FlexColumn width="30%" style={{ padding: "5px" }}>
                {this.renderTotalEntries()}
            </FlexColumn>
            <FlexColumn width="70%" style={{ padding: "5px", textAlign: "right" }}>
                { total > 0 ? this.renderLinks() : '' }
            </FlexColumn>
        </Box>
        )
    }

}

export default Pagination