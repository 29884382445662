import React from 'react';
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import ReactDataGrid from 'react-data-grid';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Papa from 'papaparse';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _split from 'lodash/split';
import _map from 'lodash/map';
import _startsWith from 'lodash/startsWith';
import _endsWith from 'lodash/endsWith';
import _round from 'lodash/round';

/* eslint-disable */

import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormSelect2 from '../../components/FormSelect2';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormDatePicker from '../../components/FormDatePicker';
import FormMultiOptions from '../../components/FormMultiOptions';

import { InfoButton, GreyButton, AInfoLink, ButtonGroup, SuccessButton } from '../../styles/button';
// import { WrapWord } from '../../styles/misc';
import { FormBox } from '../../styles/form';

import { isAdmin, hasLimitedAccess } from '../../helpers/auth';
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, getSelectValues, doMultiSort } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isSchemaRequired, isSync, getSelectedSchema } from '../../helpers/schemas';
import { formatMoney, stringToInteger, stringToCurrency } from '../../helpers/number';
import { getReadableData } from '../../helpers/report';

import { accountSchema } from '../../schemas/account';
import { contactSchema } from '../../schemas/contact';
import { potentialSchema } from '../../schemas/potential';
import { quoteSchema } from '../../schemas/quote';
import { invoiceSchema } from '../../schemas/invoice';
import { creditNoteSchema } from '../../schemas/credit_note';
import { productSchema } from '../../schemas/product';
import { reportTemplateSchema } from '../../schemas/report_template';

const useStyles = theme => ({
    cell: {
        display: 'block'
    }
});

class ReportTable extends React.Component {

    state = {
        randNum: false
    };

    handleCSVDownload = (event) => {
        event.preventDefault();
        const { report_id } = this.props;
        const report = this.getReportData();
        const columns = this.getColumns();
        var results = { fields: [], data: [] };

        // add header
        if ( columns && isArrayExists( columns ) ) {
            _forEach( columns, column => {
                // ignore first column
                if ( !( column.position < 0 ) ) {
                    results.fields.push( ( column.name && !_isEmpty( column.name ) ? column.name : '' ) );
                } // end - column
            });
        } // end - columns

        // add rows
        if ( report && isArrayExists( report ) ) {
            _forEach( report, item => {
                var row = [];
                if ( columns && isArrayExists( columns ) ) {
                    _forEach( columns, column => {
                        // ignore first column
                        if ( !( column.position < 0 ) ) {
                            var value = '';
                            if ( column.key && item[column.key] ) {
                                // if is calc columns
                                if ( _endsWith( column.key, '__sum' ) || _endsWith( column.key, '__avg' ) || _endsWith( column.key, '__min' ) || _endsWith( column.key, '__max' ) ) {
                                    value = item[column.key]; // just get the calcaluted value
                                } else {
                                    let schema = this.getColumnSchema(column.key);
                                    value = getReadableData( item[column.key], schema );
                                }
                            } // end - column.key
                            row.push( value );
                        } // end - column
                    });
                } // end - columns
                results.data.push(row);
            });
        } // end - report

        var csv = Papa.unparse( results, { delimiter: ',' } );
        var blob = new Blob( [csv] , { type: 'text/csv;charset=utf-8;' }); //new way
        var uri = window.URL.createObjectURL(blob);
        var link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", '_blank');
        link.setAttribute("download", "export_report_"+report_id+".csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    getValueBySchema = (schema,value) => {
        switch( schema.type ) {
            // case 'array':
            // case 'array_ids':
            // case 'array_obj':
            //     var string = '';
            //     if ( value && ( isArrayExists( value ) || isObjectExists( value ) ) ) {
            //         _forEach( value, item => {
            //             string += ( !_isEmpty( string ) ? ', ' : '' )+( item.name || '' )
            //         })
            //     } // end - value
            //     return string;
            default:
                return getReadableData(value,schema);
        }
    }

    getColumnMod = (column_key) => {
        let splitData = ( column_key && !_isEmpty( column_key ) ? _split( column_key, '|' ) : false );
        return ( splitData && splitData[0] && !_isEmpty( splitData[0] ) ? splitData[0] : '' );
    }

    getColumnKey = (column_key) => {
        let splitData = ( column_key && !_isEmpty( column_key ) ? _split( column_key, '|' ) : false );
        return ( splitData && splitData[1] && !_isEmpty( splitData[1] ) ? splitData[1] : '' );
    }

    getColumnSchema = (column_key) => {
        let mod = this.getColumnMod(column_key),
            key = this.getColumnKey(column_key),
            schemas = this.getSchemaByMod(mod);
        return _find( schemas, { id: key });
    }

    getSchemaByMod = (mod) => {
        let selectedSchema = false;
        switch( mod ) {
            case 'accounts':
                selectedSchema = cloneCollections( accountSchema );
                break;
            case 'contacts':
                selectedSchema = cloneCollections( contactSchema );
                break;
            case 'potentials':
                selectedSchema = cloneCollections( potentialSchema );
                break;
            case 'quotes':
                selectedSchema = cloneCollections( quoteSchema );
                break;
            case 'invoices':
                selectedSchema = cloneCollections( invoiceSchema );
                break;
            case 'credit_notes':
                selectedSchema = cloneCollections( creditNoteSchema );
                break;
            case 'products':
                selectedSchema = cloneCollections( productSchema );
                break;
        }
        return selectedSchema;
    }

    getColumn = (schema, mod, column_key, columnCount, defaultColProps) => {
        let column = false,
            name = '';

        switch( mod ) {
            case 'accounts':
                name = 'Account ->';
                break;
            case 'contacts':
                name = 'Contact ->';
                break;
            case 'potentials':
                name = 'Potential ->';
                break;
            case 'quotes':
                name = 'Quote ->';
                break;
            case 'invoices':
                name = 'Invoice ->';
                break;
            case 'products':
                name = 'Product ->';
                break;
        }

        // get key label
        if ( schema && schema.label && !_isEmpty( schema.label ) ) {
            name += ' ' + ( schema.report_label && !_isEmpty( schema.report_label ) ? schema.report_label : schema.label );
        } // end - selectedSchema

        // prepare to be push to columns
        column = {
            position: columnCount, // based on selected position
            key: column_key,
            name,
            formatter: this.renderCell(schema),
            width: 200,
            ...defaultColProps
        };

        return column;
    }

    getCalcFormula = (column) => {
        const { template } = this.props;
        var formula = [];
        if ( template && template.columns_calculation && isArrayExists( template.columns_calculation ) ) {
            var selected = _find( template.columns_calculation, { col_num: column.key } );
            if ( selected && selected.calc && isArrayExists( selected.calc ) )
                formula = cloneCollections( selected.calc );
        } // end - template.columns_calculation
        return ( formula && isArrayExists( formula ) ? formula : false );
    }

    getCalcColumn = (column,method,schema) => {
        var newColumn = cloneCollections( column );
        newColumn.key += '__'+method;
        newColumn.name += ' ('+method.toUpperCase()+')';
        newColumn.formatter = this.renderCalcCell(schema);
        return newColumn;
    }

    getColumns = () => {
        const { template, report } = this.props;
        const defaultColProps = {
            resizable: true,
        };
        var columnCount = 0,
            columns = ( template && template.columns_calculation && isArrayExists( template.columns_calculation ) ? [] : [{
                position: -1,
                key: 'primary_id',
                name: '#',
                width: 30,
                formatter: this.renderActionCell,
                ...defaultColProps
            }] );

        // make sure there are columns selected
        if ( template && template.columns && isArrayExists( template.columns ) ) {
            // arrangement based on columns
            _forEach( template.columns, column_key => {
                let mod = this.getColumnMod(column_key),
                    key = this.getColumnKey(column_key),
                    schemas = this.getSchemaByMod( mod ),
                    schema = ( schemas && isArrayExists( schemas ) ? _find( schemas, {id: key}) : false ),
                    column = ( schema ? this.getColumn( schema, mod, column_key, columnCount, defaultColProps ) : false );
                if ( column ) {
                    // check if is calculate column
                    var formula = this.getCalcFormula(column);
                    if ( formula ) {
                        _forEach( formula, method => {
                            var newColumn = this.getCalcColumn(column,method,schema);
                            columns.push( newColumn );
                            columnCount++; // do column count
                        });
                    } else {
                        columns.push( column );
                        columnCount++; // do column count
                    }
                } // end - column
            });

            // // get primary columns first
            // if ( template.primary_module && !_isEmpty( template.primary_module ) ) {
            //     let primary_columns = _filter( template.columns, (c) => _startsWith( c, template.primary_module + '|' )), // filter all columns for this schema
            //         schemas = this.getSchemaByMod( template.primary_module );

            //     // make sure schemas exists & there are columns as well
            //     if ( schemas && isArrayExists( schemas ) && primary_columns && isArrayExists( primary_columns ) ) {
            //         // run through the whole schema
            //         _forEach( schemas, schema => {  
            //             // check if the selected schema is in the columns
            //             var column_key = _find( primary_columns, (c) => ( c === template.primary_module + '|' + schema.id ));
            //             if ( column_key ) {
            //                 let column = this.getColumn( schema, template.primary_module, column_key, columnCount, defaultColProps );
            //                 if ( column ) {
            //                     columns.push( column );
            //                     columnCount++; // do column count
            //                 } // end - column
            //             } // end - column_key
            //         });
            //     } // end - schemas

            // } // end - template.primary_module

            // // get related columns
            // if ( template.related_modules && isArrayExists( template.related_modules ) ) {
            //     _forEach( template.related_modules, related_mod => {
            //         let related_columns = _filter( template.columns, (c) => _startsWith( c, related_mod + '|' )),
            //             schemas = this.getSchemaByMod( related_mod );

            //         // make sure schemas exists & there are columns as well
            //         if ( schemas && isArrayExists( schemas ) && related_columns && isArrayExists( related_columns ) ) {
            //             // run through the whole schema
            //             _forEach( schemas, schema => {  
            //                 // check if the selected schema is in the columns
            //                 var column_key = _find( related_columns, (c) => ( c === related_mod + '|' + schema.id ));
            //                 if ( column_key ) {
            //                     let column = this.getColumn( schema, related_mod, column_key, columnCount, defaultColProps );
            //                     if ( column ) {
            //                         columns.push( column );
            //                         columnCount++; // do column count
            //                     } // end - column
            //                 } // end - column_key
            //             });
            //         } // end - schemas

            //     });
            // } // end - template.related_modules

        } // end - columns

        return _sortBy( columns, ['position'] );
    }

    getReportData = () => {
        const { report, template } = this.props;
        var items = ( report && isArrayExists( report ) ? cloneCollections( report ) : [] ),
            toBeExcluded = []; // to store excluded row

        // do calculation
        if ( template && template.columns_calculation && isArrayExists( template.columns_calculation ) ) {
            var calcList = _sortBy( cloneCollections( template.columns_calculation ), ['position'] ),
                grouped_by = cloneCollections( template.columns_calculation[0] );
            items = []; // reset to empty array
            _forEach( report, (row,index) => {
                // skip if is in toBeExcluded
                if ( !_find( toBeExcluded, { id: row.id } ) ) {
                    var newRow = cloneCollections( row ),
                        similar_items = ( grouped_by && grouped_by.col_main && !_isEmpty( grouped_by.col_main ) ? _filter( report, (r) => ( r[grouped_by.col_main] === row[grouped_by.col_main] )) : [] );

                    _forEach( calcList, formula => {
                        var schema = this.getColumnSchema(formula.col_num);

                        // do calcuation
                        if ( formula.calc && isArrayExists( formula.calc ) ) {
                            _forEach( formula.calc, method => {
                                var val = 0;
                                switch ( method ) {
                                    case 'avg':
                                    case 'sum':
                                        var itemCount = 0;
                                        _forEach( similar_items, i => {
                                            itemCount++;
                                            val += ( i[formula.col_num] ? ( schema.type === 'currency' ? stringToCurrency(i[formula.col_num]) : stringToInteger( i[formula.col_num] ) ) : 0 );
                                        });
                                        if ( method === 'avg' )
                                            val = (val/itemCount);
                                        break;
                                    case 'min':
                                    case 'max':
                                        var itemCount = 0;
                                        _forEach( similar_items, i => {
                                            itemCount++;
                                            var currentVal = ( i[formula.col_num] ? ( schema.type === 'currency' ? stringToCurrency(i[formula.col_num]) : stringToInteger( i[formula.col_num] ) ) : 0 );
                                            if ( itemCount > 1 ) { 
                                                if ( method === 'min' ) {
                                                    if ( currentVal < val )
                                                        val = currentVal
                                                } else {
                                                    if ( currentVal > val )
                                                        val = currentVal
                                                } // end - method
                                            } else {
                                                val = currentVal
                                            } // end - itemCount
                                        });
                                        break;
                                }
                                newRow[formula.col_num+'__'+method] = ( schema.type === 'currency' ? formatMoney(val,2,3) : _round(val,2) );
                            });
                        } // end - formula.calc
                    });

                    // add similar_items to toBeExcluded
                    if ( similar_items && isArrayExists( similar_items ) ) {
                        _forEach( similar_items, i => {
                            // as long as it isn't from the same row
                            if ( row.id !== i.id )
                                toBeExcluded.push({ id: i.id });
                        })
                    } // end - similar_items

                    // add to items
                    items.push( newRow );

                } // end - toBeExcluded
            });

        } // end - template.columns_calculation

        // re-do sort by
        if ( template && template.sort_by && isArrayExists( template.sort_by ) ) {
            var sort_data = { columns: [], orderby: [], type: [] };
            _forEach( _sortBy( template.sort_by, [(s) => parseInt( s.position, 10 )] ), sortby => {
                var selected_schema = getSelectedSchema( sortby.column ); // get schema
                // if calculation exists, use calculation column id
                var selected_col = ( template && template.columns_calculation && isArrayExists( template.columns_calculation ) ? _find( template.columns_calculation , { col_num: sortby.column } ) : false );
                if ( selected_col && selected_col.calc && isArrayExists( selected_col.calc ) ) {
                    _forEach( selected_col.calc, method => {
                        sort_data.columns.push( sortby.column + '__' + method );
                        
                        if ( selected_schema && selected_schema.type )
                            sort_data.type.push( selected_schema.type );

                        // set direction
                        if ( sortby.direction && sortby.direction === 'desc' ) {
                            sort_data.orderby.push( 'DESC' );
                        } else {
                            sort_data.orderby.push( 'ASC' );
                        } // end - sortby.direction
                    });
                } else {
                    sort_data.columns.push( sortby.column );

                    if ( selected_schema && selected_schema.type )
                        sort_data.type.push( selected_schema.type );

                    // set direction
                    if ( sortby.direction && sortby.direction === 'desc' ) {
                        sort_data.orderby.push( 'DESC' );
                    } else {
                        sort_data.orderby.push( 'ASC' );
                    } // end - sortby.direction
                }
            });
            // do sort
            items = doMultiSort( items, sort_data.columns, sort_data.orderby, sort_data.type );
        } // end - template.sort_by

        return items;
    }

    canSeePreviousReport = () => {
        const { authData, template } = this.props;
        var valid = false;
        if ( authData ) {
            if ( hasLimitedAccess( authData ) ) {
                // if is limited user - make sure the report is from them
                if ( template && template.generated_by && !_isEmpty( template.generated_by ) && authData.email && !_isEmpty( authData.email ) && template.generated_by === authData.email )
                    valid = true;
            } else {
                valid = true;
            } // end - authData
        } // end - authData
        return valid
    }

    renderCalcCell = ( schema ) => (props) => {
        const { row, value } = props;
        return <div>{( value || '')}</div>;
    }

    renderCell = ( schema ) => (props) => {
        const { row, value } = props;
        return <div>{this.getValueBySchema(schema,value)}</div>;
    }

    renderActionCell = (props) => {
        const { row, value } = props;
        return <AInfoLink href={( row && row.primary_module && value ? "/"+row.primary_module+"/"+value : '' )} size="small" noIconMargin="yes" minWidth="none" target="_blank" style={{ lineHeight: '1.15' }}><i className="fa fa-search"></i></AInfoLink>
    }

    renderTable = () => {
        const report = this.getReportData();
        return <ReactDataGrid
            columns={this.getColumns()}
            rowGetter={i => report[i]}
            rowsCount={_size( report )}
            minHeight={600} />
    }

    renderButtons = () => {
        return (
        <div style={{ paddingTop: '30px' }}>
            <ButtonGroup>
                <SuccessButton style={{ padding: '10px 45px' }} onClick={this.handleCSVDownload}><i className="fa fa-file-excel-o"></i>Export to CSV</SuccessButton>
            </ButtonGroup>
        </div>
        )
    }

    renderGeneratedBy = () => {
        const { template, users } = this.props;
        const user = ( template && template.generated_by && !_isEmpty( template.generated_by ) ? _find( users, { email: template.generated_by } ) : false );
        return ( user && user.name && !_isEmpty( user.name ) ? ' by ' + user.name : ( template && template.generated_by && !_isEmpty( template.generated_by ) ? template.generated_by : '' ) );
    }

    render() {
        const { authData, report, template } = this.props;
        return (
        <div>
            { this.canSeePreviousReport() && template && template.generated_on ? <div style={{ marginBottom: '30px', fontStyle: 'italic' }}>Data generated on {getMomentTime( template.generated_on, 'YYYY-MM-DD HH:mm' )}{this.renderGeneratedBy()}</div> : null }
            { report && isArrayExists( report ) ? this.renderTable() : <div>{ template && template.generated_on ? "No data found." : "No data generated yet." }</div> }
            { report && isArrayExists( report ) && authData && !hasLimitedAccess(authData) ? this.renderButtons() : null }
        </div>
        )
    }

}

export default compose(
    withStyles(useStyles),
    withRouter
)(ReportTable);