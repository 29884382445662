/* eslint-disable */
import _random from 'lodash/random';

import {
    M_GET_PAYMENT_TERMS,
    M_PAYMENT_TERMS_UPDATED
} from '../types';

import { 
	toggleModalDeleting, toggleModalProcessing, 
	toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
// import { appChangesReset } from '../misc';

import { fb_getPaymentTerms, fb_addPaymentTerm, fb_updatePaymentTerm, fb_deletePaymentTerm } from './fb';

export const getPaymentTerms = () => {
	return dispatch => {

		fb_getPaymentTerms(list => {
			dispatch({ 
                type: M_GET_PAYMENT_TERMS, 
                payload: { list } 
            });
		});

	}
}

export const addNewPaymentTerm = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addPaymentTerm(formData)
		.then( results => {
			dispatch({ type: M_PAYMENT_TERMS_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Payment Term Added");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new payment term' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const updatePaymentTerm = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_updatePaymentTerm(formData)
		.then( results => {
			dispatch({ type: M_PAYMENT_TERMS_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Payment Term Updated");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update payment term' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deletePaymentTerm = (id) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deletePaymentTerm(id)
		.then( results => {
			dispatch({ type: M_PAYMENT_TERMS_UPDATED });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Payment Term Deleted");
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete payment term' ) );
			triggerErrorAlert(errMsg);
		});

	}
}