/* eslint-disable */
import _random from 'lodash/random';

import {
    M_GET_COURSE_PORTFOLIOS,
    M_COURSE_PORTFOLIOS_UPDATED
} from '../types';

import { 
	toggleModalDeleting, toggleModalProcessing, 
	toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
// import { appChangesReset } from '../misc';

import { fb_getCoursePortfolios, fb_syncCoursePortfolios, fb_addCoursePortfolio, fb_deleteCoursePortfolio, fb_updateCoursePortfolio } from './fb';

export const getCoursePortfolios = () => {
	return dispatch => {

		fb_getCoursePortfolios(list => {
			dispatch({ 
                type: M_GET_COURSE_PORTFOLIOS, 
                payload: { list } 
            });
		});

	}
}

export const addNewCoursePortfolio = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addCoursePortfolio(formData)
		.then( results => {
			dispatch({ type: M_COURSE_PORTFOLIOS_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Business Added");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new Business' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const updateCoursePortfolio = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));
		dispatch(toggleModalDeleting(true));

		fb_updateCoursePortfolio(formData)
		.then( results => {
			dispatch({ type: M_COURSE_PORTFOLIOS_UPDATED });
			dispatch(toggleModalProcessing(false));
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Business Updated");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update Business' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteCoursePortfolio = (id) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteCoursePortfolio(id)
		.then( results => {
			dispatch({ type: M_COURSE_PORTFOLIOS_UPDATED });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Business Deleted");
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete Business' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const syncDataWithLMS = (id,type) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_syncCoursePortfolios()
		.then(() => {
			dispatch({ type: M_COURSE_PORTFOLIOS_UPDATED });
			dispatch(toggleLoader(false));
			triggerSuccessAlert("Course Portfolio Synced!");
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to sync data with LMS' ) );
			dispatch(toggleLoader(false));
			triggerErrorAlert(errMsg);
		});

  	}
}