/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
//import Select2 from 'react-select';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';

import { FlexColumn } from '../../styles/division';
import { InfoButton, GreyButton, ButtonGroup, InverseButton } from '../../styles/button';

import FormSelect from '../FormSelect';
import FormSelect2 from '../FormSelect2';
import PopperBox from '../Popper';
import SearchFilterFireStore from '../SearchFilterFireStore';

import { isArrayExists } from '../../helpers/validation';
import { isAdmin } from '../../helpers/auth';
import { triggerErrorAlert } from '../../helpers/alert';

import theme from '../../theme';

const useStyles = theme => ({
    selector: {
        
    }
});

class TableBarFirestore extends React.Component {

    state = {
        keywords: '',
        openSearch: false,
        searchAnchor: {}
    }

    handleEntriesChange = (event) => {
        if ( this.props.onEntriesChange )
            this.props.onEntriesChange( event.target.value );
    }

    handleSortByChange = (event) => {
        if ( this.props.onSortByChange )
            this.props.onSortByChange( event.target.value );
    }

    handlefilterByChange = (newValue) => {
        if ( this.props.onFilterByChange )
            this.props.onFilterByChange( newValue );
    }

    handleInputChange = (event) => {
        this.setState({ keywords: event.target.value });
    }

    handleSearchChange = (keywords) => {
        if ( this.props.onSearchChange ) {
            this.props.onSearchChange( keywords );
        }
    }

    isSearch = () => {
        const { searchterms } = this.props;
        return ( searchterms && !_isEmpty( searchterms ) ? true : false ); 
    }

    showItem = (id) => {
        const { show } = this.props;
        return ( !show || ( show && isArrayExists( show ) && _find( show, (o) => ( o == id ) ) ) ? true : false );
    }

    renderFilterBy = () => {
        const { filterBy, filterByOptions, filterByPlaceholder } = this.props;
        return (
        <div style={{ maxWidth: '300px', width: '100%' }}>
            <FormSelect2 
                label="Filter By"
                placeholder={( filterByPlaceholder || '' )}
                options={( filterByOptions || [] )}
                value={( filterBy || false )}
                isClearable={true}
                isSearchable={true}
                onChange={this.handlefilterByChange} />
        </div>
        // <FormControl variant="outlined" style={{ background: "#fff", marginRight: "5px" }}>
        //     {/* <InputLabel style={{ background: "#fff", padding: "5px", color: theme.palette.background }}>Filter By</InputLabel> */}
        //     <FormSelect2 
        //         label="Filter By"
        //         options={( filterByOptions || [] )}
        //         value={( filterBy || false )}
        //         isClearable={true}
        //         isSearchable={true}
        //         onChange={this.handlefilterByChange} />
        // </FormControl>
        );
    }

    renderSortBy = () => {
        const { sortBy, sortByOptions } = this.props;
        return (
        <FormControl variant="outlined" style={{ background: "#fff", marginRight: "5px" }}>
            <InputLabel style={{ background: "#fff", padding: "5px", color: theme.palette.background }}>Sort By</InputLabel>
            <Select native value={sortBy} 
                onChange={this.handleSortByChange}
                input={
                    <OutlinedInput name="sortby" />
                } >
                {sortByOptions.map(option => {
                    return <option key={option.value} value={option.value}>{option.label}</option>
                })}
            </Select>
        </FormControl>
        );
    }

    renderEntries = () => {
        const { authData, perPage, limitedPerPage } = this.props;
        return (
        <FormControl variant="outlined" style={{ background: "#fff", marginRight: "5px" }}>
            <InputLabel style={{ background: "#fff", padding: "5px", color: theme.palette.background }}>Per Page</InputLabel>
            <Select native value={perPage} 
                onChange={this.handleEntriesChange}
                input={
                    <OutlinedInput name="perpage" />
                } >
                <option value={5}>5 per page</option>
                <option value={20}>20 per page</option>
                { limitedPerPage ? null : <option value={40}>40 per page</option> }
                { limitedPerPage ? null : <option value={60}>60 per page</option> }
                { limitedPerPage ? null : <option value={80}>80 per page</option> }
                { limitedPerPage ? null : <option value={100}>100 per page</option> }
                { authData && isAdmin( authData ) ? <option value={200}>200 per page</option> : null }
                { authData && isAdmin( authData ) ? <option value={500}>500 per page</option> : null }
            </Select>
        </FormControl>
        )
    }

    render() {
        const { mod, style, sortByOptions, filterByOptions, leftWidth, rightWidth, leftButtons, rightButtons, searchterms, searchOptions, dateOptions } = this.props;
        const { openSearch, searchAnchor } = this.state;
        return (
        <div>
            <Box display="flex" justifyContent="space-between" alignItems="center" style={( style || null )}>
                <FlexColumn width={( leftWidth || "30%" )}>
                    <Box display="flex">
                        { this.showItem('search') ? (
                            <SearchFilterFireStore 
                                mod={mod}
                                searchterms={searchterms}
                                searchOptions={( searchOptions || [] )}
                                dateOptions={( dateOptions || [] )}
                                onSearchChange={this.handleSearchChange} />
                        ) : null }
                        { leftButtons && isArrayExists( leftButtons ) ? leftButtons.map((btn,index) => {
                            return btn;
                        }) : null }
                    </Box>
                </FlexColumn>
                <FlexColumn width={( rightWidth || "70%" )}>
                    <Box display="flex" justifyContent="flex-end">
                        { rightButtons && isArrayExists( rightButtons ) ? rightButtons.map((btn,index) => {
                            return btn;
                        }) : null }
                        { this.showItem('filter') && filterByOptions && isArrayExists( filterByOptions ) ? this.renderFilterBy() : null }
                        { this.showItem('sort') && sortByOptions && isArrayExists( sortByOptions ) ? this.renderSortBy() : null }
                        { this.showItem('entries') && !this.isSearch() ? this.renderEntries() : null }
                    </Box>
                </FlexColumn>
            </Box>
        
        </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles)
)(TableBarFirestore);