import _now from 'lodash/now';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _last from 'lodash/last';
import _size from 'lodash/size';
import _endsWith from 'lodash/endsWith';
import moment from 'moment';

import { isTimeStamp, isArrayExists } from './validation';
import { replaceAll, combineStringsBySeparator } from './data';

/* convert value into date using moment */
export const getMomentTime = ( value, format , offset ) => {
    var utc = ( offset ? offset : 8 );
    return ( value ? moment(value).utcOffset(utc).format(format) : '' );
}

/* convert now into date using moment */
export const getMomentNow = ( format , offset ) => {
    var utc = ( offset ? offset : 8 );
    return moment().utcOffset(utc).format(format);
}

/* convert use moment to convert whatever value to timestamp */
export const getMomentTimestamp = ( value , offset ) => {
    var utc = ( offset ? offset : 8 );
    return moment(value).utcOffset(utc).valueOf();
}

/* compare time from x using moment */
export const compareTimeFromMoment = ( value, offset ) => {
    var utc = ( offset ? offset : 8 );
    return ( value ? moment().utcOffset(utc).from(value, true) : '' );
}

// get date based on condition set
export const getDateBasedCondition = ( condition, dateformat, offset ) => {
    var date = '',
        format = ( dateformat ? dateformat : 'YYYY/MM/DD' ),
        utc = ( offset ? offset : 8 );
    switch( condition ) {
        case 'previous-fy':
            var start = moment().utcOffset(utc).subtract(1,'year').startOf('year').format(format),
                end = moment().utcOffset(utc).subtract(1,'year').endOf('year').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'current-fy':
            var start = moment().utcOffset(utc).startOf('year').format(format),
                end = moment().utcOffset(utc).endOf('year').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'next-fy':
            var start = moment().utcOffset(utc).add(1,'year').startOf('year').format(format),
                end = moment().utcOffset(utc).add(1,'year').endOf('year').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'previous-fq':
            var start = moment().utcOffset(utc).subtract(1,'quarters').startOf('quarters').format(format),
                end = moment().utcOffset(utc).subtract(1,'quarters').endOf('quarters').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'current-fq':
            var start = moment().utcOffset(utc).startOf('quarters').format(format),
                end = moment().utcOffset(utc).endOf('quarters').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'next-fq':
            var start = moment().utcOffset(utc).add(1,'quarters').startOf('quarters').format(format),
                end = moment().utcOffset(utc).add(1,'quarters').endOf('quarters').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break
        case 'yesterday':
            date = moment().utcOffset(utc).subtract(1,'day').startOf('date').format(format);
            break;
        case 'today':
            date = moment().utcOffset(utc).startOf('date').format(format);
            break;
        case 'tomorrow': 
            date = moment().utcOffset(utc).add(1,'day').startOf('date').format(format);
            break;
        case 'previous-week':
            var start = moment().utcOffset(utc).subtract(1,'week').startOf('isoWeek').format(format),
                end = moment().utcOffset(utc).subtract(1,'week').endOf('isoWeek').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'current-week':
            var start = moment().utcOffset(utc).startOf('isoWeek').format(format),
                end = moment().utcOffset(utc).endOf('isoWeek').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'next-week':
            var start = moment().utcOffset(utc).add(1,'week').startOf('isoWeek').format(format),
                end = moment().utcOffset(utc).add(1,'week').endOf('isoWeek').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'previous-month':
            var start = moment().utcOffset(utc).subtract(1,'month').startOf('month').format(format),
                end = moment().utcOffset(utc).subtract(1,'month').endOf('month').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'current-month':
            var start = moment().utcOffset(utc).startOf('month').format(format),
                end = moment().utcOffset(utc).endOf('month').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'next-month':
            var start = moment().utcOffset(utc).add(1,'month').startOf('month').format(format),
                end = moment().utcOffset(utc).add(1,'month').endOf('month').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'last-7days':
            var start = moment().utcOffset(utc).subtract(6,'day').startOf('date').format(format),
                end = moment().utcOffset(utc).startOf('date').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'last-30days':
            var start = moment().utcOffset(utc).subtract(29,'day').startOf('date').format(format),
                end = moment().utcOffset(utc).startOf('date').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'last-60days':
            var start = moment().utcOffset(utc).subtract(59,'day').startOf('date').format(format),
                end = moment().utcOffset(utc).startOf('date').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'last-90days':
            var start = moment().utcOffset(utc).subtract(89,'day').startOf('date').format(format),
                end = moment().utcOffset(utc).startOf('date').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'last-120days':
            var start = moment().utcOffset(utc).subtract(119,'day').startOf('date').format(format),
                end = moment().utcOffset(utc).startOf('date').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'next-7days':
            var start = moment().utcOffset(utc).startOf('date').format(format),
                end = moment().utcOffset(utc).add(6,'day').startOf('date').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'next-30days':
            var start = moment().utcOffset(utc).startOf('date').format(format),
                end = moment().utcOffset(utc).add(29,'day').startOf('date').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'next-60days':
            var start = moment().utcOffset(utc).startOf('date').format(format),
                end = moment().utcOffset(utc).add(59,'day').startOf('date').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'next-90days':
            var start = moment().utcOffset(utc).startOf('date').format(format),
                end = moment().utcOffset(utc).add(89,'day').startOf('date').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
        case 'next-120days':
            var start = moment().utcOffset(utc).startOf('date').format(format),
                end = moment().utcOffset(utc).add(119,'day').startOf('date').format(format);
            date = combineStringsBySeparator([ start, end ],', ');
            break;
    }
    return date;
}