/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_INVOICES,
	GET_INVOICES_BY,
	GET_INVOICE,
	ADD_INVOICE,
	EDIT_INVOICE,
	DELETE_INVOICE,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getInvoices, fb_getInvoicesBy, fb_getInvoice, fb_getPreloadInvoiceData, fb_addInvoice, fb_updateInvoice, fb_generateInvoicePDF, fb_duplicateInvoice, fb_deleteInvoice } from './fb';


export const getInvoices = (props) => {
	return dispatch => {

		fb_getInvoices(props)  
		.then(({ list, total, firstDoc, lastDoc }) => {
			dispatch({
				type: GET_INVOICES,
				payload: { list, total, firstDoc, lastDoc }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_INVOICES,
				payload: { list: [] }
			});
		});

	}
}

export const getInvoicesBy = (type,id) => {
	return dispatch => {

		fb_getInvoicesBy(type,id)  
		.then(list => {
			dispatch({
				type: GET_INVOICES_BY,
				payload: { list }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_INVOICES_BY,
				payload: { list: {} }
			});
		});

	}
}

export const getInvoice = (invoice_id) => {
	return dispatch => {

		fb_getInvoice(invoice_id)  
		.then(invoice => {
			dispatch({
				type: GET_INVOICE,
				payload: { invoice }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve data' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_INVOICE,
				payload: { invoice: {} }
			});
		});

	}
}

export const getPreloadInvoiceData = (props) => {
	return dispatch => {

		fb_getPreloadInvoiceData(props)  
		.then(invoice => {
			dispatch({
				type: GET_INVOICE,
				payload: { invoice }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve data' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_INVOICE,
				payload: { invoice: {} }
			});
		});

	}
}

export const addNewInvoice = (formData,history) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_addInvoice(formData)
		.then( results => {
			if ( results && results.new_invoice_id && !_isEmpty( results.new_invoice_id ) ) {

				fb_getInvoice( results.new_invoice_id )
				.then( invoice => {

					// updated
					dispatch({ type: GET_INVOICE, payload: { invoice } });
					dispatch(toggleLoader(false));
					dispatch(appChangesReset());
					triggerSuccessAlert("Invoice Added");

					// redirect to invoice page
					history.push("/invoices/" + results.new_invoice_id);

				});

			} else {
				dispatch(toggleLoader(false));
				triggerErrorAlert('Missing new invoice ID');
			}
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new invoice' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const editInvoice = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateInvoice(formData)
		.then( results => {

			fb_getInvoice(formData.id)
			.then(invoice => {
				// push to list
				dispatch({ type: GET_INVOICE, payload: { invoice } });

				// updated
				dispatch({ type: EDIT_INVOICE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Invoice Updated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update invoice' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const generateInvoicePDF = (id) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_generateInvoicePDF(id)
		.then( results => {

			fb_getInvoice(id)
			.then(invoice => {
				// push to list
				dispatch({ type: GET_INVOICE, payload: { invoice } });

				// updated
				dispatch({ type: EDIT_INVOICE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("PDF Generated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to generate invoice  PDF' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const duplicateInvoice = (id,history) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_duplicateInvoice(id)
		.then( results => {
			if ( results && results.new_invoice_id && !_isEmpty( results.new_invoice_id ) ) {

				fb_getInvoice( results.new_invoice_id )
				.then( invoice => {

					// updated
					dispatch({ type: GET_INVOICE, payload: { invoice } });
					dispatch(toggleLoader(false));
					triggerSuccessAlert("Invoice Duplicated");

					// redirect to invoice page
					history.push("/invoices/" + results.new_invoice_id);

				});

			} else {
				dispatch(toggleLoader(false));
				triggerErrorAlert('Missing new invoice ID');
			} // end - results
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to duplicate invoice' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteInvoice = (id,props) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteInvoice(id)
		.then( results => {

			fb_getInvoices(props)  
			.then(({ list, total, firstDoc, lastDoc }) => {
				// update list
				dispatch({
					type: GET_INVOICES,
					payload: { list, total, firstDoc, lastDoc }
				});

				// trigger updated
				dispatch({ type: DELETE_INVOICE });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("Invoice Deleted");
			});

		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete list' ) );
			triggerErrorAlert(errMsg);
		});

	}
}