/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';

import ModalView from '../ModalView';
import FormInput from '../../components/FormInput';

import { InfoButton } from '../../styles/button';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { compareTimeFromMoment } from '../../helpers/date';

import { addNewComment } from '../../actions/comments';

class ListComments extends React.Component {

    state = {
        open: false,
        comment: ''
    }

    handleReset = () => {
        this.setState({ open: false, comment: '' });
    }

    handleAddNew = () => {
        const { comment } = this.state;
        const { authData, refer_type, refer_id, refer_label, updateActions } = this.props;

        if ( comment && !_isEmpty( comment ) ) {
            // trigger add comment dispatch
            this.props.dispatch(addNewComment({
                refer_type,
                refer_id,
                refer_label,
                text: comment
            },updateActions));
        } else {
            triggerErrorAlert("Please enter something in the comment box");
        } // end - comment
    }

    handleOpenModal = () => {
        const { changesMade } = this.props;
        if ( changesMade ) {
            alert("Please save changes first before proceed");
        } else {
            this.setState({ open: true, comment: '' });
        } // end - changesMade
    }

    renderAddNewForm = () => {
        const { comment } = this.state;
        return (
        <div>
            <FormInput multiline={true} rows={3} label="Comment" name="comment" value={( comment || '' )} onChange={(newValue) => this.setState({ comment: newValue })} />
        </div>
        )
    }

    renderItem = (item) => {
        return (
        <Box key={item.id} display="flex" alignItems="center" justifyContent="space-between" style={{ marginBottom: "10px" }}>
            <div>
                <div style={{ fontSize: "1.35rem", fontWeight: "700" }}>{( item.user_name && !_isEmpty( item.user_name ) ? item.user_name : item.user )}</div>
                <div style={{ fontSize: "1.15rem" }}>{item.text}</div>
            </div>
            { item.commented_on ? <div style={{ width: "20%", fontSize: "1rem", textAlign: 'right' }}>{compareTimeFromMoment(item.commented_on) + ' ago'}</div> : null }
        </Box>
        );
    }

    renderList = () => {
        const { list } = this.props;
        let items = _sortBy( list, ['commented_on'] );
        items = _reverse( items );
        return items.map(this.renderItem);
    }

    render() {
        const { list } = this.props;
        const { open } = this.state;
        return (
        <div>

            <div style={{ padding: "15px 15px 5px 15px", background: "#f1f1f1", marginTop: "15px" }}>
                { list && isArrayExists( list ) ? this.renderList() : 'No comment(s) yet' }
            </div>

            <div style={{ marginTop: "6px", textAlign: "right" }}>
                <InfoButton size="small" onClick={this.handleOpenModal}><i className="fa fa-comment-o" style={{ marginRight: "5px" }}></i>Add Comment</InfoButton>
            </div>

            <ModalView 
                open={open}
                title="Add New Comment"
                onClose={this.handleReset}
                doAction={this.handleAddNew}
                contents={this.renderAddNewForm()} />

        </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ListComments);