/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _toLower from 'lodash/toLower';
import _size from 'lodash/size';
import _snakeCase from 'lodash/snakeCase';

import Table from '../../components/Table';
import TableBar from '../../components/TableBar';
// import Pagination from '../../components/Pagination';

// import FormInput from '../../components/FormInput';
// import FormSelect from '../../components/FormSelect';
// import FormMultiSelect from '../../components/FormMultiSelect';
// import FormDatePicker from '../../components/FormDatePicker';

// import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination } from '../../helpers/data';
// import { getMomentTime } from '../../helpers/date';
// import { isSchemaRequired } from '../../helpers/schemas';

import { InverseButton, ButtonGroup, InfoButton, GreyButton, AInfoLink } from '../../styles/button';

import { accountSchema } from '../../schemas/account';

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

class DuplicatesList extends React.Component {

    state = {
        selected: [],
        openMerge: false
    };

    handleMergeView = () => {
        const { selected } = this.state;
        if ( selected && isArrayExists( selected ) && _size( selected ) > 1 ) {
            this.setState({ openMerge: true });
        } else {
            triggerErrorAlert("Please select at least 2 items to perform merge")
        } // end - selected
    }

    getList = () => {
        const { list, field } = this.props;
        const { selected } = this.state;
        const items = cloneCollections(list);

        if ( selected && isArrayExists( selected ) ) {
            const compare = _find( list, { id: selected[0].id } );
            _forEach( list, (item,index) => {
                // check if value is the same as selected
                if ( compare && field && compare[field] && !_isEmpty( compare[field] ) && _snakeCase( compare[field] ) === _snakeCase( item[field] ) ) {
                    items[index].disabled_check = false;
                } else {
                    items[index].disabled_check = true;
                } // end - compare
            });
        } // end - selected

        return items;
    }

    getTableCells = () => {
        const { field } = this.props; 
        const cells = [
            { id: 'id', label: 'ID', style: { width: '150px' }, render: (item) => ( item.id || '' ) },
            { id: 'name', label: 'Name', render: (item) => ( item.name || '' ) }
        ];
        if ( field && !_isEmpty( field ) && field !== 'name' ) {
            const selected_schema = _find( accountSchema, { id: field } );
            if ( selected_schema ) {
                cells.push({
                    id: selected_schema.id, label: selected_schema.label || '', render: (item) => ( item[selected_schema.id] || '' )
                });
            } // end - selected_schema
        } // end - field
        return cells;
    }

    renderTableActions = () => {
        const { selected } = this.state;
        return <TableBar
                show={['nothing']}
                leftButtons={ selected && isArrayExists( selected ) ? [
                    <div key="check_option">
                        <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ selected: [] })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(selected) + ' selected'}</GreyButton>
                    </div>
                    ] : null }
                rightButtons={[
                    <InfoButton minWidth="128px" key="merge" style={{ marginRight: "5px", padding: '10px 25px' }} onClick={this.handleMergeView}><i className="fa fa-random"></i>Merge Selected Accounts</InfoButton>
                ]}
                style={{ marginBottom: "20px" }} />
    }

    renderTable = () => {
        const { field } = this.props;
        const { selected } = this.state;
        const list = this.getList();
        return <Table 
            items={_sortBy( list, [(i) => field && i[field] && _toLower( i[field] )] )}
            showCheckbox={true}
            hideAllCheck={true}
            checked={( selected || [] )}
            onChecked={(newValue) => this.setState({ selected: newValue })}
            cells={this.getTableCells()}
            actionStyles={{ width: "10%" }}
            actions={(item) => (
                <ButtonGroup>
                    <AInfoLink key="view" href={"/accounts/"+item.id} size="small"><i className="fa fa-search"></i>View</AInfoLink>
                </ButtonGroup>
            )} />
    }

    render = () => {
        const { classes } = this.props;
        return (
        <div>

            {this.renderTableActions()}
            {this.renderTable()}
            
        </div>
        );
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(DuplicatesList);