import _random from 'lodash/random';

import { 
	GENERATE_REPORT,
	GET_REPORT_TEMPLATES,
	GET_REPORT_TEMPLATE,
	ADD_REPORT_TEMPLATE,
	UPDATE_REPORT_TEMPLATE,
	DELETE_REPORT_TEMPLATE,
	GET_REPORT_CATEGORIES,
	ADD_REPORT_CATEGORY,
	UPDATE_REPORT_CATEGORY,
    DELETE_REPORT_CATEGORY,
    RESET_REPORT
} from '../actions/types';

const initialState = {
    templates: null,
    template: null,
    categories: null,
    categories_updated: false,
    updated: false,
    rand: false
};

export const reports = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_REPORT_TEMPLATES:
            return {
                ...state,
                templates: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case GET_REPORT_TEMPLATE:
            return {
                ...state,
                template: ( payload.template || {} ),
                rand: _random(1,9999)
            };
        case ADD_REPORT_TEMPLATE:
        case UPDATE_REPORT_TEMPLATE:
        case DELETE_REPORT_TEMPLATE:
        case GENERATE_REPORT:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case GET_REPORT_CATEGORIES:
            return {
                ...state,
                categories: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case ADD_REPORT_CATEGORY:
        case UPDATE_REPORT_CATEGORY:
        case DELETE_REPORT_CATEGORY:
            var randNum = _random(1,9999);
            return {
                ...state,
                categories_updated: randNum,
                rand: randNum
            };
        case RESET_REPORT:
            return {
                ...state,
                templates: null,
                template: null,
                updated: false,
                rand: false
            };
        default:
            return state;
    }
}