/* eslint-disable */
import _round from "lodash/round";
import _isEmpty from "lodash/isEmpty";
import _isInteger from "lodash/isInteger";
import _startsWith from "lodash/startsWith";
import _replace from "lodash/replace";
import _size from "lodash/size";
import _split from "lodash/split";

import { replaceAll } from "./data";

const getTotalZero = function (times) {
    var number = 1;
    for (var index = 1; index < times; index++) {
        number = number * 10;
    } // end - for
    return number;
};

// Turn number into money format
export const formatMoney = function (number, n, x) {
    var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
    return number.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
};

// turn number into percentage
export const getPercentage = function (top, bottom) {
    return _round((top / bottom) * 100);
};

// turn string to integer
export const stringToInteger = function (string) {
    var number = 0,
        negative = false,
        re = /^([0-9]+)$/;

    // check if string provided is number or string
    if (_isInteger(string)) {
        // if is number - just return it
        number = string;
    } else {
        // if is string - convert it to number
        if (string && !_isEmpty(string) && _startsWith(string, "-")) {
            negative = true;
            string = _replace(string, "-", "");
        } // end - string

        if (string && !_isEmpty(string) && re.test(string)) {
            var totalSize = _size(string);
            for (var index = 0; index < totalSize; index++) {
                var num = parseInt(string[index], 10),
                    totalZero = totalSize - index;

                number += num * getTotalZero(totalZero);
            } // end - for
        } // end - string
    } // end - _isInteger( string )

    return negative ? 0 - number : number;
};

export const stringToCurrency = function (string) {
    var number = 0,
        float_num = 0,
        negative = false,
        re = /^([0-9]+)$/;

    // check if string provided is number or string
    if (_isInteger(string)) {
        // if is number - just return it
        number = string;
    } else {
        // if is string - convert it to number
        if (string && !_isEmpty(string) && _startsWith(string, "-")) {
            negative = true;
            string = _replace(string, "-", "");
        } // end - string

        var splitString = [],
            totalSize = 0;

        // replace all comma
        string = replaceAll(string, ",", "");

        // if contains float, split it up
        if (string.indexOf(".") >= 0) {
            splitString = _split(string, ".", 2);
        } else {
            splitString.push(string);
        } // end - string

        // get number
        if (
            splitString &&
            splitString[0] &&
            !_isEmpty(splitString[0]) &&
            re.test(splitString[0])
        ) {
            totalSize = _size(splitString[0]);
            for (var index = 0; index < totalSize; index++) {
                var num = parseInt(splitString[0][index], 10),
                    totalZero = totalSize - index;

                number += num * getTotalZero(totalZero);
            } // end - for
        } // end - splitString[0]

        // get float number
        if (
            splitString &&
            splitString[1] &&
            !_isEmpty(splitString[1]) &&
            re.test(splitString[1])
        ) {
            var float_num_string = splitString[1];
            totalSize = _size(float_num_string);
            // add zero if total size is less than 2 decimal point
            if (totalSize < 2) {
                float_num_string = float_num_string + "0";
                totalSize++;
            } // end - totalSize

            for (var index = 0; index < totalSize; index++) {
                var num = parseInt(float_num_string[index], 10),
                    totalZero = totalSize - index;

                float_num += num * getTotalZero(totalZero);
            } // end - for

            // get divided
            if (totalSize > 2) {
                var divided = 1;
                for (var index = 0; index < totalSize; index++) {
                    divided = divided * 10;
                } // end - for
            } else {
                var divided = 100;
            } // end - totalSize

            number += float_num / divided;
        } // end - splitString[1]
    } // end - _isInteger( string )

    return _round(negative ? 0 - number : number, 2);
};

export const convertToTwoDecimalPoints = (num) => {
    // Convert the number to a string with a fixed number of decimal points (e.g., 10)
    let numStr = num.toFixed(10);

    // Find the position of the decimal point
    let decimalPointIndex = numStr.indexOf(".");

    // Slice the string to keep only two decimal points, if the number has decimals
    if (decimalPointIndex !== -1 && decimalPointIndex + 3 < numStr.length) {
        numStr = numStr.substring(0, decimalPointIndex + 3);
    }

    // Convert the sliced string back to a number
    return parseFloat(numStr);
};
