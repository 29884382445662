import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import List from './list';

import { getCourseGroup } from '../../actions/m_course_group';

class MaintenanceCourseGroup extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {

        // get course group
        this.props.dispatch(getCourseGroup());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { courseGroupList, randNum } = this.props;

        if ( courseGroupList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    renderContents() {
        const { courseGroupList } = this.props;
        return <List list={courseGroupList} />;
    }

    render() {
        const { courseGroupList, randNum } = this.props;
        return <AppWrapper 
                title="Course Group"
                subtitle="Maintenance"
                onLoad={( !( courseGroupList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        courseGroupList: state.maintenance && state.maintenance.course_group || null,
        randNum: state.maintenance && state.maintenance.rand || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(MaintenanceCourseGroup);