/* eslint-disable */
import * as firebase from 'firebase/app';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _snakeCase from 'lodash/snakeCase';
import _find from 'lodash/find';

// import { doesUserHaveAccess } from '../../helpers/auth';
import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists, validateEmail, isObjectExists } from '../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema } from '../../helpers/schemas';

import { accountSchema } from '../../schemas/account';

const FAKE_LIST = [
    { id: 'ACC3849', name: 'ABC', email: 'abc@gmail.com' },
    { id: 'ACC1234', name: 'ABC', email: 'abc@gmail.com' },
    { id: 'ACC174', name: 'ABC', email: 'abc@gmail.com' },
    { id: 'ACC384', name: 'DEF', email: 'def@gmail.com' },
    { id: 'ACC123', name: 'DEF', email: 'def@gmail.com' },
    { id: 'ACC1744', name: 'XYZ', email: 'xyz@gmail.com' },
    { id: 'ACC1546', name: 'XYZ', email: 'xyz@gmail.com' },
];

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

/* helper end */


export const fb_getDuplicateAccounts = () => {
    return new Promise((resolve,reject) => {

        var promises = [];

        // _forEach( selected, account => {
        //     if ( account.id && !_isEmpty( account.id ) )
        //         promises.push( doSingleAccountUpdate( account.id, formData ) );
        // });

        Promise.all( promises )
        .then( results => {
            // resolve({ list: FAKE_LIST, field: 'name', created_on: moment().utcOffset(8).valueOf() });
            resolve({ list: [], field: false, created_on: false });
        })
        .catch( error => {
            reject(error);
        });

    })
}

export const fb_findDuplicateAccounts = (field) => {
    return new Promise((resolve,reject) => {

        var promises = [];

        // _forEach( selected, account => {
        //     if ( account.id && !_isEmpty( account.id ) )
        //         promises.push( doSingleAccountUpdate( account.id, formData ) );
        // });

        Promise.all( promises )
        .then( results => {
            setTimeout(() => {
                resolve({ list: FAKE_LIST, field, created_on: moment().utcOffset(8).valueOf() });
            },1500)
        })
        .catch( error => {
            reject(error);
        });

    })
}