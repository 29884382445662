/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Box from '@material-ui/core/Box';
import grey from '@material-ui/core/colors/grey';
import _isEmpty from 'lodash/isEmpty';

import { FlexColumn } from '../../styles/division';
import { InfoButton, GreyLink } from '../../styles/button';

import theme from '../../theme';
import BreadCrumbs from '../BreadCrumbs';

import { appChangesReset } from '../../actions/misc';

const TopBarWrapper = styled.div`
    margin: 0px -30px;
    padding: 15px;
`;

class TopBar extends React.Component {

    handleBack = (event) => {
        const { back, history, changesNotSaved } = this.props;
        event.preventDefault();

        // if changes not saved - trigger warning        
        if ( changesNotSaved ) {
            var answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
            // reset status if answer is yes
            if ( answer ) {
                this.props.dispatch(appChangesReset());
                if ( back && !_isEmpty( back ) ) {
                    history.push(back);
                } else {
                    history.goBack();
                }
            }
        } else {
            if ( back && !_isEmpty( back ) ) {
                history.push(back);
            } else {
                history.goBack();
            }
        }
    }

    render() {
        const { back, breadcrumbs } = this.props;
        return (
        <TopBarWrapper>
            <Box display="flex" justifyContent="space-around" alignItems="center">
                <FlexColumn>
                    { back ? <GreyLink style={{ marginRight: "20px", fontSize: "2rem" }} onClick={this.handleBack}><i className="fa fa-long-arrow-left"></i></GreyLink> : '' }
                </FlexColumn>
                <FlexColumn style={{ textAlign: "right", paddingRight: "15px" }}>
                    { breadcrumbs ? <BreadCrumbs links={breadcrumbs} color={grey['500']} hoverColor={theme.palette.primary.main} /> : '' }
                </FlexColumn>
            </Box>
        </TopBarWrapper>
        )
    }

}

const mapStateToProps = state => {
    return {
        changesNotSaved: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(TopBar);