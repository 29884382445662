import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';

/* eslint-disable */

import ContactForm from './contact_form';

import Table from '../../components/Table';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormMultiSelect from '../../components/FormMultiSelect';
import ButtonActions from '../../components/ButtonActions';

import { InfoButton, GreyButton, ButtonGroup, InverseButton, ErrorButton } from '../../styles/button';
import { SuccessTag, AmberTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
// import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
// import { isAdmin, hasAccessRights } from '../../helpers/auth';

class ResultTable extends React.Component {
    
    state = {
        openViewModal: false,
        modalData: false,
        openRemoveModal: false,
        removeModal: false,
    }

    handleFormUpdate = (newValue,key) => {
        const { users } = this.props;
        const { modalData } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        newData[key] = newValue;
        this.setState({ modalData: newData });
    }

    handleRemove = () => {
        const { contacts, trainees, onUpdate } = this.props;
        const { openRemoveModal, removeModal } = this.state;
        const newContacts = ( contacts && isArrayExists( contacts ) ? cloneCollections( contacts ) : [] );

        if ( removeModal && removeModal.unique_id && !_isEmpty( removeModal.unique_id ) ) {
            let pulled = _remove( newContacts, { unique_id: removeModal.unique_id } );
        }

        if ( onUpdate )
            onUpdate(newContacts);

        // close modal
        this.setState({ openRemoveModal: false, removeModal: false });
    }

    handleEditContact = () => {
        const { contacts, trainees, onUpdate } = this.props;
        const { openViewModal, modalData } = this.state;
        const newContacts = ( contacts && isArrayExists( contacts ) ? cloneCollections( contacts ) : [] );
        const index = ( contacts && isArrayExists( contacts ) && modalData && modalData.unique_id && !_isEmpty( modalData.unique_id ) ? _findIndex( contacts, { unique_id: modalData.unique_id } ) : -1 );
        if ( index >= 0 ) {
            newContacts[index] = ( modalData ? cloneCollections( modalData ) : {} );
        }

        if ( onUpdate )
            onUpdate(newContacts);

        // close modal
        this.setState({ openViewModal: false, modalData: false });
    }

    renderCell = type => item => {
        switch( type ) {
            case 'email':
                return <WrapWord>{( item[type] || '' )}</WrapWord>
        }
    }

    renderEditForm = () => {
        const { contacts, trainees } = this.props;
        const { modalData } = this.state;
        return (
        <div>
            <ContactForm
                contact={( modalData || false )}
                trainee={( modalData && modalData.email && !_isEmpty( modalData.email ) ? _find( trainees, { trainee_email: modalData.email } ) : false )}
                onUpdate={(newValue) => this.setState({ modalData: newValue })} />
        </div>
        );
    }

    render() {
        const { authData, contacts } = this.props;
        const { openViewModal, modalData, openRemoveModal, removeModal } = this.state;
        return (
        <>

            <Table 
                items={( contacts || [] )}
                uniqueKey="unique_id"
                emptyCell="No contact(s) found"
                showCheckbox={false}
                cells={[
                    { id: 'email', label: 'Email', render: this.renderCell('email') },
                    { id: 'id', label: 'ID', render: (item) => ( item.id && !_isEmpty( item.id ) ? item.id : '[new]' ) },
                    { id: 'first_name', label: 'First Name', render: (item) => ( item.first_name || '' ) }, 
                    { id: 'last_name', label: 'Last Name', render: (item) => ( item.last_name || '' ) },
                    { id: 'mobile_phone', label: 'Mobile Phone', render: (item) => ( item.mobile_phone || '' ) },
                    { id: 'office_phone', label: 'Office Phone', render: (item) => ( item.office_phone || '' ) },
                    { id: 'other_phone', label: 'Other Phone', render: (item) => ( item.other_phone || '' ) },
                    { id: 'position', label: 'Position', render: (item) => ( item.position || '' ) },
                    { id: 'account_label', label: 'Account', render: (item) => ( item.account_label || '' ) },
                ]}
                actionStyles={{ width: "15%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <InfoButton key="edit" size="small" onClick={() => this.setState({ openViewModal: true, modalData: item })}><i className="fa fa-edit"></i>Edit</InfoButton>
                        <ErrorButton key="delete" size="small" onClick={() => this.setState({ openRemoveModal: true, removeModal: item })}><i className="fa fa-trash"></i>Remove</ErrorButton>
                    </ButtonGroup>
                )} />

            <ModalView 
                open={openViewModal}
                title="Edit Contact"
                maxWidth="lg"
                actionLabel="Update"
                disableBackdrop={true}
                onClose={() => this.setState({ openViewModal: false, modalData: false })}
                doAction={this.handleEditContact}
                contents={this.renderEditForm()} />

            <ModelDelete
                open={openRemoveModal}
                title={( removeModal && removeModal.last_name ? `Are you sure you want to remove this contact ( ${removeModal.last_name} )?` : false )}
                onClose={() => this.setState({ openRemoveModal: false, removeModal: false })}
                onDelete={this.handleRemove} />

        </>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(ResultTable);