/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// import Divider from '@material-ui/core/Divider';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';

// import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
// import FormMultiSelect from '../../components/FormMultiSelect';
// import FormDatePicker from '../../components/FormDatePicker';

// import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
// import { cloneCollections } from '../../helpers/data';
// import { getMomentTime } from '../../helpers/date';
// import { isSchemaRequired } from '../../helpers/schemas';

import { findDuplicatesAccount } from '../../actions/accounts';

import { InverseButton, ButtonGroup, InfoButton } from '../../styles/button';

import { accountSchema } from '../../schemas/account';

// import { YES_NO_OPTIONS } from '../../constants';

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

const FormWrapper = styled.div`
    display: inline-block;
    margin: 0;
    width: 100%;
    max-width: 450px;
    padding: 30px 20px 15px 20px;
    border: 2px solid #000;
`;

const ResultWrapper = styled(FormWrapper)`
    display: block;
    margin: 0 0 15px 0;
    max-width: 100%;
    padding: 10px 20px;
    background: #fff;
`;

class FindDuplicateForm extends React.Component {

    state = {
        field: ''
    };

    handleFindDuplicates = () => {
        const { field } = this.props;
        let error = false;

        if ( !( field && !_isEmpty( field ) ) )
            error = 'Please select a field to look for duplicates';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            // trigger find duplicate dispatch
            this.props.dispatch(findDuplicatesAccount(field));
        } // end - error

    }

    handleFieldChange = (newValue) => {
        const { onFieldChange } = this.props;
        if ( onFieldChange )
            onFieldChange( newValue );
    }

    handleReset = () => {
        const { onReset } = this.props;
        if ( onReset )
            onReset();
    }

    getFieldOptions = () => {
        let options = [{ value: '', label: 'Select a field'}];
        if ( accountSchema && isArrayExists( accountSchema ) ) {
            _forEach( accountSchema, schema => {
                if ( schema.duplicate ) {
                    options.push({ value: schema.id, label: ( schema.label || '' ) });
                } // end - sync
            });
        } // end - accountSchema
        return options;
    }

    renderResultBox = () => {
        const { created_on } = this.props;
        return (
        <ResultWrapper>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={7}>
                    <Typography variant="h5" style={{ fontStyle: 'italic' }}>List generated on {( created_on ? moment(created_on).utcOffset(8).format('YYYY-MM-DD HH:mm:ss') : '---' )}</Typography>
                </Grid>
                <Grid item xs={5}>
                    <ButtonGroup style={{ textAlign: 'right' }}>
                        <InverseButton style={{ padding: '10px 25px' }} onClick={this.handleFindDuplicates}><i className="fa fa-cogs"></i>Re-Generate</InverseButton>
                        <InfoButton style={{ padding: '10px 25px' }} onClick={this.handleReset}><i className="fa fa-cogs"></i>Reset</InfoButton>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </ResultWrapper>
        )
    }

    renderForm = () => {
        const { field } = this.props;
        return (
        <FormWrapper>
            <FormSelect label="Find duplicate accounts based on" value={( field || '' )} options={this.getFieldOptions()} onChange={this.handleFieldChange} />
            <div style={{ paddingTop: "10px", textAlign: 'right' }}>
                <InverseButton style={{ padding: '10px 25px' }} onClick={this.handleFindDuplicates}><i className="fa fa-cogs"></i>Generate</InverseButton>
            </div>
        </FormWrapper>
        );
    }

    render = () => {
        const { classes, list } = this.props;
        return (
        <div>
            { list && isArrayExists( list ) ? this.renderResultBox() : this.renderForm() }
        </div>
        );
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(FindDuplicateForm);