/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';

import ModalView from '../ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormSelect2 from '../../components/FormSelect2';
import FormMultiSelect from '../FormMultiSelect';
import SelectAccounts from '../SelectAccounts';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { reverseUsersValues } from '../../helpers/users';

import { duplicateContact } from '../../actions/contacts';

const initialState = {
    first_name: '',
    last_name: '',
    account_id: '',
    account_label: '',
    assigned_to: []
};

class DuplicateContact extends React.Component {

    state = {
        data: initialState,
        randNum: false
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { open } = this.props;
        if ( open && !prevProps.open ) {
            this.setState({ data: this.getInitialValues() }); // reset 
        }
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose ) {
            this.setState({ data: this.getInitialValues() }); // reset 
            onClose();
        } // end - onClose
    }

    handleFormUpdate = (newValue,key) => {
        const { users } = this.props;
        const { data } = this.state;
        var newData = ( data ? cloneCollections( data ) : {} );
        if ( key === 'assigned_to' ) {
            newData[key] = reverseUsersValues( newValue, users );
        } else {
            newData[key] = newValue;
        } // end - key
        this.setState({ data: newData });
    }

    handleAddNew = () => {
        const { history, contact } = this.props;
        const { data } = this.state;
        var error = false,
            formData = cloneCollections( data );

        formData.id = ( contact && contact.id && !_isEmpty( contact.id ) ? contact.id : false );

        if ( !( formData && formData.assigned_to && isArrayExists( formData.assigned_to ) ) ) {
            error = 'Please assign at least one user';
        } // end - formData.email

        if ( !( formData && formData.account_id && !_isEmpty( formData.account_id ) ) ) {
            error = 'Please select a valid account';
        } // end - formData.email

        if ( !( formData && formData.last_name && !_isEmpty( formData.last_name ) ) ) {
            error = 'Please enter a valid last name';
        } // end - formData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(duplicateContact(formData,history));
        } // end - error
    }

    getInitialValues = () => {
        const { authData, contact } = this.props;
        var data = cloneCollections( initialState );

        // set in current first name & last name
        if ( contact && contact.first_name && !_isEmpty( contact.first_name ) ) {
            data.first_name = contact.first_name;
        } // end - contact.first_name

        if ( contact && contact.last_name && !_isEmpty( contact.last_name ) ) {
            data.last_name = contact.last_name;
        } // end - contact.last_name

        // set account_id & account_label if available
        if ( contact && contact.account_id && !_isEmpty( contact.account_id ) ) {
            data.account_id = contact.account_id;
        } // end - contact.account_id

        if ( contact && contact.account_label && !_isEmpty( contact.account_label ) ) {
            data.account_label = contact.account_label;
        } // end - contact.account_label
        
        // set assigned_to based on current logged-in user
        if ( authData && authData.name && authData.email ) {
            data.assigned_to = [{ id: authData.email, name: authData.name }];
        }

        // overwrite assigned_to if there is a contact
        if ( contact && contact.assigned_to && isArrayExists( contact.assigned_to ) ) {
            data.assigned_to = contact.assigned_to;
        } // end - contact

        return data;
    }

    renderAddNewForm = () => {
        const { users, contact, disabled } = this.props;
        const { data } = this.state;
        return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormInput label="First Name" name="first_name" value={( data.first_name || '' )} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={6}>
                    <FormInput label="Last Name (Required)" name="last_name" value={( data.last_name || '' )} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={12}>
                    <SelectAccounts
                        label="Account (Required)" 
                        name="account_id"
                        disabled={( disabled ? true : false )}
                        value={( data && data.account_id ? data.account_id : '' )} 
                        onChange={(newAccounts,key) => {
                            const { data } = this.state;
                            var newData = ( data ? cloneCollections( data ) : {} );
                            newData[key] = ( newAccounts && newAccounts[0] && newAccounts[0].id ? newAccounts[0].id : '' ); // update account_id
                            newData['account_label'] =  ( newAccounts && newAccounts[0] && newAccounts[0].name ? newAccounts[0].name : '' ); // update account_label

                            // update assigned_to
                            if ( newAccounts && newAccounts[0] && newAccounts[0].assigned_to && isObjectExists( newAccounts[0].assigned_to ) ) {
                                newData['assigned_to'] = [];
                                _forEach( newAccounts[0].assigned_to, user => {
                                    newData['assigned_to'].push( user );
                                });
                            } // end - newAccounts[0].assigned_to
                            
                            this.setState({ data: newData });
                        }} />
                    <div style={{ paddingTop: "10px" }}>
                        <FormMultiSelect 
                            label="Assigned To (Required)" 
                            name="assigned_to" 
                            disabled={true}
                            value={( data && data.assigned_to && isArrayExists( data.assigned_to ) ? getSelectValues( data.assigned_to, 'id' ) : [] )} 
                            options={getSelectOptions({ list: ( users || [] ), keys: { value: 'email', label: 'name' }, sortBy: 'label' })} 
                            onChange={this.handleFormUpdate} />
                    </div>
                </Grid>
            </Grid>
        </div>
        );
    }

    render() {
        const { open } = this.props;
        return (
        <div>

            <ModalView 
                open={open}
                title="Duplicate Contact"
                actionLabel="Confirm"
                onClose={this.handleClose}
                doAction={this.handleAddNew}
                contents={this.renderAddNewForm()} />

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(DuplicateContact);