/* eslint-disable */

import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _merge from 'lodash/merge';
import _size from 'lodash/size';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';

import AppWrapper from '../../components/AppWrapper';
import List from './list';

import { cloneCollections } from '../../helpers/data';

import { getTestQueries } from '../../actions/test_queries';
import { getUsersOptions } from '../../actions/users';
import { resetRedux } from '../../actions/misc';

import { FIRESTORE_DEFAULT_PERPAGE } from '../../constants';

class TestQueriesPage extends React.Component {

    state = {
        query: {
            searchterms: '',
            filterBy: 'all',
            sortBy: 'modified_on-desc',
            perPage: FIRESTORE_DEFAULT_PERPAGE,
            page: 1
        },
        randNum: false
    };

    componentDidMount() {

        // get list
        this.props.dispatch(getTestQueries());

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { QueriesList, randNum } = this.props;

        // once all the data is loaded
        if ( QueriesList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('test_queries'));
    }

    handleRefresh = () => {
        const { query } = this.state;
        this.setState({ randNum: false });
        this.props.dispatch(resetRedux('test_queries'));
        this.props.dispatch(getTestQueries(query));
    }

    handleQueryChange = (props) => {
        const { query } = this.state
        var newQuery = this.setNewQuery(props);
        if ( this.ifOnlySortChanged(newQuery) && newQuery.searchterms && !_isEmpty( newQuery.searchterms ) ) {
            // for search sort - no need to trigger reload
            this.setState({ query: newQuery });
        } else {
            this.setState({ query: newQuery, randNum: false });
            this.props.dispatch(resetRedux('test_queries'));
            this.props.dispatch(getTestQueries(newQuery));
        } // end - newQuery
    }

    ifOnlySortChanged = (newQuery) => {
        const { query } = this.state;
        var changed = false;
        if ( !_isEqual( query.sortBy, newQuery.sortBy ) ) {
            if ( !_isEqual( query.searchterms, newQuery.searchterms ) || 
                !_isEqual( query.filterBy, newQuery.filterBy ) || 
                !_isEqual( query.perPage, newQuery.perPage ) || 
                !_isEqual( query.page, newQuery.page ) )
                changed = true;
        } else {
            changed = true;
        }
        return ( changed ? false : true );
    }

    setNewQuery = (query) => {
        const { QueriesFirstDoc, QueriesLastDoc } = this.props;
        var currentQuery = this.getCurrentQuery(),
            newQuery = _merge( this.getCurrentQuery(), query);
        newQuery.endBefore = ( newQuery.page >= 2 && newQuery.page < currentQuery.page && QueriesFirstDoc ? QueriesFirstDoc : false );
        newQuery.startAfter = ( newQuery.page >= 2 && newQuery.page > currentQuery.page && QueriesLastDoc ? QueriesLastDoc : false );
        return newQuery;
    }

    getCurrentQuery = () => {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state.query;
        return cloneCollections({ searchterms, sortBy, filterBy, perPage, page });
    }

    renderContents() {
        const { authData, QueriesList, QueriesTotal } = this.props;
        const { query } = this.state;
        return <List 
                authData={authData} 
                invoices={QueriesList}
                total={QueriesTotal}
                currentTotal={( QueriesList ? _size( QueriesList ) : 0 )}
                searchterms={( query.searchterms || '' )}
                sortBy={( query.sortBy || 'modified_on-desc' )}
                filterBy={( query.filterBy || 'all' )}
                perPage={( query.perPage || 5 )}
                page={( query.page || 1 )}
                onRefresh={this.handleRefresh}
                onQueryChange={this.handleQueryChange} />;
    }

    render() {
        const { randNum } = this.state;
        const { QueriesList } = this.props;
        return <AppWrapper 
                title="Test Page"
                onLoad={( !( QueriesList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        QueriesList: state.test_queries && state.test_queries.list || null,
        QueriesTotal: state.test_queries && state.test_queries.total || null,
        QueriesFirstDoc: state.test_queries && state.test_queries.firstDoc || null,
        QueriesLastDoc: state.test_queries && state.test_queries.lastDoc || null,
        randNum: state.test_queries && state.test_queries.rand || null,
        usersOptions: state.users && state.users.users_options || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(TestQueriesPage);