/* eslint-disable */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FullScreenFlex, FullScreenComponent } from '../../styles/division';

export default class ScreenLoader extends React.Component {

    render() {
        return (
        <FullScreenComponent style={{ background: 'url("/background.png")', backgroundPosition: "center center", backgroundSize: "cover" }}>
            <FullScreenFlex justify="center" items="center" style={{ background: "rgba(#f1f1f1,0.75)" }}>
                <div style={{ textAlign: 'center', padding: "60px 90px", background: "#f1f1f1", borderRadius: "12px", boxShadow: "0 0 15px 0 rgba(0,0,0,0.25)" }}>
                    <div style={{ marginBottom: "30px" }}><img src="/logo.png" /></div>
                    <CircularProgress size={80} thickness={6} color="primary" />
                    <div style={{ marginTop: "30px" }}>LOADING...</div>
                </div>
            </FullScreenFlex>
        </FullScreenComponent>
        )
    }

}