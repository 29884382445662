/* eslint-disable */
import _random from 'lodash/random';

import {
    M_GET_ACCOUNTS_TIER_2,
    M_ACCOUNTS_TIER_2_UPDATED
} from '../types';

import { 
	toggleModalDeleting, toggleModalProcessing, 
	toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
// import { appChangesReset } from '../misc';

import { fb_getAccountTier2, fb_addAccountTier2, fb_updateAccountTier2, fb_deleteAccountTier2 } from './fb';

export const getAccountTier2 = () => {
	return dispatch => {

		fb_getAccountTier2(list => {
			dispatch({ 
                type: M_GET_ACCOUNTS_TIER_2, 
                payload: { list } 
            });
		});

	}
}

export const addNewAccountTier2 = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addAccountTier2(formData)
		.then( results => {
			dispatch({ type: M_ACCOUNTS_TIER_2_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Account tier 2 Added");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new Account tier 2' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const updateAccountTier2 = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_updateAccountTier2(formData)
		.then( results => {
			dispatch({ type: M_ACCOUNTS_TIER_2_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Account tier 2 Updated");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update Account tier 2' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteAccountTier2 = (id) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteAccountTier2(id)
		.then( results => {
			dispatch({ type: M_ACCOUNTS_TIER_2_UPDATED });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Account tier 2 Deleted");
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete Account tier 2' ) );
			triggerErrorAlert(errMsg);
		});

	}
}