import React from "react";
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _sortBy from "lodash/sortBy";
import _remove from "lodash/remove";

/* eslint-disable */

import AlternativeEmails from "./alternative_emails";

import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormSelect2 from "../../components/FormSelect2";
import FormMultiSelect from "../../components/FormMultiSelect";
import FormDatePicker from "../../components/FormDatePicker";
import FormRadio from "../../components/FormRadio";
import FormCheckMailingLists from "../../components/FormCheckMailingLists";

import SelectAccounts from "../../components/SelectAccounts";

// import { InfoButton, GreyButton } from '../../styles/button';
// import { WrapWord } from '../../styles/misc';
import { FormBox } from "../../styles/form";

import { isAdmin, hasAccessRights } from "../../helpers/auth";
import { isArrayExists, isObjectExists } from "../../helpers/validation";
import { cloneCollections, getSelectOptions, getSelectValues } from "../../helpers/data";
import { reverseUsersValues } from "../../helpers/users";
import { getMomentTime } from "../../helpers/date";
import { isSchemaRequired } from "../../helpers/schemas";

import { contactSchema } from "../../schemas/contact";
import { COUNTRIES_LIST } from "../../data/const_countries";
import { STATE_OPTIONS } from "../../data/const_states";

import { YES_NO_OPTIONS, YES_NO_OPTIONS_WITH_BLANK, YES_OPTIONS_WITH_BLANK, DEV_MODE } from "../../constants";

const useStyles = (theme) => ({
    boxheading: {
        fontSize: "20px",
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd",
    },
});

class EditForm extends React.Component {
    state = {
        randNum: false,
    };

    handleFormUpdate = (newValue, key) => {
        const { onFormUpdate, contact, users, selectables, lead_source } = this.props;
        let newData = contact && !_isEmpty(contact) ? cloneCollections(contact) : {};

        switch (key) {
            case "assigned_to":
                newData[key] = reverseUsersValues(newValue, users);
                break;
            case "designation":
                let designation = selectables && isArrayExists(selectables) ? _find(selectables, { id: "designation" }) : false,
                    selected =
                        designation && designation.options && isArrayExists(designation.options)
                            ? _find(designation.options, { label: newValue })
                            : false;
                newData[key] = newValue;
                newData["designation_id"] = (selected && selected.value) || ""; // add to designation_id for reference purpose
                break;
            case "lead_source_id":
                let selected_lead = lead_source && isArrayExists(lead_source) ? _find(lead_source, { id: newValue }) : false;
                newData[key] = newValue;
                newData["lead_source"] = (selected_lead && selected_lead.label) || ""; // add to label
                break;
            case "email_optout":
                newData[key] = newValue;
                // if value changed to yes, remove all mailing_lists
                if (newValue && newValue === "yes") {
                    newData["mailing_lists"] = [];
                }
                break;
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if (onFormUpdate) onFormUpdate(newData);
    };

    getFieldOptions = (field) => {
        const { users, contact, selectables, lead_source, massEditMode } = this.props;
        switch (field.name) {
            case "status":
                return [
                    { value: "active", label: "Active" },
                    { value: "disabled", label: "Disabled" },
                ];
            case "designation":
                let designation = selectables && isArrayExists(selectables) ? _find(selectables, { id: "designation" }) : false,
                    designation_options = [{ value: "", label: "None" }];
                if (designation && designation.options && isArrayExists(designation.options)) {
                    _forEach(_sortBy(designation.options, ["position"]), (option) => {
                        designation_options.push({ value: option.label, label: option.label });
                    });
                } // end - designation
                return designation_options;
            case "do_not_call":
            case "email_bounced":
                return massEditMode ? YES_NO_OPTIONS_WITH_BLANK : YES_NO_OPTIONS;
            case "email_optout":
                return massEditMode ? YES_OPTIONS_WITH_BLANK : YES_NO_OPTIONS;
            case "lead_source_id":
                return getSelectOptions({
                    list: lead_source || false,
                    options: [],
                    keys: { value: "id", label: "label", disabled: "status" },
                    sortBy: "label",
                });
            case "billing_state":
                let selected =
                        contact && contact.billing_country && !_isEmpty(contact.billing_country)
                            ? _find(COUNTRIES_LIST, { value: contact.billing_country })
                            : false,
                    country = selected && selected.label && !_isEmpty(selected.label) ? _find(STATE_OPTIONS, { country: selected.label }) : false,
                    options = [{ value: "", label: "Select an Option" }];
                if (country && country.states && isArrayExists(country.states)) {
                    country.states.forEach((state) => {
                        options.push({ value: state, label: state });
                    });
                } else {
                    options.push({ value: "N/A", label: "N/A" });
                } // end - country.states
                return options;
            case "billing_country":
                return getSelectOptions({
                    list: COUNTRIES_LIST,
                    options: [{ value: "", label: "Select an Option" }],
                    keys: { value: "value", label: "label" },
                });
            case "assigned_to":
                return getSelectOptions({ list: users || [], keys: { value: "email", label: "name" }, sortBy: "label" });
            case "age_group":
                return [
                    { value: "", label: "Select an Option" },
                    { value: "1960s or before", label: "1960s or before" },
                    { value: "1970s", label: "1970s" },
                    { value: "1980s", label: "1980s" },
                    { value: "1990s", label: "1990s" },
                    { value: "2000s", label: "2000s" },
                    { value: "2010s", label: "2010s" },
                    { value: "2020s", label: "2020s" },
                ];
            default:
                return [];
        }
    };

    getFieldValue = (field) => {
        const { contact } = this.props;
        switch (field.name) {
            case "assigned_to":
                return contact && contact[field.name] && isArrayExists(contact[field.name]) ? getSelectValues(contact[field.name], "id") : [];
            default:
                return (contact && contact[field.name]) || field.default || "";
        }
    };

    isFieldDisabled = (schema) => {
        const { authData, massEditMode } = this.props;
        var disabled = false;

        if (schema && schema.disabled && isArrayExists(schema.disabled)) {
            schema.disabled.forEach((condition) => {
                switch (condition) {
                    case "admin":
                        if (!isAdmin(authData)) disabled = true;
                        break;
                    case "update":
                        if ((isAdmin(authData) || hasAccessRights(authData, ["cte"])) && schema.id === "account_id") {
                            // do nothing
                        } else {
                            disabled = true;
                        }
                        break;
                }
            });
        } // end - schema

        return disabled;
    };

    checkForSpecificCondition = (schema) => {
        const { contact, isContactPage, currentContact } = this.props;
        let disabled = false;
        switch (schema.id) {
            case "email_optout":
                // only for contact page
                if (isContactPage) {
                    if (contact && contact.email_optout && contact.email_optout === "yes") {
                        disabled = true;
                    }
                    // if the original email_optout is no, then can be editable
                    if (currentContact && currentContact.email_optout && currentContact.email_optout === "no") {
                        disabled = false;
                    }
                } // end - isContactPage
                break;
        }
        return disabled;
    };

    getField = (id) => {
        const { massEditMode } = this.props;
        let schema = _find(contactSchema, { id });
        return schema
            ? {
                  name: schema.id || "",
                  label: (schema.label || "") + (isSchemaRequired(schema, "update") && !massEditMode ? " (Required)" : ""),
                  field_type: schema.field || "",
                  default: massEditMode ? null : schema.default || null,
                  disabled: schema.disabled ? this.isFieldDisabled(schema) : this.checkForSpecificCondition(schema),
              }
            : null;
    };

    renderMailingListField = () => {
        const { contact, isContactPage, massEditMode } = this.props;
        return (
            <FormCheckMailingLists
                disabled={
                    (isContactPage || massEditMode) &&
                    ((contact && contact.email_optout && contact.email_optout === "yes") ||
                        (contact && contact.email_optout && contact.email_bounced === "yes"))
                        ? true
                        : false
                }
                value={contact && contact.mailing_lists ? contact.mailing_lists : []}
                name="mailing_lists"
                onChange={this.handleFormUpdate}
            />
        );
    };

    renderField = (id) => {
        let field = this.getField(id);
        if (field && field.field_type && !_isEmpty(field.field_type)) {
            switch (field.field_type) {
                case "text":
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "textarea":
                    return (
                        <FormInput
                            {...field}
                            rows={"notes" === id ? 6 : 3}
                            multiline={true}
                            value={this.getFieldValue(field)}
                            onChange={this.handleFormUpdate}
                        />
                    );
                case "text_number":
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "email":
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "select2":
                    if (id === "account_id") {
                        return (
                            <SelectAccounts
                                {...field}
                                value={this.getFieldValue(field)}
                                onChange={(newAccounts, key) => {
                                    const { onFormUpdate, contact } = this.props;
                                    let newData = contact && !_isEmpty(contact) ? cloneCollections(contact) : {};
                                    newData[key] = newAccounts && newAccounts[0] && newAccounts[0].id ? newAccounts[0].id : ""; // update account_id
                                    newData["account_label"] = newAccounts && newAccounts[0] && newAccounts[0].name ? newAccounts[0].name : ""; // update account_label

                                    // update assigned_to
                                    if (newAccounts && newAccounts[0] && newAccounts[0].assigned_to && isObjectExists(newAccounts[0].assigned_to)) {
                                        newData["assigned_to"] = [];
                                        _forEach(newAccounts[0].assigned_to, (user) => {
                                            newData["assigned_to"].push(user);
                                        });
                                    } // end - newAccounts[0].assigned_to

                                    var answer = window.confirm(
                                        "Are you sure you want to move the contact's account from '" +
                                            (contact["account_label"] || "") +
                                            "' to '" +
                                            (newData["account_label"] || "") +
                                            "'?"
                                    );
                                    if (answer) {
                                        // do update
                                        if (onFormUpdate) onFormUpdate(newData);
                                    } // end - answer
                                }}
                            />
                        );
                    } else {
                        return (
                            <FormSelect2
                                placeholder="Select an Option"
                                {...field}
                                value={this.getFieldValue(field)}
                                options={this.getFieldOptions(field)}
                                onChange={this.handleFormUpdate}
                            />
                        );
                    }
                case "select":
                    return (
                        <div style={{ paddingTop: "7px" }}>
                            <FormSelect
                                {...field}
                                value={this.getFieldValue(field)}
                                options={this.getFieldOptions(field)}
                                disableNative={id === "lead_source_id" ? true : false}
                                onChange={this.handleFormUpdate}
                            />
                        </div>
                    );
                case "radio":
                    return (
                        <div style={{ paddingTop: "7px" }}>
                            <FormRadio
                                inline={true}
                                {...field}
                                value={this.getFieldValue(field)}
                                options={this.getFieldOptions(field)}
                                onChange={this.handleFormUpdate}
                            />
                        </div>
                    );
                case "multiselect":
                    return (
                        <div style={{ paddingTop: "7px" }}>
                            <FormMultiSelect
                                {...field}
                                value={this.getFieldValue(field)}
                                options={this.getFieldOptions(field)}
                                onChange={this.handleFormUpdate}
                            />
                        </div>
                    );
                case "datepicker":
                    return <FormDatePicker {...field} noDefaultVal={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
            }
        } // end - field.field_type
    };

    renderSystemDate = () => {
        const { contact } = this.props;
        return (
            <div style={{ paddingTop: "10px", textAlign: "right", color: "#999", fontSize: "1.25rem" }}>
                <div>{contact.created_on ? "Created on " + getMomentTime(contact.created_on, "YYYY-MM-DD hh:mm:ssa") : ""}</div>
                <div>{contact.modified_on ? "Last Modified on " + getMomentTime(contact.modified_on, "YYYY-MM-DD hh:mm:ssa") : ""}</div>
            </div>
        );
    };

    renderEmailField = () => {
        const { contact } = this.props;
        let field = this.getField("email"),
            dcl_synced = false;
        if (contact && contact.dcl_sync_uid && !_isEmpty(contact.dcl_sync_uid)) {
            dcl_synced = true;
            field.disabled = true;
        }
        return (
            <>
                <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />
                {dcl_synced && (
                    <Typography
                        variant="caption"
                        style={{ color: "#999", fontSize: "1rem", marginTop: "5px", fontStyle: "italic", paddingLeft: "5px" }}
                    >
                        * Email cannot be changed as it is synced with DC Learn.
                    </Typography>
                )}
            </>
        );
    };

    render = () => {
        const { authData, classes, contact, disableSystemDate, massEditMode } = this.props;
        return (
            <FormBox>
                {massEditMode ? null : (
                    <Typography variant="h4" className={classes.boxheading}>
                        Contact Info
                    </Typography>
                )}

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {this.renderField("status")}
                    </Grid>
                </Grid>

                <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />

                <Grid container spacing={3}>
                    <Grid item xs={2}>
                        {this.renderField("designation")}
                    </Grid>
                    <Grid item xs={5}>
                        <div style={{ paddingTop: "7px" }}>{this.renderField("first_name")}</div>
                    </Grid>
                    <Grid item xs={5}>
                        <div style={{ paddingTop: "7px" }}>{this.renderField("last_name")}</div>
                    </Grid>

                    {massEditMode ? null : (
                        <Grid item xs={6}>
                            {this.renderField("assigned_to")}
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        {isAdmin(authData) || hasAccessRights(authData, ["cte"]) ? (
                            this.renderField("account_id")
                        ) : (
                            <FormInput
                                label="Account (required)"
                                value={(contact && contact.account_label) || ""}
                                disabled={true}
                                key="account_label"
                            />
                        )}
                    </Grid>
                    {massEditMode ? (
                        <Grid item xs={6}>
                            {" "}
                        </Grid>
                    ) : null}

                    <Grid item xs={12}>
                        {this.renderEmailField()}
                    </Grid>
                    {/* <Grid item xs={6}>
                        {this.renderField("email_personal")}
                    </Grid> */}

                    <Grid item xs={12}>
                        <AlternativeEmails
                            contact={contact}
                            onPersonalEmailUpdate={(newData) => {
                                this.handleFormUpdate(newData, "email_personal");
                            }}
                            onAlternativeEmailsUpdate={(newData) => {
                                this.handleFormUpdate(newData, "alternative_emails");
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        {this.renderField("office_phone")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("mobile_phone")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("other_phone")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("fax")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("position")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("department")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("age_group")}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderField("area_of_interest_string")}
                    </Grid>
                </Grid>

                <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {this.renderField("lead_source_id")}
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ paddingTop: "7px" }}>{this.renderField("last_called")}</div>
                    </Grid>

                    <Grid item xs={4}>
                        {this.renderField("do_not_call")}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderField("email_optout")}
                    </Grid>
                    <Grid item xs={4}>
                        {this.renderField("email_bounced")}
                    </Grid>

                    <Grid item xs={12}>
                        {this.renderMailingListField()}
                    </Grid>
                </Grid>

                <Divider style={{ marginTop: "30px", marginBottom: "30px" }} />

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {this.renderField("billing_address")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("billing_city")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("billing_post_code")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("billing_state")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("billing_country")}
                    </Grid>

                    <Grid item xs={12}>
                        {this.renderField("notes")}
                    </Grid>
                </Grid>

                {disableSystemDate ? null : this.renderSystemDate()}
            </FormBox>
        );
    };
}

export default compose(withStyles(useStyles), withRouter)(EditForm);
