/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';

import Table from '../Table';
import Pagination from '../Pagination';
import TableBar from '../TableBar';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton } from '../../styles/button';
// import { SuccessTag, AmberTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { hasLimitedAccess } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
// import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, combineStrings } from '../../helpers/data';
// import { getMomentTime, getMomentTimestamp } from '../../helpers/date';

import { appChangesReset } from '../../actions/misc';

class AccountsList extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        filterBy: 'all',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false
    }

    handlePageRefresh = (event) => {
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh('accounts');
    }

    handleAddNew = (event) => {
        const { changesMade } = this.props;
        event.preventDefault();
        if ( changesMade ) {
            alert("Please save changes first before proceed");
        } else {
            this.setState({ openViewModal: true });
        } // end - changesMade
    }

    handleView = (id,event) => {
        const { changesMade, history, dispatch } = this.props;
        event.preventDefault();
        let url = "/accounts/"+id;
        if ( changesMade ) {
            var answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
            // reset status if answer is yes
            if ( answer ) {
                dispatch(appChangesReset());
                history.push(url);
            }
        } else {
            history.push(url);
        } // end - changesMade
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { accounts } = this.props;
        let items = ( accounts ? cloneCollections( accounts ) : [] ),
            total = _size( items );

        // add in assigned to label
        if ( accounts && isArrayExists( accounts ) ) {
            _forEach( accounts, (account,index) => {

                // set label as empty
                items[index].assigned_to_label = '';

                // run through all the IDs to get labels
                if ( account && account.assigned_to && isArrayExists( account.assigned_to ) ) {
                    _forEach( account.assigned_to, user => {
                        items[index].assigned_to_label += ( !_isEmpty( items[index].assigned_to_label ) ? ' ' : '' ) + ( user && user.name ? user.name : user.id );  
                    });
                } // end - account.assigned_to
                
            });
        } // end - accounts

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','assigned_to_label','billing_city'] );
			total = _size( items );
        } // end - searchterms

        // do custom filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy != 'all' ) {
            // items = _filter( items, { org: filterBy });
            // total = _size( items );
        }

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, (i) => ( i.modified_on && i.modified_on._seconds ? i.modified_on._seconds : i.modified_on ) );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, (i) => ( i.modified_on && i.modified_on._seconds ? i.modified_on._seconds : i.modified_on ) );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderCell = type => item => {
        switch( type ) {
            case 'assigned_to':
                return (
                <div>{ item.assigned_to && isArrayExists( item.assigned_to ) ? item.assigned_to.map(user => ( <div key={user.id}>{ user && user.name ? user.name :  user.id }</div> )) : null }</div>
                );
            case 'email':
                return <WrapWord>{( item[type] || '' )}</WrapWord>
        }
    }

    renderRightActionButtons = () => {
        var rightButtons = [];

        rightButtons.push( <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton> );

        return rightButtons;
    }

    renderTableActions = () => {
        const { authData } = this.props;
        const { sortBy, perPage, searchterms } = this.state;
        return <TableBar
                limitedPerPage={( hasLimitedAccess(authData) ? true : false )}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Modified first' },
                    { value: 'date-asc', label: 'Oldest Modified first' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' }
                ]}
                rightButtons={this.renderRightActionButtons()}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    doneLoaded={true}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { noAddNew, authData, users, account } = this.props;
        const { openViewModal } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            {this.renderTableActions()}
            <Table 
                noSelect={( hasLimitedAccess(authData) ? true : false )}
                items={items}
                showCheckbox={false}
                cells={[
                    { id: 'name', label: 'Name', render: (item) => ( item.name || '' ) },
                    { id: 'assigned_to', label: 'Assigned To', render: this.renderCell('assigned_to') },
                    { id: 'billing_city', label: 'Billing City', render: (item) => ( item.billing_city || '' ) },
                    { id: 'general_line', label: 'General Line', render: (item) => ( item.general_line || '' ) },
                    { id: 'direct_line', label: 'Direct Line', render: (item) => ( item.direct_line || '' ) },
                    { id: 'email', label: 'Email', render: this.renderCell('email') },
                ]}
                actionStyles={{ width: "10%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <AInfoLink key="view" href={"/accounts/"+item.id} size="small" onClick={this.handleView.bind(this,item.id)}><i className="fa fa-search"></i>View</AInfoLink>
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(AccountsList);