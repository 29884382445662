import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import shortid from 'shortid';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import _isEmpty from 'lodash/isEmpty';
import _toLower from 'lodash/toLower';

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import ModalView from '../../components/ModalView';
import ButtonActions from '../../components/ButtonActions';
import SnackBarSave from '../../components/SnackBarSave';
import ItemNotFound from '../../components/ItemNotFound';
import AddNewEmail from '../../components/AddNewEmail';

import Details from './details';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';
import { newQuoteItem } from '../../helpers/quotes';

import { getQuote, editQuote, generateQuotePDF, convertQuoteToInvoice, duplicateQuote } from '../../actions/quotes';
import { getUsersOptions } from '../../actions/users';
import { getSelectables } from '../../actions/m_selectables';
import { getRegions } from '../../actions/m_regions';
import { appChangesMade, resetRedux } from '../../actions/misc';
import { getPaymentTerms } from '../../actions/m_payment_terms';

import { quoteSchema } from '../../schemas/quote';

import { FIREBASE_STORAGE_URL } from '../../constants';

class QuotePage extends React.Component {

    state = {
        quote: false,
        openViewModal: false,
        modalType: false,
        modalContent: false,
        openSendEmail: false,
        randNum: false
    };

    componentDidMount() {
        const { quote_id } = this.props.match.params;
        
        // retrieve quote details
        this.props.dispatch(getQuote(quote_id));

        // get regions
        if ( !this.props.regionsList )
            this.props.dispatch(getRegions());

        // get selectables
        if ( !this.props.selectablesList )
            this.props.dispatch(getSelectables());

        // get all users
        if ( !this.props.usersOptions )
            this.props.dispatch(getUsersOptions());

        // get payment terms
        if ( !this.props.paymentTermsList )
            this.props.dispatch(getPaymentTerms());
   
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentQuote, regionsList, selectablesList, paymentTermsList, usersOptions, randNum } = this.props;

        // for quote
        if ( currentQuote && regionsList && selectablesList && paymentTermsList && usersOptions && randNum && randNum !== this.state.randNum ) {
            this.setState({ quote: this.getQuoteData( currentQuote ), randNum });
        } // end - currentQuote
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('quote'));
    }

    getQuoteData = (currentQuote) => {
        var quote = cloneCollections( currentQuote );

        // add empty row for products if needed
        if ( !( quote.products_linked && isArrayExists( quote.products_linked ) ) ) {
            quote.products_linked = [];
            quote.products_linked.push( newQuoteItem(0) );
        } // end - quote

        return quote
    }

    handleSaveChanges = (event) => {
        const { quote } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do error check
        quoteSchema.forEach(schema => {
            formData[schema.id] = ( quote && quote[schema.id] ? cloneCollections( quote[schema.id] ) : schema.default );

            // insert issue_date if is empty
            if ( schema.id === 'issue_date' && !formData[schema.id]  ) {
                formData[schema.id] = moment().utcOffset(8).valueOf();
            } // end - schema.id

            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editQuote(formData));
        } // end - error

    }

    handleActionConfirmation = () => {
        const { quote, modalType } = this.state;
        switch( modalType ) {
            case 'generate-pdf':
                this.props.dispatch( generateQuotePDF(quote.id) );
                break;
            case 'generate-invoice':
                //this.props.dispatch( convertQuoteToInvoice(quote.id,this.props.history) );
                this.props.history.push( '/invoices/new?action=convert&id=' + quote.id );
                break;
            case 'duplicate-quote':
                //this.props.dispatch( duplicateQuote(quote.id,this.props.history) );
                this.props.history.push( '/quotes/new?action=duplicate&id=' + quote.id );
                break;
        } // end - type
        this.setState({ openViewModal: false, modalType: false, modalContent: false });
    }

    handleButtonActions = (type) => {
        const { quote } = this.state;
        const { changesMade } = this.props;
        var error = false,
            formData = {};
        // if changes made - trigger error
        if ( changesMade ) {
            alert("Please save changes first before proceed");
        } else {    
            switch( type ) {
                case 'generate-pdf':
                case 'generate-invoice':
                case 'duplicate-quote':
                    // do update error check
                    quoteSchema.forEach(schema => {
                        formData[schema.id] = ( quote && quote[schema.id] ? cloneCollections( quote[schema.id] ) : schema.default );
                        if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                            error = 'Please fill out "' + schema.label + '" field';
                        }
                    });

                    if ( error ) {
                        triggerErrorAlert(error);
                    } else {
                        var modalMessage = '';
                        if ( type == 'generate-pdf' ) {
                            modalMessage = "You're about to export this quote to PDF. Click CONFIRM to continue";
                        } else if ( type == 'generate-invoice' ) {
                            modalMessage = "You're about to generate an invoice based on this quote. Click CONFIRM to continue";
                        } else if ( type == 'duplicate-quote' ) {
                            modalMessage = "You're about to duplicate this quote. Click CONFIRM to continue";
                        }
                        this.setState({ openViewModal: true, modalType: type, modalContent: modalMessage });
                    } // end - error
                    break;
                case 'sendemail':
                    this.setState({ openSendEmail: true });
                    break;
            } // end - type
        } // end - changesMade

    }

    handleFormUpdate = (newValue) => {
        this.setState({ quote: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    getActions = () => {
        const { authData } = this.props;
        const { quote } = this.state;
        let actions = [];

        if ( quote && quote.pdf_id && !_isEmpty( quote.pdf_id ) ) {
            actions.push( { id: 'download-pdf', label: 'View PDF', icon: 'fa-download', onClick: () => {
                let link = FIREBASE_STORAGE_URL + 'quotes%2F' + quote.pdf_id + '_' + quote.id + '.pdf?alt=media';
                window.open(link, '_blank');
            }} );
        } // end - quote

        actions.push( { id: 'generate-pdf', label: 'Export Quote To PDF', icon: 'fa-file-pdf-o', onClick: this.handleButtonActions.bind(this,'generate-pdf') } );
        actions.push( { id: 'generate-invoice', label: 'Generate Invoice from Quote', icon: 'fa-cogs', onClick: this.handleButtonActions.bind(this,'generate-invoice') } );

        // disabled if quote wasn't in PDF format yet
        if ( quote && quote.pdf_id && !_isEmpty( quote.pdf_id ) ) {
            actions.push( { id: 'sendemail', label: 'Send email with Quote PDF', icon: 'fa-envelope', disabled: ( authData && hasAccessRights(authData, ['qts']) ? false : true ), onClick: this.handleButtonActions.bind(this,'sendemail') } );
        } // end - quote

        actions.push( { id: 'duplicate-quote', label: 'Duplicate Quote', icon: 'fa-clone', disabled: false, onClick: this.handleButtonActions.bind(this,'duplicate-quote') } );

        return actions;
    }

    renderActionsButton = () => {
        return (
        <div style={{ textAlign: 'right', paddingTop: '15px' }}>
            <ButtonActions 
                key="actions" 
                label="Actions"
                color="inverse"
                menuContainerStyle={{ width: "300px" }}
                style={{ padding: '15px 45px' }}
                actions={this.getActions()} />
        </div>
        );
    }

    renderdetails = () => {
        const { quote_id } = this.props.match.params;
        const { authData, usersOptions, regionsList, selectablesList, paymentTermsList, changesMade } = this.props;
        const { quote } = this.state;
        return <Details 
                authData={( authData || false )}
                quote={( quote || false )}
                regions={( regionsList || [] )}
                selectables={( selectablesList || [] )}
                payment_terms={( paymentTermsList || [] )}
                changesMade={( changesMade || false )}
                onFormUpdate={this.handleFormUpdate}
                updateActions={[getQuote(quote_id)]}
                users={( usersOptions || [] )} />;
    }

    renderContents() {
        const { authData, usersOptions } = this.props;
        const { quote, openViewModal, modalType, modalContent, openSendEmail } = this.state;
        return (
        <div>

            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                {this.renderdetails()}
            </Paper>

            <Grid container style={{ marginTop: "45px" }}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
                </Grid>
                {/* <Grid item xs={6} style={{ textAlign: 'right' }}>
                    {this.renderActionsButton()}
                </Grid> */}
            </Grid>

            <SnackBarSave onSave={this.handleSaveChanges} />

            <ModalView
                open={openViewModal}
                title={"Please Confirm Your Action"}
                actionLabel="Confirm"
                maxWidth="sm"
                onClose={() => this.setState({ openViewModal: false, modalType: false, modalContent: false })}
                doAction={this.handleActionConfirmation}
                contents={(<div>{ modalContent && !_isEmpty( modalContent ) ? <Typography variant="body1">{modalContent}</Typography> : '' }</div>)} />

            { authData && hasAccessRights(authData, ['qts']) ? <AddNewEmail
                authData={authData}
                open={openSendEmail}
                users={( usersOptions || [] )}
                predefined_accounts={( quote && quote.accounts_linked && isArrayExists( quote.accounts_linked ) ? quote.accounts_linked : false )}
                predefined_contacts={( quote && quote.contacts_linked && isArrayExists( quote.contacts_linked ) ? quote.contacts_linked : false )}
                predefined_quotes={( quote && quote.id && !_isEmpty( quote.id ) ? [ quote ] : false )}
                onClose={() => this.setState({ openSendEmail: false })} /> : null }

        </div>
        );
    }

    render() {
        const { randNum } = this.state;
        const { currentQuote } = this.props;
        return <AppWrapper 
                title="Edit Quote"
                subtitle="Quotes"
                back="/quotes"
                breadcrumbs={[
                    { url: '/quotes', label: 'Quotes' },
                    { label: 'Edit' }
                ]}
                customColumn={( randNum && currentQuote && currentQuote.id && !_isEmpty( currentQuote.id ) ? this.renderActionsButton() : null )}
                onLoad={( !( randNum ) ? true : false )}
                contents={ currentQuote && currentQuote.id && !_isEmpty( currentQuote.id ) ? this.renderContents() : <ItemNotFound item="Quote" />} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentQuote: state.quotes && state.quotes.quote || null,
        randNum: state.quotes && state.quotes.rand || null,
        usersOptions: state.users && state.users.users_options || null,
        selectablesList: state.maintenance && state.maintenance.selectables || null,
        regionsList: state.maintenance && state.maintenance.regions || null,
        paymentTermsList: state.maintenance && state.maintenance.payment_terms || null,
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(QuotePage);