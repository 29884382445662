import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _split from 'lodash/split';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _remove from 'lodash/remove';

/* eslint-disable */

import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormRadio from '../../components/FormRadio';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormCheckbox from '../../components/FormCheckbox';

import { InfoButton, ErrorButton, ButtonGroup, AInfoLink, SuccessButton, IndigoButton } from '../../styles/button';
import { WrapWord } from '../../styles/misc';

import { isArrayExists, isObjectExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from '../../helpers/data';
import { isAdmin, hasAccessRights } from '../../helpers/auth';

import { addNewCoursePortfolio, updateCoursePortfolio, deleteCoursePortfolio } from '../../actions/m_course_portfolio';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});

const ActiveTag = styled.div`
    display: inline-block;
    padding: 5px 8px;
    margin: 0px 5px 5px 0px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 6px;
    color: #fff;
    background: ${props => (props.active && props.active == 'yes' ? green['500'] : grey['500'])};
`;

class List extends React.Component {
    
    state = {
        filterBy: 'all',
        sortBy: 'name-asc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalType: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false,
        openRemoveModal: false,
        removeModal: false
    }

    handleDataSync = (event) => {
        event.preventDefault();
        // this.props.dispatch(syncDataWithLMS())
    }

    handleFormUpdate = (newValue,key,status, checkedVal) => {
        const { modalData } = this.state;
        var newData = ( modalData ? cloneCollections( modalData ) : {} );
        switch( key ) {
            case 'regions':
                if ( !( newData[key] && isArrayExists( newData[key] ) ) )
                    newData[key] = [];

                if ( status && status === 'checked' ) {
                    if ( !_find( newData[key], { id: checkedVal } ) )
                        newData[key].push({ id: checkedVal });
                } else {
                    var pulled = _remove( newData[key], { id: checkedVal } );
                }
                break;
            default:
                newData[key] = newValue;
                break;
        }
        this.setState({ modalData: newData });
    }

    handleAddNew = () => {
        const { modalData } = this.state;
        var error = false;

        if ( !( modalData && modalData.label && !_isEmpty( modalData.label ) ) ) {
            error = 'Please insert a valid label';
        } // end - modalData.label

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addNewCoursePortfolio(modalData));
        } // end - error
    }

    handleEdit = () => {
        const { modalData } = this.state;
        var error = false;

        if ( !( modalData && modalData.label && !_isEmpty( modalData.label ) ) ) {
            error = 'Please insert a valid label';
        } // end - modalData.label

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateCoursePortfolio(modalData));
        } // end - error
    }

    handleAction = () => {
        const { modalType } = this.state;
        if ( modalType && modalType == 'new' ) {
            this.handleAddNew();
        } else if ( modalType && modalType == 'edit' ) {
            this.handleEdit();
        } // end - modalType
    }

    handleDelete = () => {
        const { deleteModal } = this.state;
        //perform delete
        this.props.dispatch(deleteCoursePortfolio(deleteModal.id));
    }

    handleRemoveFromCompany = () => {
        const { removeModal } = this.state
        let newData = ( removeModal && removeModal.item && isObjectExists( removeModal.item ) ? { ...removeModal.item } : false );
        if ( newData ) {
            if ( newData && newData.regions && isArrayExists( newData.regions ) && removeModal.region_id && !_isEmpty( removeModal.region_id ) ) {
                if ( _find( newData.regions, { id: removeModal.region_id } ) ) {
                    let pulled = _remove( newData.regions, { id: removeModal.region_id } );
                }  
            }
            this.props.dispatch(updateCoursePortfolio(newData));
        } else {
            triggerErrorAlert("Unknown error. Please refresh the browser and try again");
        }

    }

    reorganizeData(region) {
        const { sortBy, perPage, page, filterBy } = this.state;
        const { list } = this.props;
        var items = ( list ? cloneCollections( list ) : [] ),
            total = _size( items );

        // do region filter
        if ( region && region.id && !_isEmpty( region.id ) ) {
            items = _filter( items, i => {
                return i.regions && isArrayExists( i.regions ) && _find( i.regions, { id: region.id } );
            } );
            total = _size( items );
        } else {
            // for uncategorized
            items = _filter( items, i => {
                return !( i.regions && isArrayExists( i.regions ) );
            } );
            total = _size( items );
        }

        // do filter
        // if ( filterBy && !_isEmpty( filterBy ) && filterBy != 'all' ) {
        //     items = _filter( items, i => {
        //         return i.regions && isArrayExists( i.regions ) && _find( i.regions, { id: filterBy } );
        //     } );
        //     total = _size( items );
        // }

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'position-desc':
                    items = _sortBy( items, [(i) => ( i.position ? parseInt( i.position, 10 ) : 99 )] );
                    items = _reverse( items );
                    break;
                case 'position-asc':
                    items = _sortBy( items, [(i) => ( i.position ? parseInt( i.position, 10 ) : 99 )] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['label'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['label'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        // items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderViewForm = () => {
        const { authData, regions } = this.props;
        const { modalData, modalType } = this.state;
        return (
        <div>
            {/* <div style={{ padding: '5px 10px' }}>
                <FormRadio label="Status" name="status" inline={true} value={( modalData.status || '' )} options={[
                    { value: 'active', label: 'Active' },
                    { value: 'disabled', label: 'Disabled' }
                ]} onChange={this.handleFormUpdate} />
            </div> */}
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormInput label="Label" name="label" value={( modalData.label || '' )} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={6}>
                    <FormInput label="Code" name="value" value={( modalData.value || '' )} onChange={this.handleFormUpdate} />
                </Grid>
            </Grid>
            {/* <div style={{ padding: '10px 5px' }}>
                <FormSelect 
                    label="Type" 
                    name="type" 
                    value={( modalData.type || '' )} 
                    options={[
                        { value: '', label: 'None' },
                        { value: 'public', label: 'Public' },
                        { value: 'internal', label: 'Internal' },
                    ]}
                    onChange={this.handleFormUpdate} />
            </div> */}
            <div style={{ padding: '25px 10px 10px 10px' }}>
                <h4 style={{ fontWeight: '700', marginBottom: '10px', textTransform: 'uppercase', color: 'rgba(0, 0, 0, 0.54)' }}>Companies</h4>
                { regions && isArrayExists( regions ) && (
                <Grid container spacing={0}>
                    {regions.map( region => (
                        <Grid key={region.id} item xs={12}>
                            <FormCheckbox
                                label={region.company_name}
                                name="regions"
                                value={( modalData.regions && isArrayExists( modalData.regions ) && _find( modalData.regions, { id: region.id } ) ) ? region.id : null }
                                checked={region.id}
                                unchecked={region.id}
                                onChange={this.handleFormUpdate}/>
                        </Grid>
                    ))}
                </Grid>
                ) }
            </div>
        </div>
        );
    }

    renderTableActions = () => {
        const { sortBy, perPage, searchterms, filterBy } = this.state;
        const { regions } = this.props;
        return <TableBar
                show={[]}
                filterBy={filterBy}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'name-asc', label: 'Company ( A - Z)' },
                    { value: 'name-desc', label: 'Company ( Z - A )' },
                    // { value: 'position-asc', label: 'Position (smallest number first)' },
                    // { value: 'position-desc', label: 'Position (biggest number first)' }
                ]}
                rightButtons={[
                    <InfoButton minWidth="128px" key="addnew" style={{ marginRight: "5px", padding: '10px 25px' }} onClick={() => this.setState({ openViewModal: true, 
                        modalData: { 
                            label: '', 
                            value: '', 
                            type: 'none', 
                            status: 'active',
                            regions: []
                        }, modalType: 'new' })}><i className="fa fa-plus-circle"></i>Add New</InfoButton>
                    // <SuccessButton minWidth="168px" key="sync" style={{ marginRight: "5px" }} onClick={this.handleDataSync}><i className="fa fa-refresh"></i>Sycn with LMS</SuccessButton>
                ]}
                filterByOptions={getSelectOptions({ list: regions, options: [{ value: 'all', label: 'All' }], keys: { value: 'id', label: 'company_name' }, sortBy: 'company_name' })}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    doneLoaded={true}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderBody = (items,region) => {
        const { classes, authData } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(item => {
                return (
                <TableRow key={item.id}>
                    <TableCell className={classes.bodycell} style={{ width: '25%' }}>{item.label || ''}</TableCell>
                    <TableCell className={classes.bodycell} style={{ width: '15%' }}>{item.value || ''}</TableCell>
                    {/* <TableCell className={classes.bodycell}>{item.type || ''}</TableCell> */}
                    {/* <TableCell className={classes.bodycell} style={{ width: '25%' }}>{( item.regions && isArrayExists( item.regions ) ? item.regions.map(region => {
                        let selected = _find( regions, { id: region.id } );
                        return selected ? (
                            <div key={selected.id}>{selected.company_name || ''}</div>
                        ) : null;
                    }) : '-' )}</TableCell> */}
                    {/* <TableCell className={classes.bodycell}><ActiveTag active={( item.status && item.status === 'active' ? 'yes' : 'no' )}>{item.status || ''}</ActiveTag></TableCell> */}
                    <TableCell className={classes.bodycell} style={{ textAlign: 'right' }}>
                        <ButtonGroup>
                            <InfoButton size="small" onClick={() => this.setState({ openViewModal: true, modalData: item, modalType: 'edit' })}><i className="fa fa-edit"></i>Edit</InfoButton>
                            { region && region.id && !_isEmpty( region.id ) ? <IndigoButton size="small" onClick={() => this.setState({
                                openRemoveModal: true,
                                removeModal: {
                                    item: item,
                                    region_id: region.id,
                                    region
                                }
                            })}>Remove Item From This Company</IndigoButton> : null }
                            { authData && hasAccessRights( authData, ['mtd'] ) ? <ErrorButton size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete Item Entirely</ErrorButton> : null }
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
                )
            }) : (
                <TableRow>
                    <TableCell className={classes.bodycell}>No item(s) found.</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes } = this.props;
        return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headcell}>Label</TableCell>
                <TableCell className={classes.headcell}>Code</TableCell>
                {/* <TableCell className={classes.headcell}>Type</TableCell>
                <TableCell className={classes.headcell}>Status</TableCell> */}
                <TableCell className={classes.headcell}>Actions</TableCell>
            </TableRow>
        </TableHead>
        )
    }

    renderCompaniesTable = () => {
        const { regions, classes } = this.props;
        const unassigned = this.reorganizeData();
        return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.headcell}>Company</TableCell>
                    <TableCell className={classes.headcell}>Business</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                { regions && isArrayExists( regions ) ? (
                <>
                    {regions.map(region => {
                        const { items, total } = this.reorganizeData(region);
                        return (
                        <TableRow key={region.id}>
                            <TableCell className={classes.bodycell}>{region.company_name || ''}</TableCell>
                            <TableCell className={classes.bodycell}>
                                <Table>
                                    {/* {this.renderHeader()} */}
                                    {this.renderBody(items,region)}
                                </Table>
                            </TableCell>
                        </TableRow>
                        );
                    })}
                    { unassigned.items && isArrayExists( unassigned.items ) && (
                    <TableRow>
                        <TableCell className={classes.bodycell}>{"<UNASSIGNED>"}</TableCell>
                        <TableCell className={classes.bodycell}>
                            <Table>
                                {/* {this.renderHeader()} */}
                                {this.renderBody(unassigned.items)}
                            </Table>
                        </TableCell>
                    </TableRow>
                    )}
                </>
                ) : (
                <TableRow>
                    <TableCell className={classes.bodycell}>No company found.</TableCell>
                </TableRow>
                )}
            </TableBody>
        </Table>
        )
    }

    render() {
        const { openDeleteModal, deleteModal, openViewModal, modalType, openRemoveModal, removeModal } = this.state;
        
        return (
        <div>

            <ModalView 
                open={openViewModal}
                title={ modalType && modalType == 'edit' ? "Edit Business" : "Add New Business" }
                actionLabel={ modalType && modalType == 'edit' ? "Update" : "Add New" }
                onClose={() => this.setState({ openViewModal: false, modalType: false, modalData: false })}
                doAction={this.handleAction}
                contents={this.renderViewForm()} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.label ? `Are you sure you want to delete this business ( ${deleteModal.label} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            <ModelDelete
                open={openRemoveModal}
                deleteLabel="Remove Item From This Company"
                processingLabel="Removing..."
                title={( removeModal && removeModal.item && removeModal.item.label ? `Are you sure you want to remove this business ( ${removeModal.item.label} ) from ${( removeModal && removeModal.region && removeModal.region.company_name ) || 'this company'}?` : false )}
                onClose={() => this.setState({ openRemoveModal: false, removeModal: false })}
                onDelete={this.handleRemoveFromCompany} />

            {this.renderTableActions()}
            <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                {this.renderCompaniesTable()}
            </Paper>
            {/* {this.renderPagination(total)} */}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(List);