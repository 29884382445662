import _random from 'lodash/random';

import { 
    GET_PRODUCTS,
    GET_PRODUCTS_BY,
    GET_PRODUCTS_OPTIONS,
	GET_PRODUCT,
	ADD_PRODUCT,
	EDIT_PRODUCT,
    DELETE_PRODUCT,
    RESET_PRODUCT,
    RESET_PRODUCTS,
    RESET_PRODUCTS_OPTIONS,
    RESET_GET_PRODUCTS_BY
} from '../actions/types';

const initialState = {
    products: null,
    total: null,
    firstDoc: null,
    lastDoc: null,
    products_loaded: false,
    products_by: null,
    products_options: null,
    product: null,
    new_product_id: null,
    updated: null,
    by_rand: false,
    rand: false
};

export const products = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_PRODUCTS:
            return {
                ...state,
                products: ( payload.list || [] ),
                total: ( payload.total || null ),
                firstDoc: ( payload.firstDoc || null ),
                lastDoc: ( payload.lastDoc || null ),
                products_loaded: true, // fix this to true - change it if needed
                rand: _random(1,9999)
            };
        case GET_PRODUCTS_BY:
            return {
                ...state,
                products_by: ( payload.list || [] ),
                by_rand: _random(1,9999)
            };
        case GET_PRODUCTS_OPTIONS:
            return {
                ...state,
                products_options: ( payload.list || [] ),
                options_rand: _random(1,9999)
            };
        case GET_PRODUCT:
            return {
                ...state,
                product: ( payload.product || {} ),
                rand: _random(1,9999)
            };
        case ADD_PRODUCT:
            return {
                ...state,
                new_product_id: ( payload.id || false ),
                rand: _random(1,9999)
            };
        case EDIT_PRODUCT:
        case DELETE_PRODUCT:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_PRODUCT:
            return {
                ...state,
                product: null,
                updated: null,
                rand: false
            };
        case RESET_PRODUCTS:
            return {
                ...state,
                products: null,
                total: null,
                firstDoc: null,
                lastDoc: null,
                products_loaded: false,
                rand: false
            };
        case RESET_PRODUCTS_OPTIONS:
            return {
                ...state,
                products_options: null,
                options_rand: false
            };
        case RESET_GET_PRODUCTS_BY:
            return {
                ...state,
                products_by: null,
                by_rand: false
            };    
        default:
            return state;
    }
}