export const accountSchema = [
    {
        id: "id",
        label: "ID",
        required: ["update", "delete"],
        default: "",
        type: "string",
        validation: "string_id",
        skip: ["add", "merge", "transfer_ownership"],
        report: true,
        sort: true,
        search_match: true,
        sync: false,
    },
    {
        id: "scrm_id",
        label: "Second CRM ID",
        required: false,
        default: "",
        type: "system_data",
        skip: ["add", "update", "merge", "transfer_ownership"],
        report: false,
        sync: true,
        disabled: ["update"],
    },
    {
        id: "lms_linked",
        label: "LMS ID",
        required: false,
        default: "",
        type: "system_data",
        field: "text",
        skip: ["add", "update", "transfer_ownership"],
        report: true,
        merge: true,
        sync: true,
        disabled: ["update"],
    },
    {
        id: "name",
        label: "Name",
        required: ["add", "update"],
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership"],
        report: true,
        sort: true,
        search_keywords: true,
        duplicate: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "account_tier_1_id",
        label: "Tier 1",
        required: false,
        default: "",
        type: "system_data",
        field: "select",
        skip: ["transfer_ownership"],
        report: false,
        merge: true,
        sync: true,
    },
    {
        id: "account_tier_1",
        label: "Tier 1",
        required: false,
        default: "",
        type: "string",
        field: "select",
        skip: ["transfer_ownership"],
        report: true,
        sort: true,
        merge: true,
        sync: true,
    },
    {
        id: "account_tier_2_id",
        label: "Tier 2",
        required: false,
        default: "",
        type: "system_data",
        field: "select",
        skip: ["transfer_ownership"],
        report: false,
        merge: true,
        sync: true,
    },
    {
        id: "account_tier_2",
        label: "Tier 2",
        required: false,
        default: "",
        type: "string",
        field: "select",
        skip: ["transfer_ownership"],
        report: true,
        sort: true,
        merge: true,
        sync: true,
    },
    {
        id: "company_reg_no",
        label: "Company Registration No",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership"],
        report: true,
        duplicate: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "direct_line",
        label: "Direct Line",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership"],
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "general_line",
        label: "General Line",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership"],
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "fax",
        label: "Fax",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership"],
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "email",
        label: "Email",
        required: false,
        default: "",
        type: "email",
        field: "email",
        skip: ["transfer_ownership"],
        report: true,
        search_match: true,
        search_keywords: true,
        duplicate: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "email_other",
        label: "Other Email",
        required: false,
        default: "",
        type: "email",
        field: "email",
        skip: ["transfer_ownership"],
        report: true,
        search_match: true,
        search_keywords: true,
        duplicate: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "website",
        label: "Website",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership"],
        report: true,
        duplicate: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "num_of_employee",
        label: "Number of Employee",
        required: false,
        default: 0,
        type: "number",
        field: "text_number",
        skip: ["transfer_ownership"],
        report: true,
        merge: true,
        sync: true,
    },
    {
        id: "num_of_engineer",
        label: "Number of Engineer",
        required: false,
        default: 0,
        type: "number",
        field: "text_number",
        skip: ["transfer_ownership"],
        report: true,
        merge: true,
        sync: true,
    },
    {
        id: "last_called",
        label: "Last Called",
        required: false,
        default: 0,
        type: "timestamp",
        field: "datepicker",
        skip: ["transfer_ownership"],
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "hrdf_contributer",
        label: "HRDF contributer",
        required: false,
        default: "",
        type: "string",
        field: "select",
        skip: ["transfer_ownership"],
        report: true,
        merge: true,
        sync: true,
    },
    {
        id: "mailing_lists",
        label: "Mailing Lists",
        required: false,
        default: [],
        type: "array_ids",
        field: "checkboxes",
        skip: ["transfer_ownership"],
        report: true,
        merge: true,
        sync: true,
    },

    {
        id: "billing_address",
        label: "Billing (address)",
        required: false,
        default: "",
        type: "string",
        field: "textarea",
        skip: ["transfer_ownership"],
        report: true,
        duplicate: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "billing_city",
        label: "Billing (city)",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership"],
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "billing_post_code",
        label: "Billing (Post Code)",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership"],
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "billing_state",
        label: "Billing (state)",
        required: false,
        default: "",
        type: "string",
        field: "select",
        skip: ["transfer_ownership"],
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "billing_country",
        label: "Billing (Country)",
        required: false,
        default: "",
        type: "string",
        field: "select",
        skip: ["transfer_ownership"],
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "tin_no",
        label: "Tax Identification Number (TIN)",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership"],
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "sst_no",
        label: "SST Number",
        required: false,
        default: "",
        type: "string",
        field: "text",
        skip: ["transfer_ownership"],
        report: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "einv_notification_email",
        label: "E-invoicing notification email",
        required: false,
        default: "",
        type: "email",
        field: "email",
        skip: ["transfer_ownership"],
        report: true,
        search_match: true,
        search_keywords: true,
        duplicate: true,
        merge: true,
        email_shortcode: true,
        sync: true,
    },
    {
        id: "msic_code",
        label: "MSIC code",
        required: false,
        default: "",
        type: "string",
        field: "textarea",
        skip: ["transfer_ownership"],
        report: true,
        merge: true,
        sync: true,
    },
    {
        id: "business_activity_description",
        label: " Business activity description",
        required: false,
        default: "",
        type: "string",
        field: "textarea",
        skip: ["transfer_ownership"],
        report: true,
        merge: true,
        sync: true,
    },
    {
        id: "notes",
        label: "Notes",
        required: false,
        default: "",
        type: "string",
        field: "textarea",
        skip: ["transfer_ownership"],
        report: true,
        merge: true,
        sync: true,
    },
    {
        id: "assigned_to",
        label: "Assigned To",
        required: ["add", "update"],
        default: [],
        type: "array_ids",
        field: "multiselect",
        report: true,
        sort: true,
        search_keywords: true,
        merge: true,
        sync: true,
        disabled: ["admin"],
    },
    {
        id: "created_on",
        label: "Created On",
        required: false,
        default: 0,
        type: "system_date",
        skip: ["update", "merge", "transfer_ownership"],
        report: true,
        sync: true,
    },
    {
        id: "modified_on",
        label: "Modified On",
        required: false,
        default: 0,
        type: "system_date",
        report: false,
        sync: true,
    },
];
