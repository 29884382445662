import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import List from './list';

import { getAccountTier2 } from '../../actions/m_accounts_tier_2';

class MaintenanceAccountsTier2 extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {

        // get list
        this.props.dispatch(getAccountTier2());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { accountsTier2List, randNum } = this.props;

        if ( accountsTier2List && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    renderContents() {
        const { accountsTier2List, authData } = this.props;
        return <List authData={authData} list={accountsTier2List} />;
    }

    render() {
        const { accountsTier2List, randNum } = this.props;
        return <AppWrapper 
                title="Accounts Tier 2"
                subtitle="Maintenance"
                onLoad={( !( accountsTier2List && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        accountsTier2List: state.maintenance && state.maintenance.accounts_tier_2 || null,
        randNum: state.maintenance && state.maintenance.rand || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(MaintenanceAccountsTier2);