import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import List from './list';

import { getPaymentTerms } from '../../actions/m_payment_terms';

class MaintenancePaymentTerms extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {

        // get course group
        this.props.dispatch(getPaymentTerms());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { paymentTermsList, randNum } = this.props;

        if ( paymentTermsList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    renderContents() {
        const { paymentTermsList, authData } = this.props;
        return <List authData={authData} list={paymentTermsList} />;
    }

    render() {
        const { paymentTermsList, randNum } = this.props;
        return <AppWrapper 
                title="Payment Terms"
                subtitle="Maintenance"
                onLoad={( !( paymentTermsList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        paymentTermsList: state.maintenance && state.maintenance.payment_terms || null,
        randNum: state.maintenance && state.maintenance.rand || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(MaintenancePaymentTerms);