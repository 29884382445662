/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';

import ModalView from '../ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormSelect2 from '../../components/FormSelect2';
import FormMultiSelect from '../FormMultiSelect';
import FormCheckMailingLists from '../../components/FormCheckMailingLists';
import SelectAccounts from '../SelectAccounts';

import { isArrayExists, isObjectExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { reverseUsersValues } from '../../helpers/users';

import { addNewContact } from '../../actions/contacts';

// import { DEV_MODE } from '../../constants';

const initialState = {
    first_name: '',
    last_name: '',
    email: '',
    account_id: '',
    account_label: '',
    mailing_lists: [],
    assigned_to: []
};

class AddNewContact extends React.Component {

    state = {
        data: initialState,
        randNum: false
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { open } = this.props;
        if ( open && !prevProps.open ) {
            this.setState({ data: this.getInitialValues() }); // reset 
        }
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose ) {
            this.setState({ data: this.getInitialValues() }); // reset 
            onClose();
        } // end - onClose
    }

    handleFormUpdate = (newValue,key) => {
        const { users } = this.props;
        const { data } = this.state;
        var newData = ( data ? cloneCollections( data ) : {} );
        if ( key === 'assigned_to' ) {
            newData[key] = reverseUsersValues( newValue, users );
        } else {
            newData[key] = newValue;
        } // end - key
        this.setState({ data: newData });
    }

    handleAddNew = () => {
        const { history, predefined_account } = this.props;
        const { data } = this.state;
        var error = false,
            formData = cloneCollections( data );

        // insert predefined_account if available
        if ( predefined_account && predefined_account.id && !_isEmpty( predefined_account.id ) ) {
            _forEach( predefined_account, account => {
                formData['account_id'] = predefined_account.id;
                formData['account_label'] = ( predefined_account.name || '' );
            });
        } // end - predefined_account

        if ( !( formData && formData.assigned_to && isArrayExists( formData.assigned_to ) ) ) {
            error = 'Please assign at least one user';
        } // end - formData.email

        if ( !( formData && formData.email && validateEmail( formData.email ) ) ) {
            error = 'Please enter a valid email address';
        } // end - formData.email

        if ( !( formData && formData.account_id && !_isEmpty( formData.account_id ) ) ) {
            error = 'Please select a valid account';
        } // end - formData.email

        if ( !( formData && formData.last_name && !_isEmpty( formData.last_name ) ) ) {
            error = 'Please enter a valid last name';
        } // end - formData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addNewContact(formData,history));
        } // end - error
    }

    getInitialValues = () => {
        const { authData, predefined_account } = this.props;
        var data = cloneCollections( initialState );
        
        // set assigned_to based on current logged-in user
        if ( authData && authData.name && authData.email ) {
            data.assigned_to = [{ id: authData.email, name: authData.name }];
        }

        // overwrite assigned_to if there is a predefined_account
        if ( predefined_account && predefined_account.assigned_to && isArrayExists( predefined_account.assigned_to ) ) {
            data.assigned_to = predefined_account.assigned_to;
        } // end - predefined_account

        // overwrite mailing_lists if there is a predefined_account
        if ( predefined_account && predefined_account.mailing_lists && isArrayExists( predefined_account.mailing_lists ) ) {
            data.mailing_lists = predefined_account.mailing_lists;
        } // end - predefined_account

        return data;
    }
    
    renderMailingListField = () => {
        const { data } = this.state;
        return <FormCheckMailingLists value={( data && data.mailing_lists ? data.mailing_lists : [] )} name="mailing_lists" onChange={this.handleFormUpdate} />
    }

    renderAddNewForm = () => {
        const { users, predefined_account } = this.props;
        const { data } = this.state;
        return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormInput label="First Name" name="first_name" value={( data.first_name || '' )} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={6}>
                    <FormInput label="Last Name (Required)" name="last_name" value={( data.last_name || '' )} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={12}>
                    <FormInput label="Email (Required)" name="email" value={( data.email || '' )} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={12}>
                    <SelectAccounts
                        label="Account (Required)" 
                        name="account_id"
                        disabled={( predefined_account && predefined_account.id && !_isEmpty( predefined_account.id ) ? true : false )}
                        value={( predefined_account && predefined_account.id && !_isEmpty( predefined_account.id ) ? predefined_account.id  : ( data && data.account_id ? data.account_id : '' ) )} 
                        onChange={(newAccounts,key) => {
                            const { mailing_lists } = this.props;
                            const { data } = this.state;
                            var newData = ( data ? cloneCollections( data ) : {} );
                            newData[key] = ( newAccounts && newAccounts[0] && newAccounts[0].id ? newAccounts[0].id : '' ); // update account_id
                            newData['account_label'] =  ( newAccounts && newAccounts[0] && newAccounts[0].name ? newAccounts[0].name : '' ); // update account_label

                            // update mailing lists based on tier_1
                            if ( newAccounts && newAccounts[0] && newAccounts[0].mailing_lists && !_isEmpty( newAccounts[0].mailing_lists ) ) {
                                newData['mailing_lists'] = [];
                                if ( newAccounts[0].mailing_lists && ( isArrayExists( newAccounts[0].mailing_lists ) || isObjectExists( newAccounts[0].mailing_lists ) ) ) {
                                    _forEach( newAccounts[0].mailing_lists, list => {
                                        if ( list && list.id && !_isEmpty( list.id ) ) {
                                            newData['mailing_lists'].push({
                                                id: list.id,
                                                name: list.name || ''
                                            });
                                        } // end - list.id
                                    });
                                } // end - newAccounts[0].mailing_lists
                            } // end - newAccounts[0].assigned_to

                            // update assigned_to
                            if ( newAccounts && newAccounts[0] && newAccounts[0].assigned_to && isObjectExists( newAccounts[0].assigned_to ) ) {
                                newData['assigned_to'] = [];
                                _forEach( newAccounts[0].assigned_to, user => {
                                    newData['assigned_to'].push( user );
                                });
                            } // end - newAccounts[0].assigned_to
                            
                            this.setState({ data: newData });
                        }} />
                </Grid>
                <Grid item xs={12}>{this.renderMailingListField()}</Grid>
                <Grid item xs={12}>
                    <FormMultiSelect 
                        label="Assigned To (Required)" 
                        name="assigned_to" 
                        disabled={true}
                        value={( data && data.assigned_to && isArrayExists( data.assigned_to ) ? getSelectValues( data.assigned_to, 'id' ) : [] )} 
                        options={getSelectOptions({ list: ( users || [] ), keys: { value: 'email', label: 'name' }, sortBy: 'label' })} 
                        onChange={this.handleFormUpdate} />
                </Grid>
            </Grid>
        </div>
        );
    }

    render() {
        const { open } = this.props;
        return (
        <div>

            <ModalView 
                open={open}
                title="Add New Contact"
                onClose={this.handleClose}
                doAction={this.handleAddNew}
                contents={this.renderAddNewForm()} />

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(AddNewContact);