import React from 'react';
import { compose } from "recompose";
import shortid from 'shortid';
import styled from "styled-components";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _size from 'lodash/size';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _forEach from 'lodash/forEach';

/* eslint-disable */

import Table from '../../components/Table';
import ModalView from '../../components/ModalView';
import ModalDelete from '../../components/ModalDelete';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormRadio from '../../components/FormRadio';
import FormSelect2 from '../../components/FormSelect2';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormDatePicker from '../../components/FormDatePicker';
import FormCheckbox from '../../components/FormCheckbox';

import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, movePosition, calculatePosition } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton, SuccessButton } from '../../styles/button';
import { AmberTag, SuccessTag, InfoTag } from '../../styles/tag';

const TableWrapper = styled.div`
    display: block;
    padding: 30px 0px 0px 0px;

    .MuiPaper-elevation2 { box-shadow: none; border-radius: 0px; }
    .MuiTable-root { border: 1px solid #999; }
    .MuiTableCell-root { 
        border-color: #999; 
        padding: 8px; 
        &.MuiTableCell-head { 
            font-size: 14px; 
            background: #f1f1f1;
        }
    }
`;

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '14px',
        verticalAlign: 'top'
    }
});


class ColumnsCalculation extends React.Component {

    state = {
        openModal: false,
        modalType: false,
        selectItem: false
    };

    handleAction = () => {
        const { modalType, selectItem } = this.state;
        const { onChange, template } = this.props;

        switch( modalType ) {
            case 'new':
            case 'edit':
                // do error check
                var error = false;

                // do error check
                if ( !( selectItem.calc && isArrayExists( selectItem.calc ) ) || !( selectItem.col_num && !_isEmpty( selectItem.col_num ) ) || !( selectItem.calc && !_isEmpty( selectItem.col_main ) ) )
                    error = 'Please fill out all the fields.';

                if ( error ) {
                    triggerErrorAlert(error);
                } else {
                    // add or update
                    var newValue = ( template && template.columns_calculation && isArrayExists( template.columns_calculation ) ? cloneCollections( template.columns_calculation ) : [] );
                    if ( modalType === 'new' ) {
                        newValue.push( selectItem );
                    } else {
                        let index = _findIndex( newValue, { id: selectItem.id } );
                        newValue[index] = selectItem;
                        // if is position 1, and make sure all the grouped_by is the same
                        if ( parseInt( selectItem.position, 10 ) === 1 && _size( newValue ) > 1 ) {
                            _forEach( template.columns_calculation, (v,i) => {
                                newValue[i].col_main = ( selectItem.col_main || '' );
                            });
                        } // end - newValue
                    } // end - modalType

                    // push updates
                    if ( onChange )
                        onChange( newValue, 'columns_calculation' );

                    // reset modal
                    this.setState({ openModal: true, modalType: false, selectItem: false });
                } // end - error
                break;
            case 'delete':
                if ( selectItem && selectItem.id && template && template.columns_calculation && isArrayExists( template.columns_calculation ) && _find( template.columns_calculation, { id: selectItem.id }) ) {
                    var newValue = ( template && template.columns_calculation && isArrayExists( template.columns_calculation ) ? cloneCollections( template.columns_calculation ) : [] ),
                        pulled = _remove( newValue, { id: selectItem.id });

                    // recalculate position
                    newValue = calculatePosition( newValue );

                    // push updates
                    if ( onChange )
                        onChange( newValue, 'columns_calculation' );

                    // reset modal
                    this.setState({ openModal: true, modalType: false, selectItem: false });
                } // end - selectItem
                break;
        } // end - modalType

    }

    handleFormUpdate = (newValue,key) => {
        const { selectItem } = this.state;
        let newData = ( selectItem ? cloneCollections( selectItem ) : {});
        if ( key === 'calc' ) {
            newData[key] = newValue;
        } else {
            // otherwise - just update normally
            newData[key] = newValue;
        }
        this.setState({ selectItem: newData });
    }

    getDefaultValue = () => {
        const { template } = this.props;
        var currentVal = ( template && template.columns_calculation && isArrayExists( template.columns_calculation ) ? cloneCollections( template.columns_calculation ) : false );
        return { 
            id: shortid.generate(),
            calc: [],
            col_num: '',
            col_main: ( currentVal && currentVal[0] && currentVal[0].col_main ? currentVal[0].col_main : '' ),
            position: _size( currentVal ) + 1
        };
    }

    isMoreThanOneCalc = () => {
        const { template } = this.props;
        return ( template && template.columns_calculation && isArrayExists( template.columns_calculation ) && _size( template.columns_calculation ) > 0 ? true : false )
    }

    getOptions = (list,prefill) => {
        var options = ( prefill ? [{ value: '', label: 'Select an Option' }] : [] );
        if ( list && isArrayExists( list ) ) {
            _forEach( list, item => {
                options.push(item);
            });
        } // end - list
        return options;
    }

    getCalcOptions = () => {
        return [
            { value: 'sum', label: 'SUM' },
            { value: 'avg', label: 'AVERAGE' },
            { value: 'max', label: 'MAX' },
            { value: 'min', label: 'MIN' }
        ];
    }

    getColumnLabel = (val,options) => {
        var option = ( val && !_isEmpty(val) && options && isArrayExists( options ) ? _find( options, { value: val } ) : false );
        return ( option && option.label || '' );
    }

    // renderField = (cell) => {
    //     const { selectItem } = this.state;
    //     if ( cell && cell.field && !_isEmpty( cell.field ) ) {
    //         let field = this.getFieldprops( cell );
    //         switch( cell.field ) {
    //             case 'text':
    //                 return <FormInput {...field} onChange={this.handleFormUpdate} />;
    //             case 'text_number':
    //                 return <FormInput {...field} type="number" onChange={this.handleFormUpdate} />;
    //             case 'email':
    //                 return <FormInput {...field} type="email" onChange={this.handleFormUpdate} />;
    //             case 'select2':
    //                 return <div style={{ paddingTop: "10px" }}><FormSelect2 placeholder="Select an Option" {...field} onChange={this.handleFormUpdate} /></div>;
    //             case 'select':
    //                 return <div style={{ paddingTop: "10px" }}><FormSelect {...field} onChange={this.handleFormUpdate} /></div>;
    //             case 'radio':
    //                 return <div style={{ paddingTop: "10px" }}><FormRadio {...field} inline={true} onChange={this.handleFormUpdate} /></div>;
    //             case 'multiselect':
    //                 return <div style={{ paddingTop: "10px" }}><FormMultiSelect {...field} onChange={this.handleFormUpdate} /></div>;
    //             case 'datepicker':
    //                 return <FormDatePicker {...field} noDefaultVal={true} onChange={this.handleFormUpdate} />;
    //             case 'checkbox':
    //                 return <FormCheckbox {...field} checked={( cell.checked ? cell.checked : 'yes' )} unchecked={( cell.unchecked ? cell.unchecked : 'no' )} onChange={this.handleFormUpdate} />;
    //             case 'custom':
    //                 return ( cell.renderCustomField ? cell.renderCustomField({ item: selectItem, field, cell, onChange: this.handleFormUpdate }) : null );
    //         }
    //     } // end - cell.field_type
    // }

    renderModal = () => {
        const { columns, numColumns } = this.props;
        const { openModal, modalType, selectItem } = this.state;
        return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={1}>
                    <div style={{ lineHeight: '48px', paddingRight: '5px' }}>Do</div>
                </Grid>
                <Grid item xs={8}>
                    <FormMultiSelect 
                        noShrink={true}
                        label="Select calculation method(s)"
                        value={( selectItem && selectItem.calc || [] )} 
                        name="calc" options={this.getCalcOptions()} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={3}>
                    <div style={{ lineHeight: '48px', paddingLeft: '5px' }}>Calculation On</div>
                </Grid>
                <Grid item xs={12}>
                    <FormSelect value={( selectItem && selectItem.col_num || '' )} name="col_num" options={this.getOptions(numColumns)} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={2}>
                    <div style={{ lineHeight: '48px', paddingRight: '5px' }}>Grouped By</div>
                </Grid>
                <Grid item xs={10}>
                    <FormSelect value={( selectItem && selectItem.col_main || '' )} disabled={( parseInt(selectItem.position,10) > 1 ? true : false )} name="col_main" options={this.getOptions(columns)} onChange={this.handleFormUpdate} />
                </Grid>
            </Grid>
        </div>
        );
    }

    renderAddNewButton = () => {
        const { disabled } = this.props;
        return (
        <div style={{ padding: '10px 0 0 5px' }}>
            <InfoButton key="select" disabled={( disabled ? 'yes' : false )} size="small" onClick={() => this.setState({ openModal: true, modalType: 'new', selectItem: this.getDefaultValue() })} style={{ borderRadius: '25px', padding: '8px 25px' }}><i className="fa fa-plus"></i>Add New</InfoButton>
        </div>
        )
    }

    renderCell = (item) => {
        const { columns, numColumns } = this.props;
        return (
        <div style={{ verticalAlign: 'middle' }}>
            <span>Do </span>
            {_map(item.calc,cal => {
                return <AmberTag key={cal} style={{ marginRight: "5px" }}>{cal.toUpperCase()}</AmberTag>
            })}
            <span>on </span>
            <InfoTag style={{ marginRight: "5px" }}>{this.getColumnLabel(item.col_num,numColumns)}</InfoTag>
            <span>grouped by </span> 
            <SuccessTag style={{ marginRight: "5px" }}>{this.getColumnLabel(item.col_main,columns)}</SuccessTag>
        </div>
        )
    }

    renderTable = () => {
        const { template, disabled } = this.props;
        return <Table 
            items={( template && template.columns_calculation && isArrayExists( template.columns_calculation ) ? template.columns_calculation : [] )}
            showCheckbox={false}
            cells={[
                {   id: 'calc_cond' , 
                    label: 'Calculation Condition',
                    render: (item) => this.renderCell(item)
                }
            ]}
            actionStyles={{ width: "25%" }}
            actions={(item) => (
                <ButtonGroup>
                    <InfoButton key="add" disabled={( disabled ? 'yes' : false )} size="small" minWidth="none" noIconMargin="yes" onClick={() => this.setState({ openModal: true, modalType: 'edit', selectItem: item })}><i className="fa fa-edit"></i></InfoButton>
                    <ErrorButton key="delete" disabled={( disabled ? 'yes' : false )} size="small" minWidth="none" noIconMargin="yes" onClick={() => this.setState({ openModal: true, modalType: 'delete', selectItem: item })}><i className="fa fa-trash"></i></ErrorButton>
                </ButtonGroup>
            )} />
    }

    render() {
        const { template } = this.props;
        const { openModal, modalType } = this.state;
        return (
        <div>

            <FormControl fullWidth={true}>
                <InputLabel shrink={true} style={{ fontSize: "16px", fontWeight: "700", textTransform: "uppercase" }}>Do Calculation on Numerical Column</InputLabel>
                <TableWrapper>
                    {this.renderTable()}
                    {this.renderAddNewButton()}
                </TableWrapper>
            </FormControl>

            <ModalView 
                open={( openModal && modalType && ( modalType === 'new' || modalType === 'edit' ) ? true : false )}
                title={( openModal && modalType && modalType === 'edit' ? 'Edit Calculation' : 'Add New Calculation' )}
                actionLabel={( openModal && modalType && modalType === 'edit' ? 'Update' : 'Add New' )}
                cancelLabel="Close"
                doAction={this.handleAction}
                onClose={() => this.setState({ openModal: true, modalType: false, selectItem: false })}
                contents={this.renderModal()} />

            <ModalDelete
                open={( openModal && modalType && modalType === 'delete' ? true : false )}
                title="Are you sure you want to delete this calculation?"
                onClose={() => this.setState({ openModal: true, modalType: false, selectItem: false })}
                onDelete={this.handleAction} />


        </div>
        )
    }

}

export default compose(
    withStyles(useStyles)
)(ColumnsCalculation);