import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _isEmpty from 'lodash/isEmpty';
import _merge from 'lodash/merge';
import _size from 'lodash/size';
import _isEqual from 'lodash/isEqual';

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import Products from './products';

import { cloneCollections } from '../../helpers/data';

import { getProducts } from '../../actions/products';
import { getCourseProfile } from '../../actions/m_course_profile';
import { getSelectables } from '../../actions/m_selectables';
import { getRegions } from '../../actions/m_regions';
import { resetRedux } from '../../actions/misc';

import { FIRESTORE_DEFAULT_PERPAGE, DEV_MODE } from '../../constants';

class ProductsPage extends React.Component {

    state = {
        query: {
            searchterms: '',
            filterBy: '',
            sortBy: 'modified_on-desc',
            perPage: 20,
            page: 1
        },
        randNum: false
    };

    componentDidMount() {
        const { selectablesList, courseProfileList, regionsList, authData } = this.props;

        // get products list
        this.props.dispatch(getProducts(this.getInitialQuery( authData && authData.region || '' )));

        // set filterBy based on user region
        if ( authData && authData.region && !_isEmpty( authData.region ) )
            this.setInitialQuery(authData.region);

        // get course portfolio
        // if ( !courseProfileList )
        //     this.props.dispatch(getCourseProfile());

        // get selectables
        if ( !selectablesList )
            this.props.dispatch(getSelectables());

        // get regions
        if ( !regionsList )
            this.props.dispatch(getRegions());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { productsList, selectablesList, authData, randNum } = this.props;

        // once all the data is loaded
        if ( productsList && selectablesList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('products'));
    }

    handleRefresh = () => {
        // get products
        this.setState({ randNum: false });
        this.props.dispatch(resetRedux('products'));
        this.props.dispatch(getProducts());
    } 

    handleFSRefresh = () => {
        const { query } = this.state;
        this.setState({ randNum: false });
        this.props.dispatch(resetRedux('products'));
        this.props.dispatch(getProducts(query));
    }

    handleFSQueryChange = (props) => {
        const { query } = this.state
        var newQuery = this.setNewFSQuery(props);
        if ( this.ifOnlySortFilterChanged(newQuery) && newQuery.searchterms && !_isEmpty( newQuery.searchterms ) ) {
            // for search sort - no need to trigger reload
            this.setState({ query: newQuery });
        } else {
            this.setState({ query: newQuery, randNum: false });
            this.props.dispatch(resetRedux('products'));
            this.props.dispatch(getProducts(newQuery));
        } // end - newQuery 

    }

    ifOnlySortFilterChanged = (newQuery) => {
        const { query } = this.state;
        var changed = false;
        if ( !_isEqual( query.sortBy, newQuery.sortBy ) || !_isEqual( query.filterBy, newQuery.filterBy ) ) {
            if ( !_isEqual( query.searchterms, newQuery.searchterms ) || 
                !_isEqual( query.perPage, newQuery.perPage ) || 
                !_isEqual( query.page, newQuery.page ) )
                changed = true;
        } else {
            changed = true;
        }
        return ( changed ? false : true );
    }

    setNewFSQuery = (query) => {
        const { productsFirstDoc, productsLastDoc } = this.props;
        var currentQuery = this.getCurrentFSQuery(),
            newQuery = _merge( this.getCurrentFSQuery(), query);
        newQuery.endBefore = ( newQuery.page >= 2 && newQuery.page < currentQuery.page && productsFirstDoc ? productsFirstDoc : false );
        newQuery.startAfter = ( newQuery.page >= 2 && newQuery.page > currentQuery.page && productsLastDoc ? productsLastDoc : false );
        return newQuery;
    }

    getCurrentFSQuery = () => {
        const { query } = this.state;
        var currentQuery = {};
        if ( query && !_isEmpty( query ) ) {
            const { searchterms, sortBy, filterBy, perPage, page } = query;
            currentQuery = cloneCollections({ searchterms, sortBy, filterBy, perPage, page });
        }
        return currentQuery;
    }

    setInitialQuery = (region) => {
        const { query } = this.state;
        var newQuery = cloneCollections(query);
        newQuery.filterBy = 'region_id_|_'+region;
        this.setState({ query: newQuery });
    }

    getInitialQuery = (region) => {
        const { query } = this.state;
        var newQuery = cloneCollections(query);
        newQuery.filterBy = 'region_id_|_'+region;
        if ( DEV_MODE )
            newQuery.perPage = FIRESTORE_DEFAULT_PERPAGE;
        return newQuery;
    }

    renderContents() {
        const { authData, productsList, productsLoaded, courseProfileList, regionsList, selectablesList, productsTotal } = this.props;
        const { query } = this.state;
        return (
        <Products 
            authData={authData}
            products={productsList}
            productsLoaded={productsLoaded}
            //courses={courseProfileList}
            regions={regionsList}
            selectables={( selectablesList || [] )}
            total={productsTotal}
            currentTotal={( productsList ? _size( productsList ) : 0 )}
            currentQuery={this.getCurrentFSQuery()}
            searchterms={( query.searchterms || '' )}
            sortBy={( query.sortBy || 'modified_on-desc' )}
            filterBy={( query.filterBy || '' )}
            perPage={( query.perPage || 20 )}
            page={( query.page || 1 )}
            onRefresh={this.handleFSRefresh}
            onQueryChange={this.handleFSQueryChange} />
        );
    }

    render() {
        const { randNum } = this.state;
        const { productsList, selectablesList, authData } = this.props;
        return <AppWrapper 
                title="Products"
                onLoad={( !( randNum && productsList && selectablesList ) ? true : false )}
                contents={ authData ? this.renderContents() : null } />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        productsList: state.products && state.products.products || null,
        productsTotal: state.products && state.products.total || null,
        productsFirstDoc: state.products && state.products.firstDoc || null,
        productsLastDoc: state.products && state.products.lastDoc || null,
        productsLoaded: state.products && state.products.products_loaded || null,
        courseProfileList: state.maintenance && state.maintenance.courses_profile || null,
        selectablesList: state.maintenance && state.maintenance.selectables || null,
        regionsList: state.maintenance && state.maintenance.regions || null,
        randNum: state.products && state.products.rand || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ProductsPage);