/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _isEqual from 'lodash/isEqual';

import ModalView from '../ModalView';
import Table from '../Table';
import Pagination from '../Pagination';
import TableBar from '../TableBar';
import DotsLoader from '../DotsLoader';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { formatMoney } from '../../helpers/number';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';

import { getEmailTemplates } from '../../actions/emailTemplates';
import { fb_getEmailTemplateWithShortcode } from '../../actions/emailTemplates/fb';
import { toggleLoader } from '../../actions/global';
import { resetRedux } from '../../actions/misc';

class ModalSelectEmailTemplate extends React.Component {

    state = {
        searchterms: '',
        filterBy: 'all',
        sortBy: 'name-asc',
        perPage: 20,
        page: 1,
        randNum: false
    };

    componentDidMount() {
        this.dispatchOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( !_isEqual( prevProps.emailTemplatesList, this.props.emailTemplatesList ) || 
            !_isEqual( prevProps.authData, this.props.authData ) || 
            !_isEqual( prevProps.randNum, this.props.randNum ) ) {
            this.dispatchOptions();
        }
    }

    dispatchOptions = () => {
        const { emailTemplatesList, authData, randNum } = this.props;
        if ( ( !emailTemplatesList || !randNum ) && authData ) {
            this.props.dispatch(getEmailTemplates(authData));
        } else if ( emailTemplatesList && randNum && randNum != this.state.randNum ) {
            this.setState({ randNum });
        } // end - emailTemplatesList
    }

    handleSelect = (option) => {
        const { onSelect, dispatch, email } = this.props;
        const answer = window.confirm("Are you sure you want to import this template\n\nAny existing email content & subject line will be overwritten.");
        if ( answer ) {
            const modulesIDs = ['account','contact','potential','quote','invoice','product'],
                modules = [];

            // get modules
            if ( email && !_isEmpty( email ) ) {
                _forEach( modulesIDs, type => {
                    if ( email[type+'s_linked'] && isArrayExists( email[type+'s_linked'] ) ) {
                        _forEach( email[type+'s_linked'],item => {
                            // for product module, use product_id instead
                            modules.push({ type, id: ( type === 'product' ? item.product_id : item.id ) });
                        });
                    } // end - email[type+'s_linked']
                });
            } // end -  email

            dispatch(toggleLoader(true));
            fb_getEmailTemplateWithShortcode({ 
                template_id: option.id,
                modules
            })
            .then( template => {
                // trigger option select
                if ( onSelect && template && template.id && !_isEmpty( template.id ) ) {
                    onSelect(template);
                    dispatch(toggleLoader(false));
                    triggerSuccessAlert("Email Template Imported")
                } else {
                    dispatch(toggleLoader(false));
                    triggerErrorAlert('Unable to retrieve template');
                }
            })
            .catch( error => {
                var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve template' ) );
                dispatch(toggleLoader(false));
                triggerErrorAlert(errMsg);
            });
        } // end = answer

    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose )
            onClose();
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { emailTemplatesList } = this.props;
        let items = ( emailTemplatesList ? cloneCollections( emailTemplatesList ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','subject','desc'] );
			total = _size( items );
        } // end - searchterms

        // do custom filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy != 'all' ) {
            // items = _filter( items, { org: filterBy });
            // total = _size( items );
        }

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'name-desc':
                    items = _sortBy( items, [(i) => i.name.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, [(i) => i.name.toLowerCase()] );
                    break;
                case 'subject-desc':
                    items = _sortBy( items, [(i) => i.subject.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'subject-asc':
                    items = _sortBy( items, [(i) => i.subject.toLowerCase()] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                total={totalCount}
                perPage={perPage} 
                page={page}
                doneLoaded={true}
                style={{ marginTop: "20px" }}
                onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderTableActions = () => {
        const { sortBy, perPage, searchterms } = this.state;
        return <TableBar
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' },
                    { value: 'subject-asc', label: 'Subject ( A - Z)' },
                    { value: 'subject-desc', label: 'Subject ( Z - A )' }
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderTable = (items) => {
        return <Table 
            items={items}
            showCheckbox={false}
            cells={[
                { id: 'name', label: 'Name', render: (item) => ( item.name || '' ) },
                { id: 'subject', label: 'Subject', render: (item) => ( item.subject || '' ) },
                { id: 'desc', label: 'Description', render: (item) => ( item.desc || '' ) },
            ]}
            actionStyles={{ width: "10%" }}
            actions={(item) => (
                <ButtonGroup>
                    <InfoButton key="select" size="small" onClick={this.handleSelect.bind(this,item)}><i className="fa fa-edit"></i>Select</InfoButton>
                </ButtonGroup>
            )} />
    }

    renderModal = () => {
        const { randNum } = this.state;
        const { items, total } = this.reorganizeData();
        return !randNum ? <DotsLoader /> : (
        <div>

            {this.renderTableActions()}
            {this.renderTable(items)}
            {this.renderPagination(total)}
            
        </div>   
        )
    }

    render() {
        const { open } = this.props;
        return <ModalView 
                open={open}
                title="Select Email Template"
                noAction={true}
                actionLabel="Select"
                maxWidth="lg"
                cancelLabel="Close"
                onClose={this.handleClose}
                contents={ open ? this.renderModal() : null } />
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        emailTemplatesList: state.emailtemplates && state.emailtemplates.templates || null,
        randNum: state.emailtemplates && state.emailtemplates.rand || null,
    }
}

export default compose(
    connect(mapStateToProps)
)(ModalSelectEmailTemplate);