/* eslint-disable */
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _size from 'lodash/size';
import _find from 'lodash/find';
import _endsWith from 'lodash/endsWith';
import _isNull from 'lodash/isNull';

import { isArrayExists, validate } from './validation';
import { stringToInteger } from './number';
import { getMomentTime } from './date';

// get readable data
export const getReadableData = (value,schema) => {
    var datatype = ( schema.report_datatype && !_isEmpty( schema.report_datatype ) ? schema.report_datatype : schema.type );
    switch( datatype ) {
        case 'system_date':
        case 'timestamp':
            let timestamp = ( value ? stringToInteger( value ) : null );
            return ( !_isNull( timestamp ) ? getMomentTime( timestamp, 'YYYY-MM-DD HH:mm' ) : '-' );
        default:
            return value;
    }
}