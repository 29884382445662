/* eslint-disable */
import * as firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _snakeCase from 'lodash/snakeCase';
import _find from 'lodash/find';

import { doesUserHaveAccess, hasLimitedAccess } from '../../helpers/auth';
import { callFunctionsAPI, checkIfUserAccessLimited } from '../../helpers/action';
import { isArrayExists, isObjectExists, validateEmail } from '../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema } from '../../helpers/schemas';
import { getItemsFromFirestore, getTotalCountBySchema } from '../../helpers/firestore';
import { isValidUser } from '../../helpers/users';
import { newQuoteItem } from '../../helpers/quotes';

import { quoteSchema } from '../../schemas/quote';
import { commentSchema } from '../../schemas/comment';
import { logSchema } from '../../schemas/log';


/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

const getQuoteOptionData = (quote) => {
    return new Promise((resolve,reject) => {

        const promises = [];

        if ( quote && quote.accounts_linked && isArrayExists( quote.accounts_linked ) && quote.accounts_linked[0] && quote.accounts_linked[0].id && !_isEmpty( quote.accounts_linked[0].id ) ) {
            promises.push( firebase.database().ref("maintenance/options/accounts/"+quote.accounts_linked[0].id).once("value") );
        }

        if ( quote && quote.contacts_linked && isArrayExists( quote.contacts_linked ) && quote.contacts_linked[0] && quote.contacts_linked[0].id && !_isEmpty( quote.contacts_linked[0].id ) ) {
            promises.push( firebase.database().ref("maintenance/options/contacts/"+quote.contacts_linked[0].id).once("value") );
        }

        if ( isArrayExists( promises ) ) {
            Promise.all( promises )
            .then((results) => {

                if ( results && isArrayExists( results ) ) {
                    _forEach( results, result => {
                        if ( result && result.exists() ) {
                            const refPath = result.ref.toString();

                            if ( refPath.indexOf("maintenance/options/accounts/") >= 0 ) {
                                const accountOption = result.val();
                                if ( quote && quote.accounts_linked && isArrayExists( quote.accounts_linked ) && quote.accounts_linked[0] && quote.accounts_linked[0].id && !_isEmpty( quote.accounts_linked[0].id ) && accountOption && accountOption.name && !_isEmpty( accountOption.name ) ) {
                                    quote.accounts_linked[0].name = accountOption.name;
                                }
                            }

                            if ( refPath.indexOf("maintenance/options/contacts/") >= 0 ) {
                                const contactOption = result.val();
                                if ( quote && quote.contacts_linked && isArrayExists( quote.contacts_linked ) && quote.contacts_linked[0] && quote.contacts_linked[0].id && !_isEmpty( quote.contacts_linked[0].id ) && contactOption && contactOption.name && !_isEmpty( contactOption.name ) ) {
                                    quote.contacts_linked[0].name = contactOption.name;
                                }
                            }
                        } // end - result
                    });
                } // end - results

                resolve(quote);
            })
            .catch( error => {
                reject(error);
            });
        } else {
            resolve(quote);
        }

    })
}

// get a specific data from database and
const getDataFromFirestore = (type,id,authData) => {
    return new Promise((resolve,reject) => {

        firebase.firestore().collection(type).doc(id).get()
        .then( doc => {
            var data = ( doc.exists ? doc.data() : false );
            
            // make sure limited user have the access
            if ( authData && hasLimitedAccess( authData ) ) {
                if ( !doesUserHaveAccess(authData,data) )
                    data = false;
            } // end - authData

            // get products data if potential has product
            if ( type === 'potentials' && data && data.product_id && !_isEmpty( data.product_id ) ) {
                firebase.firestore().collection("products").doc(data.product_id).get()
                .then(proDoc => {
                    data.product_data = ( proDoc.exists ? proDoc.data() : false );
                    resolve({ type, data });
                })
                .catch(error => {
                    resolve({ type, data });
                });
            } else {
                resolve({ type, data });
            } // end - type    
        })
        .catch(error => {
            reject(error);
        });

    })
}

// get list from firebase based props
const getFirestoreList = (props) => {
    return new Promise((resolve,reject) => {

        var promises = [];
        promises.push( getItemsFromFirestore( "quotes", ( props && isObjectExists( props ) ? props : null ) ) );

        // if no keywords filter
        if ( !( props && props.searchterms && !_isEmpty( props.searchterms ) ) ) {
            promises.push( getTotalCountBySchema('quotes') );
        } // end - props.searchterms

        Promise.all( promises )
        .then(results => {
            resolve({ 
                list: ( results && results[0] && results[0].list && isArrayExists( results[0].list ) ? results[0].list : [] ), 
                firstDoc: ( results && results[0] && results[0].firstDoc ? results[0].firstDoc : false ),
                lastDoc: ( results && results[0] && results[0].lastDoc ? results[0].lastDoc : false ), 
                total: ( results && results[1] ? results[1] : 0 )
            });
        })
        .catch(error => {
            // only use it when needed
            // console.log(error);
            reject(error);
        });

    })
} 

// get list based on assigned_to
const getAssignedToList = (user) => {
    return new Promise((resolve,reject) => {

        let ref = firebase.database().ref("quotes");
        if ( user && user.id && user.email && validateEmail(user.email) ) {
            ref = ref.orderByChild("assigned_to/"+user.id+"/id").equalTo(user.email);
        }

        ref.once("value")
        .then(snapshot => {

            let list = [];

            if ( snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach( childSnapshot => {
                    let item = getSnapshotDataBySchema( quoteSchema, childSnapshot );
                    list.push(item);
                });
            } // end - snapshot

            resolve({ list });

        })
        .catch(error => {
            reject(error);
        });
    })
}


/* helper end */

export const fb_getQuotes = (props) => {
    return new Promise((resolve,reject) => {

        checkIfUserAccessLimited()
        .then(user => {
            return ( isValidUser(user) ? getAssignedToList(user) : getFirestoreList(props) );
        })
        .then(payload => {
            resolve(payload);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getQuote = (quote_id) => {
    return new Promise((resolve,reject) => {

        let promises = [];
        promises.push( firebase.firestore().collection("quotes").doc(quote_id).get() );
        promises.push( firebase.database().ref("comments").orderByChild('refer_id').equalTo(quote_id).once("value") );
        promises.push( firebase.database().ref("logs").orderByChild('type_id').equalTo(quote_id).once("value") );
        promises.push( checkIfUserAccessLimited() );
        
        Promise.all( promises )
        .then(results => {
            let item = {};

            if ( results && results[0] ) {
                item = getDocDataBySchema( quoteSchema, results[0] )
            } // end - results[0]

            // add comments
            item['comments'] = [];
            if ( results && results[1] && results[1].exists() && results[1].hasChildren() ) {
                results[1].forEach( childSnapshot => {
                    let comment = getSnapshotDataBySchema( commentSchema , childSnapshot );
                    item['comments'].push( comment );
                });
            } // end - results[1]

            // add updates
            item['updates'] = [];
            if ( results && results[2] && results[2].exists() && results[2].hasChildren() ) {
                results[2].forEach( childSnapshot => {
                    let update = getSnapshotDataBySchema( logSchema , childSnapshot );
                    item['updates'].push( update );
                });
            } // end - results[2]

            // if is limited user
            if ( results && results[3] && !_isEmpty( results[3] ) ) {
                if ( doesUserHaveAccess( results[3], item ) ) {
                    // do nothing
                    // resolve(item);
                } else {
                    // reset item if don't have access
                    item = {};
                }
            } // end - results

            return ( item && isObjectExists(item) ? getQuoteOptionData(item) : {} );
        })
        .then(item => {
            resolve(item);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getPreloadQuoteData = ({ account_id, contact_id, potential_id, authData }) => {
    return new Promise((resolve,reject) => {

        let promises = [];

        if ( account_id && !_isEmpty( account_id ) )
            promises.push( getDataFromFirestore( 'accounts', account_id, authData ) );

        if ( contact_id && !_isEmpty( contact_id ) )
            promises.push( getDataFromFirestore( 'contacts', contact_id, authData ) );

        if ( potential_id && !_isEmpty( potential_id ) )
            promises.push( getDataFromFirestore( 'potentials', potential_id, authData ) );
        
        Promise.all( promises )
        .then(results => {
            var quote = {};

            // add default data
            quoteSchema.forEach(schema => {
                quote[schema.id] = ( schema.default || null );
            });

            if ( results && isArrayExists( results ) ) {
                var account = _find( results, { type: 'accounts' } ),
                    contact = _find( results, { type: 'contacts' } ),
                    potential = _find( results, { type: 'potentials' } );

                // add account if available
                if ( account && account.data && account.data.id && !_isEmpty( account.data.id ) ) {
                    var newAccount = {
                        id: account.data.id,
                        name: account.data.name || '',
                        name_sort: account.data.name_sort || '',
                        assigned_to: {}
                    };

                    // add assigned_to
                    if ( account.data.assigned_to && isArrayExists( account.data.assigned_to ) ) {
                        _forEach( account.data.assigned_to , assign => {
                            if ( assign.id && !_isEmpty( assign.id ) )
                                newAccount.assigned_to[ _snakeCase( assign.id ) ] = assign;
                        });
                    } // end - account.data.assigned_to

                    quote.accounts_linked = [];
                    quote.accounts_linked.push(newAccount);

                    // add assigned_to from account to quote
                    if ( account && account.data && account.data.assigned_to && isArrayExists( account.data.assigned_to ) )
                        quote.assigned_to = account.data.assigned_to;
                        
                } // end - account

                // add contact if Available
                if ( contact && contact.data && contact.data.id && !_isEmpty( contact.data.id ) ) {
                    var newContact = {
                        id: contact.data.id,
                        name: contact.data.name || '',
                        email: contact.data.email || '',
                        account_id: contact.data.account_id || '',
                    };

                    quote.contacts_linked = [];
                    quote.contacts_linked.push(newContact);
                } // end - contact

                // add potential if available
                if ( potential && potential.data && potential.data.id && !_isEmpty( potential.data.id ) ) {
                    var newPotential = {
                        id: potential.data.id,
                        name: potential.data.name || '',
                    };

                    quote.potentials_linked = [];
                    quote.potentials_linked.push(newPotential);

                    // if potential has a product
                    if ( potential.data && potential.data.product_data && potential.data.product_data.id && !_isEmpty( potential.data.product_data.id ) ) {
                        quote.products_linked = [];
                        var productItem = newQuoteItem(0);
                        productItem.product_id = potential.data.product_data.id;
                        productItem.name = ( potential.data.product_data.name || '' );
                        productItem.quantity = 1;

                        // add pricing category
						if ( potential.data.product_data.price_category && isArrayExists( potential.data.product_data.price_category ) ) {
							_forEach( potential.data.product_data.price_category, price_cat => {
								if ( price_cat.id && !_isEmpty( price_cat.id ) ) {
									// if is regular price - add as unit price
									if ( price_cat.id === '281' && price_cat.price )
										productItem.unit_price = parseFloat( price_cat.price, 10 );

									// add as price cat
									productItem.pricing_category[price_cat.id] = price_cat;
								} // end - price_cat.id
							});
						} // end - product.price_category

                        quote.products_linked.push(productItem);
                    } // end - potential.data.product_data

                } // end - potential

            } // end - results

            // add empty row for products if needed
            if ( !( quote.products_linked && isArrayExists( quote.products_linked ) ) ) {
                quote.products_linked = [];
                quote.products_linked.push( newQuoteItem(0) );
            } // end - quote

            resolve(quote);
            
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getQuotesBy = (type,id) => {
    return new Promise((resolve,reject) => {

        firebase.database().ref("usage/"+type+"/"+id+"/quotes").once("value")
        .then( snapshot => {
            var promises = [];
            if ( snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach( childSnapshot => {
                    promises.push( firebase.database().ref("quotes/"+childSnapshot.key).once("value") );
                });
            } // end - snapshpt

            return ( isArrayExists( promises ) ? Promise.all( promises ) : false );
        })
        .then(results => {

            let list = [];

            if ( results && isArrayExists( results ) ) {
                _forEach( results, result => {
                    if ( result && result.exists() ) {
                        let item = getSnapshotDataBySchema( quoteSchema, result );
                        list.push(item);
                    } // end - result
                });
            } // end - results

            resolve(list);

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_addQuote = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'quote', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateQuote = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'quote', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteQuote = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'quote', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_generateQuotePDF = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'quote', action: 'generate_pdf', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_convertQuoteToInvoice = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'quote', action: 'convert_to_invoice', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_duplicateQuote = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'quote', action: 'duplicate', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}