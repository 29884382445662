import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import ModalView from '../../components/ModalView';
import ButtonActions from '../../components/ButtonActions'
import SnackBarSave from '../../components/SnackBarSave';

import Details from '../CreditNotePage/details';

import { InfoButton, GreyButton } from '../../styles/button';

import { isArrayExists, validate, isNumeric } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';
import { newInvoiceItem } from '../../helpers/invoices';
import { getRegionTaxRate } from '../../helpers/quotes';

import { getInvoice } from '../../actions/invoices';
import { addNewCreditNote, getCreditNote, getPreloadCreditNoteData } from '../../actions/credit_notes';
import { getUsersOptions } from '../../actions/users';
import { getSelectables } from '../../actions/m_selectables';
import { getRegions } from '../../actions/m_regions';
import { getCoursePortfolios } from '../../actions/m_course_portfolio';
import { appChangesMade, resetRedux } from '../../actions/misc';
import { getInvoiceType } from '../../actions/m_invoice_type';
import { getPaymentTerms } from '../../actions/m_payment_terms';

import { creditNoteSchema } from '../../schemas/credit_note';

import { FIREBASE_STORAGE_URL } from '../../constants';

const getDefaultCreditNote = () => {
    var credit_note = {};

    creditNoteSchema.forEach(schema => {
        credit_note[schema.id] = ( schema.default || null );
    });

    // add empty row for products if needed
    if ( !( credit_note.products_linked && isArrayExists( credit_note.products_linked ) ) ) {
        credit_note.products_linked = [];
        credit_note.products_linked.push( newInvoiceItem(0) );
    } // end - credit_note

    return credit_note
}

class CreditNoteNewPage extends React.Component {

    state = {
        credit_note: getDefaultCreditNote(),
        randNum: false
    };

    componentDidMount() {
        const { regionsList, selectablesList, coursePortfolioList, invoiceTypeList, paymentTermsList, usersOptions, mainRand, authData } = this.props;
        const { action, id, account, contact } = this.getParams();

        // get regions
        if ( !regionsList )
            this.props.dispatch(getRegions());

        // get selectables
        if ( !selectablesList )
            this.props.dispatch(getSelectables());

        // get all users
        if ( !usersOptions )
            this.props.dispatch(getUsersOptions());

        // get course portfolio
        if ( !coursePortfolioList )
            this.props.dispatch(getCoursePortfolios());

        // get invoice type
        if ( !invoiceTypeList )
            this.props.dispatch(getInvoiceType());

        // get payment terms
        if ( !paymentTermsList )
            this.props.dispatch(getPaymentTerms());


        if ( action && ( action === 'duplicate' || action === 'convert' ) && id && !_isEmpty( id ) ) {
            if ( action === 'duplicate' ) {
                this.props.dispatch(getCreditNote(id));
            } else {
                this.props.dispatch(getInvoice(id));
            } // end - action
            // trigger changes made
            this.props.dispatch(appChangesMade());
        } else if ( action && ( action === 'preload' ) && (
            ( account && !_isEmpty( account ) ) || 
            ( contact && !_isEmpty( contact ) )
        ) ) {
            this.props.dispatch(getPreloadCreditNoteData({ account_id: account, contact_id: contact, authData }));
            // trigger changes made
            this.props.dispatch(appChangesMade());
        } else if ( regionsList && selectablesList && coursePortfolioList && invoiceTypeList && paymentTermsList && usersOptions && mainRand ) {
            this.setState({ randNum: mainRand });
        } // end - currentInvoice
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentCreditNote, currentInvoice, regionsList, selectablesList, coursePortfolioList, invoiceTypeList, paymentTermsList, usersOptions, randNum, invoiceRand, mainRand } = this.props;
        const { action, id, account, contact } = this.getParams();

        if ( action && ( action === 'duplicate' || action === 'convert' ) && id && !_isEmpty( id ) ) {
            if ( action === 'duplicate' && currentCreditNote && regionsList && selectablesList && coursePortfolioList && invoiceTypeList && paymentTermsList && usersOptions && randNum && randNum !== this.state.randNum ) {
                this.setState({ credit_note: this.getDuplicateCreditNoteData(currentCreditNote), randNum });
            } else if ( action === 'convert' && currentInvoice && regionsList && selectablesList && coursePortfolioList && invoiceTypeList && paymentTermsList && usersOptions && invoiceRand && invoiceRand !== this.state.randNum ) {
                this.setState({ credit_note: this.getInvoiceData(currentInvoice), randNum: invoiceRand });
            } // end - currentInvoice
        } else if ( action && ( action === 'preload' ) && (
            ( account && !_isEmpty( account ) ) || 
            ( contact && !_isEmpty( contact ) )
        ) ) {
            if ( currentCreditNote && regionsList && selectablesList && coursePortfolioList && invoiceTypeList && paymentTermsList && usersOptions && randNum && randNum !== this.state.randNum ) {
                this.setState({ credit_note: this.getDuplicateCreditNoteData(currentCreditNote), randNum });
            }
        } else {
            // for invoice
            if ( regionsList && selectablesList && coursePortfolioList && invoiceTypeList && paymentTermsList && usersOptions && mainRand && mainRand !== this.state.randNum ) {
                this.setState({ randNum: mainRand });
            } // end - currentInvoice
        }
        
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('credit_note'));
        this.props.dispatch(resetRedux('invoice'));
    }

    handleSaveChanges = (event) => {
        const { history, authData, regionsList } = this.props;
        const { credit_note } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do product error check
        if ( credit_note && credit_note.products_linked && isArrayExists( credit_note.products_linked ) ) {
            _forEach( credit_note.products_linked, product => {
                if ( !( product && product.product_id && !_isEmpty( product.product_id ) ) )
                    error = 'One of the product inserted is invalid';
            });
        } else {
            error = 'Please insert at least one valid product';
        } // end - credit_note.products_linked

        // do error check
        creditNoteSchema.forEach(schema => {
            formData[schema.id] = ( credit_note && credit_note[schema.id] ? cloneCollections( credit_note[schema.id] ) : schema.default );

            // insert credit_note_date if is empty
            if ( schema.id === 'credit_note_date' && !formData[schema.id]  ) {
                formData[schema.id] = moment().utcOffset(8).valueOf();
            } // end - schema.id

            // insert default value if is empty
            if ( schema.id === 'issued_by' && !( formData[schema.id] && !_isEmpty( formData[schema.id] ) ) ) {
                formData[schema.id] = ( authData && authData.email || '' );
            } // end - schema.id

            // insert company_details if is empty
            if ( schema.id === 'company_details' && !( formData[schema.id] && !_isEmpty( formData[schema.id] ) ) && authData && authData.region && !_isEmpty( authData.region ) ) {
                formData[schema.id] = authData.region;
            } // end - formData[schema.id]

            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'add' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }

            // check id
            if ( schema.id === 'id' ) {
                if ( formData[schema.id] && validate( formData[schema.id] , 'string_id' ) ) {
                    // if valid, add CN_ prefix
                    formData[schema.id] = 'CN_' + formData[schema.id];
                } else {
                    error = 'Invalid Credit Note Number. Only alphanumeric, underscores, and dashes are allowed';
                }
            }
        });

        // add tax rate if is empty
        if ( !( formData.custom_tax_rate && isNumeric( formData.custom_tax_rate ) ) ) {
            formData.custom_tax_rate = getRegionTaxRate(formData, regionsList).toString();
        }

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addNewCreditNote(formData,history));
        } // end - error

    }

    handleFormUpdate = (newValue) => {
        this.setState({ credit_note: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    getDuplicateCreditNoteData = (val) => {
        var newData = {};

        // get value based on schema
        creditNoteSchema.forEach(schema => {
            switch( schema.id ) {
                case 'id':
                    newData[schema.id] = '';
                    break;
                default:
                    newData[schema.id] = ( val && val[schema.id] ? cloneCollections( val[schema.id] ) : schema.default );
                    break;
            }
        });

        return newData;
    }

    getInvoiceData = (val) => {
        var newData = {};

        // get value based on schema
        creditNoteSchema.forEach(schema => {
            switch( schema.id ) {
                case 'invoice_number':
                    newData[schema.id] = ( val && val['id'] ? cloneCollections( val['id'] ) : schema.default );
                    break;
                default:
                    newData[schema.id] = ( val && val[schema.id] ? cloneCollections( val[schema.id] ) : schema.default );
                    break;
            }
        });
        return newData;
    }

    getParams = () => {
        return queryString.parse( this.props.location.search );
    }

    renderdetails = () => {
        const { authData, usersOptions, regionsList, selectablesList, coursePortfolioList, invoiceTypeList, paymentTermsList, changesMade } = this.props;
        const { credit_note } = this.state;
        const { action } = this.getParams();
        return <Details 
                isAddNew={true}
                isDuplicate={( action && action === 'duplicate' ? true : false )}
                isConvert={( action && action === 'convert' ? true : false )}
                authData={( authData || false )}
                credit_note={( credit_note || false )}
                regions={( regionsList || [] )}
                course_portfolio={( coursePortfolioList || [] )}
                selectables={( selectablesList || [] )}
                invoice_type={( invoiceTypeList || [] )}
                payment_terms={( paymentTermsList || [] )}
                changesMade={( changesMade || false )}
                onFormUpdate={this.handleFormUpdate}
                users={( usersOptions || [] )} />;
    }

    renderContents() {
        return (
            <div>

            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                {this.renderdetails()}
            </Paper>

            <Grid container style={{ marginTop: "45px" }}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
                </Grid>
            </Grid>

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { randNum } = this.state;
        return <AppWrapper 
                title="Add New Credit Note"
                subtitle="Credit Notes"
                back="/credit_notes"
                breadcrumbs={[
                    { url: '/credit_notes', label: 'Credit Notes' },
                    { label: 'Add New' }
                ]}
                customColumn={null}
                onLoad={( !( randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentCreditNote: state.credit_notes && state.credit_notes.credit_note || null,
        randNum: state.credit_notes && state.credit_notes.rand || null,
        currentInvoice: state.invoices && state.invoices.invoice || null,
        invoiceRand: state.invoices && state.invoices.rand || null,
        usersOptions: state.users && state.users.users_options || null,
        selectablesList: state.maintenance && state.maintenance.selectables || null,
        regionsList: state.maintenance && state.maintenance.regions || null,
        coursePortfolioList: state.maintenance && state.maintenance.course_portfolio || null,
        invoiceTypeList: state.maintenance && state.maintenance.invoice_type || null,
        paymentTermsList: state.maintenance && state.maintenance.payment_terms || null,
        mainRand: state.maintenance && state.maintenance.rand || null,
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(CreditNoteNewPage);