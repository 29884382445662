/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';

import ModalView from '../ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormSelect2 from '../FormSelect2';
import FormMultiSelect from '../../components/FormMultiSelect';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';

import { addNewReportTemplate } from '../../actions/reports';

const initialState = {
    name: '',
    desc: '',
    category_id: '',
    category_label: '',
    primary_module: '',
};

class addNewReportComp extends React.Component {

    state = {
        data: initialState,
        randNum: false
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { open } = this.props;
        if ( open && !prevProps.open ) {
            this.setState({ data: this.getInitialValues() }); // reset 
        }
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose ) {
            this.setState({ data: this.getInitialValues() }); // reset 
            onClose();
        } // end - onClose
    }

    handleFormUpdate = (newValue,key) => {
        const { categories } = this.props;
        const { data } = this.state;
        var newData = ( data ? cloneCollections( data ) : {} );
        newData[key] = newValue;
        if ( key === 'category_id' ) {
            var selected = ( categories && isArrayExists( categories ) ? _find( categories, { id: newValue }) : false );
            if ( selected && selected.name )
                newData['category_label'] = selected.name;
        }
        
        this.setState({ data: newData });
    }

    handleAddNew = () => {
        const { history } = this.props;
        const { data } = this.state;
        var error = false,
            formData = cloneCollections( data );

        if ( !( formData && formData.primary_module && !_isEmpty( formData.primary_module ) ) ) {
            error = 'Please select a valid primary module';
        } // end - formData.email

        if ( !( formData && formData.category_id && !_isEmpty( formData.category_id ) ) ) {
            error = 'Please select a valid category';
        } // end - formData.email   

        if ( !( formData && formData.name && !_isEmpty( formData.name ) ) ) {
            error = 'Please enter a valid template name';
        } // end - formData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addNewReportTemplate(formData,history));
        } // end - error
    }

    getInitialValues = () => {
        const { authData } = this.props;
        var data = cloneCollections( initialState );
        return data;
    }

    renderAddNewForm = () => {
        const { data } = this.state;
        const { categories } = this.props;
        return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormInput label="Name *" name="name" value={( data.name || '' )} onChange={this.handleFormUpdate} />
                    <div style={{ paddingTop: '14px' }}>
                        <FormSelect label="Category *" name="category_id" value={( data.category_id || '' )} options={getSelectOptions({ list: ( categories || [] ), options: [{ value: '', label: 'Select an Option' }], keys: { value: 'id', label: 'name' }, sortBy: 'label' })} onChange={this.handleFormUpdate} />
                    </div>
                    <div style={{ paddingTop: '21px' }}>
                        <FormSelect label="Primary Module *" name="primary_module" value={( data.primary_module || '' )} options={[
                            { value: '', label: 'Select an Option' },
                            { value: 'accounts', label: 'Accounts' },
                            { value: 'contacts', label: 'Contacts' },
                            { value: 'potentials', label: 'Potentials' },
                            { value: 'quotes', label: 'Quotes' },
                            { value: 'invoices', label: 'Invoices' },
                            { value: 'credit_notes', label: 'Credit Notes' },
                            { value: 'products', label: 'Products' },
                            //{ value: 'emails', label: 'emails' }
                        ]} onChange={this.handleFormUpdate} />
                    </div>
                    <FormInput label="Description" name="desc" value={( data.desc || '' )} rows={3} multiline={true} onChange={this.handleFormUpdate} />
                </Grid>
            </Grid>
        </div>
        );
    }

    render() {
        const { open } = this.props;
        return (
        <div>

            <ModalView 
                open={open}
                title="Add New Report Template"
                onClose={this.handleClose}
                doAction={this.handleAddNew}
                contents={this.renderAddNewForm()} />

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(addNewReportComp);