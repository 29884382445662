export const logSchema = [
    { 
        id: 'type', 
        label: 'Type',
        default: '', 
        type: 'string' 
    },
    { 
        id: 'type_id', 
        label: 'Type ID',
        default: '', 
        type: 'string' 
    },
    { 
        id: 'type_label', 
        label: 'Type Label',
        default: '', 
        type: 'string' 
    },
    { 
        id: 'text', 
        label: 'Text',
        default: '', 
        type: 'string' 
    },
    { 
        id: 'action_type', 
        label: 'Action Type',
        default: '', 
        type: 'string' 
    },
    { 
        id: 'action_target', 
        label: 'Action Target',
        default: '', 
        type: 'string' 
    },
    { 
        id: 'action_target_id', 
        label: 'Action Target ID',
        default: '', 
        type: 'string' 
    },
    { 
        id: 'action_target_label', 
        label: 'Action Target Label',
        default: '', 
        type: 'string' 
    },
    { 
        id: 'user', 
        label: 'User (email)',
        default: '', 
        type: 'email' 
    },
    { 
        id: 'user_name', 
        label: 'User (Name)',
        default: '', 
        type: 'string' 
    },
    {   
        id: 'logged_on',
        label: 'Logged On',
        default: 0,
        type: 'system_date'
    }
];