import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import _isEmpty from 'lodash/isEmpty';
import _toLower from 'lodash/toLower';

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import ModalView from '../../components/ModalView';
import ButtonActions from '../../components/ButtonActions'
import SnackBarSave from '../../components/SnackBarSave';
import ItemNotFound from '../../components/ItemNotFound';
import AddNewEmail from '../../components/AddNewEmail';

import Details from './details';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';
import { newInvoiceItem } from '../../helpers/invoices';

import { getCreditNote, editCreditNote, generateCreditNotePDF, duplicateCreditNote } from '../../actions/credit_notes';
import { getUsersOptions } from '../../actions/users';
import { getSelectables } from '../../actions/m_selectables';
import { getRegions } from '../../actions/m_regions';
import { getCoursePortfolios } from '../../actions/m_course_portfolio';
import { appChangesMade, resetRedux } from '../../actions/misc';
import { getInvoiceType } from '../../actions/m_invoice_type';
import { getPaymentTerms } from '../../actions/m_payment_terms';

import { creditNoteSchema } from '../../schemas/credit_note';

import { FIREBASE_STORAGE_URL } from '../../constants';

class CreditNotePage extends React.Component {

    state = {
        credit_note: false,
        openViewModal: false,
        modalType: false,
        modalContent: false,
        openSendEmail: false,
        randNum: false
    };

    componentDidMount() {
        const { credit_note_id } = this.props.match.params;
        
        // retrieve credit_note details
        this.props.dispatch(getCreditNote(credit_note_id));

        // get regions
        if ( !this.props.regionsList )
            this.props.dispatch(getRegions());

        // get selectables
        if ( !this.props.selectablesList )
            this.props.dispatch(getSelectables());

        // get all users
        if ( !this.props.usersOptions )
            this.props.dispatch(getUsersOptions());

        // get course portfolio
        if ( !this.props.coursePortfolioList )
            this.props.dispatch(getCoursePortfolios());

        // get invoice type
        if ( !this.props.invoiceTypeList )
            this.props.dispatch(getInvoiceType());

        // get payment terms
        if ( !this.props.paymentTermsList )
            this.props.dispatch(getPaymentTerms());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentCreditNote, regionsList, selectablesList, coursePortfolioList, invoiceTypeList, paymentTermsList, usersOptions, randNum } = this.props;

        // for credit_note
        if ( currentCreditNote && regionsList && selectablesList && coursePortfolioList && invoiceTypeList && paymentTermsList && usersOptions && randNum && randNum !== this.state.randNum ) {
            this.setState({ credit_note: this.getCreditNoteData( currentCreditNote ), randNum });
        } // end - currentCreditNote
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('credit_note'));
    }

    getCreditNoteData = (currentCreditNote) => {
        var credit_note = cloneCollections( currentCreditNote );

        // add empty row for products if needed
        if ( !( credit_note.products_linked && isArrayExists( credit_note.products_linked ) ) ) {
            credit_note.products_linked = [];
            credit_note.products_linked.push( newInvoiceItem(0) );
        } // end - credit_note

        return credit_note
    }

    handleSaveChanges = (event) => {
        const { authData } = this.props;
        const { credit_note } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do error check
        creditNoteSchema.forEach(schema => {
            formData[schema.id] = ( credit_note && credit_note[schema.id] ? cloneCollections( credit_note[schema.id] ) : schema.default );

            // insert credit_note_date if is empty
            if ( schema.id === 'credit_note_date' && !formData[schema.id]  ) {
                formData[schema.id] = moment().utcOffset(8).valueOf();
            } // end - schema.id

            // insert default value if is empty
            if ( schema.id === 'issued_by' && !( formData[schema.id] && !_isEmpty( formData[schema.id] ) ) ) {
                formData[schema.id] = ( authData && authData.email || '' );
            } // end - schema.id

            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editCreditNote(formData));
        } // end - error

    }

    handleActionConfirmation = () => {
        const { credit_note, modalType } = this.state;
        switch( modalType ) {
            case 'generate-pdf':
                this.props.dispatch( generateCreditNotePDF(credit_note.id) );
                break;
            case 'duplicate-credit_note':
                //this.props.dispatch( duplicateCreditNote(credit_note.id,this.props.history) );
                this.props.history.push("/credit_notes/new?action=duplicate&id="+credit_note.id);
                break;
        } // end - type
        this.setState({ openViewModal: false, modalType: false, modalContent: false });
    }

    handleButtonActions = (type) => {
        const { credit_note } = this.state;
        const { changesMade } = this.props;
        var error = false,
            formData = {};
        // if changes made - trigger error
        if ( changesMade ) {
            alert("Please save changes first before proceed");
        } else {    
            switch( type ) {
                case 'generate-pdf':
                case 'duplicate-credit_note':
                    // do update error check
                    creditNoteSchema.forEach(schema => {
                        formData[schema.id] = ( credit_note && credit_note[schema.id] ? cloneCollections( credit_note[schema.id] ) : schema.default );
                        if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                            error = 'Please fill out "' + schema.label + '" field';
                        }
                    });

                    if ( error ) {
                        triggerErrorAlert(error);
                    } else {
                        var modalMessage = '';
                        if ( type == 'generate-pdf' ) {
                            modalMessage = "You're about to export this credit note to PDF. Click CONFIRM to continue";
                        } else if ( type == 'duplicate-credit_note' ) {
                            modalMessage = "You're about to duplicate this credit note. Click CONFIRM to continue";
                        }
                        this.setState({ openViewModal: true, modalType: type, modalContent: modalMessage });
                    } // end - error
                    break;
                case 'sendemail':
                    this.setState({ openSendEmail: true });
                    break;
            } // end - type
        } // end - changesMade

    }

    handleFormUpdate = (newValue) => {
        this.setState({ credit_note: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    getActions = () => {
        const { authData } = this.props;
        const { credit_note } = this.state;
        let actions = [];

        if ( credit_note && credit_note.pdf_id && !_isEmpty( credit_note.pdf_id ) ) {
            actions.push( { id: 'download-pdf', label: 'View PDF', icon: 'fa-download', onClick: () => {
                let link = FIREBASE_STORAGE_URL + 'credit_notes%2F' + credit_note.pdf_id + '_' + credit_note.id + '.pdf?alt=media';
                window.open(link, '_blank');
            }} );
        } // end - credit_note

        // if pdf already exists - only those has the modify access can modify it
        if ( credit_note && credit_note.pdf_id && !_isEmpty( credit_note.pdf_id ) ) {
            if ( hasAccessRights( authData, ['cnm'] ) )
                actions.push( { id: 'generate-pdf', label: 'Export Credit Note To PDF', icon: 'fa-file-pdf-o', onClick: this.handleButtonActions.bind(this,'generate-pdf') } );
        } else {
            actions.push( { id: 'generate-pdf', label: 'Export Credit Note To PDF', icon: 'fa-file-pdf-o', onClick: this.handleButtonActions.bind(this,'generate-pdf') } );
        } // end - credit_note
        
        // disabled if credit_note wasn't in PDF format yet
        // if ( credit_note && credit_note.pdf_id && !_isEmpty( credit_note.pdf_id ) ) {
        //     actions.push( { id: 'sendemail', label: 'Send email with Credit Note PDF', icon: 'fa-envelope', disabled: ( authData && hasAccessRights(authData, ['cns']) ? false : true ), onClick: this.handleButtonActions.bind(this,'sendemail') } );
        // } // end - credit_note
        
        actions.push( { id: 'duplicate-credit_note', label: 'Duplicate Credit Note', icon: 'fa-clone', onClick: this.handleButtonActions.bind(this,'duplicate-credit_note') } );

        return actions;
    }

    renderActionsButton = () => {
        return (
        <div style={{ textAlign: 'right', paddingTop: '15px' }}>
            <ButtonActions 
                key="actions" 
                label="Actions"
                color="inverse"
                menuContainerStyle={{ width: "300px" }}
                style={{ padding: '15px 45px' }}
                actions={this.getActions()} />
        </div>
        );
    }

    renderdetails = () => {
        const { credit_note_id } = this.props.match.params;
        const { authData, usersOptions, regionsList, selectablesList, coursePortfolioList, invoiceTypeList, paymentTermsList, changesMade } = this.props;
        const { credit_note } = this.state;
        return <Details 
                authData={( authData || false )}
                credit_note={( credit_note || false )}
                regions={( regionsList || [] )}
                course_portfolio={( coursePortfolioList || [] )}
                selectables={( selectablesList || [] )}
                invoice_type={( invoiceTypeList || [] )}
                payment_terms={( paymentTermsList || [] )}
                changesMade={( changesMade || false )}
                onFormUpdate={this.handleFormUpdate}
                updateActions={[getCreditNote(credit_note_id)]}
                users={( usersOptions || [] )} />;
    }

    renderContents() {
        const { authData, usersOptions } = this.props;
        const { credit_note, openViewModal, modalType, modalContent, openSendEmail } = this.state;
        return (
            <div>

            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                {this.renderdetails()}
            </Paper>

            <Grid container style={{ marginTop: "45px" }}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
                </Grid>
                {/* <Grid item xs={6} style={{ textAlign: 'right' }}>
                    {this.renderActionsButton()}
                </Grid> */}
            </Grid>

            <SnackBarSave onSave={this.handleSaveChanges} />

            <ModalView
                open={openViewModal}
                title={"Please Confirm Your Action"}
                actionLabel="Confirm"
                maxWidth="sm"
                onClose={() => this.setState({ openViewModal: false, modalType: false, modalContent: false })}
                doAction={this.handleActionConfirmation}
                contents={(<div>{ modalContent && !_isEmpty( modalContent ) ? <Typography variant="body1">{modalContent}</Typography> : '' }</div>)} />

            {/* { authData && hasAccessRights(authData, ['cns']) ? <AddNewEmail
                authData={authData}
                open={openSendEmail}
                users={( usersOptions || [] )}
                predefined_accounts={( credit_note && credit_note.end_user_company_linked && isArrayExists( credit_note.end_user_company_linked ) ? credit_note.end_user_company_linked : false )}
                predefined_contacts={( credit_note && credit_note.contacts_linked && isArrayExists( credit_note.contacts_linked ) ? credit_note.contacts_linked : false )}
                predefined_credit_notes={( credit_note && credit_note.id && !_isEmpty( credit_note.id ) ? [ credit_note ] : false )}
                onClose={() => this.setState({ openSendEmail: false })} /> : null } */}

        </div>
        );
    }

    render() {
        const { randNum } = this.state;
        const { currentCreditNote } = this.props;
        return <AppWrapper 
                title="Edit Credit Note"
                subtitle="Credit Notes"
                back="/credit_notes"
                breadcrumbs={[
                    { url: '/credit_notes', label: 'Credit Notes' },
                    { label: 'Edit' }
                ]}
                customColumn={( randNum && currentCreditNote && currentCreditNote.id && !_isEmpty( currentCreditNote.id ) ? this.renderActionsButton() : null )}
                onLoad={( !( randNum ) ? true : false )}
                contents={ currentCreditNote && currentCreditNote.id && !_isEmpty( currentCreditNote.id ) ? this.renderContents() : <ItemNotFound item="Credit Note" />} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentCreditNote: state.credit_notes && state.credit_notes.credit_note || null,
        randNum: state.credit_notes && state.credit_notes.rand || null,
        usersOptions: state.users && state.users.users_options || null,
        selectablesList: state.maintenance && state.maintenance.selectables || null,
        regionsList: state.maintenance && state.maintenance.regions || null,
        coursePortfolioList: state.maintenance && state.maintenance.course_portfolio || null,
        invoiceTypeList: state.maintenance && state.maintenance.invoice_type || null,
        paymentTermsList: state.maintenance && state.maintenance.payment_terms || null,
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(CreditNotePage);