/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from "@material-ui/core/styles";
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _random from 'lodash/random';
import _isEqual from 'lodash/isEqual';

import ModalView from '../ModalView';
import DotsLoader from '../DotsLoader';
import Table from '../Table';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { formatMoney } from '../../helpers/number';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { FormBox } from '../../styles/form';

import { accountSchema } from '../../schemas/account';

import { mergeAccounts } from '../../actions/accounts';

const useStyles = theme => ({
    checkbox: {
        padding: '0px 9px 9px 9px'
    },
    closeBtn: {
        position: 'absolute',
        top: '0',
        right: '0',
        padding: '5px 8px',
        borderRadius: '0px'
    }
});

class ModalMergeAccounts extends React.Component {

    state = {
        account: {},
        main: false
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { open } = this.props;

        if ( !prevProps.open && open ) {
            this.setState({ account: this.getInitialValues(), main: this.getInitialMain() });
        } // end - open
            
    }

    handleMerge = () => {
        const { selected, query } = this.props;
        const { account, main } = this.state;
        const formData = this.compileFormData();
        const list = this.compileSelectedList();
        let error = false;

        if ( _isEmpty( formData ) )
            error = 'Please make at least one changes';

        if ( !( list && isArrayExists( list ) && _size( list ) > 1 ) )
            error = 'Please select at least 2 accounts.';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(mergeAccounts(list,formData,query));
        } // end - error
    }

    handleCheckboxUpdate = ({ id, field }) => event => {
        const { account } = this.state;
        let newAccount = ( account ? cloneCollections( account) : {} );
        if ( event.target.checked && id && field ) {
            newAccount[field] = id;
        }
        this.setState({ account: newAccount });
    }

    handleHeadCheckboxUpdate = (id) => event => {
        const { account, main } = this.state;
        if ( id ) {
            let newAccount = ( account ? cloneCollections( account) : {} );
            _forEach( accountSchema, schema => {
                if ( schema.merge && ( schema.id !== 'account_tier_1_id' && schema.id !== 'account_tier_2_id' ) ) {
                    newAccount[schema.id] = id;
                } // end - schema.merge
            });
            this.setState({ main: id, account: newAccount });
        }
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose ) {
            this.setState({ account: {} });
            onClose();
        }
    }

    compileSelectedList = () => {
        const { selected } = this.props;
        const { main } = this.state;
        const list = [];
        if ( selected &&  isArrayExists( selected ) ) {
            _forEach( selected, item => {
                list.push({ id: item.id , type: ( item.id && main && main === item.id ? 'main' : 'sub' ) });
            });
        } // end - selected
        return list;
    }

    compileFormData = () => {
        const { selected } = this.props;
        const { account } = this.state;
        let formData = {};
        _forEach( accountSchema, schema => {
            if ( schema.merge ) {
                let selectedAccount = false;
                switch( schema.id ) {
                    case 'account_tier_1_id':
                        selectedAccount = ( account && account['account_tier_1'] && !_isEmpty( account['account_tier_1'] ) ? _find( selected, { id: account['account_tier_1'] }) : false );
                        formData[schema.id] = ( selectedAccount && selectedAccount[schema.id] ? selectedAccount[schema.id] : ( schema.default || '' ) );
                        break;
                    case 'account_tier_2_id':
                        selectedAccount = ( account && account['account_tier_2'] && !_isEmpty( account['account_tier_2'] ) ? _find( selected, { id: account['account_tier_2'] }) : false );
                        formData[schema.id] = ( selectedAccount && selectedAccount[schema.id] ? selectedAccount[schema.id] : ( schema.default || '' ) );
                        break;
                    case 'mailing_lists':
                        selectedAccount = ( account && account[schema.id] && !_isEmpty( account[schema.id] ) ? _find( selected, { id: account[schema.id] }) : false );
                        formData[schema.id] = ( selectedAccount && selectedAccount[schema.id] ? selectedAccount[schema.id] : ( schema.default || [] ) );

                        // add mailing_lists_trigger
                        formData.mailing_lists_trigger = [];
                        // add list
                        if ( formData[schema.id] && isArrayExists( formData[schema.id] ) ) {
                            _forEach( formData[schema.id], (list) => {
                                if ( list && list.id && !_isEmpty( list.id ) ) {
                                    formData.mailing_lists_trigger.push({
                                        id: list.id, 
                                        name: ( list.name || '' ),
                                        type: 'add'
                                    });
                                } // end - list
                            });
                        } // end - formData[schema.id]

                        // remove list
                        _forEach( selected, acct => {
                            if ( acct.mailing_lists && isArrayExists( acct.mailing_lists ) ) {
                                _forEach( acct.mailing_lists, list => {
                                    if ( !_find( formData.mailing_lists_trigger, { id: list.id } ) ) {
                                        formData.mailing_lists_trigger.push({
                                            id: list.id, 
                                            name: ( list.name || '' ),
                                            type: 'remove'
                                        });
                                    } // end - formData.mailing_lists_trigger
                                });
                            } // end - acct.mailing_lists
                        });

                        break;
                    default:
                        selectedAccount = ( account && account[schema.id] && !_isEmpty( account[schema.id] ) ? _find( selected, { id: account[schema.id] }) : false );
                        formData[schema.id] = ( selectedAccount && selectedAccount[schema.id] ? selectedAccount[schema.id] : ( schema.default || '' ) );
                        break;
                } // end - schema
            } // end - schema.merge
        });
        return formData;
    }

    getInitialValues = () => {
        const { selected } = this.props;
        let account = {},
            firstID = ( selected && selected[0] && selected[0].id && !_isEmpty( selected[0].id ) ? selected[0].id : false );
        if ( firstID ) {
            _forEach( accountSchema, schema => {
                if ( schema.merge && ( schema.id !== 'account_tier_1_id' && schema.id !== 'account_tier_2_id' ) ) {
                    account[schema.id] = firstID;
                } // end - schema.merge
            });
        } // end - firstID
        return account;
    }

    getInitialMain = () => {
        const { selected } = this.props;
        return ( selected && selected[0] && selected[0].id && !_isEmpty( selected[0].id ) ? selected[0].id : false )
    }

    getCells = () => {
        const { selected } = this.props;
        const cells = [{ id: 'field', label: 'Fields', style: { width: '20%' }, render: (item) => ( item.field || '' ) }];
        if ( selected && isArrayExists( selected ) ) {
            const totalRecords = _size(selected);
            const widthSize = (80/totalRecords);
            for (let index = 1; index <= totalRecords; index++) { 
                cells.push({ id: 'record_'+index, label: 'Record #'+index + ' ('+( selected && selected[index-1] && selected[index-1].id ? selected[index-1].id : '' )+')', style: { width: widthSize+'%' }, render: this.renderCell( index ), renderHead: this.renderHeadCell(index) });
            }
        } // end - selected
        return cells;
    }

    getValue = (schema,index,val) => {
        switch (schema.id) {
            case 'assigned_to':
            case 'mailing_lists':
                let value = '';
                if ( val && val[schema.id] && isArrayExists( val[schema.id] ) ) {
                    _forEach( val[schema.id], item => {
                        if ( item.name && !_isEmpty( item.name ) ) {
                            value += ( !_isEmpty( value ) ? ', ' : '' ) + item.name;
                        }
                    });
                } // end - val[schema.id]
                return value;
            default:
                return ( val && val[schema.id] || '' );
        }
    }

    getItems = () => {
        const { selected } = this.props;
        const { account } = this.state;
        const items = [];
        const totalRecords = _size(selected);
        _forEach( accountSchema, schema => {
            if ( schema.merge && ( schema.id !== 'account_tier_1_id' && schema.id !== 'account_tier_2_id' ) ) {
                let item = { id: schema.id, field: ( schema.label || '' ) };
                for (let index = 1; index <= totalRecords; index++) { 
                    let val = ( selected && selected[index-1] || false );
                    item['record_'+index] = this.getValue(schema,index,val);
                }
                items.push(item)
            } // end - schema.merge
        });
        return items;
    }

    isAllChecked = (id) => {
        const { account } = this.state;
        let checked = true;
        _forEach( accountSchema, schema => {
            if ( schema.merge && ( schema.id !== 'account_tier_1_id' && schema.id !== 'account_tier_2_id' ) ) {
                if ( account[schema.id] !== id )
                    checked = false;
            } // end - schema.merge
        });
        return checked;
    }

    renderHeadCell = (index) => (cell) => {
        const { selected, classes } = this.props;
        const { account, main } = this.state;
        const selectedAccountID = ( selected && selected[index-1] && selected[index-1].id && !_isEmpty( selected[index-1].id ) ? selected[index-1].id : false );
        return (
        <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs={1}>
                <Checkbox
                    className={classes.checkbox}
                    checked={( selectedAccountID && main && main === selectedAccountID ? true : false )}
                    onChange={this.handleHeadCheckboxUpdate(selectedAccountID)}
                    color="primary" />
            </Grid>
            <Grid item xs={11}>
                {( cell.label || '' )}
            </Grid>
        </Grid>
        );
    }

    renderCell = (index) => (item) => {
        const { selected, classes } = this.props;
        const { account } = this.state;
        const selectedAccountID = ( selected && selected[index-1] && selected[index-1].id && !_isEmpty( selected[index-1].id ) ? selected[index-1].id : false );
        return (
        <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs={1}>
                <Checkbox
                    className={classes.checkbox}
                    checked={( account && account[item.id] && account[item.id] === selectedAccountID ? true : false )}
                    onChange={this.handleCheckboxUpdate({ id: selectedAccountID, field: item.id })}
                    color="primary" />
            </Grid>
            <Grid item xs={11}>
                {( item && item['record_'+index] && !_isEmpty( item['record_'+index] ) ? item['record_'+index] : '' )}
            </Grid>
        </Grid>
        );
    }

    renderTable = () => {
        const { authData, selected, coursePortfolioList, accountsTier2List } = this.props;
        const { account } = this.state;
        return <Table 
                items={this.getItems()}
                showCheckbox={false}
                cells={this.getCells()}
                 />;
    }


    renderModal = () => {
        const { selected, classes } = this.props;
        return (
        <div>
            {this.renderTable()} 
            <InverseButton className={classes.closeBtn} noIconMargin="yes" size="small" minWidth="0px" onClick={this.handleClose}><i className="fa fa-times"></i></InverseButton>           
        </div>
        )
    }

    render() {
        const { open } = this.props;
        return <ModalView 
            open={open}
            title="Edit Selected Account(s)"
            actionLabel="Merge"
            maxWidth="lg"
            cancelLabel="Close"
            disableBackdrop={true}
            onClose={this.handleClose}
            doAction={this.handleMerge}
            contents={ open ? this.renderModal() : null } />
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        coursePortfolioList: state.maintenance && state.maintenance.course_portfolio || null,
        accountsTier2List: state.maintenance && state.maintenance.accounts_tier_2 || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles)
)(ModalMergeAccounts);