/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AppWrapper from '../../components/AppWrapper';

class AuthProfilePage extends React.Component {

    state = {
        randNum: true //*incomplete
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    renderContents() {
        return (
        <div></div>
        );
    }

    render() {
        const { randNum } = this.state;
        return <AppWrapper 
                title="Edit Profile"
                subtitle="Account Settings"
                onLoad={( !( randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        sample: state.sample && state.sample.sample || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(AuthProfilePage);