/* eslint-disable */
import * as firebase from 'firebase/app';
import shortid from 'shortid';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _size from 'lodash/size';
import _snakeCase from 'lodash/snakeCase';

import { isArrayExists } from './validation';

export const getDataFromDoc = ( type, id ) => {
    return new Promise((resolve,reject) => {

        firebase.firestore().collection(type).doc(id).get()
        .then( doc => {
            var val = ( doc.exists ? doc.data() : false );
            resolve(val);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const getDataFromCollections = ( query ) => {
    return new Promise((resolve,reject) => {

        

    })
}

export const getDataFromDatabase = ( type, id ) => {
    return new Promise((resolve,reject) => {

        let path = type + ( id && !_isEmpty( id ) ? '/'+id : '' );

        firebase.database().ref(path).once("value")
        .then( snapshot => {
            if ( snapshot && snapshot.exists() ) {
                resolve( snapshot );
            } else {
                resolve( false );
            } // end - snapshot
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const uploadMediaToStorage = ( file, path, user ) => {
    return new Promise((resolve,reject) => {

        const storageRef = firebase.storage().ref();

        var file_id = shortid.generate(),
            filePath = ( path && !_isEmpty( path ) ? path : 'uploads' )+'/' + file_id + '_' + _snakeCase( file.name ),
            metaData = {
                uid: user.uid,
                contentType: ( file.type || '' ),
                region: ( user.region || '' )
            };

        const uploadTask = storageRef.child(filePath).put(file, metaData);

        uploadTask.on('state_changed', function(snapshot){
            // Observe state change events such as progress, pause, and resume
        }, function(error) {
            reject(error);
        }, function() {
            // Handle successful uploads on complete
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                resolve(downloadURL);             
            });
        });

    })
}