/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import DotsLoader from '../../components/DotsLoader';
import ListUpdates from '../../components/ListUpdates';

import { isArrayExists } from '../../helpers/validation';

import { getLatestUpdates } from '../../actions/updates';

const WidgetWrapper = styled.div`
    height: 250px;
    overflow-y: scroll;
    background: #f1f1f1;
`;

class WidgetLatestUpdates extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {
        const { latestUpdates, authData, randNum } = this.props;

        if ( latestUpdates && randNum ) {
            this.setState({ randNum });
        } else {
            this.props.dispatch(getLatestUpdates(50,authData));
        } // end - latestUpdates
            
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { latestUpdates, randNum } = this.props;
        if ( latestUpdates && randNum && randNum != this.state.randNum ) {
            this.setState({ randNum });
        } // end - latestUpdates
    }

    renderList = () => {
        const { latestUpdates } = this.props;
        return (
        <WidgetWrapper>

            <ListUpdates
                showActionTarget={true}
                list={( latestUpdates && isArrayExists( latestUpdates ) ? latestUpdates : [] )} />

        </WidgetWrapper>
        )
    }

    render() {
        const { randNum } = this.state;
        return (
        <Paper elevation={2} style={{ padding: '30px', background: '#fff' }}>
            <Typography variant="h5" style={{ marginBottom: "20px" }}>Latest Update(s)</Typography>
            { randNum ? this.renderList() : <DotsLoader /> }
        </Paper>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        latestUpdates: state.updates && state.updates.latest || null,
        randNum: state.updates && state.updates.rand || null,
    }
}

export default compose(
    connect(mapStateToProps)
)(WidgetLatestUpdates);