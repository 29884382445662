/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import GrapesJS from 'grapesjs';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _random from 'lodash/random';
import _snakeCase from 'lodash/snakeCase';
import _toLower from 'lodash/toLower';
import _last from 'lodash/last';

import Form from "./form";
import Editor from '../../pages/EmailTemplatePage/editor_mce';

import ModalView from '../ModalView';
import MediaLibrary from '../MediaLibrary';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';
import FormSelect2 from '../FormSelect2';
import FormMultiSelect from '../FormMultiSelect';
import ModalSelectEmailTemplate from '../ModalSelectEmailTemplate';

import { isArrayExists, validateEmail, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, getSelectValues, combineStrings } from '../../helpers/data';
import { getSchemaDefaultValue, doSchemaErrorCheck } from '../../helpers/schemas'

import { InverseButton } from '../../styles/button';

import { addNewEmail } from '../../actions/emails';

import { emailSchema } from '../../schemas/email';

import { defaultEmailTemplate, FIREBASE_STORAGE_URL } from '../../constants';

const useStyles = theme => ({
    closeBtn: {
        position: 'absolute',
        top: '0',
        right: '0',
        padding: '5px 8px',
        borderRadius: '0px'
    }
});

class AddNewEmail extends React.Component {

    state = {
        email: getSchemaDefaultValue( emailSchema, 'add' ),
        randNum: false,
        openEmailTemplateModal: false,
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { open } = this.props;
        if ( open && !prevProps.open ) {
            this.setState({ email: this.getInitialValues(), randNum: _random(1,9999) }); // reset 
        }
    }

    getInitialValues = () => {
        const { 
            authData, 
            predefined_accounts, 
            predefined_contacts,
            predefined_potentials,
            predefined_quotes,
            predefined_invoices
        } = this.props;
        let email = getSchemaDefaultValue( emailSchema, 'add' );

        // add authData into from
        if ( authData ) {
            email.from = ( authData.email || '' );
            email.from_label = ( authData.name || '' );
        }

        // add template default content
        email.contents_html = ( defaultEmailTemplate && defaultEmailTemplate.html ? defaultEmailTemplate.html : '' );
        email.contents_css = ( defaultEmailTemplate && defaultEmailTemplate.css ? defaultEmailTemplate.css : '' );
        email.contents_js = ( defaultEmailTemplate && defaultEmailTemplate.js ? defaultEmailTemplate.js : '' );

        // overwrite emails if there are predefined data
        if ( 
            ( predefined_accounts && isArrayExists( predefined_accounts ) ) || 
            ( predefined_contacts && isArrayExists( predefined_contacts ) ) || 
            ( predefined_potentials && isArrayExists( predefined_potentials ) ) || 
            ( predefined_quotes && isArrayExists( predefined_quotes ) ) || 
            ( predefined_invoices && isArrayExists( predefined_invoices ) )
            ) {
            email = this.addPredefinedData(email);
        } // end - predefined_account

        return email;
    }

    addPredefinedData = (email) => {
        const { 
            predefined_accounts, 
            predefined_contacts,
            predefined_potentials,
            predefined_quotes,
            predefined_invoices,
            email_type
        } = this.props;
        const emails = [],
            attachments = [],
            accounts_linked = [],
            contacts_linked = [],
            potentials_linked = [],
            quotes_linked = [],
            invoices_linked = [],
            products_linked = [];

        // run through predefined_invoices
        if ( predefined_invoices && isArrayExists( predefined_invoices ) ) {
            _forEach( predefined_invoices, invoice => {
                // add email ( if invoice has email assigned )
                if ( invoice.email && validateEmail( invoice.email ) ) {
                    if ( !_find( emails, { id: invoice.email }) )
                        emails.push({ 
                            id: _snakeCase( invoice.email ),
                            email: invoice.email,
                            type: ( email_type && ( email_type === 'to' || email_type === 'cc' || email_type === 'bcc' ) ? email_type : 'to' ),
                            source: 'invoice',
                            source_id: invoice.id
                        });
                } // end - contact.email

                // add invoices_linked
                invoices_linked.push({
                    id: invoice.id,
                    name: ( invoice.name || '' ),
                    email: invoice.email || '',
                });

                // add invoice PDF as attachment
                if ( invoice && invoice.pdf_id && !_isEmpty( invoice.pdf_id ) ) {
                    attachments.push({
                        id: invoice.pdf_id,
                        name: invoice.id+'.pdf',
                        path: "invoices/" + invoice.pdf_id + '_' + invoice.id + '.pdf',
                        type: 'application/pdf',
                        url: FIREBASE_STORAGE_URL + 'invoices%2F' + invoice.pdf_id + '_' + invoice.id + '.pdf?alt=media'
                    })
                } // end - invoice

                // add products_linked if available
                if ( invoice.products_linked && isArrayExists( invoice.products_linked ) ) {
                    _forEach( invoice.products_linked, product => {
                        products_linked.push( product );
                    });
                } // end - invoice.products_linked
 
            });
        } // end - predefined_invoices

        // run through predefined_quotes
        if ( predefined_quotes && isArrayExists( predefined_quotes ) ) {
            _forEach( predefined_quotes, quote => {
                // add email ( if quote has email assigned )
                if ( quote.email && validateEmail( quote.email ) ) {
                    if ( !_find( emails, { id: quote.email }) )
                        emails.push({ 
                            id: _snakeCase( quote.email ),
                            email: quote.email,
                            type: ( email_type && ( email_type === 'to' || email_type === 'cc' || email_type === 'bcc' ) ? email_type : 'to' ),
                            source: 'quote',
                            source_id: quote.id
                        });
                } // end - contact.email

                // add quotes_linked
                quotes_linked.push({
                    id: quote.id,
                    name: ( quote.name || '' ),
                    email: quote.email || '',
                });

                // add quote PDF as attachment
                if ( quote && quote.pdf_id && !_isEmpty( quote.pdf_id ) ) {
                    attachments.push({
                        id: quote.pdf_id,
                        name: quote.id+'.pdf',
                        path: "quotes/" + quote.pdf_id + '_' + quote.id + '.pdf',
                        type: 'application/pdf',
                        url: FIREBASE_STORAGE_URL + 'quotes%2F' + quote.pdf_id + '_' + quote.id + '.pdf?alt=media'
                    })
                } // end - quote

                // add products_linked if available
                if ( quote.products_linked && isArrayExists( quote.products_linked ) ) {
                    _forEach( quote.products_linked, product => {
                        products_linked.push( product );
                    });
                } // end - quote.products_linked

            });
        } // end - predefined_quotes

        // run through predefined_potentials
        if ( predefined_potentials && isArrayExists( predefined_potentials ) ) {
            _forEach( predefined_potentials, potential => {
                // add email ( if quote has email assigned )
                if ( potential.email && validateEmail( potential.email ) ) {
                    if ( !_find( emails, { id: potential.email }) )
                        emails.push({ 
                            id: _snakeCase( potential.email ),
                            email: potential.email,
                            type: ( email_type && ( email_type === 'to' || email_type === 'cc' || email_type === 'bcc' ) ? email_type : 'to' ),
                            source: 'potential',
                            source_id: potential.id
                        });
                } // end - contact.email

                // add quotes_linked
                potentials_linked.push({
                    id: potential.id,
                    name: ( potential.name || '' ),
                    email: potential.email || '',
                });

                // add products_linked if available
                if ( potential.product_id && !_isEmpty( potential.product_id ) ) {
                    products_linked.push({
                        id: potential.product_id,
                        name: ( potential.product_label && !_isEmpty( potential.product_label ) ? potential.product_label : '' ),
                        product_id: potential.product_id
                    });
                } // end - potential.product_id

            });
        } // end - predefined_potentials

        //  - then use account's email
        const canUseContactEmails = ( isArrayExists( emails ) ? false : true );

        // run through predefined_contacts 
        if ( predefined_contacts && isArrayExists( predefined_contacts ) ) {
            _forEach( predefined_contacts, contact => {
                // add email ( if no emails added yet )
                if ( canUseContactEmails && contact.email && validateEmail( contact.email ) ) {
                    if ( !_find( emails, { id: contact.email }) )
                        emails.push({ 
                            id: _snakeCase( contact.email ),
                            email: contact.email,
                            type: ( email_type && ( email_type === 'to' || email_type === 'cc' || email_type === 'bcc' ) ? email_type : 'to' ),
                            source: 'contact',
                            source_id: contact.id
                        });
                } // end - contact.email

                // add contacts_linked
                contacts_linked.push({
                    id: contact.id,
                    name: ( contact.designation || contact.first_name || contact.last_name ? combineStrings( contact.designation, contact.first_name, contact.last_name ) : '' ),
                    email: contact.email || '',
                    account_id: contact.account_id || ''
                });

            });
        } // end - predefined_contacts

        // if no emails added yet - then use account's email
        const canUseAccountEmails = ( isArrayExists( emails ) ? false : true );
        
        // run through predefined_accounts 
        if ( predefined_accounts && isArrayExists( predefined_accounts ) ) {
            _forEach( predefined_accounts, account => {

                // add email if emails is empty
                if ( canUseAccountEmails && account.email && validateEmail( account.email ) ) {
                    if ( !_find( emails, { id: account.email }) )
                        emails.push({ 
                            id: _snakeCase( account.email ),
                            email: account.email,
                            type: ( email_type && ( email_type === 'to' || email_type === 'cc' || email_type === 'bcc' ) ? email_type : 'to' ),
                            source: 'account',
                            source_id: account.id
                        });
                } // end - account.email

                // add accounts_linked
                let account_linked = {
                    id: account.id,
                    name: account.name || '',
                    // email: account.email || '',
                    // lms_linked: account.lms_linked || '',
                    // account_tier_1_id: account.account_tier_1_id || '',
                    sort_name: ( account.name && !_isEmpty( account.name ) ? _toLower( account.name ) : '' ),
                    assigned_to: {}
                };

                // add assigned_to
                if ( account.assigned_to && ( isArrayExists( account.assigned_to ) || isObjectExists( account.assigned_to) ) ) {
                    _forEach( account.assigned_to, assigned => {
                        if ( assigned && assigned.id && !_isEmpty( assigned.id ) ) {
                            account_linked.assigned_to[_snakeCase( assigned.id )] = {
                                id: assigned.id,
                                name: ( assigned.name || '' )
                            };
                        } // end - assigned.id
                    });
                } // end - account.assigned_to

                accounts_linked.push(account_linked);
                
            });
        } // end - predefined_accounts

        email.emails = emails;
        email.accounts_linked = accounts_linked;
        email.contacts_linked = contacts_linked;
        email.potentials_linked = potentials_linked;
        email.quotes_linked = quotes_linked;
        email.invoices_linked = invoices_linked;
        email.products_linked = products_linked;
        email.attachments = attachments;

        return email;
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose ) {
            this.setState({ email: this.getInitialValues(), randNum: false }); // reset 
            onClose();
        } // end - onClose
    }

    handleFormUpdate = (newValue,key) => {
        const { email } = this.state;
        var newData = ( email ? cloneCollections( email ) : {} );
        newData[key] = newValue;

        this.setState({ email: newData });
    }

    handleAddNew = (status) => {
        const { history, type, typeID } = this.props;
        const { email } = this.state;
        // const editor = ( GrapesJS.editors && isArrayExists( GrapesJS.editors ) ? _last( GrapesJS.editors ) : false );
        let error = false,
            formData = {};

        // do error check
        emailSchema.forEach(schema => {
            switch( schema.id ) {
                case 'status':
                    formData[schema.id] = ( status && !_isEmpty( status ) ? status : schema.default );
                    break;
                default:
                    formData[schema.id] = ( email && email[schema.id] ? cloneCollections( email[schema.id] ) : schema.default );
                    if ( !doSchemaErrorCheck( formData[schema.id], schema, 'add' ) ) {
                        error = 'Please fill out "' + schema.label + '" field';
                    }
                    break;
            } // end - schema.id
        });

        // make sure there is a email inserted in to field
        if ( ! ( formData.emails && _find( formData.emails, { type: 'to' } ) ) )
            error = 'Please fill out "To" field';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            // compile editor contents (no longer needed)
            // if ( editor ) {
            //     formData['contents_html'] = editor.getHtml();
            //     formData['contents_css'] = editor.getCss();
            //     formData['contents_js'] = editor.getJs();
            // } // end - editor

            // compile editor for tinymce
            formData['contents_html'] = ( email && email.contents_html && !_isEmpty( email.contents_html ) ? email.contents_html : '' );
            formData['contents_css'] = ( email && email.contents_css && !_isEmpty( email.contents_css ) ? email.contents_css : '' );
            formData['contents_js'] = ( email && email.contents_js && !_isEmpty( email.contents_js ) ? email.contents_js : '' );

            // trigger new email
            this.props.dispatch(addNewEmail(formData,type,typeID));
        } // end - error
    }

    handleEmailTemplateSelect = (template) => {
        const { email } = this.state;
        let newData = ( email ? cloneCollections( email ) : {} );

        // import template subject line
        newData.subject = ( template && template.subject && !_isEmpty( template.subject ) ? template.subject : '' );

        // import template content
        newData.contents_html = ( template && template.contents_html && !_isEmpty( template.contents_html ) ? template.contents_html : '' );
        newData.contents_css = ( template && template.contents_css && !_isEmpty( template.contents_css ) ? template.contents_css : '' );
        newData.contents_js = ( template && template.contents_js && !_isEmpty( template.contents_js ) ? template.contents_js : '' );

        this.setState({ 
            email: newData, 
            openEmailTemplateModal: false,
            randNum: _random(1,9999)
        });
    }

    renderEditor = () => {
        const { email, randNum } = this.state;
        return <Editor 
            template={( email || null )}
            noChangesTrigger={true}
            randNum={( randNum || false )}
            onEditorUpdate={this.handleFormUpdate} />
    }

    renderForm = () => {
        const { authData } = this.props;
        const { email } = this.state;
        return <Form
            authData={( authData || false )}
            email={( email || null )}
            onTemplateTriggered={() => this.setState({ openEmailTemplateModal: true })}
            onFormUpdate={newData => this.setState({ email: newData })}
            onAddNew={this.handleAddNew} />
    }

    renderComposeContainer = () => {
        const { email, openEmailTemplateModal } = this.state;
        const { classes } = this.props;
        return (
        <div>
            {this.renderForm()}

            <div style={{ marginTop: "45px" }}>
                <div style={{ marginBottom: "20px" }}>
                    <MediaLibrary
                        attachable={true}
                        attachments={( email && email.attachments ? email.attachments : [] )}
                        onAttachmentUpdate={(medias) => this.handleFormUpdate(medias,'attachments')} />
                </div>
                {this.renderEditor()}
            </div>

            <InverseButton className={classes.closeBtn} noIconMargin="yes" size="small" minWidth="0px" onClick={this.handleClose}><i className="fa fa-times"></i></InverseButton>

            <ModalSelectEmailTemplate
                open={( openEmailTemplateModal || false )}
                email={( email || false )}
                onClose={() => this.setState({ openEmailTemplateModal: false })}
                onSelect={this.handleEmailTemplateSelect} />
        </div>
        );
    }

    render() {
        const { open } = this.props;
        return (
        <div>

            <ModalView 
                open={open}
                title="Compose Email"
                maxWidth="lg"
                noAction={true}
                cancelLabel="Close"
                disableBackdrop={true}
                disableEnforceFocus={true}
                onClose={this.handleClose}
                contents={ open ? this.renderComposeContainer() : null } />

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(AddNewEmail);