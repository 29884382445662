/* eslint-disable */
import * as firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { getItemsFromFirestore, getTotalCountBySchema } from '../../helpers/firestore';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getTestQueries = (props) => {
    return new Promise((resolve,reject) => {

        var promises = [];
        promises.push( getItemsFromFirestore( "invoices", ( props && isObjectExists( props ) ? props : null ) ) );

        // if no keywords filter
        if ( !( props && props.searchterms && !_isEmpty( props.searchterms ) ) ) {
            promises.push( getTotalCountBySchema('invoices') );
        } // end - props.searchterms

        Promise.all( promises )
        .then(results => {
            resolve({ 
                list: ( results && results[0] && results[0].list && isArrayExists( results[0].list ) ? results[0].list : [] ), 
                firstDoc: ( results && results[0] && results[0].firstDoc ? results[0].firstDoc : false ),
                lastDoc: ( results && results[0] && results[0].lastDoc ? results[0].lastDoc : false ), 
                total: ( results && results[1] ? results[1] : 0 )
            });
        })
        .catch(error => {
            console.log(error);
            reject(error);
        });

    })
}