import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import _find from 'lodash/find';

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import List from './list';

import { SuccessButton } from '../../styles/button';

import { isArrayExists } from '../../helpers/validation';

import { getSelectables, syncDataWithLMS } from '../../actions/m_selectables';

class MaintenanceSelectables extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {

        // get selectables
        this.props.dispatch(getSelectables());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectablesList, randNum } = this.props;

        if ( selectablesList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    handleDataSync = (event) => {
        event.preventDefault();
        this.props.dispatch(syncDataWithLMS())
    }

    renderCustomColumn = () => {
        return (
        <div style={{ textAlign: 'right' }}>
            <SuccessButton minWidth="168px" key="sync" style={{ marginRight: "5px", padding: "10px 45px" }} onClick={this.handleDataSync}><i className="fa fa-refresh"></i>Sycn with LMS</SuccessButton>
        </div>
        )
    }

    renderList = (title,id) => {
        const { selectablesList } = this.props;
        let selected = ( selectablesList && isArrayExists( selectablesList ) ? _find( selectablesList, { id } ) : false );
        return (
        <div style={{ marginBottom: '30px', padding: '30px 40px', border: '1px solid #999' }}>
            <List title={title} list={( selected && selected.options && isArrayExists( selected.options ) ? selected.options : [] )} />
        </div>
        )
    }

    renderContents() {
        return (
        <div>

            {this.renderList('Base Unit','base_unit')}
            {this.renderList('Calendar Pricing Summary','calendar_pricing_summary')}
            {this.renderList('Course Duration','course_duration')}
            {this.renderList('Currency','currency')}
            {this.renderList('Delivery Method','delivery_method')}
            {this.renderList('Designation','designation')}
            {this.renderList('Finance Status','finance_status')}
            {this.renderList('IP Owner Base unit','ip_owner_base_unit')}
            {this.renderList('Language','language')}
            {this.renderList('Lecturer - Education Level','lecturer_education_level')}
            {this.renderList('Member Category','member_category')}
            {this.renderList('Method of Payment','method_of_payment')}
            {this.renderList('Price Category','price_category')}
            {this.renderList('Schedule Status','schedule_status')}
            {this.renderList('Trainee Status','trainee_status')}

        </div>
        )
    }

    render() {
        const { selectablesList, randNum } = this.props;
        return <AppWrapper 
                title="Selectables"
                subtitle="Maintenance"
                onLoad={( !( selectablesList && randNum ) ? true : false )}
                customColumn={this.renderCustomColumn()}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        selectablesList: state.maintenance && state.maintenance.selectables || null,
        randNum: state.maintenance && state.maintenance.rand || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(MaintenanceSelectables);