import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _remove from 'lodash/remove';

/* eslint-disable */

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';

import { InverseButton } from '../../styles/button';

import theme from '../../theme';

export const Message = styled.div`
    border-left: 2px solid #ffc107;
    background: #ffef62;
    color: #212121;
    padding: 5px 12px;
    font-size: 12px;
`;


class FormCheckMailingLists extends React.Component {

    handleMessageRemove = list => event => {
        const { onMessageRemove } = this.props;
        if ( onMessageRemove )
            onMessageRemove(list.id);
    }

    handleChange = list => event => {
        const { onChange, mailingLists, value, name } = this.props;
        let newValue = ( value && isArrayExists( value ) ? cloneCollections( value ) : [] );

        if ( event.target.checked ) {
            if ( !_find( newValue, { id: list.id } ) )
                newValue.push({ id: list.id, name: ( list.name || '' ) });
        } else {
            if ( _find( newValue, { id: list.id } ) ) {
                let pulled = _remove( newValue, { id: list.id });
            }
        } // end - event

        // do update
        if ( onChange )
            onChange( newValue, name );
    }

    getMessage = (list) => {
        const { messages } = this.props;
        const selected = ( messages && isArrayExists( messages ) ? _find( messages, { id: list.id } ) : false );
        return ( selected && !_isEmpty( selected ) ? selected : false );
    }

    renderMessage = (list) => {
        const selected = this.getMessage(list);
        return ( selected && selected.message && !_isEmpty( selected.message ) ? (
        <Message>
            <i className="fa fa-arrow-up" style={{ marginRight: "5px" }}></i>{selected.message}
            { selected.type && selected.type === 'remove' ? <div style={{ marginTop: '5px', padding: '5px', background: '#ff9800', fontSize: '11px' }}><InverseButton size="small" onClick={this.handleMessageRemove(selected)}>Click here</InverseButton>{" if you want to keep all the contacts in this mailing list"}</div> : null }
        </Message>
        ) : null )
    }

    render() {
        const { mailingLists, value, disabled } = this.props;
        return (
        <div style={{ paddingLeft: "5px", paddingRight: "5px" }}>
            <FormControl fullWidth={true}>
                <InputLabel shrink={true} style={{ fontSize: "16px", fontWeight: "700", textTransform: "uppercase" }}>Mailing Lists</InputLabel>
                <Grid container spacing={1} style={{ paddingTop: "30px" }}>
                    { mailingLists && isArrayExists( mailingLists ) ? _sortBy(mailingLists,['name']).map(list => {
                        return (
                        <Grid key={list.id} item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={disabled}
                                        checked={( value && isArrayExists( value ) && _find( value, { id: list.id }) ? true : false )}
                                        onChange={this.handleChange(list)}
                                        color="primary" />
                                }
                                label={( list.name || '' )} />
                            {this.renderMessage(list)}
                        </Grid>
                        )
                    }) : null }
                </Grid>
            </FormControl>
        </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        mailingLists: state.maintenance && state.maintenance.mailing_lists || null,
    }
}

export default compose(
    connect(mapStateToProps)
)(FormCheckMailingLists);