import _random from 'lodash/random';

import { 
    GET_EMAIL_TEMPLATES,
    GET_EMAIL_TEMPLATE,
    ADD_EMAIL_TEMPLATE,
    EDIT_EMAIL_TEMPLATE,
    DELETE_EMAIL_TEMPLATE,
    RESET_EMAIL_TEMPLATES,
    RESET_EMAIL_TEMPLATE
} from '../actions/types';

const initialState = {
    templates: null,
    template: null,
    updated: false,
    rand: false
};

export const emailtemplates = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_EMAIL_TEMPLATES:
            return {
                ...state,
                templates: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case GET_EMAIL_TEMPLATE:
            return {
                ...state,
                template: ( payload.template || {} ),
                rand: _random(1,9999)
            };
        case ADD_EMAIL_TEMPLATE:
        case EDIT_EMAIL_TEMPLATE:
        case DELETE_EMAIL_TEMPLATE:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_EMAIL_TEMPLATES: 
            return {
                ...state,
                templates: null,
                rand: false
            };
        case RESET_EMAIL_TEMPLATE: 
            return {
                ...state,
                template: null,
                rand: false
            };
        default:
            return state;
    }
}