/* eslint-disable */
import * as firebase from 'firebase/app';

import {
	IS_LOGGED_IN,
	AUTH_LOGIN,
	AUTH_LOGOUT,
	// AUTH_EDIT_PROFILE,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { googleSignIn, logoutUser, onAuth } from './fb';

export const isUserLoggedIn = () => {
	return dispatch => {

        firebase.auth().onAuthStateChanged(function(userToken) {
            if (userToken) {
				onAuth(userToken)
				.then(user => {
					dispatch({
						type: IS_LOGGED_IN,
						payload: user
					});
				})
				.catch(error => {
					dispatch({ type: AUTH_LOGOUT });
					triggerErrorAlert(error.message);
				});
            } else {
                dispatch({ type: AUTH_LOGOUT });
            }
        }); 

	}
}

export const signIn = () => {
	return dispatch => {
		dispatch(toggleLoader(true));

		googleSignIn()
		.then( user => {
			setTimeout(() => {
				dispatch({
					type: AUTH_LOGIN,
					payload: user
				});
				dispatch(toggleLoader(false));
			},2500)
		})
		.catch(error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert( error.message );
		});

	}
}

export const logout = () => {
	return dispatch => {
		dispatch(toggleLoader(true));

		logoutUser()
		.then(() => {
			dispatch({ type: AUTH_LOGOUT });
			dispatch(toggleLoader(false));
		});

	}
}