import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import List from './list';

import { getCourseProfile } from '../../actions/m_course_profile';

class MaintenanceCourseProfile extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {

        // get courses profile
        this.props.dispatch(getCourseProfile());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { courseProfileList, randNum } = this.props;

        if ( courseProfileList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    renderContents() {
        const { courseProfileList } = this.props;
        return <List list={courseProfileList} />;
    }

    render() {
        const { courseProfileList, randNum } = this.props;
        return <AppWrapper 
                title="Course Profile"
                subtitle="Maintenance"
                onLoad={( !( courseProfileList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        courseProfileList: state.maintenance && state.maintenance.courses_profile || null,
        randNum: state.maintenance && state.maintenance.rand || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(MaintenanceCourseProfile);