import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { Editor } from '@tinymce/tinymce-react';
import styled from "styled-components";
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _split from 'lodash/split';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _replace from 'lodash/replace';
import _remove from 'lodash/remove';
import _map from 'lodash/map';
import _random from 'lodash/random';

/* eslint-disable */

import '../EmailTemplatePage/editor_mce.css';

import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormRadio from '../../components/FormRadio';
import FormInput from '../../components/FormInput';
import FormUpload from '../../components/FormUpload';

import { InfoButton, ErrorButton, ButtonGroup, AInfoLink, SuccessButton } from '../../styles/button';
import { WrapWord } from '../../styles/misc';

import { isArrayExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { uploadMediaToStorage } from '../../helpers/firebase';

import { updateBeneficiaryData } from '../../actions/m_beneficiary_data';
import { syncDataWithLMS } from '../../actions/m_regions';
import { toggleLoader } from '../../actions/global';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});

const ActiveTag = styled.div`
    display: inline-block;
    padding: 5px 8px;
    margin: 0px 5px 5px 0px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 6px;
    color: #fff;
    background: ${props => (props.active && props.active == 'yes' ? green['500'] : grey['500'])};
`;

class List extends React.Component {
    
    state = {
        sortBy: 'name-asc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalType: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false
    }

    handleRegionDataSync = (event) => {
        event.preventDefault();
        //this.props.dispatch(syncDataWithLMS())
    }

    handleFormUpdate = (newValue,key,status) => {
        const { modalData } = this.state;
        var newData = ( modalData ? cloneCollections( modalData ) : {} );
        newData[key] = newValue;
        this.setState({ modalData: newData });
    }

    handleEditorChange = (content) => {
        const { modalData } = this.state;
        var newData = ( modalData ? cloneCollections( modalData ) : {} );
        newData['custom_html'] = content;
        this.setState({ modalData: newData });
    }

    handleFileUpload = (files,key) => {
        const { authData, dispatch } = this.props;
        if ( files && files[0] && !_isEmpty( files[0] ) ) {
            dispatch(toggleLoader(true));
            uploadMediaToStorage( files[0], 'uploads', authData )
            .then( url => {
                this.handleFormUpdate(url,key);
                dispatch(toggleLoader(false));
            })
            .catch(error => {
                triggerErrorAlert(( error && error.message || 'Upload Error. Please try again' ));
            })
        } else {
            triggerErrorAlert('Please upload a valid image file');
        }
    }

    handleEdit = () => {
        const { modalData } = this.state;
        var error = false;

        if ( !( modalData && modalData.label && !_isEmpty( modalData.label ) ) ) {
            error = 'Please insert a valid beneficiary name';
        } // end - modalData.label

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateBeneficiaryData(modalData));
        } // end - error
    }

    handleAction = () => {
        const { modalType } = this.state;
        if ( modalType && modalType == 'new' ) {
            this.handleAddNew();
        } else if ( modalType && modalType == 'edit' ) {
            this.handleEdit();
        } // end - modalType
    }

    getBeneficiaryData = (item,key,defaultVal) => {
        const { list } = this.props;
        var selected = ( list && isArrayExists( list ) ? _find( list, { id: item.id } ) : false );
        if ( key )
            return ( selected && selected[key] ? selected[key] : ( defaultVal || '' ) );
        else
            return ( selected ? selected : { id: item.id } );
    }

    getEditData = (item) => {
        const { list } = this.props;
        let selected = ( list && isArrayExists( list ) ? _find( list, { id: item.id } ) : false );
        if ( selected ) {
            return selected;
        } else {
            return { id: item.id, label: ( item.company_name || '' ) };
        }
    }

    reorganizeData() {
        const { sortBy, perPage, page } = this.state;
        const { regions } = this.props;
        var items = ( regions ? cloneCollections( regions ) : [] ),
            total = _size( items );

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                // case 'position-desc':
                //     items = _sortBy( items, [(i) => ( i.position ? parseInt( i.position, 10 ) : 99 )] );
                //     items = _reverse( items );
                //     break;
                // case 'position-asc':
                //     items = _sortBy( items, [(i) => ( i.position ? parseInt( i.position, 10 ) : 99 )] );
                //     break;
                case 'name-desc':
                    items = _sortBy( items, ['label'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['label'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderViewForm = () => {
        const { regions } = this.props;
        const { modalData, modalType } = this.state;
        return (
        <div>
            <FormInput label="Beneficiary Name" name="label" value={( modalData && modalData.label ? modalData.label : '' )} onChange={this.handleFormUpdate} />
            <FormInput label="Bank Name" name="bank_name" value={( modalData && modalData.bank_name ? modalData.bank_name : '' )} onChange={this.handleFormUpdate} />
            <FormInput label="Bank Account No." name="bank_account_no" value={( modalData && modalData.bank_account_no ? modalData.bank_account_no : '' )} onChange={this.handleFormUpdate} />
            <FormInput label="Bank Swift Code" name="bank_swift_code" value={( modalData && modalData.bank_swift_code ? modalData.bank_swift_code : '' )} onChange={this.handleFormUpdate} />
            <FormInput label="Invoice Name" name="invoice_name" value={( modalData && modalData.invoice_name ? modalData.invoice_name : '' )} onChange={this.handleFormUpdate} />
            {/* <div style={{ padding: '20px 0 10px 0' }}>
                <FormUpload 
                    label="Signature Image (max image height:- 100px)"
                    name="signature_image" 
                    value={( modalData && modalData.signature_image ? modalData.signature_image : '' )} 
                    onReset={this.handleFormUpdate}
                    onUpload={this.handleFileUpload} />
            </div> */}
            <h4 style={{ padding: '20px 0 5px 0', fontWeight: '700' }}>Custom Beneficiary Details</h4>
            <Editor
                id={"mce-editor-" + ( modalData && modalData.id ? modalData.id : _random(1,9999) )}
                value={( modalData && modalData.custom_html && !_isEmpty( modalData.custom_html ) ? modalData.custom_html : '' )}
                init={{
                    height: 500,
                    plugins: [
                            'advlist autolink lists link image charmap preview hr anchor',
                            'searchreplace visualblocks visualchars code',
                            'insertdatetime media nonbreaking save table directionality',
                            'paste textpattern imagetools toc'
                        ],
                    toolbar1: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
                    toolbar2: 'preview media | forecolor backcolor'
                }}
                onEditorChange={this.handleEditorChange} />
        </div>
        );
    }

    renderTableActions = () => {
        const { sortBy, perPage, searchterms } = this.state;
        return <TableBar
                show={['sort','entries']}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'name-asc', label: 'Label ( A - Z)' },
                    { value: 'name-desc', label: 'Label ( Z - A )' },
                    // { value: 'position-asc', label: 'Position (smallest number first)' },
                    // { value: 'position-desc', label: 'Position (biggest number first)' }
                ]}
                // rightButtons={[
                //     <SuccessButton minWidth="168px" key="sync" style={{ marginRight: "5px" }} onClick={this.handleRegionDataSync}><i className="fa fa-refresh"></i>Sycn Region List with LMS</SuccessButton>
                // ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    doneLoaded={true}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderBody = (items) => {
        const { classes } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(item => {
                return (
                <TableRow key={item.id}>
                    <TableCell className={classes.bodycell} style={{ width: '30%' }}>{item.label || ''}</TableCell>
                    <TableCell className={classes.bodycell} style={{ width: '60%' }}>
                        <strong style={{ fontWeight: '700' }}>Beneficiary Name:</strong> {this.getBeneficiaryData(item,'label',( item.company_name || false ))}<br /><br />
                        <strong style={{ fontWeight: '700' }}>Bank Name:</strong> {this.getBeneficiaryData(item,'bank_name')}<br />
                        <strong style={{ fontWeight: '700' }}>Bank Account Number:</strong> {this.getBeneficiaryData(item,'bank_account_no')}<br />
                        <strong style={{ fontWeight: '700' }}>Bank Swift Code:</strong> {this.getBeneficiaryData(item,'bank_swift_code')}<br />
                        <strong style={{ fontWeight: '700' }}>Invoice Name:</strong> {this.getBeneficiaryData(item,'invoice_name')}
                    </TableCell>
                    <TableCell className={classes.bodycell}>
                        <ButtonGroup>
                            <InfoButton size="small" onClick={() => this.setState({ openViewModal: true, modalData: this.getEditData( item ), modalType: 'edit' })}><i className="fa fa-edit"></i>Edit</InfoButton>
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
                )
            }) : (
                <TableRow>
                    <TableCell className={classes.bodycell}>No item(s) found.</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes } = this.props;
        return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headcell}>Label</TableCell>
                <TableCell className={classes.headcell}>Beneficiary Data</TableCell>
                <TableCell className={classes.headcell}>Actions</TableCell>
            </TableRow>
        </TableHead>
        )
    }

    render() {
        const { openDeleteModal, deleteModal, openViewModal, modalType } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openViewModal}
                title={ modalType && modalType == 'edit' ? "Edit Beneficiary Data" : "Add New Beneficiary Data" }
                actionLabel={ modalType && modalType == 'edit' ? "Update" : "Add New" }
                maxWidth="lg"
                disableBackdrop={true}
                disableEnforceFocus={true}
                onClose={() => this.setState({ openViewModal: false, modalType: false, modalData: false })}
                doAction={this.handleAction}
                contents={this.renderViewForm()} />

            {this.renderTableActions()}
            <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                <Table>
                    {this.renderHeader()}
                    {this.renderBody(items)}
                </Table>
            </Paper>
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(List);