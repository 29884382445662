import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';

/* eslint-disable */

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormSelect from '../../components/FormSelect';
import FormInput from '../../components/FormInput';
import ButtonActions from '../../components/ButtonActions';
import AddNewReport from '../../components/AddNewReport';

import ReportCategories from './categories';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { SuccessTag, AmberTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, combineStrings } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { isAdmin, hasAccessRights, hasLimitedAccess } from '../../helpers/auth';

import { deleteReportTemplate } from '../../actions/reports';


class ReportTemplates extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        filterBy: 'all',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        openDeleteModal: false,
        deleteModal: false,
        openCategoryModal: false
    }

    handlePageRefresh = (event) => {
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh();
    }

    handleEdit = (id,event) => {
        event.preventDefault();
        this.props.history.push("/reports/"+id);
    }

    handleDelete = () => {
        const { deleteModal } = this.state;
        // perform delete
        this.props.dispatch(deleteReportTemplate(deleteModal.id));
    }

    handleAction = type => {
        const { checked } = this.state;
        // make sure there is an item selected
        if ( isArrayExists( checked ) ) {
            if ( type && type === 'comment' ) {
                this.setState({ openComment: true });
            }
        } else {
            triggerErrorAlert("Please select at least one item");
        } // end - checked
    }

    getActions = () => {
        const { authData } = this.props;
        let actions = [
            { id: 'viewcategory', label: 'View Report Category', icon: 'fa-folder-open-o', onClick: () => this.setState({ openCategoryModal: true }) },
            { id: 'addreport', label: 'Add Report Template', icon: 'fa-file-excel-o', onClick: () => this.setState({ openViewModal: true }) },
            { id: 'massdelete', label: 'Delete', icon: 'fa-trash', onClick: false }
        ];

        return actions;
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { templates, categories, authData } = this.props;
        let items = ( templates ? cloneCollections( templates ) : [] ),
            total = _size( items );

        // add in searchable name
        if ( templates && isArrayExists( templates ) ) {
            _forEach( templates, (current,index) => {
                var selected_cat = ( current.category_id && !_isEmpty( current.category_id ) && categories && isArrayExists( categories ) ? _find( categories, { id: current.category_id }) : false );
                items[index].category_label = ( selected_cat && selected_cat.name || '' );
            });
        } // end - reports

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','desc','category_label'] );
			total = _size( items );
        } // end - searchterms

        // do custom filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy != 'all' ) {
            items = _filter( items, { category_id: filterBy });
            total = _size( items );
        }

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['modified_on'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['modified_on'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, [(i) => i.name.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, [(i) => i.name.toLowerCase()] );
                    break;
                case 'category_label-desc':
                    items = _sortBy( items, [(i) => i.category_label.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'category_label-asc':
                    items = _sortBy( items, [(i) => i.category_label.toLowerCase()] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderRightButtons = () => {
        const { authData } = this.props;
        const buttons = [];

        if ( authData && !hasLimitedAccess(authData) ) {
            buttons.push(<ButtonActions 
                key="actions" 
                label="Actions"
                menuContainerStyle={{ width: "200px" }}
                style={{ marginRight: "5px" }}
                actions={this.getActions()} />);
        } // end - authData

        buttons.push(<InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton>);

        return buttons;
    }

    renderTableActions = () => {
        const { authData, categories } = this.props;
        const { filterBy, sortBy, perPage, searchterms, checked } = this.state;
        return <TableBar
                filterBy={filterBy}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Modified first' },
                    { value: 'date-asc', label: 'Oldest Modified first' },
                    { value: 'name-asc', label: 'Report Name ( A - Z)' },
                    { value: 'name-desc', label: 'Report Name ( Z - A )' },
                    { value: 'category_label-asc', label: 'Category Name ( A - Z)' },
                    { value: 'category_label-desc', label: 'Category Name ( Z - A )' }
                ]}
                filterByOptions={getSelectOptions({ list: ( categories || false ), options: [{ value: 'all', label: 'All Report' }], keys: { value: 'id', label: 'name' } })}
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                    </div>
                    ] : null }
                rightButtons={this.renderRightButtons()}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    doneLoaded={true}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { authData, categories, templates } = this.props;
        const { openViewModal, openDeleteModal, deleteModal, openCategoryModal, allCheck, checked } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <AddNewReport
                authData={authData}
                open={openViewModal}
                categories={( categories || [] )}
                onClose={() => this.setState({ openViewModal: false })} />

            <ReportCategories
                authData={authData}
                open={openCategoryModal}
                categories={( categories || [] )}
                templates={( templates || [] )}
                onClose={() => this.setState({ openCategoryModal: false })} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to delete this report ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            {this.renderTableActions()}
            <Table 
                items={items}
                showCheckbox={true}
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={[
                    { id: 'name', label: 'Report Name', render: (item) => ( item.name || '' ) },
                    { id: 'desc', label: 'Description', render: (item) => ( item.desc || '' ) },
                    { id: 'category_label', label: 'Category Name', render: (item) => ( item.category_label && !_isEmpty( item.category_label ) ? item.category_label : '' ) }
                ]}
                actionStyles={{ width: "10%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <AInfoLink key="edit" href={"/reports/"+item.id} size="small" onClick={this.handleEdit.bind(this,item.id)}><i className="fa fa-edit"></i>Edit</AInfoLink>
                        { authData && hasAccessRights( authData, ['rpd'] ) ? <ErrorButton key="delete" size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton> : null }
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(ReportTemplates);