/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_TEST_QUERIES,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appGET_TEST_QUERIEShangesReset } from '../misc';

import { fb_getTestQueries } from './fb';

/* helper start */


/* helper end */

export const getTestQueries = (props) => {
	return dispatch => {

		fb_getTestQueries(props)
		.then(({ list, total, firstDoc, lastDoc }) => {
			dispatch({
				type: GET_TEST_QUERIES,
				payload: { list, total, firstDoc, lastDoc }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_TEST_QUERIES,
				payload: { list: [] }
			});
		});


	}
}