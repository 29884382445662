import React from 'react';
import ReactDOM from 'react-dom';
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";

import './reset.css';
import './styles.css';
import App from './app';
import * as serviceWorker from './serviceWorker';

/* eslint-disable */

// init fb
firebase.initializeApp({
    apiKey: "AIzaSyBxUtDODczYESU2ooiG-PQlxM-kdXbiNaI",
    authDomain: "dreamcatcher-crm.firebaseapp.com",
    databaseURL: "https://dreamcatcher-crm.firebaseio.com",
    projectId: "dreamcatcher-crm",
    storageBucket: "dreamcatcher-crm.appspot.com",
    messagingSenderId: "544284792125",
    appId: "1:544284792125:web:66b5814495e354e3"
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
