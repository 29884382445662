export const productSchema = [
    { 
        id: 'id', 
        label: 'ID',
        required: ['update','delete'], 
        default: '', 
        type: 'string', 
        validation: 'string_id', 
        skip: ['add','transfer_ownership'],
        report: true,
        sort: true,
        sync: false 
    },
    { 
        id: 'lms_linked', 
        label: 'LMS Linked',
        required: false, 
        default: 'no', 
        type: 'system_data', 
        skip: ['transfer_ownership'],
        report: true,
        sync: true 
    },
    { 
        id: 'course_profile_linked', 
        label: 'Course Profile Linked',
        required: false, 
        default: 'no', 
        type: 'system_data', 
        skip: ['transfer_ownership'],
        report: false,
        sync: true 
    },

    {   
        id: 'name',
        label: 'Name',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'text',
        skip: ['transfer_ownership'],
        report: true,
        sort: true,
        search_keywords: true,
        email_shortcode: true,
        sync: true
    },
    { 
        id: 'course_group_id', 
        label: 'Course Group ID',
        required: false, 
        default: '', 
        type: 'system_data', 
        skip: ['transfer_ownership'],
        report: false,
        sync: true 
    },
    { 
        id: 'course_group_code', 
        label: 'Course Group Code',
        required: false, 
        default: '', 
        type: 'system_data', 
        skip: ['transfer_ownership'],
        report: false,
        sync: true 
    },
    {   
        id: 'course_group_label',
        label: 'Course Group',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        skip: ['transfer_ownership'],
        report: true,
        sort: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'status',
        label: 'Product Status',
        required: false,
        default: 'active',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'course_duration',
        label: 'Course Duration',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        skip: ['transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: true
    },

    // pricing here
    {   
        id: 'price_category',
        label: 'Pricing Category',
        required: false,
        default: [],
        type: 'array_obj',
        field: 'special',
        skip: ['transfer_ownership'],
        report: true,
        report_hidden: true,
        sync: true
    },
    {   
        id: 'currency',
        label: 'Currency',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'price_regular_fee',
        label: 'Price: Regular Fee',
        required: false,
        default: '',
        type: 'currency',
        skip: ['add','update','transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: false
    },
    {   
        id: 'price_early_bird_discount',
        label: 'Price: Early Bird Discount',
        required: false,
        default: '',
        type: 'currency',
        skip: ['add','update','transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: false
    },
    {   
        id: 'price_group_discount',
        label: 'Price: Group Discount',
        required: false,
        default: '',
        type: 'currency',
        skip: ['add','update','transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: false
    },

    { 
        id: 'region_id', 
        label: 'Region',
        required: false, 
        default: '', 
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        sync: true 
    },
    { 
        id: 'tracking_id', 
        label: 'Tracking ID',
        required: false, 
        default: '', 
        type: 'system_data', 
        skip: ['transfer_ownership'],
        report: false,
        sync: true 
    },
    { 
        id: 'course_id', 
        label: 'Course ID',
        required: false, 
        default: '', 
        type: 'system_data', 
        skip: ['transfer_ownership'],
        report: true,
        sync: true 
    },
    { 
        id: 'schedule_id', 
        label: 'Schedule ID',
        required: false, 
        default: '', 
        type: 'system_data', 
        skip: ['transfer_ownership'],
        report: true,
        sync: true 
    },
    {   
        id: 'publish',
        label: 'Publish',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'schedule_status',
        label: 'Course Status',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'portfolio',
        label: 'Portfolio',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'consultant',
        label: 'Consultant',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'delivery_method',
        label: 'Delivery Method',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: false,
        sync: true
    },
    {   
        id: 'delivery_method_online',
        label: 'Delivery Options Available: Online',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'delivery_method_physical',
        label: 'Delivery Options Available: Physical',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'delivery_method_hybrid',
        label: 'Delivery Options Available: Hybrid',
        required: false,
        default: '',
        type: 'string',
        field: 'checkboxes',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'physical_class_req',
        label: 'Physical Class Requirement',
        required: false,
        default: '',
        type: 'string',
        field: 'select',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'start_date',
        label: 'Start Date',
        required: false,
        default: 0,
        type: 'timestamp',
        field: 'datepicker',
        skip: ['transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'end_date',
        label: 'End Date',
        required: false,
        default: 0,
        type: 'timestamp',
        field: 'datepicker',
        skip: ['transfer_ownership'],
        report: true,
        email_shortcode: true,
        sync: true
    },

    { 
        id: 'venue_id', 
        label: 'Venue ID',
        required: false, 
        default: '', 
        type: 'system_data', 
        skip: ['transfer_ownership'],
        report: false,
        sync: true 
    },
    {   
        id: 'location',
        label: 'Location',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        skip: ['transfer_ownership'],
        report: true,
        sort: true,
        search_keywords: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'city',
        label: 'City',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        skip: ['transfer_ownership'],
        report: true,
        sort: true,
        search_keywords: true,
        email_shortcode: true,
        sync: true
    },
    {   
        id: 'notes',
        label: 'Notes',
        required: false,
        default: '',
        type: 'string',
        field: 'textarea',
        skip: ['transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'created_on',
        label: 'Created On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: ['update','transfer_ownership'],
        report: true,
        sync: true
    },
    {   
        id: 'modified_on',
        label: 'Modified On',
        required: false,
        default: 0,
        type: 'system_date',
        report: false,
        sync: true
    }
];