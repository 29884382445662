/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _size from 'lodash/size';

import ModalView from '../ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormSelect2 from '../FormSelect2';
import FormMultiSelect from '../../components/FormMultiSelect';
import SelectAccounts from '../SelectAccounts';
import SelectContacts from '../SelectContacts';
import SelectProducts from '../SelectProducts';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { reverseUsersValues } from '../../helpers/users';

import { addNewPotential } from '../../actions/potentials';

const initialState = {
    name: '',
    product_id: '',
    product_label: '',
    accounts_linked: [],
    contacts_linked: [],
    assigned_to: []
};

class AddNewPotential extends React.Component {

    state = {
        data: initialState,
        randNum: false
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { open } = this.props;
        if ( open && !prevProps.open ) {
            this.setState({ data: this.getInitialValues() }); // reset 
        }
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose ) {
            this.setState({ data: this.getInitialValues() }); // reset 
            onClose();
        } // end - onClose
    }

    handleFormUpdate = (newValue,key) => {
        const { users } = this.props;
        const { data } = this.state;
        var newData = ( data ? cloneCollections( data ) : {} );

        switch(key) {
            case 'assigned_to':
                newData[key] = reverseUsersValues( newValue, users );
                break;
            default:
                newData[key] = newValue;
                break;
        }

        this.setState({ data: newData });
    }

    handleAddNew = () => {
        const { history, predefined_product, predefined_account, predefined_contact } = this.props;
        const { data } = this.state;
        var error = false,
            formData = cloneCollections( data );

        // insert predefined_product if available
        if ( predefined_product && predefined_product.id && !_isEmpty( predefined_product.id ) ) {
            _forEach( predefined_product, product => {
                formData['product_id'] = predefined_product.id;
                formData['product_label'] = ( predefined_product.name || '' );
            });
        } // end - predefined_product

        // insert predefined_account if available
        if ( predefined_account && predefined_account.id && !_isEmpty( predefined_account.id ) ) {
            formData['accounts_linked'] = [{
                id: predefined_account.id,
                name: ( predefined_account.name || '' )
            }];
        } // end - predefined_account

        // insert predefined_contact if available
        if ( predefined_contact && predefined_contact.id && !_isEmpty( predefined_contact.id ) ) {
            formData['contacts_linked'] = [{
                id: predefined_contact.id,
                name: ( predefined_contact.name || '' ),
                email: ( predefined_contact.email || '' ),
                account_id: ( predefined_contact.account_id || '' )
            }];
        } // end - predefined_contact

        if ( !( formData && formData.assigned_to && isArrayExists( formData.assigned_to ) ) ) {
            error = 'Please assign at least one user';
        } // end - formData.email

        if ( !( formData && formData.accounts_linked && isArrayExists( formData.accounts_linked ) ) ) {
            error = 'Please assign an account';
        } // end - formData.email

        if ( !( formData && formData.name && !_isEmpty( formData.name ) ) ) {
            error = 'Please enter a valid Potential Deal';
        } // end - formData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addNewPotential(formData,history));
        } // end - error
    }

    isDisabled = (key) => {
        const { predefined_account, predefined_contact, predefined_product } = this.props;
        let disabled = false;

        switch( key ) {
            case 'accounts_linked':
                if ( predefined_account && predefined_account.id && !_isEmpty( predefined_account.id ) )
                    disabled = true;
                break;
            case 'contacts_linked':
                if ( predefined_contact && predefined_contact.id && !_isEmpty( predefined_contact.id ) )
                    disabled = true;
                break;
            case 'product_id':
                if ( predefined_product && predefined_product.id && !_isEmpty( predefined_product.id ) )
                    disabled = true;
                break;
        }

        return disabled;
    }

    getFormValues = (key) => {
        const { predefined_account, predefined_contact, predefined_product } = this.props;
        const { data } = this.state;
        var val = false;

        switch( key ) {
            case 'accounts_linked':
                val = ''; // set to default
                // if predefined value exists
                if ( predefined_account && predefined_account.id && !_isEmpty( predefined_account.id ) ) {
                    val = predefined_account.id;
                } else {
                    // turn array to string
                    if ( data && data[key] && isArrayExists( data[key] ) ) {
                        data[key].forEach(item => {
                            val = ( item.id || false );
                        });
                    } // end - data[key]
                } // end - predefined_account
                break;
            case 'contacts_linked':
                val = ''; // set to default
                // if predefined value exists
                if ( predefined_contact && predefined_contact.id && !_isEmpty( predefined_contact.id ) ) {
                    val = predefined_contact.id;
                } else {
                    // turn array to string
                    if ( data && data[key] && isArrayExists( data[key] ) ) {
                        data[key].forEach(item => {
                            val = ( item.id || false );
                        });
                    } // end - data[key]
                } // end - predefined_contact
                break;
            case 'product_id':
                val = ( predefined_product && predefined_product.id && !_isEmpty( predefined_product.id ) ? predefined_product.id  : ( data && data[key] ? data[key] : '' ) );
                break;
            case 'assigned_to':
                val = ( data && data.assigned_to && isArrayExists( data.assigned_to ) ? getSelectValues( data.assigned_to, 'id' ) : [] );
                break;
            default:
                val = ( data && data[key] ? data[key] : '' );
                break;
        }

        return val;
    }

    getFormOptions = (key) => {
        const { users } = this.props;
        let options = [];

        switch( key ) {
            case 'assigned_to':
                options = getSelectOptions({ list: ( users || [] ), keys: { value: 'email', label: 'name' }, sortBy: 'label' });
                break;
        }

        return options;
    }

    getInitialValues = () => {
        const { authData, predefined_account, predefined_contact } = this.props;
        var data = cloneCollections( initialState );
        
        // set assigned_to based on current login user
        if ( authData && authData.name && authData.email ) {
            data.assigned_to = [{ id: authData.email, name: authData.name }];
        }

        // overwrite assigned_to if there is a predefined_account
        if ( predefined_account && predefined_account.assigned_to && isArrayExists( predefined_account.assigned_to ) ) {
            data.assigned_to = predefined_account.assigned_to;
        } // end - predefined_account

        // overwrite assigned_to again if thise is a predefined_contact
        if ( predefined_contact && predefined_contact.assigned_to && isArrayExists( predefined_contact.assigned_to ) ) {
            data.assigned_to = predefined_contact.assigned_to;
        } // end - predefined_contact

        return data;
    }

    renderAddNewForm = () => {
        const { predefined_account, authData } = this.props;
        const { data } = this.state;
        return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <SelectProducts
                        showCoreCourseOnly={true}
                        label="Product" 
                        name="product_id"
                        disabled={this.isDisabled('product_id')}
                        value={this.getFormValues('product_id')} 
                        isClearable={true}
                        onChange={(newProducts,key) => {
                            const { data } = this.state;
                            var newData = ( data ? cloneCollections( data ) : {} );
                            newData[key] = ( newProducts && isArrayExists( newProducts ) && newProducts[0] && newProducts[0].id ? newProducts[0].id : '' ); // update product_id

                            // get product_label
                            newData['product_label'] =  ( newProducts && isArrayExists( newProducts ) && newProducts[0] && newProducts[0].name ? newProducts[0].name : '' ); 
                            newData['name'] =  ( newProducts && isArrayExists( newProducts ) && newProducts[0] && newProducts[0].name ? newProducts[0].name : '' ); 
                            this.setState({ data: newData });
                        }} />
                    <FormInput label="Potential Deal *" name="name" value={this.getFormValues('name')} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={6}>
                    <SelectAccounts
                        label="Account *" 
                        name="accounts_linked"
                        disabled={this.isDisabled('accounts_linked')}
                        value={this.getFormValues('accounts_linked')} 
                        isClearable={true}
                        onChange={(newAccounts,key) => {
                            const { data } = this.state;
                            var newData = ( data ? cloneCollections( data ) : {} );
                            if ( newAccounts && newAccounts[0] && newAccounts[0].id ) {
                                // update account
                                newData[key] = newAccounts;
                                newData['contacts_linked'] = [];

                                // update assigned_to
                                if ( newAccounts && newAccounts[0] && newAccounts[0].assigned_to && isObjectExists( newAccounts[0].assigned_to ) ) {
                                    newData['assigned_to'] = [];
                                    _forEach( newAccounts[0].assigned_to, user => {
                                        newData['assigned_to'].push( user );
                                    });
                                } // end - newAccounts[0].assigned_to

                            } else {
                                // reset
                                newData[key] = [];
                                newData['contacts_linked'] = [];

                                // reset assigned_to back to logged in user
                                newData['assigned_to'] = ( authData && authData.name && authData.email ? [{ id: authData.email, name: authData.name }] : [] );
                            } // end - account
                            this.setState({ data: newData });
                        }} />
                </Grid>
                <Grid item xs={6}>
                    <SelectContacts
                        label="Contact(s)" 
                        name="contacts_linked" 
                        disabled={this.isDisabled('contacts_linked')}
                        value={this.getFormValues('contacts_linked')} 
                        predefined_account={( predefined_account && predefined_account.id && !_isEmpty( predefined_account.id ) ? predefined_account: null )} 
                        selected_accounts={( data && data.accounts_linked && isArrayExists( data.accounts_linked ) ? data.accounts_linked : [] )} 
                        isClearable={true}
                        onChange={(newContacts,key) => {
                            const { data } = this.state;
                            var newData = ( data ? cloneCollections( data ) : {} );
                            // update contact
                            newData[key] = ( newContacts && newContacts[0] && newContacts[0].id && !_isEmpty( newContacts[0].id ) ? newContacts : [] );
                            // update account (if available)
                            if ( newContacts && newContacts[0] && newContacts[0].account_id && !_isEmpty( newContacts[0].account_id ) ) {
                                newData['accounts_linked'] = [{ id: newContacts[0].account_id, name: ( newContacts[0].account_label && !_isEmpty( newContacts[0].account_label ) ? newContacts[0].account_label : '' ) }];
                            } // end - newContacts[0].account_id
                            // update assigned_to
                            if ( newContacts && newContacts[0] && newContacts[0].assigned_to && isObjectExists( newContacts[0].assigned_to ) ) {
                                newData['assigned_to'] = [];
                                _forEach( newContacts[0].assigned_to, user => {
                                    newData['assigned_to'].push( user );
                                });
                            } // end - newContacts[0].assigned_to
                            this.setState({ data: newData });
                        }} />
                </Grid>
                <Grid item xs={12}>
                    <div style={{ paddingTop: "10px" }}>
                        <FormMultiSelect 
                            label="Assigned To (Required)" 
                            name="assigned_to" 
                            disabled={true}
                            value={this.getFormValues('assigned_to')} 
                            options={this.getFormOptions('assigned_to')} 
                            onChange={this.handleFormUpdate} />
                    </div>
                </Grid>
            </Grid>
        </div>
        );
    }

    render() {
        const { open } = this.props;
        return (
        <div>

            <ModalView 
                open={open}
                title="Add New Potential"
                onClose={this.handleClose}
                doAction={this.handleAddNew}
                contents={this.renderAddNewForm()} />

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(AddNewPotential);