/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_COMMENTS,
	GET_COMMENT,
	ADD_COMMENT,
	ADD_COMMENTS,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { triggerPromises, triggerDispatches } from '../../helpers/action';
import { appChangesReset } from '../misc';

import { fb_addNewComment, fb_addComments } from './fb';


export const addNewComment = (formData,updateActions) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addNewComment(formData)
		.then( results => {
			triggerDispatches(updateActions,dispatch)
			.then( results => {

				// updated
				dispatch({ type: ADD_COMMENT });
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("Comment Added");

			});
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new contact' ) );
			triggerErrorAlert(errMsg);
		});


	}
}

export const addComments = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addComments(formData)
		.then( results => {
			// updated
			dispatch({ type: ADD_COMMENTS });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Comment Added");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new contact' ) );
			triggerErrorAlert(errMsg);
		});

	}
}