import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import List from './list';

import { getLeadSource } from '../../actions/m_lead_source';

class MaintenanceLeadSource extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {

        // get list
        this.props.dispatch(getLeadSource());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { leadSourceList, randNum } = this.props;

        if ( leadSourceList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    renderContents() {
        const { leadSourceList, authData } = this.props;
        return <List authData={authData} list={leadSourceList} />;
    }

    render() {
        const { leadSourceList, randNum } = this.props;
        return <AppWrapper 
                title="Lead Source"
                subtitle="Maintenance"
                onLoad={( !( leadSourceList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        leadSourceList: state.maintenance && state.maintenance.lead_source || null,
        randNum: state.maintenance && state.maintenance.rand || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(MaintenanceLeadSource);