/* eslint-disable */
import React from 'react';
import shortid from 'shortid';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import _merge from 'lodash/merge';

import PopperBox from '../Popper';

import { InfoButton, InverseButton, GreyButton } from '../../styles/button';
import { isArrayExists } from '../../helpers/validation';

import theme from '../../theme';

class ButtonActions extends React.Component {

    state = {
        open: false,
        anchorEl: null
    }

    handleItemClick = action => event => {
        event.preventDefault();
        if ( action.onClick )
            action.onClick();
    }

    handleToggle = (event) => {
        const { open } = this.state;
        if ( open ) {
            this.handleClose();
        } else {
            this.setState({ open: true, anchorEl: event.currentTarget });
        } // end - open
    }

    handleClose = () => {
        this.setState({ open: false, anchorEl: null });
    }

    renderMenu = () => {
        const { actions, menuItemStyle, menuMaxHeight, menuScrollable } = this.props;
        return (
        <div style={{ padding: "5px 0px", maxHeight: ( menuMaxHeight || 'none' ), overflow: ( menuScrollable ? 'hidden auto' : 'hidden' ) }}>
            { actions && isArrayExists( actions ) ? actions.map( action => {
                return (
                <MenuItem key={action.id} id={action.id} onClick={this.handleItemClick(action)} disabled={( action.disabled || false )} style={( menuItemStyle || { minHeight: "24px" })}>
                    { action.icon ? <i className={"fa "+action.icon} style={{ marginRight: "15px" }}></i>: null }
                    {action.label}
                </MenuItem>
                )
            }) : null }
        </div>
        )
    }

    renderPopupMenu = () => {
        const { menuContainerStyle } = this.props;
        const { open, anchorEl } = this.state;
        return (
        <PopperBox 
            open={open}
            placement="bottom-end"
            anchorEl={anchorEl}
            onClose={this.handleClose}
            wrapperStyle={{ paddingTop: "2px" }}
            containerStyle={( menuContainerStyle || { width: "150px" })}
            zIndex="999"
            contents={this.renderMenu()} />
        )
    }

    renderButtonProps = () => {
        const { style } = this.props;
        return {
            minWidth: '128px',
            style: ( style ? _merge( style, { height: "100%" } ) : { height: "100%" }),
            onClick: this.handleToggle
        }
    }

    renderButtonContent = () => {
        const { label } = this.props;
        return (
        <span style={{ width: '100%', height: '100%' }}>
            {( label || 'Actions' )}
            <i className="fa fa-caret-down" style={{ margin: '0 0 0 8px' }}></i>
        </span>
        )
    }

    renderButton = () => {
        const { color } = this.props;
        switch( color ) {
            case 'inverse':
                return <InverseButton {...this.renderButtonProps()}>{this.renderButtonContent()}</InverseButton>;
            case 'grey':
                return <GreyButton {...this.renderButtonProps()}>{this.renderButtonContent()}</GreyButton>;
            default:
                return <InfoButton {...this.renderButtonProps()}>{this.renderButtonContent()}</InfoButton>;
        }
    }

    render() {
        const { containerStyle = {} } = this.props;
        return (
        <div style={{ marginRight: "5px", ...containerStyle }}>
            {this.renderButton()}
            {this.renderPopupMenu()}
        </div>
        )
    }

}

export default ButtonActions