/* eslint-disable */
import React from 'react';
// import * as firebase from 'firebase/app';
// import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _isEqual from 'lodash/isEqual';
import _toLower from 'lodash/toLower';
import _replace from 'lodash/replace';

import FormSelect2 from '../FormSelect2';
import ModalSelectAccounts from '../ModalSelectAccounts';

import { cloneCollections, getSelectOptions, 
    // getSelectValues, doArraySearch 
} from '../../helpers/data';
import { isArrayExists, isObjectExists } from '../../helpers/validation';

// import { getAccountsOptions } from '../../actions/accounts';

const useStyles = theme => ({
    loaderwrapper: {
        position: 'relative'
    },
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        margin: '-18px 0 0 -18px',
        zIndex: '999'
    },
    selectinput: {
        "& .MuiInputBase-root.Mui-disabled": {
            color: theme.palette.background
        }
    }
});

class SelectAccounts extends React.Component {

    state = {
        openSelect: false,
        selectItem: false,
        randNum: false
    };

    componentDidMount() {
        this.dispatchOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( !_isEqual( prevProps.accountsOptions, this.props.accountsOptions ) || 
            !_isEqual( prevProps.randNum, this.props.randNum ) ) {
            this.dispatchOptions();
        }
    }

    dispatchOptions = () => {
        const { accountsOptions, randNum } = this.props;
        if ( !accountsOptions ) {
            //this.props.dispatch(getAccountsOptions());
        } else if ( accountsOptions && randNum && randNum != this.state.randNum ) {
            this.setState({ randNum });
        } // end - accountsOptions
    }

    getValue = () => {
        const { value, valueObj } = this.props;
        if ( valueObj && isObjectExists( valueObj ) ) {
            return ( valueObj && valueObj.name || '' )
        } else {
            let selected = ( value && !_isEmpty( value ) ? _find( this.getOptions(), { id: value } ) : false );
            return ( selected && selected.name || '' );
        } // end - valueObj
    }

    getOptions = () => {
        const { accountsOptions } = this.props;
        return ( accountsOptions ? cloneCollections( accountsOptions ) : [] );
    }

    handleFormUpdate = (newValue,key) => {
        const { onChange } = this.props;
        let newOptions = [],
            selected = ( newValue && !_isEmpty( newValue ) ? _find( this.getOptions(), { id: newValue } ) : false );

        if ( onChange ) {
            if ( selected )
                newOptions.push( selected );

            // do onChange
            onChange(newOptions,key);
        } // end - onChange
            
    }

    handleSelect = (selected) => {
        const { name, onChange } = this.props;
        let newOptions = [];
        // update data
        if ( onChange ) {
            if ( selected )
                newOptions.push( selected );

            // do onChange
            onChange(newOptions,name);
        } // end - onChange

        // reset modal
        this.setState({ openSelect: false, selectItem: false });
    }

    handleReset = () => {
        const { name, onChange } = this.props;
        if ( onChange ) {
            // do onChange
            onChange([],name);
        } // end - onChange
    }

    renderSelectOld = () => {
        const { label, name, disabled, value, isClearable } = this.props;
        const { randNum } = this.state;
        return <FormSelect2 
            label={( label || '' )}
            name={( name || '' )}
            disabled={( disabled || !randNum ? true : false )}
            value={( value || '' )} 
            options={getSelectOptions({ list: this.getOptions(), keys: { value: 'id', label: 'name' }, sortBy: 'label' })}  
            placeholder="Select an Option"
            menuPosition="fixed"
            isClearable={( isClearable || false )}
            onChange={this.handleFormUpdate} />
    }

    renderLabel = () => {
        const { label } = this.props;
        var newLabel = false;
        if ( label && !_isEmpty( label ) && ( 
            ( _toLower( label ).indexOf("(required)") >= 0 ) || 
            ( _toLower( label ).indexOf("*") >= 0 )
         ) ) {
            newLabel = _replace( label, '(Required)', '' );
            newLabel = _replace( newLabel, '(required)', '' );
            newLabel = _replace( newLabel, '*', '' );
        } // end - label
        return ( newLabel ? <span>{ newLabel || '' }<span className="required">*</span></span> : ( label || '' ) );
    }

    renderSelect = () => {
        const { disabled, labelProps, value, isClearable, classes } = this.props;
        const { randNum } = this.state;
        return <TextField 
            label={this.renderLabel()} 
            value={this.getValue()} 
            fullWidth={true}
            variant="outlined" 
            className={classes.selectinput}
            disabled={true}
            InputLabelProps={( labelProps ? labelProps : {
                shrink: true,
                style: { fontSize: "16px", fontWeight: "700", textTransform: "uppercase", color: '#757575' }
            })}
            InputProps={{
                startAdornment: ( value && !_isEmpty( value ) && isClearable && !( disabled || !randNum ) ? (
                <InputAdornment position="start">
                    <Tooltip title="Remove" arrow="true">
                        <IconButton 
                            style={{ padding: '6px' }}
                            aria-label="Remove"
                            onClick={this.handleReset}><ClearIcon /></IconButton>
                    </Tooltip>
                </InputAdornment>
                ) : null ),
                endAdornment: ( disabled || !randNum ? ( disabled ? null : ( // if is disabled - still return null - otherwise return loading icon
                <InputAdornment position="end">
                    <Tooltip title={"Loading"} arrow="true">
                        <CircularProgress size={24} />
                    </Tooltip>
                </InputAdornment>
                ) ) : (
                <InputAdornment position="end">
                    <Tooltip title="Select Account" arrow="true">
                        <IconButton 
                            aria-label="Select Account"
                            onClick={() => this.setState({ openSelect: true })}><ControlPointIcon /></IconButton>
                    </Tooltip>
                </InputAdornment>
                ) )
            }} />
    }

    render() {
        const { randNum, openSelect} = this.state;
        const { classes, valueObj } = this.props;
        return (
        <div>

            {( !randNum && !( valueObj && isObjectExists( valueObj ) ) ? (
            <div className={classes.loaderwrapper}>
                <CircularProgress size={36} className={classes.loader} />
                {this.renderSelect()}
            </div>
            ) : this.renderSelect() )}

            <ModalSelectAccounts
                open={openSelect}
                onClose={() => this.setState({ openSelect: false, selectItem: false })}
                onSelect={this.handleSelect} />

        </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        accountsOptions: state.accounts && state.accounts.accounts_options || null,
        randNum: state.accounts && state.accounts.options_rand || null
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles)
)(SelectAccounts);