/* eslint-disable */
import * as firebase from 'firebase/app';
import moment from 'moment';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _size from 'lodash/size';
import _split from 'lodash/split';
import _merge from 'lodash/merge';
import _toLower from 'lodash/toLower';
import _snakeCase from 'lodash/snakeCase';
import _uniq from 'lodash/uniq';
import _remove from 'lodash/remove';
import _replace from 'lodash/replace';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _trim from 'lodash/trim';

import { callFunctionsAPI, doPromise } from '../../helpers/action';
import { isArrayExists, isObjectExists, validateEmail } from '../../helpers/validation';
import { cloneCollections, compileEmailKeywordsArray } from '../../helpers/data';
import { getSelectedSchemas, getSelectedSchema, getDocDataBySchema } from '../../helpers/schemas';

import { accountSchema } from '../../schemas/account';
import { contactSchema } from '../../schemas/contact';

/* helper start */

const sampleFunc = ( formData ) => {
    return new Promise((resolve,reject) => {


    })
}

const doPartPromises = (contacts) => {
    return new Promise((resolve,reject) => {

        const promises = [];

        // compile promises
        if ( contacts && isArrayExists( contacts ) ) {
           _forEach( contacts, contact => {
                if ( contact ) {
                    if ( contact.id && !_isEmpty( contact.id ) ) {
                        promises.push( callFunctionsAPI({ url: 'contact', action: 'update', formData: contact }) );
                    } else {
                        promises.push( callFunctionsAPI({ url: 'contact', action: 'add', formData: contact }) );
                    } // end - contact.id
                } // end - contact

            //    promises.push( fakePromise(contact) );
           });
        } // end - contacts

        Promise.all( promises )
        .then(() => {
            resolve(true);
        })
        .catch(error => {
            reject(error);
        });

    })
}

/* helper end */

// get contacts data by email
export const getContactsByEmail = ( list ) => {
    return new Promise((resolve,reject) => {

        let contacts = [];
        
        if ( list && isArrayExists( list ) ) {
            let promises = [];
            _forEach( list, item => {
                if ( item && item.email && !_isEmpty( item.email ) && validateEmail( item.email ) )
                    promises.push( firebase.firestore().collection("contacts").where("email","==",item.email).limit(30).get() );
            });

            // perform Promises
            Promise.all( promises )
            .then( results => {
                if ( results && isArrayExists( results ) ) {
                    _forEach( results, snapshot => {
                        if ( snapshot ) {
                            snapshot.forEach(doc => {
                                let contact = getDocDataBySchema( contactSchema, doc );
                                contacts.push( contact );
                            });
                        } // end - snapshot
                    });
                } // end - results
                resolve(contacts);
            })
            .catch( error => {
                reject( error );
            });
        } else {
            resolve(contacts);
        } // end - list


    })
}

// get account data
export const getAccountsData = ( list ) => {
    return new Promise((resolve,reject) => {

        let accounts = [];
        
        if ( list && isArrayExists( list ) ) {
            let promises = [];
            _forEach( list, item => {
                switch( item.type ) {
                    case 'id':
                        promises.push( firebase.firestore().collection("accounts").where("id","==",item.id).limit(1).get() );
                        break;
                    case 'name':
                        promises.push( firebase.firestore().collection("accounts").where("name","==",item.id).limit(5).get() );
                        break;
                }
            });
            // perform Promises
            Promise.all( promises )
            .then( results => {
                if ( results && isArrayExists( results ) ) {
                    _forEach( results, snapshot => {
                        if ( snapshot ) {
                            snapshot.forEach(doc => {
                                let account = getDocDataBySchema( accountSchema, doc );
                                accounts.push( account );
                            });
                        } // end - snapshot
                    });
                } // end - results
                resolve(accounts);
            })
            .catch( error => {
                reject( error );
            });
        } else {
            resolve(accounts);
        } // end - list

    })
}

export const doContactsImportCSV = async (contacts) => {

    const list = [];
    const limits = 6;
    let count = 1,
        batch = [];

    if ( contacts && isArrayExists( contacts ) ) {
        _forEach( contacts, contact => {
            batch.push(contact);
            if ( count === limits ) {
                list.push(batch); // add to list
                // then reset
                count = 1; 
                batch = [];
            } else {
                count++;
            } // end - count
        });

        // if batch is not empty - add to list
        if ( batch && isArrayExists( batch ) )
            list.push( batch );

    } // end - contacts

    if ( list && isArrayExists( list ) ) {
        let cIndex = 0;
        while ( cIndex < list.length ) {
            await doPartPromises(list[cIndex])
                .then(() => {
                    cIndex++;
                })
                .catch(error => {
                    let errorMessage = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to import contacts. Please try again.' ) );
                    throw new Error( errorMessage );
                });
        }
    } // end - contacts

    return 'done';
}

export const createNewImportAccount = ( formData ) => {
    return new Promise((resolve,reject) => {

        // fake account 
        // setTimeout(() => {
        //     resolve({
        //         id: 'acc99999',
        //         name: formData.name || '',
        //         assigned_to: ( formData.assigned_to || [] )
        //     });
        // },1500);

        // only create new account if formData wasn't empty
        if ( formData && isObjectExists( formData ) ) {
            callFunctionsAPI({ url: 'account', action: 'add', formData })
            .then(({ new_account_id }) => {
                if ( new_account_id && !_isEmpty( new_account_id ) ) {
                    firebase.firestore().collection("accounts").doc(new_account_id).get()
                    .then(doc => {
                        let account = ( doc && doc.exists ? getDocDataBySchema( accountSchema, doc ) : false );
                        resolve( account );
                    })
                    .catch(error => {
                        reject(error);
                    })
                } else {
                    resolve(false);
                }
            })
            .catch(error => {
                reject(error);
            });
        } else {
            // do nothing
            resolve(false);
        } // end - formData

    })
}