/* eslint-disable */
import shortid from "shortid";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _filter from "lodash/filter";
import _size from "lodash/size";
import _sortBy from "lodash/sortBy";
import _round from "lodash/round";
import _remove from "lodash/remove";

import { cloneCollections } from "./data";
import { isArrayExists, isNumeric, isObjectExists } from "./validation";
import { convertToTwoDecimalPoints } from "./number";

// get new quote item
export const newQuoteItem = function (total) {
    return {
        id: shortid.generate(),
        product_id: "",
        name: "",
        desc: "",
        quantity: 1,
        unit_price: 0,
        discount: 0,
        discount_type: "none",
        no_tax: "no",
        pricing_category: {},
        position: (total || 0) + 1,
    };
};

// recount quote item position
export const recountItemPosition = function (list) {
    var newList = [];
    if (list && isArrayExists(list)) {
        newList = cloneCollections(list); // clone into new list
        var count = 1; // set first position
        _forEach(list, (product, index) => {
            newList[index].position = count;
            count++;
        });
    } // end - list
    return newList;
};

// get quote item - total price
export const getItemTotalPrice = function (item) {
    return (
        (item && item.quantity ? parseInt(item.quantity, 10) : 0) *
        (item ? parseFloat(item.unit_price, 10) : 0)
    );
};

// get quote item - pre-tax total
export const getItemPreTaxTotal = function (item) {
    var total = getItemTotalPrice(item);
    // do discount
    return total - getItemDiscountAmount(item);
};

// get region tax rate
export const getRegionTaxRate = function (quote, regions) {
    var tax_rate = 0;
    if (
        quote &&
        quote.company_details &&
        !_isEmpty(quote.company_details) &&
        isArrayExists(regions)
    ) {
        var region = _find(regions, { id: quote.company_details });
        if (region && region.tax_amount) {
            tax_rate = parseFloat(region.tax_amount, 10);
        }
    }
    return tax_rate;
};

// get tax rate
export const getTaxRate = function (quote, tax_amount) {
    return quote &&
        quote.custom_tax_rate &&
        !_isEmpty(quote.custom_tax_rate) &&
        isNumeric(quote.custom_tax_rate)
        ? parseFloat(quote.custom_tax_rate, 10)
        : tax_amount;
};

// get quote item - tax amount
export const getItemTaxAmount = function (item, tax_amount, quote) {
    let tax_percent = tax_amount ? parseInt(tax_amount, 10) : 0;
    // use custom tax rate if available
    if (
        quote &&
        quote.custom_tax_rate &&
        !_isEmpty(quote.custom_tax_rate) &&
        isNumeric(quote.custom_tax_rate)
    ) {
        tax_percent = parseInt(quote.custom_tax_rate, 10);
    }
    let total = getItemPreTaxTotal(item);
    return total > 0 && !(item.no_tax && item.no_tax === "yes")
        ? calculateTaxAmount(total, tax_percent)
        : 0;
};

// get quote item - discount amount
export const getItemDiscountAmount = function (item) {
    var total = getItemTotalPrice(item),
        amount = 0;
    switch (item.discount_type) {
        case "fixed":
            amount = item.discount ? parseFloat(item.discount, 10) : 0;
            break;
        case "percentage":
            amount = item.discount
                ? parseFloat(total * (item.discount / 100))
                : 0;
            break;
    }
    return amount;
};

// get quote item - net price
export const getItemNetPrice = function (
    item,
    isIndividualTax,
    tax_amount,
    quote
) {
    let total = getItemPreTaxTotal(item),
        net_price = isIndividualTax
            ? total + getItemTaxAmount(item, tax_amount, quote)
            : total;
    return _round(net_price, 2); // round it up to 2 decimal places
};

// get quote Total
export const getTotal = function (quote, isIndividualTax, tax_amount) {
    var total = 0;
    if (quote.products_linked && isArrayExists(quote.products_linked)) {
        _forEach(quote.products_linked, (item) => {
            total += getItemNetPrice(item, isIndividualTax, tax_amount, quote);
        });
    } // end - quote.products_linked
    return parseFloat(total);
};

// get quote total discount
export const getTotalDiscountAmount = function (
    quote,
    isIndividualTax,
    tax_amount
) {
    var total = getTotal(quote, isIndividualTax, tax_amount),
        amount = 0;
    switch (quote.group_discount_type) {
        case "fixed":
            amount = quote.group_discount
                ? parseFloat(quote.group_discount, 10)
                : 0;
            break;
        case "percentage":
            amount = quote.group_discount
                ? parseFloat(total * (quote.group_discount / 100))
                : 0;
            break;
    }
    return amount;
};

// get quote pre-tax Total
export const getPreTaxTotal = function (quote, isIndividualTax, tax_amount) {
    var total = getTotal(quote, isIndividualTax, tax_amount);
    // minus group discount
    return parseFloat(
        total - getTotalDiscountAmount(quote, isIndividualTax, tax_amount)
    );
};

// get quote tax amount
export const getTaxAmount = function (quote, isIndividualTax, tax_amount) {
    let tax_percent = tax_amount ? parseInt(tax_amount, 10) : 0;
    // use custom tax rate if available
    if (
        quote.custom_tax_rate &&
        !_isEmpty(quote.custom_tax_rate) &&
        isNumeric(quote.custom_tax_rate)
    ) {
        tax_percent = parseInt(quote.custom_tax_rate, 10);
    }
    let total = getPreTaxTotal(quote, isIndividualTax, tax_amount);
    return total > 0 ? calculateTaxAmount(total, tax_percent) : 0;
};

// get quote adjustment amount
export const getAdjustmentAmount = function (quote) {
    return quote && quote.total_adjustment
        ? parseFloat(quote.total_adjustment)
        : 0;
};

// get quote grand total
export const getGrandTotal = function (quote, isIndividualTax, tax_amount) {
    var total = getPreTaxTotal(quote, isIndividualTax, tax_amount);

    // add group tax
    if (quote && quote.tax_mode && quote.tax_mode === "group") {
        total += getTaxAmount(quote, isIndividualTax, tax_amount);
    }

    // do adjustment
    if (
        quote &&
        quote.total_adjustment_type &&
        quote.total_adjustment_type === "add"
    ) {
        total += getAdjustmentAmount(quote);
    } else if (
        quote &&
        quote.total_adjustment_type &&
        quote.total_adjustment_type === "deduct"
    ) {
        total -= getAdjustmentAmount(quote);
    } // end - quote.total_adjustment_type

    // first round of rounding up
    total = _round(parseFloat(total), 3);

    return _round(parseFloat(total), 2);
};

// calculate invoice deduct amount
export const calDeductAmount = function (invoice, isIndividualTax, tax_amount) {
    let pretax_total = getPreTaxTotal(invoice, isIndividualTax, tax_amount),
        deduct_amt =
            pretax_total > 0
                ? convertToTwoDecimalPoints(pretax_total * (4 / 100))
                : 0;
    // first round of rounding up
    deduct_amt = _round(parseFloat(deduct_amt), 3);
    return _round(parseFloat(deduct_amt), 2);
};

// get invoice deduct amount
export const getDeductAmount = function (invoice) {
    return invoice && invoice.deduct_amt ? parseFloat(invoice.deduct_amt) : 0;
};

// get amount due
export const getAmountDue = (invoice, isIndividualTax, tax_amount) => {
    let total =
        getGrandTotal(invoice, isIndividualTax, tax_amount) -
        getDeductAmount(invoice);
    // first round of rounding up
    total = _round(parseFloat(total), 3);
    return _round(parseFloat(total), 2);
};

export const getNetRevenue = (invoice, isIndividualTax, tax_amount) => {
    const pretax_total = getPreTaxTotal(invoice, isIndividualTax, tax_amount),
        deduct_amt = getDeductAmount(invoice);
    return pretax_total - deduct_amt;
};

// calculate tax amount
export const calculateTaxAmount = (total, tax_percent) => {
    return total > 0 ? _round(total * (tax_percent / 100), 2) : 0;
};

// sample
export const sampleFunc = function () {};
