import _random from 'lodash/random';

import { 
    GET_ACCOUNTS,
    GET_ACCOUNTS_BY,
    GET_ACCOUNTS_OPTIONS,
	GET_ACCOUNT,
	ADD_ACCOUNT,
	EDIT_ACCOUNT,
    DELETE_ACCOUNT,
    GET_DUPLICATES_ACCOUNT,
	FIND_DUPLICATES_ACCOUNT,
	MERGE_ACCOUNTS,
    RESET_ACCOUNT,
    RESET_ACCOUNTS,
    RESET_GET_ACCOUNTS_BY,
    RESET_DUPLICATES_ACCOUNT
} from '../actions/types';

const initialState = {
    accounts: null,
    total: null,
    firstDoc: null,
    lastDoc: null,
    accounts_loaded: false,
    accounts_by: null,
    accounts_options: null,
    account: null,
    new_account_id: null,
    updated: null,
    duplicate_accounts: null,
    duplicate_field: null,
    dupe_rand: false,
    by_rand: false,
    rand: false
};

export const accounts = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ACCOUNTS:
            return {
                ...state,
                accounts: ( payload.list || [] ),
                total: ( payload.total || null ),
                firstDoc: ( payload.firstDoc || null ),
                lastDoc: ( payload.lastDoc || null ),
                accounts_loaded: true, // fix this to true - change it if needed
                rand: _random(1,9999)
            };
        case GET_ACCOUNTS_BY:
            return {
                ...state,
                accounts_by: ( payload.list || [] ),
                by_rand: _random(1,9999)
            };
        case GET_ACCOUNTS_OPTIONS:
            return {
                ...state,
                accounts_options: ( payload.list || [] ),
                options_rand: _random(1,9999)
            };
        case GET_ACCOUNT:
            return {
                ...state,
                account: ( payload.account || {} ),
                rand: _random(1,9999)
            };
        case ADD_ACCOUNT:
            return {
                ...state,
                new_account_id: ( payload.id || false ),
                rand: _random(1,9999)
            };
        case EDIT_ACCOUNT:
        case DELETE_ACCOUNT:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case GET_DUPLICATES_ACCOUNT:
            return {
                ...state,
                duplicates_account: ( payload.data || {} ),
                dupe_rand: _random(1,9999)
            }; 
        case FIND_DUPLICATES_ACCOUNT:
            return {
                ...state,
                duplicates_account: ( payload.data || {} ),
                dupe_rand: _random(1,9999)
            }; 
        case MERGE_ACCOUNTS:
            return {
                ...state
            }; 
        
        case RESET_ACCOUNT:
            return {
                ...state,
                account: null,
                updated: null,
                rand: false
            };
        case RESET_ACCOUNTS:
            return {
                ...state,
                accounts: null,
                total: null,
                firstDoc: null,
                lastDoc: null,
                accounts_loaded: false,
                rand: false
            };
        case RESET_GET_ACCOUNTS_BY:
            return {
                ...state,
                accounts_by: null,
                by_rand: false
            };    
        case RESET_DUPLICATES_ACCOUNT:
            return {
                ...state,
                duplicate_accounts: null,
                duplicate_field: null,
                dupe_rand: false
            };
        default:
            return state;
    }
}