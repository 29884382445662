/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import ReactDataGrid from 'react-data-grid';
import shortid from 'shortid';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _toLower from 'lodash/toLower';
import _trim from 'lodash/trim';

import ContactForm from './contact_form';

import Table from '../../components/Table';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormMultiSelect from '../../components/FormMultiSelect';
import ButtonActions from '../../components/ButtonActions';

import { InfoButton, ErrorButton, ButtonGroup } from '../../styles/button';
import { SuccessTag, AmberTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
// import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
// import { isAdmin, hasAccessRights } from '../../helpers/auth';

import { contactSchema } from '../../schemas/contact';

import { COUNTRIES_LIST } from '../../data/const_countries';
import { STATE_OPTIONS } from '../../data/const_states';

class ResultTable extends React.Component {
    
    state = {
        openViewModal: false,
        modalData: false,
        openRemoveModal: false,
        removeModal: false,
    }

    handleFormUpdate = (newValue,key) => {
        const { users } = this.props;
        const { modalData } = this.state;
        let newData = ( modalData ? cloneCollections( modalData ) : {} );
        newData[key] = newValue;
        this.setState({ modalData: newData });
    }

    handleRemove = () => {
        const { onUpdate, import_contacts } = this.props;
        const { openRemoveModal, removeModal } = this.state;
        let newContacts = ( import_contacts && isArrayExists( import_contacts ) ? cloneCollections( import_contacts ) : [] );

        if ( removeModal && removeModal.unique_id && !_isEmpty( removeModal.unique_id ) ) {
            let pulled = _remove( newContacts, { unique_id: removeModal.unique_id } );
        }

        if ( onUpdate )
            onUpdate(newContacts);

        // close modal
        this.setState({ openRemoveModal: false, removeModal: false });
    }

    handleEditContact = () => {
        const { import_contacts, onUpdate } = this.props;
        const { openViewModal, modalData } = this.state;
        let newContacts = ( import_contacts && isArrayExists( import_contacts ) ? cloneCollections( import_contacts ) : [] );
        const index = ( import_contacts && isArrayExists( import_contacts ) && modalData && modalData.unique_id && !_isEmpty( modalData.unique_id ) ? _findIndex( import_contacts, { unique_id: modalData.unique_id } ) : -1 );
        if ( index >= 0 ) {
            newContacts[index] = ( modalData ? cloneCollections( modalData ) : {} );
        }

        if ( onUpdate )
            onUpdate(newContacts);

        // close modal
        this.setState({ openViewModal: false, modalData: false });
    }

    getColumns = () => {
        const defaultColProps = {
            resizable: true,
        };
        let columnCount = 0,
            columns = [
                {
                    position: -1,
                    key: 'unique_id',
                    name: '#',
                    width: 30,
                    formatter: this.renderActionCell,
                    ...defaultColProps
                },
                {
                    position: 0,
                    key: 'id',
                    name: 'ID',
                    width: 30,
                    formatter: ({ row, value }) => ( value && !_isEmpty( value ) ? value : '[new]' ),
                    ...defaultColProps
                },
            ];

        // only display import field
        _forEach( contactSchema, schema => {
            if ( schema.import_csv ) {
                columnCount++;
                columns.push({
                    position: columnCount, // based on selected position
                    key: schema.id,
                    name: ( schema.id === 'account_id' ? 'Account ID' : ( schema.label || '' ) ),
                    formatter: this.renderCell(schema),
                    width: 200,
                    ...defaultColProps
                });
            } // end - schema.import_csv
        });

        return _sortBy( columns, ['position'] );
    }

    renderEditForm = () => {
        const { csv_contacts, accounts, selectables, lead_source } = this.props;
        const { modalData } = this.state;
        return (
        <div>
            <ContactForm
                contact={( modalData || false )}
                csvContact={( modalData && modalData.email && !_isEmpty( modalData.email ) ? _find( csv_contacts, { email: modalData.email } ) : false )}
                accounts={( accounts || false )}
                selectables={( selectables || [] )}
                lead_source={( lead_source || [] )}
                onUpdate={(newValue) => this.setState({ modalData: newValue })} />
        </div>
        );
    }

    renderCell = ( schema ) => (props) => {
        const { selectables, lead_source } = this.props;
        const { row, value } = props;
        switch( schema.id ) {
            case 'billing_country':
                let selected_country = ( value ? _find( COUNTRIES_LIST, { value }) : false );
                return ( selected_country && selected_country.label ? selected_country.label : '' );
            default:
                return ( value || '' );
        }
    }

    renderActionCell = (props) => {
        const { row, value } = props;
        return (
        <ButtonGroup>
            <InfoButton size="small" noIconMargin="yes" minWidth="none" style={{ lineHeight: '1.15', marginRight: "10px" }} onClick={() => this.setState({ openViewModal: true, modalData: row })}><i className="fa fa-edit"></i></InfoButton>
            <ErrorButton size="small" noIconMargin="yes" minWidth="none" style={{ lineHeight: '1.15' }} onClick={() => this.setState({ openRemoveModal: true, removeModal: row })}><i className="fa fa-trash"></i></ErrorButton>
        </ButtonGroup>
        );
    }

    render() {
        const { authData, import_contacts } = this.props;
        const { openViewModal, modalData, openRemoveModal, removeModal } = this.state;
        return (
        <>

            <ReactDataGrid
                columns={this.getColumns()}
                rowGetter={i => import_contacts[i]}
                rowsCount={_size( import_contacts )}
                minHeight={600} />

            <ModalView 
                open={openViewModal}
                title="Edit Contact"
                maxWidth="lg"
                actionLabel="Update"
                disableBackdrop={true}
                onClose={() => this.setState({ openViewModal: false, modalData: false })}
                doAction={this.handleEditContact}
                contents={this.renderEditForm()} />

            <ModelDelete
                open={openRemoveModal}
                title={( removeModal && removeModal.last_name ? `Are you sure you want to remove this contact ( ${removeModal.last_name} )?` : false )}
                onClose={() => this.setState({ openRemoveModal: false, removeModal: false })}
                onDelete={this.handleRemove} />

        </>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(ResultTable);