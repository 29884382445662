import React from 'react';
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _sortBy from 'lodash/sortBy';
import _split from 'lodash/split';
import _remove from 'lodash/remove';
import _map from 'lodash/map';

/* eslint-disable */

import FormInput from '../../components/FormInput';
import FormDatePicker from '../../components/FormDatePicker';
import FormSelect from '../../components/FormSelect';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormRadio from '../../components/FormRadio';

import ListUpdates from '../../components/ListUpdates';

import { InfoButton, GreyButton } from '../../styles/button';
import { FormBox } from '../../styles/form';
import { SuccessTag } from '../../styles/tag';

import { isAdmin } from '../../helpers/auth';
import { isArrayExists, inArray } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';

import { productSchema } from '../../schemas/product';

import { YES_NO_OPTIONS } from '../../constants';

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

class ProductDetails extends React.Component {

    state = {
        randNum: false
    };

    handleFormUpdate = ( newValue, key ) => {
        const { onFormUpdate, product } = this.props;
        let newData = ( product && !_isEmpty( product ) ? cloneCollections( product ) : {} );

        switch( key ) {
            case 'sample_abc':
                newData[key] = newValue;
                break;
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    handlePriceUpdate = price_category => ( newValue, id ) => {
        const { onFormUpdate, product } = this.props;
        let newData = ( product && !_isEmpty( product ) ? cloneCollections( product ) : {} ),
            newPriceCategory = ( product && product.price_category && isArrayExists( product.price_category ) ? cloneCollections( product.price_category ) : [] );

        let index = _findIndex( newPriceCategory, { id } );
        if ( index >= 0 ) {
            newPriceCategory[index].price = newValue;
        } else {
            newPriceCategory.push({ 
                id,
                condition: ( price_category && price_category.value && !_isEmpty( price_category.value ) ? price_category.value : '' ),
                price: newValue
            })
        } // end - index

        newData['price_category'] = newPriceCategory;

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    getFieldOptions = (field) => {
        const { selectables, course_group, course_portfolio, regions, consultants } = this.props;
        switch( field.name ) {
            case 'status':
                return [{ value: 'active', label: 'Active' },{ value: 'disabled', label: 'Disabled' }];
            case 'publish':
                return YES_NO_OPTIONS;
            case 'schedule_status':
                let schedule_status = ( selectables && isArrayExists( selectables ) ? _find( selectables, { id: 'schedule_status' } ) : false );
                return getSelectOptions({ 
                    list: ( schedule_status && schedule_status.options && isArrayExists( schedule_status.options ) ? _sortBy( schedule_status.options, ['position'] ) : [] ), 
                    options: [{ value: '', label: 'Select an Option' }],
                    keys: { value: 'id', label: 'label' }
                });
            case 'delivery_method':
                let delivery_method = ( selectables && isArrayExists( selectables ) ? _find( selectables, { id: 'delivery_method' } ) : false );
                return getSelectOptions({ 
                    list: ( delivery_method && delivery_method.options && isArrayExists( delivery_method.options ) ? _sortBy( delivery_method.options, ['position'] ) : [] ), 
                    options: [{ value: '', label: 'Select an Option' }],
                    keys: { value: 'id', label: 'label' }
                });
            case 'currency':
                let currency = ( selectables && isArrayExists( selectables ) ? _find( selectables, { id: 'currency' } ) : false );
                return getSelectOptions({ 
                    list: ( currency && currency.options && isArrayExists( currency.options ) ? _sortBy( currency.options, ['position'] ) : [] ), 
                    options: [{ value: '', label: 'Select an Option' }],
                    keys: { value: 'id', label: 'label' }
                });
            case 'portfolio':
                return getSelectOptions({ 
                    list: ( course_portfolio && isArrayExists( course_portfolio ) ? _sortBy( course_portfolio, ['label'] ) : [] ), 
                    options: [{ value: '', label: 'Select an Option' }],
                    keys: { value: 'id', label: 'label' }
                });
            case 'region_id':
                return getSelectOptions({ 
                    list: ( regions && isArrayExists( regions ) ? _sortBy( regions, ['company_name'] ) : [] ), 
                    options: [{ value: '', label: 'Select an Option' }],
                    keys: { value: 'id', label: 'company_name' }
                });
            case 'consultant':
                return getSelectOptions({ 
                    list: ( consultants && isArrayExists( consultants ) ? _sortBy( consultants, ['label'] ) : [] ), 
                    options: [{ value: '', label: 'Select an Option' }],
                    keys: { value: 'value', label: 'label' }
                });
            default:
                return [];
        }
    }

    getFieldValue = (field,id) => {
        const { product } = this.props;
        switch( field.name ) {
            default:
                return ( product && product[field.name] || ( field.default || '' ) );
        }
    }

    isLMSLinked = () => {
        const { product } = this.props;
        return ( product && product.lms_linked && product.lms_linked === 'yes' ? true : false );
    }

    isFieldDisabled = (schema) => {
        const { authData } = this.props;
        var disabled = false;

        if ( schema && schema.disabled && isArrayExists( schema.disabled ) ) {
            schema.disabled.forEach( condition => {
                switch( condition ) {
                    case 'admin':
                        if ( !isAdmin( authData ) )
                            disabled = true;
                        break;
                }
            });
        } // end - schema

        return disabled
    }

    getField = (id) => {
        let schema = _find( productSchema, { id } );
        return ( schema ? {
            name: ( schema.id || '' ),
            label: ( schema.label || '' ) + ( isSchemaRequired(schema,'update') ? ' (Required)' : '' ),
            field_type: ( schema.field || '' ),
            default: ( schema.default || null ),
            disabled: ( this.isLMSLinked() ? true : ( schema.disabled ? this.isFieldDisabled( schema ) : false ) )
        } : null );
    }

    renderPriceCategory = (field) => {
        const { selectables, product } = this.props;
        var price_categories = ( selectables && isArrayExists( selectables ) ? _find( selectables, { id: 'price_category' } ) : false );
        return (
        <Grid container spacing={1}>
            { price_categories && price_categories.options && isArrayExists( price_categories.options ) ? price_categories.options.map(price_category => {
                var category = ( product.price_category && isArrayExists( product.price_category ) ? _find( product.price_category, { id: price_category.id } ) : false );
                return (
                <Grid item xs={4} key={price_category.id}>
                    <FormInput 
                        label={( category && category.condition && !_isEmpty( category.condition ) ? category.condition : price_category.value )} 
                        name={price_category.id} 
                        type="number" 
                        value={( category && category.price ? category.price : null )} 
                        disabled={( this.isLMSLinked() ? true : false )}
                        labelProps={{
                            shrink: true,
                            style: { fontSize: "14px", fontWeight: "700", textTransform: "uppercase" }
                        }} 
                        onChange={this.handlePriceUpdate(price_category)} />
                </Grid>
                )
            }) : null }
        </Grid>
        )
    }

    renderDeliveryMethodOld = () => {
        const { selectables, product } = this.props;
        var delivery_methods = ( selectables && isArrayExists( selectables ) ? _find( selectables, { id: 'delivery_method' } ) : false );
        const field = this.getField('delivery_method');
        return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <InputLabel shrink={true} style={{ fontSize: "16px", fontWeight: "700", textTransform: "uppercase" }}>{field.label || ''}</InputLabel>
            </Grid>
            { delivery_methods && delivery_methods.options && isArrayExists( delivery_methods.options ) ? delivery_methods.options.map(dmethod => {
                return (
                <Grid item xs={4} key={dmethod.id}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={( product.delivery_method && product.delivery_method.indexOf(dmethod.id) > -1 ? true : false )}
                                disabled={field.disabled || false}
                                onChange={(event) => {
                                    let dmlist = _split( product.delivery_method, ',' );
									if ( event.target.checked ) {
										if ( !_find( dmlist, (i) => i === dmethod.id ) )
											dmlist.push(dmethod.id);
									} else {
										if ( _find( dmlist, (i) => i === dmethod.id ) ) {
											var pulled = _remove( dmlist, (i) => i === dmethod.id );
										}
									}
									let dmstring = '';
									if ( dmlist.length > 0 ) {
										_forEach( dmlist, (i) => {
											if ( !_isEmpty( i ) ) {
												dmstring += ( !_isEmpty( dmstring ) ? ',' : '' ) + i;
											}
										} );
									}
                                    this.handleFormUpdate( dmstring, 'delivery_method' )
                                }}
                                value={dmethod.id}
                                color="primary" />
                        }
                        label={dmethod.label || ''} />
                </Grid>
                )
            }) : null }
        </Grid>
        )
    }

    renderDeliveryMethod = () => {
        const { selectables, product } = this.props;
        var delivery_methods = ( selectables && isArrayExists( selectables ) ? _find( selectables, { id: 'delivery_method' } ) : false );
        const physical_class_options = [];
		if ( delivery_methods && delivery_methods.options && isArrayExists( delivery_methods.options ) ) {
			delivery_methods.options.forEach((item) => {
				if ( item.id === '270' || item.id === '272' || item.id === '273' || item.id === '274' ) {
					physical_class_options.push({
                        value: item.id,
                        label: item.label,
                    });
				}
			});
		}
        return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <InputLabel shrink={true} style={{ fontSize: "16px", fontWeight: "700", textTransform: "uppercase" }}>Delivery Options Available</InputLabel>
            </Grid>
           <Grid item xs={12} style={{ paddingLeft: '5px', paddingTop: '10px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FormRadio 
                            label="Online" 
                            name="delivery_method_online" 
                            value={( product.delivery_method_online || '' )} 
                            disabled={( this.isLMSLinked() ? true : false )}
                            options={[
                                { label: 'IL', value: 'il' },
                                { label: 'IL + MS', value: 'il_ms' },
                                { label: 'N/A', value: '' },
                            ]} 
                            onChange={this.handleFormUpdate} />
                    </Grid>
                    <Grid item xs={4}>
                        <FormRadio 
                            label="Physical" 
                            name="delivery_method_physical" 
                            value={( product.delivery_method_physical || '' )} 
                            disabled={( this.isLMSLinked() ? true : false )}
                            options={[
                                { label: 'IL', value: 'il' },
                                { label: 'IL + MS', value: 'il_ms' },
                                { label: 'N/A', value: '' },
                            ]} 
                            onChange={this.handleFormUpdate} />
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel shrink={true} style={{ fontSize: "16px", fontWeight: "700", textTransform: "uppercase" }}>Hybrid</InputLabel>
                        {_map( [
							{ label: 'IL Online', value: 'il_online' },
							{ label: 'MS Online', value: 'ms_online' },
							{ label: 'IL Physical', value: 'il_physical' },
							{ label: 'MS Physical', value: 'ms_physical' },
							{ label: 'SP', value: 'sp' },
						], option => {
							return (
                            <div key={option.value}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={( product.delivery_method_hybrid && product.delivery_method_hybrid.indexOf(option.value) > -1 ? true : false )}
                                            disabled={( this.isLMSLinked() ? true : false )}
                                            onChange={(event) => {
                                                let dmlist = _split( product.delivery_method_hybrid, ',' );
                                                if ( event.target.checked ) {
                                                    if ( !_find( dmlist, (i) => i === option.value ) )
                                                        dmlist.push(option.value);
                                                } else {
                                                    if ( _find( dmlist, (i) => i === option.value ) ) {
                                                        var pulled = _remove( dmlist, (i) => i === option.value );
                                                    }
                                                }
                                                let dmstring = '';
                                                if ( dmlist.length > 0 ) {
                                                    _forEach( dmlist, (i) => {
                                                        if ( !_isEmpty( i ) ) {
                                                            dmstring += ( !_isEmpty( dmstring ) ? ',' : '' ) + i;
                                                        }
                                                    } );
                                                }
                                                this.handleFormUpdate( dmstring, 'delivery_method_hybrid' )
                                            }}
                                            value={option.value}
                                            color="primary" />
                                    }
                                    label={option.label || ''} />
                            </div>
							)
						})}
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{
                            padding: '0px 15px 10px 5px',
                            fontWeight: '700',
                            fontSize: '14px',
                            fontStyle: 'italic',
                            color: '#212121'
                        }}>
                            IL: Instructor-Led, MS: Mentor Supported, SP: Self-Paced
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingLeft: '5px' }}>
                <FormRadio 
                    label="Physical Class Requirement" 
                    name="delivery_method_physical" 
                    value={( product.physical_class_req || '' )} 
                    disabled={( this.isLMSLinked() ? true : false )}
                    options={ physical_class_options } 
                    onChange={this.handleFormUpdate} />
            </Grid>
        </Grid>
        )
    }

    renderField = (id) => {
        let field = this.getField(id);
        if ( field && field.field_type && !_isEmpty( field.field_type ) ) {
            switch( field.field_type ) {
                case 'text':
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'text_number':
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'email':
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'datepicker':
                    return <FormDatePicker {...field} noDefaultVal={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'select':
                    return <FormSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} />;
                case 'multiselect':
                    return <div style={{ paddingTop: "7px" }}><FormMultiSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} /></div>;
                case 'special':
                    if ( id === 'price_category' ) {
                        return this.renderPriceCategory(field);
                    }
                    break;
            }
        } // end - field.field_type
    }

    renderUpdatesBox = () => {
        const { classes, product } = this.props;
        return (
        <div>
            <Typography variant="h4" className={classes.boxheading}>Updates</Typography>
            <ListUpdates 
                list={( product && product.updates && isArrayExists( product.updates ) ? product.updates : false )} />
        </div>
        );
    }

    renderDetailsBox = () => {
        const { classes, product } = this.props;
        return (
        <FormBox>
            <Typography variant="h4" className={classes.boxheading}>
                Product Info
                { this.isLMSLinked() ? <SuccessTag style={{ marginLeft: "8px" }}>LMS Product</SuccessTag> : null }
            </Typography>

            <Grid container spacing={3}>

                <Grid item xs={6}>{this.renderField('name')}</Grid>
                <Grid item xs={6}>{this.renderField('status')}</Grid>

                <Grid item xs={6}>{this.renderField('course_group_label')}</Grid>
                <Grid item xs={6}>{this.renderField('course_duration')}</Grid>

                <Grid item xs={12}>{this.renderDeliveryMethod()}</Grid>
                <Grid item xs={6}>{this.renderField('consultant')}</Grid>
                <Grid item xs={6}> </Grid>

                <Grid item xs={6}>{this.renderField('start_date')}</Grid>
                <Grid item xs={6}>{this.renderField('end_date')}</Grid>

                <Grid item xs={6}>{this.renderField('schedule_status')}</Grid>
                <Grid item xs={6}>{this.renderField('publish')}</Grid>
                <Grid item xs={6}>{this.renderField('portfolio')}</Grid>
                <Grid item xs={6}>{this.renderField('region_id')}</Grid>

            </Grid>

            <Grid container spacing={3} style={{ paddingTop: "10px" }}>
                <Grid item xs={6}>{this.renderField('location')}</Grid>
                <Grid item xs={6}>{this.renderField('city')}</Grid>
            </Grid>

            <Grid container spacing={3} style={{ paddingTop: "10px" }}>
                <Grid item xs={6}>{this.renderField('currency')}</Grid>
                <Grid item xs={12}>{this.renderField('price_category')}</Grid>
            </Grid>

            <div style={{ paddingTop: "10px", textAlign: 'right', color: '#999', fontSize: '1.25rem' }}>
                <div>{ product.created_on ? 'Created on ' + getMomentTime( (product.created_on) , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
                <div>{ product.modified_on ? 'Last Modified on ' + getMomentTime( (product.modified_on) , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
            </div>

        </FormBox>
        );
    }
   
    render() {
        return (
        <div>

            {this.renderDetailsBox()}

            <Grid container spacing={3} style={{ marginTop: "30px" }}>
                <Grid item xs={12}>
                    {this.renderUpdatesBox()}
                </Grid>
            </Grid>


        </div>
        )
    }

}

export default compose(
    withStyles(useStyles),
    withRouter
)(ProductDetails);