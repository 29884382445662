/* eslint-disable */
import _random from 'lodash/random';

import {
    M_GET_INVOICE_TYPE,
    M_INVOICE_TYPE_UPDATED
} from '../types';

import { 
	toggleModalDeleting, toggleModalProcessing, 
	toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
// import { appChangesReset } from '../misc';

import { fb_getInvoiceType, fb_addInvoiceType, fb_updateInvoiceType, fb_deleteInvoiceType } from './fb';

export const getInvoiceType = () => {
	return dispatch => {

		fb_getInvoiceType(list => {
			dispatch({ 
                type: M_GET_INVOICE_TYPE, 
                payload: { list } 
            });
		});

	}
}

export const addNewInvoiceType = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addInvoiceType(formData)
		.then( results => {
			dispatch({ type: M_INVOICE_TYPE_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Product Line Added");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new Product Line' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const updateInvoiceType = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));
		dispatch(toggleModalDeleting(true));

		fb_updateInvoiceType(formData)
		.then( results => {
			dispatch({ type: M_INVOICE_TYPE_UPDATED });
			dispatch(toggleModalProcessing(false));
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Product Line Updated");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update Product Line' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteInvoiceType = (id) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteInvoiceType(id)
		.then( results => {
			dispatch({ type: M_INVOICE_TYPE_UPDATED });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Product Line Deleted");
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete Product Line' ) );
			triggerErrorAlert(errMsg);
		});

	}
}