/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_EMAILS_BY,
	GET_EMAIL,
	ADD_EMAIL,
	EDIT_EMAIL,
	DELETE_EMAIL,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getEmailsBy, fb_getEmail, fb_addEmail, fb_updateEmail, fb_deleteEmail } from './fb';

export const getEmailsBy = (type,id) => {
	return dispatch => {
		
		fb_getEmailsBy(type,id)  
		.then(list => {
			dispatch({
				type: GET_EMAILS_BY,
				payload: { list }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_EMAILS_BY,
				payload: { list: [] }
			});
		});

	}
}

export const getEmail = (email_id) => {
	return dispatch => {

		fb_getEmail(email_id)  
		.then(email => {
			dispatch({
				type: GET_EMAIL,
				payload: { email }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve email' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_EMAIL,
				payload: { email: {} }
			});
		});

	}
}

export const addNewEmail = (formData,type,type_id) => {
	return dispatch => {
		var apiNum = _random(1,9999),
			email = false;
		dispatch(toggleModalProcessing(true,apiNum));
		dispatch(toggleLoader(true));

		fb_addEmail(formData)
		.then( results => {
			email = ( results && results.new_email_id && !_isEmpty( results.new_email_id ) ? { id: results.new_email_id } : false );
			return fb_getEmailsBy(type,type_id);
		})
		.then( list => {
			if ( email && email.id && !_isEmpty( email.id ) ) {
				// send updates
				dispatch({
					type: GET_EMAILS_BY,
					payload: { list }
				});

				// updated
				dispatch({ type: ADD_EMAIL, payload: { id: email.id } });
				dispatch(toggleModalProcessing(false));
				dispatch(toggleLoader(false));
				triggerSuccessAlert("Email Added");


			} else {
				dispatch(toggleModalProcessing(false,apiNum));
				dispatch(toggleLoader(false));
				triggerErrorAlert('Missing new email ID');
			} // end - email
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new email template' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const editEmail = (formData,type,type_id) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));
		dispatch(toggleLoader(true));

		fb_updateEmail(formData)
		.then( results => {
			return fb_getEmailsBy(type,type_id);
		})
		.then(list => {
			// send updates
			dispatch({
				type: GET_EMAILS_BY,
				payload: { list }
			});

			// updated
			dispatch({ type: EDIT_EMAIL });
			dispatch(toggleLoader(false));
			dispatch(toggleModalProcessing(false));
			dispatch(appChangesReset());
			triggerSuccessAlert("Email Updated");

		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update email template' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteEmail = (id,type,type_id) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteEmail(id)
		.then( results => {
			return fb_getEmailsBy(type,type_id)
		})
		.then( list => {
			// send updates
			dispatch({
				type: GET_EMAILS_BY,
				payload: { list }
			});

			// trigger updated
			dispatch({ type: DELETE_EMAIL });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Email Deleted");

		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete email' ) );
			triggerErrorAlert(errMsg);
		});

	}
}