/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';
import _last from 'lodash/last';
import _merge from 'lodash/merge';

import {
	GET_PRODUCTS,
	GET_ACCOUNTS_BY,
	GET_CONTACTS_BY,
	GET_POTENTIALS_BY,
	GET_QUOTES_BY,
	GET_INVOICES_BY,
	GET_PRODUCTS_BY,
	GET_PRODUCTS_OPTIONS,
	GET_PRODUCT,
	ADD_PRODUCT,
	EDIT_PRODUCT,
	DELETE_PRODUCT,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections } from '../../helpers/data';
import { isArrayExists } from '../../helpers/validation';
import { appChangesReset } from '../misc';

import { fb_getProducts, fb_getProductsFirstBatch, fb_getProductsForTheRest, fb_getProductsBy, fb_getProductUsage, fb_getProductsOptions, fb_getProduct, fb_addProduct, fb_updateProduct, fb_deleteProduct, fb_syncSchedules, fb_syncCourseProfileToProducts } from './fb';

/* helper start */
// const getProductsByBatch = (dispatch,props,callback) => {
// 	const { perPage } = props;
// 	var mainList = [];

// 	fb_getProductsFirstBatch(props)
// 	.then(({ list, accessLimited }) => {

// 		var endAt = false;
// 		mainList = cloneCollections( list );

// 		// calculate endAt
// 		if ( isArrayExists( mainList ) ) {
// 			let lastItem = _last( mainList );
// 			if ( lastItem && lastItem.modified_on ) {
// 				endAt = lastItem.modified_on;
// 			} // end - lastItem
// 		} // end - mainList

// 		// dispatch first batch
// 		dispatch({
// 			type: GET_PRODUCTS,
// 			payload: { list, loaded: ( !accessLimited && isArrayExists( mainList ) && endAt ? false : true ) }
// 		});

// 		// trigger callback
// 		if ( callback ) {
// 			callback( dispatch );
// 		} // end - callback

// 		return ( !accessLimited && isArrayExists( mainList ) && endAt ? fb_getProductsForTheRest( endAt ) : false );
// 	})
// 	.then(list => {

// 		if ( list && isArrayExists( list ) ) {
// 			// dispatch other items
// 			dispatch({
// 				type: GET_PRODUCTS,
// 				payload: { list: _merge( mainList, list ), loaded: true }
// 			});
// 		} // end - list
	
// 		// else - do nothing
// 	})
// 	.catch(error => {
// 		var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
// 		triggerErrorAlert(errMsg);
// 		dispatch({
// 			type: GET_PRODUCTS,
// 			payload: { list: [] }
// 		});
// 	});

// }



/* helper end */

export const getProducts = (props) => {
	return dispatch => {

		// getProductsByBatch( dispatch, ( props || {} ) );

		fb_getProducts(props)  
		.then(({ list, total, firstDoc, lastDoc }) => {
			dispatch({
				type: GET_PRODUCTS,
				payload: { list, total, firstDoc, lastDoc }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_PRODUCTS,
				payload: { list: [] }
			});
		});

	}
}

export const getProductsBy = (type,id) => {
	return dispatch => {

		fb_getProductsBy(type,id)  
		.then(list => {
			dispatch({
				type: GET_PRODUCTS_BY,
				payload: { list }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_PRODUCTS_BY,
				payload: { list: {} }
			});
		});

	}
}

export const getProductUsage = (id,user) => {
	return dispatch => {

		fb_getProductUsage(id,user)  
		.then(data => {
			dispatch({ type: GET_ACCOUNTS_BY, payload: { list: ( data && data.accounts ? data.accounts : [] ) } });
			dispatch({ type: GET_CONTACTS_BY, payload: { list: ( data && data.contacts ? data.contacts : [] ) } });
			dispatch({ type: GET_POTENTIALS_BY, payload: { list: ( data && data.potentials ? data.potentials : [] ) } });
			dispatch({ type: GET_QUOTES_BY, payload: { list: ( data && data.quotes ? data.quotes : [] ) } });
			dispatch({ type: GET_INVOICES_BY, payload: { list: ( data && data.invoices ? data.invoices : [] ) } });
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({ type: GET_ACCOUNTS_BY, payload: { list: [] } });
			dispatch({ type: GET_CONTACTS_BY, payload: { list: [] } });
			dispatch({ type: GET_POTENTIALS_BY, payload: { list: [] } });
			dispatch({ type: GET_QUOTES_BY, payload: { list: [] } });
			dispatch({ type: GET_INVOICES_BY, payload: { list: [] } });
		});

	}
}

export const getProductsOptions = (props) => {
	return dispatch => {

		fb_getProductsOptions(list => {
			dispatch({
				type: GET_PRODUCTS_OPTIONS,
				payload: { list }
			});	
		},props);

	}
}

export const getProduct = (product_id) => {
	return dispatch => {

		fb_getProduct(product_id)  
		.then(product => {
			dispatch({
				type: GET_PRODUCT,
				payload: { product }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_PRODUCT,
				payload: { product: {} }
			});
		});

	}
}

export const addNewProduct = (formData,history) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addProduct(formData)
		.then( results => {
			if ( results && results.new_product_id && !_isEmpty( results.new_product_id ) ) {

				fb_getProduct( results.new_product_id )
				.then( product => {

					// updated
					dispatch({ type: GET_PRODUCT, payload: { product } });
					dispatch(toggleModalProcessing(false));
					triggerSuccessAlert("Product Added");

					// redirect to product page
					history.push("/products/" + results.new_product_id);

				});

			} else {
				dispatch(toggleModalProcessing(false,apiNum));
				triggerErrorAlert('Missing new product ID');
			}
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new product' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const editProduct = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateProduct(formData)
		.then( results => {

			fb_getProduct(formData.id)
			.then(product => {
				// push to list
				dispatch({ type: GET_PRODUCT, payload: { product } });

				// updated
				dispatch({ type: EDIT_PRODUCT });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Product Updated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update product' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteProduct = (id,props) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteProduct(id)
		.then( results => {
			// retrieve new list
			fb_getProducts(props)  
			.then(({ list, total, firstDoc, lastDoc }) => {
				dispatch({
					type: GET_PRODUCTS,
					payload: { list, total, firstDoc, lastDoc }
				});

				// trigger updated
				dispatch({ type: DELETE_PRODUCT });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("Product Deleted");

			});
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete list' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const syncScheduleDataWithLMS = (props) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_syncSchedules()
		.then(() => {
			setTimeout(() => {
				fb_getProducts(props)  
				.then(({ list, total, firstDoc, lastDoc }) => {
					dispatch({
						type: GET_PRODUCTS,
						payload: { list, total, firstDoc, lastDoc }
					});
					
					dispatch(toggleLoader(false));
					triggerSuccessAlert("Data Synced!");
				})
				.catch(error => {
					var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
					triggerErrorAlert(errMsg);
					dispatch({
						type: GET_PRODUCTS,
						payload: { list: [] }
					});
				});	
			},3000);
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to sync data with LMS' ) );
			dispatch(toggleLoader(false));
			triggerErrorAlert(errMsg);
		});

  	}
}

export const syncCourseDataWithLMS = (props) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_syncCourseProfileToProducts()
		.then(() => {
			setTimeout(() => {
				fb_getProducts(props)  
				.then(({ list, total, firstDoc, lastDoc }) => {
					dispatch({
						type: GET_PRODUCTS,
						payload: { list, total, firstDoc, lastDoc }
					});
					
					dispatch(toggleLoader(false));
					triggerSuccessAlert("Data Synced!");
				})
				.catch(error => {
					var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
					triggerErrorAlert(errMsg);
					dispatch({
						type: GET_PRODUCTS,
						payload: { list: [] }
					});
				});	
			},3000);
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to sync data with LMS' ) );
			dispatch(toggleLoader(false));
			triggerErrorAlert(errMsg);
		});

  	}
}