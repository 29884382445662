import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import _isEmpty from 'lodash/isEmpty';

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader'
import SnackBarSave from '../../components/SnackBarSave';
import ItemNotFound from '../../components/ItemNotFound';

import Details from './details.js';
import ContactsList from '../../components/ListContacts';
import QuotesList from '../../components/ListQuotes';
import EmailsList from '../../components/ListEmails';

import { InfoButton, GreyButton } from '../../styles/button';

import { hasAccessRights } from '../../helpers/auth'; 
import { cloneCollections } from '../../helpers/data';
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { getPotential, editPotential } from '../../actions/potentials';
import { getContactsBy } from '../../actions/contacts';
import { getQuotesBy } from '../../actions/quotes';
import { getEmailsBy } from '../../actions/emails';
import { getUsersOptions } from '../../actions/users';
import { getSelectables } from '../../actions/m_selectables';
import { appChangesMade, resetRedux } from '../../actions/misc';

import { potentialSchema } from '../../schemas/potential';

class PotentialPage extends React.Component {

    state = {
        currentTab: 'details',
        potential: false,
        contacts: false,
        contactsRand: false,
        quotes: false,
        quotesRand: false,
        emails: false,
        emailsRand: false,
        randNum: false
    };

    componentDidMount() {
        const { potential_id } = this.props.match.params;
        
        // retrieve potential details
        this.props.dispatch(getPotential(potential_id));

        // get contacts by
        this.props.dispatch(getContactsBy('potentials',potential_id));

        // get quotes by
        this.props.dispatch(getQuotesBy('potentials',potential_id));

        // get emails by
        this.props.dispatch(getEmailsBy('potentials',potential_id));

        // get selectables
        if ( !this.props.selectablesList )
            this.props.dispatch(getSelectables());

        // get all users
        if ( !this.props.usersOptions )
            this.props.dispatch(getUsersOptions());

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentPotential, usersOptions, randNum, 
            contactsByList, contactsByListRand,
            quotesByList, quotesByListRand,
            emailsByList, emailsByListRand } = this.props;

        // for potential
        if ( currentPotential && usersOptions && randNum && randNum !== this.state.randNum ) {
            this.setState({ potential: cloneCollections( currentPotential ), randNum });
        } // end - currentPotential

        // for contacts
        if ( contactsByList && contactsByListRand && contactsByListRand !== this.state.contactsRand ) {
            this.setState({ contacts: cloneCollections( contactsByList ), contactsRand: contactsByListRand });
        }

        // for quotes
        if ( quotesByList && quotesByListRand && quotesByListRand !== this.state.quotesRand ) {
            this.setState({ quotes: cloneCollections( quotesByList ), quotesRand: quotesByListRand });
        }

        // for emails
        if ( emailsByList && emailsByListRand && emailsByListRand !== this.state.emailsRand ) {
            this.setState({ emails: cloneCollections( emailsByList ), emailsRand: emailsByListRand });
        }
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('potential'));
    }

    handleRefresh = (type) => {
        const { potential_id } = this.props.match.params;
        switch( type ) {
            case 'contacts':
                this.setState({ contacts: false });
                this.props.dispatch(getContactsBy('potentials',potential_id));
                break;
            case 'quotes':
                this.setState({ quotes: false });
                this.props.dispatch(getQuotesBy('potentials',potential_id));
                break;
            case 'emails':
                this.setState({ emails: false });
                this.props.dispatch(getEmailsBy('potentials',potential_id));
                break;
        }
    }

    handleSaveChanges = (event) => {
        const { potential } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do error check
        potentialSchema.forEach(schema => {
            formData[schema.id] = ( potential && potential[schema.id] ? cloneCollections( potential[schema.id] ) : schema.default );
            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editPotential(formData));
        } // end - error

    }

    handleFormUpdate = (newValue) => {
        this.setState({ potential: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    handleTabChange = (newTab) => {
        const { changesMade } = this.props;
        if ( changesMade ) {
            alert("Please save changes first before proceed");
        } else {
            this.setState({ currentTab: newTab });
        }
    }

    getLinks = () => {
        const { authData } = this.props;
        let links = [{ id: 'details', label: 'Potential Info', icon: 'fa fa-cog' }];

        if ( authData && hasAccessRights( authData, ['ctr','ctw']) )
            links.push({ id: 'contacts', label: 'Contacts', icon: 'fa fa-user' });

        if ( authData && hasAccessRights( authData, ['qtr','qtw']) )
            links.push({ id: 'quotes', label: 'Quotes', icon: 'fa fa-edit' });

        if ( authData && hasAccessRights( authData, ['pts']) )
            links.push({ id: 'emails', label: 'Emails', icon: 'fa fa-envelope' });

        return links;
    }

    renderTabContents = () => {
        const { potential_id } = this.props.match.params;
        const { authData, usersOptions, selectablesList, changesMade } = this.props;
        const { currentTab, potential, contacts, quotes, emails } = this.state;
        const tabProps = {
            authData: ( authData || false ),
            potential: ( potential || false ),
            changesMade: ( changesMade || false ),
            onFormUpdate: this.handleFormUpdate
        };
        switch( currentTab ) {
            case 'details':
                return <Details 
                    users={( usersOptions || [] )} 
                    selectables={( selectablesList || [] )}
                    {...tabProps}
                    updateActions={[getPotential(potential_id)]} 
                    />;
            case 'contacts':
                return ( contacts ? <ContactsList 
                    contacts={contacts} 
                    changesMade={changesMade}
                    onRefresh={this.handleRefresh}
                    noAddNew={true}
                    {...tabProps} /> : <DotsLoader /> );    
            case 'quotes':
                return ( quotes ? <QuotesList
                    users={( usersOptions || [] )}
                    contact={( potential && potential.contacts_linked && isArrayExists( potential.contacts_linked ) && potential.contacts_linked[0] && isObjectExists( potential.contacts_linked[0] ) ? potential.contacts_linked[0] : null )}
                    account= {( potential && potential.accounts_linked && isArrayExists( potential.accounts_linked ) && potential.accounts_linked[0] && isObjectExists( potential.accounts_linked[0] ) ? potential.accounts_linked[0] : null )}
                    quotes={quotes}
                    changesMade={changesMade}
                    onRefresh={this.handleRefresh}
                    {...tabProps} /> : <DotsLoader /> ); 
            case 'emails': 
                return ( emails ? <EmailsList
                    type="potentials"
                    typeID={( potential_id || false )}
                    emails={emails}
                    account= {( potential && potential.accounts_linked && isArrayExists( potential.accounts_linked ) && potential.accounts_linked[0] && isObjectExists( potential.accounts_linked[0] ) ? potential.accounts_linked[0] : null )}
                    contact={( potential && potential.contacts_linked && isArrayExists( potential.contacts_linked ) && potential.contacts_linked[0] && isObjectExists( potential.contacts_linked[0] ) ? potential.contacts_linked[0] : null )}
                    potential={( potential || null )}
                    changesMade={changesMade}
                    onRefresh={this.handleRefresh}
                    {...tabProps} /> : <DotsLoader /> );
        }
    }

    renderTabs = () => {
        const { currentTab } = this.state;
        const btnStyle = {
            textAlign: 'center',
            minWidth: '175px',
            padding: "10px 5px",
            marginRight: "5px"
        };
        return (
        <Box display="flex" justifyContent="center" alignItems="center">
            {this.getLinks().map((button,index) => {
                if ( button.id === currentTab) {
                    return <InfoButton key={index} style={btnStyle} onClick={event => event.preventDefault()}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</InfoButton>
                } else {
                    return <GreyButton key={index} style={btnStyle} onClick={this.handleTabChange.bind(this,button.id)}>{ button.icon ? <i className={button.icon}></i> : '' }{button.label}</GreyButton>
                }
            })}
        </Box>
        )
    }

    renderContents() {
        return (
        <div>

            {this.renderTabs()}

            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                {this.renderTabContents()}
            </Paper>

            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
            </div>

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { randNum } = this.state;
        const { currentPotential } = this.props;
        return <AppWrapper 
                title="Edit Potential"
                subtitle="Potentials"
                back="/potentials"
                breadcrumbs={[
                    { url: '/potentials', label: 'Potentials' },
                    { label: 'Edit' }
                ]}
                onLoad={( !( randNum ) ? true : false )}
                contents={ currentPotential && currentPotential.id && !_isEmpty( currentPotential.id ) ? this.renderContents() : <ItemNotFound item="Potential" />} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentPotential: state.potentials && state.potentials.potential || null,
        randNum: state.potentials && state.potentials.rand || null,
        contactsByList: state.contacts && state.contacts.contacts_by || null,
        contactsByListRand: state.contacts && state.contacts.by_rand || null,
        quotesByList: state.quotes && state.quotes.quotes_by || null,
        quotesByListRand: state.quotes && state.quotes.by_rand || null,
        emailsByList: state.emails && state.emails.emails_by || null,
        emailsByListRand: state.emails && state.emails.by_rand || null,
        usersOptions: state.users && state.users.users_options || null,
        selectablesList: state.maintenance && state.maintenance.selectables || null,
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(PotentialPage);