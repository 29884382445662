import React from 'react';
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _split from 'lodash/split';
import _remove from 'lodash/remove';
import _startsWith from 'lodash/startsWith';
import _concat from 'lodash/concat';
import _toString from 'lodash/toString';

/* eslint-disable */

import ColumnSelector from './columns';
import ColumnsCalculation from './columns_calculation';

import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormSelect2 from '../../components/FormSelect2';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormDatePicker from '../../components/FormDatePicker';
import FormMultiOptions from '../../components/FormMultiOptions';
// import FormCheckbox from '../../components/FormCheckbox';
import FormRadio from '../../components/FormRadio';

// import { InfoButton, GreyButton } from '../../styles/button';
// import { WrapWord } from '../../styles/misc';
import { FormBox } from '../../styles/form';

import { isAdmin } from '../../helpers/auth';
import { reverseUsersValues } from '../../helpers/users';
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, getSelectValues, calculatePosition } from '../../helpers/data';
import { getMomentTime, getDateBasedCondition, getMomentTimestamp, getMomentNow } from '../../helpers/date';
import { isSchemaRequired, isReportData, isReportVisible, isValidSchema } from '../../helpers/schemas';

import { accountSchema } from '../../schemas/account';
import { contactSchema } from '../../schemas/contact';
import { potentialSchema } from '../../schemas/potential';
import { quoteSchema } from '../../schemas/quote';
import { invoiceSchema } from '../../schemas/invoice';
import { creditNoteSchema } from '../../schemas/credit_note';
import { productSchema } from '../../schemas/product';
import { reportTemplateSchema } from '../../schemas/report_template';

import { CORE_MODULES, CONDITION_OPTIONS_DEFAULT, CONDITION_OPTIONS_NUMBER, CONDITION_OPTIONS_TIMESTAMP, YES_NO_OPTIONS } from '../../constants';

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

class ReportDetails extends React.Component {

    state = {
        randNum: false
    };

    // do check if sort_by column is still in selection
    updateSortBy = (newData,template) => {
        if ( template && template['sort_by'] && isArrayExists( template['sort_by'] ) ) {
            _forEach( template['sort_by'], sortby => {
                if ( newData['columns'] && isArrayExists( newData['columns'] ) ) {
                    // if can't find in columns - remove it
                    if ( !_find( newData['columns'], (col) => col === sortby.column ) ) {
                        let pulled = _remove( newData['sort_by'], { id: sortby.id } );
                        // re-arrange sortby
                        newData['sort_by'] = calculatePosition( newData['sort_by'] );
                    } // end - newData['columns']
                } else {
                    // if no columns selected - reset sort_by
                    newData['sort_by'] = [];
                } // end - newData['columns']
            });
        } // end - template['sort_by']
        return newData;
    }

    handleFormUpdate = ( newValue, key ) => {
        const { onFormUpdate, template, categories, users } = this.props;
        let newData = ( template && !_isEmpty( template ) ? cloneCollections( template ) : {} );
        
        switch( key ) {
            case 'report_owners':
                newData[key] = reverseUsersValues( newValue, users );
                break;
            case 'category_id':
                newData[key] = newValue;
                var selected = ( categories && isArrayExists( categories ) ? _find( categories, { id: newValue }) : false );
                if ( selected && selected.name )
                    newData['category_label'] = selected.name;
                break;
            case 'related_modules':
                // disable multiple modules for now
                newData[key] = ( newValue && !_isEmpty( newValue ) && isValidSchema( newValue ) ? [ newValue ] : [] );

                // when related_modules changed, need to make sure columns selected are updated as well
                var columns = ( newData['columns'] && isArrayExists( newData['columns'] ) ? cloneCollections( newData['columns'] ) : [] );
                if ( columns && isArrayExists( columns ) ) {
                    _forEach( columns, col => {
                        // check if the selected col is still in the primary & related modules
                        if ( col && !( 
                            ( template.primary_module && !_isEmpty( template.primary_module ) && _startsWith( col, template.primary_module + '|' ) ) || 
                            _find( newData[key], (r) => _startsWith( col, r + '|' ) )
                         ) ) {
                            let pulled = _remove( newData['columns'], (c) => c === col );
                        } // end - 
                    });
                } // end - columns

                // do check if sort_by column is still in selection
                newData = this.updateSortBy( newData, template );
                break;
            case 'columns':
                newData[key] = newValue;

                // do check if sort_by column is still in selection
                newData = this.updateSortBy( newData, template );
                break;
            case 'filterby_conditions':
                // reset everything
                newData['filterby_all_conditions'] = [];
                newData['filterby_any_conditions'] = [];

                if ( newValue && isArrayExists( newValue ) ) {
                    _forEach( newValue, cond => {
                        if ( cond.by && cond.by === 'all' ) {
                            newData['filterby_all_conditions'].push(cond);
                        } else {
                            newData['filterby_any_conditions'].push(cond);
                        } // end - cond.by
                    })
                } // end - newValue
                break;
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    handleFilterUpdated = ({ formData, newValue, key, item, cell, subkey, subVal}) => {
        var newData = cloneCollections( formData ),
            schema = this.getColumnSchema(item);
        if ( key === 'value' || ( subkey && ( subkey === 'value|start_range' || subkey === 'value|end_range' ) ) ) {
            // update value based on schema
            switch( schema.type ) {
                case 'timestamp':
                case 'system_date':
                    // if condition is between
                    if ( newData['condition'] && newData['condition'] === 'between' ) {
                        let splitDate = ( formData['value'] && !_isEmpty( formData['value'] ) ? _split( formData['value'], ', ' ) : false ),
                            startTimestamp = ( splitDate[0] && !_isEmpty( splitDate[0] ) ? getMomentTimestamp( splitDate[0] ) : moment().utcOffset(8).startOf('date').valueOf() ),
                            endTimestamp = ( splitDate[1] && !_isEmpty( splitDate[1] ) ? getMomentTimestamp( splitDate[1] ) : moment().utcOffset(8).endOf('date').valueOf() );

                        if ( subkey && subkey === 'value|start_range' ) {
                            startTimestamp = moment(newValue).utcOffset(8).startOf('date').valueOf();
                            // make sure start wasn't bigger than end
                            if ( startTimestamp > endTimestamp ) {
                                endTimestamp = moment(newValue).utcOffset(8).endOf('date').valueOf();
                            } // end - startTimestamp
                        } else if ( subkey && subkey === 'value|end_range' ) {
                            endTimestamp = moment(newValue).utcOffset(8).endOf('date').valueOf();
                            // make sure end wasn't small than start
                            if ( endTimestamp < startTimestamp ) {
                                startTimestamp = moment(newValue).utcOffset(8).startOf('date').valueOf();
                            } // end - startTimestamp
                        } // end  subkey
                        newData['value'] = moment(startTimestamp).utcOffset(8).startOf('date').format('YYYY/MM/DD') + ', ' + moment(endTimestamp).utcOffset(8).endOf('date').format('YYYY/MM/DD');
                    } else {
                        // change to timestamp to readable date format
                        newData[key] = ( newValue ? getMomentTime( newValue, 'YYYY/MM/DD' ) : '' );
                    } // edn - newData['condition']
                    break;
                default:
                    newData[key] = newValue;
                    break;
            } // end - switch
        } else if ( key === 'condition' ) {
            newData[key] = newValue; // update condition normally
            // update value if needed for date related schema
            newData['value'] = this.updateFilterValueByCondition( newValue, ( formData && formData[key] || false ), newData['value'], schema );
        } else if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
            // use subVal as the new value
            // for by
            newData[key] = subVal;
        } else {
            // otherwise - just update normally
            // for column
            newData[key] = newValue;
        } // end - key

        return newData;
    }

    canUpdateSettings = (filters) => {
        const { authData, template } = this.props;
        let can = false;
        // if is admin 
        if ( authData && isAdmin( authData ) ) {
            can = true;
        } else if ( template && template.report_access && !_isEmpty( template.report_access ) ) {
            switch ( template.report_access ) {
                case 'lock':
                    // if is filter
                    if ( filters ) {
                        can = true;
                    } else {
                        if ( template.report_owners && isArrayExists( template.report_owners ) && authData && authData.email && !_isEmpty( authData.email ) && _find( template.report_owners, { id: authData.email } ) ) {
                            can = true;
                        } // end - template.report_owners
                    } // end - filters
                    break;
                case 'generate-only':
                    if ( template.report_owners && isArrayExists( template.report_owners ) && authData && authData.email && !_isEmpty( authData.email ) && _find( template.report_owners, { id: authData.email } ) ) {
                        can = true;
                    } // end - template.report_owners
                    break;
                case 'default':
                    can = true;
                    break;
            }
        } // end - authData
        return can;
    }

    updateFilterValueByCondition = (condition,oldCondition,newValue,schema) => {
        var isDate = ( schema && schema.type && ( schema.type === 'timestamp' || schema.type === 'system_date' ) ? true : false ); // check if is date schema
        switch( condition ) {
            case 'equals':
            case 'notequalto':
            case 'before':
            case 'after':
                if ( isDate ) {
                    // if old condition isn't the new - reset value
                    if ( oldCondition && _find(['equals','notequalto','before','after'],(c) => c === oldCondition) ) {
                        return newValue;
                    } else {
                        return '';
                    }
                } else {
                    return newValue;
                } // end - isDate
            case 'between':
                return moment().utcOffset(8).startOf('date').format('YYYY/MM/DD') + ', ' + moment().utcOffset(8).endOf('date').format('YYYY/MM/DD');
            case 'isempty':
            case 'isnotempty':
                return ''; // reset value
            case 'previous-fy':
            case 'current-fy':
            case 'next-fy':
            case 'previous-fq':
            case 'current-fq':
            case 'next-fq':
            case 'yesterday':
            case 'today':
            case 'tomorrow': 
            case 'previous-week':
            case 'current-week':
            case 'next-week':
            case 'previous-month':
            case 'current-month':
            case 'next-month':
            case 'last-7days':
            case 'last-30days':
            case 'last-60days':
            case 'last-90days':
            case 'last-120days':
            case 'next-7days':
            case 'next-30days':
            case 'next-60days':
            case 'next-90days':
            case 'next-120days':
                return ( isDate ? getDateBasedCondition( condition ) : newValue );
            default:
                return newValue; // default - return newValue
        }
    }

    sortOptionsByLabel = (options,blank) => {
        const { template } = this.props;
        let related_modules = template && template.related_modules && isArrayExists( template.related_modules ) ? template.related_modules : false;
        options = _sortBy( options, [(option) => {
            let count = 1;
            if ( related_modules ) {
                let related_count = 0;
                _forEach( related_modules, related_mod => {
                    related_count++
                    if ( related_mod && option.value.indexOf(related_mod+'|') >= 0 ) {
                        count += related_count;
                    } // end - related_mod
                })
            } // end - option
            return _toString( count ) + ' - ' + option.label.toLowerCase();
        }]);

        // add blank option if needed
        if ( blank )
            options.unshift({ value: '', label: 'Select an Option'});

        return options;
    }

    getFieldOptions = (field) => {
        const { template, selectables, categories, users } = this.props;
        switch( field.name ) {
            case 'report_owners':
                return getSelectOptions({ list: ( users ? _filter( users, (u) => u.status !== 'disabled' ) : [] ), keys: { value: 'email', label: 'name' }, sortBy: 'label' });
            case 'report_access':
                return [
                    { value: 'default', label: 'Default' },
                    { value: 'lock', label: 'Lock report template' },
                    { value: 'generate-only', label: 'Generate report only' },
                ];
            case 'report_restricted':
                return YES_NO_OPTIONS;
            case 'category_id':
                return getSelectOptions({ list: ( categories || false ), options: [{ value: '', label: 'Select an Option' }], keys: { value: 'id', label: 'name' }, sortBy: 'label' });
            case 'primary_module':
                return getSelectOptions({ list: CORE_MODULES, options: [{ value: '', label: 'Select an Option' }], keys: { value: 'value', label: 'label' } });
            case 'related_modules':
                var options = [];
                // use primary module to retrieve the related modules
                var primary_mod = ( template && template.primary_module && !_isEmpty( template.primary_module ) ? _find( CORE_MODULES, { value: template.primary_module } ) : false );
                if ( primary_mod && primary_mod.related && isArrayExists( primary_mod.related ) ) {
                    _forEach( primary_mod.related, mod => {
                        var option = _find( CORE_MODULES, { value: mod } );
                        if ( option )
                            options.push({ value: option.value, label: option.label });
                    });
                } // end - primary_mod.related

                // disable multiple modules selection for now
                // _forEach( CORE_MODULES, mod => {
                //     // make sure primary module wasn't in the options
                //     if ( template && template.primary_module && !_isEmpty( template.primary_module ) && template.primary_module !== mod.value ) {
                //         let option = cloneCollections( mod );

                //         // check if already 2 options selected
                //         if ( template.related_modules && isArrayExists( template.related_modules ) && _size( template.related_modules ) >= 2 ) {
                //             // if option wasn't selected - disable it
                //             if ( !_find( template.related_modules, (o) => o === mod.value ) ) {
                //                 option['disabled'] = 'yes';
                //             } // end - template.related_modules
                //         } // end - related_modules

                //         options.push( option );
                //     } // end - template.primary_module
                // });
                return options;
            case 'columns':
                var options = [],
                    disabled = false,
                    selected_modules = ( template && template.related_modules && isArrayExists( template.related_modules ) ? cloneCollections( template.related_modules ) : [] );

                // add primary module
                if ( template && template.primary_module && !_isEmpty( template.primary_module ) )
                    selected_modules.push(template.primary_module);

                // get selected modules
                if ( isArrayExists( selected_modules ) ) {
                    _forEach( _reverse( selected_modules ), mod => {
                        let headerLabel = this.getSchemaLabel(mod),
                            selectedSchema = this.getSelectedSchema(mod);

                        //options.push({ value: mod, label: headerLabel, heading: true });
                        _forEach( selectedSchema, schema => {
                            if ( isReportData( schema ) && isReportVisible( schema ) ) {
                                let key = mod+'|'+schema.id;
                                options.push({ value: key, label: headerLabel + ' -> ' + ( schema.report_label && !_isEmpty( schema.report_label ) ? schema.report_label : schema.label ), disabled: ( disabled && !( _find( template.columns, (o) => o === key ) ) ? 'yes' : 'no' ) });
                            } // end - schema
                        });
                    });
                } // end - selected_modules

                return this.sortOptionsByLabel( options );
            case 'sort_by':
            case 'columns_calculation':
                var options = [],
                    selected_columns = ( template && template.columns && isArrayExists( template.columns ) ? cloneCollections( template.columns ) : [] );

                // get selected columns
                if ( isArrayExists( selected_columns ) ) {
                    _forEach( selected_columns, col => {
                        let mod = this.getColumnMod({ column: col }),
                            headerLabel = this.getSchemaLabel(mod),
                            selectedSchema = this.getSelectedSchema(mod);

                        _forEach( selectedSchema, schema => {
                            if ( isReportData( schema ) && isReportVisible( schema ) ) {
                                let key = mod+'|'+schema.id;
                                if ( key === col ) {
                                    var colOption = { 
                                        value: key, 
                                        label: headerLabel + ' -> '+ ( schema.report_label && !_isEmpty( schema.report_label ) ? schema.report_label : schema.label ) 
                                    };
                                    // if is for columns_calculation
                                    if ( field.name === 'columns_calculation' ) {
                                        if ( schema.type && ( schema.type === 'number' || schema.type === 'currency' ) ) {
                                            colOption.type = schema.type;
                                            options.push(colOption);
                                        } // end - schema.type
                                    } else {
                                        options.push(colOption);
                                    } // end - field.name
                                } // end - key                                    
                            } // end - schema
                        });
                    });
                } // end - selected_modules

                return this.sortOptionsByLabel( options, true );
            case 'filterby_conditions':
            case 'filterby_all_conditions':
            case 'filterby_any_conditions':
                var options = [];

                // add primary_module schema
                if ( template && template.primary_module && !_isEmpty( template.primary_module ) ) {
                    _forEach( this.getSelectedSchema(template.primary_module), schema => {
                        if ( isReportData( schema ) && isReportVisible( schema ) ) {
                            options.push({ 
                                value: template.primary_module+'|'+schema.id, 
                                label: this.getSchemaLabel(template.primary_module) + ' -> '+ ( schema.report_label && !_isEmpty( schema.report_label ) ? schema.report_label : schema.label ) 
                            });
                        } // end - schema
                    });
                } // end - template.primary_module

                // add related_modules schema (if available)
                if ( template && template.related_modules && isArrayExists( template.related_modules ) ) {
                    _forEach( template.related_modules, related_mod => {
                        _forEach( this.getSelectedSchema(related_mod), schema => {
                            if ( isReportData( schema ) && isReportVisible( schema ) ) {
                                options.push({ 
                                    value: related_mod+'|'+schema.id, 
                                    label: this.getSchemaLabel(related_mod) + ' -> '+ ( schema.report_label && !_isEmpty( schema.report_label ) ? schema.report_label : schema.label ) 
                                });
                            } // end - schema
                        });
                    }); // end - template.related_modules
                } // end - template.related_modules

                return this.sortOptionsByLabel( options, true );
            default:
                return [];
        }
    }

    getFieldValue = (field) => {
        const { template } = this.props;
        switch( field.name ) {
            case 'report_owners':
                return ( template && template[field.name] && isArrayExists( template[field.name] ) ? getSelectValues( template[field.name], 'id' ) : [] );
            case 'related_modules':
                // turn array to value
                return ( template && template[field.name] && template[field.name][0] && !_isEmpty( template[field.name][0] ) ? template[field.name][0] : ( field.default || '' ) );
            case 'sort_by':
                // turn position to integer
                var options = ( template && template[field.name] && isArrayExists( template[field.name] ) ? cloneCollections( template[field.name] ) : [] );
                if ( !_isEmpty( options ) ) {
                    _forEach( template[field.name], (option,index) => {
                        options[index].position = ( option.position ? parseInt( option.position, 10 ) : 99 );
                    });
                }
                return options;
            case 'columns':
                var columns = [];
                if ( template[field.name] && isArrayExists( template[field.name] ) ) {
                    _forEach( template[field.name], col => {
                        let mod = this.getColumnMod({ column: col }),
                            headerLabel = this.getSchemaLabel(mod),
                            selectedSchema = this.getSelectedSchema(mod),
                            selectedOption = ( selectedSchema && isArrayExists( selectedSchema ) ? _find( selectedSchema, (s) => ( mod+'|'+s.id ) === col ? true : false ) : false );

                        if ( selectedOption ) {
                            columns.push({ value: col, label: headerLabel + ' -> '+ ( selectedOption.report_label && !_isEmpty( selectedOption.report_label ) ? selectedOption.report_label : selectedOption.label ) });
                        } // end - selectedOption
                    });
                } // end - template[field.name]
                return columns;
            case 'filterby_conditions':
                var conditions = [];
                // add all conditions first
                if ( template.filterby_all_conditions && isArrayExists( template.filterby_all_conditions ) ) {
                    _forEach( template.filterby_all_conditions, filter => {
                        filter['by'] = 'all';
                        conditions.push( filter );
                    });
                } // end - template.filterby_all_conditions

                // add any conditions
                if ( template.filterby_any_conditions && isArrayExists( template.filterby_any_conditions ) ) {
                    _forEach( template.filterby_any_conditions, filter => {
                        filter['by'] = 'any';
                        conditions.push( filter );
                    });
                } // end - template.filterby_any_conditions

                return conditions;
            default:
                return ( template && template[field.name] || ( field.default || '' ) );
        }
    }

    isFieldDisabled = (schema) => {
        const { authData, template } = this.props;
        let disabled = false;

        if ( schema && schema.disabled && isArrayExists( schema.disabled ) ) {
            schema.disabled.forEach( condition => {
                switch( condition ) {
                    case 'admin':
                        if ( !isAdmin( authData ) )
                            disabled = true;
                        break;
                    case 'update':
                        disabled = true;
                        break;
                }
            });
        } // end - schema

        // check for access
        switch ( schema.id ) {
            case 'filterby_any_conditions':
            case 'filterby_all_conditions':
                if ( !this.canUpdateSettings(true) ) {
                    disabled = true;
                }
                break;
            default:
                if ( !this.canUpdateSettings() ) {
                    disabled = true;
                }
                break;
        } // end - schema.id

        return disabled
    }

    getField = (id) => {
        let schema = _find( reportTemplateSchema, { id } );
        return ( schema ? {
            name: ( schema.id || '' ),
            label: ( schema.label || '' ) + ( isSchemaRequired(schema,'update') ? ' (Required)' : '' ),
            field_type: ( schema.field || '' ),
            default: ( schema.default || null ),
            disabled: this.isFieldDisabled( schema )
        } : null );
    }

    getColumnMod = (item) => {
        let splitData = ( item && item.column && !_isEmpty( item.column ) ? _split( item.column, '|' ) : false );
        return ( splitData && splitData[0] && !_isEmpty( splitData[0] ) ? splitData[0] : '' );
    }

    getColumnKey = (item) => {
        let splitData = ( item && item.column && !_isEmpty( item.column ) ? _split( item.column, '|' ) : false );
        return ( splitData && splitData[1] && !_isEmpty( splitData[1] ) ? splitData[1] : '' );
    }

    getColumnSchema = (item) => {
        let mod = this.getColumnMod(item),
            key = this.getColumnKey(item),
            schemas = this.getSelectedSchema(mod);
        return _find( schemas, { id: key });
    }

    getSelectedSchema = (mod) => {
        switch( mod ) {
            case 'accounts':
                return cloneCollections( accountSchema );
            case 'contacts':
                return cloneCollections( contactSchema );
            case 'potentials':
                return cloneCollections( potentialSchema );
            case 'quotes':
                return cloneCollections( quoteSchema );
            case 'invoices':
                return cloneCollections( invoiceSchema );
            case 'credit_notes':
                return cloneCollections( creditNoteSchema );
            case 'products':
                return cloneCollections( productSchema );
        } // end - mod
    }

    getSchemaLabel = (mod) => {
        switch( mod ) {
            case 'accounts':
                return 'Account';
            case 'contacts':
                return 'Contact';
            case 'potentials':
                return 'Potential';
            case 'quotes':
                return 'Quote';
            case 'invoices':
                return 'Invoice';
            case 'credit_notes':
                return 'Credit Note';
            case 'products':
                return 'Product';
        }
    }

    getConditionValue = (type,item) => {
        let mod = this.getColumnMod(item),
            key = this.getColumnKey(item),
            headerLabel = this.getSchemaLabel(mod),
            selectedSchema = this.getSelectedSchema(mod);

        let schema = _find( selectedSchema, { id: key });
        switch( type ) {
            case 'schema':
                return ( schema || false );
            case 'condition':
                let options = this.getConditionOptions(schema),
                    option = ( item.condition && !_isEmpty( item.condition ) ? _find( options, { value: item.condition }) : false );
                return ( option && option.label || '' );
            case 'options':
                return this.getConditionOptions(schema);
            case 'label':
            default:
                return ( schema && schema.label && !_isEmpty( schema.label ) ? headerLabel + ' -> ' + ( schema.report_label && !_isEmpty( schema.report_label ) ? schema.report_label : schema.label ) : '' ); 
        }
    }

    getSchemaByCol = (echo) => (item) => {
        return this.getConditionValue(echo, item);
    }

    getConditionOptions = (schema) => {
        var options = [{ value: '', label: 'Select an Option' }];
        if ( schema && schema.type ) {
            switch( schema.type ) {
                case 'number':
                case 'currency':
                    options = _concat( options, CONDITION_OPTIONS_NUMBER );
                    break;
                case 'timestamp':
                case 'system_date':
                    options = _concat( options, CONDITION_OPTIONS_TIMESTAMP );
                    break;
                default:
                    options = _concat( options, CONDITION_OPTIONS_DEFAULT );
                    break;
            }
        } // end - schema
        return options;
    }

    checkIfIsRequired = ({ item, cell }) => {
        let required = true,
            mod = this.getColumnMod(item),
            key = this.getColumnKey(item),
            schemas = this.getSelectedSchema(mod),
            schema = _find( schemas, { id: key });

        if ( schema && schema.type ) {
            switch( schema.type ) {
                default:
                    if ( item && item.condition && ( item.condition === 'isempty' || item.condition === 'isnotempty' ) ) {
                        required = false;
                    } // end - item
                    break;
            } // end - schema
        } // end - schema.type

        return required;
    }

    renderDateRangeField = ({ item, field, cell, onChange }) => {
        var dateRange = ( item.value && !_isEmpty( item.value ) ? _split( item.value, ', ' ) : false ),
            startDate = ( dateRange && dateRange[0] && !_isEmpty( dateRange[0] ) ? dateRange[0] : false ),
            endDate = ( dateRange && dateRange[1] && !_isEmpty( dateRange[1] ) ? dateRange[1] : false );
        return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <FormDatePicker label="Start Date" name="value" value={( startDate && !_isEmpty( startDate ) ? startDate : null )} onChange={(newValue,key) => {
                    onChange(newValue,key,'value|start_range');
                }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormDatePicker label="End Date" name="value" value={( endDate && !_isEmpty( endDate ) ? endDate : null )} onChange={(newValue,key) => {
                    onChange(newValue,key,'value|end_range');
                }} />
            </Grid>
        </Grid>
        )
    }

    renderFieldBySchema = ({ item, field, cell, onChange }) => {
        let mod = this.getColumnMod(item),
            key = this.getColumnKey(item),
            schemas = this.getSelectedSchema(mod),
            schema = _find( schemas, { id: key });

        // make sure the condition is not isempty or isnotempty
        if ( !( item && item.condition && ( item.condition === 'isempty' || item.condition === 'isnotempty' ) ) ) {
            // trigger different fields
            if ( schema && schema.type ) {
                switch( schema.type ) {
                    case 'number':
                    case 'currency':
                        return <FormInput {...field} type="number" onChange={( onChange ? onChange : null )} />;
                    case 'timestamp':
                    case 'system_date':
                        // only display date selector if condition set is allowed to change
                        if ( item && item.condition && (
                            item.condition === 'equals' || 
                            item.condition === 'notequalto' ||  
                            item.condition === 'before' || 
                            item.condition === 'after'
                        ) ) {
                            return <FormDatePicker {...field} noDefaultVal={true} onChange={( onChange ? onChange : null )} />;
                        } else if ( item.condition === 'between' ) {
                            return this.renderDateRangeField({ item, field, cell, onChange });
                        } else {
                            // else use input that are disabled
                            return <FormInput {...field} disabled={true} onChange={( onChange ? onChange : null )} />;
                        } // end - item.condition
                    default:
                        return <FormInput {...field} onChange={( onChange ? onChange : null )} />;
                } // end - schema.type
            } // end - schema
        } // end - item.condition
        
    }

    renderField = (id) => {
        const { template, authData } = this.props;
        let field = this.getField(id);
        if ( field && field.field_type && !_isEmpty( field.field_type ) ) {
            switch( field.field_type ) {
                case 'text':
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'text_number':
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'email':
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'select2':
                    return <FormSelect2 placeholder="Select an Option" {...field} value={this.getFieldValue(field)} isClearable={true} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} />;
                case 'select':
                    return <FormSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} />;
                case 'multiselect':
                    if ( id === 'columns' ) {
                        return <ColumnSelector {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} />;
                    } else {
                        return <div style={{ paddingTop: "7px" }}><FormMultiSelect {...field} columns={12} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} /></div>;
                    }
                case 'datepicker':
                    return <FormDatePicker {...field} noDefaultVal={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'radio':
                    return <div style={{ paddingTop: "7px" }}><FormRadio inline={true} {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} /></div>;
            }
        } // end - field.field_type
    }

    renderFilterByCond = () => {
        return <FormMultiOptions 
            name='filterby_conditions'
            label=''
            field_type=''
            isDisabled={( this.canUpdateSettings(true) ? false : true )}
            default={[]}
            value={this.getFieldValue({ name: 'filterby_conditions' })}
            moveable={false}
            cells={[
                { 
                    id: 'column', 
                    label: 'Column', 
                    render: this.getSchemaByCol('label'), 
                    field: 'select', 
                    required: true, 
                    options: this.getFieldOptions({ name: 'filterby_conditions' }),
                    onValueUpdated: this.handleFilterUpdated 
                },
                { 
                    id: 'condition', 
                    label: 'Condition', 
                    render: this.getSchemaByCol('condition'), 
                    field: 'select', 
                    required: true, 
                    defaultVal: '', 
                    getOptions: this.getSchemaByCol('options'),
                    onValueUpdated: this.handleFilterUpdated
                },
                { 
                    id: 'value', 
                    label: 'Value', 
                    render: (item) => ( item && item.value || '' ), 
                    field: 'custom',
                    renderCustomField: this.renderFieldBySchema, 
                    isRequired: this.checkIfIsRequired, 
                    defaultVal: '',
                    onValueUpdated: this.handleFilterUpdated
                },
                { 
                    id: 'by', 
                    label: 'Condition Logic', 
                    render: (item) => ( item && item.by && item.by === 'all' ? 'AND' : 'OR' ), 
                    field: 'radio',
                    options: [
                        { value: 'all', label: 'AND' },
                        { value: 'any', label: 'OR' },
                    ],
                    defaultVal: 'all',
                    onValueUpdated: this.handleFilterUpdated
                }
            ]}
            onChange={this.handleFormUpdate} />
    }

    renderSortByOptions = () => {
        let field = this.getField('sort_by');
        return <FormMultiOptions 
            {...field}
            isDisabled={( this.canUpdateSettings() ? false : true )}
            value={this.getFieldValue(field)}
            moveable={true}
            cells={[
                { 
                    id: 'column', label: 'Column', render: this.getSchemaByCol('label'), 
                    field: 'select', required: true, options: this.getFieldOptions(field) 
                },
                { id: 'direction', label: 'Sorting Direction', render: (item) => ( item.direction && ( item.direction === 'asc' || item.direction === 'desc' ) ? ( item.direction === 'asc' ? 'Ascending' : 'Descending' ) : '' ), 
                    field: 'radio', required: true, defaultVal: 'asc', options: [
                        { value: 'asc', label: 'Ascending' },
                        { value: 'desc', label: 'Descending' },
                    ] 
                }
            ]}
            onChange={this.handleFormUpdate} />
    }

    renderConditionLogic = () => {
        const { template } = this.props;
        return ( template && (
            ( template.filterby_all_conditions && isArrayExists( template.filterby_all_conditions ) ) || 
            ( template.filterby_any_conditions && isArrayExists( template.filterby_any_conditions ) )
        ) ? (
            <div>
                <strong style={{ fontWeight: '700' }}>Condition Logic:</strong><br />
                { template.filterby_all_conditions && isArrayExists( template.filterby_all_conditions ) ? _map( template.filterby_all_conditions, ( cond, index ) => {
                    return <span key={cond.id}>{( index > 0 ? <strong style={{ fontWeight: '700'}}>{' AND '}</strong> : '' )}<span style={{ fontStyle: 'italic' }}>{ this.getConditionValue('label',cond) + ' ' + this.getConditionValue('condition',cond) + ' "'+( cond && cond.value || '' )+'" ' }</span></span>
                }) : null }
                { template.filterby_all_conditions && isArrayExists( template.filterby_all_conditions ) && template.filterby_any_conditions && isArrayExists( template.filterby_any_conditions ) ? <strong style={{ fontWeight: '700'}}>{' AND ( '}</strong> : null }
                { template.filterby_any_conditions && isArrayExists( template.filterby_any_conditions ) ? _map( template.filterby_any_conditions, ( cond, index ) => {
                    return <span key={cond.id}>{( index > 0 ? <strong style={{ fontWeight: '700'}}>{' OR '}</strong> : '' )}<span style={{ fontStyle: 'italic' }}>{ this.getConditionValue('label',cond) + ' ' + this.getConditionValue('condition',cond) + ' "'+( cond && cond.value || '' )+'" ' }</span></span>
                }) : null }
                { template.filterby_all_conditions && isArrayExists( template.filterby_all_conditions ) && template.filterby_any_conditions && isArrayExists( template.filterby_any_conditions ) ? <strong style={{ fontWeight: '700'}}>{' ) '}</strong> : null }
            </div>
        ) : (
            <div>
                <strong style={{ fontWeight: '700' }}>Condition Logic (example):</strong><br /><span style={{ fontStyle: 'italic' }}>and_condition1</span> AND <span style={{ fontStyle: 'italic' }}>and_condition2</span> AND ( <span style={{ fontStyle: 'italic' }}>or_condition1</span> OR <span style={{ fontStyle: 'italic' }}>or_condition2</span> )
            </div>
        ));
    }

    renderDetailsBox = () => {
        const { authData, classes, template } = this.props;
        return (
        <FormBox style={{ paddingBottom: "30px" }}>
            <Typography variant="h4" className={classes.boxheading}>Template Settings</Typography>
            <Grid container spacing={3}>

                <Grid item xs={6}>{this.renderField('name')}</Grid>
                <Grid item xs={6}>{this.renderField('category_id')}</Grid>
                <Grid item xs={12}>{this.renderField('desc')}</Grid>

                <Grid item xs={6}>{this.renderField('primary_module')}</Grid>
                <Grid item xs={6}>{this.renderField('related_modules')}</Grid>

                <Grid item xs={6}>{this.renderField('report_owners')}</Grid>
                <Grid item xs={6}>{this.renderField('report_access')}</Grid>
                <Grid item xs={6}>{this.renderField('report_restricted')}</Grid>

            </Grid>

            <div style={{ paddingTop: "10px", textAlign: 'right', color: '#999', fontSize: '1.25rem' }}>
                <div>{ template.created_on ? 'Created on ' + getMomentTime( (template.created_on) , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
                <div>{ template.modified_on ? 'Last Modified on ' + getMomentTime( (template.modified_on) , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
            </div>

            <Typography variant="h4" className={classes.boxheading}>Display Settings</Typography>
            <Grid container spacing={3}>

                <Grid item xs={12}>{this.renderField('columns')}</Grid>
                <Grid item xs={8}>
                    <div>{this.renderSortByOptions()}</div>
                </Grid>
                <Grid item xs={8}>
                    <ColumnsCalculation
                        template={template}
                        disabled={( this.canUpdateSettings() ? false : true )}
                        columns={this.getFieldOptions({ name: 'sort_by' })}
                        numColumns={this.getFieldOptions({ name: 'columns_calculation' })}
                        onChange={this.handleFormUpdate} />
                </Grid>
            </Grid>

            <div style={{ paddingTop: '45px' }}>
                <Typography variant="h4" className={classes.boxheading}>Filter By Conditions</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div>{this.renderConditionLogic()}</div>
                        <div>{this.renderFilterByCond()}</div>
                        {/* <div>{this.renderFilterByCond('filterby_any_conditions')}</div> */}
                    </Grid>
                </Grid>
            </div>


        </FormBox>
        );
    }
   
    render() {
        return (
        <div>
            {this.renderDetailsBox()}
        </div>
        )
    }

}

export default compose(
    withStyles(useStyles),
    withRouter
)(ReportDetails);