import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import GrapesJS from 'grapesjs';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _last from 'lodash/last';

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import ModalView from '../../components/ModalView';
import ButtonActions from '../../components/ButtonActions';
import SnackBarSave from '../../components/SnackBarSave';
import ItemNotFound from '../../components/ItemNotFound';
import MediaLibrary from '../../components/MediaLibrary';

import Details from './details';
import Editor from './editor_mce';
import ShortcodeMenu from './shortcode_menu';

import { InfoButton, GreyButton, ButtonGroup, SuccessButton, IndigoButton } from '../../styles/button';

import { isAdmin } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';

import { getEmailTemplate, editEmailTemplate } from '../../actions/emailTemplates';
import { getUsersOptions } from '../../actions/users';
import { appChangesMade, resetRedux } from '../../actions/misc';

import { emailTemplateSchema } from '../../schemas/email_template';

class ReportTemplatePage extends React.Component {

    state = {
        template: false,
        openShortcodeMenu: false,
        randNum: false
    };

    componentDidMount() {
        const { template_id } = this.props.match.params;
        
        // retrieve email template
        this.props.dispatch(getEmailTemplate(template_id));

        // get all users
        if ( !this.props.usersOptions )
            this.props.dispatch(getUsersOptions());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentEmailTemplate, usersOptions, randNum } = this.props;

        // for report template
        if ( currentEmailTemplate && usersOptions && randNum && randNum !== this.state.randNum ) {
            this.setState({ template: currentEmailTemplate, randNum });
        } // end - currentEmailTemplate
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('email_template'));
    }

    handleSaveChanges = (event) => {
        const { template } = this.state;
        // const editor = ( GrapesJS.editors && isArrayExists( GrapesJS.editors ) ? _last( GrapesJS.editors ) : false );
        event.preventDefault();
        var error = false,
            formData = {};

        // compile editor contents (no longer needed)
        // if ( editor ) {
        //     formData['contents_html'] = editor.getHtml();
        //     formData['contents_css'] = editor.getCss();
        //     formData['contents_js'] = editor.getJs();
        // } // end - editor

        // compile editor for tinymce
        formData['contents_html'] = ( template && template.contents_html && !_isEmpty( template.contents_html ) ? template.contents_html : '' );
        formData['contents_css'] = ( template && template.contents_css && !_isEmpty( template.contents_css ) ? template.contents_css : '' );
        formData['contents_js'] = ( template && template.contents_js && !_isEmpty( template.contents_js ) ? template.contents_js : '' );

        // do error check
        emailTemplateSchema.forEach(schema => {
            formData[schema.id] = ( template && template[schema.id] ? cloneCollections( template[schema.id] ) : schema.default );
            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editEmailTemplate(formData));
        } // end - error

    }

    handleEditorUpdate = (newValue,key) => {
        const { template } = this.state;
        var newData = ( template ? cloneCollections( template ) : {} );
        newData[key] = newValue;

        this.setState({ template: newData });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    handleFormUpdate = (newValue) => {
        this.setState({ template: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    renderShortcodeButton = () => {
        const { openShortcodeMenu } = this.state;
        return <IndigoButton style={{ padding: "10px 45px", borderRadius: "45px", marginLeft: "5px" }} onClick={() => this.setState({ openShortcodeMenu: !openShortcodeMenu })}><i className="fa fa-code"></i>{ openShortcodeMenu ? 'Close' : 'Open' } Shortcode Menu</IndigoButton>
    }

    renderdetails = () => {
        const { template_id } = this.props.match.params;
        const { authData, changesMade, usersOptions } = this.props;
        const { template } = this.state;
        return <Details
            authData={( authData || false )}
            template={( template || false )}
            users={( usersOptions || [] )}
            changesMade={( changesMade || false )}
            onFormUpdate={this.handleFormUpdate} />;
    }

    renderContents() {
        const { randNum } = this.props;
        const { template, openShortcodeMenu } = this.state;
        return (
        <div>
            
            <div style={{ marginRight: ( openShortcodeMenu ? '240px' : '0px' )}}>
                <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                    {this.renderdetails()}
                </Paper>

                <div style={{ marginTop: "45px" }}>
                    <div style={{ marginBottom: "20px" }}>
                        <MediaLibrary
                            extraButtons={this.renderShortcodeButton()} />
                    </div>
                    <Editor 
                        template={( template || null )}
                        randNum={( randNum || false )}
                        onEditorUpdate={this.handleEditorUpdate} />
                </div>

                <div style={{ textAlign: "center", marginTop: "45px" }}>
                    <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
                </div>
            </div>

            <ShortcodeMenu
                open={( openShortcodeMenu || false )}
                onClose={() => this.setState({ openShortcodeMenu: false })} />

            <SnackBarSave saveNowLabel="Save Template Settings" onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { currentEmailTemplate } = this.props;
        const { randNum } = this.state;
        return <AppWrapper 
                title="Edit Email Template"
                subtitle="Email Templates"
                back="/email_templates"
                breadcrumbs={[
                    { url: '/email_templates', label: 'Email Templates' },
                    { label: 'Edit' }
                ]}
                onLoad={( !( randNum ) ? true : false )}
                contents={ currentEmailTemplate && currentEmailTemplate.id && !_isEmpty( currentEmailTemplate.id ) ? this.renderContents() : <ItemNotFound item="Email Template" />} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentEmailTemplate: state.emailtemplates && state.emailtemplates.template || null,
        randNum: state.emailtemplates && state.emailtemplates.rand || null,
        usersOptions: state.users && state.users.users_options || null,
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ReportTemplatePage);