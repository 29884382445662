/* eslint-disable */
import _random from 'lodash/random';

import {
    M_GET_ROLES,
    M_ROLES_UPDATED,
	GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';
import { fb_getRolesFromDB, fb_addRole, fb_editRole, fb_deleteRole, fb_syncRoles } from './fb';

export const getRoles = () => {
	return dispatch => {

        fb_getRolesFromDB(list => {
			dispatch({ 
                type: M_GET_ROLES, 
                payload: { list } 
            });
		});

	}
}

export const addRole = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addRole(formData)
		.then( results => {
			dispatch({ type: M_ROLES_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Role Added");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new role' ) );
			triggerErrorAlert(errMsg);
		});

  }
}

export const editRole = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_editRole(formData)
		.then( results => {
			dispatch({ type: M_ROLES_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Role Updated");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update role' ) );
			triggerErrorAlert(errMsg);
		});

  }
}

export const deleteRole = (id) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_addRole({ id })
		.then( results => {
			dispatch({ type: M_ROLES_UPDATED });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Role Deleted");
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete role' ) );
			triggerErrorAlert(errMsg);
		});

  	}
}

export const syncDataWithLMS = (id,type) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_syncRoles()
		.then(() => {
			dispatch({ type: M_ROLES_UPDATED });
			dispatch(toggleLoader(false));
			triggerSuccessAlert("Roles Synced!");
		})
		.catch(error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to sync data with LMS' ) );
			triggerErrorAlert(errMsg);
		});

  	}
}