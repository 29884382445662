import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _isEqual from 'lodash/isEqual';

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import SnackBarSave from '../../components/SnackBarSave';
import ModalView from '../../components/ModalView';

import DateRangeForm from './date_range_form';
import ResultTable from './table';
import ContactForm from './contact_form';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';

import { InfoButton, InverseButton } from '../../styles/button';

import { importContactsFromLMS } from '../../actions/contacts';
import { doContactsImport } from '../../actions/contacts/import_from_lms';
import { toggleLoader } from '../../actions/global';
import { resetRedux, appChangesReset } from '../../actions/misc';

// import { DEV_MODE } from '../../constants';

const getDefaultDateRange = () => {
    return {
        start_date: moment().utcOffset(8).subtract(1, 'months').startOf('date').valueOf(),
        end_date: moment().utcOffset(8).endOf('date').valueOf()
    };
    // return {
    //     start_date: moment().utcOffset(8).set({ month: 7, date: 9, year: 2017 }).startOf('date').valueOf(),
    //     end_date: moment().utcOffset(8).set({ month: 7, date: 11, year: 2017 }).endOf('date').valueOf()
    // };
    // return {
    //     start_date: moment().utcOffset(8).set({ month: 9, date: 10, year: 2020 }).startOf('date').valueOf(),
    //     end_date: moment().utcOffset(8).set({ month: 9, date: 15, year: 2020 }).endOf('date').valueOf()
    // };
    // return {
    //     start_date: moment().utcOffset(8).set({ month: 6, date: 28, year: 2018 }).startOf('date').valueOf(),
    //     end_date: moment().utcOffset(8).set({ month: 6, date: 28, year: 2018 }).endOf('date').valueOf()
    // };
}

class ContactsImportFromLMS extends React.Component {

    state = {
        date_range: getDefaultDateRange(),
        openEditModal: false,
        selectedContact: false,
        selectedIndex: 0,
        contacts: false,
        trainees: false,
        needToEditContacts: false,
        randNum: false, 
        dataLoaded: true //*incomplete - change this to false (if need to load maintenance data)
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedIndex } = this.state;
        const { importContactsList, lmsTraineesList, randNum } = this.props;
        if ( importContactsList && lmsTraineesList && randNum && randNum != this.state.randNum ) {
            const trainees = ( lmsTraineesList ? cloneCollections( lmsTraineesList ) : false ),
                contacts = ( importContactsList ? this.getNeedToUpdateContacts( importContactsList, trainees ) : false ), 
                needToEditContacts = this.getNeedToEditContacts(contacts,trainees),
                selectedContact = ( needToEditContacts && isArrayExists( needToEditContacts ) && needToEditContacts[selectedIndex] && !_isEmpty( needToEditContacts[selectedIndex] ) ? cloneCollections( needToEditContacts[selectedIndex] ) : false );
            this.setState({ 
                openEditModal: ( selectedContact ? true : false ),
                needToEditContacts,
                selectedContact,
                contacts, 
                trainees, 
                randNum 
            });
        }
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('contacts_import_lms'));
    }

    doReset = () => {
        this.props.dispatch(resetRedux('contacts_import_lms'));
        this.props.dispatch(appChangesReset());
        setTimeout(() => {
            this.setState({
                openEditModal: false,
                selectedContact: false,
                selectedIndex: 0,
                contacts: false,
                trainees: false,
                randNum: false
            });
        },200);
    }

    handleReset = () => {
        const answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to reset everything?");
        if ( answer ) {
            this.doReset();
        }
    }

    handleImportDataToCRM = (event) => {
        event.preventDefault();
        const { contacts } = this.state;
        let list = [];

        if ( contacts && isArrayExists( contacts ) ) {
            _forEach( contacts, contact => {
                let val = cloneCollections(contact);
                // remove unique_id
                if ( val.unique_id ) {
                    delete val.unique_id;
                }
                list.push( val );
            });
        } // end - contacts

        this.props.dispatch(toggleLoader(true,"The import process is currently in progress. Please do not close this window or click the Back button on your browser. On average, 90 seconds are needed to process every 100 contacts."));
        doContactsImport(list)
        .then( results => {
            this.props.dispatch(toggleLoader(false));
            this.doReset();
            triggerSuccessAlert("Contacts Import Success","none");
        })
        .catch(error => {
            let errorMessage = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to import contacts. Please try again.' ) );
            this.props.dispatch(toggleLoader(false));
            this.doReset();
            triggerErrorAlert(errorMessage,"none");
        });
    }

    handleImportDataFromLMS = () => {
        const { date_range } = this.state;
        this.props.dispatch(importContactsFromLMS(date_range));
    }

    handleContactPrevious = (event) => {
        event.preventDefault();
        const { needToEditContacts, selectedIndex } = this.state;
        const newIndex = ( selectedIndex > 0 ? selectedIndex-1 : 0 );
        const newContact = ( needToEditContacts && needToEditContacts[newIndex] && isObjectExists( needToEditContacts[newIndex] ) ? cloneCollections( needToEditContacts[newIndex] ) : false );
        this.setState({ selectedIndex: newIndex, selectedContact: newContact });
    }


    handleContactNext = (event) => {
        event.preventDefault();
        const { needToEditContacts, selectedIndex } = this.state;
        const total = _size( needToEditContacts ),
            newIndex = ( selectedIndex < (total-1) ? selectedIndex+1 : total );

        if ( newIndex === total ) {
            this.setState({ selectedIndex: 0, selectedContact: false, openEditModal: false });
        } else {
            const newContact = ( needToEditContacts && needToEditContacts[newIndex] && isObjectExists( needToEditContacts[newIndex] ) ? cloneCollections( needToEditContacts[newIndex] ) : false );
            this.setState({ selectedIndex: newIndex, selectedContact: newContact });
        }
    }

    handleContactUpdate = (event) => {
        event.preventDefault();
        const { contacts, trainees, needToEditContacts, selectedContact, selectedIndex } = this.state;

        // update contacts 
        const newContacts = ( contacts && isArrayExists( contacts ) ? cloneCollections( contacts ) : [] );
        let index = ( contacts && isArrayExists( contacts ) && selectedContact && selectedContact.unique_id && !_isEmpty( selectedContact.unique_id ) ? _findIndex( contacts, { unique_id: selectedContact.unique_id }) : -1 );
        if ( index >= 0 ) {
            newContacts[index] = cloneCollections(selectedContact);
        } // end - index

        // update needToEditContacts
        const newNeedToEditContacts = ( needToEditContacts && isArrayExists( needToEditContacts ) ? cloneCollections( needToEditContacts ) : [] );
        let reviewIndex = ( needToEditContacts && isArrayExists( needToEditContacts ) && selectedContact && selectedContact.unique_id && !_isEmpty( selectedContact.unique_id ) ? _findIndex( needToEditContacts, { unique_id: selectedContact.unique_id }) : -1 );
        if ( reviewIndex >= 0 ) {
            newNeedToEditContacts[reviewIndex] = cloneCollections(selectedContact);
        } // end - reviewIndex

        this.setState({ contacts: newContacts, needToEditContacts: newNeedToEditContacts });
    }

    handleSkipUpdating = (event) => {
        event.preventDefault();
        const { contacts, trainees, needToEditContacts, selectedContact, selectedIndex } = this.state;

        // update contacts 
        const newContacts = ( contacts && isArrayExists( contacts ) ? _filter( contacts, c => !( c.id && !_isEmpty( c.id ) ) ) : [] );

        this.setState({ contacts: newContacts, needToEditContacts: [], selectedIndex: 0, selectedContact: false, openEditModal: false });
    }

    checkIfContactInfoNotMatch = (contact,trainee) => {
        let notMatch = false;
        if ( trainee && contact ) {
            if ( 
                ( !_isEqual( trainee.trainee_name, contact.last_name ) ) || 
                ( !_isEmpty( trainee.trainee_contact ) && ( !_isEqual( trainee.trainee_contact, contact.office_phone ) && !_isEqual( trainee.trainee_contact, contact.mobile_phone ) && !_isEqual( trainee.trainee_contact, contact.other_phone ) ) ) || 
                ( !_isEmpty( trainee.trainee_job_title_dept ) && !_isEqual( trainee.trainee_job_title_dept, contact.position ) )
            ) {
                notMatch = true;
            } // end - 
        } // end - trainee
        return notMatch;
    }

    doesContactNeedToBeReview = (contact, trainees ) => {
        let toBeReviewed = false;
        // make sure to do this for existing contacts only
        if ( contact && contact.id && !_isEmpty( contact.id ) ) {
            let trainee = _find( trainees, { trainee_email: contact.email } );
            toBeReviewed = this.checkIfContactInfoNotMatch(contact,trainee);
        } // end - c
        return toBeReviewed; 
    }

    doesContactNeedToBeUpdate = (contact, trainees ) => {
        let toBeUpdated = false;
        if ( contact ) {
            if ( contact.id && !_isEmpty( contact.id ) ) {
                let trainee = _find( trainees, { trainee_email: contact.email } );
                toBeUpdated = this.checkIfContactInfoNotMatch(contact,trainee);
            } else {
                toBeUpdated = true;
            } // end - toBeUpdated
        } // end - contact
        return toBeUpdated; 
    }

    getNeedToEditContacts = (contacts,trainees) => {
        return ( contacts && isArrayExists( contacts ) ? _filter( contacts, (c) => this.doesContactNeedToBeReview( c, trainees ) ) : [] );
    }

    getNeedToUpdateContacts = (contacts,trainees) => {
        return ( contacts && isArrayExists( contacts ) ? _filter( cloneCollections( contacts ), (c) => this.doesContactNeedToBeUpdate( c, trainees ) ) : [] );
    }

    renderEditForm = () => {
        const { contacts, trainees, selectedContact } = this.state;
        return (
        <div>
            <ContactForm
                contact={( selectedContact || false )}
                trainee={( selectedContact && selectedContact.email && !_isEmpty( selectedContact.email ) && trainees && isArrayExists( trainees ) ? _find( trainees, { trainee_email: selectedContact.email } ) : false )}
                onUpdate={(newValue) => this.setState({ selectedContact: newValue })} />
        </div>
        );
    }

    renderEditActions = () => {
        const { selectedIndex, contacts, trainees, needToEditContacts } = this.state;
        const total = ( needToEditContacts && isArrayExists( needToEditContacts ) ? _size( needToEditContacts ) : 0 );
        return (
        <DialogActions align="center">
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    Total Records: {selectedIndex+1} of {total}
                </Grid>
                <Grid item xs={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <Button color="secondary" onClick={this.handleReset}>Cancel</Button>
                        </Grid>
                        <Grid item xs={8} alignItems="center">
                            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group" style={{ boxShadow: 'none' }}>
                                <Button onClick={this.handleContactPrevious} disabled={( selectedIndex === 0 ? true : false )} >Previous</Button>
                                <Button onClick={this.handleContactUpdate} style={{ marginLeft: '12px' }}>Save</Button>
                                <Button onClick={this.handleContactNext} style={{ marginLeft: '12px' }}>{( selectedIndex === (total-1) ? 'Done' : 'Next' )}</Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={2} alignItems="flex-end">
                            <Button color="primary" variant='contained' onClick={this.handleSkipUpdating}>Skip Updating</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DialogActions>
        )
    }

    renderDateRangeForm = () => {
        const { date_range } = this.state;
        return <DateRangeForm 
                date_range={date_range} 
                default_date_range={getDefaultDateRange()}
                onUpdate={(newValue) => this.setState({ date_range: newValue })} 
                onTrigger={this.handleImportDataFromLMS} />
    }

    renderTable = () => {
        const { contacts, trainees } = this.state;
        return (
        <>

            <div style={{ marginBottom: "20px" }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <strong style={{ fontWeight: '700' }}>New Contact(s): </strong>{_size( _filter( contacts, c => !( c.id && !_isEmpty( c.id ) ) ) )}<br />
                        <strong style={{ fontWeight: '700' }}>Existing Contact(s): </strong>{_size( _filter( contacts, c => ( c.id && !_isEmpty( c.id ) ) ) )}
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <InverseButton style={{ padding: '8px 15px' }} onClick={this.handleReset}>Reset</InverseButton>
                    </Grid>
                </Grid>
            </div>

            <ResultTable
                contacts={contacts}
                trainees={trainees}
                onUpdate={(newValue) => this.setState({ contacts: newValue })}
             />

            { contacts && isArrayExists( contacts ) ? (
            <div style={{ marginTop: "45px", textAlign: "center" }}>
                <InfoButton style={{ padding: "15px 45px" }} onClick={this.handleImportDataToCRM}>Perform Contacts Import</InfoButton>
            </div>
            ) : null }

        </>
        )
    }

    renderContents() {
        const { trainees, contacts, randNum, openEditModal } = this.state;
        return (
        <>

            { trainees && contacts && randNum ? this.renderTable() : this.renderDateRangeForm() }

            <ModalView 
                open={openEditModal}
                title={(
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        Please Review these Contacts' Data
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <strong style={{ fontWeight: '700' }}>New Contact(s): </strong>{_size( _filter( contacts, c => !( c.id && !_isEmpty( c.id ) ) ) )}
                        <span style={{ padding: '0 5px' }}>|</span>
                        <strong style={{ fontWeight: '700' }}>Existing Contact(s): </strong>{_size( _filter( contacts, c => ( c.id && !_isEmpty( c.id ) ) ) )}
                    </Grid>
                </Grid>
                )}
                maxWidth="lg"
                disableBackdrop={true}
                onClose={() => this.setState({ openEditModal: false, selectedContact: false, needToEditContacts: false, selectedIndex: 0 })}
                customActions={this.renderEditActions()}
                contents={this.renderEditForm()} />

            <SnackBarSave noSaveButton={true} />

        </>
        );
    }

    render() {
        const { dataLoaded } = this.state;
        return <AppWrapper 
                title="Import Contacts From LMS"
                subtitle="Contacts"
                back="/contacts"
                breadcrumbs={[
                    { url: '/contacts', label: 'Contacts' },
                    { label: 'Import' }
                ]}
                onLoad={( !( dataLoaded ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        importContactsList: state.contacts && state.contacts.import_contacts || null,
        lmsTraineesList: state.contacts && state.contacts.lms_trainees || null,
        randNum: state.contacts && state.contacts.rand_import || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ContactsImportFromLMS);