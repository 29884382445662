import _random from 'lodash/random';

import { 
    GET_TEST_QUERIES,
    RESET_TEST_QUERIES 
} from '../actions/types';

const initialState = {
    list: null,
    total: null,
    firstDoc: null,
    lastDoc: null,
    updated: false,
    rand: false
};

export const test_queries = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_TEST_QUERIES:
            return {
                ...state,
                list: ( payload.list || [] ),
                total: ( payload.total || null ),
                firstDoc: ( payload.firstDoc || null ),
                lastDoc: ( payload.lastDoc || null ),
                rand: _random(1,9999)
            };
        case RESET_TEST_QUERIES:
            return {
                ...state,
                list: null,
                total: null,
                firstDoc: null,
                lastDoc: null,
                rand: false
            }
        default:
            return state;
    }
}