import Alert from 'react-s-alert';

import {
	MISC_CHANGES_NOT_SAVED,
	MISC_CHANGES_RESET,
	RESET_ACCOUNT,
	RESET_CONTACT,
	RESET_POTENTIAL,
	RESET_QUOTE,
	RESET_INVOICE,
	RESET_PRODUCT,
	RESET_REPORT,
	RESET_COMMENT,
	RESET_ACCOUNTS,
	RESET_CONTACTS,
	RESET_POTENTIALS,
	RESET_QUOTES,
	RESET_INVOICES,
	RESET_CREDIT_NOTE,
	RESET_CREDIT_NOTES,
	RESET_PRODUCTS,
	RESET_PRODUCTS_OPTIONS,
	RESET_EMAIL_TEMPLATES,
	RESET_EMAIL_TEMPLATE,
	RESET_GET_ACCOUNT_BY,
	RESET_GET_CONTACTS_BY,
	RESET_GET_POTENTIALS_BY,
	RESET_GET_QUOTES_BY,
	RESET_GET_INVOICES_BY,
	RESET_GET_PRODUCTS_BY,
	RESET_GET_EMAILS_BY,
	RESET_DUPLICATES_ACCOUNT,
	RESET_MASS_ACTION,
	RESET_TEST_QUERIES,
	RESET_IMPORT_CONTACTS_FROM_LMS,
	RESET_IMPORT_CONTACTS_FROM_CSV
} from './types';

export const appChangesMade = () => {
	return dispatch => {
  		dispatch({ type: MISC_CHANGES_NOT_SAVED });
	}
}

export const appChangesReset = () => {
	return dispatch => {
  		dispatch({ type: MISC_CHANGES_RESET });
	}
}

export const resetRedux = (type) => {
	return dispatch => {
		switch( type ) {
			case 'account':
				setTimeout(() => {
					dispatch({ type: RESET_ACCOUNT });
					dispatch({ type: RESET_GET_CONTACTS_BY });
					dispatch({ type: RESET_GET_POTENTIALS_BY });
					dispatch({ type: RESET_GET_QUOTES_BY });
					dispatch({ type: RESET_GET_INVOICES_BY });
					dispatch({ type: RESET_GET_EMAILS_BY });
				}, 150);
				break;
			case 'accounts':
				setTimeout(() => {
					dispatch({ type: RESET_ACCOUNTS });
				}, 150);
				break;
			case 'duplicates_account':
				setTimeout(() => {
					dispatch({ type: RESET_DUPLICATES_ACCOUNT });
				}, 150);
				break;
			case 'contact':
				setTimeout(() => {
					dispatch({ type: RESET_CONTACT });
				}, 150);
				break;
			case 'contacts':
				setTimeout(() => {
					dispatch({ type: RESET_CONTACTS });
				}, 150);
				break;
			case 'contacts_import_lms':
				setTimeout(() => {
					dispatch({ type: RESET_IMPORT_CONTACTS_FROM_LMS });
				}, 150);
				break;
			case 'contacts_import_csv':
				setTimeout(() => {
					dispatch({ type: RESET_IMPORT_CONTACTS_FROM_CSV });
				}, 150);
				break;
			case 'potential':
				setTimeout(() => {
					dispatch({ type: RESET_POTENTIAL });
				}, 150);
				break;
			case 'potentials':
				setTimeout(() => {
					dispatch({ type: RESET_POTENTIALS });
				}, 150);
				break;
			case 'quote':
				setTimeout(() => {
					dispatch({ type: RESET_QUOTE });
				}, 150);
				break;
			case 'quotes':
				setTimeout(() => {
					dispatch({ type: RESET_QUOTES });
				}, 150);
				break;
			case 'invoice':
				setTimeout(() => {
					dispatch({ type: RESET_INVOICE });
				}, 150);
				break;
			case 'invoices':
				setTimeout(() => {
					dispatch({ type: RESET_INVOICES });
				}, 150);
				break;
			case 'credit_note':
				setTimeout(() => {
					dispatch({ type: RESET_CREDIT_NOTE });
				}, 150);
				break;
			case 'credit_notes':
				setTimeout(() => {
					dispatch({ type: RESET_CREDIT_NOTES });
				}, 150);
				break;
			case 'product':
				setTimeout(() => {
					dispatch({ type: RESET_PRODUCT });
				}, 150);
				break;
			case 'products':
				setTimeout(() => {
					dispatch({ type: RESET_PRODUCTS });
				}, 150);
				break;
			case 'products_options':
				setTimeout(() => {
					dispatch({ type: RESET_PRODUCTS_OPTIONS });
				}, 150);
				break;
			case 'email_template':
				setTimeout(() => {
					dispatch({ type: RESET_EMAIL_TEMPLATE });
				}, 150);
				break;
			case 'email_templates':
				setTimeout(() => {
					dispatch({ type: RESET_EMAIL_TEMPLATES });
				}, 150);
				break;
			case 'report':
				setTimeout(() => {
					dispatch({ type: RESET_REPORT });
				}, 150);
				break;
			case 'comment':
				setTimeout(() => {
					dispatch({ type: RESET_COMMENT });
				}, 150);
				break;
			case 'mass_action':
				setTimeout(() => {
					dispatch({ type: RESET_MASS_ACTION });
				}, 150);
				break;
			case 'test_queries':
				setTimeout(() => {
					dispatch({ type: RESET_TEST_QUERIES });
				}, 150);
				break;
		}
	}
}