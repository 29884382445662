/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import GrapesJS from 'grapesjs';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _random from 'lodash/random';
import _snakeCase from 'lodash/snakeCase';
import _toLower from 'lodash/toLower';
import _last from 'lodash/last';

import Form from "../AddNewEmail/form";
import Editor from '../../pages/EmailTemplatePage/editor_mce';

import DotsLoader from '../DotsLoader';
import ModalView from '../ModalView';
import MediaLibrary from '../MediaLibrary';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';
import FormSelect2 from '../FormSelect2';
import FormMultiSelect from '../FormMultiSelect';
import ModalSelectEmailTemplate from '../ModalSelectEmailTemplate';
import ItemNotFound from '../ItemNotFound';

import { isArrayExists, validateEmail, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, getSelectValues, combineStrings } from '../../helpers/data';
import { getSchemaDefaultValue, doSchemaErrorCheck } from '../../helpers/schemas'

import { InverseButton } from '../../styles/button';

import { editEmail } from '../../actions/emails';
import { fb_getEmail } from '../../actions/emails/fb';

import { emailSchema } from '../../schemas/email';

import { defaultEmailTemplate, FIREBASE_STORAGE_URL } from '../../constants';

const useStyles = theme => ({
    closeBtn: {
        position: 'absolute',
        top: '0',
        right: '0',
        padding: '5px 8px',
        borderRadius: '0px'
    }
});

class ModalEditEmail extends React.Component {

    state = {
        email: false,
        randNum: false,
        openEmailTemplateModal: false,
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { open } = this.props;
        if ( open && !prevProps.open ) {
            this.setInitialValues();
        }
    }

    setInitialValues = () => {
        const { email_id } = this.props;
        if ( email_id && !_isEmpty( email_id ) ) {
            // retrieve the email data & content
            fb_getEmail(email_id)
            .then(email => {
                this.setState({ email, randNum: _random(1,9999) });
            })
            .catch(error => {
                var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve email' ) );
                triggerErrorAlert(errMsg);
                this.setState({ email: {}, randNum: _random(1,9999) });
            })
        } else {
            triggerErrorAlert('Invalid email ID');
            this.setState({ email: {}, randNum: _random(1,9999) });
        }
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose ) {
            this.setState({ email: false, randNum: false }); // reset 
            onClose();
        } // end - onClose
    }

    handleFormUpdate = (newValue,key) => {
        const { email } = this.state;
        var newData = ( email ? cloneCollections( email ) : {} );
        newData[key] = newValue;

        this.setState({ email: newData });
    }

    handleUpdate = (status) => {
        const { history, type, typeID } = this.props;
        const { email } = this.state;
        // const editor = ( GrapesJS.editors && isArrayExists( GrapesJS.editors ) ? _last( GrapesJS.editors ) : false );
        let error = false,
            formData = {};

        // do error check
        emailSchema.forEach(schema => {
            switch( schema.id ) {
                case 'status':
                    formData[schema.id] = ( status && !_isEmpty( status ) ? status : schema.default );
                    break;
                default:
                    formData[schema.id] = ( email && email[schema.id] ? cloneCollections( email[schema.id] ) : schema.default );
                    if ( !doSchemaErrorCheck( formData[schema.id], schema, 'update' ) ) {
                        error = 'Please fill out "' + schema.label + '" field';
                    }
                    break;
            } // end - schema.id
        });

        // make sure there is a email inserted in to field
        if ( ! ( formData.emails && _find( formData.emails, { type: 'to' } ) ) )
            error = 'Please fill out "To" field';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            // compile editor contents
            // if ( editor ) {
            //     formData['contents_html'] = editor.getHtml();
            //     formData['contents_css'] = editor.getCss();
            //     formData['contents_js'] = editor.getJs();
            // } // end - editor

            // compile editor for tinymce
            formData['contents_html'] = ( email && email.contents_html && !_isEmpty( email.contents_html ) ? email.contents_html : '' );
            formData['contents_css'] = ( email && email.contents_css && !_isEmpty( email.contents_css ) ? email.contents_css : '' );
            formData['contents_js'] = ( email && email.contents_js && !_isEmpty( email.contents_js ) ? email.contents_js : '' );

            // trigger edit email
            this.props.dispatch(editEmail(formData,type,typeID));
        } // end - error
    }

    handleEmailTemplateSelect = (template) => {
        const { email } = this.state;
        let newData = ( email ? cloneCollections( email ) : {} );

        // import template subject line
        newData.subject = ( template && template.subject && !_isEmpty( template.subject ) ? template.subject : '' );

        // import template content
        newData.contents_html = ( template && template.contents_html && !_isEmpty( template.contents_html ) ? template.contents_html : '' );
        newData.contents_css = ( template && template.contents_css && !_isEmpty( template.contents_css ) ? template.contents_css : '' );
        newData.contents_js = ( template && template.contents_js && !_isEmpty( template.contents_js ) ? template.contents_js : '' );

        this.setState({ 
            email: newData, 
            openEmailTemplateModal: false,
            randNum: _random(1,9999)
        });
    }

    renderEditor = () => {
        const { readOnly } = this.props;
        const { email, randNum } = this.state;
        return <Editor 
            template={( email || null )}
            noChangesTrigger={true}
            isPreview={( readOnly || false)}
            randNum={( randNum || false )}
            onEditorUpdate={this.handleFormUpdate} />
    }

    renderForm = () => {
        const { authData, readOnly } = this.props;
        const { email } = this.state;
        return <Form
            authData={( authData || false )}
            email={( email || null )}
            readOnly={( readOnly || false )}
            onTemplateTriggered={() => this.setState({ openEmailTemplateModal: true })}
            onFormUpdate={newData => this.setState({ email: newData })}
            onAddNew={this.handleUpdate} />
    }

    renderComposeContainer = () => {
        const { email, openEmailTemplateModal } = this.state;
        const { classes, readOnly } = this.props;
        return (
        <div>
            {this.renderForm()}

            <div style={{ marginTop: "45px" }}>
                <div style={{ marginBottom: "20px" }}>
                    <MediaLibrary
                        attachable={true}
                        readOnly={( readOnly || false )}
                        attachments={( email && email.attachments ? email.attachments : [] )}
                        onAttachmentUpdate={(medias) => this.handleFormUpdate(medias,'attachments')} />
                </div>
                {this.renderEditor()}
            </div>

            <InverseButton className={classes.closeBtn} noIconMargin="yes" size="small" minWidth="0px" onClick={this.handleClose}><i className="fa fa-times"></i></InverseButton>

            <ModalSelectEmailTemplate
                open={( openEmailTemplateModal || false )}
                email={( email || false )}
                onClose={() => this.setState({ openEmailTemplateModal: false })}
                onSelect={this.handleEmailTemplateSelect} />
        </div>
        );
    }

    render() {
        const { open, readOnly } = this.props;
        const { randNum, email } = this.state;
        return (
        <div>

            <ModalView 
                open={open}
                title={ readOnly ? "View Email" : "Compose Email" }
                maxWidth="lg"
                noAction={true}
                cancelLabel="Close"
                disableBackdrop={true}
                disableEnforceFocus={true}
                onClose={this.handleClose}
                contents={ open ? ( randNum ? ( email && email.id && !_isEmpty( email.id ) ? this.renderComposeContainer() : <ItemNotFound item="Email" /> ) : <DotsLoader /> ) : null } />

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(ModalEditEmail);