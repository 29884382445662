import React from 'react';
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
// import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
// import Divider from '@material-ui/core/Divider';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';

/* eslint-disable */

import EditForm from './form';

// import FormInput from '../../components/FormInput';
// import FormSelect from '../../components/FormSelect';
// import FormMultiSelect from '../../components/FormMultiSelect';
// import FormDatePicker from '../../components/FormDatePicker';

import ListUpdates from '../../components/ListUpdates';
import ListComments from '../../components/ListComments';

// import { InfoButton, GreyButton } from '../../styles/button';
// import { FormBox } from '../../styles/form';

// import { isAdmin } from '../../helpers/auth';
import { isArrayExists, inArray } from '../../helpers/validation';
// import { triggerErrorAlert } from '../../helpers/alert';
// import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
// import { reverseUsersValues } from '../../helpers/users';
// import { getMomentTime } from '../../helpers/date';
// import { isSchemaRequired } from '../../helpers/schemas';

// import { accountSchema } from '../../schemas/account';
// import { COUNTRIES_LIST } from '../../data/const_countries';
// import { STATE_OPTIONS } from '../../data/const_states';

// import { YES_NO_OPTIONS } from '../../constants';

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

class AccountDetails extends React.Component {

    state = {
        randNum: false
    };

    renderUpdatesBox = () => {
        const { classes, account } = this.props;
        return (
        <div>
            <Typography variant="h4" className={classes.boxheading}>Updates</Typography>
            <ListUpdates 
                list={( account && account.updates && isArrayExists( account.updates ) ? account.updates : false )} />
        </div>
        );
    }

    renderCommentsBox = () => {
        const { classes, account, authData, updateActions } = this.props;
        return (
        <div>
            <Typography variant="h4" className={classes.boxheading}>Comments</Typography>
            <ListComments 
                authData={( authData || false )}
                refer_type="account"
                refer_id={( account && account.id && !_isEmpty( account.id ) ? account.id : false )}
                refer_label={( account && account.name && !_isEmpty( account.name ) ? account.name : false )}
                updateActions={( updateActions || false )}
                list={( account && account.comments && isArrayExists( account.comments ) ? account.comments : false )} />
        </div>
        );
    }
   
    render() {
        return (
        <div>

            <EditForm {...this.props} />

            <Grid container spacing={3} style={{ marginTop: "30px" }}>
                <Grid item xs={6}>
                {this.renderCommentsBox()}
                </Grid>
                <Grid item xs={6}>
                    {this.renderUpdatesBox()}
                </Grid>
            </Grid>


        </div>
        )
    }

}

export default compose(
    withStyles(useStyles),
    withRouter
)(AccountDetails);