import React from 'react';
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import shortid from 'shortid';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import {
  Table,
  Caption,
  Head,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell
} from '@zendeskgarden/react-tables';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import ListAltIcon from '@material-ui/icons/ListAlt';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import red from '@material-ui/core/colors/red';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _sortBy from 'lodash/sortBy';
import _round from 'lodash/round';
import _remove from 'lodash/remove';
import _random from 'lodash/random';

/* eslint-disable */

import ModelDelete from '../../components/ModalDelete';
import ModalSelectProducts from '../../components/ModalSelectProducts';
import ModalSelectPrice from '../../components/ModalSelectPrice';
import ModalSelectDiscount from '../../components/ModalSelectDiscount';

import { FormBox } from '../../styles/form';
import { ButtonGroup, ErrorButton, InfoButton, GreyButton, InverseButton } from '../../styles/button';

import { isAdmin } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { reverseUsersValues } from '../../helpers/users';
import { getMomentTime } from '../../helpers/date';
import { formatMoney } from '../../helpers/number';
import { recountItemPosition, getItemTotalPrice, getItemPreTaxTotal, getItemTaxAmount, getItemDiscountAmount, getItemNetPrice, getTaxRate } from '../../helpers/quotes';
import { newInvoiceItem } from '../../helpers/invoices';

import { invoiceSchema } from '../../schemas/invoice';

const useStyles = theme => ({
    tablewrapper: {
        margin: '30px 0',
        '& .MuiTextField-root': {
            margin: theme.spacing(0),
            width: '100%',
        },
        '& input.MuiOutlinedInput-input, & div.MuiOutlinedInput-multiline': {
            padding: theme.spacing(1)
        }
    },
    productinput: {
        "& .MuiInputBase-root.Mui-disabled": {
            color: theme.palette.background
        }
    }
});

const DraggableRow = styled(Row)`
    ${props =>
        props.isDraggingOver
        ? `
        :hover {
        background-color: inherit !important;
        }
    `
    : ''};
`;

const DraggableCell = styled(Cell)`
    .MuiOutlinedInput-adornedEnd { padding-right: 0px; }
    .MuiOutlinedInput-adornedStart { padding-left: 0px; }
    .MuiInputAdornment-positionStart { margin-right: 0px; }
    .MuiInputAdornment-positionStart > .MuiIconButton-root { padding-left: 4px; padding-right: 0px; }
    ${props =>
        props.isDragging
        ? `
        display: inline-block !important;
    `
        : ''};
`;

const DraggableContainer = styled.div`
    display: inline-block;

    button { cursor: grab; }

    :focus {
        outline: none;
    }
`;

const DeleteIcon = styled.i`
    display: inline-block;
    margin: 2px 0 0 20px;
    cursor: pointer;

    &:hover {
        color: ${red['500']}
    }
`;

class InvoiceItems extends React.Component {

    state = {
        openDeleteModal: false,
        deleteModal: false,
        openSelectProduct: false,
        openSelectPrice: false,
        openSelectDiscount: false,
        selectItem: false
    };

    handleFormUpdate = ( newValue, key ) => {
        const { onFormUpdate, invoice } = this.props;
        let newData = ( invoice && !_isEmpty( invoice ) ? cloneCollections( invoice ) : {} );

        switch( key ) {
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    handleRowUpdate = (item,key) => event => {
        const { invoice } = this.props;
        let newProducts = ( invoice && invoice.products_linked && isArrayExists( invoice.products_linked ) ? cloneCollections( invoice.products_linked ) : [] ),
            index = ( newProducts ? _findIndex( newProducts, { id: item.id }) : -1 );

        if ( index >= 0 ) {
            newProducts[index][key] = event.target.value;
        } // end - index

        this.handleFormUpdate( newProducts, 'products_linked' );
    }

    handleProductSelect = (product) => {
        const { invoice } = this.props;
        const { selectItem } = this.state;

        let newProducts = ( invoice && invoice.products_linked && isArrayExists( invoice.products_linked ) ? cloneCollections( invoice.products_linked ) : [] ),
            index = ( newProducts && selectItem && selectItem.id ? _findIndex( newProducts, { id: selectItem.id }) : -1 );

        if ( index >= 0 ) {
            newProducts[index].product_id = ( product && product.product_id || '' );
            newProducts[index].name = ( product && product.name || '' );
            newProducts[index].unit_price = ( product && product.unit_price || 0 );
            newProducts[index].pricing_category = ( product && product.pricing_category && !_isEmpty( product.pricing_category ) ? product.pricing_category : {} );
        } // end - index

        // update data & reset modal
        this.handleFormUpdate( newProducts, 'products_linked' );
        this.setState({ openSelectProduct: false, selectItem: false });
    }

    handleDiscountSelect = ({ discount,type }) => {
        const { invoice } = this.props;
        const { selectItem } = this.state;

        let newProducts = ( invoice && invoice.products_linked && isArrayExists( invoice.products_linked ) ? cloneCollections( invoice.products_linked ) : [] ),
            index = ( newProducts && selectItem && selectItem.id ? _findIndex( newProducts, { id: selectItem.id }) : -1 );

        if ( index >= 0 ) {
            newProducts[index].discount = ( discount || 0 );
            newProducts[index].discount_type = ( type || '' );
        } // end - index

        // update data & reset modal
        this.handleFormUpdate( newProducts, 'products_linked' );
        this.setState({ openSelectDiscount: false, selectItem: false });
    }

    handlePriceSelect = (newPrice) => {
        const { invoice } = this.props;
        const { selectItem } = this.state;

        let newProducts = ( invoice && invoice.products_linked && isArrayExists( invoice.products_linked ) ? cloneCollections( invoice.products_linked ) : [] ),
            index = ( newProducts && selectItem && selectItem.id ? _findIndex( newProducts, { id: selectItem.id }) : -1 );

        if ( index >= 0 ) {
            newProducts[index].unit_price = ( newPrice || 0 );
        } // end - index

        // update data & reset modal
        this.handleFormUpdate( newProducts, 'products_linked' );
        this.setState({ openSelectPrice: false, selectItem: false });
    }

    handleItemDelete = () => {
        const { invoice } = this.props;
        const { deleteModal } = this.state;
        let newProducts = ( invoice && invoice.products_linked && isArrayExists( invoice.products_linked ) ? cloneCollections( invoice.products_linked ) : [] );

        if ( deleteModal && deleteModal.id && !_isEmpty( deleteModal.id ) ) {
            let pulled = _remove( newProducts, { id: deleteModal.id });
        } // end - deleteModal

        // re-count position
        newProducts = recountItemPosition( newProducts );

        this.setState({ openDeleteModal: false, deleteModal: false });
        this.handleFormUpdate( newProducts, 'products_linked' );
    }

    handleAddNewProduct = () => {
        const { invoice } = this.props;
        let newProducts = ( invoice && invoice.products_linked && isArrayExists( invoice.products_linked ) ? cloneCollections( invoice.products_linked ) : [] );

        // add new product
        newProducts.push( newInvoiceItem( this.getTotalItems() ) );

        this.handleFormUpdate( newProducts, 'products_linked' );
    }

    onDragEnd = (result) => {
        const { invoice } = this.props;
        let newProducts = ( invoice && invoice.products_linked && isArrayExists( invoice.products_linked ) ? cloneCollections( invoice.products_linked ) : [] );

        if ( result.draggableId && !_isEmpty( result.draggableId ) ) {
            let index = _findIndex( newProducts, { id: result.draggableId } );
            if ( index >= 0 ) {
                // re-order
                newProducts = Array.from(newProducts);
                let [removed] = newProducts.splice(result.source.index, 1);
                newProducts.splice(result.destination.index, 0, removed);

                // re-count position
                newProducts = recountItemPosition( newProducts );

            } // end - index
        } // end - result.draggableId

        this.handleFormUpdate( newProducts, 'products_linked' );
    }

    getTotalItems = () => {
        const { invoice } = this.props;
        return ( invoice.products_linked && isArrayExists( invoice.products_linked ) ? _size( invoice.products_linked ) : 0 );
    }

    getSelectedRegion = (type) => {
        const { invoice, regions, authData } = this.props;
        let region = ( regions && isArrayExists( regions ) ? _find( regions, { id: ( invoice && invoice.company_details && !_isEmpty( invoice.company_details ) ? invoice.company_details : ( authData && authData.region && !_isEmpty( authData.region ) ? authData.region : '' ) ) } ) : false );
        if ( type && !_isEmpty( type ) ) {
            return ( region && region[type] || '' );
        } else {
            return region
        } // end - type
    }

    isIndividualTax = () => {
        const { invoice } = this.props;
        let region = this.getSelectedRegion();
        return ( invoice.tax_mode && invoice.tax_mode === 'individual' && region && region.tax_amount && region.tax_label && !_isEmpty( region.tax_label ) ? true : false );
    }

    renderCurrencyFormat = (number) => {
        return formatMoney( _round(number,2), 2, 3 );
    }

    renderTableRow = droppableSnapshot => (item,index) => {
        const { classes, invoice } = this.props;
        return (
        <Draggable key={item.id} draggableId={item.id} index={index}>
        {(provided, snapshot) => (
            <DraggableRow
                ref={provided.innerRef}
                isDragging={snapshot.isDragging}
                isDraggingOver={droppableSnapshot.isDraggingOver}
                hovered={snapshot.isDragging}
                focused={
                    droppableSnapshot.isDraggingOver ? snapshot.isDragging : undefined
                }
                {...provided.draggableProps.style}
                {...provided.draggableProps}>
                <DraggableCell isDragging={snapshot.isDragging} width="10%">
                    <DraggableContainer id={item.id} {...provided.dragHandleProps}>
                        <Tooltip title="Drag" arrow="true"><i className="fa fa-bars"></i></Tooltip>
                    </DraggableContainer>
                    { this.getTotalItems() > 1 ? <span><Tooltip title="Delete" arrow="true"><DeleteIcon className="fa fa-trash" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}></DeleteIcon></Tooltip></span> : null }
                </DraggableCell>
                <DraggableCell isDragging={snapshot.isDragging} width={( this.isIndividualTax() ? "30%" : "40%" )}>
                    <div style={{ width: '100%' }}>
                        <TextField 
                            label="" 
                            value={( item.name || '' )} 
                            size="small" 
                            variant="outlined"
                            disabled={true} 
                            className={classes.productinput}
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip title="Select Product" arrow="true"><IconButton aria-label="Select Product"
                                    onClick={() => this.setState({ openSelectProduct: true, selectItem: item })}><ControlPointIcon /></IconButton></Tooltip>
                                </InputAdornment>
                                )
                            }}
                            onChange={this.handleRowUpdate(item,'name')} />
                        <div style={{ paddingTop: "15px" }}>
                            <TextField label=""  value={( item.desc || '' )} rows={6} multiline size="small" variant="outlined" onChange={this.handleRowUpdate(item,'desc')} />
                        </div>
                    </div>
                </DraggableCell>
                <DraggableCell isDragging={snapshot.isDragging} width="10%">
                    <TextField label="" value={( item.quantity || 1 )} size="small" type="number" variant="outlined" onChange={this.handleRowUpdate(item,'quantity')} />
                </DraggableCell>
                <DraggableCell isDragging={snapshot.isDragging} width="20%">
                    <div style={{ width: '100%' }}>
                        <div style={{ minHeight: '32px' }}>
                            <TextField 
                                label="" value={( item.unit_price || 0 )} size="small" type="number" variant="outlined" 
                                InputProps={ item.product_id && !_isEmpty( item.product_id ) ? {
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <Tooltip title="Select Price" arrow="true"><IconButton aria-label="Select Price"
                                        onClick={() => this.setState({ openSelectPrice: true, selectItem: item })}><ListAltIcon /></IconButton></Tooltip>
                                    </InputAdornment>
                                    )
                                } : null }
                                onChange={this.handleRowUpdate(item,'unit_price')} />
                        </div>
                        <div>(-) <span style={{ fontWeight: '700', cursor: 'pointer', color: '#1976d2', textDecoration: 'underline' }} onClick={() => this.setState({ openSelectDiscount: _random(1,9999), selectItem: item })}>Discount</span>:</div>
                        <div style={{ fontWeight: '700' }}>Total After Discount:</div>
                    </div>
                </DraggableCell>
                <DraggableCell isDragging={snapshot.isDragging} width="10%">
                    <div style={{ width: '100%', textAlign: 'right' }}>
                        <div style={{ minHeight: '32px', lineHeight: '32px' }}>{this.renderCurrencyFormat(getItemTotalPrice(item))}</div>
                        <div>{this.renderCurrencyFormat(getItemDiscountAmount(item))}</div>
                        <div>{this.renderCurrencyFormat(getItemPreTaxTotal(item))}</div>
                    </div>
                </DraggableCell>
                { this.isIndividualTax() ? (
                <DraggableCell isDragging={snapshot.isDragging} width="10%">
                    <div style={{ width: '100%', textAlign: 'right' }}>
                        <div>
                            <Tooltip title={( item.no_tax && item.no_tax === 'yes' ? 'Add Tax?' : 'Remove Tax?' )} arrow="true"><GreyButton 
                                size="small" minWidth="24px" noIconMargin="yes" 
                                style={{ marginRight: '5px' }}
                                onClick={() => {
                                    const { invoice } = this.props;
                                    let newProducts = ( invoice && invoice.products_linked && isArrayExists( invoice.products_linked ) ? cloneCollections( invoice.products_linked ) : [] ),
                                        index = ( newProducts ? _findIndex( newProducts, { id: item.id }) : -1 );
                            
                                    if ( index >= 0 ) {
                                        newProducts[index]['no_tax'] = ( item.no_tax && item.no_tax === 'yes' ? 'no' : 'yes' );
                                    } // end - index
                            
                                    this.handleFormUpdate( newProducts, 'products_linked' );
                                }}><i className={"fa fa-"+( item.no_tax && item.no_tax === 'yes' ? 'plus' : 'minus' )}></i></GreyButton></Tooltip>
                            {this.renderCurrencyFormat(getItemTaxAmount(item,this.getSelectedRegion('tax_amount'),invoice))}
                        </div>
                    </div>
                </DraggableCell>
                ) : null }
                <DraggableCell isDragging={snapshot.isDragging} width="10%">
                    <div style={{ width: '100%', textAlign: 'right' }}>
                        <div>{this.renderCurrencyFormat(getItemNetPrice(item,this.isIndividualTax(),this.getSelectedRegion('tax_amount'),invoice))}</div>
                        { this.isIndividualTax() || ( invoice.products_linked && isArrayExists( invoice.products_linked ) && _size( invoice.products_linked ) < 2 ) ? <InfoButton style={{ marginTop: '5px' }} size="small" minWidth="none" noIconMargin="yes" onClick={(event) => {
                            const { onCalTriggered, invoice } = this.props;
                            event.preventDefault();
                            if ( onCalTriggered ) 
                                onCalTriggered(( !this.isIndividualTax() && invoice.products_linked && isArrayExists( invoice.products_linked ) && _size( invoice.products_linked ) < 2 ? 'grand_total' : item.id ));
                        }}><i className="fa fa-calculator"></i></InfoButton> : null }
                    </div>
                </DraggableCell>
            </DraggableRow>
        )}
        </Draggable>
        )
    }

    renderTableHead = () => {
        const { invoice } = this.props;
        return (
        <Head>
            <HeaderRow>
                <HeaderCell width="10%">Actions</HeaderCell>
                <HeaderCell width={( this.isIndividualTax() ? "30%" : "40%" )}>Item Name</HeaderCell>
                <HeaderCell width="10%">Quantity</HeaderCell>
                <HeaderCell width="20%">Unit Price</HeaderCell>
                <HeaderCell width="10%"><div style={{ width: '100%', textAlign: 'right' }}>Total</div></HeaderCell>
                { this.isIndividualTax() ? (
                <HeaderCell width="10%">
                    <div style={{ width: '100%', textAlign: 'right' }}>
                        {this.getSelectedRegion('tax_label')}
                        <span style={{ paddingLeft: '3px' }}>({getTaxRate(invoice,this.getSelectedRegion('tax_amount'))}%)</span>
                    </div>
                </HeaderCell>
                ) : null }
                <HeaderCell width="10%"><div style={{ width: '100%', textAlign: 'right' }}>Net Price</div></HeaderCell>
            </HeaderRow>
        </Head>
        )
    }

    render() {
        const { classes, invoice, selectables } = this.props;
        const { openDeleteModal, deleteModal, openSelectProduct, openSelectPrice, openSelectDiscount, selectItem } = this.state;
        return (
        <div className={classes.tablewrapper}>

            <ThemeProvider>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Table>
                        {this.renderTableHead()}
                        <Droppable droppableId="droppable">
                        {(provided, droppableSnapshot) => (
                            <Body ref={provided.innerRef} isDraggingOver={droppableSnapshot.isDraggingOver}>
                                { invoice.products_linked && isArrayExists( invoice.products_linked ) ? invoice.products_linked.map(this.renderTableRow(droppableSnapshot)) : null }
                                { provided.placeholder }
                            </Body>
                        )}
                        </Droppable>
                    </Table>
                </DragDropContext>
            </ThemeProvider>

            <div style={{ marginTop: '5px' }}>
                <InverseButton style={{ padding: '10px 25px' }} onClick={this.handleAddNewProduct}><i className="fa fa-plus"></i>Add New</InverseButton>
            </div>

            <ModalSelectProducts
                open={openSelectProduct}
                selectables={selectables}
                onClose={() => this.setState({ openSelectProduct: false, selectItem: false })}
                onProductSelect={this.handleProductSelect} />

            <ModalSelectPrice
                open={openSelectPrice}
                doc={( invoice || false )}
                item={( selectItem || false )}
                selectables={selectables}
                onClose={() => this.setState({ openSelectPrice: false, selectItem: false })}
                onPriceSelect={this.handlePriceSelect} />

            <ModalSelectDiscount
                open={openSelectDiscount}
                item={( selectItem || false )}
                onClose={() => this.setState({ openSelectDiscount: false, selectItem: false })}
                onDiscountSelect={this.handleDiscountSelect} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to delete this item ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleItemDelete} />

        </div>
        )
    }

}


export default compose(
    withStyles(useStyles),
    withRouter
)(InvoiceItems);