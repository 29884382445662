import { combineReducers } from "redux";

import { auth } from "./auth";
import { global } from "./global";
import { accounts } from "./accounts";
import { contacts } from "./contacts";
import { potentials } from "./potentials";
import { quotes } from "./quotes";
import { invoices } from "./invoices";
import { credit_notes } from "./credit_notes";
import { products } from "./products";
import { emails } from "./emails";
import { reports } from "./reports";
import { comments } from './comments';
import { updates } from './updates';
import { massaction } from './mass_action';
import { users } from "./users";
import { emailtemplates } from './email_templates';
import { media_library } from './media_library';
import { maintenance } from './maintenance';
import { misc } from "./misc";
import { test_queries } from "./test_queries";

export default combineReducers({ 
    auth, 
    global,
    accounts,
    contacts,
    potentials,
    quotes,
    invoices,
    credit_notes,
    products,
    emails,
    reports,
    comments,
    updates,
    massaction,
    users,
    emailtemplates,
    media_library,
    maintenance,
    test_queries,
    misc
});