export const reportTemplateSchema = [
    { 
        id: 'id', 
        label: 'ID',
        required: ['update','delete'], 
        default: '', 
        type: 'string', 
        validation: 'string_id', 
        skip: ['add'],
        sync: false 
    },
    {   
        id: 'name',
        label: 'Name',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'text',
        skip: false,
        sync: true
    },
    {   
        id: 'desc',
        label: 'Description',
        required: false,
        default: '',
        type: 'string',
        field: 'text',
        skip: false,
        sync: true
    },
    {   
        id: 'category_id',
        label: 'Category',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'category_label',
        label: 'Category Name',
        required: false,
        default: '',
        type: 'string',
        skip: false,
        sync: true
    },
    {   
        id: 'primary_module',
        label: 'Primary Module',
        required: ['add','update'],
        default: '',
        type: 'string',
        field: 'select',
        disabled: ['update'],
        skip: false,
        sync: true
    },
    {   
        id: 'related_modules',
        label: 'Related Module',
        required: false,
        default: [],
        type: 'array',
        field: 'select2',
        skip: false,
        sync: true
    },
    {   
        id: 'modules_ids',
        label: 'Modules IDs',
        required: false,
        default: [],
        type: 'system_data',
        skip: false,
        sync: true
    },
    {   
        id: 'columns',
        label: 'Columns',
        required: ['update'],
        default: [],
        type: 'array',
        field: 'multiselect',
        skip: false,
        sync: false
    },
    {   
        id: 'sort_by',
        label: 'Sort By',
        required: false,
        default: [],
        type: 'array_obj',
        field: 'select2',
        skip: false,
        sync: false
    },
    {   
        id: 'filterby_all_conditions',
        label: 'All Conditions (all conditions must be met)',
        required: false,
        default: [],
        type: 'array_obj',
        field: 'select2',
        skip: false,
        sync: false
    },
    {   
        id: 'filterby_any_conditions',
        label: 'Any Conditions (any conditions must be met)',
        required: false,
        default: [],
        type: 'array_obj',
        field: 'select2',
        skip: false,
        sync: false
    },
    {   
        id: 'columns_calculation',
        label: 'Do Calculation on Numerical Columns',
        required: false,
        default: [],
        type: 'array_obj',
        field: 'select2',
        skip: false,
        sync: false
    },
    {   
        id: 'report_owners',
        label: 'Owner',
        required: ['update'],
        default: [],
        type: 'array_ids',
        field: 'multiselect',
        skip: false,
        sync: true
    },
    {   
        id: 'report_access',
        label: 'Report Access',
        required: ['update'],
        default: 'default',
        type: 'string',
        field: 'select',
        skip: false,
        sync: true
    },
    {   
        id: 'report_restricted',
        label: 'Restricted',
        required: ['update'],
        default: 'yes',
        type: 'string',
        field: 'radio',
        skip: false,
        sync: true
    },

    {   
        id: 'created_on',
        label: 'Created On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: ['update'],
        sync: true
    },
    {   
        id: 'modified_on',
        label: 'Modified On',
        required: false,
        default: 0,
        type: 'system_date',
        sync: true
    },
    {   
        id: 'generated_on',
        label: 'Report Generated On',
        required: false,
        default: 0,
        type: 'system_date',
        skip: ['add','update'],
        sync: true
    },
    {   
        id: 'generated_by',
        label: 'Report Generated By',
        required: false,
        default: '',
        type: 'system_data',
        skip: ['add','update'],
        sync: true
    }
];