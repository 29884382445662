/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import _isEmpty from 'lodash/isEmpty';

import ModalView from '../ModalView';
import FormInput from '../../components/FormInput';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';

import { addComments } from '../../actions/comments';
import { resetRedux } from '../../actions/misc';

class MassComment extends React.Component {

    state = {
        comment: '',
        randNum: false
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { commmentAdded, randNum } = this.props;
        if ( commmentAdded && randNum && commmentAdded === randNum && randNum !== this.state.randNum ) {
            this.setState({ randNum });
            this.handleComplete();
        } // end - commmentAdded
    }

    handleComplete = () => {
        const { onCompleted } = this.props;
        if ( onCompleted ) {
            this.setState({ comment: '' }); // reset comment box
            onCompleted();
            this.props.dispatch(resetRedux('comment'));
        } // end - onCompleted
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose ) {
            this.setState({ comment: '' }); // reset comment box
            onClose();
        } // end - onClose
    }

    handleAddNew = () => {
        const { comment } = this.state;
        const { refer_type, selected } = this.props;

        if ( comment && !_isEmpty( comment ) ) {
            // trigger add comment dispatch
            this.props.dispatch(addComments({
                refer_type,
                selected,
                text: comment
            }));
        } else {
            triggerErrorAlert("Please enter something in the comment box");
        } // end - comment
    }

    renderAddNewForm = () => {
        const { comment } = this.state;
        return (
        <div>
            <FormInput multiline={true} rows={3} label="Comment" name="comment" value={( comment || '' )} onChange={(newValue) => this.setState({ comment: newValue })} />
        </div>
        )
    }

    render() {
        const { open } = this.props;
        return (
        <div>

            <ModalView 
                open={open}
                title="Add New Comment"
                onClose={this.handleClose}
                doAction={this.handleAddNew}
                contents={this.renderAddNewForm()} />

        </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        commmentAdded: state.comments && state.comments.commented || null,
        randNum: state.comments && state.comments.rand || null,
    }
}

export default compose(
    connect(mapStateToProps)
)(MassComment);