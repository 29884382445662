/* eslint-disable */
import _random from 'lodash/random';

import {
    M_GET_BENEFICIARY_DATA,
    M_BENEFICIARY_DATA_UPDATED
} from '../types';

import { 
	toggleModalDeleting, toggleModalProcessing, 
	toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
// import { appChangesReset } from '../misc';

import { fb_getBeneficiaryData, fb_updateBeneficiaryData } from './fb';

export const getBeneficiaryData = () => {
	return dispatch => {

		fb_getBeneficiaryData(list => {
			dispatch({ 
                type: M_GET_BENEFICIARY_DATA, 
                payload: { list } 
            });
		});

	}
}

export const updateBeneficiaryData = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_updateBeneficiaryData(formData)
		.then( results => {
			dispatch({ type: M_BENEFICIARY_DATA_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Invoice Data Updated");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update Invoice Data' ) );
			triggerErrorAlert(errMsg);
		});

	}
}