/* eslint-disable */
import * as firebase from 'firebase/app';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

/* helper end */

export const fb_getBeneficiaryData = (callback) => {

    firebase.database()
    .ref("maintenance/beneficiary_data")
    .on("value", snapshot => {

        var list = [];

        if ( snapshot.exists() && snapshot.hasChildren() ) {
            snapshot.forEach( childSnapshot => {
                var val = childSnapshot.val(),
                    item = {
                        id: childSnapshot.key,
                        label: val.label || '',
                        bank_name: val.bank_name || '',
                        bank_account_no: val.bank_account_no || '',
                        bank_swift_code: val.bank_swift_code || '',
                        invoice_name: val.invoice_name || '',
                        signature_image: val.signature_image || '',
                        custom_html: val.custom_html || ''
                    };

                list.push(item);
            });
        } // end - snapshpt\
        
        callback(list);

    });
    
}

export const fb_updateBeneficiaryData = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'mBeneficiaryData', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}