import _random from 'lodash/random';

import { 
    GET_LATEST_UPDATES 
} from '../actions/types';

const initialState = {
    latest: null,
    rand: false
};

export const updates = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_LATEST_UPDATES:
            return {
                ...state,
                latest: ( payload.list || [] ),
                rand: _random(1,9999),
            };
        default:
            return state;
    }
}