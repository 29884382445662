import _random from 'lodash/random';

import { 
    DO_MASS_ACTION,
    RESET_MASS_ACTION
} from '../actions/types';

const initialState = {
    action_done: false,
    rand: null
};

export const massaction = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case DO_MASS_ACTION:
            var randNum = _random(1,9999);
            return {
                ...state,
                action_done: randNum,
                rand: randNum
            };
        case RESET_MASS_ACTION:
            return {
                ...state,
                action_done: false,
                rand: null
            };
        default:
            return state;
    }
}