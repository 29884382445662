/* eslint-disable */
import * as firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';
import { getSnapshotDataBySchema } from '../../helpers/schemas';

import { emailTemplateSchema } from '../../schemas/email_template';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getEmailTemplatesLive = (props,callback) => {
    return new Promise((resolve,reject) => {
        
        firebase.database().ref("email_templates").on("value", snapshot => {
            const list = [];
            if ( snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach(childSnapshot => {
                    const item = getSnapshotDataBySchema( emailTemplateSchema, childSnapshot );
                    list.push(item);
                });
            } // end - snapshot
            callback(list);
        });

    })
}

export const fb_getEmailTemplates = (props) => {
    return new Promise((resolve,reject) => {
        
        firebase.database().ref("email_templates").once("value")
        .then(snapshot => {
            const list = [];
            if ( snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach(childSnapshot => {
                    const item = getSnapshotDataBySchema( emailTemplateSchema, childSnapshot );
                    list.push(item);
                });
            } // end - snapshot
            resolve(list);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getEmailTemplate = (email_id) => {
    return new Promise((resolve,reject) => {
        
        const promises = [];
        promises.push( firebase.database().ref("email_templates/"+email_id).once("value") );
        promises.push( firebase.database().ref("email_templates_content/"+email_id).once("value") );

        Promise.all( promises )
        .then( results => {

            const template = ( results && results[0] ? getSnapshotDataBySchema( emailTemplateSchema, results[0] ) : {});
            
            // make sure template is not empty
            if ( template && !_isEmpty( template ) ) {
                const contentVal = ( results && results[1] && results[1].exists() ? results[1].val() : false );
                // add in content
                template.contents_html = ( contentVal.html && !_isEmpty( contentVal.html ) ? contentVal.html : '' );
                template.contents_css = ( contentVal.css && !_isEmpty( contentVal.css ) ? contentVal.css : '' );
                template.contents_js = ( contentVal.js && !_isEmpty( contentVal.js ) ? contentVal.js : '' );

                resolve(template);
            } else {
                // otherwise - return empty object
                resolve({});
            } // end - template

        })
        .catch(error => {
            reject(error);
        });


    })
}

export const fb_getEmailTemplateWithShortcode = (formData) => {
    return new Promise((resolve,reject) => {
        callFunctionsAPI({ url: 'email', action: 'insert_shortcode_data', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
    })
}


export const fb_addEmailTemplate = (formData) => {
    return new Promise((resolve,reject) => {
        callFunctionsAPI({ url: 'emailTemplates', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
    })
}

export const fb_updateEmailTemplate = (formData) => {
    return new Promise((resolve,reject) => {
        callFunctionsAPI({ url: 'emailTemplates', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
    })
}

export const fb_deleteEmailTemplate = (id) => {
    return new Promise((resolve,reject) => {
        callFunctionsAPI({ url: 'emailTemplates', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
    })
}