/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import _isEmpty from 'lodash/isEmpty';
import _toLower from 'lodash/toLower';
import _replace from 'lodash/replace';

import theme from '../../theme';

class FormInput extends React.Component {

    handleChange = (event) => {
        const { name } = this.props;
        if ( this.props.onChange ) {
            this.props.onChange(event.target.value,name);
        }
    }

    renderLabel = () => {
        const { label } = this.props;
        var newLabel = false;
        if ( label && !_isEmpty( label ) && ( 
            ( _toLower( label ).indexOf("(required)") >= 0 ) || 
            ( _toLower( label ).indexOf("*") >= 0 )
         ) ) {
            newLabel = _replace( label, '(Required)', '' );
            newLabel = _replace( newLabel, '(required)', '' );
            newLabel = _replace( newLabel, '*', '' );
        } // end - label
        return ( newLabel ? <span>{ newLabel || '' }<span className="required">*</span></span> : ( label || '' ) );
    }

    render() {
        const { type, label, labelProps, InputProps, value, disabled, multiline, rows, rowsMax } = this.props;
        return (
        <FormControl fullWidth={true}>
            <TextField 
                label={this.renderLabel()} 
                type={( type || 'text' )} 
                disabled={( disabled || false )}
                multiline={( multiline || null )}
                rows={( rows || null )}
                rowsMax={( rowsMax || null )}
                fullWidth={true} 
                InputLabelProps={( labelProps ? labelProps : {
                    shrink: true,
                    style: { fontSize: "16px", fontWeight: "700", textTransform: "uppercase" }
                })}
                InputProps={( InputProps ? InputProps : null )}
                margin="normal" 
                variant={( disabled ? "filled" : "outlined" )} 
                value={( value || '' )} 
                onChange={this.handleChange} />
        </FormControl>
        )
    }

}

export default FormInput