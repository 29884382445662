import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _round from 'lodash/round';
import _floor from 'lodash/floor';

/* eslint-disable */

import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';

import { isArrayExists, isNumeric } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { formatMoney } from '../../helpers/number';

import { InverseButton } from '../../styles/button';

import { getAccountsOptions } from '../../actions/accounts';

const useStyles = theme => ({
    gridwrapper: {
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            backgroundColor: '#fff', 
            paddingLeft: '5px'
        }
    },
});

class TaxRate extends React.Component {

    state = {
        open: false,
        tax_rate: 0
    };

    handleUpdate = () => {
        const { onUpdate } = this.props;
        const { tax_rate } = this.state;

        // check if the tax rate is valid
        if ( !( tax_rate && isNumeric( tax_rate ) ) ) {
            triggerErrorAlert('Invalid tax rate');
            return;
        }

        if ( onUpdate ) {
            onUpdate( tax_rate );
            this.handleClose();
        }
        
    }


    handleClose = () => {
        const { onClose } = this.props;

        // reset everything
        this.setState({
            open: false,
            tax_rate: 0
        });

        if ( onClose )
            onClose();
    }

    getSelectedRegion = (type) => {
        const { item, regions, authData } = this.props;
        var selected = ( regions && isArrayExists( regions ) ? _find( regions, { id: ( item.company_details && !_isEmpty( item.company_details ) ? item.company_details : ( authData && authData.region || '' ) ) } ) : false );
        if ( type && !_isEmpty( type ) ) {
            return ( selected && selected[type] || false );
        } else {
            return ( selected || false );
        } // end - type
    }

    getTaxRate = () => {
        const { item } = this.props;
        let amt = this.getSelectedRegion('tax_amount');
        if ( item && item.custom_tax_rate && !_isEmpty( item.custom_tax_rate ) && isNumeric( item.custom_tax_rate ) ) {
            amt = item.custom_tax_rate;
        }
        return amt;
    }

    handleOpen = () => {
        this.setState({
            open: true,
            tax_rate: this.getTaxRate()
        });
    }

    renderModal = () => {
        const { classes } = this.props;
        const { tax_rate } = this.state;
        return (
        <div className={classes.gridwrapper}>
            <Grid container spacing={1} align="center">
                <FormInput 
                    type="number" 
                    label="New Tax Rate *" 
                    name="tax_rate" 
                    value={( tax_rate || 0 )} 
                    onChange={(newValue) => {
                        this.setState({ tax_rate: newValue });
                    }} />
            </Grid>
        </div>   
        )
    }

    render() {
        const { item } = this.props;
        const { open, tax_rate } = this.state;
        return (
        <>
            <Grid container spacing={1} alignItems='center'>
                <Grid item xs={10}>
                    <FormInput 
                        type="number" 
                        label="Tax Rate" 
                        name="tax_rate" 
                        disabled={true}
                        value={this.getTaxRate()} 
                        onChange={(newValue) => {
                            
                        }} />
                </Grid>
                <Grid item xs={2}>
                    <InverseButton 
                        style={{ padding: '10px 15px'}}
                        onClick={this.handleOpen}>
                        <i class="fa fa-edit"></i> Edit
                    </InverseButton>
                </Grid>
            </Grid>
        
            <ModalView 
                open={open}
                title="Change Tax Rate"
                actionLabel="Apply"
                maxWidth="xs"
                cancelLabel="Close"
                doAction={this.handleUpdate}
                onClose={this.handleClose}
                contents={ open ? this.renderModal() : null } />
        
        </>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
)(TaxRate);