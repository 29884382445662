/* eslint-disable */
import * as firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema } from '../../helpers/schemas';

import { emailSchema } from '../../schemas/email';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getEmailsBy = (type,id) => {
    return new Promise((resolve,reject) => {

        if ( type && !_isEmpty( type ) && id && !_isEmpty( id ) ) {

            firebase.database().ref("usage/"+type+"/"+id+"/emails").once("value")
            .then( snapshot => {
                var promises = [];
                if ( snapshot.exists() && snapshot.hasChildren() ) {
                    snapshot.forEach( childSnapshot => {
                        promises.push( firebase.database().ref("emails/"+childSnapshot.key).once("value") );
                    });
                } // end - snapshpt

                return ( isArrayExists( promises ) ? Promise.all( promises ) : false );
            })
            .then(results => {

                let list = [];

                if ( results && isArrayExists( results ) ) {
                    _forEach( results, result => {
                        if ( result && result.exists() ) {
                            let item = getSnapshotDataBySchema( emailSchema, result );
                            list.push(item);
                        } // end - result
                    });
                } // end - results

                resolve(list);

            })
            .catch(error => {
                reject(error);
            });

        } else {
            resolve([]);
        } // end - type

    })
}

export const fb_getEmail = (email_id) => {
    return new Promise((resolve,reject) => {
        
        const promises = [];
        promises.push( firebase.firestore().collection("emails").doc(email_id).get() );
        promises.push( firebase.database().ref("emails_content/"+email_id).once("value") );

        Promise.all( promises )
        .then( results => {

            const email = ( results && results[0] ? getDocDataBySchema( emailSchema, results[0] ) : {});
            
            // make sure email is not empty
            if ( email && !_isEmpty( email ) ) {
                const contentVal = ( results && results[1] && results[1].exists() ? results[1].val() : false );
                // add in content
                email.contents_html = ( contentVal.html && !_isEmpty( contentVal.html ) ? contentVal.html : '' );
                email.contents_css = ( contentVal.css && !_isEmpty( contentVal.css ) ? contentVal.css : '' );
                email.contents_js = ( contentVal.js && !_isEmpty( contentVal.js ) ? contentVal.js : '' );

                resolve(email);
            } else {
                // otherwise - return empty object
                resolve({});
            } // end - email

        })
        .catch(error => {
            reject(error);
        });


    })
}

export const fb_addEmail = (formData) => {
    return new Promise((resolve,reject) => {
        callFunctionsAPI({ url: 'email', action: 'add', formData })
        .then(data => {
            // delay for 5 second - so that trigger function can be completed
            setTimeout(() => {
                resolve(data);
            }, 5000);
        })
        .catch(error => {
            reject(error);
        });
    })
}

export const fb_updateEmail = (formData) => {
    return new Promise((resolve,reject) => {
        callFunctionsAPI({ url: 'email', action: 'update', formData })
        .then(data => {
            // delay for 5 second - so that trigger function can be completed
            setTimeout(() => {
                resolve(data);
            }, 5000);
        })
        .catch(error => {
            reject(error);
        });
    })
}

export const fb_deleteEmail = (id) => {
    return new Promise((resolve,reject) => {
        callFunctionsAPI({ url: 'email', action: 'delete', formData: { id } })
        .then(data => {
            // delay for 5 second - so that trigger function can be completed
            setTimeout(() => {
                resolve(data);
            }, 5000);
        })
        .catch(error => {
            reject(error);
        });
    })
}