import React from 'react';
import styled from "styled-components";
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';
import indigo from '@material-ui/core/colors/indigo';

export const Tag = styled.div`
    display: inline-block;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 6px;
`;

export const SuccessTag = styled(Tag)`
    background: ${green['500']};
    color: ${grey['50']};
`;

export const InfoTag = styled(Tag)`
    background: ${blue['500']};
    color: ${grey['50']};
`;

export const ErrorTag = styled(Tag)`
    background: ${red['500']};
    color: ${grey['50']};
`;

export const AmberTag = styled(Tag)`
    background: ${amber['700']};
    color: ${grey['50']};
`;

export const GreyTag = styled(Tag)`
    background: ${grey['300']};
    color: ${grey['500']};
`;

export const InverseTag = styled(Tag)`
    background: ${grey['900']};
    color: ${grey['50']};
`;

export const IndigoTag = styled(Tag)`
    background: ${indigo['700']};
    color: ${grey['50']};
`;