/* eslint-disable */
import * as firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _snakeCase from 'lodash/snakeCase';
import _find from 'lodash/find';

import { doesUserHaveAccess } from '../../helpers/auth';
import { callFunctionsAPI, checkIfUserAccessLimited, checkAuthDataIfAccessLimited } from '../../helpers/action';
import { isArrayExists, validateEmail, isObjectExists } from '../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema } from '../../helpers/schemas';
import { getItemsFromFirestore, getTotalCountBySchema } from '../../helpers/firestore';
import { isValidUser } from '../../helpers/users';

import { accountSchema } from '../../schemas/account';
import { commentSchema } from '../../schemas/comment';
import { logSchema } from '../../schemas/log';

import { SET_READ_LIMITS, FIRST_BATCH_LOAD_LIMITS, REST_BATCH_LOAD_LIMITS } from '../../constants';

/* helpers start */
// retrieve accounts based on usage
const getUsageBy = (type,id) => {
    return new Promise((resolve,reject) => {

        switch( type ) {
            case 'products':
                var product = false;
                firebase.database().ref("products/"+id).once("value")
                .then( snapshot => {
                    product = ( snapshot.exists() ? snapshot.val() : false );
                    // get all products from the same course_id
                    // only retrieve if it's general product
                    return ( product && product.course_id && !_isEmpty( product.course_id ) && !( product.schedule_id && !_isEmpty( product.schedule_id ) ) ? firebase.database().ref("products").orderByChild("course_id").equalTo(product.course_id).once("value") : false );
                })
                .then( snapshot => {
                    var promises = [];

                    // if list of products exists
                    if ( snapshot && snapshot.exists() && snapshot.hasChildren() ) {
                        snapshot.forEach( childSnapshot => {
                            var selectedPro = childSnapshot.val();
                            // based on the products (make sure it's from the same region)
                            if ( product.region_id && selectedPro.region_id && product.region_id === selectedPro.region_id ) {
                                promises.push( firebase.database().ref("usage/products/"+childSnapshot.key+"/accounts").once("value") );
                            }
                        });
                    } else {
                        // otherwise - just retrieve it based on the original ID
                        promises.push( firebase.database().ref("usage/products/"+id+"/accounts").once("value") );
                    } // end - snapshpt

                    return ( isArrayExists( promises ) ? Promise.all( promises ) : false );
                })
                .then( results => {
                    var promises = [],
                        ids = [];
                    if ( results && isArrayExists( results ) ) {
                        _forEach( results, result => {
                            if ( result.exists() && result.hasChildren() ) {
                                result.forEach( childSnapshot => {
                                    // only retrieve it if haven't add to promises
                                    if ( !_find( ids, { id: childSnapshot.key } ) ) {
                                        promises.push( firebase.database().ref("accounts/"+childSnapshot.key).once("value") );
                                        ids.push({ id: childSnapshot.key });
                                    } // end - childSnapshot.key
                                });
                            } // end - result
                        });
                    } // end - results
        
                    return ( isArrayExists( promises ) ? Promise.all( promises ) : false );
                })
                .then( results => {
                    resolve(results);
                })
                .catch(error => {
                    reject(error);
                });
                break;
            default:
                firebase.database().ref("usage/"+type+"/"+id+"/accounts").once("value")
                .then( snapshot => {
                    var promises = [];
                    if ( snapshot.exists() && snapshot.hasChildren() ) {
                        snapshot.forEach( childSnapshot => {
                            promises.push( firebase.database().ref("accounts/"+childSnapshot.key).once("value") );
                        });
                    } // end - snapshpt
        
                    return ( isArrayExists( promises ) ? Promise.all( promises ) : false );
                })
                .then( results => {
                    resolve(results);
                })
                .catch(error => {
                    reject(error);
                });
                break;
        }

    })
}

// do each account update based on given data from mass edit
const doSingleAccountUpdate = (account_id,formData) => {
    return new Promise((resolve,reject) => {

        firebase.firestore().collection("accounts").doc(account_id).get()
        .then( doc => {
            var updateData = {}
            if ( doc.exists ) {
                // get all current data
                updateData = getDocDataBySchema( accountSchema, doc );
                // add new data into current data
                if ( formData && isObjectExists( formData ) ) {
                    _forEach( formData, (value,key) => {
                        updateData[key] = value;
                    });
                } // end - formData
            } // end 
            // do update only if data is available
            return ( updateData && !_isEmpty( updateData ) ? callFunctionsAPI({ url: 'account', action: 'update', formData: updateData }) : false );
        })
        .then( results => {
            resolve(true);
        })
        .catch( error => {
            reject(error);
        });

    })
}

// get list from firebase based props
const getFirestoreList = (props) => {
    return new Promise((resolve,reject) => {

        var promises = [];
        promises.push( getItemsFromFirestore( "accounts", ( props && isObjectExists( props ) ? props : null ) ) );

        // if no keywords filter
        if ( !( props && props.searchterms && !_isEmpty( props.searchterms ) ) ) {
            promises.push( getTotalCountBySchema('accounts') );
        } // end - props.searchterms

        Promise.all( promises )
        .then(results => {
            resolve({ 
                list: ( results && results[0] && results[0].list && isArrayExists( results[0].list ) ? results[0].list : [] ), 
                firstDoc: ( results && results[0] && results[0].firstDoc ? results[0].firstDoc : false ),
                lastDoc: ( results && results[0] && results[0].lastDoc ? results[0].lastDoc : false ), 
                total: ( results && results[1] ? results[1] : 0 )
            });
        })
        .catch(error => {
            // only use it when needed
            // console.log(error);
            reject(error);
        });

    })
} 

// get list based on assigned_to
const getAssignedToList = (user) => {
    return new Promise((resolve,reject) => {

        let ref = firebase.database().ref("accounts");
        if ( user && user.id && user.email && validateEmail(user.email) ) {
            ref = ref.orderByChild("assigned_to/"+user.id+"/id").equalTo(user.email);
        }

        ref.once("value")
        .then(snapshot => {

            let list = [];

            if ( snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach( childSnapshot => {
                    let item = getSnapshotDataBySchema( accountSchema, childSnapshot );
                    list.push(item);
                });
            } // end - snapshot

            resolve({ list });

        })
        .catch(error => {
            reject(error);
        });
    })
}

/* helper end */

export const fb_getAccountsFirstBatch = (props) => {
    return new Promise((resolve,reject) => {
        // const { perPage } = props;
        // var accessLimited = false;

        // checkIfUserAccessLimited()
        // .then(user => {
        //     let ref = firebase.database().ref("accounts");

        //     // for access limited user only load based on assigned_to value
        //     if ( user && user.id && user.email && validateEmail(user.email) ) {
        //         accessLimited = true; // set to true - so that this will load everything
        //         ref = ref.orderByChild("assigned_to/"+user.id+"/id").equalTo(user.email);
        //     } else {
        //         // if not access limited, then limit to only perpage
        //         ref = ref.orderByChild("modified_on/_seconds").limitToLast(( perPage || FIRST_BATCH_LOAD_LIMITS ));
        //     } // end - user

        //     return ref.once("value");
        // })
        // .then(snapshot => {

        //     let list = [];

        //     if ( snapshot.exists() && snapshot.hasChildren() ) {
        //         snapshot.forEach( childSnapshot => {
        //             let item = getSnapshotDataBySchema( accountSchema, childSnapshot );
        //             list.push(item);
        //         });
        //     } // end - snapshot

        //     resolve({ list, accessLimited });

        // })
        // .catch(error => {
        //     reject(error);
        // });

    })
}

export const fb_getAccountsForTheRest = (endAt) => {
    return new Promise((resolve,reject) => {


        // let ref = firebase.database().ref("accounts");
        
        // // set limits to only 30 - for dev only
        // if ( SET_READ_LIMITS ) {
        //     ref = ref.orderByChild("modified_on/_seconds").endAt(endAt).limitToLast(REST_BATCH_LOAD_LIMITS);
        // } else {
        //     ref = ref.orderByChild("modified_on/_seconds").endAt(endAt);
        // } // end - SET_READ_LIMITS

        // ref.once("value")
        // .then(snapshot => {

        //     let list = [];

        //     if ( snapshot.exists() && snapshot.hasChildren() ) {
        //         snapshot.forEach( childSnapshot => {
        //             let item = getSnapshotDataBySchema( accountSchema, childSnapshot );
        //             list.push(item);
        //         });
        //     } // end - snapshot

        //     resolve(list);

        // })
        // .catch(error => {
        //     reject(error);
        // });

    })
}

export const fb_getAccounts = (props) => {
    return new Promise((resolve,reject) => {

        checkIfUserAccessLimited()
        .then(user => {
            return ( isValidUser(user) ? getAssignedToList(user) : getFirestoreList(props) );
        })
        .then(payload => {
            resolve(payload);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getAccount = (account_id) => {
    return new Promise((resolve,reject) => {

        let promises = [];
        promises.push( firebase.firestore().collection("accounts").doc(account_id).get() );
        promises.push( firebase.database().ref("comments").orderByChild('refer_id').equalTo(account_id).once("value") );
        promises.push( firebase.database().ref("logs").orderByChild('type_id').equalTo(account_id).once("value") );
        promises.push( checkIfUserAccessLimited() );
        
        Promise.all( promises )
        .then(results => {
            let item = {};

            if ( results && results[0] ) {
                item = getDocDataBySchema( accountSchema, results[0] );
            } // end - results[0]

            // add comments
            item['comments'] = [];
            if ( results && results[1] && results[1].exists() && results[1].hasChildren() ) {
                results[1].forEach( childSnapshot => {
                    let comment = getSnapshotDataBySchema( commentSchema , childSnapshot );
                    item['comments'].push( comment );
                });
            } // end - results[1]

            // add updates
            item['updates'] = [];
            if ( results && results[2] && results[2].exists() && results[2].hasChildren() ) {
                results[2].forEach( childSnapshot => {
                    let update = getSnapshotDataBySchema( logSchema , childSnapshot );
                    item['updates'].push( update );
                });
            } // end - results[2]

            // if is limited user
            if ( results && results[3] && !_isEmpty( results[3] ) ) {
                if ( doesUserHaveAccess( results[3], item ) ) {
                    resolve(item);
                } else {
                    resolve({});
                }
            } else {    
                resolve(item);
            } // end - results
        })
        .catch(error => {
            reject(error);
        });
    })
}

export const fb_getAccountsBy = (type,id) => {
    return new Promise((resolve,reject) => {
        
        getUsageBy(type,id)
        .then(results => {

            let list = [];

            if ( results && isArrayExists( results ) ) {
                _forEach( results, result => {
                    if ( result && result.exists() ) {
                        let item = getSnapshotDataBySchema( accountSchema, result );
                        list.push(item);
                    } // end - result
                });
            } // end - results

            resolve(list);

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getAccountsOptions = (callback,limitByUser) => {

    var ref = firebase.database().ref("maintenance/options/accounts");

    if ( limitByUser && checkAuthDataIfAccessLimited( limitByUser ) && limitByUser.email && validateEmail( limitByUser.email ) ) {
        ref = ref.orderByChild("assigned_to/"+_snakeCase(limitByUser.email)+"/id").equalTo(limitByUser.email);
    } // end - limitByUser.email

    if ( SET_READ_LIMITS ) {
        ref = ref.limitToLast(25);
    }

    ref.on("value",snapshot => {

        var options = [];

        if ( snapshot.exists() && snapshot.hasChildren() ) {
            snapshot.forEach(childSnapshot => {
                var option = childSnapshot.val();
                option['id'] = childSnapshot.key;
                options.push(option);
            });
        } // end - snapshot
 
        callback(options);

    });

}

export const fb_addAccount = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'account', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateAccount = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'account', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteAccount = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'account', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_massEditAccount = (selected,formData) => {
    return new Promise((resolve,reject) => {

        var promises = [];

        _forEach( selected, account => {
            if ( account.id && !_isEmpty( account.id ) )
                promises.push( doSingleAccountUpdate( account.id, formData ) );
        });

        Promise.all( promises )
        .then( results => {
            resolve('done');
        })
        .catch( error => {
            reject(error);
        });

    })
}

export const fb_mergeAccounts = (list,newData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'accountsMerge', formData: { list, newData } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}