import _random from 'lodash/random';

import { 
    GET_COMMENTS,
    GET_COMMENT,
    ADD_COMMENT,
    ADD_COMMENTS,
    RESET_COMMENT
} from '../actions/types';

const initialState = {
    comment: null,
    updated: null,
    rand: false
};

export const comments = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_COMMENT:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum,
            };
        case ADD_COMMENTS:
            var randNum = _random(1,9999);
            return {
                ...state,
                commented: randNum,
                rand: randNum,
            };
        case RESET_COMMENT:
            return {
                ...state,
                comment: null,
                updated: null,
                commented: null,
                rand: false
            };
        default:
            return state;
    }
}