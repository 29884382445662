/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _random from 'lodash/random';
import _remove from 'lodash/remove';
import _isEqual from 'lodash/isEqual';

import EditForm from '../../pages/AccountPage/form';

import ModalView from '../ModalView';
import DotsLoader from '../DotsLoader';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { formatMoney } from '../../helpers/number';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { FormBox } from '../../styles/form';

import { accountSchema } from '../../schemas/account';

import { massEditAccount } from '../../actions/accounts';

const useStyles = theme => ({
    closeBtn: {
        position: 'absolute',
        top: '0',
        right: '0',
        padding: '5px 8px',
        borderRadius: '0px'
    }
});

class ModalMassEditAccounts extends React.Component {

    state = {
        account: {},
        mailingListMessages: []
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { open } = this.props;

        if ( !prevProps.open && open ) {
            this.setState({ account: {}, mailingListMessages: [] });
        } // end - open
            
    }

    handleMassUpdate = () => {
        const { selected, query } = this.props;
        const { account } = this.state;
        var error = false,
            formData = ( account && isObjectExists( account ) ? cloneCollections( account ) : {} );

        if ( _isEmpty( formData ) )
            error = 'Please make at least one changes';

        if ( !( selected && isArrayExists( selected ) ) )
            error = 'Please select at least one account.';

        // add in mailing_lists_trigger if needed
        if ( account && account.mailing_lists && isArrayExists( account.mailing_lists ) ) {
            // set as new array
            formData.mailing_lists_trigger = [];
            _forEach( account.mailing_lists, (list) => {
                if ( list && list.id && !_isEmpty( list.id ) ) {
                    formData.mailing_lists_trigger.push({
                        id: list.id, 
                        name: ( list.name || '' ),
                        type: 'add'
                    });
                } // end - list
            });
        } // end - account

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(massEditAccount(selected,formData,query));
        } // end - error
    }

    handleFormUpdate = (newValue) => {
        const { account, mailingListMessages } = this.state;
        const newMessages = [];
        if ( newValue && newValue.mailing_lists && isArrayExists( newValue.mailing_lists ) ) {
            _forEach( newValue.mailing_lists, list => {
                newMessages.push({ 
                    id: list.id, 
                    name: ( list.name || ''),
                    message: 'WARNING: All the contacts under the selected account(s) will be transferred to this mailing list.',
                    type: 'add'
                })
            });
        } // end - newValue.mailing_lists

        // update state
        this.setState({ account: newValue, mailingListMessages: newMessages })
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose ) {
            this.setState({ account: {} });
            onClose();
        }
    }

    reorganizeData = () => {

    }

    renderForm = () => {
        const { authData,  coursePortfolioList, accountsTier2List } = this.props;
        const { account, mailingListMessages } = this.state;
        return <EditForm
            massEditMode={true}
            authData={( authData || false )}
            account={( account || false )}
            users={null} 
            course_portfolio={( coursePortfolioList || [] )}
            accounts_tier_2={( accountsTier2List || [] )}
            disableSystemDate={true}
            mailingListMessages={( mailingListMessages || [] )}
            onFormUpdate={this.handleFormUpdate} />
    }


    renderModal = () => {
        const { selected, classes } = this.props;
        return (
        <div>
            {this.renderForm()}     
            <InverseButton className={classes.closeBtn} noIconMargin="yes" size="small" minWidth="0px" onClick={this.handleClose}><i className="fa fa-times"></i></InverseButton>       
        </div>
        )
    }

    render() {
        const { open } = this.props;
        return <ModalView 
            open={open}
            title="Edit Selected Account(s)"
            actionLabel="Update"
            maxWidth="lg"
            cancelLabel="Close"
            disableBackdrop={true}
            onClose={this.handleClose}
            doAction={this.handleMassUpdate}
            contents={ open ? this.renderModal() : null } />
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        coursePortfolioList: state.maintenance && state.maintenance.course_portfolio || null,
        accountsTier2List: state.maintenance && state.maintenance.accounts_tier_2 || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles)
)(ModalMassEditAccounts);