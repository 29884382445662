/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _isEqual from 'lodash/isEqual';

import ModalView from '../ModalView';
import Table from '../Table';
import Pagination from '../Pagination';
import TableBar from '../TableBar';
import DotsLoader from '../DotsLoader';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { formatMoney } from '../../helpers/number';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';

import { getAccountsOptions } from '../../actions/accounts';

class ModalSelectAccounts extends React.Component {

    state = {
        searchterms: '',
        filterBy: 'all',
        sortBy: 'name-asc',
        perPage: 20,
        page: 1,
        randNum: false
    };

    componentDidMount() {
        this.dispatchOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( !_isEqual( prevProps.accountsOptions, this.props.accountsOptions ) || 
            !_isEqual( prevProps.authData, this.props.authData ) || 
            !_isEqual( prevProps.randNum, this.props.randNum ) ) {
            this.dispatchOptions();
        }
    }

    dispatchOptions = () => {
        const { accountsOptions, authData, randNum } = this.props;
        if ( !accountsOptions && authData ) {
            this.props.dispatch(getAccountsOptions(authData));
        } else if ( accountsOptions && randNum && randNum != this.state.randNum ) {
            this.setState({ randNum });
        } // end - accountsOptions
    }

    handleSelect = (option) => {
        const { onSelect } = this.props;
        // trigger option select
        if ( onSelect )
            onSelect(option);
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose )
            onClose();
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { accountsOptions } = this.props;
        let items = ( accountsOptions ? cloneCollections( accountsOptions ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','assigned_to.name'] );
			total = _size( items );
        } // end - searchterms

        // do custom filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy != 'all' ) {
            // items = _filter( items, { org: filterBy });
            // total = _size( items );
        }

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'name-desc':
                    items = _sortBy( items, [(i) => i.name.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, [(i) => i.name.toLowerCase()] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                total={totalCount}
                perPage={perPage} 
                page={page}
                doneLoaded={true}
                style={{ marginTop: "20px" }}
                onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderTableActions = () => {
        const { sortBy, perPage, searchterms } = this.state;
        return <TableBar
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' }
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderAssignedTo = (item) => {
        return <div>{( item.assigned_to && !_isEmpty( item.assigned_to ) ? _map( item.assigned_to, (u) => {
            return <div key={u.id}>{( u.name || '' )}</div>
        }) : '---' )}</div>;
    }

    renderTable = (items) => {
        return <Table 
            items={items}
            showCheckbox={false}
            cells={[
                { id: 'name', label: 'Name', render: (item) => ( item.name || '' ) },
                { id: 'assigned_to', label: 'Assigned To', render: this.renderAssignedTo }
            ]}
            actionStyles={{ width: "10%" }}
            actions={(item) => (
                <ButtonGroup>
                    <InfoButton key="select" size="small" onClick={this.handleSelect.bind(this,item)}><i className="fa fa-edit"></i>Select</InfoButton>
                </ButtonGroup>
            )} />
    }

    renderModal = () => {
        const { randNum } = this.state;
        const { items, total } = this.reorganizeData();
        return !randNum ? <DotsLoader /> : (
        <div>

            {this.renderTableActions()}
            {this.renderTable(items)}
            {this.renderPagination(total)}
            
        </div>   
        )
    }

    render() {
        const { open } = this.props;
        return <ModalView 
                open={open}
                title="Select Account"
                noAction={true}
                actionLabel="Select"
                maxWidth="lg"
                cancelLabel="Close"
                onClose={this.handleClose}
                contents={ open ? this.renderModal() : null } />
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        accountsOptions: state.accounts && state.accounts.accounts_options || null,
        randNum: state.accounts && state.accounts.options_rand || null
    }
}

export default compose(
    connect(mapStateToProps)
)(ModalSelectAccounts);