import React from 'react';
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
//import Divider from '@material-ui/core/Divider';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';

/* eslint-disable */

import EditForm from './form';

// import FormInput from '../../components/FormInput';
// import FormSelect from '../../components/FormSelect';
// import FormSelect2 from '../../components/FormSelect2';
// import FormMultiSelect from '../../components/FormMultiSelect';
// import FormDatePicker from '../../components/FormDatePicker';
// import FormRadio from '../../components/FormRadio';

// import SelectAccounts from '../../components/SelectAccounts';

import ListUpdates from '../../components/ListUpdates';
import ListComments from '../../components/ListComments';

// import { InfoButton, GreyButton } from '../../styles/button';
// import { WrapWord } from '../../styles/misc';
// import { FormBox } from '../../styles/form';

// import { isAdmin } from '../../helpers/auth';
import { isArrayExists, isObjectExists } from '../../helpers/validation';
// import { triggerErrorAlert } from '../../helpers/alert';
import { 
    //cloneCollections, getSelectOptions, getSelectValues, 
    combineStrings } from '../../helpers/data';
// import { reverseUsersValues } from '../../helpers/users';
// import { getMomentTime } from '../../helpers/date';
// import { isSchemaRequired } from '../../helpers/schemas';

// import { contactSchema } from '../../schemas/contact';
// import { COUNTRIES_LIST } from '../../data/const_countries';
// import { STATE_OPTIONS } from '../../data/const_states';

// import { YES_NO_OPTIONS } from '../../constants';

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

class ContactDetails extends React.Component {

    state = {
        randNum: false
    };

    renderUpdatesBox = () => {
        const { classes, contact } = this.props;
        return (
        <div>
            <Typography variant="h4" className={classes.boxheading}>Updates</Typography>
            <ListUpdates 
                list={( contact && contact.updates && isArrayExists( contact.updates ) ? contact.updates : false )} />
        </div>
        );
    }

    renderCommentsBox = () => {
        const { classes, contact, authData, updateActions } = this.props;
        return (
        <div>
            <Typography variant="h4" className={classes.boxheading}>Comments</Typography>
            <ListComments 
                authData={( authData || false )}
                refer_type="contact"
                refer_id={( contact && contact.id && !_isEmpty( contact.id ) ? contact.id : false )}
                refer_label={( contact ? combineStrings( contact.designation, contact.first_name, contact.last_name ) : false )}
                updateActions={( updateActions || false )}
                list={( contact && contact.comments && isArrayExists( contact.comments ) ? contact.comments : false )} />
        </div>
        );
    }
   
    render() {
        return (
        <div>

            <EditForm {...this.props} />

            <Grid container spacing={3}>
                <Grid item xs={6}>
                {this.renderCommentsBox()}
                </Grid>
                <Grid item xs={6}>
                    {this.renderUpdatesBox()}
                </Grid>
            </Grid>


        </div>
        )
    }

}

export default compose(
    withStyles(useStyles),
    withRouter
)(ContactDetails);