/* eslint-disable */
import * as firebase from 'firebase/app';

import { getIDToken } from '../auth/fb';
import { doPromise, callFunctionsAPI } from '../../helpers/action';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

/* helper end */

export const fb_getRegions = (callback) => {

    firebase.database()
    .ref("maintenance/regions")
    .on("value", snapshot => {

        var list = [];

        if ( snapshot.exists() && snapshot.hasChildren() ) {
            snapshot.forEach( childSnapshot => {
                var val = childSnapshot.val(),
                    item = {
                        id: childSnapshot.key,
                        label: val.label || '',
                        company_name: val.company_name || '',
                        company_logo: val.company_logo || '',
                        company_code: val.company_code || '',
                        company_address: val.company_address || '',
                        company_email: val.company_email || '',
                        company_fax: val.company_fax || '',
                        company_phone: val.company_phone || '',
                        company_website: val.company_website || '',
                        tax_amount: val.tax_amount || '',
                        tax_label: val.tax_label || '',
                        tax_reg_number: val.tax_reg_number || '',
                        tax_reg_number_label: val.tax_reg_number_label || '',
                        prefix_quote: val.prefix_quote || '',
                        prefix_invoice: val.prefix_invoice || ''
                    };

                list.push(item);
            });
        } // end - snapshpt\
        
        callback(list);

    });
    
}

export const fb_addRegion = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'mRegions', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateRegion = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'mRegions', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteRegion = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'mRegions', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_syncRegions = () => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'sync',
                params: [{ key: 'action', value: 'sync_regions' }]
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}