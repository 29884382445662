import _random from 'lodash/random';

import { 
    GET_CREDIT_NOTES,
    GET_CREDIT_NOTES_BY,
	GET_CREDIT_NOTE,
	ADD_CREDIT_NOTE,
	EDIT_CREDIT_NOTE,
    DELETE_CREDIT_NOTE,
    RESET_CREDIT_NOTE,
    RESET_CREDIT_NOTES,
    RESET_GET_CREDIT_NOTES_BY
} from '../actions/types';

const initialState = {
    credit_notes: null,
    total: null,
    firstDoc: null,
    lastDoc: null,
    credit_notes_by: null,
    credit_note: null,
    new_credit_note_id: null,
    updated: null,
    by_rand: false,
    rand: false
};

export const credit_notes = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_CREDIT_NOTES:
            return {
                ...state,
                credit_notes: ( payload.list || [] ),
                total: ( payload.total || null ),
                firstDoc: ( payload.firstDoc || null ),
                lastDoc: ( payload.lastDoc || null ),
                rand: _random(1,9999)
            };
        case GET_CREDIT_NOTES_BY:
            return {
                ...state,
                credit_notes_by: ( payload.list || [] ),
                by_rand: _random(1,9999)
            };
        case GET_CREDIT_NOTE:
            return {
                ...state,
                credit_note: ( payload.credit_note || {} ),
                rand: _random(1,9999)
            };
        case ADD_CREDIT_NOTE:
            return {
                ...state,
                new_credit_note_id: ( payload.id || false ),
                rand: _random(1,9999)
            };
        case EDIT_CREDIT_NOTE:
        case DELETE_CREDIT_NOTE:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_CREDIT_NOTE:
            return {
                ...state,
                credit_note: null,
                updated: null,
                rand: false
            };
        case RESET_CREDIT_NOTES:
            return {
                ...state,
                credit_notes: null,
                total: null,
                firstDoc: null,
                lastDoc: null,
                rand: false
            };
        case RESET_GET_CREDIT_NOTES_BY:
            return {
                ...state,
                credit_notes_by: null,
                by_rand: false
            };    
        default:
            return state;
    }
}