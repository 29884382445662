import React from "react";
import { compose } from "recompose";
// import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import _sortBy from "lodash/sortBy";
import _filter from "lodash/filter";

/* eslint-disable */

import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormMultiSelect from "../../components/FormMultiSelect";
import FormDatePicker from "../../components/FormDatePicker";
import FormCheckMailingLists from "../../components/FormCheckMailingLists";

import { FormBox } from "../../styles/form";

import { isAdmin, hasAccessRights } from "../../helpers/auth";
import { isArrayExists } from "../../helpers/validation";
import { cloneCollections, getSelectOptions, getSelectValues } from "../../helpers/data";
import { reverseUsersValues } from "../../helpers/users";
import { getMomentTime } from "../../helpers/date";
import { isSchemaRequired } from "../../helpers/schemas";

import { accountSchema } from "../../schemas/account";
import { COUNTRIES_LIST } from "../../data/const_countries";
import { STATE_OPTIONS } from "../../data/const_states";

import { YES_NO_OPTIONS } from "../../constants";

const useStyles = (theme) => ({
    boxheading: {
        fontSize: "20px",
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd",
    },
});

class AccountEditForm extends React.Component {
    state = {
        // showMailingListWarning: false,
        // newPortfolio: false,
        randNum: false,
    };

    handleMailingListMessagesUpdate = (listID) => {
        const { onMailingListMessagesUpdate } = this.props;
        if (onMailingListMessagesUpdate) onMailingListMessagesUpdate(listID);
    };

    handleMailingListConfirmation = () => {
        const { account, onFormUpdate } = this.props;
        const { newPortfolio } = this.state;
        let newData = account && !_isEmpty(account) ? cloneCollections(account) : {};
        newData["account_tier_1_id"] = newPortfolio.id;
        newData["account_tier_1"] = (newPortfolio && newPortfolio.value) || ""; // add to value

        // close warning
        // this.setState({ showMailingListWarning: false, newPortfolio: false });

        // do update
        if (onFormUpdate) onFormUpdate(newData);
    };

    handleFormUpdate = (newValue, key) => {
        const { onFormUpdate, account, users, course_portfolio, accounts_tier_2, mailing_lists, massEditMode, isAccountPage, currentAccount } =
            this.props;
        let newData = account && !_isEmpty(account) ? cloneCollections(account) : {};

        switch (key) {
            case "assigned_to":
                newData[key] = reverseUsersValues(newValue, users);
                break;
            case "account_tier_1_id":
                let selected_portfolio = course_portfolio && isArrayExists(course_portfolio) ? _find(course_portfolio, { id: newValue }) : false;
                newData[key] = newValue;
                newData["account_tier_1"] = (selected_portfolio && selected_portfolio.value) || ""; // add to value

                // add to mailing_lists if original account_tier_1 is empty, mailing_lists is empty and is account page
                if (
                    isAccountPage &&
                    !(currentAccount.account_tier_1_id && !_isEmpty(currentAccount.account_tier_1_id)) &&
                    !(account && account.mailing_lists && isArrayExists(account.mailing_lists))
                ) {
                    let selected_list =
                        mailing_lists && isArrayExists(mailing_lists)
                            ? _find(mailing_lists, { account_tier_1_id: newData["account_tier_1_id"] })
                            : false;
                    if (selected_list && selected_list.id && !_isEmpty(selected_list.id)) {
                        newData["mailing_lists"] = [{ id: selected_list.id, name: selected_list.name || "" }];
                    }
                } // end - isAccountPage
                break;
            case "account_tier_2_id":
                let selected_tier = accounts_tier_2 && isArrayExists(accounts_tier_2) ? _find(accounts_tier_2, { id: newValue }) : false;
                newData[key] = newValue;
                newData["account_tier_2"] = (selected_tier && selected_tier.label) || ""; // add to label
                break;
            // case 'mailing_lists':
            //     newData[key] = newValue;
            //     break;
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if (onFormUpdate) onFormUpdate(newData);
    };

    getFieldOptions = (field) => {
        const { users, account, course_portfolio, accounts_tier_2 } = this.props;

        switch (field.name) {
            case "assigned_to":
                return getSelectOptions({
                    list: users ? _filter(users, (u) => u.status !== "disabled") : [],
                    keys: { value: "email", label: "name" },
                    sortBy: "label",
                });
            case "hrdf_contributer":
            case "hrdf_sme":
                return getSelectOptions({
                    list: YES_NO_OPTIONS,
                    options: [{ value: "", label: "Select an Option" }],
                    keys: { value: "value", label: "label" },
                });
            case "account_tier_1_id":
                return getSelectOptions({
                    list: course_portfolio && isArrayExists(course_portfolio) ? _sortBy(course_portfolio, ["label"]) : [],
                    options: [{ value: "", label: "Select an Option" }],
                    keys: { value: "id", label: "label" },
                });
            case "account_tier_2_id":
                return getSelectOptions({
                    list: accounts_tier_2 && isArrayExists(accounts_tier_2) ? _sortBy(accounts_tier_2, ["label"]) : [],
                    keys: { value: "id", label: "label", disabled: "status" },
                });
            case "billing_state":
                let selected =
                        account && account.billing_country && !_isEmpty(account.billing_country)
                            ? _find(COUNTRIES_LIST, { value: account.billing_country })
                            : false,
                    country = selected && selected.label && !_isEmpty(selected.label) ? _find(STATE_OPTIONS, { country: selected.label }) : false,
                    options = [{ value: "", label: "Select an Option" }];
                if (country && country.states && isArrayExists(country.states)) {
                    country.states.forEach((state) => {
                        options.push({ value: state, label: state });
                    });
                } // end - country.states
                return options;
            case "billing_country":
                return getSelectOptions({
                    list: COUNTRIES_LIST,
                    options: [{ value: "", label: "Select an Option" }],
                    keys: { value: "value", label: "label" },
                });

            default:
                return [];
        }
    };

    getFieldValue = (field) => {
        const { account } = this.props;
        switch (field.name) {
            case "assigned_to":
                return account && account[field.name] && isArrayExists(account[field.name]) ? getSelectValues(account[field.name], "id") : [];
            default:
                return (account && account[field.name]) || field.default || "";
        }
    };

    isFieldDisabled = (schema) => {
        const { authData } = this.props;
        var disabled = false;

        if (schema && schema.disabled && isArrayExists(schema.disabled)) {
            schema.disabled.forEach((condition) => {
                switch (condition) {
                    case "admin":
                        if (schema.id === "assigned_to") {
                            if (!(authData && hasAccessRights(authData, ["act"]))) disabled = true;
                        } else {
                            if (!isAdmin(authData)) disabled = true;
                        }
                        break;
                    case "update":
                        disabled = true;
                        break;
                }
            });
        } // end - schema

        return disabled;
    };

    getField = (id) => {
        const { massEditMode } = this.props;
        let schema = _find(accountSchema, { id });
        return schema
            ? {
                  name: schema.id || "",
                  label: (schema.label || "") + (isSchemaRequired(schema, "update") && !massEditMode ? " (Required)" : ""),
                  field_type: schema.field || "",
                  default: massEditMode ? null : schema.default || null,
                  disabled: schema.disabled ? this.isFieldDisabled(schema) : false,
              }
            : null;
    };

    renderMailingListField = () => {
        const { account, isAccountPage, massEditMode, mailingListMessages } = this.props;
        return (
            <FormCheckMailingLists
                value={account && account.mailing_lists ? account.mailing_lists : []}
                name="mailing_lists"
                messages={mailingListMessages || []}
                onMessageRemove={this.handleMailingListMessagesUpdate}
                onChange={this.handleFormUpdate}
            />
        );
    };

    renderField = (id) => {
        let field = this.getField(id);
        if (field && field.field_type && !_isEmpty(field.field_type)) {
            switch (field.field_type) {
                case "text":
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "textarea":
                    let rows = 3;
                    if ("notes" === id) {
                        rows = 6;
                    }
                    if ("msic_code" === id) {
                        rows = 3;
                    }
                    return <FormInput {...field} rows={rows} multiline={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "text_number":
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "email":
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case "select":
                    return (
                        <div style={{ paddingTop: "7px" }}>
                            <FormSelect
                                {...field}
                                value={this.getFieldValue(field)}
                                options={this.getFieldOptions(field)}
                                disableNative={id === "account_tier_2_id" ? true : false}
                                onChange={this.handleFormUpdate}
                            />
                        </div>
                    );
                case "multiselect":
                    return (
                        <div style={{ paddingTop: "7px" }}>
                            <FormMultiSelect
                                {...field}
                                value={this.getFieldValue(field)}
                                options={this.getFieldOptions(field)}
                                onChange={this.handleFormUpdate}
                            />
                        </div>
                    );
                case "datepicker":
                    return <FormDatePicker {...field} noDefaultVal={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
            }
        } // end - field.field_type
    };

    renderCreatedModifiedDate = (item) => {
        let created_on = item.created_on && item.created_on._seconds ? item.created_on._seconds * 1000 : item.created_on || null,
            modified_on = item.modified_on && item.modified_on._seconds ? item.modified_on._seconds * 1000 : item.modified_on || null;
        return (
            <div style={{ paddingTop: "10px", textAlign: "right", color: "#999", fontSize: "1.25rem" }}>
                <div>{created_on ? "Created on " + getMomentTime(created_on, "YYYY-MM-DD hh:mm:ssa") : ""}</div>
                <div>{modified_on ? "Last Modified on " + getMomentTime(modified_on, "YYYY-MM-DD hh:mm:ssa") : ""}</div>
            </div>
        );
    };

    render = () => {
        const { classes, account, disableSystemDate, massEditMode } = this.props;
        // const { showMailingListWarning, newPortfolio } = this.state;
        return (
            <FormBox>
                {massEditMode ? null : (
                    <Typography variant="h4" className={classes.boxheading}>
                        Account Info
                    </Typography>
                )}
                <Grid container spacing={3}>
                    {massEditMode ? null : (
                        <Grid item xs={6}>
                            <FormInput label="Account ID" value={(account && account.id) || ""} disabled={true} />
                        </Grid>
                    )}
                    {massEditMode ? null : (
                        <Grid item xs={6}>
                            {this.renderField("lms_linked")}
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        {this.renderField("name")}
                    </Grid>

                    <Grid item xs={6}>
                        {this.renderField("company_reg_no")}
                    </Grid>
                    {massEditMode ? null : (
                        <Grid item xs={12}>
                            {this.renderField("assigned_to")}
                        </Grid>
                    )}

                    <Grid item xs={6}>
                        {this.renderField("account_tier_1_id")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("account_tier_2_id")}
                    </Grid>

                    <Grid item xs={12}>
                        {this.renderMailingListField()}
                    </Grid>

                    <Grid item xs={12}>
                        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                    </Grid>

                    <Grid item xs={6}>
                        {this.renderField("direct_line")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("general_line")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("fax")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("last_called")}
                    </Grid>

                    <Grid item xs={6}>
                        {this.renderField("email")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("email_other")}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderField("website")}
                    </Grid>

                    <Grid item xs={6}>
                        {this.renderField("num_of_employee")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("num_of_engineer")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("hrdf_contributer")}
                    </Grid>

                    <Grid item xs={12}>
                        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                    </Grid>

                    <Grid item xs={12}>
                        {this.renderField("billing_address")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("billing_city")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("billing_post_code")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("billing_state")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("billing_country")}
                    </Grid>

                    <Grid item xs={6}>
                        {this.renderField("tin_no")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("sst_no")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("einv_notification_email")}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderField("msic_code")}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderField("business_activity_description")}
                    </Grid>

                    <Grid item xs={12}>
                        {this.renderField("notes")}
                    </Grid>
                </Grid>

                {disableSystemDate ? null : this.renderCreatedModifiedDate(account)}

                {/* <ModalView
                open={showMailingListWarning}
                title={"Please Confirm Your Action"}
                actionLabel="Confirm"
                maxWidth="sm"
                onClose={() => this.setState({ showMailingListWarning: false, newPortfolio: false })}
                doAction={this.handleMailingListConfirmation}
                contents={(<div><Typography variant="body1">{'WARNING: All the contacts under this account will be transferred to the mailing list associated with this new portfolio: '+( newPortfolio && newPortfolio.label && !_isEmpty( newPortfolio.label ) ? newPortfolio.label : '' )+'.'}</Typography></div>)} /> */}
            </FormBox>
        );
    };
}

export default compose(withStyles(useStyles), withRouter)(AccountEditForm);
