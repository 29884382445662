import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';

/* eslint-disable */

import Table from '../../components/Table';
import Pagination from '../../components/PaginationFireStore';
import TableBar from '../../components/TableBarFirestore';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormSelect from '../../components/FormSelect';
import FormInput from '../../components/FormInput';
import ButtonActions from '../../components/ButtonActions';
import MassComment from '../../components/MassComment';
import AddNewPotential from '../../components/AddNewPotential';
import AddNewEmail from '../../components/AddNewEmail';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { SuccessTag, AmberTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, combineStrings } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { getSearchKeyID, getRawSearchKeywords, doSearchSort } from '../../helpers/firestore';

import { deletePotential } from '../../actions/potentials';

class Potentials extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        openViewModal: false,
        openDeleteModal: false,
        deleteModal: false,
        openComment: false,
        openSendEmail: false
    }

    handlePageRefresh = (event) => {
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh();
    }

    handleEdit = (id,event) => {
        event.preventDefault();
        this.props.history.push("/potentials/"+id);
    }

    handleDelete = () => {
        const { currentQuery } = this.props;
        const { deleteModal } = this.state;
        // perform delete
        this.props.dispatch(deletePotential(deleteModal.id,currentQuery));
    }

    handleQueryChange = (newQuery) => {
        const { onQueryChange } = this.props;
        // trigger change
        if ( onQueryChange )
            onQueryChange(newQuery);
    }

    handleAction = type => {
        const { checked } = this.state;
        // make sure there is an item selected
        if ( isArrayExists( checked ) ) {
            if ( type && type === 'comment' ) {
                this.setState({ openComment: true });
            } else if ( type && type === 'sendemail' ) {
                this.setState({ openSendEmail: true });
            } 
        } else {
            triggerErrorAlert("Please select at least one item");
        } // end - checked
    }

    getPredefinedAccounts = () => {
        const { checked } = this.state;
        const { items, total } = this.reorganizeData();
        const selected = [];
        if ( checked && isArrayExists( checked ) && items && isArrayExists( items ) ) {
            _forEach( checked, item => {
                let potential = _find( items, { id: item.id });
                if ( potential && potential.accounts_linked && isArrayExists( potential.accounts_linked ) ) {
                    _forEach( potential.accounts_linked, account => {
                        if ( account && account.id && !_isEmpty( account.id ) ) {
                            selected.push({ 
                                id: account.id,
                                name: account.name || '',
                                assigned_to: ( potential.assigned_to && isArrayExists( potential.assigned_to ) ? potential.assigned_to : [] )
                            });
                        } // end - account.id
                    });
                } // end - potential.accounts_linked                   
            });
        } // end - checked
        return selected;
    }

    getPredefinedContacts = () => {
        const { checked } = this.state;
        const { items, total } = this.reorganizeData();
        const selected = [];
        if ( checked && isArrayExists( checked ) && items && isArrayExists( items ) ) {
            _forEach( checked, item => {
                let potential = _find( items, { id: item.id });
                if ( potential && potential.contacts_linked && isArrayExists( potential.contacts_linked ) ) {
                    _forEach( potential.contacts_linked, contact => {
                        if ( contact && contact.id && !_isEmpty( contact.id ) ) {
                            selected.push({ 
                                id: contact.id,
                                name: contact.name || '',
                                email: contact.email || '',
                                account_id: contact.account_id || '',
                            });
                        } // end - contact.id
                    });
                } // end - potential.contacts_linked            
            });
        } // end - checked
        return selected;
    }

    getPredefinedPotentials = () => {
        const { checked } = this.state;
        const { items, total } = this.reorganizeData();
        const selected = [];
        if ( checked && isArrayExists( checked ) && items && isArrayExists( items ) ) {
            _forEach( checked, item => {
                let potential = _find( items, { id: item.id });
                if ( potential && !_find( selected, { id: potential.id }) )
                    selected.push(potential);
            });
        } // end - checked
        return selected;
    }

    getActions = () => {
        const { authData } = this.props;
        let actions = [
            { id: 'addnew', label: 'Add New Potential', icon: 'fa-plus-circle', onClick: () => this.setState({ openViewModal: true }) },
            { id: 'addcomment', label: 'Add Comment', icon: 'fa-commenting', onClick: this.handleAction.bind(this,'comment') },
            { id: 'sendemail', label: 'Send Email', icon: 'fa-envelope', onClick: this.handleAction.bind(this,'sendemail'), disabled: ( authData && hasAccessRights(authData, ['pts']) ? false : true ) }
        ];

        return actions;
    }

    reorganizeData() {
        const { potentials, total, authData, searchterms, sortBy } = this.props;
        let items = ( potentials ? cloneCollections( potentials ) : [] ),
            totalCount = ( total ? total : 0 );;

        // add in searchable label
        if ( potentials && isArrayExists( potentials ) ) {
            _forEach( potentials, (current,index) => {

                // set label as empty
                items[index].accounts_label = '';
                items[index].contacts_label = '';
                items[index].assigned_to_label = '';

                // run through all the IDs to get labels
                if ( current && current.accounts_linked && isArrayExists( current.accounts_linked ) ) {
                    _forEach( current.accounts_linked, u => {
                        items[index].accounts_label += ( !_isEmpty( items[index].accounts_label ) ? ' ' : '' ) + ( u && u.name ? u.name : u.id );  
                    });
                } // end - current.accounts_linked

                // run through all the IDs to get labels
                if ( current && current.contacts_linked && isArrayExists( current.contacts_linked ) ) {
                    _forEach( current.contacts_linked, u => {
                        items[index].contacts_label += ( !_isEmpty( items[index].contacts_label ) ? ' ' : '' ) + ( u && u.name ? u.name : u.id );  
                    });
                } // end - current.contacts_linked

                // run through all the IDs to get labels
                if ( current && current.assigned_to && isArrayExists( current.assigned_to ) ) {
                    _forEach( current.assigned_to, u => {
                        items[index].assigned_to_label += ( !_isEmpty( items[index].assigned_to_label ) ? ' ' : '' ) + ( u && u.name ? u.name : u.id );  
                    });
                } // end - current.assigned_to
                
            });
        } // end - potentials

        // for searchterms
        if ( searchterms && !_isEmpty( searchterms ) ) {
            // further filter out unnecessary keywords
            var search_key = getSearchKeyID(searchterms),
                search_keywords = getRawSearchKeywords(searchterms);

            items = doArraySearch( items, search_keywords, [ search_key ]);
            totalCount = _size( items );

            // do sorting on search results
            if ( sortBy && !_isEmpty( sortBy ) ) {
                items = doSearchSort( items, sortBy );
            }
        } // end - searchterms

        return { items, total: totalCount };
    }

    renderCell = type => item => {
        switch( type ) {
            case 'accounts_linked':
            case 'contacts_linked':
            case 'assigned_to':
                return ( item[type] && isArrayExists( item[type] ) ? item[type].map( (u) => (<div key={u.id}>{u.name}</div>) ) : '--' );
            case 'expected_close_date':
                return ( item[type] ? getMomentTime(item[type],'YYYY-MM-DD') : '--' );
            case 'email':
                return <WrapWord>{( item[type] || '' )}</WrapWord>
        }
    }

    renderTableActions = () => {
        const { authData, sortBy, perPage, searchterms } = this.props;
        const { checked } = this.state;
        return <TableBar
                mod="potentials"
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                searchOptions={[
                    { id: 'sales_stage', options: [
                        { value: 'Prospecting', label: 'Prospecting' },
                        { value: 'Qualification', label: 'Qualification' },
                        { value: 'Proposal/Quotation', label: 'Proposal/Quotation' },
                        { value: 'Negotiation or Review', label: 'Negotiation or Review' },
                        { value: 'Closed Won', label: 'Closed Won' },
                        { value: 'Closed Lost', label: 'Closed Lost' },
                    ] }
                ]}
                sortByOptions={[
                    { value: 'modified_on-desc', label: 'Recent Modified first' },
                    { value: 'modified_on-asc', label: 'Oldest Modified first' },
                    { value: 'name-asc', label: 'Potential Deal ( A - Z)' },
                    { value: 'name-desc', label: 'Potential Deal ( Z - A )' },
                    { value: 'accounts_linked_sort-asc', label: 'Account Name ( A - Z)' },
                    { value: 'accounts_linked_sort-desc', label: 'Account Name ( Z - A )' },
                    { value: 'contacts_linked_sort-asc', label: 'Contact Name ( A - Z)' },
                    { value: 'contacts_linked_sort-desc', label: 'Contact Name ( Z - A )' }
                ]}
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                    </div>
                    ] : null }
                rightButtons={[
                    <ButtonActions 
                        key="actions" 
                        label="Actions"
                        menuContainerStyle={{ width: "200px" }}
                        style={{ marginRight: "5px" }}
                        actions={this.getActions()} />
                    ,
                    <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton>
                ]}
                onFilterByChange={(newFilterBy) => this.handleQueryChange({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.handleQueryChange({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.handleQueryChange({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.handleQueryChange({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page, searchterms, currentTotal } = this.props;
        return <Pagination 
                    total={totalCount}
                    currentTotal={currentTotal}
                    searchterms={searchterms}
                    perPage={perPage} 
                    page={page}
                    doneLoaded={true}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.handleQueryChange({ page: newPage }) } />
    }

    render() {
        const { authData, users } = this.props;
        const { openDeleteModal, deleteModal, openViewModal, allCheck, checked, openComment, openSendEmail } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <AddNewPotential
                authData={authData}
                open={openViewModal}
                users={( users || [] )}
                onClose={() => this.setState({ openViewModal: false })} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to delete this potential ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            {this.renderTableActions()}
            <Table 
                items={items}
                showCheckbox={true}
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={[
                    { id: 'name', label: 'Potential Deal', render: (item) => ( item.name || '' ) },
                    { id: 'accounts_label', label: 'Account Name', render: (item) => ( item.accounts_label && !_isEmpty( item.accounts_label ) ? item.accounts_label : '' ) },
                    { id: 'expected_close_date', label: 'Expected Close Date', render: this.renderCell('expected_close_date') },
                    { id: 'contact_label', label: 'Contact Name', render: (item) => ( item.contacts_label && !_isEmpty( item.contacts_label ) ? item.contacts_label : '' ) },
                    { id: 'sales_stage', label: 'Sales Stage', render: (item) => ( item.sales_stage || '' ) },
                    { id: 'num_of_pax', label: 'Number of Pax', render: (item) => ( item.num_of_pax || 0 ) },
                    { id: 'assigned_to', label: 'Assigned To', render: this.renderCell('assigned_to') },
                ]}
                actionStyles={{ width: "10%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <AInfoLink key="edit" href={"/potentials/"+item.id} size="small" onClick={this.handleEdit.bind(this,item.id)}><i className="fa fa-edit"></i>Edit</AInfoLink>
                        { authData && hasAccessRights( authData, ['ptd'] ) ? <ErrorButton key="delete" size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton> : null }
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}

            <MassComment 
                refer_type="potential"
                open={openComment}
                selected={( checked || [] )}
                onClose={() => this.setState({ openComment: false })}
                onCompleted={() => {
                    // reset modal, and selector
                    this.setState({ openComment: false, checked: [], allCheck: false })
                    // trigger refresh
                    if ( this.props.onRefresh )
                        this.props.onRefresh();
                }} />

            { authData && hasAccessRights(authData, ['pts']) ? <AddNewEmail
                authData={authData}
                open={openSendEmail}
                users={( users || [] )}
                predefined_accounts={this.getPredefinedAccounts()}
                predefined_contacts={this.getPredefinedContacts()}
                predefined_potentials={this.getPredefinedPotentials()}
                onClose={() => this.setState({ openSendEmail: false })} /> : null }

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(Potentials);