import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import List from './list';

import { getRegions } from '../../actions/m_regions';
import { getCoursePortfolios } from '../../actions/m_course_portfolio';

class MaintenanceCoursePortfolio extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {

        // get course portfolio
        this.props.dispatch(getCoursePortfolios());

        // get regions
        this.props.dispatch(getRegions());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { coursePortfolioList, regionsList, randNum } = this.props;

        if ( coursePortfolioList && regionsList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    renderContents() {
        const { authData, coursePortfolioList, regionsList } = this.props;
        return <List authData={authData} list={coursePortfolioList} regions={regionsList} />;
    }

    render() {
        const { coursePortfolioList, randNum } = this.props;
        return <AppWrapper 
                title="Business"
                subtitle="Maintenance"
                onLoad={( !( coursePortfolioList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        coursePortfolioList: state.maintenance && state.maintenance.course_portfolio || null,
        regionsList: state.maintenance && state.maintenance.regions || null,
        randNum: state.maintenance && state.maintenance.rand || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(MaintenanceCoursePortfolio);