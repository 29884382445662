import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';

/* eslint-disable */

import Table from '../../components/Table';
import Pagination from '../../components/PaginationFireStore';
import TableBar from '../../components/TableBarFirestore';
import ModelDelete from '../../components/ModalDelete';
// import ModalView from '../../components/ModalView';
// import FormSelect from '../../components/FormSelect';
// import FormInput from '../../components/FormInput';
import ButtonActions from '../../components/ButtonActions';
import MassComment from '../../components/MassComment';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
// import { SuccessTag, AmberTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, combineStrings } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { getSearchKeyID, getRawSearchKeywords, doSearchSort } from '../../helpers/firestore';

import { deleteQuote } from '../../actions/quotes';


class Quotes extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        openViewModal: false,
        openDeleteModal: false,
        deleteModal: false,
        openComment: false
    }

    handlePageRefresh = (event) => {
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh();
    }

    handleEdit = (id,event) => {
        event.preventDefault();
        this.props.history.push("/quotes/"+id);
    }

    handleDelete = () => {
        const { currentQuery } = this.props;
        const { deleteModal } = this.state;
        // perform delete
        this.props.dispatch(deleteQuote(deleteModal.id,currentQuery));
    }

    handleQueryChange = (newQuery) => {
        const { onQueryChange } = this.props;
        // trigger change
        if ( onQueryChange )
            onQueryChange(newQuery);
    }

    getRegionOptions = () => {
        const { regions } = this.props;
        var options = [{ value: '', label: 'All Companies' }];

        if ( regions && isArrayExists( regions ) ) {
            _forEach( regions, region => {
                options.push({ value: 'company_details_|_'+region.id, label: region.company_name });
            });
        } // end - regions

        return options;
    }

    handleAction = type => {
        const { checked } = this.state;
        // make sure there is an item selected
        if ( isArrayExists( checked ) ) {
            if ( type && type === 'comment' ) {
                this.setState({ openComment: true });
            }
        } else {
            triggerErrorAlert("Please select at least one item");
        } // end - checked
    }

    getActions = () => {
        const { authData } = this.props;
        let actions = [
            { id: 'addnew', label: 'Add New Quote', icon: 'fa-plus-circle', onClick: () => {
                this.props.history.push("/quotes/new");
            }}
        ];

        // if ( authData && isAdmin( authData ) ) {
        //     actions.push({ id: 'export', label: 'Export', icon: 'fa-cloud-download', onClick: false, disabled: true });
        // } // end - authData

        return actions;
    }

    reorganizeData() {
        const { quotes, total, authData, searchterms, sortBy, filterBy } = this.props;
        let items = ( quotes ? cloneCollections( quotes ) : [] ),
            totalCount = ( total ? total : 0 );

        // add in searchable label
        if ( quotes && isArrayExists( quotes ) ) {
            _forEach( quotes, (current,index) => {

                // set label as empty
                items[index].accounts_label = '';
                items[index].potentials_label = '';
                items[index].assigned_to_label = '';

                // run through all the IDs to get labels
                if ( current && current.accounts_linked && isArrayExists( current.accounts_linked ) ) {
                    _forEach( current.accounts_linked, u => {
                        items[index].accounts_label += ( !_isEmpty( items[index].accounts_label ) ? ' ' : '' ) + ( u && u.name ? u.name : u.id );  
                    });
                } // end - current.accounts_linked

                // run through all the IDs to get labels
                if ( current && current.potentials_linked && isArrayExists( current.potentials_linked ) ) {
                    _forEach( current.potentials_linked, u => {
                        items[index].potentials_label += ( !_isEmpty( items[index].potentials_label ) ? ' ' : '' ) + ( u && u.name ? u.name : u.id );  
                    });
                } // end - current.potentials_linked

                // run through all the IDs to get labels
                if ( current && current.assigned_to && isArrayExists( current.assigned_to ) ) {
                    _forEach( current.assigned_to, u => {
                        items[index].assigned_to_label += ( !_isEmpty( items[index].assigned_to_label ) ? ' ' : '' ) + ( u && u.name ? u.name : u.id );  
                    });
                } // end - current.assigned_to
                
            });
        } // end - quotes

        // for searchterms
        if ( searchterms && !_isEmpty( searchterms ) ) {
            // further filter out unnecessary keywords
            var search_key = getSearchKeyID(searchterms),
                search_keywords = getRawSearchKeywords(searchterms);

            items = doArraySearch( items, search_keywords, [ search_key ]);
            totalCount = _size( items );

            // do filter if needed
            if ( filterBy && !_isEmpty( filterBy ) ) {
                var filterKey = getSearchKeyID(filterBy),
                    filterVal = getRawSearchKeywords(filterBy);
                if ( filterKey && !_isEmpty( filterKey ) && filterVal && !_isEmpty( filterVal ) ) {
                    items = _filter( items, (i) => i[filterKey] === filterVal );
                    totalCount = _size( items );
                }
            } // end - filterBy

            // do sorting on search results
            if ( sortBy && !_isEmpty( sortBy ) ) {
                items = doSearchSort( items, sortBy );
            }
        } // end - searchterms

        return { items, total: totalCount };
    }

    renderCell = type => item => {
        const { users } = this.props;
        switch( type ) {
            case 'sales_person':
                const user = ( item[type] && !_isEmpty( item[type] ) ? _find( users, { email: item[type] } ) : false );
                return ( user && user.name && !_isEmpty( user.name ) ? user.name : ( item[type] || '---' ) );
            case 'accounts_linked':
            case 'potentials_linked':
            case 'assigned_to':
                return ( item[type] && isArrayExists( item[type] ) ? item[type].map( (u) => (<div key={u.id}>{u.name}</div>) ) : '--' );
            case 'email':
                return <WrapWord>{( item[type] || '' )}</WrapWord>
        }
    }

    renderTableActions = () => {
        const { authData, sortBy, perPage, searchterms, users, filterBy } = this.props;
        const { checked } = this.state;
        return <TableBar
                mod="quotes"
                sortBy={sortBy}
                filterBy={filterBy}
                perPage={perPage}
                searchterms={searchterms}
                searchOptions={[
                    { id: 'stage', options: [
                        { value: 'created', label: 'Created' },
                        { value: 'delivered', label: 'Delivered' },
                        { value: 'reviewed', label: 'Reviewed' },
                        { value: 'accepted', label: 'Accepted' },
                        { value: 'rejected', label: 'Rejected' },
                        { value: 'invoiced', label: 'Invoiced' },
                        { value: 'expired', label: 'Expired' }
                    ] },
                    { id: 'sales_person', options: getSelectOptions({ list: ( users || [] ), keys: { value: 'email', label: 'name' }, sortBy: 'label' }) },
                ]}
                sortByOptions={[
                    { value: 'modified_on-desc', label: 'Recent Modified first' },
                    { value: 'modified_on-asc', label: 'Oldest Modified first' },
                    { value: 'id_sort-desc', label: 'ID ( Newest First )' },
                    { value: 'id_sort-asc', label: 'ID ( Oldest First )' },
                    { value: 'name_sort-asc', label: 'Subject ( A - Z)' },
                    { value: 'name_sort-desc', label: 'Subject ( Z - A )' },
                    { value: 'accounts_linked_sort-asc', label: 'Account Name ( A - Z)' },
                    { value: 'accounts_linked_sort-desc', label: 'Account Name ( Z - A )' },
                    { value: 'potentials_linked_sort-asc', label: 'Potential Name ( A - Z)' },
                    { value: 'potentials_linked_sort-desc', label: 'Potential Name ( Z - A )' }
                ]}
                filterByOptions={this.getRegionOptions()}
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                    </div>
                    ] : null }
                rightButtons={[
                    <ButtonActions 
                        key="actions" 
                        label="Actions"
                        menuContainerStyle={{ width: "200px" }}
                        style={{ marginRight: "5px" }}
                        actions={this.getActions()} />
                    ,
                    <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton>
                ]}
                onFilterByChange={(newFilterBy) => this.handleQueryChange({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.handleQueryChange({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.handleQueryChange({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.handleQueryChange({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page, searchterms, currentTotal, filterBy } = this.props;
        return <Pagination 
                    total={totalCount}
                    currentTotal={currentTotal}
                    searchterms={searchterms}
                    filterBy={filterBy}
                    perPage={perPage} 
                    page={page}
                    doneLoaded={true}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.handleQueryChange({ page: newPage }) } />
    }

    render() {
        const { authData, users } = this.props;
        const { openDeleteModal, deleteModal, openViewModal, allCheck, checked, openComment } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to delete this quote ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            {this.renderTableActions()}
            <Table 
                items={items}
                showCheckbox={true}
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={[
                    { id: 'id', label: 'Quote Number', render: (item) => ( item.id || '' ) },
                    { id: 'name', label: 'Subject', render: (item) => ( item.name || '' ) },
                    { id: 'stage', label: 'Quote Stage', render: (item) => ( item.stage || '' ) },
                    { id: 'potential_label', label: 'Potential Name', render: (item) => ( item.potentials_label && !_isEmpty( item.potentials_label ) ? item.potentials_label : '--' ) },
                    { id: 'account_label', label: 'Account Name', render: (item) => ( item.accounts_label && !_isEmpty( item.accounts_label ) ? item.accounts_label : '--' ) },
                    { id: 'grand_total', label: 'Total', render: (item) => ( item.grand_total && !_isEmpty( item.grand_total ) ? ( item.currency && !_isEmpty( item.currency ) ? item.currency : '' )+item.grand_total : '---' ) },
                    { id: 'sales_person', label: 'Sales Person', render: this.renderCell('sales_person') },
                ]}
                actionStyles={{ width: "10%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <AInfoLink key="edit" href={"/quotes/"+item.id} size="small" onClick={this.handleEdit.bind(this,item.id)}><i className="fa fa-edit"></i>Edit</AInfoLink>
                        { authData && hasAccessRights( authData, ['qtd'] ) ? <ErrorButton key="delete" size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton> : null }
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}

            <MassComment 
                refer_type="quote"
                open={openComment}
                selected={( checked || [] )}
                onClose={() => this.setState({ openComment: false })}
                onCompleted={() => {
                    // reset modal, and selector
                    this.setState({ openComment: false, checked: [], allCheck: false })
                    // trigger refresh
                    if ( this.props.onRefresh )
                        this.props.onRefresh();
                }} />

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(Quotes);