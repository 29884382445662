/* eslint-disable */
import * as firebase from 'firebase/app';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

/* helper end */

export const fb_getListsFromMailer = () => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'mMailingLists', action: 'get' })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getMailingLists = (callback) => {

    firebase.database()
    .ref("maintenance/mailing_lists")
    .on("value", snapshot => {

        var list = [];

        if ( snapshot.exists() && snapshot.hasChildren() ) {
            snapshot.forEach( childSnapshot => {
                var val = childSnapshot.val(),
                    item = {
                        id: childSnapshot.key,
                        name: val.name || '',
                        domain_name: val.domain_name || '',
                        org: val.org || '',
                        org_label: val.org_label || '',
                        account_tier_1_id: val.account_tier_1_id || ''
                    };

                list.push(item);
            });
        } // end - snapshpt\
        
        callback(list);

    });
    
}

export const fb_addMailingList = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'mMailingLists', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateMailingList = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'mMailingLists', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteMailingList = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'mMailingLists', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}