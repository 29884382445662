import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import ModalView from '../../components/ModalView';
import ButtonActions from '../../components/ButtonActions'
import SnackBarSave from '../../components/SnackBarSave';

import Details from '../InvoicePage/details';

import { InfoButton, GreyButton } from '../../styles/button';

import { isArrayExists, isNumeric } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';
import { doSchemaErrorCheck } from '../../helpers/schemas';
import { newInvoiceItem } from '../../helpers/invoices';
import { getRegionTaxRate } from '../../helpers/quotes';

import { getQuote } from '../../actions/quotes';
import { addNewInvoice, getInvoice, getPreloadInvoiceData } from '../../actions/invoices';
import { getUsersOptions } from '../../actions/users';
import { getSelectables } from '../../actions/m_selectables';
import { getRegions } from '../../actions/m_regions';
import { getCoursePortfolios } from '../../actions/m_course_portfolio';
import { appChangesMade, resetRedux } from '../../actions/misc';
import { getInvoiceType } from '../../actions/m_invoice_type';
import { getPaymentTerms } from '../../actions/m_payment_terms';

import { invoiceSchema } from '../../schemas/invoice';
import { quoteSchema } from '../../schemas/quote';

import { FIREBASE_STORAGE_URL } from '../../constants';

const getDefaultInvoice = () => {
    var invoice = {};

    invoiceSchema.forEach(schema => {
        invoice[schema.id] = ( schema.default || null );
    });

    // add empty row for products if needed
    if ( !( invoice.products_linked && isArrayExists( invoice.products_linked ) ) ) {
        invoice.products_linked = [];
        invoice.products_linked.push( newInvoiceItem(0) );
    } // end - invoice

    return invoice
}

class InvoiceNewPage extends React.Component {

    state = {
        invoice: getDefaultInvoice(),
        randNum: false
    };

    componentDidMount() {
        const { regionsList, selectablesList, coursePortfolioList, invoiceTypeList, paymentTermsList, usersOptions, mainRand, authData } = this.props;
        const { action, id, account, contact } = this.getParams();

        // get regions
        if ( !regionsList )
            this.props.dispatch(getRegions());

        // get selectables
        if ( !selectablesList )
            this.props.dispatch(getSelectables());

        // get all users
        if ( !usersOptions )
            this.props.dispatch(getUsersOptions());

        // get course portfolio
        if ( !coursePortfolioList )
            this.props.dispatch(getCoursePortfolios());

        // get invoice type
        if ( !invoiceTypeList )
            this.props.dispatch(getInvoiceType());

        // get payment terms
        if ( !paymentTermsList )
            this.props.dispatch(getPaymentTerms());


        if ( action && ( action === 'duplicate' || action === 'convert' ) && id && !_isEmpty( id ) ) {
            if ( action === 'duplicate' ) {
                this.props.dispatch(getInvoice(id));
            } else {
                this.props.dispatch(getQuote(id));
            } // end - action
            // trigger changes made
            this.props.dispatch(appChangesMade());
        } else if ( action && ( action === 'preload' ) && (
            ( account && !_isEmpty( account ) ) || 
            ( contact && !_isEmpty( contact ) )
        ) ) {
            this.props.dispatch(getPreloadInvoiceData({ account_id: account, contact_id: contact, authData }));
            // trigger changes made
            this.props.dispatch(appChangesMade());
        } else if ( regionsList && selectablesList && coursePortfolioList && invoiceTypeList && paymentTermsList && usersOptions && mainRand ) {
            this.setState({ randNum: mainRand });
        } // end - currentQuote
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentInvoice, currentQuote, regionsList, selectablesList, coursePortfolioList, invoiceTypeList, paymentTermsList, usersOptions, randNum, quoteRand, mainRand } = this.props;
        const { action, id, account, contact } = this.getParams();

        if ( action && ( action === 'duplicate' || action === 'convert' ) && id && !_isEmpty( id ) ) {
            if ( action === 'duplicate' && currentInvoice && regionsList && selectablesList && coursePortfolioList && invoiceTypeList && paymentTermsList && usersOptions && randNum && randNum !== this.state.randNum ) {
                this.setState({ invoice: this.getDuplicateInvoiceData(currentInvoice), randNum });
            } else if ( action === 'convert' && currentQuote && regionsList && selectablesList && coursePortfolioList && invoiceTypeList && paymentTermsList && usersOptions && quoteRand && quoteRand !== this.state.randNum ) {
                this.setState({ invoice: this.getQuoteData(currentQuote), randNum: quoteRand });
            } // end - currentQuote
        } else if ( action && ( action === 'preload' ) && (
            ( account && !_isEmpty( account ) ) || 
            ( contact && !_isEmpty( contact ) )
        ) ) {
            if ( currentInvoice && regionsList && selectablesList && coursePortfolioList && invoiceTypeList && paymentTermsList && usersOptions && randNum && randNum !== this.state.randNum ) {
                this.setState({ invoice: this.getDuplicateInvoiceData(currentInvoice), randNum });
            }
        } else {
            // for invoice
            if ( regionsList && selectablesList && coursePortfolioList && invoiceTypeList && paymentTermsList && usersOptions && mainRand && mainRand !== this.state.randNum ) {
                this.setState({ randNum: mainRand });
            } // end - currentQuote
        }
        
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('invoice'));
        this.props.dispatch(resetRedux('quote'));
    }

    handleSaveChanges = (event) => {
        const { history, authData, regionsList } = this.props;
        const { invoice } = this.state;
        event.preventDefault();
        var error = false,
            formData = {};

        // do product error check
        if ( invoice && invoice.products_linked && isArrayExists( invoice.products_linked ) ) {
            _forEach( invoice.products_linked, product => {
                if ( !( product && product.product_id && !_isEmpty( product.product_id ) ) )
                    error = 'One of the product inserted is invalid';
            });
        } else {
            error = 'Please insert at least one valid product';
        } // end - invoice.products_linked

        // do error check
        invoiceSchema.forEach(schema => {
            formData[schema.id] = ( invoice && invoice[schema.id] ? cloneCollections( invoice[schema.id] ) : schema.default );

            // insert invoice_date if is empty
            if ( schema.id === 'invoice_date' && !formData[schema.id]  ) {
                formData[schema.id] = moment().utcOffset(8).valueOf();
            } // end - schema.id

            // insert default value if is empty
            if ( schema.id === 'issued_by' && !( formData[schema.id] && !_isEmpty( formData[schema.id] ) ) ) {
                formData[schema.id] = ( authData && authData.email || '' );
            } // end - schema.id

            // insert company_details if is empty
            if ( schema.id === 'company_details' && !( formData[schema.id] && !_isEmpty( formData[schema.id] ) ) && authData && authData.region && !_isEmpty( authData.region ) ) {
                formData[schema.id] = authData.region;
            } // end - formData[schema.id]

            if ( !doSchemaErrorCheck( formData[schema.id], schema, 'add' ) ) {
                error = 'Please fill out "' + schema.label + '" field';
            }
        });

        // add tax rate if is empty
        if ( !( formData.custom_tax_rate && isNumeric( formData.custom_tax_rate ) ) ) {
            formData.custom_tax_rate = getRegionTaxRate(formData, regionsList).toString();
        }

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addNewInvoice(formData,history));
        } // end - error

    }

    handleFormUpdate = (newValue) => {
        this.setState({ invoice: newValue });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    getDuplicateInvoiceData = (val) => {
        var newData = {};

        // get value based on schema
        invoiceSchema.forEach(schema => {
            newData[schema.id] = ( val && val[schema.id] ? cloneCollections( val[schema.id] ) : schema.default );
        });

        return newData;
    }

    getQuoteData = (val) => {
        var newData = {};

        // get value based on schema
        invoiceSchema.forEach(schema => {
            switch( schema.id ) {
                case 'quote_id':
                    newData[schema.id] = ( val && val['id'] ? cloneCollections( val['id'] ) : schema.default );
                    break;
                case 'end_user_company_linked':
                    newData[schema.id] = ( val && val['accounts_linked'] ? cloneCollections( val['accounts_linked'] ) : schema.default );
                    break;
                default:
                    newData[schema.id] = ( val && val[schema.id] ? cloneCollections( val[schema.id] ) : schema.default );
                    break;
            }
        });

        return newData;
    }

    getParams = () => {
        return queryString.parse( this.props.location.search );
    }

    renderdetails = () => {
        const { authData, usersOptions, regionsList, selectablesList, coursePortfolioList, invoiceTypeList, paymentTermsList, changesMade } = this.props;
        const { invoice } = this.state;
        return <Details 
                isAddNew={true}
                authData={( authData || false )}
                invoice={( invoice || false )}
                regions={( regionsList || [] )}
                course_portfolio={( coursePortfolioList || [] )}
                selectables={( selectablesList || [] )}
                invoice_type={( invoiceTypeList || [] )}
                payment_terms={( paymentTermsList || [] )}
                changesMade={( changesMade || false )}
                onFormUpdate={this.handleFormUpdate}
                users={( usersOptions || [] )} />;
    }

    renderContents() {
        return (
            <div>

            <Paper elevation={3} style={{ padding: "45px 30px", background: "#fff", marginTop: "15px" }}>
                {this.renderdetails()}
            </Paper>

            <Grid container style={{ marginTop: "45px" }}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
                </Grid>
            </Grid>

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { randNum } = this.state;
        return <AppWrapper 
                title="Add New Invoice"
                subtitle="Invoices"
                back="/invoices"
                breadcrumbs={[
                    { url: '/invoices', label: 'Invoices' },
                    { label: 'Add New' }
                ]}
                customColumn={null}
                onLoad={( !( randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentInvoice: state.invoices && state.invoices.invoice || null,
        randNum: state.invoices && state.invoices.rand || null,
        currentQuote: state.quotes && state.quotes.quote || null,
        quoteRand: state.quotes && state.quotes.rand || null,
        usersOptions: state.users && state.users.users_options || null,
        selectablesList: state.maintenance && state.maintenance.selectables || null,
        regionsList: state.maintenance && state.maintenance.regions || null,
        coursePortfolioList: state.maintenance && state.maintenance.course_portfolio || null,
        invoiceTypeList: state.maintenance && state.maintenance.invoice_type || null,
        paymentTermsList: state.maintenance && state.maintenance.payment_terms || null,
        mainRand: state.maintenance && state.maintenance.rand || null,
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(InvoiceNewPage);