/* eslint-disable */
import * as firebase from 'firebase/app';

import { getIDToken } from '../auth/fb';
import { doPromise } from '../../helpers/action';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

/* helper end */

export const fb_getCourseProfile = (callback) => {

    firebase.database()
    .ref("maintenance/courses_profile")
    .on("value", snapshot => {

        var list = [];

        if ( snapshot.exists() && snapshot.hasChildren() ) {
            snapshot.forEach( childSnapshot => {
                var val = childSnapshot.val(),
                    item = {
                        id: childSnapshot.key,
                        group_code: val.group_code || '',
                        group_id: val.group_id || '',
                        group_label: val.group_label || '',
                        publish: val.publish || '',
                        status: val.status || '',
                        title: val.title || ''
                    };

                list.push(item);
            });
        } // end - snapshpt\
        
        callback(list);

    });
    
}

export const fb_syncCourseProfile = () => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'sync',
                params: [{ key: 'action', value: 'sync_course_profile' }]
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}