/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _random from 'lodash/random';
import _isEqual from 'lodash/isEqual';

import EditForm from '../../pages/ContactPage/form';

import ModalView from '../ModalView';
import DotsLoader from '../DotsLoader';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { formatMoney } from '../../helpers/number';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { FormBox } from '../../styles/form';

import { contactSchema } from '../../schemas/contact';

import { massEditContact } from '../../actions/contacts';

const useStyles = theme => ({
    closeBtn: {
        position: 'absolute',
        top: '0',
        right: '0',
        padding: '5px 8px',
        borderRadius: '0px'
    }
});

class ModalMassEditContacts extends React.Component {

    state = {
        contact: {}
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { open } = this.props;

        if ( !prevProps.open && open ) {
            this.setState({ contact: {} });
        } // end - open
            
    }

    handleMassUpdate = () => {
        const { selected, query } = this.props;
        const { contact } = this.state;
        var error = false,
            formData = ( contact && isObjectExists( contact ) ? cloneCollections( contact ) : {} );

        if ( _isEmpty( formData ) )
            error = 'Please make at least one changes';

        if ( !( selected && isArrayExists( selected ) ) )
            error = 'Please select at least one contact.';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(massEditContact(selected,formData,query));
        } // end - error
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose ) {
            this.setState({ contact: {} });
            onClose();
        }
    }

    reorganizeData = () => {

    }

    renderForm = () => {
        const { authData, selectablesList, leadSourceList, mailingList } = this.props;
        const { contact } = this.state;
        return <EditForm
            massEditMode={true}
            authData={( authData || false )}
            contact={( contact || false )}
            users={null} 
            selectables={( selectablesList || [] )}
            lead_source={( leadSourceList || [] )}
            mailing_lists={( mailingList || [] )}
            disableSystemDate={true}
            onFormUpdate={(newValue) => this.setState({ contact: newValue })} />
    }


    renderModal = () => {
        const { selected, classes } = this.props;
        return (
        <div>
            {this.renderForm()}            
            <InverseButton className={classes.closeBtn} noIconMargin="yes" size="small" minWidth="0px" onClick={this.handleClose}><i className="fa fa-times"></i></InverseButton>
        </div>
        )
    }

    render() {
        const { open } = this.props;
        return <ModalView 
            open={open}
            title="Edit Selected Contact(s)"
            actionLabel="Update"
            maxWidth="lg"
            cancelLabel="Close"
            disableBackdrop={true}
            onClose={this.handleClose}
            doAction={this.handleMassUpdate}
            contents={ open ? this.renderModal() : null } />
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        selectablesList: state.maintenance && state.maintenance.selectables || null,
        leadSourceList: state.maintenance && state.maintenance.lead_source || null,
        mailingList: state.maintenance && state.maintenance.mailing_lists || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles)
)(ModalMassEditContacts);