/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import shortid from 'shortid';
import styled from "styled-components";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _size from 'lodash/size';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _forEach from 'lodash/forEach';

import Table from '../Table';
import ModalView from '../ModalView';
import ModalDelete from '../ModalDelete';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';
import FormRadio from '../FormRadio';
import FormSelect2 from '../FormSelect2';
import FormMultiSelect from '../FormMultiSelect';
import FormDatePicker from '../FormDatePicker';
import FormCheckbox from '../FormCheckbox';

import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, movePosition, calculatePosition } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton, SuccessButton } from '../../styles/button';

const TableWrapper = styled.div`
    display: block;
    padding: 30px 0px 0px 0px;

    .MuiPaper-elevation2 { box-shadow: none; border-radius: 0px; }
    .MuiTable-root { border: 1px solid #999; }
    .MuiTableCell-root { 
        border-color: #999; 
        padding: 8px; 
        &.MuiTableCell-head { 
            font-size: 14px; 
            background: #f1f1f1;
        }
    }
`;

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '14px',
        verticalAlign: 'top'
    }
});

class FormMultiOptions extends React.Component {

    state = {
        openModal: false,
        modalType: false,
        selectItem: false
    };

    handleAction = () => {
        const { modalType, selectItem } = this.state;
        const { onChange, name, cells, value, moveable } = this.props;

        switch( modalType ) {
            case 'new':
            case 'edit':
                // do error check
                var error = false;

                if ( cells && isArrayExists( cells ) ) {
                    _forEach( cells, cell => {
                        if ( this.isRequired(cell) ) {
                            if ( !( selectItem[cell.id] && !_isEmpty( selectItem[cell.id] ) ) )
                                error = 'Please fill out all the required field(s).'
                        } // end - required
                    });
                } // end - cells

                if ( error ) {
                    triggerErrorAlert(error);
                } else {
                    // add or update
                    var newValue = ( value ? cloneCollections( value ) : [] );
                    if ( modalType === 'new' ) {
                        newValue.push( selectItem );
                    } else {
                        let index = _findIndex( newValue, { id: selectItem.id } );
                        newValue[index] = selectItem;
                    } // end - modalType

                    // push updates
                    if ( onChange )
                        onChange( newValue, name );

                    // reset modal
                    this.setState({ openModal: true, modalType: false, selectItem: false });
                } // end - error
                break;
            case 'delete':
                if ( selectItem && selectItem.id && value && isArrayExists( value ) && _find( value, { id: selectItem.id }) ) {
                    var newValue = ( value ? cloneCollections( value ) : [] ),
                        pulled = _remove( newValue, { id: selectItem.id });

                    // if movable - recalculate position
                    if ( moveable ) {
                        newValue = calculatePosition( newValue );
                    } // end - movable

                    // push updates
                    if ( onChange )
                        onChange( newValue, name );

                    // reset modal
                    this.setState({ openModal: true, modalType: false, selectItem: false });
                } // end - selectItem
                break;
        } // end - modalType

    }

    handleFormUpdate = (newValue,key,subkey,subVal) => {
        const { cells } = this.props;
        const { selectItem } = this.state;
        let newData = ( selectItem ? cloneCollections( selectItem ) : {}),
            cell = _find( cells, { id: key });
        // if need to update value externally
        if ( cell && cell.onValueUpdated ) {
            newData = cell.onValueUpdated({ formData: newData, newValue, key, item: selectItem, cell, subkey, subVal });
        } else {  
            if ( subkey && ( subkey === 'checked' || subkey === 'unchecked' ) && subVal && !_isEmpty( subVal ) ) {
                // use subVal as the new value
                newData[key] = subVal;
            } else {
                // otherwise - just update normally
                newData[key] = newValue;
            }
        } // end - newData[key] = newValue;
        this.setState({ selectItem: newData });
    }

    handlePositionMove = ( item, direction ) => event => {
        const { value, name, onChange } = this.props;
        var newValue = ( value ? cloneCollections( value ) : [] ),
            currentPosition = ( item.position ? item.position : 1 ),
            newPosition = ( direction && direction === 'up' ? currentPosition - 1 : currentPosition + 1 );

        // make sure new position don't exceed more that array size, and not below 1
        if ( newPosition > _size( value ) )
            newPosition = _size( value );

        if ( !newPosition )
            newPosition = 1;

        // move position
        newValue = movePosition( newValue, currentPosition, newPosition );

        // push updates
        if ( onChange )
            onChange( newValue, name );
    }

    getDefaultValue = () => {
        const { cells, moveable, value } = this.props;
        var val = { id: shortid.generate() };

        // calculate position
        if ( moveable ) {
            val.position = ( value && isArrayExists( value ) ? _size( value ) : 0 ) + 1;
        } // end - moveable

        // populate cell value
        if ( cells && isArrayExists( cells ) ) {
            _forEach( cells, cell => {
                val[cell.id] = ( cell.defaultVal || '' );
            });
        } // end - cells

        return val;
    }

    getFieldprops = (cell) => {
        const { selectItem } = this.state;
        let props = {
            label: ( cell.label || '' ) + ( this.isRequired(cell) ? ' (required)' : '' ),
            name: ( cell.id || '' ),
            value: ( selectItem && cell.id && selectItem[cell.id] ? selectItem[cell.id] : '' ),
            options: ( cell.getOptions ? cell.getOptions(selectItem) : ( cell.options && isArrayExists( cell.options ) ? cell.options : null ) )
        };
        return props;
    }

    isRequired = (cell) => {
        const { selectItem } = this.state;
        return ( cell && ( cell.required || ( cell.isRequired && cell.isRequired({ item: selectItem, cell }) ) ) ? true : false )
    }

    renderField = (cell) => {
        const { selectItem } = this.state;
        if ( cell && cell.field && !_isEmpty( cell.field ) ) {
            let field = this.getFieldprops( cell );
            switch( cell.field ) {
                case 'text':
                    return <FormInput {...field} onChange={this.handleFormUpdate} />;
                case 'text_number':
                    return <FormInput {...field} type="number" onChange={this.handleFormUpdate} />;
                case 'email':
                    return <FormInput {...field} type="email" onChange={this.handleFormUpdate} />;
                case 'select2':
                    return <div style={{ paddingTop: "10px" }}><FormSelect2 placeholder="Select an Option" {...field} onChange={this.handleFormUpdate} /></div>;
                case 'select':
                    return <div style={{ paddingTop: "10px" }}><FormSelect {...field} onChange={this.handleFormUpdate} /></div>;
                case 'radio':
                    return <div style={{ paddingTop: "10px" }}><FormRadio {...field} inline={true} onChange={this.handleFormUpdate} /></div>;
                case 'multiselect':
                    return <div style={{ paddingTop: "10px" }}><FormMultiSelect {...field} onChange={this.handleFormUpdate} /></div>;
                case 'datepicker':
                    return <FormDatePicker {...field} noDefaultVal={true} onChange={this.handleFormUpdate} />;
                case 'checkbox':
                    return <FormCheckbox {...field} checked={( cell.checked ? cell.checked : 'yes' )} unchecked={( cell.unchecked ? cell.unchecked : 'no' )} onChange={this.handleFormUpdate} />;
                case 'custom':
                    return ( cell.renderCustomField ? cell.renderCustomField({ item: selectItem, field, cell, onChange: this.handleFormUpdate }) : null );
            }
        } // end - cell.field_type
    }

    renderModal = () => {
        const { cells } = this.props;
        const { openModal, modalType } = this.state;
        return (
        <div>
            <Grid container spacing={1}>
                { cells && isArrayExists( cells ) ? _map( cells, (cell) => <Grid key={cell.id} item xs={( cell.column || 12 )}>{this.renderField(cell)}</Grid> ) : null }
            </Grid>
        </div>
        );
    }

    renderAddNewButton = () => {
        const { onAction, isDisabled } = this.props;
        return (
        <div style={{ padding: '10px 0 0 5px' }}>
            <InfoButton key="select" disabled={( isDisabled ? 'yes' : false )} size="small" onClick={() => this.setState({ openModal: true, modalType: 'new', selectItem: this.getDefaultValue() })} style={{ borderRadius: '25px', padding: '8px 25px' }}><i className="fa fa-plus"></i>Add New</InfoButton>
        </div>
        )
    }

    renderTable = () => {
        const { value, onAction, isDisabled } = this.props;
        const { cells, moveable } = this.props;
        return <Table 
            items={( value && isArrayExists( value ) ? ( moveable ? _sortBy( value, ['position'] ) : value ) : [] )}
            showCheckbox={false}
            cells={ cells && isArrayExists( cells ) ? cells : [] }
            //emptyCell="abc"
            actionStyles={{ width: "25%" }}
            actions={(item) => (
                <ButtonGroup>
                    <InfoButton key="add" disabled={( isDisabled ? 'yes' : false )} size="small" minWidth="none" noIconMargin="yes" onClick={() => this.setState({ openModal: true, modalType: 'edit', selectItem: item })}><i className="fa fa-edit"></i></InfoButton>
                    { moveable ? <SuccessButton key="moveup" size="small" minWidth="none" noIconMargin="yes" disabled={( isDisabled || ( item.position && item.position < 2 ) ? 'yes' : false )} onClick={this.handlePositionMove(item,'up')}><i className="fa fa-angle-up"></i></SuccessButton> : null }
                    { moveable ? <SuccessButton key="movedown" size="small" minWidth="none" noIconMargin="yes" disabled={( isDisabled || ( item.position && item.position >= _size( value ) ) ? 'yes' : false )} onClick={this.handlePositionMove(item,'down')}><i className="fa fa-angle-down"></i></SuccessButton> : null }
                    <ErrorButton key="delete" disabled={( isDisabled ? 'yes' : false )} size="small" minWidth="none" noIconMargin="yes" onClick={() => this.setState({ openModal: true, modalType: 'delete', selectItem: item })}><i className="fa fa-trash"></i></ErrorButton>
                </ButtonGroup>
            )} />
    }

    render() {
        const { id, label, labelProps } = this.props;
        const { openModal, modalType } = this.state;
        var newID = 'multi-options-' + _random(1,9999);
        return (
        <div>

            <FormControl fullWidth={true}>
                <InputLabel shrink={true} htmlFor={( id || newID )} style={{ fontSize: "16px", fontWeight: "700", textTransform: "uppercase" }}>{label || '' }</InputLabel>
                <TableWrapper>
                    {this.renderTable()}
                    {this.renderAddNewButton()}
                </TableWrapper>
            </FormControl>

            <ModalView 
                open={( openModal && modalType && ( modalType === 'new' || modalType === 'edit' ) ? true : false )}
                title={( openModal && modalType && modalType === 'edit' ? 'Edit Item' : 'Add New Item' )}
                actionLabel={( openModal && modalType && modalType === 'edit' ? 'Update' : 'Add New' )}
                cancelLabel="Close"
                doAction={this.handleAction}
                onClose={() => this.setState({ openModal: true, modalType: false, selectItem: false })}
                contents={this.renderModal()} />

            <ModalDelete
                open={( openModal && modalType && modalType === 'delete' ? true : false )}
                title="Are you sure you want to delete this item?"
                onClose={() => this.setState({ openModal: true, modalType: false, selectItem: false })}
                onDelete={this.handleAction} />

        </div>
        )
    }

}

export default compose(
    withStyles(useStyles)
)(FormMultiOptions);