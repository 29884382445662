/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';
import _last from 'lodash/last';
import _merge from 'lodash/merge';

import {
	GET_ACCOUNTS,
	GET_ACCOUNTS_BY,
	GET_ACCOUNTS_OPTIONS,
	GET_ACCOUNT,
	ADD_ACCOUNT,
	EDIT_ACCOUNT,
	DELETE_ACCOUNT,
	GET_DUPLICATES_ACCOUNT,
	FIND_DUPLICATES_ACCOUNT,
	MERGE_ACCOUNTS,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections } from '../../helpers/data';
import { isArrayExists } from '../../helpers/validation';
import { appChangesReset } from '../misc';

import { fb_getAccounts, fb_getAccountsFirstBatch, fb_getAccountsForTheRest, fb_getAccountsBy, fb_getAccountsOptions, fb_getAccount, fb_addAccount, fb_updateAccount, fb_massEditAccount, fb_deleteAccount, fb_mergeAccounts } from './fb';
import { fb_getDuplicateAccounts, fb_findDuplicateAccounts } from './duplicates';

/* helper start */
// const getAccountsByBatch = (dispatch,props,callback) => {
// 	const { perPage } = props;
// 	var mainList = [];

// 	fb_getAccountsFirstBatch(props)
// 	.then(({ list, accessLimited }) => {

// 		var endAt = false;
// 		mainList = cloneCollections( list );

// 		// calculate endAt
// 		if ( isArrayExists( mainList ) ) {
// 			let lastItem = _last( mainList );
// 			if ( lastItem && lastItem.modified_on ) {
// 				endAt = lastItem.modified_on;
// 			} // end - lastItem
// 		} // end - mainList

// 		// dispatch first batch
// 		dispatch({
// 			type: GET_ACCOUNTS,
// 			payload: { list, loaded: ( !accessLimited && isArrayExists( mainList ) && endAt ? false : true ) }
// 		});

// 		// trigger callback
// 		if ( callback ) {
// 			callback( dispatch );
// 		} // end - callback

// 		return ( !accessLimited && isArrayExists( mainList ) && endAt ? fb_getAccountsForTheRest( endAt ) : false );
// 	})
// 	.then(list => {

// 		if ( list && isArrayExists( list ) ) {
// 			// dispatch other items
// 			dispatch({
// 				type: GET_ACCOUNTS,
// 				payload: { list: _merge( mainList, list ), loaded: true }
// 			});
// 		} // end - list
	
// 		// else - do nothing
// 	})
// 	.catch(error => {
// 		var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
// 		triggerErrorAlert(errMsg);
// 		dispatch({
// 			type: GET_ACCOUNTS,
// 			payload: { list: [] }
// 		});
// 	});

// }
/* helper end */

export const getAccounts = (props) => {
	return dispatch => {

		// probably don't need this anymore
		//getAccountsByBatch( dispatch, ( props || {} ) );
		
		fb_getAccounts(props)  
		.then(({ list, total, firstDoc, lastDoc }) => {
			dispatch({
				type: GET_ACCOUNTS,
				payload: { list, total, firstDoc, lastDoc }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_ACCOUNTS,
				payload: { list: [] }
			});
		});

	}
}

export const getAccountsBy = (type,id) => {
	return dispatch => {

		fb_getAccountsBy(type,id)  
		.then(list => {
			dispatch({
				type: GET_ACCOUNTS_BY,
				payload: { list }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_ACCOUNTS_BY,
				payload: { list: {} }
			});
		});

	}
}

export const getAccountsOptions = (authData) => {
	return dispatch => {
		
		fb_getAccountsOptions(list => {
			dispatch({
				type: GET_ACCOUNTS_OPTIONS,
				payload: { list }
			});	
		},authData);

	}
}

export const getAccount = (account_id) => {
	return dispatch => {

		fb_getAccount(account_id)  
		.then(account => {
			dispatch({
				type: GET_ACCOUNT,
				payload: { account }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_ACCOUNT,
				payload: { account: {} }
			});
		});

	}
}

export const addNewAccount = (formData,history) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addAccount(formData)
		.then( results => {
			if ( results && results.new_account_id && !_isEmpty( results.new_account_id ) ) {

				fb_getAccount( results.new_account_id )
				.then( account => {

					// updated
					dispatch({ type: GET_ACCOUNT, payload: { account } });
					dispatch(toggleModalProcessing(false));
					triggerSuccessAlert("Account Added");

					// redirect to account page
					history.push("/accounts/" + results.new_account_id);

				});

			} else {
				dispatch(toggleModalProcessing(false,apiNum));
				triggerErrorAlert('Missing new account ID');
			}
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new account' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const editAccount = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateAccount(formData)
		.then( results => {

			fb_getAccount(formData.id)
			.then(account => {
				// push to list
				dispatch({ type: GET_ACCOUNT, payload: { account } });

				// updated
				dispatch({ type: EDIT_ACCOUNT });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Account Updated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update account' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const massEditAccount = (selected,formData,props) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));
		dispatch(toggleLoader(true));

		fb_massEditAccount(selected,formData)
		.then( results => {

			// setTimeout(() => {
			// 	// retrieve new list
			// 	getAccountsByBatch( dispatch, ( props || {} ), (dispatch) => {
			// 		// trigger updated
			// 		dispatch({ type: EDIT_ACCOUNT });
			// 		dispatch(toggleModalProcessing(false));
			// 		dispatch(toggleLoader(false));
			// 		triggerSuccessAlert("Selected account(s) updated");
			// 	});
			// }, 5000);

			// retrieve new list
			fb_getAccounts(props)  
			.then(({ list, total, firstDoc, lastDoc }) => {
				dispatch({
					type: GET_ACCOUNTS,
					payload: { list, total, firstDoc, lastDoc }
				});

				// trigger updated
				dispatch({ type: EDIT_ACCOUNT });
				dispatch(toggleModalProcessing(false));
				dispatch(toggleLoader(false));
				triggerSuccessAlert("Selected account(s) updated");

			});
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update account' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteAccount = (id,props) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteAccount(id)
		.then( results => {

			// setTimeout(() => {
			// 	// retrieve new list
			// 	getAccountsByBatch( dispatch, ( props || {} ), (dispatch) => {
			// 		// trigger updated
			// 		dispatch({ type: DELETE_ACCOUNT });
			// 		dispatch(toggleModalDeleting(false));
			// 		triggerSuccessAlert("Account Deleted");
			// 	});
			// }, 5000);

			// get updated list
			fb_getAccounts(props)  
			.then(({ list, total, firstDoc, lastDoc }) => {
				// dispatch updated list
				dispatch({
					type: GET_ACCOUNTS,
					payload: { list, total, firstDoc, lastDoc }
				});

				// trigger updated
				dispatch({ type: DELETE_ACCOUNT });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("Account Deleted");
			});
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete list' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

/* duplicate functions */

export const getDuplicatesAccount = () => {
	return dispatch => {

		fb_getDuplicateAccounts()  
		.then(data => {
			dispatch({
				type: GET_DUPLICATES_ACCOUNT,
				payload: { data }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_DUPLICATES_ACCOUNT,
				payload: { data: false }
			});
		});

	}
}

export const findDuplicatesAccount = (field) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_findDuplicateAccounts(field)  
		.then(data => {
			dispatch(toggleLoader(false));
			dispatch({
				type: FIND_DUPLICATES_ACCOUNT,
				payload: { data }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			dispatch(toggleLoader(false));
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_DUPLICATES_ACCOUNT,
				payload: { data: false }
			});
		});

	}
}

/* merge functions */
export const mergeAccounts = (selected,formData,props) => {
	return dispatch => {
		var apiNum = _random(1,9999);
			dispatch(toggleModalProcessing(true,apiNum));
			dispatch(toggleLoader(true));

			fb_mergeAccounts(selected,formData)
			.then( results => {

				// retrieve new list
				fb_getAccounts(props)  
				.then(({ list, total, firstDoc, lastDoc }) => {
					dispatch({
						type: GET_ACCOUNTS,
						payload: { list, total, firstDoc, lastDoc }
					});

					// trigger updated
					dispatch({ type: EDIT_ACCOUNT });
					dispatch(toggleModalProcessing(false));
					dispatch(toggleLoader(false));
					triggerSuccessAlert("Selected accounts marged");

				});
			})
			.catch( error => {
				dispatch(toggleModalProcessing(false,apiNum));
				dispatch(toggleLoader(false));
				var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to merge accounts' ) );
				triggerErrorAlert(errMsg);
			});

	}
}