/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';

import Table from '../Table';
import Pagination from '../Pagination';
import TableBar from '../TableBar';
import AddNewContact from '../AddNewContact';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton } from '../../styles/button';
// import { SuccessTag, AmberTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { hasLimitedAccess } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
// import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, combineStrings } from '../../helpers/data';
// import { getMomentTime, getMomentTimestamp } from '../../helpers/date';

import { appChangesReset } from '../../actions/misc';

class ContactsList extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        filterBy: 'all',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false
    }

    handlePageRefresh = (event) => {
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh('contacts');
    }

    handleAddNew = (event) => {
        const { changesMade } = this.props;
        event.preventDefault();
        if ( changesMade ) {
            alert("Please save changes first before proceed");
        } else {
            this.setState({ openViewModal: true });
        } // end - changesMade
    }

    handleView = (id,event) => {
        const { changesMade, history, dispatch } = this.props;
        event.preventDefault();
        let url = "/contacts/"+id;
        if ( changesMade ) {
            var answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
            // reset status if answer is yes
            if ( answer ) {
                dispatch(appChangesReset());
                history.push(url);
            }
        } else {
            history.push(url);
        } // end - changesMade
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { contacts } = this.props;
        let items = ( contacts ? cloneCollections( contacts ) : [] ),
            total = _size( items );

        // add in assigned_to_label
        if ( contacts && isArrayExists( contacts ) ) {
            _forEach( contacts, (current,index) => {

                // set label as empty
                items[index].assigned_to_label = '';

                // run through all the IDs to get labels
                if ( current && current.assigned_to && isArrayExists( current.assigned_to ) ) {
                    _forEach( current.assigned_to, u => {
                        items[index].assigned_to_label += ( !_isEmpty( items[index].assigned_to_label ) ? ' ' : '' ) + ( u && u.name ? u.name : u.id );  
                    });
                } // end - current.assigned_to
                
            });
        } // end - contacts

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','account_label','email','assigned_to_label'] );
			total = _size( items );
        } // end - searchterms

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['modified_on'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['modified_on'] );
                    break;
                case 'email-desc':
                    items = _sortBy( items, ['email'] );
                    items = _reverse( items );
                    break;
                case 'email-asc':
                    items = _sortBy( items, ['email'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, [(i) => combineStrings(i.designation,i.first_name,i.last_name).toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, [(i) => combineStrings(i.designation,i.first_name,i.last_name).toLowerCase()] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderCell = type => item => {
        switch( type ) {
            case 'assigned_to':
                return ( item[type] && isArrayExists( item[type] ) ? item[type].map((u) => (<div key={u.id}>{u.name}</div>) ) : '--' );
            default:
                return <WrapWord>{( item[type] || '' )}</WrapWord>
        }
    }

    renderRightActionButtons = () => {
        const { noAddNew } = this.props;
        var rightButtons = [];

        // add add new contact button
        if ( !noAddNew ) {
            rightButtons.push( <InfoButton minWidth="128px" key="addnew" style={{ marginRight: "5px" }} onClick={this.handleAddNew}><i className="fa fa-plus-circle"></i>Add New</InfoButton> );
        } // end - noAddNew

        rightButtons.push( <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton> );

        return rightButtons;
    }

    renderTableActions = () => {
        const { authData } = this.props;
        const { sortBy, perPage, searchterms } = this.state;
        return <TableBar
                limitedPerPage={( hasLimitedAccess(authData) ? true : false )}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Modified first' },
                    { value: 'date-asc', label: 'Oldest Modified first' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' },
                    { value: 'email-asc', label: 'Email ( A - Z)' },
                    { value: 'email-desc', label: 'Email ( Z - A )' }
                ]}
                rightButtons={this.renderRightActionButtons()}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    doneLoaded={true}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { noAddNew, authData, users, account, mailing_lists } = this.props;
        const { openViewModal } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            {this.renderTableActions()}
            <Table 
                noSelect={( hasLimitedAccess(authData) ? true : false )}
                items={items}
                showCheckbox={false}
                cells={[
                    { id: 'name', label: 'Name', render: (item) => combineStrings( item.designation, item.first_name, item.last_name ) },
                    { id: 'account_label', label: 'Account', render: (item) => ( item.account_label || '' ) },
                    { id: 'email', label: 'Email', render: this.renderCell('email') },
                    { id: 'office_phone', label: 'Office Phone', render: this.renderCell('office_phone') },
                    { id: 'mobile_phone', label: 'Mobile Phone', render: this.renderCell('mobile_phone') },
                    { id: 'assigned_to', label: 'Assigned To', render: this.renderCell('assigned_to') },
                ]}
                actionStyles={{ width: "10%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <AInfoLink key="view" href={"/contacts/"+item.id} size="small" onClick={this.handleView.bind(this,item.id)}><i className="fa fa-search"></i>View</AInfoLink>
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}

            { noAddNew ? null : <AddNewContact
                authData={authData}
                open={openViewModal}
                users={( users || [] )}
                mailing_lists={( mailing_lists || [] )}
                predefined_account={( account || null )}
                accounts={( account ? [{ id: account.id, name: account.name }] : [] )}
                onClose={() => this.setState({ openViewModal: false })} /> }

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(ContactsList);