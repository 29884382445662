/* eslint-disable */
import * as firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _snakeCase from 'lodash/snakeCase';
import _find from 'lodash/find';

import { doesUserHaveAccess, hasLimitedAccess } from '../../helpers/auth';
import { callFunctionsAPI, checkIfUserAccessLimited } from '../../helpers/action';
import { isArrayExists, isObjectExists, validateEmail } from '../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema } from '../../helpers/schemas';
import { getItemsFromFirestore, getTotalCountBySchema } from '../../helpers/firestore';
import { isValidUser } from '../../helpers/users';
import { newQuoteItem } from '../../helpers/quotes';

import { creditNoteSchema } from '../../schemas/credit_note';
import { commentSchema } from '../../schemas/comment';
import { logSchema } from '../../schemas/log';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

const getCreditNoteOptionData = (credit_note) => {
    return new Promise((resolve,reject) => {

        const promises = [];

        if ( credit_note && credit_note.accounts_linked && isArrayExists( credit_note.accounts_linked ) && credit_note.accounts_linked[0] && credit_note.accounts_linked[0].id && !_isEmpty( credit_note.accounts_linked[0].id ) ) {
            promises.push( firebase.database().ref("maintenance/options/accounts/"+credit_note.accounts_linked[0].id).once("value") );
        }

        if ( credit_note && credit_note.contacts_linked && isArrayExists( credit_note.contacts_linked ) && credit_note.contacts_linked[0] && credit_note.contacts_linked[0].id && !_isEmpty( credit_note.contacts_linked[0].id ) ) {
            promises.push( firebase.database().ref("maintenance/options/contacts/"+credit_note.contacts_linked[0].id).once("value") );
        }

        // for end user company - only if it's different from account
        // do nothing for now

        if ( isArrayExists( promises ) ) {
            Promise.all( promises )
            .then((results) => {

                if ( results && isArrayExists( results ) ) {
                    _forEach( results, result => {
                        if ( result && result.exists() ) {
                            const refPath = result.ref.toString();

                            if ( credit_note && credit_note.accounts_linked && isArrayExists( credit_note.accounts_linked ) && credit_note.accounts_linked[0] && credit_note.accounts_linked[0].id && !_isEmpty( credit_note.accounts_linked[0].id ) && refPath.indexOf("maintenance/options/accounts/"+credit_note.accounts_linked[0].id) >= 0 ) {
                                const accountOption = result.val();
                                if ( accountOption && accountOption.name && !_isEmpty( accountOption.name ) ) {
                                    credit_note.accounts_linked[0].name = accountOption.name;
                                }
                            }

                            if ( refPath.indexOf("maintenance/options/contacts/") >= 0 ) {
                                const contactOption = result.val();
                                if ( credit_note && credit_note.contacts_linked && isArrayExists( credit_note.contacts_linked ) && credit_note.contacts_linked[0] && credit_note.contacts_linked[0].id && !_isEmpty( credit_note.contacts_linked[0].id ) && contactOption && contactOption.name && !_isEmpty( contactOption.name ) ) {
                                    credit_note.contacts_linked[0].name = contactOption.name;
                                }
                            }

                            // for end user company
                            // do nothing for now

                        } // end - result
                    });
                } // end - results

                resolve(credit_note);
            })
            .catch( error => {
                reject(error);
            });
        } else {
            resolve(credit_note);
        }

    })
}

// get a specific data from database and
const getDataFromFirestore = (type,id,authData) => {
    return new Promise((resolve,reject) => {
        
        firebase.firestore().collection(type).doc(id).get()
        .then( doc => {
            var data = ( doc.exists ? doc.data() : false );

            // make sure limited user have the access
            if ( authData && hasLimitedAccess( authData ) ) {
                if ( !doesUserHaveAccess(authData,data) )
                    data = false;
            } // end - authData

            resolve({ type, data });   
        })
        .catch(error => {
            reject(error);
        });

    })
}

// get list from firebase based props
const getFirestoreList = (props) => {
    return new Promise((resolve,reject) => {

        var promises = [];
        promises.push( getItemsFromFirestore( "credit_notes", ( props && isObjectExists( props ) ? props : null ) ) );

        // if no keywords filter
        if ( !( props && props.searchterms && !_isEmpty( props.searchterms ) ) ) {
            promises.push( getTotalCountBySchema('credit_notes') );
        } // end - props.searchterms

        Promise.all( promises )
        .then(results => {
            resolve({ 
                list: ( results && results[0] && results[0].list && isArrayExists( results[0].list ) ? results[0].list : [] ), 
                firstDoc: ( results && results[0] && results[0].firstDoc ? results[0].firstDoc : false ),
                lastDoc: ( results && results[0] && results[0].lastDoc ? results[0].lastDoc : false ), 
                total: ( results && results[1] ? results[1] : 0 )
            });
        })
        .catch(error => {
            // only use it when needed
            // console.log(error);
            reject(error);
        });

    })
} 

// get list based on assigned_to
const getAssignedToList = (user) => {
    return new Promise((resolve,reject) => {

        let ref = firebase.database().ref("credit_notes");
        if ( user && user.id && user.email && validateEmail(user.email) ) {
            ref = ref.orderByChild("assigned_to/"+user.id+"/id").equalTo(user.email);
        }

        ref.once("value")
        .then(snapshot => {

            let list = [];

            if ( snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach( childSnapshot => {
                    let item = getSnapshotDataBySchema( creditNoteSchema, childSnapshot );
                    list.push(item);
                });
            } // end - snapshot

            resolve({ list });

        })
        .catch(error => {
            reject(error);
        });
    })
}

/* helper end */

export const fb_getCreditNotes = (props) => {
    return new Promise((resolve,reject) => {

        checkIfUserAccessLimited()
        .then(user => {
            return ( isValidUser(user) ? getAssignedToList(user) : getFirestoreList(props) );
        })
        .then(payload => {
            resolve(payload);
        })
        .catch(error => {
            reject(error);
        });        
    
    })
}

export const fb_getCreditNote = (credit_note_id) => {
    return new Promise((resolve,reject) => {
        
        let promises = [];
        promises.push( firebase.firestore().collection("credit_notes").doc(credit_note_id).get() );
        promises.push( firebase.database().ref("comments").orderByChild('refer_id').equalTo(credit_note_id).once("value") );
        promises.push( firebase.database().ref("logs").orderByChild('type_id').equalTo(credit_note_id).once("value") );
        promises.push( checkIfUserAccessLimited() );
        
        Promise.all( promises )
        .then(results => {
            let item = {};

            if ( results && results[0] ) {
                item = getDocDataBySchema( creditNoteSchema, results[0] )
            } // end - results[0]

            // add comments
            item['comments'] = [];
            if ( results && results[1] && results[1].exists() && results[1].hasChildren() ) {
                results[1].forEach( childSnapshot => {
                    let comment = getSnapshotDataBySchema( commentSchema , childSnapshot );
                    item['comments'].push( comment );
                });
            } // end - results[1]

            // add updates
            item['updates'] = [];
            if ( results && results[2] && results[2].exists() && results[2].hasChildren() ) {
                results[2].forEach( childSnapshot => {
                    let update = getSnapshotDataBySchema( logSchema , childSnapshot );
                    item['updates'].push( update );
                });
            } // end - results[2]

            // if is limited user
            if ( results && results[3] && !_isEmpty( results[3] ) ) {
                if ( doesUserHaveAccess( results[3], item ) ) {
                    // do nothing
                    // resolve(item);
                } else {
                    // reset item if don't have access
                    item = {};
                }
            } // end - results
            
            return ( item && isObjectExists(item) ? getCreditNoteOptionData(item) : {} );
        })
        .then(item => {
            resolve(item);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getPreloadCreditNoteData = ({ account_id, contact_id, authData }) => {
    return new Promise((resolve,reject) => {

        let promises = [];

        if ( account_id && !_isEmpty( account_id ) )
            promises.push( getDataFromFirestore( 'accounts', account_id, authData ) );

        if ( contact_id && !_isEmpty( contact_id ) )
            promises.push( getDataFromFirestore( 'contacts', contact_id, authData ) );
        
        Promise.all( promises )
        .then(results => {
            var credit_note = {};

            // add default data
            creditNoteSchema.forEach(schema => {
                credit_note[schema.id] = ( schema.default || null );
            });

            if ( results && isArrayExists( results ) ) {
                var account = _find( results, { type: 'accounts' } ),
                    contact = _find( results, { type: 'contacts' } );

                // add account if available
                if ( account && account.data && account.data.id && !_isEmpty( account.data.id ) ) {
                    var newAccount = {
                        id: account.data.id,
                        name: account.data.name || ''
                    };

                    credit_note.accounts_linked = [];
                    credit_note.accounts_linked.push(newAccount);

                    credit_note.end_user_company_linked = [];
                    credit_note.end_user_company_linked.push(newAccount);

                    // add assigned_to from account to credit_note
                    if ( account && account.data && account.data.assigned_to && isArrayExists( account.data.assigned_to ) )
                        credit_note.assigned_to = account.data.assigned_to;

                } // end - account

                // add contact if Available
                if ( contact && contact.data && contact.data.id && !_isEmpty( contact.data.id ) ) {
                    var newContact = {
                        id: contact.data.id,
                        name: contact.data.name || '',
                        email: contact.data.email || '',
                        account_id: contact.data.account_id || '',
                    };

                    credit_note.contacts_linked = [];
                    credit_note.contacts_linked.push(newContact);
                } // end - contact

            } // end - results

            // add empty row for products if needed
            if ( !( credit_note.products_linked && isArrayExists( credit_note.products_linked ) ) ) {
                credit_note.products_linked = [];
                credit_note.products_linked.push( newQuoteItem(0) );
            } // end - credit_note

            resolve(credit_note);
            
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getCreditNotesBy = (type,id) => {
    return new Promise((resolve,reject) => {

        firebase.database().ref("usage/"+type+"/"+id+"/credit_notes").once("value")
        .then( snapshot => {
            var promises = [];
            if ( snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach( childSnapshot => {
                    promises.push( firebase.database().ref("credit_notes/"+childSnapshot.key).once("value") );
                });
            } // end - snapshpt

            return ( isArrayExists( promises ) ? Promise.all( promises ) : false );
        })
        .then(results => {

            let list = [];

            if ( results && isArrayExists( results ) ) {
                _forEach( results, result => {
                    if ( result && result.exists() ) {
                        let item = getSnapshotDataBySchema( creditNoteSchema, result );
                        list.push(item);
                    } // end - result
                });
            } // end - results

            resolve(list);

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_addCreditNote = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'creditNote', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateCreditNote = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'creditNote', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteCreditNote = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'creditNote', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_generateCreditNotePDF = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'creditNote', action: 'generate_pdf', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_duplicateCreditNote = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'creditNote', action: 'duplicate', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}