import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

/* eslint-disable */

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBarLimitedAccess';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormMultiSelect from '../../components/FormMultiSelect';
import ButtonActions from '../../components/ButtonActions';
import MassComment from '../../components/MassComment';
import MassTransferOwnership from '../../components/MassTransferOwnership';
import ModalMassEditAccounts from '../../components/ModalMassEditAccounts';
import AddNewEmail from '../../components/AddNewEmail';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { SuccessTag, AmberTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { reverseUsersValues } from '../../helpers/users';

import { addNewAccount, deleteAccount } from '../../actions/accounts';

import { accountSchema } from '../../schemas/account';

class Accounts extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        filterBy: 'all',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false,
        openComment: false,
        openTransferownership: false,
        openMassEdit: false, 
        openSendEmail: false
    }

    handlePageRefresh = (event) => {
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh();
    }

    handleFormUpdate = (newValue,key) => {
        const { users } = this.props;
        const { modalData } = this.state;
        var newData = ( modalData ? cloneCollections( modalData ) : {} );
        if ( key === 'assigned_to' ) {
            newData[key] = reverseUsersValues( newValue, users );
        } else {
            newData[key] = newValue;
        } // end - key
        this.setState({ modalData: newData });
    }

    handleAddNew = () => {
        const { history } = this.props;
        const { modalData } = this.state;
        var error = false;

        if ( !( modalData && modalData.assigned_to && isArrayExists( modalData.assigned_to ) ) ) {
            error = 'Please assign at least one user';
        } // end - modalData.email

        if ( !( modalData && modalData.name && !_isEmpty( modalData.name ) ) ) {
            error = 'Please enter a valid name';
        } // end - modalData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addNewAccount(modalData,history));
        } // end - error
    }

    handleEdit = (id,event) => {
        event.preventDefault();
        this.props.history.push("/accounts/"+id);
    }

    handleDelete = () => {
        const { deleteModal } = this.state;
        // perform delete
        this.props.dispatch(deleteAccount(deleteModal.id));
    }

    handleAction = type => {
        const { checked } = this.state;
        // make sure there is an item selected
        if ( isArrayExists( checked ) ) {
            if ( type && type === 'comment' ) {
                this.setState({ openComment: true });
            } else if ( type && type === 'transfer_ownership' ) {
                this.setState({ openTransferownership: true });
            } else if ( type && type === 'mass_edit' ) {
                this.setState({ openMassEdit: true });
            } else if ( type && type === 'sendemail' ) {
                this.setState({ openSendEmail: true });
            } 
        } else {
            triggerErrorAlert("Please select at least one item");
        } // end - checked
    }

    getPredefinedAccounts = () => {
        const { checked } = this.state;
        const { items, total } = this.reorganizeData();
        const selected = [];
        if ( checked && isArrayExists( checked ) && items && isArrayExists( items ) ) {
            _forEach( checked, item => {
                let account = _find( items, { id: item.id });
                if ( account && !_find( selected, { id: account.id }) ) {
                    selected.push({ 
                        id: account.id,
                        name: account.name || '',
                        email: account.email || '',
                        sort_name: ( account.name && !_isEmpty( account.name ) ? _toLower( account.name ) : '' ),
                        assigned_to: ( account.assigned_to && isArrayExists( account.assigned_to ) ? account.assigned_to : [] )
                    });
                } // end - contact                    
            });
        } // end - checked
        return selected;
    }

    getActions = () => {
        const { authData } = this.props;
        let actions = [
            { id: 'addnew', label: 'Add New Account', icon: 'fa-plus-circle', onClick: () => this.setState({ openViewModal: true, modalData: { name: '', assigned_to: [{ id: authData.email, name: ( authData.name || '' ) }] } }) },
            { id: 'addcomment', label: 'Add Comment', icon: 'fa-commenting', onClick: this.handleAction.bind(this,'comment') },
            { id: 'sendemail', label: 'Send Email', icon: 'fa-envelope', onClick: this.handleAction.bind(this,'sendemail'), disabled: ( authData && hasAccessRights(authData, ['acs']) ? false : true ) }
        ];

        if ( authData && hasAccessRights(authData, ['act']) ) {
            actions.push({ id: 'transfer', label: 'Transfer Ownership', icon: 'fa-random', onClick: this.handleAction.bind(this,'transfer_ownership') });
        }

        if ( authData && hasAccessRights(authData, ['ace']) ) {
            actions.push({ id: 'edit', label: 'Edit', icon: 'fa-edit', onClick: this.handleAction.bind(this,'mass_edit') });
        }

        if ( authData && isAdmin( authData ) ) {
            actions.push({ id: 'export', label: 'Export', icon: 'fa-cloud-download', onClick: false, disabled: true });
        } // end - authData

        return actions;
    }

    getQueryProps = () => {
        const { sortBy, perPage, page } = this.state;
        return false;
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { accounts, users, authData } = this.props;
        let items = ( accounts ? cloneCollections( accounts ) : [] ),
            total = _size( items );

        // add in assigned to label
        if ( accounts && isArrayExists( accounts ) ) {
            _forEach( accounts, (account,index) => {

                // set label as empty
                items[index].assigned_to_label = '';

                // run through all the IDs to get labels
                if ( account && account.assigned_to && isArrayExists( account.assigned_to ) ) {
                    _forEach( account.assigned_to, user => {
                        items[index].assigned_to_label += ( !_isEmpty( items[index].assigned_to_label ) ? ' ' : '' ) + ( user && user.name ? user.name : user.id );  
                    });
                } // end - account.assigned_to
                
            });
        } // end - accounts

        // // do search - no longer needed
        // if ( searchterms && !_isEmpty( searchterms ) ) {
        //     items = doArraySearch( items, searchterms, ['id','name','assigned_to_label','email','email_other'] );
		// 	total = _size( items );
        // } // end - searchterms

        // do search using search filter component
        if ( searchterms && !_isEmpty( searchterms ) ) {
            const splitTerms = searchterms.split('_|_');
            if ( splitTerms[0] && !_isEmpty(splitTerms[0]) && splitTerms[1] && !_isEmpty(splitTerms[1]) ) {
                // trim _srch from the end
                const search_col = splitTerms[0].replace(/_srch$/g,'');
                switch( search_col ) {
                    case 'assigned_to':
                        items = doArraySearch( items, splitTerms[1], [ 'assigned_to_label' ] );
                        total = _size( items );
                        break;
                    default:
                        items = doArraySearch( items, splitTerms[1], [ search_col ] );
                        total = _size( items );
                        break;
                }
            }
        }

        // do custom filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy != 'all' ) {
            // items = _filter( items, { org: filterBy });
            // total = _size( items );
        }

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, (i) => ( i.modified_on && i.modified_on._seconds ? i.modified_on._seconds : i.modified_on ) );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, (i) => ( i.modified_on && i.modified_on._seconds ? i.modified_on._seconds : i.modified_on ) );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderCell = type => item => {
        switch( type ) {
            case 'assigned_to':
                return (
                <div>{ item.assigned_to && isArrayExists( item.assigned_to ) ? item.assigned_to.map(user => ( <div key={user.id}>{ user && user.name ? user.name :  user.id }</div> )) : null }</div>
                );
            case 'email':
                return <WrapWord>{( item[type] || '' )}</WrapWord>
        }
    }

    renderAddNewForm = () => {
        const { users } = this.props;
        const { modalData } = this.state;
        return (
        <div>
            <FormInput label="Name (Required)" name="name" value={( modalData.name || '' )} onChange={this.handleFormUpdate} />
            <div style={{ paddingTop: "7px" }}>
                <FormMultiSelect 
                    label="Assigned To (Required)" 
                    name="assigned_to" 
                    value={( modalData && modalData.assigned_to && isArrayExists( modalData.assigned_to ) ? getSelectValues( modalData.assigned_to, 'id' ) : [] )} 
                    options={getSelectOptions({ list: ( users ? _filter( users, (u) => u.status !== 'disabled' ) : [] ), keys: { value: 'email', label: 'name' }, sortBy: 'label' })} 
                    onChange={this.handleFormUpdate} />
            </div>
        </div>
        );
    }

    renderTableActions = () => {
        const { authData } = this.props;
        const { sortBy, perPage, searchterms, checked } = this.state;
        return <TableBar
                mod="accounts"
                sortBy={sortBy}
                limitedPerPage={true}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Modified first' },
                    { value: 'date-asc', label: 'Oldest Modified first' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' }
                ]}
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                    </div>
                    ] : null }
                rightButtons={[
                    <ButtonActions 
                        key="actions" 
                        label="Actions"
                        menuContainerStyle={{ width: "200px" }}
                        style={{ marginRight: "5px" }}
                        actions={this.getActions()} />
                    ,
                    <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton>
                ]}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { accountsLoaded } = this.props;
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    doneLoaded={accountsLoaded}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { users, authData, mailing_lists } = this.props;
        const { openDeleteModal, deleteModal, openViewModal, allCheck, checked, openComment, openTransferownership, openMassEdit, openSendEmail } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openViewModal}
                title="Add New Account"
                onClose={() => this.setState({ openViewModal: false, modalData: false })}
                doAction={this.handleAddNew}
                contents={this.renderAddNewForm()} />

            <ModalMassEditAccounts
                open={openMassEdit}
                selected={( checked || [] )}
                query={this.getQueryProps()}
                mailing_lists={( mailing_lists || [] )}
                onUpdate={() => this.setState({ openMassEdit: false, checked: [], allCheck: false })}
                onClose={() => this.setState({ openMassEdit: false })} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to delete this account ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            {this.renderTableActions()}
            <Table 
                noSelect={true}
                items={items}
                showCheckbox={true}
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={[
                    { id: 'name', label: 'Name', render: (item) => ( item.name || '' ) },
                    { id: 'assigned_to', label: 'Assigned To', render: this.renderCell('assigned_to') },
                    { id: 'billing_city', label: 'Billing City', render: (item) => ( item.billing_city || '' ) },
                    { id: 'general_line', label: 'General Line', render: (item) => ( item.general_line || '' ) },
                    { id: 'direct_line', label: 'Direct Line', render: (item) => ( item.direct_line || '' ) },
                    { id: 'email', label: 'Email', render: this.renderCell('email') },
                ]}
                actionStyles={{ width: "10%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <AInfoLink key="edit" href={"/accounts/"+item.id} size="small" onClick={this.handleEdit.bind(this,item.id)}><i className="fa fa-edit"></i>Edit</AInfoLink>
                        { authData && hasAccessRights( authData, ['acd'] ) ? <ErrorButton key="delete" size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton> : null }
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}

            <MassComment 
                refer_type="account"
                open={openComment}
                selected={( checked || [] )}
                onClose={() => this.setState({ openComment: false })}
                onCompleted={() => {
                    // reset modal, and selector
                    this.setState({ openComment: false, checked: [], allCheck: false });
                    // trigger refresh
                    if ( this.props.onRefresh )
                        this.props.onRefresh();
                }} />

            <MassTransferOwnership
                refer_type="account"
                open={openTransferownership}
                users={( users || [] )}
                selected={( checked || [] )}
                onClose={() => this.setState({ openTransferownership: false })}
                onCompleted={() => {
                    // reset modal, and selector
                    this.setState({ openTransferownership: false, checked: [], allCheck: false });
                    // trigger refresh
                    if ( this.props.onRefresh )
                        this.props.onRefresh();
                }} />

            { authData && hasAccessRights(authData, ['acs']) ? <AddNewEmail
                authData={authData}
                open={openSendEmail}
                users={( users || [] )}
                predefined_accounts={this.getPredefinedAccounts()}
                predefined_contacts={false}
                onClose={() => this.setState({ openSendEmail: false })} /> : null }

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(Accounts);