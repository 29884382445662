/* eslint-disable */
import * as firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _snakeCase from 'lodash/snakeCase';

import { doesUserHaveAccess } from '../../helpers/auth';
import { callFunctionsAPI, checkIfUserAccessLimited, checkAuthDataIfAccessLimited } from '../../helpers/action';
import { isArrayExists, isObjectExists, validateEmail } from '../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema } from '../../helpers/schemas';
import { getItemsFromFirestore, getTotalCountBySchema } from '../../helpers/firestore';
import { isValidUser } from '../../helpers/users';

import { potentialSchema } from '../../schemas/potential';
import { commentSchema } from '../../schemas/comment';
import { logSchema } from '../../schemas/log';

import { SET_READ_LIMITS } from '../../constants';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

// get list from firebase based props
const getFirestoreList = (props) => {
    return new Promise((resolve,reject) => {

        var promises = [];
        promises.push( getItemsFromFirestore( "potentials", ( props && isObjectExists( props ) ? props : null ) ) );

        // if no keywords filter
        if ( !( props && props.searchterms && !_isEmpty( props.searchterms ) ) ) {
            promises.push( getTotalCountBySchema('potentials') );
        } // end - props.searchterms

        Promise.all( promises )
        .then(results => {
            resolve({ 
                list: ( results && results[0] && results[0].list && isArrayExists( results[0].list ) ? results[0].list : [] ), 
                firstDoc: ( results && results[0] && results[0].firstDoc ? results[0].firstDoc : false ),
                lastDoc: ( results && results[0] && results[0].lastDoc ? results[0].lastDoc : false ), 
                total: ( results && results[1] ? results[1] : 0 )
            });
        })
        .catch(error => {
            // only use it when needed
            // console.log(error);
            reject(error);
        });

    })
} 

// get list based on assigned_to
const getAssignedToList = (user) => {
    return new Promise((resolve,reject) => {

        let ref = firebase.database().ref("potentials");
        if ( user && user.id && user.email && validateEmail(user.email) ) {
            ref = ref.orderByChild("assigned_to/"+user.id+"/id").equalTo(user.email);
        }

        ref.once("value")
        .then(snapshot => {

            let list = [];

            if ( snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach( childSnapshot => {
                    let item = getSnapshotDataBySchema( potentialSchema, childSnapshot );
                    list.push(item);
                });
            } // end - snapshot

            resolve({ list });

        })
        .catch(error => {
            reject(error);
        });
    })
}

/* helper end */

export const fb_getPotentials = (props) => {
    return new Promise((resolve,reject) => {
        
        checkIfUserAccessLimited()
        .then(user => {
            return ( isValidUser(user) ? getAssignedToList(user) : getFirestoreList(props) );
        })
        .then(payload => {
            resolve(payload);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getPotential = (potential_id) => {
    return new Promise((resolve,reject) => {
        
        let promises = [];
        promises.push( firebase.firestore().collection("potentials").doc(potential_id).get() );
        promises.push( firebase.database().ref("comments").orderByChild('refer_id').equalTo(potential_id).once("value") );
        promises.push( firebase.database().ref("logs").orderByChild('type_id').equalTo(potential_id).once("value") );
        promises.push( checkIfUserAccessLimited() );
        
        Promise.all( promises )
        .then(results => {
            let item = {};

            if ( results && results[0] ) {
                item = getDocDataBySchema( potentialSchema, results[0] )
            } // end - results[0]

            // add comments
            item['comments'] = [];
            if ( results && results[1] && results[1].exists() && results[1].hasChildren() ) {
                results[1].forEach( childSnapshot => {
                    let comment = getSnapshotDataBySchema( commentSchema , childSnapshot );
                    item['comments'].push( comment );
                });
            } // end - results[1]

            // add updates
            item['updates'] = [];
            if ( results && results[2] && results[2].exists() && results[2].hasChildren() ) {
                results[2].forEach( childSnapshot => {
                    let update = getSnapshotDataBySchema( logSchema , childSnapshot );
                    item['updates'].push( update );
                });
            } // end - results[2]

            // if is limited user
            if ( results && results[3] && !_isEmpty( results[3] ) ) {
                if ( doesUserHaveAccess( results[3], item ) ) {
                    resolve(item);
                } else {
                    resolve({});
                }
            } else {    
                resolve(item);
            } // end - results
            
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getPotentialsBy = (type,id) => {
    return new Promise((resolve,reject) => {
        
        firebase.database().ref("usage/"+type+"/"+id+"/potentials").once("value")
        .then( snapshot => {
            var promises = [];
            if ( snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach( childSnapshot => {
                    promises.push( firebase.database().ref("potentials/"+childSnapshot.key).once("value") );
                });
            } // end - snapshpt

            return ( isArrayExists( promises ) ? Promise.all( promises ) : false );
        })
        .then(results => {

            let list = [];

            if ( results && isArrayExists( results ) ) {
                _forEach( results, result => {
                    if ( result && result.exists() ) {
                        let item = getSnapshotDataBySchema( potentialSchema, result );
                        list.push(item);
                    } // end - result
                });
            } // end - results

            resolve(list);

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getPotentialsOptions = (callback,limitByUser) => {

    var ref = firebase.database().ref("maintenance/options/potentials");

    if ( limitByUser && checkAuthDataIfAccessLimited( limitByUser ) && limitByUser.email && validateEmail( limitByUser.email ) ) {
        ref = ref.orderByChild("assigned_to/"+_snakeCase(limitByUser.email)+"/id").equalTo(limitByUser.email);
    } // end - limitByUser.email

    if ( SET_READ_LIMITS ) {
        ref = ref.limitToLast(5);
    }

    ref.on("value",snapshot => {

        var options = [];

        if ( snapshot.exists() && snapshot.hasChildren() ) {
            snapshot.forEach(childSnapshot => {
                var option = childSnapshot.val();
                option['id'] = childSnapshot.key;
                options.push(option);
            });
        } // end - snapshot
 
        callback(options);

    });

}

export const fb_addPotential = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'potential', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updatePotential = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'potential', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deletePotential = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'potential', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}