/* eslint-disable */
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _split from 'lodash/split';

import { isArrayExists } from './validation';

// check if current user is admin
export const isAdmin = function(authData) {
    return ( authData && authData.type && ( authData.type === 'admin' ) ? true : false );
}

// check if current user have the access rights
export const hasAccessRights = function(authData,accessRights) {
    return ( authData && !_isEmpty( authData ) && (
        ( authData.type === 'admin' || !accessRights ) || 
        ( accessRights && authData.accesses && !_isEmpty( authData.accesses ) && _find( accessRights, (r) => authData.accesses.indexOf(r) >= 0 ) )
    ) ? true : false );
}

// check if current user have limited access
export const hasLimitedAccess = function(authData) {
    return ( authData && !_isEmpty( authData ) && (
        authData.type === 'user' && 
        authData.accesses && !_isEmpty( authData.accesses ) && authData.accesses.indexOf('alr') >= 0
    ) ? true : false );
}

// check if current user have access to current item
export const doesUserHaveAccess = function(user,item) {
    return ( user && user.email && !_isEmpty( user.email ) && item && item.assigned_to && isArrayExists( item.assigned_to ) && _find( item.assigned_to, { id: user.email }) ? true : false );
}