/* eslint-disable */
import * as firebase from 'firebase/app';

import { getIDToken } from '../auth/fb';
import { doPromise } from '../../helpers/action';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

/* helper end */

export const fb_getSelectables = (callback) => {

    firebase.database()
    .ref("maintenance/selectables")
    .on("value", snapshot => {

        var list = [];

        if ( snapshot.exists() && snapshot.hasChildren() ) {
            snapshot.forEach( childSnapshot => {
                var val = childSnapshot.val(),
                    item = {
                        id: childSnapshot.key,
                        options: [],
                    };

                // add options
                if ( childSnapshot.hasChildren() ) {
                    childSnapshot.forEach(optionSnapshot => {
                        var val = optionSnapshot.val();
                        item.options.push({ 
                            id: optionSnapshot.key,
                            label: val.label || '',
                            value: val.value || '',
                            position: val.position || 99
                        });
                    });
                } // end - childSnapshot

                list.push(item);
            });
        } // end - snapshpt\
        
        callback(list);

    });
    
}

export const fb_syncSelectables = () => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'sync',
                params: [{ key: 'action', value: 'sync_selectables' }]
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}