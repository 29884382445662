/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';
import _ceil from 'lodash/ceil';

import { FlexColumn } from '../../styles/division';

import theme from '../../theme';

const Links = styled.ul`
    display: inline-block;
    margin: 0px;
    padding: 0px;
    list-style: none;

    & > li {
        display: inline-block;

        &:last-child() {
            a { border-right: none; }
        }
    }

    a {
        display: block;
        padding: 8px 15px;
        text-align: center;
        color: ${theme.palette.background};
        background: none;
        border-right: 1px solid #ddd;

        &.current {
            background: ${theme.palette.primary.main};
            color: #fff;
            border-right: none;
            cursor: default;
            pointer-events: none;
        }

        &:hover {
            background: ${theme.palette.primary.dark};
            color: #fff;
        }

        &.disabled {
            cursor: default;
            pointer-events: none;
        }
    }
`;

class PaginationFirestore extends React.Component {

    handlePageChange = (newPage,event) => {
        event.preventDefault();
		this.props.onPageChange(newPage);
    }

	isCurrent = (thisPage) => {
        const { page } = this.props;
		return ( page == thisPage ? ' current' : '' );
	}

    isSearch = () => {
        const { searchterms } = this.props;
        return ( ( searchterms && !_isEmpty( searchterms ) ) ? true : false ); 
    }

    getTotalPages = () => {
        const { total, perPage, page } = this.props;
        return ( total && _isNumber( total ) ? _ceil( total / perPage ) : 0 );
    }

    renderLink = (type) => {
        const { total, currentTotal, perPage, page } = this.props;
        var totalPage = this.getTotalPages();
        return (
        <Paper elevation={2} style={{ backgroundColor: "#fff", display: "inline-block" }}>
            <Links>
                { type && type === 'prev' ? ( page > 1 ? this.renderPrevLink('Prev') : '' ) : ( ( this.isSearch() && currentTotal >= perPage ) || page < totalPage ? this.renderNextLink('Next') : '' )}
            </Links>
        </Paper>
        );
    }

	renderPrevLink(label) {
        const { page } = this.props;
		return <li><a href="#prev" title="Previous" onClick={this.handlePageChange.bind(this, page-1)}><i className="fa fa-angle-left"></i>{ label ? <span style={{ display: "inline-block", marginLeft: "8px" }}>{label}</span> : '' }</a></li>;
	}

	renderNextLink(label) {
        const { page } = this.props;
		return <li><a href="#next" title="Next" onClick={this.handlePageChange.bind(this, page+1)}>{ label ? <span style={{ display: "inline-block", marginRight: "8px" }}>{label}</span> : '' }<i className="fa fa-angle-right"></i></a></li>;
	}

	renderBlank() {
		return <li><a href="#" className="disabled">...</a></li>
	}

    renderTotalEntries = () => {
        const { total, perPage, page, doneLoaded, searchterms, filterBy } = this.props;
		var minNum = (perPage*(page-1))+1,
			maxNum = perPage*page;
        return ( filterBy && !_isEmpty( filterBy ) && ( filterBy !== 'company_details_|_2' && filterBy !== 'all' ) ) ? null : (
        <Typography display="block" variant="h6">
            Showing { !this.isSearch() ? (  total > 0 ? minNum + ' - ' : ''  ) + ( maxNum > total ? total : maxNum ) + ' of ' : '' }{ doneLoaded ? total : <div style={{ display: 'inline-block', padding: '0 10px' }}><CircularProgress size={12} /></div> } entries
        </Typography>
        )
    }

    render() {
        const { style, total } = this.props;
        return (
        <Box display="flex" justifyContent="space-between" alignItems="center" style={( style || null )}>
            <FlexColumn width="35%" style={{ padding: "5px", textAlign: "left" }}>
                { !this.isSearch() ? this.renderLink('prev') : null }
            </FlexColumn>
            <FlexColumn width="30%" style={{ padding: "5px", textAlign: "center" }}>
                { total ? this.renderTotalEntries() : <div> </div> }
            </FlexColumn>
            <FlexColumn width="35%" style={{ padding: "5px", textAlign: "right" }}>
                { !this.isSearch() ? this.renderLink('next') : null }
            </FlexColumn>
        </Box>
        )
    }

}

export default PaginationFirestore