/* eslint-disable */
import React from 'react';
// import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';

import ModalView from '../ModalView';
import Table from '../Table';
import FormSelect from '../FormSelect';
import FormInput from '../FormInput';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
// import { cloneCollections } from '../../helpers/data';
import { formatMoney } from '../../helpers/number';

import { InfoButton, ButtonGroup } from '../../styles/button';

class ModalSelectDiscount extends React.Component {

    state = {
        type: 'fixed',
        discount: 0,
        randNum: false
    };

    componentDidMount() {
        this.setValue();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setValue();
    }

    setValue = () => {
        const { open, item } = this.props;
        if ( open && open !== this.state.randNum ) {
            this.setState({
                type: ( item.discount_type || 'fixed' ),
                discount: ( item.discount || 0 ),
                randNum: open
            });
        }
    }

    handleDiscountSelect = () => {
        const { onDiscountSelect } = this.props;
        const { type, discount } = this.state;
        var error = false;

        if ( !( type && ( type === 'fixed' || type === 'percentage' || type === 'none' ) ) )
            error = 'Please select a discount type';

        if ( !( discount || ( type && type === 'none' ) ) )
            error = 'Please insert a valid discount value';

        if ( type && type === 'percentage' && ( discount < 0 || discount > 100 )  )
            error = 'Discount percentage should be in between 0 to 100';

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            // reset state
            this.setState({ discount: 0, type: 'fixed', randNum: false });

            // trigger discount select
            if ( onDiscountSelect )
                onDiscountSelect({ discount,type });
        } // end - error

    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose )
            onClose();
    }


    renderModal = () => {
        const { type, discount } = this.state;
        return (
        <div>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormSelect label="Discount Type" value={( type || 'fixed' )} options={[
                        { value: 'none', label: 'No Discount' },
                        { value: 'fixed', label: 'Fixed Amount' },
                        { value: 'percentage', label: 'Percentage (%)' },
                    ]} onChange={(newValue) => this.setState({ type: newValue, discount: 0 })} />
                </Grid>
                <Grid item xs={12}>
                    <FormInput label="Amount" type="number" value={( discount || 0 )} onChange={(newValue) => this.setState({ discount: newValue })} />    
                </Grid>
            </Grid>
            
        </div>   
        )
    }

    render() {
        const { open } = this.props;
        return <ModalView 
                open={( open ? true : false )}
                title="Select Discount"
                actionLabel="Done"
                maxWidth="sm"
                onClose={this.handleClose}
                doAction={this.handleDiscountSelect}
                contents={this.renderModal()} />
    }

}

export default compose(
    connect()
)(ModalSelectDiscount);