/* eslint-disable */
import _random from 'lodash/random';

import {
    M_GET_COURSE_PROFILE,
    M_COURSE_PROFILE_UPDATED
} from '../types';

import { 
	// toggleModalDeleting, toggleModalProcessing, 
	toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
// import { appChangesReset } from '../misc';

import { fb_getCourseProfile, fb_syncCourseProfile } from './fb';

export const getCourseProfile = () => {
	return dispatch => {

		fb_getCourseProfile(list => {
			dispatch({ 
                type: M_GET_COURSE_PROFILE, 
                payload: { list } 
            });
		});

	}
}

export const syncDataWithLMS = (id,type) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_syncCourseProfile()
		.then(() => {
			dispatch({ type: M_COURSE_PROFILE_UPDATED });
			dispatch(toggleLoader(false));
			triggerSuccessAlert("Course Profile Synced!");
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to sync data with LMS' ) );
			dispatch(toggleLoader(false));
			triggerErrorAlert(errMsg);
		});

  	}
}