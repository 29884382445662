/* eslint-disable */
import shortid from 'shortid';
import _round from 'lodash/round';
import _isInteger from 'lodash/isInteger';
import _size from 'lodash/size';

// sample
export const newInvoiceItem = function(total) {
	return {
        id: shortid.generate(),
        product_id: '',
        name: '',
        desc: '',
        quantity: 1,
        unit_price: 0,
        discount: 0,
        discount_type: 'none',
        no_tax: 'no',
        pricing_category: [],
        position: ( total || 0 ) + 1
    };
}


// sample
export const sampleFunc = function() {
	
}

