/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	DO_MASS_ACTION,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
// import { appChangesReset } from '../misc';

import { fb_doMassAction } from './fb';


export const doMassAction = ( type, action, formData, successMsg ) => {
	return dispatch => {

		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_doMassAction(type,action,formData)
		.then( results => {
			// updated
			dispatch({ type: DO_MASS_ACTION });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert( successMsg );
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new contact' ) );
			triggerErrorAlert(errMsg);
		});

	}
}