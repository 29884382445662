/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _isEqual from 'lodash/isEqual';

import ModalView from '../ModalView';
import Table from '../Table';
import Pagination from '../Pagination';
import TableBar from '../TableBar';
import DotsLoader from '../DotsLoader';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { formatMoney } from '../../helpers/number';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';

import { getPotentialsOptions } from '../../actions/potentials';

class ModalSelectPotentials extends React.Component {

    state = {
        searchterms: '',
        filterBy: 'all',
        sortBy: 'name-asc',
        perPage: 20,
        page: 1,
        randNum: false
    };

    componentDidMount() {
        this.dispatchOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( !_isEqual( prevProps.potentialsOptions, this.props.potentialsOptions ) || 
            !_isEqual( prevProps.authData, this.props.authData ) || 
            !_isEqual( prevProps.randNum, this.props.randNum ) ) {
            this.dispatchOptions();
        }
    }

    dispatchOptions = () => {
        const { potentialsOptions, authData, randNum } = this.props;
        if ( !potentialsOptions && authData ) {
            this.props.dispatch(getPotentialsOptions(authData));
        } else if ( potentialsOptions && randNum && randNum != this.state.randNum ) {
            this.setState({ randNum });
        } // end - potentialsOptions
    }

    handleSelect = (option) => {
        const { onSelect } = this.props;
        // trigger option select
        if ( onSelect )
            onSelect(option);
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose )
            onClose();
    }

    getLabel = key => (item) => {
        var label = '';
        if ( item[key] && isObjectExists( item[key] ) ) {
            _forEach( item[key], (u) => {
                label = ( u.name ? u.name.toLowerCase() : '' );
            });
        } // end - item[key]
        return label;
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { potentialsOptions, predefined_contact, predefined_account } = this.props;
        let items = ( potentialsOptions ? cloneCollections( potentialsOptions ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','product_label','accounts_linked.name','contacts_linked.name','assigned_to.name'] );
			total = _size( items );
        } // end - searchterms

        // do custom filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy != 'all' ) {
            // items = _filter( items, { org: filterBy });
            // total = _size( items );
        }

        // filter by predefined_account
        if ( predefined_account && predefined_account.id && !_isEmpty( predefined_account.id ) ) {
            items = _filter( items, (o) => ( o.accounts_linked && isObjectExists( o.accounts_linked ) && _find( o.accounts_linked, { id: predefined_account.id } ) ? true : false ));
            total = _size( items );
        } // end - predefined_account

        // filter by predefined_contact
        if ( predefined_contact && predefined_contact.id && !_isEmpty( predefined_contact.id ) ) {
            items = _filter( items, (o) => ( o.contacts_linked && isObjectExists( o.contacts_linked ) && _find( o.contacts_linked, { id: predefined_contact.id } ) ? true : false ));
            total = _size( items );
        } // end - predefined_contact

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'name-desc':
                    items = _sortBy( items, [(i) => i.name.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, [(i) => i.name.toLowerCase()] );
                    break;
                case 'account_label-desc':
                    items = _sortBy( items, [this.getLabel('accounts_linked')] );
                    items = _reverse( items );
                    break;
                case 'account_label-asc':
                    items = _sortBy( items, [this.getLabel('accounts_linked')] );
                    break;
                case 'contact_label-desc':
                    items = _sortBy( items, [this.getLabel('contacts_linked')] );
                    items = _reverse( items );
                    break;
                case 'contact_label-asc':
                    items = _sortBy( items, [this.getLabel('contacts_linked')] );
                    break;
                case 'product_label-desc':
                    items = _sortBy( items, [(i) => i.product_label.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'product_label-asc':
                    items = _sortBy( items, [(i) => i.product_label.toLowerCase()] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                total={totalCount}
                perPage={perPage} 
                page={page}
                doneLoaded={true}
                style={{ marginTop: "20px" }}
                onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderTableActions = () => {
        const { sortBy, perPage, searchterms } = this.state;
        return <TableBar
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' },
                    { value: 'product_label-asc', label: 'Product Name ( A - Z)' },
                    { value: 'product_label-desc', label: 'Product Name ( Z - A )' },
                    { value: 'account_label-asc', label: 'Account Name ( A - Z)' },
                    { value: 'account_label-desc', label: 'Account Name ( Z - A )' },
                    { value: 'contact_label-asc', label: 'Contact Name ( A - Z)' },
                    { value: 'contact_label-desc', label: 'Contact Name ( Z - A )' }
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderCell = key => (item) => {
        return <div>{( item[key] && isObjectExists( item[key] ) ? _map( item[key], (u) => {
            return <div key={u.id}>{( u.name || '' )}</div>
        }) : '---' )}</div>;
    }

    renderTable = (items) => {
        return <Table 
            items={items}
            showCheckbox={false}
            cells={[
                { id: 'name', label: 'Name', render: (item) => ( item.name || '' ) },
                { id: 'product_label', label: 'Product', render: (item) => ( item.product_label || '' ) },
                { id: 'accounts_linked', label: 'Account', render: this.renderCell('accounts_linked') },
                { id: 'contacts_linked', label: 'Contact', render: this.renderCell('contacts_linked') },
                { id: 'assigned_to', label: 'Assigned To', render: this.renderCell('assigned_to') }
            ]}
            actionStyles={{ width: "10%" }}
            actions={(item) => (
                <ButtonGroup>
                    <InfoButton key="select" size="small" onClick={this.handleSelect.bind(this,item)}><i className="fa fa-edit"></i>Select</InfoButton>
                </ButtonGroup>
            )} />
    }

    renderModal = () => {
        const { randNum } = this.state;
        const { items, total } = this.reorganizeData();
        return !randNum ? <DotsLoader /> : (
        <div>

            {this.renderTableActions()}
            {this.renderTable(items)}
            {this.renderPagination(total)}
            
        </div>   
        )
    }

    render() {
        const { open } = this.props;
        return <ModalView 
                open={open}
                title="Select Potential"
                noAction={true}
                actionLabel="Select"
                maxWidth="lg"
                cancelLabel="Close"
                onClose={this.handleClose}
                contents={ open ? this.renderModal() : null } />
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        potentialsOptions: state.potentials && state.potentials.potentials_options || null,
        randNum: state.potentials && state.potentials.options_rand || null
    }
}

export default compose(
    connect(mapStateToProps)
)(ModalSelectPotentials);