import React from 'react';
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _remove from 'lodash/remove';

/* eslint-disable */

import FormDatePicker from '../../components/FormDatePicker';

import { InfoButton } from '../../styles/button';
import { FormBox } from '../../styles/form';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';
import { triggerErrorAlert } from '../../helpers/alert';

const useStyles = theme => ({
    paper: {
        padding: '20px',
        backgroundColor: '#fff'
    },
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    },
    btnwrapper: {
        marginTop: "15px",
        textAlign: "center",
        '& button': {
            padding: "12px 25px"
        }
    }
});

const FormWrapper = styled.div`
    display: block;
    margin: 30px auto;
    max-width: 800px;
`;

class DateRangeForm extends React.Component {

    handleFormTrigger = (event) => {
        const { onTrigger, date_range } = this.props;
        event.preventDefault();
        let error = false;
        // do error check
        if ( date_range['start_date'] > date_range['end_date'] ) {
            error = 'Start Date cannot be after End date';
        }

        if ( date_range['end_date'] < date_range['start_date'] ) {
            error = 'End Date cannot be before Start date';
        }

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            if ( onTrigger )
                onTrigger();
        }

    }

    handleFormUpdate = (newValue,key) => {
        const { date_range, default_date_range, onUpdate } = this.props;
        const newDate = ( date_range && isObjectExists( date_range ) ? cloneCollections( date_range ) : cloneCollections( default_date_range ) );
        let newDateObj = moment(newValue).utcOffset(8);

        if ( key && key === 'start_date' ) {
            // modify to start of date
            newDateObj = newDateObj.startOf('date');
            newDate[key] = newDateObj.valueOf();

            // make sure start_date isn't after end date
            // if ( newDateObj.valueOf() < newDate['end_date'] ) {
            //     newDate[key] = newDateObj.valueOf();
            // } else {
            //     triggerErrorAlert("Start Date cannot be after End date");
            // }
        } else if ( key && key === 'end_date' ) {
            // modify to end of date
            newDateObj = newDateObj.endOf('date');
            newDate[key] = newDateObj.valueOf();

            // make sure end_date isn't before start date
            // if ( newDateObj.valueOf() > newDate['start_date'] ) {
            //     newDate[key] = newDateObj.valueOf();
            // } else {
            //     triggerErrorAlert("End Date cannot be before Start date");
            // }
        } // end - key

        if ( onUpdate )
            onUpdate(newDate);
    }

    getFieldValue = (key) => {
        const { date_range } = this.props;
        const value = ( date_range && isObjectExists( date_range ) && date_range[key] ? date_range[key] : false );
        return ( value ? value : 0 );
    }

    renderDatePickerForm = (label,key) => {
        return <FormDatePicker name={key} label={label} noDefaultVal={true} value={this.getFieldValue(key)} onChange={this.handleFormUpdate} />
    }

    render() {
        const { classes } = this.props;
        return (
        <FormWrapper>
            <Paper elevation={2} className={classes.paper}>
                <Typography variant="h4" className={classes.boxheading}>Select a Date Range</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {this.renderDatePickerForm("Start Date",'start_date')}
                    </Grid>
                    <Grid item xs={6}>
                        {this.renderDatePickerForm("End Date",'end_date')}
                    </Grid>
                </Grid>
                <div className={classes.btnwrapper}>
                    <InfoButton className="submit" onClick={this.handleFormTrigger}>Submit</InfoButton>
                </div>
            </Paper>
        </FormWrapper>
        )
    }

}

export default compose(
    withStyles(useStyles)
)(DateRangeForm);