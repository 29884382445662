/* eslint-disable */
import _random from 'lodash/random';

import {
    M_GET_LEAD_SOURCE,
    M_LEAD_SOURCE_UPDATED
} from '../types';

import { 
	toggleModalDeleting, toggleModalProcessing, 
	toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
// import { appChangesReset } from '../misc';

import { fb_getLeadSource, fb_addLeadSource, fb_updateLeadSource, fb_deleteLeadSource } from './fb';

export const getLeadSource = () => {
	return dispatch => {

		fb_getLeadSource(list => {
			dispatch({ 
                type: M_GET_LEAD_SOURCE, 
                payload: { list } 
            });
		});

	}
}

export const addNewLeadSource = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addLeadSource(formData)
		.then( results => {
			dispatch({ type: M_LEAD_SOURCE_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Lead Source Added");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new lead source' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const updateLeadSource = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_updateLeadSource(formData)
		.then( results => {
			dispatch({ type: M_LEAD_SOURCE_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Lead Source Updated");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update lead source' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteLeadSource = (id) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteLeadSource(id)
		.then( results => {
			dispatch({ type: M_LEAD_SOURCE_UPDATED });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Lead Source Deleted");
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete lead source' ) );
			triggerErrorAlert(errMsg);
		});

	}
}