/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _isEmpty from 'lodash/isEmpty';
import _merge from 'lodash/merge';
import _size from 'lodash/size';
import _isEqual from 'lodash/isEqual';

import FindDuplicateForm from './form';
import DuplicatesList from './list';

import AppWrapper from '../../components/AppWrapper';

// import { hasLimitedAccess } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';

import { getDuplicatesAccount } from '../../actions/accounts';
import { getUsersOptions } from '../../actions/users';
import { getCoursePortfolios } from '../../actions/m_course_portfolio';
import { getAccountTier2 } from '../../actions/m_accounts_tier_2';

import { resetRedux } from '../../actions/misc';

class FindDuplicateAccounts extends React.Component {

    state = {
        field: false,
        list: [],
        created_on: false,
        randNum: false
    };

    componentDidMount() {
        this.props.dispatch(getDuplicatesAccount());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { duplicatesAccount, randNum } = this.props;

        // for accounts
        if ( duplicatesAccount && randNum && randNum !== this.state.randNum ) {
            this.setState({ 
                field: ( duplicatesAccount && duplicatesAccount.field && !_isEmpty( duplicatesAccount.field ) ? duplicatesAccount.field : false ), 
                list: ( duplicatesAccount && duplicatesAccount.list && isArrayExists( duplicatesAccount.list ) ? duplicatesAccount.list : [] ),
                created_on: ( duplicatesAccount && duplicatesAccount.created_on ? duplicatesAccount.created_on : false ),
                randNum 
            });
        } // end - duplicatesAccount

    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('duplicates_account'));
    }

    renderList = () => {
        const { field, list, created_on } = this.state;
        return (
        <div style={{ marginTop: '40px' }}>
            <DuplicatesList 
                field={field}
                list={list} />
        </div>
        )
    }

    renderForm = () => {
        const { field, list, created_on } = this.state;
        return <FindDuplicateForm
                field={field}
                list={list}
                created_on={created_on}
                onFieldChange={(newValue) => this.setState({ field: newValue })}
                onReset={() => this.setState({ field: null, list: [] })}
                 />;
    }

    renderContents() {
        const { field, list } = this.state;
        return (
        <div>

            {this.renderForm()}
            { list && isArrayExists( list ) ? this.renderList() : null }

        </div>
        );
    }

    render() {
        const { randNum } = this.state;
        return <AppWrapper 
                title="Find Duplicates"
                subtitle="Accounts"
                back="/accounts"
                breadcrumbs={[
                    { url: '/accounts', label: 'Accounts' },
                    { label: 'Find Duplicates' }
                ]}
                onLoad={( !( randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        duplicatesAccount: state.accounts && state.accounts.duplicates_account || null,
        randNum: state.accounts && state.accounts.dupe_rand || null,
        usersOptions: state.users && state.users.users_options || null,
        coursePortfolioList: state.maintenance && state.maintenance.course_portfolio || null,
        accountsTier2List: state.maintenance && state.maintenance.accounts_tier_2 || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(FindDuplicateAccounts);