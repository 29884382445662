export const commentSchema = [
    { 
        id: 'text', 
        label: 'Text',
        default: '', 
        type: 'string' 
    },
    { 
        id: 'refer_type', 
        label: 'Refer Type',
        default: '', 
        type: 'string' 
    },
    { 
        id: 'refer_id', 
        label: 'Refer ID',
        default: '', 
        type: 'string' 
    },
    { 
        id: 'user', 
        label: 'User (email)',
        default: '', 
        type: 'email' 
    },
    { 
        id: 'user_name', 
        label: 'User (Name)',
        default: '', 
        type: 'string' 
    },
    {   
        id: 'commented_on',
        label: 'Commented On',
        default: 0,
        type: 'system_date'
    }
];