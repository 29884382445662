import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _isEmpty from 'lodash/isEmpty';
import _merge from 'lodash/merge';
import _size from 'lodash/size';
import _isEqual from 'lodash/isEqual';

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import Invoices from './invoices';
import InvoicesFireStore from './invoices_fs';

import { hasLimitedAccess } from '../../helpers/auth';
import { cloneCollections } from '../../helpers/data';

import { getInvoices } from '../../actions/invoices';
import { getCoursePortfolios } from '../../actions/m_course_portfolio';
import { getUsersOptions } from '../../actions/users';
import { getRegions } from '../../actions/m_regions';
import { resetRedux } from '../../actions/misc';

import { FIRESTORE_DEFAULT_PERPAGE, DEV_MODE } from '../../constants';

class InvoicesPage extends React.Component {

    state = {
        query: {
            searchterms: '',
            filterBy: 'all',
            sortBy: 'modified_on-desc',
            perPage: 20,
            page: 1
        },
        randNum: false
    };

    componentDidMount() {
        const { authData } = this.props;

        // get invoices list
        this.props.dispatch(getInvoices(this.getInitialQuery( authData && authData.region || '' )));

        // set filterBy based on user region
        if ( authData && authData.region && !_isEmpty( authData.region ) )
            this.setInitialQuery(authData.region);

        // get all users
        if ( !this.props.usersOptions )
            this.props.dispatch(getUsersOptions());

        // get course portfolio
        if ( !this.props.coursePortfolioList )
            this.props.dispatch(getCoursePortfolios());

        // get regions
        if ( !this.props.regionsList )
            this.props.dispatch(getRegions());

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { invoicesList, coursePortfolioList, usersOptions, regionsList, randNum } = this.props;

        // once all the data is loaded
        if ( invoicesList && coursePortfolioList && regionsList && usersOptions && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('invoices'));
    }

    handleRefresh = () => {
        this.setState({ randNum: false });
        this.props.dispatch(resetRedux('invoices'));
        this.props.dispatch(getInvoices());
    }

    handleFSRefresh = () => {
        const { query } = this.state;
        this.setState({ randNum: false });
        this.props.dispatch(resetRedux('invoices'));
        this.props.dispatch(getInvoices(query));
    }

    handleFSQueryChange = (props) => {
        const { query } = this.state
        var newQuery = this.setNewFSQuery(props);
        if ( this.ifOnlySortChanged(newQuery) && newQuery.searchterms && !_isEmpty( newQuery.searchterms ) ) {
            // for search sort - no need to trigger reload
            this.setState({ query: newQuery });
        } else {
            this.setState({ query: newQuery, randNum: false });
            this.props.dispatch(resetRedux('invoices'));
            this.props.dispatch(getInvoices(newQuery));
        } // end - newQuery
    }

    setInitialQuery = (region) => {
        const { query } = this.state;
        var newQuery = cloneCollections(query);
        newQuery.filterBy = 'company_details_|_'+region;
        this.setState({ query: newQuery });
    }

    getInitialQuery = (region) => {
        const { query } = this.state;
        var newQuery = cloneCollections(query);
        newQuery.filterBy = 'company_details_|_'+region;
        if ( DEV_MODE )
            newQuery.perPage = FIRESTORE_DEFAULT_PERPAGE;
        return newQuery;
    }

    ifOnlySortChanged = (newQuery) => {
        const { query } = this.state;
        var changed = false;
        if ( !_isEqual( query.sortBy, newQuery.sortBy ) || !_isEqual( query.filterBy, newQuery.filterBy ) ) {
            if ( !_isEqual( query.searchterms, newQuery.searchterms ) || 
                !_isEqual( query.perPage, newQuery.perPage ) || 
                !_isEqual( query.page, newQuery.page ) )
                changed = true;
        } else {
            changed = true;
        }
        return ( changed ? false : true );
    }

    setNewFSQuery = (query) => {
        const { invoicesFirstDoc, invoicesLastDoc } = this.props;
        var currentQuery = this.getCurrentFSQuery(),
            newQuery = _merge( this.getCurrentFSQuery(), query);
        newQuery.endBefore = ( newQuery.page >= 2 && newQuery.page < currentQuery.page && invoicesFirstDoc ? invoicesFirstDoc : false );
        newQuery.startAfter = ( newQuery.page >= 2 && newQuery.page > currentQuery.page && invoicesLastDoc ? invoicesLastDoc : false );
        return newQuery;
    }

    getCurrentFSQuery = () => {
        const { query } = this.state;
        var currentQuery = {};
        if ( query && !_isEmpty( query ) ) {
            const { searchterms, sortBy, filterBy, perPage, page } = query;
            currentQuery = cloneCollections({ searchterms, sortBy, filterBy, perPage, page });
        }
        return currentQuery;
    }

    renderContents() {
        const { authData, invoicesList, coursePortfolioList, usersOptions, regionsList, invoicesTotal } = this.props;
        const { query } = this.state;
        return authData && hasLimitedAccess( authData ) ? (
            <Invoices 
                authData={authData} 
                invoices={invoicesList}
                users={usersOptions}
                course_portfolio={coursePortfolioList}
                onRefresh={this.handleRefresh} />
        ) : (
            <InvoicesFireStore 
                authData={authData} 
                regions={regionsList}
                invoices={invoicesList}
                total={invoicesTotal}
                currentTotal={( invoicesList ? _size( invoicesList ) : 0 )}
                currentQuery={this.getCurrentFSQuery()}
                searchterms={( query.searchterms || '' )}
                sortBy={( query.sortBy || 'modified_on-desc' )}
                filterBy={( query.filterBy || 'all' )}
                perPage={( query.perPage || 20 )}
                page={( query.page || 1 )}
                course_portfolio={coursePortfolioList}
                users={usersOptions}
                onRefresh={this.handleFSRefresh}
                onQueryChange={this.handleFSQueryChange} />
        );
    }

    render() {
        const { randNum } = this.state;
        const { invoicesList, authData } = this.props;
        return <AppWrapper 
                title="Invoices"
                onLoad={( !( invoicesList && randNum ) ? true : false )}
                contents={( authData ? this.renderContents() : null )} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        invoicesList: state.invoices && state.invoices.invoices || null,
        invoicesTotal: state.invoices && state.invoices.total || null,
        invoicesFirstDoc: state.invoices && state.invoices.firstDoc || null,
        invoicesLastDoc: state.invoices && state.invoices.lastDoc || null,
        randNum: state.invoices && state.invoices.rand || null,
        usersOptions: state.users && state.users.users_options || null,
        coursePortfolioList: state.maintenance && state.maintenance.course_portfolio || null,
        regionsList: state.maintenance && state.maintenance.regions || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(InvoicesPage);