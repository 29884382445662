/* eslint-disable */
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
// import _cloneDeep from 'lodash/cloneDeep';
// import _forEach from 'lodash/forEach';
// import _sortBy from 'lodash/sortBy';

import { isArrayExists, validateEmail } from './validation';

// reverse options to users values
export const reverseUsersValues = (options,users) => {
    let values = [];

    if ( options && isArrayExists( options ) ) {
        options.forEach(option => {
            let selected = ( users && isArrayExists( users ) ? _find( users, { email: option } ) : false );
            if ( selected && selected.email && !_isEmpty( selected.email ) ) {
                values.push({ 
                    id: selected.email, 
                    name: ( selected.name || '' ) 
                });
            } // end - selected
        });
    } // end- options

    return values;
}

export const isValidUser = (user) => {
    return ( user && user.id && !_isEmpty( user.id ) && user.email && validateEmail(user.email) ? true : false );
}