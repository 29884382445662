/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _toLower from 'lodash/toLower';

import Table from '../../components/Table';
import Pagination from '../../components/PaginationFireStore';
import TableBar from '../../components/TableBarFirestore';
import ModelDelete from '../../components/ModalDelete';
import ButtonActions from '../../components/ButtonActions';
import MassComment from '../../components/MassComment';
import MassTransferOwnership from '../../components/MassTransferOwnership';
import AddNewContact from '../../components/AddNewContact';
import ModalMassEditContacts from '../../components/ModalMassEditContacts';
import ModalMergeContacts from '../../components/ModalMergeContacts';
import AddNewEmail from '../../components/AddNewEmail';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { SuccessTag, AmberTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, combineStrings } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { getSearchKeyID, getRawSearchKeywords, doSearchSort } from '../../helpers/firestore';

import { deleteContact } from '../../actions/contacts';

import { DEV_MODE } from '../../constants';

class Contacts extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        openViewModal: false,
        openDeleteModal: false,
        deleteModal: false,
        openComment: false,
        openTransferownership: false,
        openMassEdit: false,
        openMerge: false,
        openSendEmail: false
    }

    handlePageRefresh = (event) => {
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh();
    }

    handleEdit = (id,event) => {
        event.preventDefault();
        this.props.history.push("/contacts/"+id);
    }

    handleDelete = () => {
        const { currentQuery } = this.props;
        const { deleteModal } = this.state;
        // perform delete
        this.props.dispatch(deleteContact(deleteModal.id,currentQuery));
    }

    handleQueryChange = (newQuery) => {
        const { onQueryChange } = this.props;
        // trigger change
        if ( onQueryChange )
            onQueryChange(newQuery);
    }

    handleAction = type => {
        const { checked } = this.state;
        // make sure there is an item selected
        if ( isArrayExists( checked ) ) {
            if ( type && type === 'comment' ) {
                this.setState({ openComment: true });
            } else if ( type && type === 'transfer_ownership' ) {
                this.setState({ openTransferownership: true });
            } else if ( type && type === 'mass_edit' ) {
                this.setState({ openMassEdit: true });
            } else if ( type && type === 'sendemail' ) {
                this.setState({ openSendEmail: true });
            } else if ( type && type === 'merge' ) {
                if ( _size( checked ) > 1 ) { 
                    // make sure selected contacts is from same account
                    if ( this.makeSureContactIsSameAccount() ) {
                        this.setState({ openMerge: true }); 
                    } else {
                        triggerErrorAlert("Please select contacts from the same account only.");
                    }
                } else {
                    triggerErrorAlert("Please select at least 2 items to perform merge");
                }
            }
        } else {
            triggerErrorAlert("Please select at least one item");
        } // end - checked
    }

    getPredefinedAccounts = () => {
        const { checked } = this.state;
        const { items, total } = this.reorganizeData();
        const selected = [];
        if ( checked && isArrayExists( checked ) && items && isArrayExists( items ) ) {
            _forEach( checked, item => {
                let contact = _find( items, { id: item.id });
                if ( contact && contact.account_id && !_isEmpty( contact.account_id ) && !_find( selected, { id: contact.account_id }) ) {
                    selected.push({ 
                        id: contact.account_id,
                        name: contact.account_label || '',
                        sort_name: ( contact.account_label && !_isEmpty( contact.account_label ) ? _toLower( contact.account_label ) : '' ),
                        assigned_to: ( contact.assigned_to && isArrayExists( contact.assigned_to ) ? contact.assigned_to : [] )
                    });
                } // end - contact                    
            });
        } // end - checked
        return selected;
    }

    getPredefinedContacts = () => {
        const { checked } = this.state;
        const { items, total } = this.reorganizeData();
        const selected = [];
        if ( checked && isArrayExists( checked ) && items && isArrayExists( items ) ) {
            _forEach( checked, item => {
                let contact = _find( items, { id: item.id });
                if ( contact && !_find( selected, { id: contact.id }) )
                    selected.push(contact);
            });
        } // end - checked
        return selected;
    }

    getActions = () => {
        const { authData } = this.props;
        let actions = [
            { id: 'addnew', label: 'Add New Contact', icon: 'fa-plus-circle', onClick: () => this.setState({ openViewModal: true }) },
            { id: 'addcomment', label: 'Add Comment', icon: 'fa-commenting', onClick: this.handleAction.bind(this,'comment') },
            { id: 'sendemail', label: 'Send Email', icon: 'fa-envelope', onClick: this.handleAction.bind(this,'sendemail'), disabled: ( authData && hasAccessRights(authData, ['cts']) ? false : true ) }
        ];

        if ( authData && hasAccessRights(authData, ['cte']) ) {
            actions.push({ id: 'edit', label: 'Edit', icon: 'fa-edit', onClick: this.handleAction.bind(this,'mass_edit') });
        }

        if ( authData && hasAccessRights(authData, ['ctg']) ) {
            actions.push({ id: 'merge', label: 'Merge Contacts', icon: 'fa-random', onClick: this.handleAction.bind(this,'merge') });
        }

        if ( authData && hasAccessRights(authData, ['cti']) ) {
            actions.push({ id: 'importfromlms', label: 'Import From LMS', icon: 'fa-cloud-upload', onClick: () => {
                this.props.history.push("/contacts/import_lms");
            } });
            actions.push({ id: 'importfromcsv', label: 'Import From CSV', icon: 'fa-file-excel-o', onClick: () => {
                this.props.history.push("/contacts/import_csv");
            } });  
        }

        if ( authData && isAdmin( authData ) ) {
            //actions.push({ id: 'transfer', label: 'Transfer Ownership', icon: 'fa-random', onClick: this.handleAction.bind(this,'transfer_ownership') });
            // actions.push({ id: 'import', label: 'Import', icon: 'fa-cloud-upload', onClick: false, disabled: true });
            // actions.push({ id: 'export', label: 'Export', icon: 'fa-cloud-download', onClick: false, disabled: true });
        } // end - authData

        return actions;
    }

    getSelectedMergeItems = () => {
        const { contacts, authData } = this.props;
        const { checked } = this.state;
        const items = [];
        if ( checked && isArrayExists( checked ) ) {
            _forEach( checked, item => {
                const contact = _find( contacts, { id: item.id });
                if ( contact ) {
                    const newItem = cloneCollections( contact );
                    items.push( newItem );
                }
            });
        } // end - checked
        return items;
    }

    makeSureContactIsSameAccount = () => {
        // const { contacts } = this.props;
        // const { checked } = this.state;
        // let accountID = false,
        //     valid = true;
        // if ( checked && isArrayExists( checked ) ) {
        //     _forEach( checked, item => {
        //         var selectedContact = _find( contacts, { id: item.id });
        //         if ( selectedContact && selectedContact.account_id && !_isEmpty( selectedContact.account_id ) ) {
        //             // add to accountID if empty
        //             if ( !( accountID && !_isEmpty( accountID ) ) ) {
        //                 accountID = selectedContact.account_id;
        //             } else {
        //                 // do check if it's the same ID
        //                 if ( !( selectedContact.account_id && !_isEmpty( selectedContact.account_id ) && accountID === selectedContact.account_id ) ) {
        //                     valid = false;
        //                 }
        //             } // end - accountID
        //         } else {
        //             // contact without account_id - make it false
        //             valid = false;
        //         } // end - selectedContact
        //     });
        // } // end - checked
        // return valid;
        return true; // make this constants
    }

    getQueryProps = () => {
        return false;
    }

    reorganizeData() {
        const { contacts, total, authData, searchterms, sortBy } = this.props;
        let items = ( contacts ? cloneCollections( contacts ) : [] ),
            totalCount = ( total ? total : 0 );

        // add in assigned_to_label label
        if ( contacts && isArrayExists( contacts ) ) {
            _forEach( contacts, (current,index) => {

                // set label as empty
                items[index].assigned_to_label = '';

                // run through all the IDs to get labels
                if ( current && current.assigned_to && isArrayExists( current.assigned_to ) ) {
                    _forEach( current.assigned_to, u => {
                        items[index].assigned_to_label += ( !_isEmpty( items[index].assigned_to_label ) ? ' ' : '' ) + ( u && u.name ? u.name : u.id );  
                    });
                } // end - current.assigned_to
                
            });
        } // end - contacts

        // for searchterms
        if ( searchterms && !_isEmpty( searchterms ) ) {
            // further filter out unnecessary keywords
            var search_key = getSearchKeyID(searchterms),
                search_keywords = getRawSearchKeywords(searchterms);

            switch ( search_key ) {
                case 'full_name':
                    items = doArraySearch( items, search_keywords, [ 'full_name_sort' ]);
                    totalCount = _size( items );
                    break;
                default: 
                    items = doArraySearch( items, search_keywords, [ search_key ]);
                    totalCount = _size( items );
                    break;
            }

            // do sorting on search results
            if ( sortBy && !_isEmpty( sortBy ) ) {
                items = doSearchSort( items, sortBy );
            }
        } // end - searchterms

        return { items, total: totalCount };
    }

    renderCell = type => item => {
        switch( type ) {
            case 'assigned_to':
                return ( item[type] && isArrayExists( item[type] ) ? item[type].map( (u) => (<div key={u.id}>{u.name}</div>) ) : '--' );
            case 'email':
                return <WrapWord>{( item[type] || '' )}</WrapWord>
        }
    }

    renderTableActions = () => {
        const { authData, sortBy, perPage, searchterms } = this.props;
        const { checked } = this.state;
        return <TableBar
                mod="contacts"
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'modified_on-desc', label: 'Recent Modified first' },
                    { value: 'modified_on-asc', label: 'Oldest Modified first' },
                    { value: 'full_name_sort-asc', label: 'Name ( A - Z)' },
                    { value: 'full_name_sort-desc', label: 'Name ( Z - A )' }
                ]}
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <GreyButton style={{ padding: "10px 25px", borderRadius: "25px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                    </div>
                    ] : null }
                rightButtons={[
                    <ButtonActions 
                        key="actions" 
                        label="Actions"
                        menuContainerStyle={{ width: "200px" }}
                        style={{ marginRight: "5px" }}
                        actions={this.getActions()} />
                    ,
                    <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton>
                ]}
                onFilterByChange={(newFilterBy) => this.handleQueryChange({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.handleQueryChange({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.handleQueryChange({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.handleQueryChange({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { contactsLoaded, perPage, page, searchterms, currentTotal } = this.props;
        return <Pagination 
                    total={totalCount}
                    currentTotal={currentTotal}
                    searchterms={searchterms}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    doneLoaded={contactsLoaded}
                    onPageChange={(newPage) => this.handleQueryChange({ page: newPage }) } />
    }

    render() {
        const { authData, users, accounts, mailing_lists } = this.props;
        const { openDeleteModal, deleteModal, openViewModal, allCheck, checked, openComment, openTransferownership, openMassEdit, openMerge, openSendEmail } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <AddNewContact
                authData={authData}
                open={openViewModal}
                users={( users || [] )}
                accounts={( accounts || [] )}
                mailing_lists={( mailing_lists || [] )}
                onClose={() => this.setState({ openViewModal: false })} />

            <ModalMassEditContacts
                open={openMassEdit}
                selected={( checked || [] )}
                query={this.getQueryProps()}
                onUpdate={() => this.setState({ openMassEdit: false, checked: [], allCheck: false })}
                onClose={() => this.setState({ openMassEdit: false })} />

            <ModalMergeContacts
                open={openMerge}
                selected={( openMerge ? this.getSelectedMergeItems() : [] )}
                query={this.getQueryProps()}
                onUpdate={() => this.setState({ openMerge: false, checked: [], allCheck: false })}
                onClose={() => this.setState({ openMerge: false })} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.last_name ? `Are you sure you want to delete this contact ( ${deleteModal.last_name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            {this.renderTableActions()}
            <Table 
                items={items}
                showCheckbox={true}
                checked={( checked || [] )}
                allCheck={allCheck}
                onChecked={(newValue) => this.setState({ checked: newValue })}
                onAllChecked={(newValue) => this.setState({ allCheck: newValue })}
                cells={[
                    { id: 'name', label: 'Name', render: (item) => combineStrings( item.designation, item.first_name, item.last_name ) },
                    { id: 'account_label', label: 'Account', render: (item) => ( item.account_label || '' ) },
                    { id: 'email', label: 'Email', render: this.renderCell('email') },
                    { id: 'office_phone', label: 'Office Phone', render: (item) => ( item.office_phone || '' ) },
                    { id: 'mobile_phone', label: 'Mobile Phone', render: (item) => ( item.mobile_phone || '' ) },
                    { id: 'assigned_to', label: 'Assigned To', render: this.renderCell('assigned_to') },
                ]}
                actionStyles={{ width: "10%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <AInfoLink key="edit" href={"/contacts/"+item.id} size="small" onClick={this.handleEdit.bind(this,item.id)}><i className="fa fa-edit"></i>Edit</AInfoLink>
                        { authData && hasAccessRights( authData, ['ctd'] ) ? <ErrorButton key="delete" size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton> : null }
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}

            <MassComment 
                refer_type="contact"
                open={openComment}
                selected={( checked || [] )}
                onClose={() => this.setState({ openComment: false })}
                onCompleted={() => {
                    // reset modal, and selector
                    this.setState({ openComment: false, checked: [], allCheck: false })
                    // trigger refresh
                    if ( this.props.onRefresh )
                        this.props.onRefresh();
                }} />

            <MassTransferOwnership
                refer_type="contact"
                open={openTransferownership}
                users={( users || [] )}
                selected={( checked || [] )}
                onClose={() => this.setState({ openTransferownership: false })}
                onCompleted={() => {
                    // reset modal, and selector
                    this.setState({ openTransferownership: false, checked: [], allCheck: false });
                    // trigger refresh
                    if ( this.props.onRefresh )
                        this.props.onRefresh();
                }} />

            { authData && hasAccessRights(authData, ['cts']) ? <AddNewEmail
                authData={authData}
                open={openSendEmail}
                users={( users || [] )}
                predefined_accounts={this.getPredefinedAccounts()}
                predefined_contacts={this.getPredefinedContacts()}
                onClose={() => this.setState({ openSendEmail: false })} /> : null }

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(Contacts);