/* eslint-disable */
import * as firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _snakeCase from 'lodash/snakeCase';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _startsWith from 'lodash/startsWith';

import { callFunctionsAPI } from '../../helpers/action';
import { hasLimitedAccess } from '../../helpers/auth';
import { isArrayExists, validateEmail, isObjectExists } from '../../helpers/validation';
import { getSnapshotDataBySchema, getDocDataBySchema, getSelectedSchemas } from '../../helpers/schemas';
import { getItemsFromFirestore, getTotalCountBySchema } from '../../helpers/firestore';

import { productSchema } from '../../schemas/product';
import { logSchema } from '../../schemas/log';

import { PRODUCTS_USAGE_3 } from '../../data/products_usage_3';

import { SET_READ_LIMITS, FIRST_BATCH_LOAD_LIMITS, REST_BATCH_LOAD_LIMITS } from '../../constants';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

// is AU product
const isAUProduct = (id,type) => {
    if ( type === '2' ) {
        return ( _find( PRODUCTS_USAGE_3, { myid: id }) ? true : false );
    } else if ( type === '3' ) {
        return ( _find( PRODUCTS_USAGE_3, { id }) ? true : false );
    } else {
        return false;
    }
}

// get AU product
const getAUProduct = (id,type) => {
    if ( type === '2' ) {
        return _find( PRODUCTS_USAGE_3, { myid: id });
    } else if ( type === '3' ) {
        return _find( PRODUCTS_USAGE_3, { id });
    } else {
        return false;
    }
}

// retrieve usage data by type
const getUsageDataByType = (type,idlist) => {
    return new Promise((resolve,reject) => {

        var list = [],
            promises = [],
            schema = getSelectedSchemas(type),
            ids = [];

        if ( idlist && isArrayExists( idlist ) ) {
            _forEach( idlist, item => {
                if ( item && item.id && !_isEmpty( item.id ) && !_find( ids, { id: item.id } ) )
                    promises.push( firebase.database().ref(type+"/"+item.id).once("value") );
            });
        } // end - idlist

        if ( schema && isArrayExists( schema ) && isArrayExists( promises ) ) {
            Promise.all( promises )
            .then( results => {
                if ( results && isArrayExists( results ) ) {
                    _forEach( results, result => {
                        if ( result && result.exists() ) {
                            let item = getSnapshotDataBySchema( schema, result );
                            list.push(item);
                        } // end - result
                    });
                } // end - results
                resolve({ type, list });
            })
            .catch( error => {
                reject(error);
            });
        } else {
            resolve({ type, list });
        }

    })
}

// retrieve usage id by type
const getUsageIDByType = (product_id) => {
    return new Promise((resolve,reject) => {

        var list = { accounts: [], contacts: [], potentials: [], quotes: [], invoices: [] },
            auProduct = false,
            notToAddList = { quotes: [], invoices: [] },
            qpromises = [];

        // if is AU product (2)
        if ( product_id && !_isEmpty( product_id ) && isAUProduct(product_id,'2') ) {
            auProduct = getAUProduct(product_id,'2');
            if ( auProduct ) {
                if ( auProduct.quotes && isArrayExists( auProduct.quotes ) ) {
                    _forEach( auProduct.quotes, quote_id => {
                        list.quotes.push({ id: quote_id });
                    });
                } // end - auProduct.quotes
                if ( auProduct.invoices && isArrayExists( auProduct.invoices ) ) {
                    _forEach( auProduct.invoices, invoice_id => {
                        list.invoices.push({ id: invoice_id });
                    });
                } // end - auProduct.invoices
            } // end - auProduct
        } else if ( product_id && !_isEmpty( product_id ) && isAUProduct(product_id,'3') ) {
            auProduct = getAUProduct(product_id,'3');
            if ( auProduct ) {
                if ( auProduct.quotes && isArrayExists( auProduct.quotes ) ) {
                    _forEach( auProduct.quotes, quote_id => {
                        notToAddList.quotes.push({ id: quote_id });
                    });
                } // end - auProduct.quotes
                if ( auProduct.invoices && isArrayExists( auProduct.invoices ) ) {
                    _forEach( auProduct.invoices, invoice_id => {
                        notToAddList.invoices.push({ id: invoice_id });
                    });
                } // end - auProduct.invoices
            } // end - auProduct
        } // end - product_id

        qpromises.push( firebase.database().ref("usage/products/"+product_id+"/potentials").once("value") );
        qpromises.push( firebase.database().ref("usage/products/"+product_id+"/quotes").once("value") );
        qpromises.push( firebase.database().ref("usage/products/"+product_id+"/invoices").once("value") );

        Promise.all( qpromises )
        .then( results => {

            var promises = [];

            if ( results && isArrayExists( results ) && results[0] && results[0].exists() && results[0].hasChildren() ) {
                results[0].forEach( childSnapshot => {
                    // make sure it wasn't exists already
                    if ( !_find( list.potentials, { id: childSnapshot.key } ) ) {
                        list.potentials.push({ id: childSnapshot.key });
                    }
                });
            } // end - results[0]

            if ( results && isArrayExists( results )  && results[1] && results[1].exists() && results[1].hasChildren() ) {
                results[1].forEach( childSnapshot => {
                    // make sure it wasn't exists already
                    if ( !_find( list.quotes, { id: childSnapshot.key } ) && !_find( notToAddList.quotes, { id: childSnapshot.key }) ) {
                        list.quotes.push({ id: childSnapshot.key });
                    }
                });
            } // end - results[1]

            if ( results && isArrayExists( results )  && results[2] && results[2].exists() && results[2].hasChildren() ) {
                results[2].forEach( childSnapshot => {
                    // make sure it wasn't exists already
                    if ( !_find( list.invoices, { id: childSnapshot.key } ) && !_find( notToAddList.invoices, { id: childSnapshot.key }) ) {
                        list.invoices.push({ id: childSnapshot.key });
                    }
                });
            } // end - results[2] 

            // add to promises based on list contents
            if ( list.potentials && isArrayExists( list.potentials ) ) {
                _forEach( list.potentials, potential => {
                    promises.push( firebase.database().ref("usage/potentials/"+potential.id+"/accounts").once("value") );
                    promises.push( firebase.database().ref("usage/potentials/"+potential.id+"/contacts").once("value") );
                });
            } // end - list.potentials

            if ( list.quotes && isArrayExists( list.quotes ) ) {
                _forEach( list.quotes, quote => {
                    promises.push( firebase.database().ref("usage/quotes/"+quote.id+"/accounts").once("value") );
                    promises.push( firebase.database().ref("usage/quotes/"+quote.id+"/contacts").once("value") );
                });
            } // end - list.quotes

            if ( list.invoices && isArrayExists( list.invoices ) ) {
                _forEach( list.invoices, invoice => {
                    promises.push( firebase.database().ref("usage/invoices/"+invoice.id+"/accounts").once("value") );
                    promises.push( firebase.database().ref("usage/invoices/"+invoice.id+"/contacts").once("value") );
                });
            } // end - list.invoices

            return ( isArrayExists( promises ) ? Promise.all( promises ) : false );
        })
        .then(results => {

            if ( results && isArrayExists( results ) ) {
                _forEach( results, result => {
                    if ( result && result.exists() && result.hasChildren() ) {
                        result.forEach( childSnapshot => {
                            var type = ( childSnapshot.key && _startsWith( childSnapshot.key, 'ACC' ) ? 'accounts' : ( childSnapshot.key && _startsWith( childSnapshot.key, 'CON' ) ? 'contacts' : '' ) );
                            switch( type ) {
                                case 'accounts':
                                    // make sure it wasn't exists already
                                    if ( !_find( list.accounts, { id: childSnapshot.key } ) ) {
                                        list.accounts.push({ id: childSnapshot.key });
                                    }
                                    break;
                                case 'contacts':
                                    // make sure it wasn't exists already
                                    if ( !_find( list.contacts, { id: childSnapshot.key } ) ) {
                                        list.contacts.push({ id: childSnapshot.key });
                                    }
                                    break;
                            }                            
                        });
                    } // end - result
                });
            } // end - results

            resolve(list);
        })
        .catch(error => {
            reject(error);
        });

    })
}

// get list from firebase based props
const getFirestoreList = (props) => {
    return new Promise((resolve,reject) => {

        var promises = [];
        promises.push( getItemsFromFirestore( "products", ( props && isObjectExists( props ) ? props : null ) ) );

        // if no keywords filter
        if ( !( props && props.searchterms && !_isEmpty( props.searchterms ) ) ) {
            promises.push( getTotalCountBySchema('products') );
        } // end - props.searchterms

        Promise.all( promises )
        .then(results => {
            resolve({ 
                list: ( results && results[0] && results[0].list && isArrayExists( results[0].list ) ? results[0].list : [] ), 
                firstDoc: ( results && results[0] && results[0].firstDoc ? results[0].firstDoc : false ),
                lastDoc: ( results && results[0] && results[0].lastDoc ? results[0].lastDoc : false ), 
                total: ( results && results[1] ? results[1] : 0 )
            });
        })
        .catch(error => {
            // only use it when needed
            // console.log(error);
            reject(error);
        });

    })
} 

/* helper end */

export const fb_getProductsFirstBatch = (props) => {
    return new Promise((resolve,reject) => {
        // const { perPage, region } = props;
        // var accessLimited = false;

        // let ref = firebase.database().ref("products");

        // // probably don't need this anymore
        // // if ( region && !_isEmpty( region ) ) {
        // //     accessLimited = true;
        // //     ref = ref.orderByChild('region_id').equalTo(region);
        // // } else {

        // // } // end - region

        // // limit to only perpage
        // ref = ref.orderByChild("modified_on/_seconds").limitToLast(( perPage || FIRST_BATCH_LOAD_LIMITS ));

        // ref.once("value")
        // .then(snapshot => {

        //     let list = [];

        //     if ( snapshot.exists() && snapshot.hasChildren() ) {
        //         snapshot.forEach( childSnapshot => {
        //             let item = getSnapshotDataBySchema( productSchema, childSnapshot );
        //             list.push(item);
        //         });
        //     } // end - snapshot

        //     resolve({ list, accessLimited });

        // })
        // .catch(error => {
        //     reject(error);
        // });

    })
}

export const fb_getProductsForTheRest = (endAt) => {
    return new Promise((resolve,reject) => {

        // let ref = firebase.database().ref("products");
        
        // // set limits to only 30 - for dev only
        // if ( SET_READ_LIMITS ) {
        //     ref = ref.orderByChild("modified_on/_seconds").endAt(endAt).limitToLast(REST_BATCH_LOAD_LIMITS);
        // } else {
        //     ref = ref.orderByChild("modified_on/_seconds").endAt(endAt);
        // } // end - SET_READ_LIMITS

        // ref.once("value")
        // .then(snapshot => {

        //     let list = [];

        //     if ( snapshot.exists() && snapshot.hasChildren() ) {
        //         snapshot.forEach( childSnapshot => {
        //             let item = getSnapshotDataBySchema( productSchema, childSnapshot );
        //             list.push(item);
        //         });
        //     } // end - snapshot

        //     resolve(list);

        // })
        // .catch(error => {
        //     reject(error);
        // });

    })
}

export const fb_getProducts = (props) => {
    return new Promise((resolve,reject) => {

        // var query = firebase.database().ref("products");

        // if ( props && props.region && !_isEmpty( props.region ) )
        //     query = query.orderByChild('region_id').equalTo(props.region);

        getFirestoreList(props)
        .then(payload => {
            resolve(payload);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getProduct = (product_id) => {
    return new Promise((resolve,reject) => {
        
        let promises = [];
        promises.push( firebase.firestore().collection("products").doc(product_id).get() );
        promises.push( firebase.database().ref("logs").orderByChild('type_id').equalTo(product_id).once("value") );
        
        Promise.all( promises )
        .then(results => {
            let item = {};

            if ( results && results[0] ) {
                item = getDocDataBySchema( productSchema, results[0] )
            } // end - results[0]

            // add updates
            item['updates'] = [];
            if ( results && results[1] && results[1].exists() && results[1].hasChildren() ) {
                results[1].forEach( childSnapshot => {
                    let update = getSnapshotDataBySchema( logSchema , childSnapshot );
                    item['updates'].push( update );
                });
            } // end - results[2]

            resolve(item);
        })
        .catch(error => {
            reject(error);
        });


    })
}

export const fb_getProductsBy = (type,id) => {
    return new Promise((resolve,reject) => {
        
        firebase.database().ref("usage/"+type+"/"+id+"/products").once("value")
        .then( snapshot => {
            var promises = [];
            if ( snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach( childSnapshot => {
                    promises.push( firebase.database().ref("products/"+childSnapshot.key).once("value") );
                });
            } // end - snapshpt

            return ( isArrayExists( promises ) ? Promise.all( promises ) : false );
        })
        .then(results => {

            let list = [];

            if ( results && isArrayExists( results ) ) {
                _forEach( results, result => {
                    if ( result && result.exists() ) {
                        let item = getSnapshotDataBySchema( productSchema, result );
                        list.push(item);
                    } // end - result
                });
            } // end - results

            resolve(list);

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getProductUsage = (id,user) => {
    return new Promise((resolve,reject) => {
        
        var product = false;

        firebase.database().ref("products/"+id).once("value")
        .then( snapshot => {
            product = ( snapshot.exists() ? snapshot.val() : false );
            // get all products from the same course_id
            // only retrieve if it's general product
            return ( product && product.course_id && !_isEmpty( product.course_id ) && !( product.schedule_id && !_isEmpty( product.schedule_id ) ) ? firebase.database().ref("products").orderByChild("course_id").equalTo(product.course_id).once("value") : false );
        })
        .then( snapshot => {
            var promises = [];

            // if list of products exists
            if ( snapshot && snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach( childSnapshot => {
                    var selectedProID = childSnapshot.key,
                        selectedPro = childSnapshot.val();
                    // based on the products (make sure it's from the same region)
                    if ( product.region_id && selectedPro.region_id && product.region_id === selectedPro.region_id ) {
                        promises.push( getUsageIDByType( selectedProID ) );
                    }
                });
            } else {
                // otherwise - just retrieve it based on the original ID
                promises.push( getUsageIDByType( id ) );
            } // end - snapshpt

            return ( isArrayExists( promises ) ? Promise.all( promises ) : false );
        })
        .then( results => {
            var promises = [],
                accounts = [],
                contacts = [],
                potentials = [],
                quotes = [],
                invoices = [];

            if ( results && isArrayExists( results ) ) {
                _forEach( results, result => {

                    if ( result && result.accounts && isArrayExists( result.accounts ) ) {
                        _forEach( result.accounts, item => {
                            if ( item && item.id && !_isEmpty( item.id ) && !_find( accounts, { id: item.id }) )
                                accounts.push({ id: item.id });
                        })
                    } // end - result.accounts

                    if ( result && result.contacts && isArrayExists( result.contacts ) ) {
                        _forEach( result.contacts, item => {
                            if ( item && item.id && !_isEmpty( item.id ) && !_find( contacts, { id: item.id }) )
                                contacts.push({ id: item.id });
                        })
                    } // end - result.contacts

                    if ( result && result.potentials && isArrayExists( result.potentials ) ) {
                        _forEach( result.potentials, item => {
                            if ( item && item.id && !_isEmpty( item.id ) && !_find( potentials, { id: item.id }) )
                                potentials.push({ id: item.id });
                        })
                    } // end - result.potentials

                    if ( result && result.quotes && isArrayExists( result.quotes ) ) {
                        _forEach( result.quotes, item => {
                            if ( item && item.id && !_isEmpty( item.id ) && !_find( quotes, { id: item.id }) )
                                quotes.push({ id: item.id });
                        })
                    } // end - result.quotes

                    if ( result && result.invoices && isArrayExists( result.invoices ) ) {
                        _forEach( result.invoices, item => {
                            if ( item && item.id && !_isEmpty( item.id ) && !_find( invoices, { id: item.id }) )
                                invoices.push({ id: item.id });
                        })
                    } // end - result.invoices
                });
            } // end - results

            // retrieve the data via promises
            promises.push( getUsageDataByType( 'accounts', accounts ) );
            promises.push( getUsageDataByType( 'contacts', contacts ) );
            promises.push( getUsageDataByType( 'potentials', potentials ) );
            promises.push( getUsageDataByType( 'quotes', quotes ) );
            promises.push( getUsageDataByType( 'invoices', invoices ) );

            return ( isArrayExists( promises ) ? Promise.all( promises ) : false );
        })
        .then( results => {
            var data = { accounts: [], contacts: [], potentials: [], quotes: [], invoices: [] };

            if ( results && isArrayExists( results ) ) {
                _forEach( results, result => {
                    if ( result && result.type && !_isEmpty( result.type ) ) {
                        switch ( result.type ) {
                            case 'accounts': data.accounts = ( result.list || [] ); break;
                            case 'contacts': data.contacts = ( result.list || [] ); break;
                            case 'potentials': data.potentials = ( result.list || [] ); break;
                            case 'quotes': data.quotes = ( result.list || [] ); break;
                            case 'invoices': data.invoices = ( result.list || [] ); break;
                        } // end - result.type
                    } // end - result.type
                });
            } // end - results

            // for limited access user only
            if ( user && hasLimitedAccess(user) ) {
                data.accounts = _filter( data.accounts, (i) => i.assigned_to && isArrayExists( i.assigned_to ) && _find( i.assigned_to, { id: user.email }) ? true : false );
                data.contacts = _filter( data.contacts, (i) => i.assigned_to && isArrayExists( i.assigned_to ) && _find( i.assigned_to, { id: user.email }) ? true : false );
                data.potentials = _filter( data.potentials, (i) => i.assigned_to && isArrayExists( i.assigned_to ) && _find( i.assigned_to, { id: user.email }) ? true : false );
                data.quotes = _filter( data.quotes, (i) => i.assigned_to && isArrayExists( i.assigned_to ) && _find( i.assigned_to, { id: user.email }) ? true : false );
                data.invoices = _filter( data.invoices, (i) => i.assigned_to && isArrayExists( i.assigned_to ) && _find( i.assigned_to, { id: user.email }) ? true : false );
            } // end - user

            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getProductsOptions = (callback,props) => {

    var query = firebase.database().ref("maintenance/options/products");

    if ( props && props.region && !_isEmpty( props.region ) )
        query = query.orderByChild('region_id').equalTo(props.region);

    if ( SET_READ_LIMITS ) {
        query = query.limitToLast(5);
    }

    query.on("value",snapshot => {

        var options = [];

        if ( snapshot.exists() && snapshot.hasChildren() ) {
            snapshot.forEach(childSnapshot => {
                var option = childSnapshot.val();
                option['id'] = childSnapshot.key;
                options.push(option);
            });
        } // end - snapshot
 
        callback(options);

    });

}

export const fb_addProduct = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'product', action: 'add', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateProduct = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'product', action: 'update', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteProduct = (id) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'product', action: 'delete', formData: { id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_syncSchedules = () => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'sync', action: 'sync_course_schedules' })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_syncCourseProfileToProducts = () => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'sync', action: 'sync_course_profile_to_products' })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}