import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _sortBy from 'lodash/sortBy';

/* eslint-disable */

import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormSelect2 from '../../components/FormSelect2';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormDatePicker from '../../components/FormDatePicker';

import SelectAccounts from '../../components/SelectAccounts';
import SelectContacts from '../../components/SelectContacts';
import SelectProducts from '../../components/SelectProducts';

import ModalChangeContactAccount from '../../components/ModalChangeContactAccount';

import ListUpdates from '../../components/ListUpdates';
import ListComments from '../../components/ListComments';

import { InfoButton } from '../../styles/button';
// import { WrapWord } from '../../styles/misc';
import { FormBox } from '../../styles/form';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, getSelectValues } from '../../helpers/data';
import { reverseUsersValues } from '../../helpers/users';
import { getMomentTime } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';
import { getDataFromDoc } from '../../helpers/firebase';

import { toggleLoader } from '../../actions/global';

import { potentialSchema } from '../../schemas/potential';


const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

class PotentialsDetails extends React.Component {

    state = {
        copyEmail: 'contacts',
        randNum: false
    };

    handleFormUpdate = ( newValue, key ) => {
        const { onFormUpdate, potential, users } = this.props;
        let newData = ( potential && !_isEmpty( potential ) ? cloneCollections( potential ) : {} );

        switch( key ) {
            case 'assigned_to':
                newData[key] = reverseUsersValues( newValue, users );
                break;
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    handleCopyEmail = (event) => {
        event.preventDefault();
        const { copyEmail } = this.state;
        const { potential, onFormUpdate } = this.props;

        // make sure copyEmail aren't empty
        if ( copyEmail && !_isEmpty( copyEmail ) ) {

            // toggle loader
            this.props.dispatch(toggleLoader(true));

            var selected_id = false;

            switch( copyEmail ) {
                case 'accounts': 
                    if ( potential && potential.accounts_linked && isArrayExists( potential.accounts_linked ) ) {
                        _forEach( potential.accounts_linked, account => {
                            selected_id = ( account.id && !_isEmpty( account.id ) ? account.id : false );
                        });
                    } // end - potential.accounts_linked
                    break;
                case 'contacts': 
                    if ( potential && potential.contacts_linked && isArrayExists( potential.contacts_linked ) ) {
                        _forEach( potential.contacts_linked, contact => {
                            selected_id = ( contact.id && !_isEmpty( contact.id ) ? contact.id : false );
                        });
                    } // end - potential.contacts_linked
                    break;
            }

            if ( selected_id ) {

                getDataFromDoc( copyEmail, selected_id )
                .then( data => {
                    let newData = ( potential && !_isEmpty( potential ) ? cloneCollections( potential ) : {} );

                    newData['email'] = ( data.email || '' );

                     // un-toggle loader
                     this.props.dispatch(toggleLoader(false));

                    // do update
                    if ( onFormUpdate )
                        onFormUpdate( newData );
                })
                .catch(error => {
                     // un-toggle loader
                    this.props.dispatch(toggleLoader(false));

                    var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve billing info' ) );
                    triggerErrorAlert( errMsg );
                });

            } else {
                // un-toggle loader
                this.props.dispatch(toggleLoader(false));

                // trigger error
                var errorMessage = ( copyEmail && copyEmail === 'accounts' ? 'No account selected' : 'No contact selected' );
                triggerErrorAlert( errorMessage );
            } // end - selected_id

        } // end - copyEmail

    }

    getFieldOptions = (field) => {
        const { users, selectables, potential } = this.props;
        switch( field.name ) {
            case 'sales_stage':
                return [
                    { value: '', label: 'Select an Option' },
                    { value: 'Prospecting', label: 'Prospecting' },
                    { value: 'Qualification', label: 'Qualification' },
                    { value: 'Proposal/Quotation', label: 'Proposal/Quotation' },
                    { value: 'Negotiation or Review', label: 'Negotiation or Review' },
                    { value: 'Closed Won', label: 'Closed Won' },
                    { value: 'Closed Lost', label: 'Closed Lost' },
                ]
            case 'type':
                return [
                    { value: '', label: 'Select an Option' },
                    { value: 'Existing Business', label: 'Existing Business' },
                    { value: 'New Business', label: 'New Business' }
                ]
            case 'class_type':
                return [
                    { value: '', label: 'Select an Option' },
                    { value: 'inhouse', label: 'inhouse' },
                    { value: 'Public', label: 'Public' }
                ]
            case 'sales_person':
                var sales_person_options = [{ value: '', label: 'Select an Option' }];
                if ( potential && potential.assigned_to && isArrayExists( potential.assigned_to ) ) {
                    _forEach( potential.assigned_to, user => {
                        sales_person_options.push({ value: user.id, label: user.name });
                    });
                } // end - potential.assigned_to
                return sales_person_options;
            case 'assigned_to':
                return getSelectOptions({ list: ( users || [] ), keys: { value: 'email', label: 'name' }, sortBy: 'label' });
            default:
                return [];
        }
    }

    getFieldValueObj = (field) => {
        const { potential } = this.props;
        var val = '';
        switch( field.name ) {
            case 'accounts_linked':
            case 'contacts_linked':
                // turn array to string
                if ( potential && potential[field.name] && isArrayExists( potential[field.name] ) ) {
                    potential[field.name].forEach(item => {
                        val = cloneCollections( item );
                    });
                } // end - potential[field.name]
                break;
        }
        return val;
    }

    getFieldValue = (field) => {
        const { potential } = this.props;
        switch( field.name ) {
            case 'accounts_linked':
            case 'contacts_linked':
                let val = '';
                // turn array to string
                if ( potential && potential[field.name] && isArrayExists( potential[field.name] ) ) {
                    potential[field.name].forEach(item => {
                        val = ( item.id || false );
                    });
                } // end - potential[field.name]
                return val;
            case 'assigned_to':
                return ( potential && potential[field.name] && isArrayExists( potential[field.name] ) ? getSelectValues( potential[field.name], 'id' ) : [] );
            default:
                return ( potential && potential[field.name] || ( field.default || '' ) );
        }
    }

    isFieldDisabled = (schema) => {
        const { authData, potential, contacts } = this.props;
        var disabled = false;

        if ( schema && schema.disabled && isArrayExists( schema.disabled ) ) {
            schema.disabled.forEach( condition => {
                switch( condition ) {
                    case 'admin':
                        if ( !isAdmin( authData ) )
                            disabled = true;
                        break;
                    case 'update':
                        disabled = true;
                        break;
                }
            });
        } // end - schema

        return disabled
    }

    getField = (id) => {
        let schema = _find( potentialSchema, { id } );
        return ( schema ? {
            name: ( schema.id || '' ),
            label: ( schema.label || '' ) + ( isSchemaRequired(schema,'update') ? ' (Required)' : '' ),
            field_type: ( schema.field || '' ),
            default: ( schema.default || null ),
            disabled: ( schema.disabled ? this.isFieldDisabled( schema ) : false )
        } : null );
    }

    renderAccountChangePrompt = () => {
        const { potential, authData } = this.props;
        return potential && potential.accounts_linked && isArrayExists( potential.accounts_linked ) && potential.contacts_linked && isArrayExists( potential.contacts_linked ) && potential.accounts_linked[0].id && potential.contacts_linked[0].account_id && potential.contacts_linked[0].account_id !== potential.accounts_linked[0].id ? <ModalChangeContactAccount
            potential={potential}
            account={potential.accounts_linked[0]}
            contact={potential.contacts_linked[0]}
            onContactUpdate={(contact_id,account_id) => {
                const { potential, onFormUpdate } = this.props;
                let newData = ( potential ? cloneCollections( potential ) : {} ),
                    newContacts = ( potential.contacts_linked && isArrayExists( potential.contacts_linked ) ? cloneCollections( potential.contacts_linked ) : [] ),
                    index = _findIndex( newContacts, { id: contact_id });
                if ( index >= 0 ) {
                    newContacts[index].account_id = account_id;
                } // end - index

                // update contacts_linked
                newData['contacts_linked'] = newContacts;
                console.log('update');
                // do update
                if ( onFormUpdate )
                    onFormUpdate( newData );
            }} /> : null;
    }

    renderField = (id) => {
        const { potential, authData } = this.props;
        let field = this.getField(id);
        if ( field && field.field_type && !_isEmpty( field.field_type ) ) {
            switch( field.field_type ) {
                case 'text':
                    return <FormInput {...field} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'textarea':
                    return <FormInput {...field} rows={( 'notes' === id ? 6 : 3 )} multiline={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'text_number':
                    return <FormInput {...field} type="number" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'email':
                    return <FormInput {...field} type="email" value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
                case 'select2':
                    if ( id === 'product_id' ) {
                        return <SelectProducts
                            {...field} 
                            showCoreCourseOnly={true}
                            value={this.getFieldValue(field)}
                            onChange={(newProducts,key) => {
                                const { potential, onFormUpdate } = this.props;
                                var newData = ( potential ? cloneCollections( potential ) : {} );
                                newData[key] = ( newProducts && isArrayExists( newProducts ) && newProducts[0] && newProducts[0].id ? newProducts[0].id : '' ); // update product_id

                                // get product_label
                                newData['product_label'] =  ( newProducts && isArrayExists( newProducts ) && newProducts[0] && newProducts[0].name ? newProducts[0].name : '' ); 
                                newData['name'] =  ( newProducts && isArrayExists( newProducts ) && newProducts[0] && newProducts[0].name ? newProducts[0].name : '' ); 

                                // do update
                                if ( onFormUpdate )
                                    onFormUpdate( newData );
                            }} />
                    } else if ( id === 'accounts_linked' ) {
                        return <SelectAccounts
                            {...field} 
                            value={this.getFieldValue(field)}
                            valueObj={this.getFieldValueObj(field)}
                            onChange={(newAccounts,key) => {
                                const { potential, onFormUpdate } = this.props;
                                var newData = ( potential ? cloneCollections( potential ) : {} );
                                if ( newAccounts && newAccounts[0] && newAccounts[0].id ) {
                                    // update account
                                    newData[key] = newAccounts;

                                    // update assigned_to
                                    if ( newAccounts && newAccounts[0] && newAccounts[0].assigned_to && isObjectExists( newAccounts[0].assigned_to ) ) {
                                        newData['assigned_to'] = [];
                                        _forEach( newAccounts[0].assigned_to, user => {
                                            newData['assigned_to'].push( user );
                                        });
                                    } // end - newAccounts[0].assigned_to

                                } else {
                                    // reset
                                    newData[key] = [];
                                    newData['contacts_linked'] = [];

                                    // reset assigned_to back to logged in user
                                    newData['assigned_to'] = ( authData && authData.name && authData.email ? [{ id: authData.email, name: authData.name }] : [] );
                                } // end - account

                                // do update
                                if ( onFormUpdate )
                                    onFormUpdate( newData );
                            }} />
                    } else if ( id === 'contacts_linked' ) {
                        return (
                        <div>
                            <SelectContacts
                                {...field} 
                                value={this.getFieldValue(field)}
                                valueObj={this.getFieldValueObj(field)}
                                selected_accounts={( potential && potential.accounts_linked && isArrayExists( potential.accounts_linked ) ? potential.accounts_linked : [] )} 
                                onChange={(newContacts,key) => {
                                    const { potential, onFormUpdate } = this.props;
                                    var newData = ( potential ? cloneCollections( potential ) : {} );
                                    newData[key] = ( newContacts && newContacts[0] && newContacts[0].id && !_isEmpty( newContacts[0].id ) ? newContacts : [] );

                                    // do update
                                    if ( onFormUpdate )
                                        onFormUpdate( newData );
                                }} />
                            {this.renderAccountChangePrompt()}
                        </div>
                        )
                    } else {
                        return <FormSelect2 placeholder="Select an Option" {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} />;
                    } // end - id
                case 'select':
                    return <div style={{ paddingTop: "7px" }}><FormSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} /></div>;
                case 'multiselect':
                    return <div style={{ paddingTop: "7px" }}><FormMultiSelect {...field} value={this.getFieldValue(field)} options={this.getFieldOptions(field)} onChange={this.handleFormUpdate} /></div>;
                case 'datepicker':
                    return <FormDatePicker {...field} noDefaultVal={true} value={this.getFieldValue(field)} onChange={this.handleFormUpdate} />;
            }
        } // end - field.field_type
    }

    renderUpdatesBox = () => {
        const { classes, potential } = this.props;
        return (
        <div>
            <Typography variant="h4" className={classes.boxheading}>Updates</Typography>
            <ListUpdates 
                list={( potential && potential.updates && isArrayExists( potential.updates ) ? potential.updates : false )} />
        </div>
        );
    }

    renderCommentsBox = () => {
        const { classes, potential, authData, updateActions } = this.props;
        return (
        <div>
            <Typography variant="h4" className={classes.boxheading}>Comments</Typography>
            <ListComments 
                authData={( authData || false )}
                refer_type="potential"
                refer_id={( potential && potential.id && !_isEmpty( potential.id ) ? potential.id : false )}
                refer_label={( potential && potential.name && !_isEmpty( potential.name ) ? potential.name : false )}
                updateActions={( updateActions || false )}
                list={( potential && potential.comments && isArrayExists( potential.comments ) ? potential.comments : false )} />
        </div>
        );
    }

    renderCopyEmailField = () => {
        const { copyEmail } = this.state;
        return (
        <div style={{ marginBottom: '0px' }}>
            <Grid container justify="flex-start" spacing={0}>
                <div style={{ width: '400px' }}><FormSelect label="Copy Email From" value={( copyEmail || '' )} options={[
                    // { value: '', label: 'None' },
                    { value: 'accounts', label: 'Account' },
                    { value: 'contacts', label: 'Contact' },
                ]} onChange={(newValue) => this.setState({ copyEmail: newValue })} /></div>
                <InfoButton disabled={ copyEmail && !_isEmpty( copyEmail ) ? false : true } style={{ marginLeft: '5px' }} onClick={this.handleCopyEmail}><i className="fa fa-clone"></i>Copy</InfoButton>
            </Grid>
        </div>
        )
    }

    renderDetailsBox = () => {
        const { classes, potential } = this.props;
        return (
        <FormBox style={{ paddingBottom: "30px" }}>
            <Typography variant="h4" className={classes.boxheading}>Potential Info</Typography>
            <Grid container spacing={3}>

                <Grid item xs={6}>{this.renderField('name')}</Grid>
                <Grid item xs={6}>{this.renderField('product_id')}</Grid>
                <Grid item xs={6}>{this.renderField('accounts_linked')}</Grid>
                <Grid item xs={6}>{this.renderField('contacts_linked')}</Grid>
                <Grid item xs={6}>{this.renderField('email')}</Grid>
                <Grid item xs={6}>{this.renderCopyEmailField()}</Grid>

                <Grid item xs={12}><Divider style={{ marginTop: '10px', marginBottom: '10px' }} /></Grid>

                <Grid item xs={6}>{this.renderField('expected_close_date')}</Grid>
                <Grid item xs={6}>{this.renderField('sales_stage')}</Grid>
                <Grid item xs={6}>{this.renderField('num_of_pax')}</Grid>
                <Grid item xs={6}>{this.renderField('probability')}</Grid>
                <Grid item xs={6}>{this.renderField('amount')}</Grid>
                <Grid item xs={6}>{this.renderField('type')}</Grid>
                <Grid item xs={6}>{this.renderField('sales_person')}</Grid>
                <Grid item xs={6}>{this.renderField('class_type')}</Grid>

                <Grid item xs={12}>{this.renderField('notes')}</Grid>

                <Grid item xs={6}>{this.renderField('assigned_to')}</Grid>

            </Grid>

            <div style={{ paddingTop: "10px", textAlign: 'right', color: '#999', fontSize: '1.25rem' }}>
                <div>{ potential.created_on ? 'Created on ' + getMomentTime( (potential.created_on) , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
                <div>{ potential.modified_on ? 'Last Modified on ' + getMomentTime( (potential.modified_on) , 'YYYY-MM-DD hh:mm:ssa' ) : ''}</div>
            </div>

        </FormBox>
        );
    }
   
    render() {
        return (
        <div>

            {this.renderDetailsBox()}

            <Grid container spacing={3}>
                <Grid item xs={6}>
                {this.renderCommentsBox()}
                </Grid>
                <Grid item xs={6}>
                    {this.renderUpdatesBox()}
                </Grid>
            </Grid>

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(PotentialsDetails);