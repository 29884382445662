/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import _merge from "lodash/merge";
import _size from "lodash/size";
import _isEqual from "lodash/isEqual";

import AppWrapper from "../../components/AppWrapper";
import Contacts from "./contacts";
import ContactsFireStore from "./contacts_fs";

import { hasLimitedAccess } from "../../helpers/auth";
import { cloneCollections } from "../../helpers/data";

import { getContacts } from "../../actions/contacts";
import { getUsersOptions } from "../../actions/users";
import { getSelectables } from "../../actions/m_selectables";
import { getLeadSource } from "../../actions/m_lead_source";
import { getMailingLists } from "../../actions/m_mailing_lists";
import { resetRedux } from "../../actions/misc";

class ContactsPage extends React.Component {
    state = {
        query: {
            searchterms: "",
            filterBy: "",
            sortBy: "modified_on-desc",
            perPage: 20,
            page: 1,
        },
        randNum: false,
    };

    componentDidMount() {
        // get contacts list
        this.props.dispatch(getContacts());

        // get all users
        if (!this.props.usersOptions) this.props.dispatch(getUsersOptions());

        // get selectables
        if (!this.props.selectablesList) this.props.dispatch(getSelectables());

        // get lead source
        if (!this.props.leadSourceList) this.props.dispatch(getLeadSource());

        // get mailing lists
        if (!this.props.mailingList) this.props.dispatch(getMailingLists());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { contactsList, usersOptions, randNum } = this.props;

        // once all the data is loaded
        if (contactsList && usersOptions && randNum && randNum != this.state.randNum) this.setState({ randNum });
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux("contacts"));
    }

    handleRefresh = () => {
        // get contacts
        this.setState({ randNum: false });
        this.props.dispatch(resetRedux("contacts"));
        this.props.dispatch(getContacts());
    };

    handleFSRefresh = () => {
        const { query } = this.state;
        this.setState({ randNum: false });
        this.props.dispatch(resetRedux("contacts"));
        this.props.dispatch(getContacts(query));
    };

    handleFSQueryChange = (props) => {
        const { query } = this.state;
        var newQuery = this.setNewFSQuery(props);
        if (this.ifOnlySortChanged(newQuery) && newQuery.searchterms && !_isEmpty(newQuery.searchterms)) {
            // for search sort - no need to trigger reload
            this.setState({ query: newQuery });
        } else {
            this.setState({ query: newQuery, randNum: false });
            this.props.dispatch(resetRedux("contacts"));
            this.props.dispatch(getContacts(newQuery));
        } // end - newQuery
    };

    ifOnlySortChanged = (newQuery) => {
        const { query } = this.state;
        var changed = false;
        if (!_isEqual(query.sortBy, newQuery.sortBy)) {
            if (
                !_isEqual(query.searchterms, newQuery.searchterms) ||
                !_isEqual(query.filterBy, newQuery.filterBy) ||
                !_isEqual(query.perPage, newQuery.perPage) ||
                !_isEqual(query.page, newQuery.page)
            )
                changed = true;
        } else {
            changed = true;
        }
        return changed ? false : true;
    };

    setNewFSQuery = (query) => {
        const { contactsFirstDoc, contactsLastDoc } = this.props;
        var currentQuery = this.getCurrentFSQuery(),
            newQuery = _merge(this.getCurrentFSQuery(), query);
        newQuery.endBefore = newQuery.page >= 2 && newQuery.page < currentQuery.page && contactsFirstDoc ? contactsFirstDoc : false;
        newQuery.startAfter = newQuery.page >= 2 && newQuery.page > currentQuery.page && contactsLastDoc ? contactsLastDoc : false;
        return newQuery;
    };

    getCurrentFSQuery = () => {
        const { query } = this.state;
        var currentQuery = {};
        if (query && !_isEmpty(query)) {
            const { searchterms, sortBy, filterBy, perPage, page } = query;
            currentQuery = cloneCollections({ searchterms, sortBy, filterBy, perPage, page });
        }
        return currentQuery;
    };

    renderContents() {
        const { authData, contactsList, contactsLoaded, usersOptions, contactsTotal, mailingList } = this.props;
        const { query } = this.state;
        return authData && hasLimitedAccess(authData) ? (
            <Contacts
                authData={authData}
                contacts={contactsList}
                contactsLoaded={contactsLoaded}
                users={usersOptions}
                mailing_lists={mailingList}
                onRefresh={this.handleRefresh}
            />
        ) : (
            <ContactsFireStore
                authData={authData}
                contacts={contactsList}
                contactsLoaded={contactsLoaded}
                users={usersOptions}
                mailing_lists={mailingList}
                total={contactsTotal}
                currentTotal={contactsList ? _size(contactsList) : 0}
                currentQuery={this.getCurrentFSQuery()}
                searchterms={query.searchterms || ""}
                sortBy={query.sortBy || "modified_on-desc"}
                filterBy={query.filterBy || ""}
                perPage={query.perPage || 20}
                page={query.page || 1}
                onRefresh={this.handleFSRefresh}
                onQueryChange={this.handleFSQueryChange}
            />
        );
    }

    render() {
        const { randNum } = this.state;
        const { contactsList, authData } = this.props;
        return <AppWrapper title="Contacts" onLoad={!(contactsList && randNum) ? true : false} contents={authData ? this.renderContents() : null} />;
    }
}

const mapStateToProps = (state) => {
    return {
        authData: (state.auth && state.auth.user) || null,
        contactsList: (state.contacts && state.contacts.contacts) || null,
        contactsTotal: (state.contacts && state.contacts.total) || null,
        contactsFirstDoc: (state.contacts && state.contacts.firstDoc) || null,
        contactsLastDoc: (state.contacts && state.contacts.lastDoc) || null,
        contactsLoaded: (state.contacts && state.contacts.contacts_loaded) || null,
        randNum: (state.contacts && state.contacts.rand) || null,
        selectablesList: (state.maintenance && state.maintenance.selectables) || null,
        leadSourceList: (state.maintenance && state.maintenance.lead_source) || null,
        mailingList: (state.maintenance && state.maintenance.mailing_lists) || null,
        usersOptions: (state.users && state.users.users_options) || null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(ContactsPage);
