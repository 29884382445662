/* eslint-disable */
import _random from 'lodash/random';

import {
    M_GET_CONSULTANTS,
    M_CONSULTANTS_UPDATED
} from '../types';

import { 
	// toggleModalDeleting, toggleModalProcessing, 
	toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
// import { appChangesReset } from '../misc';

import { fb_getConsultants, fb_syncConsultants } from './fb';

export const getConsultants = () => {
	return dispatch => {

		fb_getConsultants(list => {
			dispatch({ 
                type: M_GET_CONSULTANTS, 
                payload: { list } 
            });
		});

	}
}

export const syncDataWithLMS = (id,type) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_syncConsultants()
		.then(() => {
			dispatch({ type: M_CONSULTANTS_UPDATED });
			dispatch(toggleLoader(false));
			triggerSuccessAlert("Consultant Synced!");
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to sync data with LMS' ) );
			dispatch(toggleLoader(false));
			triggerErrorAlert(errMsg);
		});

  	}
}