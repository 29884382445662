export const SAMPLE_ACTION = 'sample_action';

/* auth */
export const IS_LOGGED_IN = 'is_logged_in';
export const AUTH_LOGIN = 'auth_login';
export const AUTH_LOGOUT = 'auth_logout';
export const AUTH_EDIT_PROFILE = 'auth_edit_profile';

/* users actions */
export const GET_USERS = 'get_users';
export const GET_USERS_OPTIONS = 'get_users_options';
export const ADD_USER = 'add_user';
export const EDIT_USER = 'edit_user';
export const DELETE_USER = 'delete_user';
export const SYNC_USERS = 'sync_users';

/* Accounts actions */
export const GET_ACCOUNTS = 'get_accounts';
export const GET_ACCOUNTS_BY = 'get_accounts_by';
export const GET_ACCOUNTS_OPTIONS = 'get_accounts_options';
export const GET_ACCOUNT = 'get_account';
export const GET_DUPLICATES_ACCOUNT = 'get_duplicates_account';
export const FIND_DUPLICATES_ACCOUNT = 'find_duplicates_account';
export const RESET_DUPLICATES_ACCOUNT = 'get_duplicates_account';
export const MERGE_ACCOUNTS = 'merge_accounts';
export const ADD_ACCOUNT = 'add_account';
export const EDIT_ACCOUNT = 'edit_account';
export const DELETE_ACCOUNT = 'delete_account';
export const RESET_ACCOUNT = 'reset_account';
export const RESET_ACCOUNTS = 'reset_accounts';
export const RESET_GET_ACCOUNTS_BY = 'reset_get_accounts_by';

/* contacts actions */
export const GET_CONTACTS = 'get_contacts';
export const GET_CONTACTS_BY = 'get_contacts_by';
export const GET_CONTACTS_OPTIONS = 'get_contacts_options';
export const UPDATE_CONTACTS_OPTIONS_RAND = 'update_contacts_options_rand';
export const GET_CONTACT = 'get_contact';
export const ADD_CONTACT = 'add_contact';
export const EDIT_CONTACT = 'edit_contact';
export const DELETE_CONTACT = 'delete_contact';
export const RESET_CONTACT = 'reset_contact';
export const RESET_CONTACTS = 'reset_contacts';
export const RESET_GET_CONTACTS_BY = 'reset_get_contacts_by';

/* import contacts from LMS actions */
export const IMPORT_CONTACTS_FROM_LMS = 'import_contacts_from_lms';
export const RESET_IMPORT_CONTACTS_FROM_LMS = 'reset_import_contacts_from_lms';

/* import contacts from CSV actions */
export const IMPORT_CONTACTS_FROM_CSV = 'import_contacts_from_csv';
export const RESET_IMPORT_CONTACTS_FROM_CSV = 'reset_import_contacts_from_csv';

/* products actions */
export const GET_PRODUCTS = 'get_products';
export const GET_PRODUCTS_BY = 'get_products_by';
export const GET_PRODUCTS_OPTIONS = 'get_products_options';
export const GET_PRODUCT = 'get_product';
export const ADD_PRODUCT = 'add_product';
export const EDIT_PRODUCT = 'edit_product';
export const DELETE_PRODUCT = 'delete_product';
export const RESET_PRODUCT = 'reset_product';
export const RESET_PRODUCTS = 'reset_products';
export const RESET_PRODUCTS_OPTIONS = 'reset_products_options';
export const RESET_GET_PRODUCTS_BY = 'reset_get_products_by';

/* potentials actions */
export const GET_POTENTIALS = 'get_potentials';
export const GET_POTENTIALS_BY = 'get_potentials_by';
export const GET_POTENTIALS_OPTIONS = 'get_potentials_options';
export const GET_POTENTIAL = 'get_potential';
export const ADD_POTENTIAL = 'add_potential';
export const EDIT_POTENTIAL = 'edit_potential';
export const DELETE_POTENTIAL = 'delete_potential';
export const RESET_POTENTIAL = 'reset_potential';
export const RESET_POTENTIALS = 'reset_potentials';
export const RESET_GET_POTENTIALS_BY = 'reset_get_potentials_by';

/* Quotes actions */
export const GET_QUOTES = 'get_quotes';
export const GET_QUOTES_BY = 'get_quotes_by';
export const GET_QUOTE = 'get_quote';
export const ADD_QUOTE = 'add_quote';
export const EDIT_QUOTE = 'edit_quote';
export const DELETE_QUOTE = 'delete_quote';
export const RESET_QUOTE = 'reset_quote';
export const RESET_QUOTES = 'reset_quotes';
export const RESET_GET_QUOTES_BY = 'reset_get_quotes_by';

/* invoices actions */
export const GET_INVOICES = 'get_invoices';
export const GET_INVOICES_BY = 'get_invoices_by';
export const GET_INVOICE = 'get_invoice';
export const ADD_INVOICE = 'add_invoice';
export const EDIT_INVOICE = 'edit_invoice';
export const DELETE_INVOICE = 'delete_invoice';
export const RESET_INVOICE = 'reset_invoice';
export const RESET_INVOICES = 'reset_invoices';
export const RESET_GET_INVOICES_BY = 'reset_get_invoices_by';

/* credit notes actions */
export const GET_CREDIT_NOTES = 'get_credit_notes';
export const GET_CREDIT_NOTES_BY = 'get_credit_notes_by';
export const GET_CREDIT_NOTE = 'get_credit_note';
export const ADD_CREDIT_NOTE = 'add_credit_note';
export const EDIT_CREDIT_NOTE = 'edit_credit_note';
export const DELETE_CREDIT_NOTE = 'delete_credit_note';
export const RESET_CREDIT_NOTE = 'reset_credit_note';
export const RESET_CREDIT_NOTES = 'reset_credit_notes';
export const RESET_GET_CREDIT_NOTES_BY = 'reset_get_credit_notes_by';

/* emails actions */
export const GET_EMAILS_BY = 'get_emails_by';
export const GET_EMAIL = 'get_email';
export const ADD_EMAIL = 'add_email';
export const EDIT_EMAIL = 'edit_email';
export const DELETE_EMAIL = 'delete_email';
export const RESET_EMAIL = 'reset_email';
export const RESET_EMAILS = 'reset_emails';
export const RESET_GET_EMAILS_BY = 'reset_get_emails_by';


/* email templates actions */
export const GET_EMAIL_TEMPLATES = 'get_email_templates';
export const GET_EMAIL_TEMPLATE = 'get_email_template';
export const ADD_EMAIL_TEMPLATE = 'add_email_template';
export const EDIT_EMAIL_TEMPLATE = 'edit_email_template';
export const DELETE_EMAIL_TEMPLATE = 'delete_email_template';
export const RESET_EMAIL_TEMPLATES = 'reset_email_templates';
export const RESET_EMAIL_TEMPLATE = 'reset_email_template';

/* mass actions */
export const DO_MASS_ACTION = 'do_mass_action';
export const RESET_MASS_ACTION = 'reset_mass_action';

/* report actions */
export const GENERATE_REPORT = 'generate_report';
export const GET_REPORT_TEMPLATES = 'get_report_templates';
export const GET_REPORT_TEMPLATE = 'get_report_template';
export const ADD_REPORT_TEMPLATE = 'add_report_template';
export const UPDATE_REPORT_TEMPLATE = 'update_report_template';
export const DELETE_REPORT_TEMPLATE = 'delete_report_template';
export const GET_REPORT_CATEGORIES = 'get_report_categories';
export const ADD_REPORT_CATEGORY = 'add_report_category';
export const UPDATE_REPORT_CATEGORY = 'update_report_category';
export const DELETE_REPORT_CATEGORY = 'delete_report_category';
export const RESET_REPORT = 'reset_report';

/* comments actions */
export const GET_COMMENTS = 'get_comments';
export const GET_LATEST_COMMENTS = 'get_latest_comments';
export const GET_COMMENT = 'get_comment';
export const ADD_COMMENT = 'add_comment';
export const ADD_COMMENTS = 'add_comments';
export const RESET_COMMENT = 'reset_comment';

/* updates actions */
export const GET_LATEST_UPDATES = 'get_latest_updates';

/* media library */
export const GET_MEDIA_LIBRARY = 'get_media_library';
export const UPLOAD_NEW_MEDIA = 'upload_new_media';
export const DELETE_MEDIA = 'delete_media';

/* maintenance actions */
export const M_GET_REGIONS = 'm_get_regions';
export const M_REGIONS_UPDATED = 'm_regions_updated';
export const M_GET_ROLES = 'm_get_roles';
export const M_ROLES_UPDATED = 'm_roles_updated';
export const M_GET_COURSE_PORTFOLIOS = 'm_get_course_portfolios';
export const M_COURSE_PORTFOLIOS_UPDATED = 'm_course_portfolios_updated';
export const M_GET_CONSULTANTS = 'm_get_consultants';
export const M_CONSULTANTS_UPDATED = 'm_consultants_updated';
export const M_GET_COURSE_GROUP = 'm_get_course_group';
export const M_COURSE_GROUP_UPDATED = 'm_course_group_updated';
export const M_GET_SELECTABLES = 'm_get_selectables';
export const M_SELECTABLES_UPDATED = 'm_selectables_updated';
export const M_GET_COURSE_PROFILE = 'm_get_course_profile';
export const M_COURSE_PROFILE_UPDATED = 'm_course_profile_updated';

export const M_GET_PAYMENT_TERMS = 'm_get_payment_terms';
export const M_PAYMENT_TERMS_UPDATED = 'm_payment_terms_updated';
export const M_GET_INVOICE_TYPE = 'm_get_invoice_type';
export const M_INVOICE_TYPE_UPDATED = 'm_invoice_type_updated';
export const M_GET_BENEFICIARY_DATA = 'm_get_beneficiary_data';
export const M_BENEFICIARY_DATA_UPDATED = 'm_beneficiary_data_updated';
export const M_GET_LEAD_SOURCE = 'm_get_lead_source';
export const M_LEAD_SOURCE_UPDATED = 'm_lead_source_updated';
export const M_GET_ACCOUNTS_TIER_2 = 'm_get_accounts_tier_2';
export const M_ACCOUNTS_TIER_2_UPDATED = 'm_accounts_tier_2_updated';
export const M_GET_MAILING_LISTS = 'm_get_mailing_lists';
export const M_MAILING_LISTS_UPDATED = 'm_mailing_lists_updated';

/* global actions */
export const GLOBAL_MODAL_PROCESSING = 'global_modal_processing';
export const GLOBAL_MODAL_DELETING = 'global_modal_deleting';
export const GLOBAL_ERROR_TRIGGER = 'global_error_trigger';
export const GLOBAL_LOADER_START = 'global_loader_start';
export const GLOBAL_LOADER_END = 'global_loader_end';
export const GLOBAL_SNACKBAR_TRIGGER = 'global_snackbar_trigger';

/* MISC actions */
export const MISC_CHANGES_SAVED = 'misc_changes_saved';
export const MISC_CHANGES_RESET = 'misc_changes_reset';
export const MISC_CHANGES_NOT_SAVED = 'misc_changes_not_saved';

/* Testing actions */
export const GET_TEST_QUERIES = 'get_test_queries';
export const RESET_TEST_QUERIES = 'reset_test_queries';