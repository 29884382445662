/* eslint-disable */
import React from 'react';
// import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
// import Grid from '@material-ui/core/Grid';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';

import ModalView from '../ModalView';
import Table from '../Table';

import { isArrayExists } from '../../helpers/validation';
// import { cloneCollections } from '../../helpers/data';
import { formatMoney } from '../../helpers/number';

import { InfoButton, ButtonGroup } from '../../styles/button';

class ModalSelectPrice extends React.Component {

    state = {
    };

    handlePriceSelect = (pricing_cat) => {
        const { onPriceSelect } = this.props;
        var selectedPrice = ( pricing_cat && pricing_cat.price ? parseFloat( pricing_cat.price, 10 ) : 0 );
        // trigger price select
        if ( onPriceSelect )
            onPriceSelect(selectedPrice);
    }

    handleClose = () => {
        const { onClose } = this.props;
        if ( onClose )
            onClose();
    }

    getPricingCategory = () => {
        const { item } = this.props;
        var categories = [];

        if ( item && item.pricing_category && !_isEmpty( item.pricing_category ) ) {
            _forEach( item.pricing_category, price_cat => {
                categories.push( price_cat );
            });
        } // end - item

        return categories;
    }

    renderPricingCell = (pricing_cat) => {
        const { selectables, doc } = this.props;
        var currency_list = ( selectables && isArrayExists( selectables ) ? _find( selectables, { id: 'currency' } ) : false ),
            currency = ( currency_list && currency_list.options && isArrayExists( currency_list.options ) && doc && doc.currency && !_isEmpty( doc.currency ) ? _find( currency_list.options, { value: doc.currency } ) : false );
        return (
        <div>
            {( pricing_cat && pricing_cat.price && !_isEmpty( pricing_cat.price ) ? <strong style={{ fontWeight: '400' }}>{( currency && currency.value || '' )}{formatMoney( parseFloat( pricing_cat.price ), 2, 3 )}</strong> : '---' )}
        </div>
        )
    }

    renderModal = () => {
        return (
        <div>

            <Table 
                items={this.getPricingCategory()}
                showCheckbox={false}
                cells={[
                    { id: 'condition', label: 'Condition', render: (item) => ( item.condition || '' ) },
                    { id: 'price', label: 'Price', render: (item) => this.renderPricingCell(item) },
                ]}
                actionStyles={{ width: "20%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <InfoButton key="select" size="small" onClick={this.handlePriceSelect.bind(this,item)}><i className="fa fa-edit"></i>Select</InfoButton>
                    </ButtonGroup>
                )} />
            
        </div>   
        )
    }

    render() {
        const { open } = this.props;
        return <ModalView 
                open={open}
                title="Select Price"
                noAction={true}
                actionLabel="Select"
                maxWidth="md"
                onClose={this.handleClose}
                doAction={this.handlePriceSelect}
                contents={this.renderModal()} />
    }

}

export default compose(
    connect()
)(ModalSelectPrice);