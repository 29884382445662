/* eslint-disable */
import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';

import Table from '../Table';
import Pagination from '../Pagination';
import TableBar from '../TableBar';
// import AddNewQuote from '../AddNewQuote';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton } from '../../styles/button';
// import { SuccessTag, AmberTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { hasLimitedAccess } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
// import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from '../../helpers/data';
// import { getMomentTime, getMomentTimestamp } from '../../helpers/date';

import { appChangesReset } from '../../actions/misc';

class QuotesList extends React.Component {
    
    state = {
        checked: [],
        allCheck: false,
        searchterms: '',
        filterBy: 'all',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false
    }

    handlePageRefresh = (event) => {
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh('quotes');
    }

    handleAddNew = (event) => {
        const { history, changesMade, account, contact, potential } = this.props;
        event.preventDefault();
        if ( changesMade ) {
            alert("Please save changes first before proceed");
        } else {
            // don't need this anymore
            //this.setState({ openViewModal: true });
            var path = '/quotes/new?action=preload';

            if ( account && account.id && !_isEmpty( account.id ) ) {
                path += '&account='+account.id;
            }

            if ( contact && contact.id && !_isEmpty( contact.id ) ) {
                path += '&contact='+contact.id;
            }

            if ( potential && potential.id && !_isEmpty( potential.id ) ) {
                path += '&potential='+potential.id;
            }

            history.push(path);
        } // end - changesMade
    }

    handleView = (id,event) => {
        const { changesMade, history, dispatch } = this.props;
        event.preventDefault();
        let url = "/quotes/"+id;
        if ( changesMade ) {
            var answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
            // reset status if answer is yes
            if ( answer ) {
                dispatch(appChangesReset());
                history.push(url);
            }
        } else {
            history.push(url);
        } // end - changesMade
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { quotes, users } = this.props;
        let items = ( quotes ? cloneCollections( quotes ) : [] ),
            total = _size( items );

        // add in sales_person_label
        if ( quotes && isArrayExists( quotes ) ) {
            _forEach( quotes, (current,index) => {

                // set label as empty
                items[index].accounts_label = '';
                items[index].potentials_label = '';
                items[index].sales_person_label = '';

                // run through all the IDs to get labels
                if ( current && current.accounts_linked && isArrayExists( current.accounts_linked ) ) {
                    _forEach( current.accounts_linked, u => {
                        items[index].accounts_label += ( !_isEmpty( items[index].accounts_label ) ? ' ' : '' ) + ( u && u.name ? u.name : u.id );  
                    });
                } // end - current.accounts_linked

                // run through all the IDs to get labels
                if ( current && current.potentials_linked && isArrayExists( current.potentials_linked ) ) {
                    _forEach( current.potentials_linked, u => {
                        items[index].potentials_label += ( !_isEmpty( items[index].potentials_label ) ? ' ' : '' ) + ( u && u.name ? u.name : u.id );  
                    });
                } // end - current.potentials_linked

                // run through all the IDs to get labels
                if ( current && current.sales_person && !_isEmpty( current.sales_person ) ) {
                    const user = ( current.sales_person && !_isEmpty( current.sales_person ) ? _find( users, { email: current.sales_person } ) : false );
                    items[index].sales_person_label = ( user && user.name && !_isEmpty( user.name ) ? user.name : ( current.sales_person || '---' ) );
                } // end - current.sales_person
                
            });
        } // end - quotes

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','account_label','email','sales_person_label'] );
			total = _size( items );
        } // end - searchterms

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['modified_on'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['modified_on'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, [(i) => i.name.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, [(i) => i.name.toLowerCase()] );
                    break;
                case 'account_label-desc':
                    items = _sortBy( items, [(i) => i.accounts_label.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'account_label-asc':
                    items = _sortBy( items, [(i) => i.accounts_label.toLowerCase()] );
                    break;
                case 'potential_label-desc':
                    items = _sortBy( items, [(i) => i.potentials_label.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'potential_label-asc':
                    items = _sortBy( items, [(i) => i.potentials_label.toLowerCase()] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderCell = type => item => {
        const { users } = this.props;
        switch( type ) {
            case 'sales_person':
                const user = ( item[type] && !_isEmpty( item[type] ) ? _find( users, { email: item[type] } ) : false );
                return ( user && user.name && !_isEmpty( user.name ) ? user.name : ( item[type] || '---' ) );
            case 'accounts_linked':
            case 'potentials_linked':
            case 'assigned_to':
                return ( item[type] && isArrayExists( item[type] ) ? item[type].map((u) => (<div key={u.id}>{u.name}</div>) ) : '--' );
            default:
                return <WrapWord>{( item[type] || '' )}</WrapWord>
        }
    }

    renderRightActionButtons = () => {
        const { noAddNew } = this.props;
        var rightButtons = [];

        // add add new contact button
        if ( !noAddNew ) {
            rightButtons.push( <InfoButton minWidth="128px" key="addnew" style={{ marginRight: "5px" }} onClick={this.handleAddNew}><i className="fa fa-plus-circle"></i>Add New</InfoButton> );
        } // end - noAddNew

        rightButtons.push( <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton> );

        return rightButtons;
    }

    renderTableActions = () => {
        const { authData } = this.props;
        const { sortBy, perPage, searchterms } = this.state;
        return <TableBar
                limitedPerPage={( hasLimitedAccess(authData) ? true : false )}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Modified first' },
                    { value: 'date-asc', label: 'Oldest Modified first' },
                    { value: 'name-asc', label: 'Subject ( A - Z)' },
                    { value: 'name-desc', label: 'Subject ( Z - A )' },
                    { value: 'account_label-asc', label: 'Account Name ( A - Z)' },
                    { value: 'account_label-desc', label: 'Account Name ( Z - A )' },
                    { value: 'potential_label-asc', label: 'Potential Name ( A - Z)' },
                    { value: 'potential_label-desc', label: 'Potential Name ( Z - A )' }
                ]}
                rightButtons={this.renderRightActionButtons()}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    doneLoaded={true}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    render() {
        const { noAddNew, authData, users, account, contact, potential } = this.props;
        const { openViewModal } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            {this.renderTableActions()}
            <Table 
                noSelect={( hasLimitedAccess(authData) ? true : false )}
                items={items}
                showCheckbox={false}
                cells={[
                    { id: 'name', label: 'Name', render: (item) => ( item.name || '' ) },
                    { id: 'stage', label: 'Quote Stage', render: (item) => ( item.stage || '' ) },
                    { id: 'potential_label', label: 'Potential Name', render: (item) => ( item.potentials_label && !_isEmpty( item.potentials_label ) ? item.potentials_label : '--' ) },
                    { id: 'account_label', label: 'Account Name', render: (item) => ( item.accounts_label && !_isEmpty( item.accounts_label ) ? item.accounts_label : '--' ) },
                    { id: 'grand_total', label: 'Total', render: (item) => ( item.grand_total && !_isEmpty( item.grand_total ) ? ( item.currency && !_isEmpty( item.currency ) ? item.currency : '' )+item.grand_total : '---' ) },
                    { id: 'sales_person', label: 'Sales Person', render: this.renderCell('sales_person') },
                ]}
                actionStyles={{ width: "10%" }}
                actions={(item) => (
                    <ButtonGroup>
                        <AInfoLink key="view" href={"/quotes/"+item.id} size="small" onClick={this.handleView.bind(this,item.id)}><i className="fa fa-search"></i>View</AInfoLink>
                    </ButtonGroup>
                )} />
            {this.renderPagination(total)}
{/* 
            { noAddNew ? null : <AddNewQuote
                authData={authData}
                open={openViewModal}
                users={( users || [] )}
                predefined_account={( account || null )}
                predefined_contact={( contact || null )}
                predefined_potential={( potential || null )}
                accounts={( account ? [{ id: account.id, name: account.name }] : [] )}
                onClose={() => this.setState({ openViewModal: false })} /> } */}

        </div>
        )
    }

}

export default compose(
    connect(),
    withRouter
)(QuotesList);