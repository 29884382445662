import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import EmailTemplates from './templates';

import { getEmailTemplates } from '../../actions/emailTemplates';
import { resetRedux } from '../../actions/misc';

class EmailTemplatesPage extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {
        // get email templates
        this.props.dispatch(getEmailTemplates());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { emailTemplatesList, randNum } = this.props;

        // once all the data is loaded
        if ( emailTemplatesList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    componentWillUnmount() {
        // this.props.dispatch(resetRedux('email_templates'));
    }

    handleRefresh = () => {
        this.setState({ randNum: false });
        this.props.dispatch(resetRedux('email_templates'));
        this.props.dispatch(getEmailTemplates());
    }

    renderContents() {
        const { authData, emailTemplatesList } = this.props;
        return <EmailTemplates 
                authData={authData} 
                templates={emailTemplatesList}
                onRefresh={this.handleRefresh} />;
    }

    render() {
        const { randNum } = this.state;
        const { emailTemplatesList } = this.props;
        return <AppWrapper 
                title="Email Templates"
                onLoad={( !( emailTemplatesList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        emailTemplatesList: state.emailtemplates && state.emailtemplates.templates || null,
        randNum: state.emailtemplates && state.emailtemplates.rand || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(EmailTemplatesPage);