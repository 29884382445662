/* eslint-disable */
import * as firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

// import { callFunctionsAPI } from '../../helpers/action';
// import { isArrayExists } from '../../helpers/validation';
import { getSnapshotDataBySchema } from '../../helpers/schemas';
import { hasLimitedAccess } from '../../helpers/auth';

import { logSchema } from '../../schemas/log';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getLatestUpdates = (callback,limits,authData) => {
    return new Promise((resolve,reject) => {

        let total = ( limits ? limits : 50 ),
            ref = firebase.database().ref("logs").orderByChild('logged_on').limitToLast(total);

        if ( authData && hasLimitedAccess(authData) ) {
            ref = firebase.database().ref("logs").orderByChild('user').equalTo(authData.email).limitToLast(500);
        } // end - authData
        
        ref.on("value", snapshot => {

            var updates = [];

            if ( snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach( childSnapshot => {
                    let update = getSnapshotDataBySchema( logSchema , childSnapshot );
                    updates.push( update );
                });
            } // end - results[2]

            callback( updates );

        });

    })
}