import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _isEmpty from 'lodash/isEmpty';
import _merge from 'lodash/merge';
import _size from 'lodash/size';
import _isEqual from 'lodash/isEqual';

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import Potentials from './potentials';
import PotentialsFireStore from './potentials_fs';

import { hasLimitedAccess } from '../../helpers/auth';
import { cloneCollections } from '../../helpers/data';

import { getPotentials } from '../../actions/potentials';
import { getUsersOptions } from '../../actions/users';
import { resetRedux } from '../../actions/misc';

class PotentialsPage extends React.Component {

    state = {
        query: {
            searchterms: '',
            filterBy: '',
            sortBy: 'modified_on-desc',
            perPage: 20,
            page: 1
        },
        randNum: false
    };

    componentDidMount() {

        // get potentials list
        this.props.dispatch(getPotentials());

        // get all users
        if ( !this.props.usersOptions )
            this.props.dispatch(getUsersOptions());

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { potentialsList, usersOptions, randNum } = this.props;

        // once all the data is loaded
        if ( potentialsList && usersOptions && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('potentials'));
    }

    handleRefresh = () => {
        this.setState({ randNum: false });
        this.props.dispatch(resetRedux('potentials'));
        this.props.dispatch(getPotentials());
    }

    handleFSRefresh = () => {
        const { query } = this.state;
        this.setState({ randNum: false });
        this.props.dispatch(resetRedux('potentials'));
        this.props.dispatch(getPotentials(query));
    }

    handleFSQueryChange = (props) => {
        const { query } = this.state
        var newQuery = this.setNewFSQuery(props);
        if ( this.ifOnlySortChanged(newQuery) && newQuery.searchterms && !_isEmpty( newQuery.searchterms ) ) {
            // for search sort - no need to trigger reload
            this.setState({ query: newQuery });
        } else {
            this.setState({ query: newQuery, randNum: false });
            this.props.dispatch(resetRedux('potentials'));
            this.props.dispatch(getPotentials(newQuery));
        } // end - newQuery
    }

    ifOnlySortChanged = (newQuery) => {
        const { query } = this.state;
        var changed = false;
        if ( !_isEqual( query.sortBy, newQuery.sortBy ) ) {
            if ( !_isEqual( query.searchterms, newQuery.searchterms ) || 
                !_isEqual( query.filterBy, newQuery.filterBy ) || 
                !_isEqual( query.perPage, newQuery.perPage ) || 
                !_isEqual( query.page, newQuery.page ) )
                changed = true;
        } else {
            changed = true;
        }
        return ( changed ? false : true );
    }

    setNewFSQuery = (query) => {
        const { potentialsFirstDoc, potentialsLastDoc } = this.props;
        var currentQuery = this.getCurrentFSQuery(),
            newQuery = _merge( this.getCurrentFSQuery(), query);
        newQuery.endBefore = ( newQuery.page >= 2 && newQuery.page < currentQuery.page && potentialsFirstDoc ? potentialsFirstDoc : false );
        newQuery.startAfter = ( newQuery.page >= 2 && newQuery.page > currentQuery.page && potentialsLastDoc ? potentialsLastDoc : false );
        return newQuery;
    }

    getCurrentFSQuery = () => {
        const { query } = this.state;
        var currentQuery = {};
        if ( query && !_isEmpty( query ) ) {
            const { searchterms, sortBy, filterBy, perPage, page } = query;
            currentQuery = cloneCollections({ searchterms, sortBy, filterBy, perPage, page });
        }
        return currentQuery;
    }

    renderContents() {
        const { authData, potentialsList, usersOptions, potentialsTotal } = this.props;
        const { query } = this.state;
        return authData && hasLimitedAccess( authData ) ? ( 
            <Potentials 
                authData={authData} 
                potentials={potentialsList}
                users={usersOptions}
                onRefresh={this.handleRefresh} />
        ) : (
            <PotentialsFireStore 
                authData={authData} 
                potentials={potentialsList}
                users={usersOptions}
                total={potentialsTotal}
                currentTotal={( potentialsList ? _size( potentialsList ) : 0 )}
                currentQuery={this.getCurrentFSQuery()}
                searchterms={( query.searchterms || '' )}
                sortBy={( query.sortBy || 'modified_on-desc' )}
                filterBy={( query.filterBy || '' )}
                perPage={( query.perPage || 20 )}
                page={( query.page || 1 )}
                onRefresh={this.handleFSRefresh}
                onQueryChange={this.handleFSQueryChange} />
        );
    }

    render() {
        const { randNum } = this.state;
        const { potentialsList, authData } = this.props;
        return <AppWrapper 
                title="Potentials"
                onLoad={( !( potentialsList && randNum ) ? true : false )}
                contents={ authData ? this.renderContents() : null } />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        potentialsList: state.potentials && state.potentials.potentials || null,
        potentialsTotal: state.potentials && state.potentials.total || null,
        potentialsFirstDoc: state.potentials && state.potentials.firstDoc || null,
        potentialsLastDoc: state.potentials && state.potentials.lastDoc || null,
        randNum: state.potentials && state.potentials.rand || null,
        usersOptions: state.users && state.users.users_options || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(PotentialsPage);