/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";

import ModalView from "../../components/ModalView";

import { callFunctionsAPI } from "../../helpers/action";
import { triggerErrorAlert } from "../../helpers/alert";
import { isObjectExists, isArrayExists } from "../../helpers/validation";
import { compareStrings } from "../../helpers/data";

import { InfoButton, GreyButton, InverseButton } from "../../styles/button";
import { AmberTag, GreyTag, SuccessTag, IndigoTag } from "../../styles/tag";

const useStyles = (theme) => ({
    headcell: {
        fontSize: "16px",
        fontWeight: "700",
        color: theme.palette.background,
    },
    bodycell: {
        fontSize: "16px",
        verticalAlign: "middle",
    },
});

const syncDataType = [
    {
        id: "last_name",
        label: "Last Name",
        dcl_id: "name",
    },
    {
        id: "account_id",
        label: "Account ID",
        dcl_id: "org_acc_id",
    },
    {
        id: "account_label",
        label: "Account Name",
        dcl_id: "org",
    },
    {
        id: "office_phone",
        label: "Office Phone",
        dcl_id: "phone",
    },
    {
        id: "mobile_phone",
        label: "Mobile Phone",
        dcl_id: "phone_mobile",
    },
    {
        id: "age_group",
        label: "Age Group",
        dcl_id: "age_group",
    },
    {
        id: "area_of_interest_string",
        label: "Area of Interest",
        dcl_id: "area_of_interest_string",
    },
    {
        id: "billing_state",
        label: "Billing (state)",
        dcl_id: "state",
    },
    {
        id: "billing_country",
        label: "Billing (Country)",
        dcl_id: "country",
    },
];

const getDCLUserData = (contact) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({
            url: "sync",
            action: "dcl_sync_user",
            formData: {
                id: contact.id,
                auto_update: "no",
            },
        })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const checkIfDataSynced = (user, contact) => {
    let sync = true;

    syncDataType.forEach((item) => {
        if (user[item.dcl_id] && !_isEmpty(user[item.dcl_id]) && !(contact[item.id] && contact[item.id] === user[item.dcl_id])) {
            sync = false;
        }
    });

    return sync;
};

const DCLSyncModal = ({ contact = {}, classes = {}, accountsOptions = [], onUpdate = () => {} }) => {
    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState({});
    const [opened, setOpened] = useState(false);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        let mounted = true;
        if (contact && contact.id && !_isEmpty(contact.id)) {
            getDCLUserData(contact)
                .then((data) => {
                    if (mounted) {
                        setLoaded(true);
                        setUser(data);
                    }
                })
                .catch((error) => {
                    // do nothing for now
                });
        }
        return () => {
            mounted = false;
        };
    }, [contact]);

    // useEffect(() => {
    //     if ( opened ) {
    //         setSelected(syncDataType.map((item) => item.id));
    //     }
    // },[opened]);

    const dataSynced = useMemo(() => {
        return checkIfDataSynced(user, contact);
    }, [user, contact]);

    const handleReplace = () => {
        let newContact = { ...contact };
        selected.forEach((item_id) => {
            switch (item_id) {
                case "account_id":
                case "account_label":
                    if (user && user.crm_account && user.crm_account.id) {
                        newContact["account_id"] = user.crm_account.id;
                        newContact["account_label"] = user.crm_account.name || "";
                        newContact["assigned_to"] =
                            user.crm_account.assigned_to && isArrayExists(user.crm_account.assigned_to) ? [...user.crm_account.assigned_to] : [];
                        newContact["assigned_to_ids"] =
                            user.crm_account.assigned_to_ids && isArrayExists(user.crm_account.assigned_to_ids)
                                ? [...user.crm_account.assigned_to_ids]
                                : [];

                        // if company is not available, then use placeholder account. Then, put the company name into notes
                        if (user.crm_account.id === "ACC1" && user.org && !_isEmpty(user.org) && !compareStrings(user.org, user.crm_account.name)) {
                            newContact["notes"] =
                                contact.notes && !_isEmpty(contact.notes)
                                    ? `${contact.notes}\n\nCompany (edited by user):\n${user.org}`
                                    : `Company (edited by user):\n${user.org}`;
                        }
                    }
                    break;
                case "last_name":
                    const nameitem = _find(syncDataType, { id: item_id });
                    if (nameitem) {
                        newContact["first_name"] = "";
                        newContact[nameitem.id] = user[nameitem.dcl_id];
                    }
                    break;
                default:
                    const item = _find(syncDataType, { id: item_id });
                    if (item) {
                        newContact[item.id] = user[item.dcl_id];
                    }
                    break;
            }
        });
        // add dcl_sync data
        newContact.dcl_sync_id = user.dcl_sync_id || "";
        newContact.dcl_sync_uid = user.dcl_sync_uid || "";
        newContact.dcl_sync_date = moment().utcOffset(8).valueOf();

        onUpdate(newContact);
        setOpened(false);
    };

    const handleCheck = (event, item) => {
        if (event.target.checked) {
            if (!_find(selected, (selectedItem) => selectedItem === item.id)) {
                switch (item.id) {
                    case "account_id":
                        setSelected([...selected, item.id, "account_label"]);
                        break;
                    case "account_label":
                        setSelected([...selected, item.id, "account_id"]);
                        break;
                    default:
                        setSelected([...selected, item.id]);
                        break;
                }
            }
        } else {
            if (_find(selected, (selectedItem) => selectedItem === item.id)) {
                switch (item.id) {
                    case "account_id":
                        setSelected(selected.filter((selectedItem) => selectedItem !== item.id && selectedItem !== "account_label"));
                        break;
                    case "account_label":
                        setSelected(selected.filter((selectedItem) => selectedItem !== item.id && selectedItem !== "account_id"));
                        break;
                    default:
                        setSelected(selected.filter((selectedItem) => selectedItem !== item.id));
                        break;
                }
            }
        }
    };

    const getAccountByName = (name = "") => {
        return _find(accountsOptions, (a) => compareStrings(name, a.name));
    };

    const renderDCLValues = (item) => {
        switch (item.id) {
            case "account_label":
                const account_exists = user && user[item.dcl_id] && !_isEmpty(user[item.dcl_id]) ? getAccountByName(user[item.dcl_id]) : false;
                return (
                    <>
                        {(user && user[item.dcl_id]) || ""}
                        {!account_exists ? (
                            <div
                                style={{
                                    marginTop: "5px",
                                    padding: "10px",
                                    border: "2px solid #222",
                                    fontSize: "13px",
                                    background: "#f1f1f1",
                                    maxWidth: "400px",
                                }}
                            >
                                This account does not exist in CRM. The contact will be assigned to the placeholder account if you sync this data.
                            </div>
                        ) : null}
                    </>
                );
            default:
                return (user && user[item.dcl_id]) || "";
        }
    };

    return (
        <div
            style={{
                display: "inline-block",
                marginRight: "5px",
                textAlign: "left",
                padding: "10px 5px",
                verticalAlign: "middle",
                maxWidth: "350px",
            }}
        >
            {loaded ? (
                <>
                    {user && isObjectExists(user) && ((user.uid && !_isEmpty(user.uid)) || (user.dcl_sync_uid && !_isEmpty(user.dcl_sync_uid))) ? (
                        <>
                            {dataSynced ? (
                                <SuccessTag>This contact data is synced with DCL account</SuccessTag>
                            ) : (
                                <>
                                    <AmberTag>
                                        This contact data is not synced with DCL account
                                        <InverseButton
                                            style={{
                                                padding: "5px",
                                                marginTop: "5px",
                                                marginLeft: "5px",
                                            }}
                                            onClick={() => setOpened(true)}
                                        >
                                            Sync Now
                                        </InverseButton>
                                    </AmberTag>
                                </>
                            )}
                        </>
                    ) : (
                        <IndigoTag>This contact does not have a DCL account</IndigoTag>
                    )}
                </>
            ) : (
                <GreyTag style={{ verticalAlign: "middle" }}>
                    <CircularProgress size={20} />
                    <span style={{ paddingLeft: "10px", verticalAlign: "middle", paddingBottom: "5px" }}>Checking DCL Sync Status...</span>
                </GreyTag>
            )}

            <ModalView
                open={opened}
                title="Sync data from DCL account (user edited data)"
                maxWidth="400px"
                onClose={() => setOpened(false)}
                doAction={handleReplace}
                actionLabel="Replace"
                contents={
                    <div>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.headcell} align="left">
                                            Field
                                        </TableCell>
                                        <TableCell className={classes.headcell} align="left">
                                            DCL Data (user edited data)
                                        </TableCell>
                                        <TableCell className={classes.headcell} align="left">
                                            CRM Data
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {syncDataType.map((item) => (
                                        <TableRow key={item.id}>
                                            <TableCell className={classes.bodycell} align="left">
                                                {item.label}
                                            </TableCell>
                                            <TableCell className={classes.bodycell} align="left">
                                                <Checkbox
                                                    checked={
                                                        (selected &&
                                                            isArrayExists(selected) &&
                                                            _find(selected, (selectedItem) => selectedItem === item.id)) ||
                                                        false
                                                    }
                                                    onChange={(event) => {
                                                        handleCheck(event, item);
                                                    }}
                                                    color="primary"
                                                />
                                                <span style={{ marginLeft: "10px" }}>{renderDCLValues(item)}</span>
                                            </TableCell>
                                            <TableCell className={classes.bodycell} align="left">
                                                {(contact && contact[item.id]) || ""}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                }
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        authData: (state.auth && state.auth.user) || null,
        accountsOptions: (state.accounts && state.accounts.accounts_options) || null,
        changesMade: (state.misc && state.misc.changes_made) || null,
    };
};

export default compose(connect(mapStateToProps), withStyles(useStyles), withRouter)(DCLSyncModal);
