/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_POTENTIALS,
	GET_POTENTIALS_BY,
	GET_POTENTIALS_OPTIONS,
	GET_POTENTIAL,
	ADD_POTENTIAL,
	EDIT_POTENTIAL,
	DELETE_POTENTIAL,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { appChangesReset } from '../misc';

import { fb_getPotentials, fb_getPotentialsBy, fb_getPotentialsOptions, fb_getPotential, fb_addPotential, fb_updatePotential, fb_deletePotential } from './fb';


export const getPotentials = (props) => {
	return dispatch => {

		fb_getPotentials(props)  
		.then(({ list, total, firstDoc, lastDoc }) => {
			dispatch({
				type: GET_POTENTIALS,
				payload: { list, total, firstDoc, lastDoc }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_POTENTIALS,
				payload: { list: [] }
			});
		});

	}
}

export const getPotentialsBy = (type,id) => {
	return dispatch => {

		fb_getPotentialsBy(type,id)  
		.then(list => {
			dispatch({
				type: GET_POTENTIALS_BY,
				payload: { list }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_POTENTIALS_BY,
				payload: { list: {} }
			});
		});

	}
}

export const getPotentialsOptions = (authData) => {
	return dispatch => {

		fb_getPotentialsOptions(list => {
			dispatch({
				type: GET_POTENTIALS_OPTIONS,
				payload: { list }
			});	
		},authData);

	}
}

export const getPotential = (potential_id) => {
	return dispatch => {

		fb_getPotential(potential_id)  
		.then(potential => {
			dispatch({
				type: GET_POTENTIAL,
				payload: { potential }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_POTENTIAL,
				payload: { potential: {} }
			});
		});

	}
}

export const addNewPotential = (formData,history) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addPotential(formData)
		.then( results => {
			if ( results && results.new_potential_id && !_isEmpty( results.new_potential_id ) ) {

				fb_getPotential( results.new_potential_id )
				.then( potential => {

					// updated
					dispatch({ type: GET_POTENTIAL, payload: { potential } });
					dispatch(toggleModalProcessing(false));
					triggerSuccessAlert("Potential Added");

					// redirect to potential page
					history.push("/potentials/" + results.new_potential_id);

				});

			} else {
				dispatch(toggleModalProcessing(false,apiNum));
				triggerErrorAlert('Missing new potential ID');
			}
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new potential' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const editPotential = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updatePotential(formData)
		.then( results => {

			fb_getPotential(formData.id)
			.then(potential => {
				// push to list
				dispatch({ type: GET_POTENTIAL, payload: { potential } });

				// updated
				dispatch({ type: EDIT_POTENTIAL });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Potential Updated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update potential' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deletePotential = (id,props) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deletePotential(id)
		.then( results => {

			fb_getPotentials(props)  
			.then(({ list, total, firstDoc, lastDoc }) => {
				// send updates
				dispatch({
					type: GET_POTENTIALS,
					payload: { list, total, firstDoc, lastDoc }
				});

				// trigger updated
				dispatch({ type: DELETE_POTENTIAL });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("Potential Deleted");
			});

		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete list' ) );
			triggerErrorAlert(errMsg);
		});

	}
}