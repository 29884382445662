import _random from 'lodash/random';

import { 
    GET_QUOTES,
    GET_QUOTES_BY,
	GET_QUOTE,
	ADD_QUOTE,
	EDIT_QUOTE,
    DELETE_QUOTE,
    RESET_QUOTE,
    RESET_QUOTES,
    RESET_GET_QUOTES_BY
} from '../actions/types';

const initialState = {
    quotes: null,
    total: null,
    firstDoc: null,
    lastDoc: null,
    quotes_by: null,
    quote: null,
    new_quote_id: null,
    updated: null,
    by_rand: false,
    rand: false
};

export const quotes = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_QUOTES:
            return {
                ...state,
                quotes: ( payload.list || [] ),
                total: ( payload.total || null ),
                firstDoc: ( payload.firstDoc || null ),
                lastDoc: ( payload.lastDoc || null ),
                rand: _random(1,9999)
            };
        case GET_QUOTES_BY:
            return {
                ...state,
                quotes_by: ( payload.list || [] ),
                by_rand: _random(1,9999)
            };
        case GET_QUOTE:
            return {
                ...state,
                quote: ( payload.quote || {} ),
                rand: _random(1,9999)
            };
        case ADD_QUOTE:
            return {
                ...state,
                new_quote_id: ( payload.id || false ),
                rand: _random(1,9999)
            };
        case EDIT_QUOTE:
        case DELETE_QUOTE:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_QUOTE:
            return {
                ...state,
                quote: null,
                updated: null,
                rand: false
            };
        case RESET_QUOTES:
            return {
                ...state,
                quotes: null,
                total: null,
                firstDoc: null,
                lastDoc: null,
                rand: false
            };
        case RESET_GET_QUOTES_BY:
            return {
                ...state,
                quotes_by: null,
                by_rand: false
            };    
        default:
            return state;
    }
}