import _random from 'lodash/random';

import { 
    GET_POTENTIALS,
    GET_POTENTIALS_BY,
    GET_POTENTIALS_OPTIONS,
	GET_POTENTIAL,
	ADD_POTENTIAL,
	EDIT_POTENTIAL,
    DELETE_POTENTIAL,
    RESET_POTENTIAL,
    RESET_POTENTIALS,
    RESET_GET_POTENTIALS_BY
} from '../actions/types';

const initialState = {
    potentials: null,
    total: null,
    firstDoc: null,
    lastDoc: null,
    potentials_by: null,
    potential: null,
    new_potential_id: null,
    updated: null,
    by_rand: false,
    rand: false
};

export const potentials = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_POTENTIALS:
            return {
                ...state,
                potentials: ( payload.list || [] ),
                total: ( payload.total || null ),
                firstDoc: ( payload.firstDoc || null ),
                lastDoc: ( payload.lastDoc || null ),
                rand: _random(1,9999)
            };
        case GET_POTENTIALS_BY:
            return {
                ...state,
                potentials_by: ( payload.list || [] ),
                by_rand: _random(1,9999)
            };
        case GET_POTENTIALS_OPTIONS:
                return {
                    ...state,
                    potentials_options: ( payload.list || [] ),
                    options_rand: _random(1,9999)
                };
        case GET_POTENTIAL:
            return {
                ...state,
                potential: ( payload.potential || {} ),
                rand: _random(1,9999)
            };
        case ADD_POTENTIAL:
            return {
                ...state,
                new_potential_id: ( payload.id || false ),
                rand: _random(1,9999)
            };
        case EDIT_POTENTIAL:
        case DELETE_POTENTIAL:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case RESET_POTENTIAL:
            return {
                ...state,
                potential: null,
                updated: null,
                rand: false
            };
        case RESET_POTENTIALS:
            return {
                ...state,
                potentials: null,
                total: null,
                firstDoc: null,
                lastDoc: null,
                rand: false
            };
        case RESET_GET_POTENTIALS_BY:
            return {
                ...state,
                potentials_by: null,
                by_rand: false
            };    
        default:
            return state;
    }
}