/* eslint-disable */
import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from "@material-ui/core/styles";
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _random from 'lodash/random';
import _isEqual from 'lodash/isEqual';

import EditForm from '../../pages/ContactPage/form';

import ModalView from '../ModalView';
import DotsLoader from '../DotsLoader';
import Table from '../Table';

import { hasAccessRights } from '../../helpers/auth';
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, combineStrings } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { formatMoney } from '../../helpers/number';

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton } from '../../styles/button';
import { FormBox } from '../../styles/form';

import { contactSchema } from '../../schemas/contact';

import { getContact, changeContactAccountViaPromise } from '../../actions/contacts';
import { getPotentialsBy } from '../../actions/potentials';
import { getQuotesBy } from '../../actions/quotes';
import { getInvoicesBy } from '../../actions/invoices';
import { getEmailsBy } from '../../actions/emails';
import { resetRedux } from '../../actions/misc';

const MessageWrapper = styled.div`
    margin: 10px 0 0 0;
    padding: 10px 25px;
    border: 2px solid #f44336;
    font-weight: 700;
    background: #ffebee;

    h4 { color: #f44336; font-weight: 700; font-size: 16px; text-transform: uppercase; }
    h5, span { font-weight: 700; font-size: 14px; }

    a { text-decoration: underline; }
`;

const BlockTag = styled.span`
    display: inline-block;
    padding: 4px 8px;
    background: #eee;
`;

const useStyles = theme => ({
    modulewrapper: {
        padding: '10px', 
        border: "1px solid #212121"
    },
    moduleheading: {
        fontWeight: '700'
    },
    modulelist: {
        fontSize: '12px'
    },
    closeBtn: {
        position: 'absolute',
        top: '0',
        right: '0',
        padding: '5px 8px',
        borderRadius: '0px'
    }
});

class ModalChangeContactAccount extends React.Component {

    state = {
        open: false,
        contact: false,
        potentials: false,
        potentialsRand: false,
        quotes: false,
        quotesRand: false,
        invoices: false,
        invoicesRand: false,
        emails: false,
        emailsRand: false,
        randNum: false
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentContact, randNum, 
            potentialsByList, potentialsByListRand, 
            quotesByList, quotesByListRand, 
            invoicesByList, invoicesByListRand, 
            emailsByList, emailsByListRand } = this.props;

        // for contact
        if ( currentContact && randNum && randNum !== this.state.randNum ) {
            this.setState({ contact: cloneCollections( currentContact ), randNum });
        } // end - currentContact

        // for potentials
        if ( potentialsByList && potentialsByListRand && potentialsByListRand !== this.state.potentialsRand ) {
            this.setState({ potentials: cloneCollections( potentialsByList ), potentialsRand: potentialsByListRand });
        }

        // for quotes
        if ( quotesByList && quotesByListRand && quotesByListRand !== this.state.quotesRand ) {
            this.setState({ quotes: cloneCollections( quotesByList ), quotesRand: quotesByListRand });
        }

        // for invoices
        if ( invoicesByList && invoicesByListRand && invoicesByListRand !== this.state.invoicesRand ) {
            this.setState({ invoices: cloneCollections( invoicesByList ), invoicesRand: invoicesByListRand });
        }

        // for emails
        if ( emailsByList && emailsByListRand && emailsByListRand !== this.state.emailsRand ) {
            this.setState({ emails: cloneCollections( emailsByList ), emailsRand: emailsByListRand });
        }
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('contact'));
    }

    handleAccountUpdate = () => {
        const { account, potential, onContactUpdate } = this.props;
        const { contact } = this.state;
        let error = false,
            formData = {
                account_id: ( account.id || '' ),
                account_label: ( account.name || '' ),
                assigned_to: ( potential && potential.assigned_to && isArrayExists( potential.assigned_to ) ? potential.assigned_to : [] )
            };

        if ( !( contact && contact.id && !_isEmpty( contact.id ) ) || !( formData && formData.account_id && !_isEmpty( formData.account_id ) ) ) {
            error = 'Unknown Error - please try again'
        }

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            changeContactAccountViaPromise(contact.id,formData,this.props.dispatch)
            .then(status => {
                if ( status && status === 'done' ) {
                    if ( onContactUpdate )
                        onContactUpdate(contact.id,account.id);
                } // end - status
            });
        } // end - error
    }

    handleModalOpen = (event) => {
        const { contact } = this.props
        event.preventDefault();

        this.setState({ open: true, rand: false });

        // retrieve contact details
        this.props.dispatch(getContact(contact.id));

        // get potentials by
        this.props.dispatch(getPotentialsBy('contacts',contact.id));

        // get quotes by
        this.props.dispatch(getQuotesBy('contacts',contact.id));

        // get invoices by
        this.props.dispatch(getInvoicesBy('contacts',contact.id));

        // get emails by
        this.props.dispatch(getEmailsBy('contacts',contact.id));
    }

    handleClose = () => {
        this.setState({ open: false, randNum: false, contact: false });
    }

    renderContactUsages = () => {
        const { classes, potential } = this.props;
        const { contact, potentials, quotes, invoices } = this.state;
        return (
        <div className={classes.modulewrapper}>
            <h4 className={classes.moduleheading}>Modules assigned to this contact:</h4>
            <Grid container spacing={1} style={{ margin: '5px 0 10px 0' }}>
                <Grid item xs={4}>
                    <h6 className={classes.moduleheading}>Potential(s):</h6>
                    { potentials && isArrayExists( potentials ) ? <ul className={classes.modulelist}>{_map( potentials, item => {
                        return <li key={item.id}>{"["+item.id+"] " + ( item.name || '' ) }{( potential && potential.id && potential.id === item.id ? <span style={{ marginLeft: '5px', color: "#f44336", fontWeight: '700', fontStyle: 'italic', fontSize: '12px' }}>(current potential)</span> : '' )}</li>;
                    })}</ul> : 'None' }
                </Grid>
                <Grid item xs={4}>
                    <h6 className={classes.moduleheading}>Quote(s):</h6>
                    { quotes && isArrayExists( quotes ) ? <ul className={classes.modulelist}>{_map( quotes, item => {
                        return <li key={item.id}>{"["+item.id+"] " + ( item.name || '' )}</li>;
                    })}</ul> : 'None' }
                </Grid>
                <Grid item xs={4}>
                    <h6 className={classes.moduleheading}>Invoice(s):</h6>
                    { invoices && isArrayExists( invoices ) ? <ul className={classes.modulelist}>{_map( invoices, item => {
                        return <li key={item.id}>{"["+item.id+"] " + ( item.name || '' )}</li>;
                    })}</ul> : 'None' }
                </Grid>
            </Grid>
            <AInfoLink href={"/contacts/"+contact.id} target="_blank" size="small">View Contact</AInfoLink> 
        </div>
        )
    }

    renderContactInfo = () => {
        const { account, classes } = this.props;
        const { contact } = this.state;
        return (
        <div>
            <div style={{ marginBottom: "10px" }}>
                To move the contact <BlockTag>{( contact.last_name && !_isEmpty( contact.last_name ) ? contact.last_name : '' )}</BlockTag> from <BlockTag>{( contact.account_label && !_isEmpty( contact.account_label ) ? contact.account_label : '' )}</BlockTag> to <BlockTag>{( account && account.name && !_isEmpty( account.name ) ? account.name : '' )}</BlockTag>, press the <strong style={{ fontWeight: "700" }}>Move</strong> button.
            </div>
            {this.renderContactUsages()}
        </div>
        )
    }

    renderModalContent = () => {
        const { classes } = this.props;
        const { contact, randNum } = this.state;
        return (
        <div>
            { contact && contact.id && !_isEmpty( contact.id ) ? this.renderContactInfo() : ( randNum ? <h4>Contact not found / You don't have access to this contact</h4> : null ) }
            {this.renderCloseButton()}
        </div>
        )
    }

    renderCloseButton = () => {
        const { classes } = this.props;
        return <InverseButton className={classes.closeBtn} noIconMargin="yes" size="small" minWidth="0px" onClick={this.handleClose}><i className="fa fa-times"></i></InverseButton> 
    }

    renderModal = () => {
        const { open, randNum } = this.state;
        return  <ModalView 
                open={open}
                title="Move Contact's Account"
                actionLabel="Move"
                maxWidth="md"
                cancelLabel="Close"
                disableBackdrop={true}
                onClose={this.handleClose}
                noAction={( randNum ? false : true )}
                doAction={this.handleAccountUpdate}
                contents={ open ? ( randNum ? this.renderModalContent() : <DotsLoader /> ) : null } />
    }

    renderMessage = () => {
        const { authData, account, contact } = this.props
        return (
        <MessageWrapper>
            <h4>Warning!</h4>
            <h5>The account assigned to this potential is different from the contact's account.</h5>
            { authData && hasAccessRights( authData, ['cte'] ) ? <span><a href="#" onClick={this.handleModalOpen}>Click here</a> to review and move the contact's account.</span> : null }
        </MessageWrapper>
        )
    }

    render() {
        const { authData, account, contact } = this.props
        const { open } = this.state;
        // just to make sure account & contact is passing through
        return ( account && !_isEmpty(account) && contact && !_isEmpty(contact) ? (
        <div>
            {this.renderMessage()}
            { authData && hasAccessRights( authData, ['cte'] ) ? this.renderModal() : null }
        </div>
        ) : null )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        currentContact: state.contacts && state.contacts.contact || null,
        randNum: state.contacts && state.contacts.rand || null,
        potentialsByList: state.potentials && state.potentials.potentials_by || null,
        potentialsByListRand: state.potentials && state.potentials.by_rand || null,
        quotesByList: state.quotes && state.quotes.quotes_by || null,
        quotesByListRand: state.quotes && state.quotes.by_rand || null,
        invoicesByList: state.invoices && state.invoices.invoices_by || null,
        invoicesByListRand: state.invoices && state.invoices.by_rand || null,
        emailsByList: state.emails && state.emails.emails_by || null,
        emailsByListRand: state.emails && state.emails.by_rand || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles)
)(ModalChangeContactAccount);