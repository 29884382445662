import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { ThemeProvider } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

/* eslint-disable */

// import styles 
import theme from './theme';
import { OpacityComponent } from './styles/division';

// import components
import ScreenLoader from './components/ScreenLoader';
import OpacityLoader from './components/OpacityLoader';
import RouterModifier from './components/RouterModifier';

// import pages
import Dashboard from './pages/Dashboard';
import AccountsPage from './pages/AccountsPage';
import AccountPage from './pages/AccountPage';
import ContactsImportLMSPage from './pages/ContactsImportLMSPage';
import ContactsImportCSVPage from './pages/ContactsImportCSVPage';
import ContactsPage from './pages/ContactsPage';
import ContactPage from './pages/ContactPage';
import PotentialsPage from './pages/PotentialsPage';
import PotentialPage from './pages/PotentialPage';
import QuotesPage from './pages/QuotesPage';
import QuotePage from './pages/QuotePage';
import QuoteNewPage from './pages/QuoteNewPage';
import InvoicesPage from './pages/InvoicesPage';
import InvoicePage from './pages/InvoicePage';
import InvoiceNewPage from './pages/InvoiceNewPage';
import CreditNotesPage from './pages/CreditNotesPage';
import CreditNotePage from './pages/CreditNotePage';
import CreditNoteNewPage from './pages/CreditNoteNewPage';
import ProductsPage from './pages/ProductsPage';
import ProductPage from './pages/ProductPage';
import ReportPage from './pages/ReportPage';
import ReportsPage from './pages/ReportsPage';

import FindDuplicateAccounts from './pages/FindDuplicateAccounts';

import UsersPage from './pages/UsersPage';
import LoginPage from './pages/Login';
import AuthProfilePage from './pages/AuthProfilePage';

import EmailTemplatePage from './pages/EmailTemplatePage';
import EmailTemplatesPage from './pages/EmailTemplatesPage';

import MaintenanceRegions from './pages/MaintenanceRegions';
import MaintenanceRoles from './pages/MaintenanceRoles';
import MaintenanceCoursePortfolio from './pages/MaintenanceCoursePortfolio';
import MaintenanceConsultants from './pages/MaintenanceConsultants';
import MaintenanceCourseGroup from './pages/MaintenanceCourseGroup';
import MaintenanceCourseProfile from './pages/MaintenanceCourseProfile';
import MaintenanceSelectables from './pages/MaintenanceSelectables';
import MaintenancePaymentTerms from './pages/MaintenancePaymentTerms';
import MaintenanceInvoiceType from './pages/MaintenanceInvoiceType';
import MaintenanceBeneficiaryData from './pages/MaintenanceBeneficiaryData';
import MaintenanceLeadSource from './pages/MaintenanceLeadSource';
import MaintenanceAccountsTier2 from './pages/MaintenanceAccountsTier2';
import MaintenanceMailingLists from './pages/MaintenanceMailingLists';

import TestQueriesPage from './pages/TestQueriesPage';

// helpers
import { hasAccessRights } from './helpers/auth';

// import actions
import { isUserLoggedIn } from './actions/auth';

/* User based routes */
const PublicRoute = ({ component, authData, ...rest }) => {
    return (
        <Route {...rest} render={ props => 
            authData ? (
                <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
            ) : (
                React.createElement(component,props)
            )
        } />
    )
}

const UserRoute = ({ component, authData, accessRights, ...rest }) => {
    return (
        <Route {...rest} render={ props =>
            authData ? ( authData.type && ( authData.type === 'user' || authData.type === 'admin' ) ? (
                ( 
                    hasAccessRights(authData,accessRights) ? React.createElement(component,props) : <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
                )
            ) : (
                <Redirect to={{ pathname: '/noaccess', state: { from: props.location } }} />
            ) ) : (
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
        } />
    )
}

const AdminRoute = ({ component, authData, ...rest }) => {
    return (
        <Route {...rest} render={ props =>  
            authData && authData.type && authData.type == 'admin' ? (
                React.createElement(component,props)
            ) : (
                <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
            )
        } />
    )
}

const TestRoute = ({ component, authData, ...rest }) => {
    return (
        <Route {...rest} render={ props => 
            authData && authData.email && authData.email == 'webadmin@dreamcatcher.asia' ? (
                React.createElement(component,props)
            ) : (
                <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
            )
        } />
    )
}

/* App Structure */
class Structure extends React.Component {
    state = {
        authRand: false,
        dataLoaded: false
    }

    componentDidMount() {
        // call for auth listener
        this.props.dispatch(isUserLoggedIn());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { authRand } = this.props;
        if ( authRand && !this.state.authRand ) {
            this.setState({ dataLoaded: true, authRand });
        }
    }
    
    renderStructure() {
        const { loader, authData, loader_label } = this.props;
        return (
        <React.Fragment>
            { loader ? <OpacityLoader open={loader} message={( loader_label || '' )} /> : null }
            <BrowserRouter>
                <RouterModifier>
                <Switch>
                    <UserRoute path="/dashboard" component={Dashboard} authData={authData} />

                    <AdminRoute path="/accounts/find-duplicates" component={FindDuplicateAccounts} authData={authData} />
                    <UserRoute path="/accounts/:account_id" component={AccountPage} authData={authData} accessRights={['acr','acw']} />
                    <UserRoute path="/accounts" component={AccountsPage} authData={authData} accessRights={['acr','acw']} />

                    <UserRoute path="/contacts/import_csv" component={ContactsImportCSVPage} authData={authData} accessRights={['cti']} />
                    <UserRoute path="/contacts/import_lms" component={ContactsImportLMSPage} authData={authData} accessRights={['cti']} />
                    <UserRoute path="/contacts/import" component={ContactsImportLMSPage} authData={authData} accessRights={['cti']} />
                    <UserRoute path="/contacts/:contact_id" component={ContactPage} authData={authData} accessRights={['ctr','ctw']} />
                    <UserRoute path="/contacts" component={ContactsPage} authData={authData} accessRights={['ctr','ctw']} />

                    <UserRoute path="/potentials/:potential_id" component={PotentialPage} authData={authData} accessRights={['ptr','ptw']} />
                    <UserRoute path="/potentials" component={PotentialsPage} authData={authData} accessRights={['ptr','ptw']} />

                    <UserRoute path="/quotes/new" component={QuoteNewPage} authData={authData} accessRights={['qtw']} />
                    <UserRoute path="/quotes/:quote_id" component={QuotePage} authData={authData} accessRights={['qtr','qtw']} />
                    <UserRoute path="/quotes" component={QuotesPage} authData={authData} accessRights={['qtr','qtw']} />

                    <UserRoute path="/invoices/new" component={InvoiceNewPage} authData={authData} accessRights={['ivw']} />
                    <UserRoute path="/invoices/:invoice_id" component={InvoicePage} authData={authData} accessRights={['ivr','ivw']} />
                    <UserRoute path="/invoices" component={InvoicesPage} authData={authData} accessRights={['ivr','ivw']} />

                    <UserRoute path="/credit_notes/new" component={CreditNoteNewPage} authData={authData} accessRights={['cnw']} />
                    <UserRoute path="/credit_notes/:credit_note_id" component={CreditNotePage} authData={authData} accessRights={['cnr','cnw']} />
                    <UserRoute path="/credit_notes" component={CreditNotesPage} authData={authData} accessRights={['cnr','cnw']} />

                    <UserRoute path="/products/:product_id" component={ProductPage} authData={authData} accessRights={['pdr','pdw']} />
                    <UserRoute path="/products" component={ProductsPage} authData={authData} accessRights={['pdr','pdw']} />

                    <UserRoute path="/reports/:report_id" component={ReportPage} authData={authData} accessRights={['rpr','rpw']} />
                    <UserRoute path="/reports" component={ReportsPage} authData={authData} accessRights={['rpr','rpw']} />

                    <UserRoute path="/maintenance/regions" component={MaintenanceRegions} authData={authData} accessRights={['mtr','mtw']} />
                    <UserRoute path="/maintenance/companies" component={MaintenanceRegions} authData={authData} accessRights={['mtr','mtw']} />
                    <UserRoute path="/maintenance/business" component={MaintenanceCoursePortfolio} authData={authData} accessRights={['mtr','mtw']} />
                    <UserRoute path="/maintenance/course_portfolio" component={MaintenanceCoursePortfolio} authData={authData} accessRights={['mtr','mtw']} />
                    <UserRoute path="/maintenance/consultants" component={MaintenanceConsultants} authData={authData} accessRights={['mtr','mtw']} />
                    <UserRoute path="/maintenance/course_group" component={MaintenanceCourseGroup} authData={authData} accessRights={['mtr','mtw']} />
                    <UserRoute path="/maintenance/course_profile" component={MaintenanceCourseProfile} authData={authData} accessRights={['mtr','mtw']} />
                    <UserRoute path="/maintenance/selectables" component={MaintenanceSelectables} authData={authData} accessRights={['mtr','mtw']} />
                    <UserRoute path="/maintenance/payment_terms" component={MaintenancePaymentTerms} authData={authData} accessRights={['mtr','mtw']} />
                    <UserRoute path="/maintenance/invoice_type" component={MaintenanceInvoiceType} authData={authData} accessRights={['mtr','mtw']} />
                    <UserRoute path="/maintenance/product_line" component={MaintenanceInvoiceType} authData={authData} accessRights={['mtr','mtw']} />
                    <UserRoute path="/maintenance/invoice_data" component={MaintenanceBeneficiaryData} authData={authData} accessRights={['mtr','mtw']} />
                    <UserRoute path="/maintenance/lead_source" component={MaintenanceLeadSource} authData={authData} accessRights={['mtr','mtw']} />
                    <UserRoute path="/maintenance/accounts_tier_2" component={MaintenanceAccountsTier2} authData={authData} accessRights={['mtr','mtw']} />
                    <UserRoute path="/maintenance/mailing_lists" component={MaintenanceMailingLists} authData={authData} accessRights={['mtr','mtw']} />

                    <UserRoute path="/email_templates/:template_id" component={EmailTemplatePage} authData={authData} accessRights={['etr','etw']} />
                    <UserRoute path="/email_templates" component={EmailTemplatesPage} authData={authData} accessRights={['etr','etw']} />

                    <UserRoute path="/auth/profile" component={AuthProfilePage} authData={authData} />
                    <UserRoute path="/auth" component={AuthProfilePage} authData={authData} />

                    <AdminRoute path="/users" component={UsersPage} authData={authData} />
                    <AdminRoute path="/roles" component={MaintenanceRoles} authData={authData} />

                    <TestRoute path="/test" component={TestQueriesPage} authData={authData} />

                    <PublicRoute path="/login" component={LoginPage} authData={authData} />
                    <PublicRoute path="/noaccess" component={LoginPage} authData={authData} />
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />

                </Switch>
                </RouterModifier>
            </BrowserRouter>
        </React.Fragment>
        );
    }

    render() {
        const { dataLoaded } = this.state;
        return (
        <ThemeProvider theme={theme}>
            {(dataLoaded ? this.renderStructure() : <ScreenLoader /> )}
        </ThemeProvider> 
        );
    }
}

const mapStateToProps = state => {
    return {
        authData: state.auth.user,
        authRand: state.auth.rand,
        loader: state.global.loader,
        loader_label: state.global.loader_label
    };
};

export default connect(mapStateToProps)(Structure);
