import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/* eslint-disable */

import AppWrapper from '../../components/AppWrapper';
import Users from './users';

import { cloneCollections } from '../../helpers/data';

import { getUsers } from '../../actions/users';
import { getRegions } from '../../actions/m_regions';
import { getRoles } from '../../actions/m_roles';

class UsersPage extends React.Component {

    state = {
        rand: false
    };

    componentDidMount() {
        const { regionsList, rolesList } = this.props;

        // get lists
        this.props.dispatch(getUsers());

        // get regions
        if ( !regionsList ) {
            this.props.dispatch(getRegions());
        }

        // get roles
        if ( !rolesList ) {
            this.props.dispatch(getRoles());
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { usersList, randNum } = this.props;

        // once all the data is loaded
        if ( usersList && randNum && randNum != this.state.rand )
            this.setState({ rand: randNum });
    }

    renderContents() {
        const { usersList, regionsList, rolesList, authData } = this.props;
        return (
        <Users users={usersList} regions={regionsList} roles={rolesList} authData={authData} />
        );
    }

    render() {
        const { usersList , regionsList, rolesList, randNum } = this.props;
        return <AppWrapper 
                title="Users Management"
                onLoad={( !( usersList && regionsList && rolesList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        usersList: state.users && state.users.users || null,
        randNum: state.users && state.users.rand || null,
        regionsList: state.maintenance && state.maintenance.regions || false,
        rolesList: state.maintenance && state.maintenance.roles || false,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(UsersPage);