import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from "@material-ui/core/styles";
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _endsWith from 'lodash/endsWith';
import _replace from 'lodash/replace';
import _remove from 'lodash/remove';

/* eslint-disable */

import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';

import { InfoButton, ErrorButton, ButtonGroup, AInfoLink, SuccessButton } from '../../styles/button';
import { WrapWord } from '../../styles/misc';

import { isArrayExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from '../../helpers/data';

import { addRole, editRole, deleteRole, syncDataWithLMS } from '../../actions/m_roles';

import { ACCESS_RIGHTS } from '../../constants';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});

const AccessTag = styled.div`
    display: inline-block;
    padding: 5px 8px;
    margin: 0px 5px 5px 0px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 6px;
    color: #fff;
    background: ${props => (props.access && props.access == 'yes' ? green['500'] : grey['500'])};
`;

class Roles extends React.Component {
    
    state = {
        sortBy: 'name-asc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalType: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false
    }

    handleDataSync = (event) => {
        event.preventDefault();
        this.props.dispatch(syncDataWithLMS())
    }

    handleFormUpdate = (newValue,key) => {
        const { modalData } = this.state;
        var newData = ( modalData ? cloneCollections( modalData ) : {} );
        newData[key] = newValue;
        this.setState({ modalData: newData });
    }

    handleAccessUpdate = key => event => {
        const { modalData } = this.state;
        var newData = ( modalData ? cloneCollections( modalData ) : {} ),
            newAccesses = ( modalData && modalData.accesses && isArrayExists( modalData.accesses ) ? cloneCollections( modalData.accesses ) : [] );

        if ( event.target.checked ) {
            // if aren't already in the state
            if ( !_find( newAccesses, (o) => o == key ) ) {
                // push to state
                newAccesses.push(key);

                // if is write access
                if ( _endsWith( key, 'w' ) ) {
                    var read = key[0]+key[1]+'r';
                    // make sure the read access is a valid access
                    if ( _find( ACCESS_RIGHTS, { id: read } ) ) {
                        // check if read access already in the state - if not push to state
                        if ( !_find( newAccesses, (o) => o == read ) )
                            newAccesses.push(read);
                    } // end - ACCESS_RIGHTS
                } else if ( _endsWith( key, 'm' ) || _endsWith( key, 'e' ) || _endsWith( key, 't' ) || _endsWith( key, 'g' ) || _endsWith( key, 's' ) ) {
                    // if is modify access

                    var read = key[0]+key[1]+'r';
                    // make sure the read access is a valid access
                    if ( _find( ACCESS_RIGHTS, { id: read } ) ) {
                        // check if read access already in the state - if not push to state
                        if ( !_find( newAccesses, (o) => o == read ) )
                            newAccesses.push(read);
                    } // end - ACCESS_RIGHTS

                    var write = key[0]+key[1]+'w';
                    // make sure the write access is a valid access
                    if ( _find( ACCESS_RIGHTS, { id: write } ) ) {
                        // check if write access already in the state - if not push to state
                        if ( !_find( newAccesses, (o) => o == write ) )
                            newAccesses.push(write);
                    } // end - ACCESS_RIGHTS
                } else if ( _endsWith( key, 'd' ) ) {
                    // if is delete access
                    var read = key[0]+key[1]+'r';
                    // make sure the read access is a valid access
                    if ( _find( ACCESS_RIGHTS, { id: read } ) ) {
                        // check if read access already in the state - if not push to state
                        if ( !_find( newAccesses, (o) => o == read ) )
                            newAccesses.push(read);
                    } // end - ACCESS_RIGHTS

                    var write = key[0]+key[1]+'w';
                    // make sure the write access is a valid access
                    if ( _find( ACCESS_RIGHTS, { id: write } ) ) {
                        // check if write access already in the state - if not push to state
                        if ( !_find( newAccesses, (o) => o == write ) )
                            newAccesses.push(write);
                    } // end - ACCESS_RIGHTS

                    var modify = key[0]+key[1]+'m';
                    // make sure the modify access is a valid access
                    if ( _find( ACCESS_RIGHTS, { id: modify } ) ) {
                        // check if modify access already in the state - if not push to state
                        if ( !_find( newAccesses, (o) => o == modify ) )
                            newAccesses.push(modify);
                    } // end - ACCESS_RIGHTS
                } // end - key

            } // end - newAccesses
        } else {
            // remove the selected item
            var pulled = _remove( newAccesses, (o) => o == key );

            // if is read access
            if ( _endsWith( key, 'r' ) ) {

                var write = key[0]+key[1]+'w';;
                // make sure the write access is a valid access
                if ( _find( ACCESS_RIGHTS, { id: write } ) ) {
                    // check if write access already in the state - if yes remove it
                    if ( _find( newAccesses, (o) => o == write ) )
                        var pulledWrite = _remove( newAccesses, (o) => o == write );
                } // end - ACCESS_RIGHTS

                var sendemail = key[0]+key[1]+'s';;
                // make sure the sendemail access is a valid access
                if ( _find( ACCESS_RIGHTS, { id: sendemail } ) ) {
                    // check if sendemail access already in the state - if yes remove it
                    if ( _find( newAccesses, (o) => o == sendemail ) )
                        var pulledWrite = _remove( newAccesses, (o) => o == sendemail );
                } // end - ACCESS_RIGHTS

                var modify = key[0]+key[1]+'m';;
                // make sure the modify access is a valid access
                if ( _find( ACCESS_RIGHTS, { id: modify } ) ) {
                    // check if delete access already in the state - if yes remove it
                    if ( _find( newAccesses, (o) => o == modify ) )
                        var pulledModify = _remove( newAccesses, (o) => o == modify );
                } // end - ACCESS_RIGHTS

                var massEdit = key[0]+key[1]+'e';;
                // make sure the massEdit access is a valid access
                if ( _find( ACCESS_RIGHTS, { id: massEdit } ) ) {
                    // check if delete access already in the state - if yes remove it
                    if ( _find( newAccesses, (o) => o == massEdit ) )
                        var pulledMassEdit = _remove( newAccesses, (o) => o == massEdit );
                } // end - ACCESS_RIGHTS

                var merge = key[0]+key[1]+'g';;
                // make sure the merge access is a valid access
                if ( _find( ACCESS_RIGHTS, { id: merge } ) ) {
                    // check if delete access already in the state - if yes remove it
                    if ( _find( newAccesses, (o) => o == merge ) )
                        var pulledMassEdit = _remove( newAccesses, (o) => o == merge );
                } // end - ACCESS_RIGHTS

                var transferOwnership = key[0]+key[1]+'t';;
                // make sure the transferOwnership access is a valid access
                if ( _find( ACCESS_RIGHTS, { id: transferOwnership } ) ) {
                    // check if delete access already in the state - if yes remove it
                    if ( _find( newAccesses, (o) => o == transferOwnership ) )
                        var pulledTransferOwnership = _remove( newAccesses, (o) => o == transferOwnership );
                } // end - ACCESS_RIGHTS

                var deleteRight = key[0]+key[1]+'d';;
                // make sure the delete access is a valid access
                if ( _find( ACCESS_RIGHTS, { id: deleteRight } ) ) {
                    // check if delete access already in the state - if yes remove it
                    if ( _find( newAccesses, (o) => o == deleteRight ) )
                        var pulledDelete = _remove( newAccesses, (o) => o == deleteRight );
                } // end - ACCESS_RIGHTS
            } // end - key
        }

        newData['accesses'] = newAccesses;
        this.setState({ modalData: newData });
    }

    handleAddNew = () => {
        const { modalData } = this.state;
        var error = false;

        if ( !( modalData && modalData.accesses && isArrayExists( modalData.accesses ) ) ) {
            error = 'Please select at least one access right(s)';
        } // end - modalData.role

        if ( !( modalData && modalData.label && !_isEmpty( modalData.label ) ) ) {
            error = 'Please insert a valid label';
        } // end - modalData.label

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addRole(modalData));
        } // end - error
    }

    handleEdit = () => {
        const { modalData } = this.state;
        var error = false;

        if ( !( modalData && modalData.label && !_isEmpty( modalData.label ) ) ) {
            error = 'Please insert a valid label';
        } // end - modalData.label

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editRole(modalData));
        } // end - error
    }

    handleAction = () => {
        const { modalType } = this.state;
        if ( modalType && modalType == 'new' ) {
            this.handleAddNew();
        } else if ( modalType && modalType == 'edit' ) {
            this.handleEdit();
        } // end - modalType
    }

    handleDelete = () => {
        const { deleteModal } = this.state;
        //perform delete
        this.props.dispatch(deleteRole(deleteModal.uid));
    }

    reorganizeData() {
        const { searchterms, sortBy, perPage, page } = this.state;
        const { roles } = this.props;
        var items = ( roles ? cloneCollections( roles ) : [] ),
            total = _size( items );

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['created_on'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['created_on'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['label'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['label'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderViewForm = () => {
        const { modalData, modalType } = this.state;
        return (
        <div>
            <FormInput label="Label" name="label" value={( modalData.label || '' )} disabled={true} onChange={this.handleFormUpdate} />
            <Grid container spacing={0}>
                {ACCESS_RIGHTS.map( access => {
                    return (
                    <Grid key={access.id} item xs={( access.width || 6 )}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={( modalData && modalData.accesses && _find( modalData.accesses, (o) => o == access.id ) ? true : false )}
                                    onChange={this.handleAccessUpdate(access.id)}
                                    value={access.id}
                                    color="primary" />
                            }
                            label={access.label} />
                    </Grid>   
                    )
                })}
            </Grid>
        </div>
        );
    }

    renderTableActions = () => {
        const { sortBy, perPage, searchterms } = this.state;
        return <TableBar
                show={['sort','entries']}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Entries first' },
                    { value: 'date-asc', label: 'Oldest Entries first' },
                    { value: 'name-asc', label: 'Label ( A - Z)' },
                    { value: 'name-desc', label: 'Label ( Z - A )' }
                ]}
                rightButtons={[
                    <SuccessButton minWidth="168px" key="sync" style={{ marginRight: "5px" }} onClick={this.handleDataSync}><i className="fa fa-refresh"></i>Sycn with LMS</SuccessButton>
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    doneLoaded={true}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderBody = (items) => {
        const { classes } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(item => {
                return (
                <TableRow key={item.id}>
                    <TableCell className={classes.bodycell}>{item.label || ''}</TableCell>
                    <TableCell className={classes.bodycell}>
                        {ACCESS_RIGHTS.map(access => {
                            var haveAccess = ( item.accesses && isArrayExists( item.accesses ) && _find( item.accesses, (o) => o == access.id ) ? 'yes' : 'no' );
                            return (
                            <AccessTag key={access.id} access={haveAccess}>{access.label}</AccessTag>
                            );
                        })}
                    </TableCell>
                    <TableCell className={classes.bodycell}>
                        <ButtonGroup>
                            <InfoButton size="small" onClick={() => this.setState({ openViewModal: true, modalData: item, modalType: 'edit' })}><i className="fa fa-edit"></i>Edit</InfoButton>
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
                )
            }) : (
                <TableRow>
                    <TableCell className={classes.bodycell}>No Role(s) found.</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes } = this.props;
        return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headcell} style={{ width: "20%" }}>Label</TableCell>
                <TableCell className={classes.headcell}>Access</TableCell>
                <TableCell className={classes.headcell} style={{ width: "10%" }}>Actions</TableCell>
            </TableRow>
        </TableHead>
        )
    }

    render() {
        const { openDeleteModal, deleteModal, openViewModal, modalType } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openViewModal}
                maxWidth="md"
                title={ modalType && modalType == 'edit' ? "Edit Role" : "Add New Role" }
                actionLabel={ modalType && modalType == 'edit' ? "Update" : "Add New" }
                onClose={() => this.setState({ openViewModal: false, modalType: false, modalData: false })}
                doAction={this.handleAction}
                contents={this.renderViewForm()} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to delete this role ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            {this.renderTableActions()}
            <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                <Table>
                    {this.renderHeader()}
                    {this.renderBody(items)}
                </Table>
            </Paper>
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(Roles);